import React from 'react';

export const PendingAdminApproval = () => {
    return (
        <div className="PendingAdminApproval">
            <p>
                You've submitted everything we need. We're just waiting for your account to be
                approved.
            </p>
            <p>You can sign out now - We'll email you when your account is approved.</p>
        </div>
    );
};
