import { Link } from '@reach/router';
import { AppPath } from 'appConstants';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Form, Formik, useField } from 'formik';
import React, { useMemo } from 'react';
import Icon_Form_01 from 'assets/ibanera/Icon_Form_01.png';
import { postRegisterCustomerUser } from './RegisterApi';
import {
    AccountType,
    BusinessDetails,
    InputDetailsProps,
    PersonalDetails,
    RegistrationDetails,
} from './models';
import * as Yup from 'yup';
import instance, { ApiResponse } from 'api';
import { useState } from 'react';
import {
    handleRegisterFormErrors,
    handleRegistrationFormErrors,
} from './RegistrationErrorHandling';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { useEffect } from 'react';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { nameCombiner } from 'helpers/nameCombiner';
import { ErrorM } from 'components/form/ErrorM';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import { FormDateField } from 'components/form/FormDateField';
import ReactTooltip from 'react-tooltip';
import { Toast } from 'helpers/toast';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';
import { useRegistrationContext } from 'helpers/registration';
import { getErrorMessage } from '../../errors';

export const InputRegistrationDetails: React.FC<InputDetailsProps> = ({
    translations,
    onComplete,
    accountType,
    setAccountDetails,
}) => {
    const [showPasswordHelper, setShowPasswordHelper] = React.useState(false);
    const [passwordVisibility, setPasswordVisibility] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errorPersonal, setErrorPersonal] = useState<string | null>(null);

    const { registrationAdditionalInfo } = useRegistrationContext();

    const business: boolean = useMemo(() => accountType === AccountType.BUSINESS, [accountType]);

    const url = useMemo(() => {
        if (business) {
            return endpoints.registration.businessRegistration;
        } else if (!business) {
            return endpoints.registration.customerUser;
        }
    }, [business]);

    const personalInitialValues = {
        accountType: accountType,
        firstName: '',
        middleNames: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        currencyCode: 'USD',
        nameConfirmed: false,
        taxNumber: '',
        businessDetails: {
            countryISOCode: '',
        },
    };
    const businessInitialValues = {
        personalDetails: {
            firstName: '',
            middleNames: '',
            lastName: '',
            currencyCode: 'USD',
            emailAddress: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            nameConfirmed: false,
        },
    };

    const handleSubmit = (values: any, actions: any) => {
        setLoading(true);
        if (business) {
            (async () => {
                if (url) {
                    const result = await postRegisterCustomerUser(url, {
                        ...values,
                        ...registrationAdditionalInfo,
                    });
                    if (result.response) {
                        onComplete();
                    }
                    if (result.errors) {
                        handleRegistrationFormErrors(result.errors, actions);
                    }
                    if (result.errors?.find((err) => err.fieldName === 'CountryISOCode')) {
                        const msg = getErrorMessage(
                            result.errors.find((err) => err.fieldName === 'CountryISOCode')!
                                .messageCode
                        );
                        Toast.openErrorToast(msg);
                    }
                } else {
                    Toast.openGenericErrorToast();
                }
                setLoading(false);
            })();

            setAccountDetails({
                emailAddress: values.personalDetails.emailAddress,
                password: values.personalDetails.password,
                phoneNumber: values.personalDetails.phoneNumber,
            });
        } else {
            (async () => {
                if (url) {
                    const result = await postRegisterCustomerUser(url, {
                        ...values,
                        ...registrationAdditionalInfo,
                    });
                    if (result.response) {
                        onComplete();
                    }
                    if (result.errors) {
                        const [error] = handleRegisterFormErrors(result.errors[0]);
                        setErrorPersonal(error);
                    }
                } else {
                    Toast.openGenericErrorToast();
                }
            })();
            setLoading(false);
            setAccountDetails({
                emailAddress: values.emailAddress,
                password: values.password,
                phoneNumber: values.phoneNumber,
            });
        }
    };

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const taxNumberTooltip: React.ReactNode = (
        <label>
            Tax Id Number
            <span>*</span>
            <span
                data-tip="If you do not have a Tax ID Number, please provide your EIN number or an equivalent number for your country"
                data-for="TaxNumberTooltip"
                className="TooltipTag"
            >
                ?
            </span>
        </label>
    );

    const validationSchemaPersonal = Yup.object({
        firstName: Yup.string().required('Please provide a first name'),
        lastName: Yup.string().required('Please provide a last name'),
        emailAddress: Yup.string()
            .email(translations?.email_address_required)
            .required(translations?.email_address_required),
        password: Yup.string()
            .required(translations?.password_required)
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[0-9]/, 'Password must contain a number')
            .matches(/[a-zA-Z]/, 'Password must contain a letter'),
        confirmPassword: Yup.string()
            .required('Please confirm the given password')
            .test('passwords-match', 'Passwords must match', (value: any, testContext?: any) => {
                return testContext.parent.password === value;
            }),
        nameConfirmed: Yup.bool().oneOf([true], 'Please confirm this name matches your ID'),
        currencyCode: Yup.string().required('Please select a currency'),
    });

    const validationSchemaBusiness = Yup.object({
        personalDetails: Yup.object().shape({
            firstName: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a first name'),
            lastName: Yup.string()
                .max(90, 'Maximum 90 characters')
                .required('Please provide a last name'),
            emailAddress: Yup.string()
                .max(200, 'Maximum 200 characters')
                .email(translations?.email_address_required)
                .required(translations?.email_address_required),
            phoneNumber: Yup.string().max(100, 'Maximum 100 characters').required('Required'),
            password: Yup.string()
                .required(translations?.password_required)
                .min(8, 'Password must be at least 8 characters long')
                .matches(/[0-9]/, 'Password must contain a number')
                .matches(/[a-zA-Z]/, 'Password must contain a letter'),
            confirmPassword: Yup.string()
                .required('Please confirm the given password')
                .test(
                    'passwords-match',
                    'Passwords must match',
                    (value: any, testContext?: any) => {
                        return testContext.parent.password === value;
                    }
                ),
            nameConfirmed: Yup.bool().oneOf([true], 'Please confirm this name matches your ID'),
            currencyCode: Yup.string().required('Please select a currency'),
        }),
    });

    return (
        <>
            {loading && <Spinner />}
            {translations && (
                <Formik
                    initialValues={business ? businessInitialValues : personalInitialValues}
                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                    validationSchema={
                        business ? validationSchemaBusiness : validationSchemaPersonal
                    }
                >
                    {({ values, setFieldValue, setFieldTouched, errors }) => {
                        return (
                            <Form className="RegisterFormWidth">
                                <div className="RegisterFormWidth">
                                    <h1>Create an account</h1>
                                    <p>
                                        {business
                                            ? 'Please enter the information for the primary contact person on the account'
                                            : 'Please enter your personal information'}
                                    </p>
                                    <div className="FormSideBySide">
                                        <FormTextField
                                            field={
                                                business ? 'personalDetails.firstName' : 'firstName'
                                            }
                                            label={'First name'}
                                            required={true}
                                            maxLength={200}
                                            sideBySide={true}
                                        />
                                        <FormTextField
                                            field={
                                                business
                                                    ? 'personalDetails.middleName'
                                                    : 'middleNames'
                                            }
                                            label={'Middle Name(s)'}
                                            required={false}
                                            maxLength={200}
                                            sideBySide={true}
                                        />
                                        <FormTextField
                                            field={
                                                business ? 'personalDetails.lastName' : 'lastName'
                                            }
                                            label={'Last name'}
                                            required={true}
                                            maxLength={200}
                                            sideBySide={true}
                                        />
                                    </div>
                                    {(business
                                        ? (values as BusinessDetails).personalDetails.firstName &&
                                          (values as BusinessDetails).personalDetails.lastName
                                        : (values as PersonalDetails).firstName &&
                                          (values as PersonalDetails).lastName) && (
                                        <div style={{ marginBottom: 20 }}>
                                            <div
                                                className={`NameConfirmation ${
                                                    business
                                                        ? (values as BusinessDetails)
                                                              .personalDetails.nameConfirmed
                                                            ? 'Checked'
                                                            : ''
                                                        : (values as PersonalDetails).nameConfirmed
                                                        ? 'Checked'
                                                        : ''
                                                }}`}
                                            >
                                                <CheckboxInput
                                                    name={
                                                        business
                                                            ? 'personalDetails.nameConfirmed'
                                                            : 'nameConfirmed'
                                                    }
                                                    label={
                                                        business
                                                            ? `I confirm "${nameCombiner(
                                                                  (values as BusinessDetails)
                                                                      .personalDetails.firstName,
                                                                  (values as BusinessDetails)
                                                                      .personalDetails.middleNames,
                                                                  (values as BusinessDetails)
                                                                      .personalDetails.lastName
                                                              )}" is the name that appears on my ID`
                                                            : `I confirm "${nameCombiner(
                                                                  (values as PersonalDetails)
                                                                      .firstName,
                                                                  (values as PersonalDetails)
                                                                      .middleNames,
                                                                  (values as PersonalDetails)
                                                                      .lastName
                                                              )}" is the name that appears on my ID`
                                                    }
                                                />
                                            </div>
                                            <ErrorM
                                                name={
                                                    business
                                                        ? 'personalDetails.nameConfirmed'
                                                        : 'nameConfirmed'
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className="FormSideBySide">
                                        <FormTextField
                                            field={
                                                business
                                                    ? 'personalDetails.emailAddress'
                                                    : 'emailAddress'
                                            }
                                            label={translations.email_address}
                                            required={true}
                                            maxLength={200}
                                            sideBySide={true}
                                            inputMode="email"
                                            tooltip={
                                                <>
                                                    If personal account enter personal email, if for
                                                    a business enter business email with entity
                                                    domain. <br /> Example: Jane.Doe@XYZCompany.com
                                                </>
                                            }
                                        />
                                        <FormPhoneNumberField
                                            field={
                                                business
                                                    ? 'personalDetails.phoneNumber'
                                                    : 'phoneNumber'
                                            }
                                            label={'Mobile Phone Number'} //TODO replace with translation
                                            sideBySide={true}
                                        />
                                    </div>
                                    <div className="FormBox PasswordField">
                                        <FormTextField
                                            field={
                                                business ? 'personalDetails.password' : 'password'
                                            }
                                            label={translations.password}
                                            required={true}
                                            maxLength={20}
                                            type={passwordVisibility ? 'text' : 'password'}
                                            showIcon={true}
                                            icon={Icon_Form_01}
                                            hint="8+ characters, must contain numbers and letters"
                                            onClickIcon={handlePasswordVisibility}
                                            onBlur={(e) => {
                                                setFieldValue(
                                                    business
                                                        ? 'personalDetails.password'
                                                        : 'password',
                                                    e.target.value.trim(),
                                                    true
                                                );
                                                setFieldTouched(
                                                    business
                                                        ? 'personalDetails.password'
                                                        : 'password',
                                                    true
                                                );
                                            }}
                                        />
                                        <PasswordStrengthMeter
                                            fieldName={
                                                business ? 'personalDetails.password' : 'password'
                                            }
                                            onInfoClick={() => setShowPasswordHelper((s) => !s)}
                                            userInputs={['ibanera']}
                                        />
                                        {showPasswordHelper && (
                                            <PasswordHelper
                                                fieldName={
                                                    business
                                                        ? 'personalDetails.password'
                                                        : 'password'
                                                }
                                                userInputs={['ibanera']}
                                            />
                                        )}
                                    </div>
                                    <FormTextField
                                        field={
                                            business
                                                ? 'personalDetails.confirmPassword'
                                                : 'confirmPassword'
                                        }
                                        label={'Confirm password'}
                                        required={true}
                                        maxLength={20}
                                        type="password"
                                        onBlur={(e) => {
                                            setFieldValue(
                                                business
                                                    ? 'personalDetails.confirmPassword'
                                                    : 'confirmPassword',
                                                e.target.value.trim(),
                                                true
                                            );
                                            setFieldTouched(
                                                business
                                                    ? 'personalDetails.confirmPassword'
                                                    : 'confirmPassword',
                                                true
                                            );
                                        }}
                                    />
                                    {/* <div className="CurrencyDropdownField">
                                        <FormSingleSelectField
                                            fieldName="currencyCode"
                                            label="Primary currency"
                                            options={availableCurrencyCodes}
                                        />
                                    </div> */}
                                    {errorPersonal && (
                                        <div className="ErrorMessage">{errorPersonal}</div>
                                    )}
                                    <Button
                                        priority="primary"
                                        variety="full"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {translations.continue_button}
                                    </Button>
                                    {/* )} */}
                                    <p className="Disclaimer">
                                        By clicking the Continue button above, you agree to
                                        Ibanera's{' '}
                                        <a href="https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/">
                                            Terms &amp; Conditions
                                        </a>
                                        ,{' '}
                                        <a href="https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/">
                                            Digital Asset Custody Terms &amp; Conditions
                                        </a>{' '}
                                        and{' '}
                                        <a href="https://www.ibanera.com/privacy-policy/">
                                            Privacy Policy
                                        </a>
                                    </p>
                                    <p className="RegisterFormRedirect">
                                        Already have an account?{' '}
                                        <Link to={AppPath.SIGN_IN} className="FormLink">
                                            {translations.sign_in_button}
                                        </Link>
                                    </p>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};
