import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { useGetAccountById } from 'helpers/useGetAccountDetails';
import { createContext, useContext, useEffect, useState } from 'react';
import { AccountSubPage } from './AccountPage';

type TAccountContext = {
    accountId: string;
    monthlyStatementsEnabled: boolean | null;
    setMonthlyStatementsEnabled: (enabled: boolean) => void;
};

export const AccountContext = createContext<TAccountContext | null>(null);

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (!context) throw new Error('useAccountContext used outside provider');
    return context;
};

export const AccountContextProvider = ({
    accountId,
    children,
}: {
    accountId: string;
    children: React.ReactNode;
}) => {
    const [monthlyStatementsEnabled, setMonthlyStatementsEnabled] = useState<boolean | null>(null);
    const accountDetails = useGetAccountById(parseInt(accountId));

    useEffect(() => {
        if (
            !accountDetails?.childElements.some((element) =>
                element.path.endsWith(AccountSubPage.MONTHLY_STATEMENTS)
            )
        )
            return;
        instance
            .get<ApiResponse<{ bEnabled: boolean }>>(endpoints.accounts.monthlyStatementSettings, {
                params: { customerAssetAccountsId: accountId },
            })
            .then((res) => setMonthlyStatementsEnabled(res.data.details.bEnabled));
    }, [accountId, accountDetails?.childElements]);
    return (
        <AccountContext.Provider
            value={{ accountId, monthlyStatementsEnabled, setMonthlyStatementsEnabled }}
        >
            {children}
        </AccountContext.Provider>
    );
};
