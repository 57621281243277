import React from 'react';

interface ProgressBarProps {
    percentageComplete: number;
    start: number | string;
    end: number | string;
    className?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    percentageComplete,
    className,
    start,
    end,
}) => {
    return (
        <div style={{ width: '100%', padding: '0 6px' }}>
            <div className={`ProgressBarContainer ${className ? className : ''}`}>
                <div className="ProgressBar">
                    <div
                        className="Filled"
                        style={{
                            width: `${percentageComplete <= 100 ? percentageComplete : 100}%`,
                        }}
                    >
                        <div className="FilledCircle" />
                    </div>
                </div>
            </div>
            <div className="LowHigh">
                <span>{start}</span>
                <span>{end}</span>
            </div>
        </div>
    );
};
