import { AppPath } from 'appConstants';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export const useBasePath = () => {
    const cultureCode = useSelector(selectCultureCode);
    const { userInfo } = useSelector(selectComponentResources);
    const basePath = useMemo(
        () =>
            `/${cultureCode}${
                userInfo?.accountType === 'Personal' ? AppPath.PERSONAL : AppPath.BUSINESS
            }`,
        [userInfo?.accountType, cultureCode]
    );
    return basePath;
};
