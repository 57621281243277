import { useLocation, useParams } from '@reach/router';
import { DashboardPath } from 'appConstants';
import { AccountLink, CryptoAccountLink, FiatAccountLink } from 'components/sideMenu/SideMenu';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';

export const useGetAccountDetails = () => {
    const params = useParams();
    const accountId = [DashboardPath.ACCOUNTS, DashboardPath.FUNDINGACCOUNTS].includes(
        `/${params.page}` as DashboardPath
    )
        ? params.sub_page
        : null;
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const accountDetails = useMemo(() => {
        if (!accountId) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        const account_details = allAccounts?.find((account) => account.id === parseInt(accountId));

        return account_details;
    }, [customerMenuLinks, accountId]);
    return accountDetails;
};
export const useCryptoAccountDetails = () => {
    const params = useParams();
    const cryptoCode = [DashboardPath.CRYPTO, DashboardPath.CRYPTOACCOUNTS].includes(
        `/${params.page}` as DashboardPath
    )
        ? params.sub_page
        : null;

    const { customerMenuLinks } = useSelector(selectComponentResources);
    const accountDetails = useMemo(() => {
        if (!cryptoCode) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        const cryptoAccounts = allAccounts?.filter(
            (acc) => !!isCryptoAccount(acc)
        ) as CryptoAccountLink[];
        const account_details = cryptoAccounts.find(
            (acc) => acc.code.toLowerCase() === cryptoCode.toLowerCase()
        );

        return account_details;
    }, [customerMenuLinks, cryptoCode]);
    return accountDetails;
};
export const useGetAccountDetailsForCrypto = (cryptoCode: string) => {
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const accountDetails = useMemo(() => {
        if (!cryptoCode) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        const cryptoAccounts = allAccounts?.filter(
            (acc) => !!isCryptoAccount(acc)
        ) as CryptoAccountLink[];
        const account_details = cryptoAccounts.find(
            (acc) => acc.code.toLowerCase() === cryptoCode.toLowerCase()
        );

        return account_details;
    }, [customerMenuLinks, cryptoCode]);
    return accountDetails;
};
export const useGetAccountById = (accountId: number) => {
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const accountDetails = useMemo(() => {
        if (!accountId) return undefined;
        const allAccounts = customerMenuLinks?.reduce<AccountLink[]>(
            (prev, curr) => prev.concat(curr?.accounts ?? []),
            []
        );
        const account_details = allAccounts?.find((acc) => acc.id === accountId);

        return account_details;
    }, [customerMenuLinks, accountId]);
    return accountDetails;
};
export const useGetAccountCode = () => {
    const account = useGetAccountDetails();

    if (isFiatAccount(account)) {
        return account.currencyCode;
    } else if (isCryptoAccount(account)) {
        return account.code;
    } else return '';
};

const isFiatAccount = (link?: AccountLink): link is FiatAccountLink => {
    return !!(link as FiatAccountLink).productDisplayName;
};
const isCryptoAccount = (link?: AccountLink): link is CryptoAccountLink => {
    return !!(link as CryptoAccountLink).code;
};

export const useIsFundingAccount = () => {
    const location = useLocation();
    return location?.pathname.includes(DashboardPath.FUNDINGACCOUNTS);
};
