import instance, { isAxiosErrorHandled, isErrorHandled, useFetch } from 'api';
import { endpoints } from 'endpoints.config';
import { closeModal, selectModalState } from 'reducers/modal';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes } from 'reducers/modal';
import { FormSingleSelectField, FormTextField } from '@avamae/formbuilder';
import { useEffect, useState } from 'react';
import { SelectOption } from 'components/categoryComponents/dropdown/Dropdown';
import { Form, Formik, FormikHelpers } from 'formik';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import * as Yup from 'yup';
import { Toast } from 'helpers/toast';
import { getErrorMessage } from 'errors';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Spinner } from 'components/spinner/Spinner';
import { FormBuilder } from 'components/form/FormBuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';

type TComplianceFieldOptions = {
    customerSubtype: string | null;
    businessActivityType: string | null;
    occupationType: string | null;
    sourceOfFundsType: string | null;
    taxNumber: string;
};
const validationSchema = Yup.object({
    customerSubtype: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    businessActivityType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    occupationType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    sourceOfFundsType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    taxNumber: Yup.string().required("Please enter the user's tax number"),
});

export const ManageCardUsers_AddCard = () => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();

    const { loading, error, data } = useFetch<ApiResponse<TComplianceFieldOptions>>(
        endpoints.cards.addCard
    );

    if (modalState.modalType !== ModalTypes.ADD_CARD_MANAGECARDUSERS) return null;
    const { manageesId, currency, onCompletion, onSuccess, onError } = modalState.data;

    const handleSubmit = (
        values: TComplianceFieldOptions,
        formikHelpers: FormikHelpers<TComplianceFieldOptions>
    ) => {
        instance
            .post(endpoints.cardManagementModule.addcard, {
                manageesId,
                currency,
                ...values,
            })
            .then(onSuccess)
            .catch((err) => {
                if ((isAxiosErrorHandled(err) && err.response.data.errors) || isErrorHandled(err)) {
                    let errorToast = false;
                    //iterate through the errors returned by the api and set error messages on the appropriate fields
                    err.response.data.errors?.forEach((error) => {
                        const fieldName = toCamelCase(error.fieldName) as string;
                        if (Object.keys(values).includes(fieldName)) {
                            formikHelpers.setFieldError(
                                fieldName,
                                getErrorMessage(error.messageCode)
                            );
                        } else if (!errorToast) {
                            errorToast = true;
                            Toast.openToastMessage(getErrorMessage(error.messageCode), 1);
                        }
                    });
                } else {
                    onError ? onError() : Toast.openGenericErrorToast();
                }
            })
            .finally(onCompletion);
    };

    const _closeModal = () => {
        onCompletion();
        dispatch(closeModal());
    };

    if (loading || !data)
        return (
            <Modal
                title={`Add managee card`}
                onCloseModal={_closeModal}
                customButtons={<ModalFooterButtons isSubmitting={true} onCancel={_closeModal} />}
            >
                <Spinner></Spinner>
            </Modal>
        );

    return (
        <Formik initialValues={data?.details} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form>
                    <Modal
                        title={`Add managee card`}
                        onCloseModal={_closeModal}
                        customButtons={
                            <ModalFooterButtons
                                isSubmitting={isSubmitting}
                                onCancel={_closeModal}
                            />
                        }
                    >
                        <div className="ManageCardUsers_AddCardModal">
                            <FormBuilder loadingFallback={<Spinner />} data={data} />
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};

const ModalFooterButtons = ({
    onCancel,
    isSubmitting,
}: {
    onCancel: () => void;
    isSubmitting: boolean;
}) => {
    return (
        <div className="ModalNavigation">
            <Button priority="secondary" type="button" onClick={onCancel}>
                Cancel
            </Button>
            <Button priority="primary" type="submit" disabled={isSubmitting}>
                Add card
            </Button>
        </div>
    );
};
