import React from 'react';
import instance, { TableInfo, useTable } from 'api';
import { FlexTable } from 'components/flexTable';
import { NameInitialsCell } from 'components/flexTable/CustomCells';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { plaintext } from 'plaintext.config';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Pagination from 'components/pagination/Pagination';
import Button from 'components/button/Button';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as AccountIcon } from 'assets/AccountIcon.svg';
import { ReactComponent as CardIcon } from 'assets/CardIcon.svg';
import { TransferType } from 'pages/crypto/Crypto';

/** @jsxImportSource @emotion/react */

type Props = {
    headerButtons?: React.ReactNode;
    rowButtonGenerator?: (row: SavedPayee) => React.ReactNode;
    table: TableInfo;
};
export enum PayeeAccountType {
    Card = 'Card',
    BankAccount = 'Account',
}
export enum PayeeType {
    Personal = 'Personal',
    Company = 'Company',
}
export type SavedPayee = {
    payees__Id: number;
    payees__Name: string;
    payeeCards__Last4Digits: string;
    payeeAccountTypes__Description: PayeeAccountType;
    payees__Type: PayeeType;
    countries__CountryName: string;

    payees__AccountName: string;
    payees__AchAccountNumber: string;

    payees__PayeesReference: null;
    payees__bPullAvailable: false;
    payees__AllowedTransferTypes: string;
    payees__bInternational: boolean;
    assets__Code: string;
};

const idColumn: keyof SavedPayee = 'payees__Id';

const rowFormatter = (row: SavedPayee) => {
    const formattedRow: { [k in keyof typeof row]: React.ReactNode } = { ...row };
    if (formattedRow.payees__Name) {
        formattedRow.payees__Name = <NameInitialsCell name={row.payees__Name} />;
    }
    return formattedRow;
};

export const PayeesTable = ({ headerButtons, rowButtonGenerator, table }: Props) => {
    const { loading, data } = table;
    return (
        <div className="PayeesTable">
            <div className="TableHeader">
                <div className="TransferButtons">{headerButtons}</div>
                <div>
                    <TableFilterButton
                        onSelect={() =>
                            table.data?.actions.changeFilter(
                                'PayeeAccountTypes__Description' as keyof unknown,
                                [
                                    {
                                        columnKey: 'PayeeAccountTypes__Description',
                                        operator: 'EQ',
                                        value: 'Account',
                                    },
                                ]
                            )
                        }
                        clear={() =>
                            table.data?.actions.changeFilter(
                                'PayeeAccountTypes__Description' as keyof unknown,
                                []
                            )
                        }
                        isSelected={
                            table.data?.details.filters.some((column) =>
                                column.filters.some((filter) => filter.value === 'Account')
                            ) ?? false
                        }
                        label={<>Accounts</>}
                        icon={<AccountIcon height={'1em'} className="TableButtonIcon" />}
                    />
                    <TableFilterButton
                        onSelect={() =>
                            table.data?.actions.changeFilter(
                                'PayeeAccountTypes__Description' as keyof unknown,
                                [
                                    {
                                        columnKey: 'PayeeAccountTypes__Description',
                                        operator: 'EQ',
                                        value: 'Card',
                                    },
                                ]
                            )
                        }
                        clear={() =>
                            table.data?.actions.changeFilter(
                                'PayeeAccountTypes__Description' as keyof unknown,
                                []
                            )
                        }
                        isSelected={
                            table.data?.details.filters.some((column) =>
                                column.filters.some((filter) => filter.value === 'Card')
                            ) ?? false
                        }
                        label={<>Cards</>}
                        icon={<CardIcon height={'1em'} className="TableButtonIcon" />}
                    />
                </div>
                <SearchBox
                    key={'searchBox'}
                    placeholder={plaintext.accounts.searchPayees}
                    initialSearchString={data?.details.searchString ?? ''}
                    changeSearch={data?.actions.changeSearch}
                    goToPage={data?.actions.goToPage}
                />
            </div>
            {loading ? (
                <SubPageLoader message={plaintext.accounts.loading} />
            ) : (
                <>
                    <FlexTable
                        idColumn={idColumn}
                        table={table as TableInfo}
                        rowFormatter={rowFormatter}
                        rowButton={rowButtonGenerator}
                        buttonInMiddleTable
                    />
                    {data?.details.listData.length === 0 && !loading && (
                        <div className="EmptyTable">
                            <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                            <h3 className="Message">{plaintext.tables.emptyPayeesTable}</h3>
                        </div>
                    )}
                    <Pagination table={table} />
                </>
            )}
        </div>
    );
};

export const TableFilterButton = <T,>({
    onSelect,
    clear,
    isSelected,
    label,
    icon,
}: {
    onSelect: () => void;
    clear: () => void;
    isSelected: boolean;
    label: React.ReactNode;
    icon?: React.ReactNode;
}) => {
    const theme = useTheme();
    const buttonCSS = isSelected
        ? css`
              background: ${theme.colors.second};
              border: 1px solid ${theme.colors.second};
              color: #fff;
              .TableButtonIcon {
                  fill: #fff;
              }
          `
        : css`
              .TableButtonIcon {
                  fill: #d5d5d5;
              }
          `;
    return (
        <button
            css={buttonCSS}
            className={`Btn TableFilterBtn ${isSelected ? 'Selected' : ''}`}
            onClick={isSelected ? clear : onSelect}
            type="button"
        >
            {icon}
            {label}
        </button>
    );
};
