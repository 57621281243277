import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { ValueType } from 'react-select/src/types';

export type SelectOption = { value: string; label: string };
export type DropdownSelectProps = Omit<
    SelectProps,
    'onChange' | 'value' | 'options' | 'defaultMenuIsOpen' | 'isMulti'
>;

type DropdownProps = {
    options: { value: string; label: string }[];
    value: ValueType<SelectOption, any>;
    onChange(selectedOption: ValueType<SelectOption, any>): void;
    id: string;
    roundedRectangle?: boolean;
    small?: boolean;
    defaultMenuIsOpen?: boolean;
    isMulti?: boolean;
    readOnly?: boolean;
    dropdownProps?: DropdownSelectProps;
    hasError?: boolean;
};

type Props = DropdownProps;

const Dropdown: React.FC<Props> = ({
    options,
    value,
    onChange,
    id,
    roundedRectangle,
    small,
    defaultMenuIsOpen,
    isMulti,
    readOnly,
    dropdownProps,
    hasError,
}) => {
    return (
        <Select
            className={`CustomSelect ${roundedRectangle ? 'RoundedRect' : ''} ${
                small ? 'Small' : ''
            } ${hasError ? 'ErrorInput' : ''}`}
            inputId={id}
            defaultMenuIsOpen={defaultMenuIsOpen}
            classNamePrefix={readOnly ? 'CustomSelect Disabled' : 'CustomSelect'}
            options={options}
            value={value}
            isDisabled={readOnly}
            onChange={onChange}
            isMulti={isMulti}
            menuPlacement="auto"
            {...dropdownProps}
        />
    );
};

export { Dropdown };
