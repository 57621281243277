import { SavedPayee as MoveMoneySavedPayee, SavedPayee } from '../pages/account/MoveMoney';
import { Store } from './rootReducer';
import { FieldHelperProps, FormikValues } from 'formik';
import { UploadDocumentFlowType } from 'components/modals/UploadDocumentModal';
import { TransactionDataRow } from 'components/transactionsTable/Transactions';
import { CurrencyExchangeItem } from 'pages/currencyExchange/models';
import { CurrencyExchangeModalData } from 'components/modals/CurrencyExchangeModal/CurrencyExchangeModal';
import { SidePanelData } from '../components/TableEntryInfoPanel/TableEntryInfoPanel';
import { CryptoExchangeItem, ExchangeType } from 'pages/cryptoV2/models';
import { FiatAccountLink } from 'components/sideMenu/SideMenu';

export type Nullable<T> = T | null;

//Types
export enum ModalTypes {
    'DELETE' = 'DELETE',
    'RESEND_CODE' = 'RESEND_CODE',
    'CRYPTO_CREATE_ALERT' = 'CRYPTO_CREATE_ALERT',
    'CRYPTO_CURRENCY_SELECT' = 'CRYPTO_CURRENCY_SELECT',
    'OPEN_MODAL' = 'OPEN_MODAL',
    'CLOSE_MODAL' = 'CLOSE_MODAL',
    'DEPOSIT_CRYPTO' = 'DEPOSIT_CRYPTO',
    'WITHDRAW_CRYPTO' = 'WITHDRAW_CRYPTO',
    'WITHDRAW_CRYPTO_AS_FIAT' = 'WITHDRAW_CRYPTO_AS_FIAT',
    'BUY_DUSD' = 'BUY_DUSD',
    'CHANGE_PASSWORD' = 'CHANGE_PASSWORD',
    'CHANGE_PHONE_NUMBER' = 'CHANGE_PHONE_NUMBER',
    'CHANGE_EMAIL' = 'CHANGE_EMAIL',
    'DEACTIVATE_ACCOUNT' = 'DEACTIVATE_ACCOUNT',
    'UPDATE_APP_TFA' = 'UPDATE_APP_TFA',
    'UPLOAD_BUSINESS_DOCUMENT' = 'UPLOAD_BUSINESS_DOCUMENT',
    'UPLOAD_MULTI_BUSINESS_DOCUMENT' = 'UPLOAD_MULTI_BUSINESS_DOCUMENT',
    'PULL_FUNDS' = 'PULL_FUNDS,',
    'ADD_CARD_MANAGECARDUSERS' = 'ADD_CARD_MANAGECARDUSERS',
    'VISA_DIRECT' = 'VISA_DIRECT',
    'LIQUIDATE_CRYPTO' = 'LIQUIDATE_CRYPTO',
    'TRANSACTION_INFO' = 'TRANSACTION_INFO',
    'TRANSACTION_NOTE' = 'TRANSACTION_NOTE',
    'CURRENCY_EXCHANGE' = 'CURRENCY_EXCHANGE',
    'CURRENCY_EXCHANGE_CHART' = 'CURRENCY_EXCHANGE_CHART',
    'TRANSACTION_DETAILS' = 'TRANSACTION_DETAILS',
    'REQUEST_INFO' = 'REQUEST_INFO',
    'UPLOAD_REQUEST_INFO' = 'UPLOAD_REQUEST_INFO',
    'PAYEE_DETAILS' = 'PAYEE_DETAILS',
    'REMOVE_PAYEE' = 'REMOVE_PAYEE',
    'CRYPTO_EXCHANGE' = 'CRYPTO_EXCHANGE',
    'CRYPTO_EXCHANGE_CHART' = 'CRYPTO_EXCHANGE_CHART',
    'PENDING_CARD' = 'PENDING_CARD',
}

export type ResendCodeData = {
    phoneNumber: string;
};

export type PendingCard = { id?: number; cardUrlPath?: string; bReadyForActivation: boolean };

// State
export type ModalState =
    | {
          modalType: null;
          data: null;
      }
    | {
          modalType: ModalTypes.RESEND_CODE;
          data: ResendCodeData;
      }
    | {
          modalType: ModalTypes.CRYPTO_CREATE_ALERT;
          data: null;
      }
    | {
          modalType: ModalTypes.CRYPTO_CURRENCY_SELECT;
          data: null;
      }
    | {
          modalType: ModalTypes.CHANGE_PASSWORD;
          data: null;
      }
    | {
          modalType: ModalTypes.CHANGE_EMAIL;
          data: null;
      }
    | {
          modalType: ModalTypes.DEPOSIT_CRYPTO;
          data: { crypto: string | null; depositEndpoint: string };
      }
    | {
          modalType: ModalTypes.WITHDRAW_CRYPTO;
          data: { crypto: string; withdrawUrl: string };
      }
    | {
          modalType: ModalTypes.WITHDRAW_CRYPTO_AS_FIAT;
      }
    | {
          modalType: ModalTypes.LIQUIDATE_CRYPTO;
          data: { crypto: string; fromAccount: number };
      }
    | {
          modalType: ModalTypes.DEACTIVATE_ACCOUNT;
          data: null;
      }
    | {
          modalType: ModalTypes.CHANGE_PHONE_NUMBER;
          data: null;
      }
    | {
          modalType: ModalTypes.BUY_DUSD;
          data: { sourceAssetAccount: FiatAccountLink };
      }
    | {
          modalType: ModalTypes.CURRENCY_EXCHANGE;
          data: CurrencyExchangeModalData;
      }
    | {
          modalType: ModalTypes.CURRENCY_EXCHANGE_CHART;
          data: CurrencyExchangeItem;
      }
    | {
          modalType: ModalTypes.UPLOAD_BUSINESS_DOCUMENT;
          data: {
              flowType: UploadDocumentFlowType;
              documentId: number;
              documentName: string;
              reloadTable: () => void;
              bRequired?: boolean;
              helpers?: FieldHelperProps<any>;
              values?: FormikValues;
              fieldName?: string;
          };
      }
    | {
          modalType: ModalTypes.UPLOAD_MULTI_BUSINESS_DOCUMENT;
          data: {
              flowType: UploadDocumentFlowType;
              documentId: number;
              reloadTable: () => void;
              helpers?: FieldHelperProps<any>;
              onRemovedItem: (docId: number, customersBusinessDocumentsId: number) => void;
              values?: FormikValues;
              fieldName?: string;
          };
      }
    | {
          modalType: ModalTypes.UPDATE_APP_TFA;
          data: null;
      }
    | {
          modalType: ModalTypes.VISA_DIRECT;
          data: { customerAssetAccountsId: number; reloadTable: () => void };
      }
    | {
          modalType: ModalTypes.PULL_FUNDS;
          data: { payeeData: SavedPayee; reloadTable: () => void };
      }
    | {
          modalType: ModalTypes.TRANSACTION_INFO;
          data: TransactionDataRow;
      }
    | {
          modalType: ModalTypes.TRANSACTION_NOTE;
          data: TransactionDataRow;
      }
    | {
          modalType: ModalTypes.TRANSACTION_DETAILS;
          data: {
              rowData: SidePanelData;
              currencySymbol: string;
          };
      }
    | {
          modalType: ModalTypes.PAYEE_DETAILS;
          data: {
              rowData: MoveMoneySavedPayee;
              disablePayment?: boolean;
              onCloseModal?: () => void;
          };
      }
    | {
          modalType: ModalTypes.REMOVE_PAYEE;
          data: MoveMoneySavedPayee;
      }
    | {
          modalType: ModalTypes.REQUEST_INFO;
          data?: { bIsReminder: boolean };
      }
    | {
          modalType: ModalTypes.UPLOAD_REQUEST_INFO;
          data: number;
      }
    | {
          modalType: ModalTypes.CRYPTO_EXCHANGE;
          data: CryptoExchangeItem & {
              amount?: number;
              exchangeType: ExchangeType;
              swapAssetPair: boolean;
              navigate: (path: string) => void;
          };
      }
    | {
          modalType: ModalTypes.CRYPTO_EXCHANGE_CHART;
          data: CryptoExchangeItem & { reverseAsset: boolean; navigate: (path: string) => void };
      }
    | {
          modalType: ModalTypes.ADD_CARD_MANAGECARDUSERS;
          data: {
              manageesId: number;
              currency: string;
              onSuccess: (res?: any) => void;
              onError: (err?: any) => void;
              onCompletion: (res?: any) => void;
          };
      }
    | {
          modalType: ModalTypes.PENDING_CARD;
          data: PendingCard;
      };

export const initialState: ModalState = {
    modalType: null,
    data: null,
};

// Actions
export type Action =
    | {
          type: ModalTypes.OPEN_MODAL;
          payload: ModalState;
      }
    | {
          type: ModalTypes.CLOSE_MODAL;
          payload: null;
      };

// Reducer
export default function modalReducer(state = initialState, action: Action): ModalState {
    switch (action.type) {
        case ModalTypes.OPEN_MODAL:
            return {
                ...action.payload,
            };
        case ModalTypes.CLOSE_MODAL:
            return {
                ...state,
                modalType: null,
                data: null,
            };
        default:
            return state;
    }
}

// Action creators
export const openModal = (payload: ModalState) => ({
    type: ModalTypes.OPEN_MODAL,
    payload,
});

export const closeModal = () => ({ type: ModalTypes.CLOSE_MODAL });

// Selectors
export const selectModalState = (state: Store) => state.modal;
