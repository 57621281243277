import { roundToPrecision } from 'helpers/calculateToPrecision';
import { ExchangeDetails } from 'pages/cryptoV2/models';
import { QuantityType, ReviewedDetails } from '../CryptoExchangeModal';
import { formatGeneralCurrency } from './FromTo';

export const OrderSummary = ({
    orderDetails,
    exchangeDetails,
}: {
    orderDetails: ReviewedDetails;
    exchangeDetails: ExchangeDetails;
}) => {
    const reverseUx = orderDetails.assetPairSwapped;
    const isBuyExchange = orderDetails.exchangeType === 'Buy';

    const buyingStr = `You're buying ${
        orderDetails.quantityType === QuantityType.Destination ? 'exactly' : ''
    }`;
    const sellingStr = `You're selling ${
        orderDetails.quantityType === QuantityType.Source ? 'exactly' : ''
    }`;
    const firstString = isBuyExchange ? buyingStr : sellingStr;
    const secondString = isBuyExchange ? sellingStr : buyingStr;

    const fromAccount = exchangeDetails.fromAccounts.find(
        (acc) => acc.accountID == orderDetails.sourceAccountId
    );
    const toAccount = exchangeDetails.mergedToAccounts.find(
        (acc) =>
            (acc.accountID !== null && acc.accountID == orderDetails.destinationAccountId) ||
            (acc.payeesId !== null && acc.payeesId == orderDetails.destinationPayeeId)
    );

    return (
        <div>
            <div
                className="BuySellAmounts"
                style={{ flexDirection: reverseUx ? 'row-reverse' : 'row' }}
            >
                <div>
                    <h4>{firstString}</h4>
                    <h3>{formatGeneralCurrency(orderDetails.amount, orderDetails.asset)}</h3>
                </div>
                <div>
                    <h4>{secondString}</h4>
                    <h3>
                        {formatGeneralCurrency(orderDetails.totalPrice, orderDetails.pairedAsset)}
                    </h3>
                    <span>
                        ({orderDetails.price}{' '}
                        {isBuyExchange
                            ? reverseUx
                                ? 'after'
                                : 'plus'
                            : reverseUx
                            ? 'before'
                            : 'minus'}{' '}
                        fees)
                    </span>
                </div>
            </div>
            <div className="ExchangeRate ExchangeRateConfirm">
                <div>Quoted Price:</div>
                <div className="Rates">
                    <p>
                        {exchangeDetails.asset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {exchangeDetails.pairedAsset} ~
                        {roundToPrecision(
                            isBuyExchange ? 1 / orderDetails.buyPrice : 1 / orderDetails.sellPrice,
                            8
                        )}
                    </p>
                    <p>
                        {exchangeDetails.pairedAsset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {exchangeDetails.asset} ~{' '}
                        {roundToPrecision(
                            !isBuyExchange ? orderDetails.sellPrice : orderDetails.buyPrice,
                            8
                        )}
                    </p>
                </div>
            </div>
            <div className="SummaryRow">
                <div className="RowInfo">
                    <p>From:</p>
                    <h4>{fromAccount?.displayName}</h4>
                </div>
                <div className="RowInfo">
                    <p>To:</p>
                    <h4>{toAccount?.label}</h4>
                </div>
            </div>
            <div className="SummaryRow">
                <div className="RowInfo">
                    <p>My comment</p>
                    <h4>{orderDetails.comment ? orderDetails.comment : '--'}</h4>
                </div>
            </div>
        </div>
    );
};
