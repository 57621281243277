import { useField } from 'formik';
import Button from '../../../../button/Button';
import FormTextField from '../../../../form/FormTextField';
import { RadioButtons } from '../../../../radiobuttons/radiobuttons';
import { BeneficialPersonalOwner, OwnerTreeStructure } from '../../../schema';
import { INDIVIDUAL_OWNERS_FORM_STRING } from '../OwnersTree';
import { useState } from 'react';
import { FormSingleSelectField } from '../../../../form/FormSingleSelectField';
import { beneficialOwnersStructureSchema } from '../../../validation';

type NewIndividualFormProps = {
    hideLeftBar: boolean;
    fieldname: string;
    parentFieldname: string;
    ownerGuid: string;
    handleDeleteOwner: () => void;
};
export const NewIndividualForm: React.FC<NewIndividualFormProps> = ({
    ownerGuid,
    fieldname,
    parentFieldname,
    hideLeftBar,
    handleDeleteOwner,
}) => {
    const [{ value: parentValue }] = useField<OwnerTreeStructure | OwnerTreeStructure[]>(
        parentFieldname
    );
    const [{ value }, , { setValue: setIndividuals }] = useField<BeneficialPersonalOwner[] | null>(
        INDIVIDUAL_OWNERS_FORM_STRING
    );
    const [, , { setValue: setTreeIsNewValue }] = useField<boolean | undefined>(
        `${fieldname}.isNewNode`
    );
    const [{ value: ownerStartingGuid }] = useField<string>(`${fieldname}.ownersGuid`);
    const [{ value: treeValue }, , { setTouched }] = useField<OwnerTreeStructure>(fieldname);

    const handleSetNewItemToFalse = () => {
        setTreeIsNewValue(false);
        /* We always add new entires to the start of the array but this element
           Will have been marked as touched when we add something new we should mark it 
           this index as not touched when we're done adding to avoid showing errors
           on the inputs of a new element */
        setTouched(false);
    };

    const [originalGuid] = useState(ownerStartingGuid);
    const removeOriginalOwner = () => {
        // Remove originally added owner
        // Guid may have changed from dropdown comp hence storing originalGuid
        setIndividuals((value ?? []).filter((item) => item.guid !== originalGuid));
    };
    const handleAddExistingOwner = () => {
        removeOriginalOwner();
        handleSetNewItemToFalse();
    };
    const handleDeleteOwnerExisiting = () => {
        removeOriginalOwner();
        handleDeleteOwner();
    };

    const siblingGuids = (Array.isArray(parentValue) ? parentValue : parentValue.children ?? [])
        .filter((o) => !o.bIsBusiness)
        .map((o) => o.ownersGuid);
    const options = (value ?? [])
        // Filter out existing siblings to not allwow repeats in the same branch level
        .filter((o) => !siblingGuids.includes(o.guid))
        .map((opt) => ({
            label: `${opt.firstName} ${opt.lastName}`,
            value: opt.guid,
        }));
    const hasExistingOwners = options.length > 0;
    const [ownerMethod, setOwnerMethod] = useState<'Existing' | 'New' | null>(
        !hasExistingOwners ? 'New' : null
    );

    const isOwnerStructureValid =
        beneficialOwnersStructureSchema.isValidSync(treeValue) &&
        (ownerMethod === 'Existing' ? treeValue.ownersGuid !== originalGuid : true);

    const selectedOwnerIndex = (value ?? []).findIndex((o) => o.guid === ownerGuid)!;

    return (
        <div className="NewIndividualForm">
            {hideLeftBar && <div aria-hidden="true" className="FloatingHide Full" />}
            {!ownerMethod && (
                <div className="NewIndividualFormRow">
                    <Button
                        onClick={() => setOwnerMethod('New')}
                        type="button"
                        priority="secondary"
                    >
                        Add new individual
                    </Button>
                    <Button
                        onClick={() => setOwnerMethod('Existing')}
                        type="button"
                        priority="secondary"
                    >
                        Add existing individual
                    </Button>
                </div>
            )}
            {ownerMethod === 'New' && (
                <>
                    <div className="NewIndividualFormRow">
                        <FormTextField
                            label="First Name"
                            field={`${INDIVIDUAL_OWNERS_FORM_STRING}[${selectedOwnerIndex}].firstName`}
                        />
                        <FormTextField
                            label="Last Name"
                            field={`${INDIVIDUAL_OWNERS_FORM_STRING}[${selectedOwnerIndex}].lastName`}
                        />
                    </div>
                    <FormTextField
                        field={`${fieldname}.positionAtCompany`}
                        label={'Position at the Company'}
                        maxLength={200}
                    />
                    <RadioButtons
                        options={['Yes', 'No']}
                        label={'Controlling Party*'}
                        booleanField={true}
                        fieldname={`${fieldname}.bControllingParty`}
                    />
                    <FormTextField
                        type="number"
                        label="Percentage of Shares Owned (%)"
                        field={`${fieldname}.percentageOwned`}
                    />
                    <div className="NewIndividualFormRow AddSave">
                        <Button onClick={handleDeleteOwner} type="button" priority="secondary">
                            Cancel
                        </Button>
                        <Button
                            disabled={!isOwnerStructureValid}
                            onClick={handleSetNewItemToFalse}
                            type="button"
                        >
                            Add
                        </Button>
                    </div>
                </>
            )}
            {ownerMethod === 'Existing' && (
                <>
                    <FormSingleSelectField<any>
                        options={options}
                        label="Choose from existing individuals"
                        fieldName={`${fieldname}.ownersGuid`}
                    />
                    <FormTextField
                        field={`${fieldname}.positionAtCompany`}
                        label={'Position at the Company'}
                        maxLength={200}
                    />
                    <RadioButtons
                        options={['Yes', 'No']}
                        label={'Controlling Party*'}
                        booleanField={true}
                        fieldname={`${fieldname}.bControllingParty`}
                    />
                    <FormTextField
                        type="number"
                        label="Percentage of Shares Owned (%)"
                        field={`${fieldname}.percentageOwned`}
                    />
                    <div className="NewIndividualFormRow AddSave">
                        <Button
                            onClick={handleDeleteOwnerExisiting}
                            type="button"
                            priority="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isOwnerStructureValid}
                            onClick={handleAddExistingOwner}
                            type="button"
                        >
                            Add
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
