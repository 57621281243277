import instance, { ApiResponse } from '../../api';
import { endpoints } from '../../endpoints.config';
import Button from '../../components/button/Button';
import React, { Dispatch, useState } from 'react';
import { JumioStage, JumioVerificationStatusResponse } from './jumioModels';
import { useDispatch } from 'react-redux';
import { setVerificationStage } from 'reducers/verification';
import { Toast } from 'helpers/toast';

type Props = {};

export const JumioConsent = ({}: Props) => {
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const handleConsent = () => {
        setSubmitting(true);
        instance
            .get<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.verificationStatus,
                {
                    params: {
                        jumioConsent: true,
                    },
                }
            )
            .then((res) => {
                dispatch(
                    setVerificationStage({
                        currentStage: res.data.details.status,
                        additionalData: res.data.details.additionalData,
                    })
                );
            })
            .catch((err) => {
                Toast.openGenericErrorToast();
                setSubmitting(false);
            });
    };
    return (
        <div className="ConsentCollection">
            <p>
                We use a third-party service "Jumio" to verify your identity. By clicking “Continue”
                you consent to Jumio collecting, processing, and sharing your personal information,
                which may include biometric data, pursuant to its{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.jumio.com/legal-information/privacy-notices/jumio-corp-privacy-policy-for-online-services/"
                >
                    Privacy Notice.
                </a>
            </p>
            <Button variety="full" onClick={handleConsent} disabled={submitting}>
                Continue
            </Button>
        </div>
    );
};
