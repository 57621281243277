// When setting a default route component sometimes you will land on it without
// the path matching the one desired for that page. Use this hook to redirect to
// the desired route. It should only be used inside of whichever component is intended

import { useLocation, useNavigate } from '@reach/router';
import { useEffect } from 'react';

// to be the default component in reach-router.
export const useDefaultRoute = (path: string) => {
    const { pathname, state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname !== path) {
            navigate(path, { state: state as any });
        }
    }, [path, pathname, navigate, state]);
};
