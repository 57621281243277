import React, { lazy } from 'react';
import { RouteComponentProps } from '@reach/router';

// Icons
import View from '../../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../../assets/Cat-Entity/Icon_Action_02.png';
import Delete from '../../../assets/Cat-Entity/Icon_Action_03.png';

import { CrudItems } from 'components/Category/CrudMenu';
import { format } from 'date-fns';
import { endpoints } from 'endpoints.config';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';
import Category from 'components/Category/Category';
import Entity from 'components/categoryComponents/Entity';

const Card = lazy(() => import('./ManageCard'));

// Define the url for the table's data endpoint.
const url = endpoints.cardManagementModule.listcards; /* REPLACE__ME - api url for this component */

// Define which column on the table represents each row's ID.
// This should be unique to each row.
const idColumn = 'Cards__Id'; /* REPLACE__ME - id column for this component */

//Define the data structure for table
/* REPLACE__ME - table data structure */
export type CardsData = {
    Cards__Id: number;
    Cards__Code: string;
    Cards__Type: string;
    Cards__Number: string;
    Cards__ExpiryDateUTC: string;
    Cards__AssignedTo: string;
    Cards__bActive: boolean;
};

const crudItems: CrudItems<CardsData> = [
    // {
    //     icon: View,
    //     title: 'Add Card' /* REPLACE__ME */,
    //     imgAltText: 'Create',
    //     menuCode: 'CREATE',
    //     showInMultiSelect: false,
    //     //permissionsKey: 'create',
    //     content: (onDone, selectedData, menu) => (
    //         <Card
    //             selectedData={selectedData}
    //             onDone={onDone}
    //             idFromRowOption={menu.selectedId}
    //             actionType="create"
    //             submitButtonText="Add"
    //         />
    //     ),
    //     inRightPanel: true,
    //     isDisabled: false,
    //     notInMenus: true,
    // },
    {
        icon: View,
        title: 'View Card' /* REPLACE__ME */,
        imgAltText: 'View',
        //permissionsKey: 'view',
        menuCode: 'VIEW',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <Card
                onDone={onDone}
                selectedData={selectedData}
                idFromRowOption={menu.selectedId}
                actionType="view"
                submitButtonText="Close"
                noSubmit={true}
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: Update,
        imgAltText: 'Update',
        //permissionsKey: 'edit',
        title: 'Top up card' /* REPLACE__ME */,
        menuCode: 'UPDATE',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <Card
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="top-up"
                submitButtonText="Top up"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: Delete,
        imgAltText: 'Delete',
        //permissionsKey: 'delete',
        title: (data) =>
            data.length <= 1 ? 'Delete Card' : `Delete ${data.length} Cards ` /* REPLACE__ME */,
        menuCode: 'DELETE',
        showInMultiSelect: true,
        content: (onActionComplete, selectedData, menu) => (
            <DeleteModal
                url={endpoints.cardManagementModule.deleteusers /* REPLACE__ME */}
                noun="Cards"
                selectedIds={selectedData.map((d) => d.Cards__Id /** */)} /*
            REPLACE__ME - return id */
                renderListItem={(data) =>
                    /** return the name to be displayed */
                    data.Cards__Code + ' ' + data.Cards__Type
                }
                selectedData={selectedData}
                open={menu.currentMenu === 'DELETE'}
                close={onActionComplete}
                idFromRowOption={menu.selectedId}
            />
        ),
        inRightPanel: false,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: CardsData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.CardUsers__AddDateUTC) {
        formattedRow = {
            ...formattedRow,
            Cards__ExpiryDateUTC: localizedFormatDate(new Date(row.Cards__ExpiryDateUTC)),
        };
    }
    return formattedRow;
};

export interface CardsProps {}

type Props = CardsProps & RouteComponentProps;

function ManageCards(props: Props) {
    return (
        <Category<CardsData>
            location={props.location}
            contentTitle="Cards"
            noun="Cards" /** REPLACE__ME */
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={true}
            fetchConfig={{ withCredentials: false }}
            classname="listsCatTable"
            emptyTableLabel="No Cards Found"
        />
    );
}

export default ManageCards;
