import { addSeconds, isPast } from 'date-fns';
import { useEffect, useState, useMemo } from 'react';
import { selectSignedIn, TFA } from '../reducers/auth';
import { useSelector } from 'react-redux';

export type TokenResource = {
    accessToken: string;
    refreshToken: string;
    refreshTokenExpiresIn: number;
    scopes?: Scopes;
};

/**
 * Save token data in local storage.
 */
export function saveTokenResource(resource: TokenResource): void {
    const refreshTokenExpires = addSeconds(new Date(), resource.refreshTokenExpiresIn);

    if (window) {
        window.sessionStorage.setItem('AT', resource.accessToken);
        window.sessionStorage.setItem('RT', resource.refreshToken);
        window.sessionStorage.setItem('RTE', refreshTokenExpires.toString());
        if (resource.scopes) {
            window.sessionStorage.setItem('SCOPES', JSON.stringify(resource.scopes));
        }
    }
}

export function deleteTokenResource() {
    if (window) {
        window.sessionStorage.removeItem('AT');
        window.sessionStorage.removeItem('RT');
        window.sessionStorage.removeItem('RTE');
        window.sessionStorage.removeItem('SCOPES');
    }
}

export type LoadedTokenResource = {
    accessToken: string;
    refreshToken: string;
    refreshTokenExpires: Date;
    scopes?: string;
};

/**
 * Fetch token data from local storage.
 */
export function loadTokenResource(): null | LoadedTokenResource {
    try {
        const accessToken = window.sessionStorage.getItem('AT');
        const refreshToken = window.sessionStorage.getItem('RT');
        const expiry = window.sessionStorage.getItem('RTE');
        const scopes = window.sessionStorage.getItem('SCOPES');

        // If anything's missing, abort.
        if (accessToken == null || refreshToken == null || expiry == null) throw new Error();

        const refreshTokenExpires = new Date(expiry);

        if (isPast(refreshTokenExpires)) throw new Error();

        return {
            accessToken,
            refreshToken,
            refreshTokenExpires,
            scopes: scopes ?? '[]',
        };
    } catch (_error) {
        return null;
    }
}

export type AccessElement = {
    displayName: string;
    path: string;
    childElements?: Scopes;
};

export type Scopes = AccessElement[];

export function loadScopes(): string {
    try {
        const scopes = window.sessionStorage.getItem('SCOPES');
        if (scopes == null) throw new Error();

        return scopes;
    } catch (error) {
        return '[]';
    }
}

/**
 * Used to load navigation routes that are available to this user.
 * Effect runs on app load and sign-in.
 */
export function useLoadScopes(): Scopes {
    const [scopeString, setScopeString] = useState('[]');
    const isSignedIn = useSelector(selectSignedIn);

    useEffect(() => {
        if (isSignedIn) setScopeString(loadScopes());
    }, [isSignedIn]);

    const scopes: Scopes = useMemo(() => JSON.parse(scopeString), [scopeString]);

    return scopes;
}
