/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as SearchIcon } from 'assets/ibanera/search-icon.svg';
type TableSearchInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

export const TableSearchInput: React.FC<TableSearchInputProps> = (props) => {
    const theme = useTheme();
    return (
        <div
            className="MarketTableInput"
            css={css(`
                .FormField .EditBox {
                    border: 1px solid ${theme.colors.first};
                }
                .EditBox::placeholder {
                    color: ${theme.colors.first};
                }
            `)}
        >
            <div className="FormBox FormSideBySide">
                <div className="FormLabel">
                    <label></label>
                </div>
                <div className="FormField">
                    <input {...props} className="EditBox" />
                </div>
                <SearchIcon className="SvgIcon" />
            </div>
        </div>
    );
};
