import { AccountType, RegistrationAdditionalInfo } from 'pages/register/models';
import { SetStateAction, createContext, useContext } from 'react';

type TRegistrationContext = {
    accountType: AccountType | null;
    setRegistrationAdditionalInfo: React.Dispatch<
        SetStateAction<RegistrationAdditionalInfo | null>
    >;
    registrationAdditionalInfo: RegistrationAdditionalInfo | null;
};

export const RegistrationContext = createContext<TRegistrationContext | null>(null);

export const useRegistrationContext = () => {
    const context = useContext(RegistrationContext);
    if (!context) {
        throw new Error('useRegistrationContext must be within a RegistrationProvider');
    }
    return context;
};
