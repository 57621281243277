import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { CryptoExchangeRateWidget } from 'pages/cryptoV2/CryptoExchangeWidget';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, openModal, selectModalState } from 'reducers/modal';

export const CryptoExchangeChartModal = () => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    useEffect(() => {
        if (modalState.modalType !== ModalTypes.CRYPTO_EXCHANGE_CHART) return;
    }, [modalState, dispatch]);
    if (modalState.modalType !== ModalTypes.CRYPTO_EXCHANGE_CHART) return null;
    const { pairedAsset, asset, reverseAsset, bBuyAvailable, bSellAvailable, navigate } =
        modalState.data;
    return (
        <Modal
            title={!reverseAsset ? `${asset}/${pairedAsset}` : `${pairedAsset}/${asset}`}
            className="CryptoExchangeChartModal"
            customButtons={
                <div className="ModalNavigation">
                    {((bBuyAvailable && !reverseAsset) || (bSellAvailable && reverseAsset)) && (
                        <Button
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CRYPTO_EXCHANGE,
                                        data: {
                                            ...modalState.data,
                                            exchangeType: !reverseAsset ? 'Buy' : 'Sell',
                                            amount: 0,
                                            swapAssetPair: reverseAsset,
                                            navigate,
                                        },
                                    })
                                )
                            }
                        >
                            Buy {!reverseAsset ? asset : pairedAsset}
                        </Button>
                    )}
                    {((bSellAvailable && !reverseAsset) || (bBuyAvailable && reverseAsset)) && (
                        <Button
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CRYPTO_EXCHANGE,
                                        data: {
                                            ...modalState.data,
                                            exchangeType: !reverseAsset ? 'Sell' : 'Buy',
                                            amount: 0,
                                            swapAssetPair: reverseAsset,
                                            navigate,
                                        },
                                    })
                                )
                            }
                        >
                            Sell {!reverseAsset ? asset : pairedAsset}
                        </Button>
                    )}
                </div>
            }
        >
            <CryptoExchangeRateWidget
                // Thw widget will throw an error if you try BTC/USD so in those cases we don't swap the asset
                baseCurrency={!reverseAsset || pairedAsset === 'USD' ? pairedAsset : asset}
                exchangeCurrency={!reverseAsset || pairedAsset === 'USD' ? asset : pairedAsset}
            />
        </Modal>
    );
};
