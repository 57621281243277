import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from './rootReducer';

/* STATE */
type State = {
    cultureCode: string | null;
    languageOptions: LanguageOptions | null;
};

type LanguageOptions = {
    name: string;
    cultureInfo: string;
    imageExtension: string;
    imagePath: string;
    imageUrl: string;
};

const initialState: State = {
    // Defaulting to this for now.
    cultureCode: null,
    languageOptions: null,
};

/* ACTIONS */
const UPDATE_LANGUAGE = 'app/ui/UPDATE_LANGUAGE';
const FETCH_LANGUAGES = 'app/ui/FETCH_LANGUAGES';

type Action =
    | { type: typeof UPDATE_LANGUAGE; payload: Partial<State> }
    | { type: typeof FETCH_LANGUAGES };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

/* EPIC */
const fetchLanguagesEpic = (
    action$: ActionsObservable<{
        type: typeof FETCH_LANGUAGES;
    }>
) => {
    return action$.pipe(
        ofType(FETCH_LANGUAGES),
        switchMap(() =>
            fetchLanguagesFromApi().pipe(
                map((res) =>
                    updateLanguage({
                        cultureCode: res.data.details.language,
                        languageOptions: res.data.details.languageOptions,
                    })
                )
            )
        )
    );
};

export const languageEpic = combineEpics(fetchLanguagesEpic);

const fetchLanguagesFromApi = () => {
    return from(
        instance.get<ApiResponse<{ language: string; languageOptions: LanguageOptions }>>(
            endpoints.languagemodule.languages
        )
    );
};

/* ACTION CREATORS */
export const updateLanguage = (payload: Partial<State>): Action => {
    return {
        type: UPDATE_LANGUAGE,
        payload,
    };
};
export const updateCultureCode = (cultureCode: string): Action => {
    return {
        type: UPDATE_LANGUAGE,
        payload: { cultureCode },
    };
};

export const fetchLanguages = () => {
    return {
        type: FETCH_LANGUAGES,
    };
};

/* SELECTORS */
export const selectCultureCode = (app: Store): string | null => app.language.cultureCode;
