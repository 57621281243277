import { Link, navigate, RouteComponentProps, useLocation } from '@reach/router';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { AppPath } from 'appConstants';
import Icon_Form_01 from 'assets/ibanera/Icon_Form_01.png';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import Page from 'components/page/Page';
import { PasswordHelper } from 'components/passwordHelper/PasswordHelper';
import { PasswordStrengthMeter } from 'components/passwordStrengthMeter/PasswordStrengthMeter';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { useForceSignout } from 'helpers/useForceSignout';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { useTheme } from '@emotion/react';
import * as Yup from 'yup';

interface ResetPasswordQueryParams {
    token: string;
}

interface ResetPasswordFormFields {
    NewPassword: string;
    ConfirmNewPassword: string;
}

const initialValues: ResetPasswordFormFields = {
    NewPassword: '',
    ConfirmNewPassword: '',
};

const validationSchema = Yup.object({
    NewPassword: Yup.string()
        .required('Please enter a new password')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[0-9]/, 'Password must contain a number')
        .matches(/[a-zA-Z]/, 'Password must contain a letter'),
    ConfirmNewPassword: Yup.string()
        .required('Please confirm the given password')
        .test('passwords-match', 'Passwords must match', (value: any, testContext?: any) => {
            return testContext.parent.NewPassword === value;
        }),
});

export const ResetPassword: React.FC<RouteComponentProps> = () => {
    const [formIsComplete, setFormIsComplete] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [showPasswordHelper, setShowPasswordHelper] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>('');
    const { search } = useLocation();
    const cultureCode = useSelector(selectCultureCode);
    const { colors } = useTheme();

    const token = useMemo(() => new URLSearchParams(search).get('token'), [search]);

    useForceSignout();

    // Reach router is stripping query params from the url, not 100% sure why.
    // Storing the token in ref before it gets stripped.

    const handlePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const handleSubmit = (
        values: ResetPasswordFormFields,
        actions: FormikHelpers<ResetPasswordFormFields>
    ) => {
        instance
            .post<ApiResponse>(endpoints.forgottenpasswordmodule.resetpassword, {
                ...values,
                PasswordResetToken: encodeURIComponent(token ?? ''),
            })
            .then((response) => {
                if (response.data.status === '1') {
                    setFormIsComplete(true);
                } else {
                    actions.setSubmitting(false);
                    setErrorMessage(response.data.errors[0].messageCode);
                }
            })
            .catch((response) => {
                actions.setSubmitting(false);
                if (isAxiosErrorHandled(response) && response.response.data?.errors?.length) {
                    const tokenExpiredCodes: Array<keyof typeof ERROR_CODES> = [
                        'Password_Reset_Token_Expired',
                        'Password_Reset_Token_Invalid',
                    ];
                    if (
                        tokenExpiredCodes.includes(
                            response.response.data?.errors[0].messageCode as any
                        )
                    ) {
                        const errorString = getErrorMessage(
                            response.response.data.errors[0].messageCode
                        );
                        const splitString = errorString.split('forgotten password ');
                        setErrorMessage(
                            <>
                                {splitString[0]}{' '}
                                <Link
                                    style={{ color: colors.second }}
                                    to={`/${cultureCode}${AppPath.FORGOTTEN_PASSWORD}`}
                                >
                                    forgotten password
                                </Link>{' '}
                                {splitString[1]}
                            </>
                        );
                        return;
                    }
                    setErrorMessage(getErrorMessage(response.response.data.errors[0].messageCode));
                } else setErrorMessage('Please try again');
            });
    };

    return (
        <Page isPublic={true}>
            <div className="ForgottenPasswordPage WidthContent">
                {!formIsComplete ? (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting, setFieldTouched, setFieldValue }) => (
                            <Form className="ForgottenPasswordForm">
                                <h1>Password Reset</h1>
                                <p>Enter your new password</p>
                                <div className="FormBox PasswordField">
                                    <FormTextField
                                        field={'NewPassword'}
                                        label={'New Password'}
                                        required={true}
                                        maxLength={20}
                                        type={passwordVisibility ? 'text' : 'password'}
                                        showIcon={true}
                                        icon={Icon_Form_01}
                                        hint="8+ characters, must contain numbers and letters"
                                        onClickIcon={handlePasswordVisibility}
                                        onBlur={(e) => {
                                            setFieldValue(
                                                'NewPassword',
                                                e.target.value.trim(),
                                                true
                                            );
                                            setFieldTouched('NewPassword', true);
                                        }}
                                    />
                                    <PasswordStrengthMeter
                                        fieldName="NewPassword"
                                        onInfoClick={() => setShowPasswordHelper((s) => !s)}
                                        userInputs={['ibanera']}
                                    />
                                    {showPasswordHelper && (
                                        <PasswordHelper
                                            fieldName="NewPassword"
                                            userInputs={['ibanera']}
                                        />
                                    )}
                                </div>
                                <FormTextField
                                    field={'ConfirmNewPassword'}
                                    label={'Confirm New Password'}
                                    required={true}
                                    maxLength={200}
                                    type="password"
                                    onBlur={(e) => {
                                        setFieldValue(
                                            'ConfirmNewPassword',
                                            e.target.value.trim(),
                                            true
                                        );
                                        setFieldTouched('ConfirmNewPassword', true);
                                    }}
                                />
                                {errorMessage && <p className="ErrorLabel">{errorMessage}</p>}
                                <Button
                                    variety="full"
                                    priority="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <Spinner /> : 'Reset password'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="SuccessMessage">
                        <h1>Thank you</h1>
                        <p>Your password has been reset</p>
                        <Button
                            variety="full"
                            priority="primary"
                            onClick={() => navigate(AppPath.SIGN_IN)}
                        >
                            Go to Sign In
                        </Button>
                    </div>
                )}
            </div>
        </Page>
    );
};
