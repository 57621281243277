import { globalHistory, Link } from '@reach/router';
import Button from 'components/button/Button';
import { AccountList } from 'components/sideMenu/AccountList';
import { AccountLinksSection, SideMenuLink } from 'components/sideMenu/SideMenu';
import { endpoints } from 'endpoints.config';
import { formatPriceWithLocale } from 'helpers/formatPriceWithCommas';
import { useBasePath } from 'helpers/useBasePath';
import { plaintext } from 'plaintext.config';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { ModalTypes, openModal } from 'reducers/modal';

type SignedInSubMenuProps = {
    pageLink: SideMenuLink | undefined;
    closeMenu: () => void;
    accountLinks?: SideMenuLink[];
    extraAccountLinks?: SideMenuLink[];
};
export const SignedInSubMenu = ({
    pageLink,
    closeMenu,
    accountLinks,
    extraAccountLinks,
}: SignedInSubMenuProps) => {
    const cultureCode = useSelector(selectCultureCode);
    const basePath = useBasePath();
    const { customerMenuLinks } = useSelector(selectComponentResources);

    const dispatch = useDispatch();
    const openDepositCryptoModal = () =>
        dispatch(
            openModal({
                modalType: ModalTypes.DEPOSIT_CRYPTO,
                data: { crypto: null, depositEndpoint: endpoints.cryptoExchange.getDepositAddress },
            })
        );

    const canAddFiatAccount = false; /* useMemo(
        () =>
            pageLink?.elementType === 'Accounts' &&
            customerMenuLinks?.some((link) => link.path === 'accounts/addaccount'),
        [pageLink?.elementType, customerMenuLinks]
    ); */

    useEffect(() => {
        return globalHistory.listen(({ action }) => {
            if (action === 'PUSH' || action === 'POP') {
                closeMenu();
            }
        });
    });
    if (!pageLink) return null;
    return (
        <div className="MoreMenu">
            <h3>{pageLink?.displayName}</h3>
            <div className="PageLinks">
                {pageLink.elementType === 'FundingAccounts' ||
                pageLink.elementType === 'Accounts' ? (
                    <AccountLinksSection
                        link={accountLinks?.[0]}
                        extraLinks={extraAccountLinks}
                        hideHeading
                        cultureCode={cultureCode}
                        isLinkActive={() => false}
                    />
                ) : (
                    <AccountList
                        link={pageLink!}
                        isLinkActive={() => false}
                        cultureCode={cultureCode}
                    />
                )}
                {canAddFiatAccount && (
                    <div>
                        {/* We need this wrapper div to not interfere with the last-of-type for links */}
                        <Link to={`${basePath}/accounts/addaccount`} className="ButtonLink">
                            <Button
                                className="MobileSubMenuButton"
                                priority="secondary"
                                onClick={() => {}}
                            >
                                {plaintext.sidemenu.addAccount}
                            </Button>
                        </Link>
                    </div>
                )}
                {pageLink.elementType === 'Cards' &&
                    (!pageLink.accounts || pageLink.accounts.length < 1) && (
                        <div>
                            {/* We need this wrapper div to not interfere with the last-of-type for links */}
                            <Link to={`${basePath}/accounts/addaccount`} className="ButtonLink">
                                <Button
                                    className="MobileSubMenuButton"
                                    priority="secondary"
                                    onClick={() => {}}
                                >
                                    {plaintext.sidemenu.addAccount}
                                </Button>
                            </Link>
                        </div>
                    )}
                {pageLink.elementType === 'Crypto' && (
                    <div>
                        {/* We need this wrapper div to not interfere with the last-of-type for links */}
                        <Link to={`${basePath}/${pageLink.path}`} className="ButtonLink">
                            <Button
                                className="MobileSubMenuButton"
                                priority="primary"
                                onClick={() => {}}
                            >
                                Trade crypto
                            </Button>
                        </Link>

                        <Button
                            className="MobileSubMenuButton"
                            priority="secondary"
                            onClick={openDepositCryptoModal}
                            style={{ marginTop: 10 }}
                        >
                            Deposit crypto
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
