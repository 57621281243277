import { produce } from 'immer';
import { Store } from './rootReducer';

type State = {
    refreshTransactions: boolean;
};

const initialState: State = {
    refreshTransactions: false,
};

/* ACTIONS */
const REFRESH_TRANSACTIONS = 'app/utility/REFRESH_TRANSACTIONS';

type Action = { type: typeof REFRESH_TRANSACTIONS };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
    return produce(state, (draft) => {
        switch (action.type) {
            case REFRESH_TRANSACTIONS:
                draft.refreshTransactions = !state.refreshTransactions;
                break;
        }
    });
}

/* ACTION CREATORS */
export const refreshTransactions = (): Action => ({
    type: REFRESH_TRANSACTIONS,
});
/* SELECTORS */

export const selectRefreshTransactionFlag = (app: Store) => app.utility.refreshTransactions;
