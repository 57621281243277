import { Store } from './rootReducer';

/* STATE */
type State = {
    hasInfoRequest: boolean;
    refresh: boolean;
};

const initialState: State = {
    hasInfoRequest: true,
    refresh: false,
};

/* ACTIONS */
const UPDATE_HAS_INFO_REQUEST = 'app/info-request/UPDATE_HAS_INFO_REQUEST';
const REFRESH_TABLE = 'app/info-request/REFRESH_TABLE';

type Action =
    | { type: typeof UPDATE_HAS_INFO_REQUEST; payload: boolean }
    | { type: typeof REFRESH_TABLE };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UPDATE_HAS_INFO_REQUEST:
            return {
                hasInfoRequest: action.payload,
                refresh: action.payload ? !state.refresh : state.refresh,
            };
        case REFRESH_TABLE:
            return {
                ...state,
                refresh: !state.refresh,
            };
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const updateHasInfoRequest = (payload: boolean): Action => {
    return {
        type: UPDATE_HAS_INFO_REQUEST,
        payload,
    };
};
export const refreshInfoRequestTable = (): Action => {
    return {
        type: REFRESH_TABLE,
    };
};

/* SELECTORS */
export const selectHasInfoRequest = (app: Store): boolean => app.infoRequest.hasInfoRequest;
export const selectRefreshInfoRequest = (app: Store): boolean => app.infoRequest.refresh;
