import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { SelectOption } from 'components/categoryComponents/dropdown/Dropdown';
import { PanelButtons } from 'components/categoryHelpers/panelHelpers/PanelButtons';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Formik, Form, FormikHelpers, FormikValues } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

type Props = {
    manageeCardsId: number;
    onDone: () => void;
    manageeCardsIds: number[];
    isBatchTransfer: boolean;
};

type MoveFundsPayload = {
    type: string;
    managerCardsId: number;
    manageeCardsId: number;
    amount: number;
};
type MoveFundsMeta = {
    moveFundsTypes: SelectOption[];
    availableManagerCards?: SelectOption[];
};

type MoveFundsResponse = MoveFundsPayload & MoveFundsMeta;

const validationSchema = Yup.object({
    type: Yup.string().required('Please select a transfer type'),
    managerCardsId: Yup.number()
        .required('Please select a card to deposit from/withraw to')
        .typeError('Please select a card to deposit from/withraw to'),
    amount: Yup.number()
        .required('Please enter an amount')
        .typeError('Please enter a numeric amount')
        .moreThan(0, 'Please enter an amount greater than 0'),
});

export const MoveFunds = ({ manageeCardsId, onDone, manageeCardsIds, isBatchTransfer }: Props) => {
    const [metaData, setMetaData] = useState<MoveFundsResponse | null>(null);

    useEffect(() => {
        instance
            .get<ApiResponse<MoveFundsPayload & MoveFundsMeta>>(
                endpoints.cardManagementModule.moveFunds,
                { params: { manageeCardsId } }
            )
            .then((res) => {
                setMetaData(res.data.details);
            })
            .catch((err) => {
                Toast.openGenericErrorToast();
                setMetaData(null); //TODO handle error
                onDone();
            });
    }, [manageeCardsId, onDone]);

    const handleSubmit = (
        values: MoveFundsResponse,
        formikHelpers: FormikHelpers<MoveFundsResponse>
    ) => {
        instance
            .post(
                isBatchTransfer
                    ? endpoints.cardManagementModule.moveFundsBatch
                    : endpoints.cardManagementModule.moveFunds,
                isBatchTransfer ? { ...values, manageeCardsIds } : values
            )
            .then((res) => {
                //if success:
                formikHelpers.setSubmitting(false);
                onDone();
                return;
            })
            .catch((err) => {
                if (isAxiosErrorHandled(err) && err.response.data.errors) {
                    //iterate through the errors returned by the api and set error messages on the appropriate fields

                    err.response.data.errors.forEach((error) => {
                        const fieldName = toCamelCase(error.fieldName) as string;
                        if (
                            fieldName.length === 0 &&
                            (error.messageCode === 'InternalError' ||
                                (error as any).errorType === 'InternalServerError')
                        ) {
                            Toast.openToastMessage(
                                'Cannot process the money transfer. Please try it again later.',
                                1
                            );
                        } else if (Object.keys(values).includes(fieldName)) {
                            formikHelpers.setFieldError(
                                fieldName,
                                getErrorMessage(error.messageCode)
                            );
                        }
                    });
                } else {
                    Toast.openGenericErrorToast();
                }
                formikHelpers.setSubmitting(false);
            });
    };
    if (!metaData) return <Spinner />;

    return (
        <Formik
            initialValues={{ ...metaData, type: '', amount: 0 }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <div className="Content">
                    <div className="Form">
                        <WrappedFormSingleSelectField
                            label="Transfer Type"
                            fieldName={'type'}
                            options={metaData.moveFundsTypes}
                        />
                        <WrappedFormSingleSelectField
                            label="Manager Card"
                            fieldName={'managerCardsId'}
                            options={metaData.availableManagerCards ?? []}
                            containerStyle={{ marginTop: 20 }}
                        />
                        <FormTextField
                            field={'amount'}
                            label={'Amount'}
                            required={true}
                            formSideBySide={true}
                            inputMode="decimal"
                        />
                    </div>
                    <PanelButtons close={onDone} submitButtonText="Transfer" />
                </div>
            </Form>
        </Formik>
    );
};
