import React, { useEffect, useRef, useState } from 'react';

export const CoinGeckoWidget = ({
    pageLoaded,
    selectedCrypto,
    currency,
    locale = 'en',
}: {
    pageLoaded: boolean;
    selectedCrypto: string;
    currency: string;
    locale?: string;
}) => {
    /* selectedCrypto: string,
    currency: string,
    locale: string */
    const ref = useRef<HTMLDivElement | null>(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widgets.coingecko.com/coingecko-coin-compare-chart-widget.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            setScriptLoaded(true);
            //setTimeout(() => , 1000); //find a better solution to this
        });

        return () => {
            script.remove();
        };
    }, []);
    useEffect(() => {
        if (ref.current === null || !scriptLoaded || !pageLoaded) return;
        //debugger;
        const widget = document.createElement('coingecko-coin-compare-chart-widget');
        widget.setAttribute('coin-ids', selectedCrypto.replaceAll(' ', '-')); //TODO set programatticaly
        widget.setAttribute('currency', currency); //TODO set programatticaly
        widget.setAttribute('locale', locale); //TODO set programatticaly
        ref.current.appendChild(widget);

        return () => widget.remove();
    }, [scriptLoaded, pageLoaded, selectedCrypto, locale, currency]);

    return <div className="CoinGeckoWidget" ref={ref}></div>;
};
