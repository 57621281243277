import React from 'react';
import classNames from 'classnames';
import { css, useTheme } from '@emotion/react';
import { initialTheme } from '../../theme';

/** @jsxImportSource @emotion/react */

type ButtonVariety = 'full' | 'small' | 'blue' | 'slim' | '';
// primary = background color and white text
// secondary = transparent background with border
// tertiary = no border and no background, just text
type ButtonPriority = 'primary' | 'secondary' | 'tertiary';

type ColourOptions = 'first' | 'second' | 'third' | 'grey';

type ButtonType = JSX.IntrinsicElements['button'] & {
    variety?: ButtonVariety;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: string;
    iconClassName?: string;
    priority?: ButtonPriority;
    // color?: keyof typeof initialTheme.colors | 'grey';
    color?: ColourOptions;
    icon?: string;
};

function varietyToClassname(v: ButtonVariety): string {
    switch (v) {
        case 'full':
            return 'BtnFull';
        case 'small':
            return 'BtnSmall';
        case 'slim':
            return 'BtnSlim';
        default:
            return '';
    }
}

const Button: React.FC<ButtonType> = ({
    variety = '',
    priority = 'primary',
    className,
    iconClassName,
    icon,
    color = 'first',
    children,
    ...props
}) => {
    const theme = useTheme();

    const buttonCSS =
        color === 'grey'
            ? css`
                  background: transparent;
                  border: 1px solid #d0d0d0;
                  color: ${theme.colors.headingColor};
              `
            : priority === 'primary'
            ? css`
                  background: ${theme.colors[color as keyof typeof initialTheme.colors]};
                  border: 1px solid ${theme.colors[color as keyof typeof initialTheme.colors]};

                  &:hover {
                      background: ${theme.colors[
                          `${color}Hover` as keyof typeof initialTheme.colors
                      ]};
                  }
              `
            : priority === 'secondary'
            ? css`
                  background-color: transparent;
                  border: 1px solid ${theme.colors[color as keyof typeof initialTheme.colors]};
                  color: ${theme.colors[color as keyof typeof initialTheme.colors]};

                  &:hover {
                      opacity: 0.8;
                  }
              `
            : priority === 'tertiary'
            ? css`
                  background-color: transparent;
                  border: 0;
                  color: ${theme.colors[color as keyof typeof initialTheme.colors]};

                  &:hover {
                      opacity: 0.8;
                  }
              `
            : null;

    return (
        <button
            css={buttonCSS}
            className={classNames('Btn', varietyToClassname(variety), className)}
            {...props}
        >
            {children}
            {icon ? (
                <img src={icon} alt="icon" className={iconClassName ? iconClassName : ''} />
            ) : null}
        </button>
    );
};

export default Button;
