import Button from 'components/button/Button';
import UploadIcon from 'assets/ibanera/Icon_Upload.png';
import { useField, useFormikContext } from 'formik';
import { useResetableDropzone } from 'helpers/useResetableDropzoneBusinessForm';
import { useEffect, useState } from 'react';
import { ErrorM } from 'components/form/ErrorM';
import RedX from 'assets/ibanera/Icon_RedCross.png';
import { AxiosRequestConfig } from 'axios';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { endpoints } from 'endpoints.config';
import { getErrorMessage, ERROR_CODES } from 'errors';
import React from 'react';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { FileError } from 'react-dropzone';

export type multiFile = {
    number: number;
    id: number;
    fileName: string;
};
interface Props {
    fieldName: string;
    displayError?: boolean;
    multi?: boolean;
    jsonField?: boolean;
    onChange?: () => void;
}
const UploadField: React.FC<Props> = ({
    fieldName,
    displayError,
    multi = false,
    jsonField = false,
    onChange,
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [storedFiles, setStoredFiles] = useState<string[] | multiFile[] | null>([]);
    const [submiting, setSubmit] = useState<boolean>(false);
    const [, meta, helpers] = useField<Array<any> | string>(fieldName);
    const { values } = useFormikContext<any>();

    const saveFile = async (files: File[]) => {
        if (!files || files.length < 1) return setErrorMessage('Please upload a file');
        setSubmit(true);
        setErrorMessage('');
        const formData = new FormData();
        files.forEach((file) => formData.append('File', file));

        const config: AxiosRequestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        try {
            const res = await instance.post(
                endpoints.businessVerificationV3Module.uploadFormDocument,
                formData,
                config
            );
            if (res.data.status === '1' && res.status === 200) {
                setSubmit(false);
                onChange && onChange();
                return true;
            }
        } catch (err) {
            let translatedErrorMessage = getErrorMessage('Generic');
            // Generic error
            if (isAxiosErrorHandled(err) && err.response.data.errors) {
                err.response.data.errors.forEach((error) => {
                    if (error.messageCode in ERROR_CODES)
                        translatedErrorMessage = getErrorMessage(error.messageCode);
                });
            }
            if (isAxiosErrorHandled(err) && err.response.status === 413) {
                translatedErrorMessage = 'File too large';
            }
            setErrorMessage(translatedErrorMessage);
            setSubmit(false);
            return false;
        }
    };
    const { acceptedFiles, getRootProps, getInputProps, resetFiles, setAcceptedFiles } =
        useResetableDropzone(
            {
                multiple: multi,
                accept: 'application/pdf',
                validator: (file) => {
                    // https://stackoverflow.com/questions/20690499/concrete-javascript-regular-expression-for-accented-characters-diacritics
                    var validFilename =
                        /^[ a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F0-9_.@()-]+\.[^.]+$/i.test(
                            file.name
                        );
                    if (!validFilename)
                        return {
                            message: `Invalid file name`,
                        } as FileError;

                    if (file.name.length > 200)
                        return {
                            message: `Filename too long. Cannot be greater than 200 characters`,
                        } as FileError;

                    return null;
                },
                onDropRejected: (rejections) => {
                    if (
                        rejections.some((reject) =>
                            reject.errors.some((error) => error.code === 'file-invalid-type')
                        )
                    ) {
                        setErrorMessage('File type must be .pdf');
                    } else if (
                        rejections.length > 0 &&
                        rejections[0].errors.length > 0 &&
                        rejections[0].errors[0].message
                    ) {
                        setErrorMessage(rejections[0].errors[0].message);
                    } else setErrorMessage('File rejected - please upload a valid .pdf file');
                },
            },
            fieldName,
            values,
            setErrorMessage,
            meta,
            helpers,
            jsonField,
            saveFile
        );
    const { setFieldTouched } = useFormikContext<any>();

    const deleteFile = (fileName: string) => {
        //call endpiont to delete then remove from list
        (async () => {
            try {
                let response = await instance.post<ApiResponse>(
                    endpoints.businessVerificationV3Module.deleteFormDocument,
                    { fileName: fileName }
                );
                if (response.data.status === '1') {
                    let filteredList = acceptedFiles.filter((file) => file.name !== fileName);
                    setAcceptedFiles([...filteredList]);

                    let fieldFilteredList =
                        typeof meta.value === 'string'
                            ? ''
                            : meta.value
                                  .filter((file: multiFile) => file.fileName !== fileName)
                                  .map((file: multiFile, index: number) => ({
                                      ...file,
                                      number: index + 1,
                                  }));
                    helpers.setValue(fieldFilteredList);
                    onChange && onChange();
                } else {
                    Toast.openToastMessage(
                        'Problem deleting file, please try again',
                        ToastMessageReason.ERROR
                    );
                }
            } catch (error) {
                if (isAxiosErrorHandled(error) && error.response.data.errors) {
                    error.response.data.errors.forEach((err) => {
                        if (err.messageCode === 'File_Does_Not_Exist') {
                            let filteredList = acceptedFiles.filter(
                                (file) => file.name !== fileName
                            );
                            setAcceptedFiles([...filteredList]);

                            let fieldFilteredList =
                                typeof meta.value === 'string'
                                    ? ''
                                    : meta.value
                                          .filter((file: multiFile) => file.fileName !== fileName)
                                          .map((file: multiFile, index: number) => ({
                                              ...file,
                                              number: index + 1,
                                          }));
                            helpers.setValue(fieldFilteredList);
                            onChange && onChange();
                        } else {
                            Toast.openToastMessage(
                                'Problem deleting file, please try again',
                                ToastMessageReason.ERROR
                            );
                        }
                    });
                } else {
                    Toast.openToastMessage(
                        'Problem deleting file, please try again',
                        ToastMessageReason.ERROR
                    );
                }
            }
        })();
    };
    const deleteFiles = (files: string[] | multiFile[]) => {
        for (const file of files) {
            typeof file === 'string' ? deleteFile(file) : deleteFile(file.fileName);
        }
    };

    useEffect(() => {
        if (Array.isArray(meta.value)) {
            setStoredFiles([...meta.value]);
        } else if (meta.value && meta.value !== '') {
            setStoredFiles([meta.value]);
        } else {
            setStoredFiles(null);
        }
    }, [meta.value]);

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    return (
        <div className="UploadDocumentModal">
            <div
                {...getRootProps({
                    className: `Dropzone ${meta.error && meta.touched ? 'ErrorInput' : ''}`,
                })}
                onBlur={() => setFieldTouched(fieldName, true)}
            >
                <input {...getInputProps()} />
                {!multi &&
                storedFiles &&
                storedFiles.length !== 0 &&
                typeof storedFiles[0] === 'string' ? (
                    (storedFiles as unknown as string[]).map((file: string, index: number) => (
                        <span key={file}>{file}</span>
                    ))
                ) : (
                    <>
                        <img src={UploadIcon} alt="upload" />
                        <h5>PDF file</h5>
                        <span>Click or drag to upload</span>
                    </>
                )}
            </div>
            <div className="DropzoneFooter">
                <span>Accepts - .pdf</span>
                {storedFiles && storedFiles.length > 0 && (
                    <Button
                        type="button"
                        priority="secondary"
                        className="ResetBtn"
                        onClick={() => {
                            deleteFiles(storedFiles);
                            resetFiles();
                        }}
                        disabled={submiting}
                    >
                        Reset
                    </Button>
                )}
            </div>
            {displayError && <ErrorM name={fieldName} />}
            {errorMessage && <p className="ErrorText NoMargin">{errorMessage}</p>}
            {storedFiles &&
                storedFiles.length > 0 &&
                multi &&
                typeof storedFiles[0] === 'object' &&
                (storedFiles as unknown as multiFile[]).map((file: multiFile, index: number) => (
                    <React.Fragment key={file.fileName + index}>
                        <div className="DocumentListContainer">
                            <p>{file.fileName}</p>
                            <img
                                src={RedX}
                                alt="delete"
                                onClick={() => deleteFile(file.fileName)}
                            />
                        </div>
                        <ErrorM
                            name={`corporate.authorizedCapital.supportingDocuments[${index}].fileName`}
                        />
                    </React.Fragment>
                ))}
        </div>
    );
};

export default UploadField;
