import instance, { TableInfo, useTable } from 'api';
import { FlexTable } from 'components/flexTable';
import Pagination from 'components/pagination/Pagination';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { localizedFormatDate, registerUserLocale } from 'helpers/language';
import { plaintext } from 'plaintext.config';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { useState } from 'react';
import { useEffect } from 'react';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { formatPriceWithLocale } from 'helpers/formatPriceWithCommas';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { DateRangePicker } from 'components/dateRangePicker/DateRangePicker';
import { ModalTypes, openModal, selectModalState } from 'reducers/modal';
import Button from 'components/button/Button';
import { CSVLink } from 'react-csv';
import { Toast } from 'helpers/toast';
import { TableEntryInfoPanel } from '../TableEntryInfoPanel/TableEntryInfoPanel';
import { useWindowDimensions } from '../../helpers/useWindowDimensions';
import styles from '../TableEntryInfoPanel/TableEntryInfoPanel.module.scss';
import { useTheme } from '@emotion/react';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { FoldingCube } from 'better-react-spinkit';
import { NameWithInitial } from '../../pages/InfoRequest/InfoRequest';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { selectComponentResources } from 'reducers/componentResources';
import { isCryptoLink } from 'components/sideMenu/AccountList';
import { useIsFundingAccount } from 'helpers/useGetAccountDetails';
import { selectRefreshTransactionFlag } from '../../reducers/utility';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';

export type Props = {
    accountId: string;
    pageType: 'TopUpCard' | 'AccountTransactions';
};

export type TransactionDataRow = {
    customerAssetAccountsTransactions__Id: number;
    customerAssetAccountsTransactions__Date: string;
    transactionAmounts__Balance: number;
    transactionAmounts__bInTransaction: boolean;
    customerAssetAccountsTransactions__bPending: boolean;
    customerAssetAccountsTransactions__bReserved: boolean;
    customerAssetAccountsTransactions__bFrozen: boolean;
    customerAssetAccountsTransactions__TransactionType: TransactionType;

    customerAssetAccountsExternalTransfers__Name: string;
    customerAssetAccountsTransactions__Amount: number;
    customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode: string;
    transactionTypes__Type: null;
    transactionAmounts__ApprovalStatus: 'Approval' | 'Approved' | 'Rejected' | 'Refunded';
    transactionAmounts__bEligibleForReverse: boolean;
    /* This just provides an indication if it could be cancellable. A more complex endpoint is
    called to calc this if this is true when the side panel is opened */
    bCancellable: boolean;
};

const checkIsPending = (row?: TransactionDataRow) =>
    row?.transactionAmounts__ApprovalStatus !== 'Approval' &&
    !!(
        row?.customerAssetAccountsTransactions__bPending ||
        row?.customerAssetAccountsTransactions__bReserved ||
        row?.customerAssetAccountsTransactions__bFrozen
    );

type TransactionType =
    | 'Buy'
    | 'Sell'
    | 'Deposit'
    | 'Withdraw'
    | 'Fee'
    | 'Refund'
    | 'Transfer'
    | 'Adjustment'
    | 'TopUp'
    | 'BalanceCorrection'
    | 'Chargeback'
    | 'Mint';

const idColumn = 'customerAssetAccountsTransactions__Id';

export const Transactions: React.FC<Props> = ({ accountId, pageType }) => {
    const modalState = useSelector(selectModalState);
    const cultureCode = useSelector(selectCultureCode);
    const update = useSelector(selectUIUpdate);
    const refreshTransactionsFlag = useSelector(selectRefreshTransactionFlag);

    const { customerMenuLinks } = useSelector(selectComponentResources);
    const hasDusdAccount = useMemo(
        () =>
            customerMenuLinks?.some(
                (link) =>
                    link.elementType === 'CryptoAsAccounts' &&
                    link.accounts &&
                    link.accounts.some(
                        (account) => isCryptoLink(account) && account.code === 'dUSD'
                    )
            ) ?? false,
        [customerMenuLinks]
    );

    const { colors } = useTheme();

    const [selectedRow, setSelectedRow] = useState<number>();
    const [currentDates, setCurrentDates] = useState({ fromDate: '', toDate: '' });

    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const transactionsTable = useTable<TransactionDataRow, any>({
        url:
            pageType === 'TopUpCard'
                ? `${endpoints.cards.cardTransactionList}?topUpCardsId=${accountId}`
                : `${endpoints.accounts.accountTransactionList}?customerAssetAccountsId=${accountId}`,
        bClearFilters: true,
    });
    const { loading, data, reload } = transactionsTable;

    useAfterMountEffect(() => {
        reload();
    }, [refreshTransactionsFlag]);

    const currencySymbol = data?.details.metadata.CurrencySymbol;

    const setFilter = (startDate: Date, endDate: Date) => {
        setCurrentDates({ fromDate: startDate.toISOString(), toDate: endDate.toISOString() });
        data?.actions.changeFilter('customerAssetAccountsTransactions__Date', [
            {
                columnKey: 'customerAssetAccountsTransactions__Date',
                operator: 'BETWEEN',
                value: `${startDate.toISOString()}TO${endDate.toISOString()}`,
            },
        ]);
    };

    const accountLink = useGetFiatAccountDetails();
    const isFundingAccount = useIsFundingAccount();

    const openBuyDusdModal = () => {
        if (!accountLink) return;
        dispatch(
            openModal({
                modalType: ModalTypes.BUY_DUSD,
                data: { sourceAssetAccount: accountLink },
            })
        );
    };

    const onRowClick = (id: number) => {
        if (selectedRow === id) setSelectedRow(undefined);
        else setSelectedRow(id);
    };

    useEffect(() => {
        if (width >= 1400 || !selectedRow) return;
        else
            dispatch(
                openModal({
                    modalType: ModalTypes.TRANSACTION_DETAILS,
                    data: {
                        rowData: {
                            id: selectedData!.customerAssetAccountsTransactions__Id,
                            amount: selectedData!.customerAssetAccountsTransactions__Amount,
                            moneyIn: selectedData!.transactionAmounts__bInTransaction,
                            reference:
                                selectedData!
                                    .customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode,
                            isPending: checkIsPending(selectedData!),
                            approvalStatus: selectedData!.transactionAmounts__ApprovalStatus,
                            bEligibleForReverse:
                                selectedData!.transactionAmounts__bEligibleForReverse,
                            bCancellable: selectedData!.bCancellable,
                        },
                        currencySymbol,
                    },
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, width]);
    useEffect(() => {
        if (modalState.modalType) return;
        else setSelectedRow(undefined);
    }, [modalState]);

    const selectedData = transactionsTable.data?.details.listData.find(
        (entry) => entry[idColumn] === selectedRow
    );

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.RELOAD_TRANSACTIONS_TABLE) {
            transactionsTable.reload();
            dispatch(completeUIUpdate());
        }
        if (update?.pushType === NotificationIdentifier.UPDATE_TRANSACTION) {
            const listData = transactionsTable.data?.details.listData;
            if (listData) {
                const newListData = listData.map((row) => ({
                    ...row,
                    customerAssetAccountsTransactions__bPending:
                        update.data.customerAssetAccountsTransactionsId ===
                        row.customerAssetAccountsTransactions__Id
                            ? update.data.bPending
                            : row.customerAssetAccountsTransactions__bPending,
                }));
                transactionsTable.data?.actions.updateLocalTable(idColumn, newListData, listData);
            }
            dispatch(completeUIUpdate());
        }
        if (update?.pushType === NotificationIdentifier.ADD_TRANSACTION_NOTE) {
            const listData = transactionsTable.data?.details.listData;
            if (listData) {
                const newListData = listData.map((row) =>
                    row.customerAssetAccountsTransactions__Id === update.data.transactionId
                        ? { ...row, customerAssetAccountsTransactions__Note: update.data.note }
                        : row
                );
                transactionsTable.data?.actions.updateLocalTable(idColumn, newListData, listData);
            }
        }
    }, [update, transactionsTable, dispatch]);

    //TODO put this somewhere sensible
    useEffect(() => {
        registerUserLocale(cultureCode || 'en-GB');
    }, [cultureCode]);

    const rowFormatter = (row: TransactionDataRow) => {
        let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };
        if (formattedRow.customerAssetAccountsTransactions__Date) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Date: localizedFormatDate(
                    new Date(row.customerAssetAccountsTransactions__Date),
                    cultureCode || undefined,
                    'dd - MM - yyyy'
                ),
            };
        }
        if (formattedRow.transactionAmounts__Balance) {
            formattedRow = {
                ...formattedRow,
                transactionAmounts__Balance:
                    data?.details.metadata.CurrencySymbol +
                    formatPriceWithLocale(row.transactionAmounts__Balance, cultureCode ?? 'en-GB'),
            };
        }
        if (formattedRow.customerAssetAccountsExternalTransfers__Name != null) {
            const desc = row.customerAssetAccountsExternalTransfers__Name;
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsExternalTransfers__Name: (
                    <NameWithInitial name={desc} moneyIn={row.transactionAmounts__bInTransaction} />
                ),
            };
        }
        if (formattedRow.customerAssetAccountsTransactions__Amount) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Amount: (
                    <span
                        className={row.transactionAmounts__bInTransaction ? 'MoneyIn' : 'MoneyOut'}
                    >
                        {insertSymbol(
                            row.customerAssetAccountsTransactions__Amount,
                            data?.details.metadata.CurrencySymbol,
                            cultureCode ?? 'en-GB'
                        )}
                    </span>
                ),
            };
        }
        if (formattedRow.transactionTypes__Type === null) {
            const showPending = checkIsPending(row);
            const showApprovalStatus =
                !showPending &&
                row.transactionAmounts__ApprovalStatus &&
                row.transactionAmounts__ApprovalStatus !== 'Approved';

            formattedRow = {
                ...formattedRow,
                transactionTypes__Type: row.transactionAmounts__bInTransaction ? (
                    <div className="OrderType">
                        <IconMoneyIn width={20} />
                        {showPending && <span className="Pending Pending--in">Pending</span>}
                        {showApprovalStatus && (
                            <span className="Pending Pending--in">
                                {row.transactionAmounts__ApprovalStatus}
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="OrderType">
                        <IconMoneyOut width={20} fill={colors.third} />
                        {showPending && <span className="Pending Pending--out">Pending</span>}
                        {showApprovalStatus && (
                            <span className="Pending Pending--out">
                                {row.transactionAmounts__ApprovalStatus}
                            </span>
                        )}
                    </div>
                ),
            };
        }

        return formattedRow;
    };
    const [csvData, setCsvData] = useState<string | null>(null);

    if (loading && !data) {
        return <SubPageLoader message={plaintext.accounts.loadingTransactions} />;
    }
    const emptyTable = transactionsTable?.data?.details.listData.length === 0;
    return (
        <div className="TransactionsPage">
            <div className="TableHeader">
                <div className="LeftHeader">
                    <DateRangePicker
                        currentDates={currentDates}
                        setFilter={setFilter}
                        cultureCode={cultureCode}
                    />
                    {pageType === 'AccountTransactions' && (
                        <Button
                            className="DownloadCsvBtn"
                            onClick={() =>
                                getCsv(accountId, currentDates.fromDate, currentDates.toDate).then(
                                    (data) => {
                                        setCsvData(formatCsvData(data));
                                        setImmediate(() => setCsvData(null));
                                    }
                                )
                            }
                        >
                            Download CSV
                        </Button>
                    )}
                    {csvData && (
                        <CSVDownload
                            data={csvData}
                            filename={`Transaction_History_${displayDateRange(
                                currentDates.fromDate,
                                currentDates.toDate
                            )}`}
                            target="_blank"
                        />
                    )}
                    {accountLink &&
                        accountLink.currencyCode === 'USD' &&
                        !isFundingAccount &&
                        hasDusdAccount && (
                            <Button priority="secondary" onClick={openBuyDusdModal}>
                                Convert to <AssetCodeFormatter assetCode="dUSD" />
                            </Button>
                        )}
                </div>
                <SearchBox
                    key={'searchBox'}
                    placeholder={plaintext.accounts.searchTransactions}
                    initialSearchString={data?.details.searchString ?? ''}
                    changeSearch={data?.actions.changeSearch}
                    goToPage={data?.actions.goToPage}
                />
            </div>
            <div className="TableWrapper">
                <FlexTable
                    idColumn={idColumn}
                    selectedIds={selectedRow ? [selectedRow] : []}
                    table={transactionsTable as TableInfo}
                    rowFormatter={rowFormatter}
                    onRowClick={onRowClick}
                    rowCellOptions={{
                        transactionTypes__Type: {
                            style: {
                                minWidth: '120px',
                                width: '120px',
                                flex: '0',
                            },
                        },
                        _button: {
                            style: {
                                minWidth: '250px',
                                width: '250px',
                                flex: '0',
                                padding: '0 10 0 0',
                            },
                        },
                    }}
                    buttonInMiddleTable
                />
                {!emptyTable && (
                    <div className={styles.TableEntryInfoPanelWrapper}>
                        <TableEntryInfoPanel
                            selectedRowData={
                                selectedData
                                    ? {
                                          id: selectedData.customerAssetAccountsTransactions__Id,
                                          amount: selectedData.customerAssetAccountsTransactions__Amount,
                                          moneyIn: selectedData.transactionAmounts__bInTransaction,
                                          reference:
                                              selectedData.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode,
                                          isPending: checkIsPending(selectedData),
                                          approvalStatus:
                                              selectedData.transactionAmounts__ApprovalStatus,
                                          bEligibleForReverse:
                                              selectedData.transactionAmounts__bEligibleForReverse,
                                          bCancellable: selectedData.bCancellable,
                                      }
                                    : undefined
                            }
                            closePanel={() => setSelectedRow(undefined)}
                            currencySymbol={currencySymbol}
                        />
                    </div>
                )}
            </div>
            {loading && (
                <div className="Loading">
                    <FoldingCube color={colors.first} size={80} />
                </div>
            )}
            {emptyTable && !transactionsTable?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">{plaintext.tables.emptyTransactionTable}</h3>
                </div>
            )}
            <Pagination table={transactionsTable} />
        </div>
    );
};

const getCsv = async (customerAssetAccountsId: string, fromDate: string, toDate: string) => {
    const params: any = { customerAssetAccountsId };
    if (fromDate) params.fromDate = fromDate;
    if (toDate) params.toDate = toDate;
    try {
        const res = await instance.get(endpoints.accounts.accountTransactionCsv, { params });
        return res.data;
    } catch (err) {
        Toast.openGenericErrorToast();
        console.error(err);
    }
};
const formatCsvData = (data: string) => {
    if (!data) return null;
    //splits the first row of data from the string, replaces all __ in that row with spaces and then concatenates the rest back on
    return data.replaceAll('\r', '').replace(/^(.*\n)((.*\n?)*)$/, (match, p1, p2, ...args) => {
        return p1.replaceAll('__', ' ') + p2;
    });
};

const CSVDownload = (props: React.ComponentProps<typeof CSVLink>) => {
    const btnRef = useRef<HTMLElement>(null);
    useEffect(() => btnRef.current?.click(), [btnRef]);
    return (
        <CSVLink {...(props as any)}>
            <span ref={btnRef} />
        </CSVLink>
    );
};

const displayDateRange = (_fromDate: string, _toDate: string) => {
    const fromDate = stripTimeInfo(_fromDate);
    const toDate = stripTimeInfo(_toDate);
    if (!fromDate && !toDate) return 'All_Time';
    if (!fromDate && toDate) return `Until_${toDate}`;
    if (fromDate && !toDate) return `After_${fromDate}`;
    return `${fromDate}_to_${toDate}`;
};

const stripTimeInfo = (date: string) => {
    if (!date) return date;
    return date.split('T')[0];
};

export const insertSymbol = (
    amount: number,
    symbol?: string,
    cultureCode?: string,
    decimalPlaces?: number,
    maxDecimal?: number
) => {
    const value = formatPriceWithLocale(amount, cultureCode ?? 'en-GB', decimalPlaces, maxDecimal);

    if (!symbol) return value;
    if (!(amount + '').includes('-')) return `${symbol}${value}`;
    return (value + '').replace('-', `- ${symbol}`);
};
