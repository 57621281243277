import { useTable } from 'api';
import { TableProps } from 'components/flexTable/FlexTable';
import {
    PortfolioTable,
    PortfolioTableLiveData,
    PortfolioTableRow,
} from 'components/portfolioTable/PortfolioTable';
import React, { useContext, useMemo, useState } from 'react';
import Select, { ValueType } from 'react-select/';
import {
    CryptoContext,
    CryptoLiveData,
    CurrencyOption,
    DEFAULT_SELECT_OPTION,
    FiatCurrency,
} from './CryptoPage';
import SearchIcon from 'assets/ibanera/search-icon.svg';
import { useCryptoContextEffects } from './useCryptoContextEffects';
import Pagination from 'components/pagination/Pagination';
import { CurrencySelectDropdown } from 'components/currencySelectDropdown/CurrencySelectDropdown';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';

export const parseCryptosToPortfolioTable = (cryptos: CryptoLiveData[]): PortfolioTableLiveData => {
    return cryptos.reduce((acc, crypto) => {
        const tradeTableRow = {
            price: crypto.sellPrice,
            percentChange: crypto.priceVariability,
            high: crypto.high24hrs,
            low: crypto.low24hrs,
        };
        return { ...acc, [crypto.ticker]: tradeTableRow };
    }, {});
};

const SEARCH_DEBOUNCE_TIME = 500;

export const Portfolio = () => {
    const {
        cryptos,
        fiatCurrencies,
        selectedFiatCurrency,
        setSelectedFiatCurrency,
        searchString,
        setSearchString,
        endpoints,
        setIsPageLoading,
    } = useContext(CryptoContext);

    const [searchTimeout, setSearchTimeout] = useState<null | number>(null);

    const portfolioTable = useTable<PortfolioTableRow, any>({
        url: endpoints.portfolioList,
        bClearFilters: true,
        bClearSearch: true,
        multiSort: false,
    });

    useCryptoContextEffects(portfolioTable);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        setSearchString(search);
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        const timeout = window.setTimeout(() => {
            portfolioTable.data?.actions.changeSearch(search);
            setSearchTimeout(null);
        }, SEARCH_DEBOUNCE_TIME);
        setSearchTimeout(timeout);
    };

    const fiatList = useMemo(
        () =>
            fiatCurrencies.map<CurrencyOption>((currency) => ({
                value: currency.code,
                label: currency.code,
            })),
        [fiatCurrencies]
    );

    const selectedCurrencyOption = useMemo<CurrencyOption | null>(
        () =>
            selectedFiatCurrency
                ? { value: selectedFiatCurrency.code, label: selectedFiatCurrency.code }
                : null,
        [selectedFiatCurrency]
    );

    const handleCurrency = (selectedItem: ValueType<CurrencyOption, false>) => {
        if (selectedItem) {
            const fiatCurrency = fiatCurrencies.find(
                (currency) => currency.code === selectedItem.value
            ) as FiatCurrency;
            setSelectedFiatCurrency(fiatCurrency);
        }
    };

    return (
        <div className="Cryptos">
            <div className="Market">
                <div className="MarketSubHeader">
                    <div className="Left">
                        <p>Your account balances.</p>
                        {fiatList.length > 0 && (
                            <CurrencySelectDropdown
                                isSearchable={false}
                                options={fiatList}
                                value={selectedCurrencyOption}
                                defaultValue={DEFAULT_SELECT_OPTION}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onChange={handleCurrency}
                            />
                        )}
                    </div>
                    <TableSearchInput
                        value={searchString}
                        onChange={handleSearch}
                        name="search"
                        placeholder="Search all currencies"
                    />
                </div>
                {selectedFiatCurrency && (
                    <>
                        <PortfolioTable
                            idColumn={'assets__Id'}
                            table={portfolioTable as TableProps['table']}
                            liveData={parseCryptosToPortfolioTable(cryptos)}
                            selectedCurrency={selectedFiatCurrency}
                            isLoading={portfolioTable.loading}
                            hideFiltering
                        />
                        <Pagination table={portfolioTable} />
                    </>
                )}
            </div>
        </div>
    );
};
