import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import WarningIcon from 'assets/Icon_Warning.png';
import { Toast } from 'helpers/toast';
import logger from 'helpers/logger';
import { SelectOption } from 'components/form/Dropdown';
import { endpoints } from 'endpoints.config';
import Select from 'react-select';

type DepositAddressInfo = {
    address: string;
    qrCode: string;
};

export const DepositCryptoModal = ({ asset }: { asset: string | null }) => {
    const dispatch = useDispatch();
    const [addressData, setAddressData] = useState<DepositAddressInfo | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);
    const [availableCryptos, setAvailableCryptos] = useState<null | SelectOption[]>(null);
    const [selectedCrypto, setSelectedCrypto] = useState<string | null>(null);

    useEffect(() => {
        if (
            modalState.modalType !== ModalTypes.DEPOSIT_CRYPTO ||
            (!modalState.data.crypto && !selectedCrypto)
        )
            return; // the modaltype check lets typescript infer the modalstate's data
        setAddressData(null);
        instance
            .get<ApiResponse<DepositAddressInfo>>(modalState.data.depositEndpoint, {
                params: { asset: modalState.data.crypto ?? selectedCrypto },
            })
            .then((res) => setAddressData(res.data.details))
            .catch((err) =>
                setErrorMessage(
                    "We can't generate an address for you right now, please try again later"
                )
            );
    }, [modalState, selectedCrypto]);
    useEffect(() => {
        if (modalState.modalType !== ModalTypes.DEPOSIT_CRYPTO || modalState.data.crypto) return;
        instance
            .get<ApiResponse<SelectOption[]>>(endpoints.cryptoExchange.getDepositOptions)
            .then((res) => setAvailableCryptos(res.data.details))
            .catch(() => {
                Toast.openErrorToast('Error Loading Cryptocurrencies');
                dispatch(closeModal());
            });
    }, [modalState, dispatch]);
    const handleCopy = () => {
        const addressToCopy = addressData?.address;
        if (addressToCopy) {
            Toast.openSuccessToast('Copied');
            window.navigator.clipboard.writeText(addressToCopy);
        }
    };
    if (modalState.modalType !== ModalTypes.DEPOSIT_CRYPTO) return null;
    const depositElement =
        addressData === null ? (
            <div className="DepositCryptoModal Loading">
                <p>Generating Deposit Address</p>
                <div style={{ position: 'relative', width: '100%', height: 10 }}>
                    <Spinner positionAbsolute />
                </div>
            </div>
        ) : (
            <div className="DepositCryptoModal">
                <p>Use the QR code or address below to deposit crypto into your account</p>
                {(asset === 'USDC' || selectedCrypto === 'USDC') && (
                    <div className="UsdcWarning">
                        <img src={WarningIcon} alt="Attention" />
                        Do not deposit non-Ethereum-based USDC to this address, as funds may be
                        lost.
                    </div>
                )}
                <img src={`data:image/jpeg;base64, ${addressData.qrCode}`} alt="Deposit QR code" />
                <p>{addressData.address}</p>
                <Button className="CopyButton" priority="secondary" onClick={handleCopy}>
                    Copy
                </Button>
            </div>
        );
    return (
        <Modal
            title={`Deposit ${modalState.data.crypto ?? selectedCrypto ?? 'Crypto'}`}
            primaryButton="Close"
            handlePrimary={() => dispatch(closeModal())}
        >
            {errorMessage ? (
                <p>{errorMessage}</p>
            ) : modalState.data.crypto === null ? (
                availableCryptos ? (
                    <div className="DepositCryptoModalWrapper WithSelector">
                        <Select
                            options={availableCryptos}
                            onChange={(item) => setSelectedCrypto(item?.value ?? null)}
                            className="CustomSelect"
                            classNamePrefix="CustomSelect"
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (provided: any) => ({
                                    ...provided,
                                    zIndex: 500,
                                }),
                            }}
                        ></Select>
                        {!!selectedCrypto && depositElement}
                    </div>
                ) : (
                    <div className="DepositCryptoModal Loading">
                        <p>Loading Cryptocurrencies</p>
                        <div style={{ position: 'relative', width: '100%', height: 10 }}>
                            <Spinner positionAbsolute />
                        </div>
                    </div>
                )
            ) : (
                depositElement
            )}
        </Modal>
    );
};
