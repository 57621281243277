import { RouteComponentProps } from '@reach/router';
import { CMSContent } from 'components/cmsContent/CMSContent';
import Page from 'components/page/Page';
import { useScrollToTop } from 'helpers/useScrollToTop';
import React from 'react';

interface PublicInformationPageProps extends RouteComponentProps {
    page?: string;
}

// Generic page for information such T&C and Banking Guarantee.
export const PublicInformationPage: React.FC<PublicInformationPageProps> = ({ page }) => {
    useScrollToTop(page);

    return (
        <Page isPublic={true}>
            <div className="PublicInformationPage">{page && <CMSContent page={page} />}</div>
        </Page>
    );
};
