import { useTable } from 'api';
import { TableProps } from 'components/flexTable/FlexTable';
import { PortfolioTable, PortfolioTableRow } from 'components/portfolioTable/PortfolioTable';
import { parseCryptosToPortfolioTable } from 'pages/crypto/Portfolio';
import { serializeFilters } from '@avamae/parse-filter';
import React, { useContext, useEffect } from 'react';
import { CryptoContext, CryptoLiveData } from './CryptoPage';
import { splitCryptoPairString } from './useCryptoContextEffects';

type MarketTableProps = {
    currency: string;
    currencyFormat: any;
    setSellButtonEnabled: (b: boolean) => void;
};

export type Data = {
    crypto__Id: number;
    crypto__Ticker: string;
    crypto__Name: string;
    crypto__Price: number;
    crypto__24hr: number;
    crypto__PercentChnge: number;
};

const CryptoHoldings: React.FC<MarketTableProps> = ({
    currency,
    currencyFormat,
    setSellButtonEnabled,
}) => {
    const { cryptos, selectedFiatCurrency, endpoints, selectedCryptoPair } =
        useContext(CryptoContext);
    const selectedCrypto = cryptos.find(
        (crypto) => crypto.pair === selectedCryptoPair
    ) as CryptoLiveData;

    const { crypto, fiat } = splitCryptoPairString(selectedCryptoPair as string);

    const assetCode: keyof PortfolioTableRow = 'assets__Code';
    const pairedCurrency: keyof PortfolioTableRow = 'pairedAssets__Code';

    const portfolioTable = useTable<PortfolioTableRow, any>({
        url: endpoints.portfolioList,
        filters: `${assetCode} EQ (${crypto}) | ${pairedCurrency} EQ (${fiat})`,
        multiSort: false,
    });
    useEffect(() => {
        if (portfolioTable.data) {
            setSellButtonEnabled(portfolioTable.data.details.listData.length > 0);
        }
    });
    if (
        portfolioTable.loading ||
        !portfolioTable.data ||
        portfolioTable.data.details.listData.length === 0
    )
        return null;
    return (
        <div className="CryptoHoldings">
            <h2>{`My ${selectedCrypto.name} (${selectedCrypto.ticker})`}</h2>
            {selectedFiatCurrency && (
                <PortfolioTable
                    idColumn={'assets__Id'}
                    table={portfolioTable as TableProps['table']}
                    liveData={parseCryptosToPortfolioTable(cryptos)}
                    selectedCurrency={selectedFiatCurrency}
                    isLoading={portfolioTable.loading}
                    excludeBuySellButton
                    hideFiltering
                />
            )}
        </div>
    );
};

export { CryptoHoldings };
