import { useFormikContext } from 'formik';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import FormTextField from 'components/form/FormTextField';
import { SelectOption } from 'components/categoryComponents/dropdown/Dropdown';
import { CorporateApp } from '../corporateAppModel';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { ErrorM } from 'components/form/ErrorM';
import { useAfterMountEffect } from '../../../../helpers/useAfterMountEffect';
interface Props {
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    expandAll: boolean;
}

const OperatingAddress: React.FC<Props> = ({ availableCountries, expandAll }) => {
    const { values, setFieldValue } = useFormikContext<CorporateApp>();

    const currentCountry = availableCountries.find(
        (country) => country.value === values.corporate.operatingAddress.country
    );
    const stateOptions = currentCountry ? currentCountry.states : null;

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`corporate.operatingAddress.stateProvince`, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.corporate.operatingAddress.country]);

    return (
        <div>
            <ErrorM name="corporate.operatingAddress" />
            <div className="FormBox">
                <RadioButtons
                    options={['Yes', 'No']}
                    label={
                        'Is the company operating address different from the registered address?'
                    }
                    fieldname={'corporate.operatingAddress.bUseDifferentAddress'}
                    error={true}
                    booleanField={true}
                />
            </div>
            {values.corporate.operatingAddress.bUseDifferentAddress && (
                <div className="FormBox">
                    <FormTextField
                        field={'corporate.operatingAddress.addressLine1'}
                        label={'Street Name'}
                        required={values.corporate.operatingAddress.bUseDifferentAddress === true}
                        maxLength={200}
                        asterisk={true}
                    />
                    <div className="FormSideBySide">
                        <FormTextField
                            field={'corporate.operatingAddress.postcode'}
                            label={'ZIP / Postal code'}
                            required={
                                values.corporate.operatingAddress.bUseDifferentAddress === true
                            }
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        {stateOptions ? (
                            <div className="FormBuilderComponent SideBySide">
                                <WrappedFormSingleSelectField
                                    fieldName="corporate.operatingAddress.stateProvince"
                                    label={'State / Province*'}
                                    options={stateOptions}
                                />
                            </div>
                        ) : (
                            <FormTextField
                                field="corporate.operatingAddress.stateProvince"
                                label={'State / Province'}
                                required={
                                    values.corporate.operatingAddress.bUseDifferentAddress === true
                                }
                                maxLength={200}
                                sideBySide={true}
                                asterisk={true}
                            />
                        )}
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={'corporate.operatingAddress.city'}
                            label={'City'}
                            required={
                                values.corporate.operatingAddress.bUseDifferentAddress === true
                            }
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={'corporate.operatingAddress.country'}
                                options={availableCountries}
                                label="Country*"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OperatingAddress;
