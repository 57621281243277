/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { TFAField } from 'components/form/TFAField';
import { Spinner } from 'components/spinner/Spinner';
import { Form, useFormikContext } from 'formik';
import { postSmsTFASetup } from 'pages/register/RegisterApi';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import {
    TFASignInAttempt,
    selectAuthStatus,
    selectTFAType,
    setAuthStatus,
    signOut,
} from '../../reducers/auth';
import { SignInTranslation, TFAFormDetails } from './signInTypes';

type Props = {
    translations: SignInTranslation | null;
    error: boolean;
    success: boolean;
};
export const ReactivateAccount: React.FC<Props> = ({ translations, success, error }) => {
    const theme = useTheme();
    const TFAType = useSelector(selectTFAType);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { values, setFieldValue, setFieldTouched, isSubmitting, setSubmitting } =
        useFormikContext<TFAFormDetails>();
    const authStatus = useSelector(selectAuthStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authStatus === 'suppressed' && !(values.username && values.password)) {
            dispatch(signOut(false));
        }
    }, [authStatus, values.username, values.password, dispatch]);

    const TFAFormCSS = css`
        .Btn {
            background: ${theme.colors.first};
        }

        .Btn.SwitchTFATypeButton {
            background-color: transparent;
        }

        .UseSMS {
            color: ${theme.colors.second};
        }
    `;
    const requestSmsCode = () => {
        postSmsTFASetup({
            username: values.username,
            password: values.password,
            bNewPhoneNumber: false,
        });
    };

    useEffect(() => {
        setSubmitting(false);
        if (values.tfaType) return;
        const tfa = TFAType === 'both' || TFAType === 'app' ? 'AuthenticatorApp' : 'SMS';
        setFieldTouched('tfaCode', false);
        setFieldValue('tfaType', tfa);
        if (tfa === 'SMS') {
            setFieldValue('useSMS', true);
            requestSmsCode();
        } else {
            setFieldValue('useSMS', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [TFAType, values.tfaType]);

    useEffect(() => {
        if (error) {
            setErrorMessage('Code is invalid, please try again');
            setFieldValue('tfaCode', '');
            setSubmitting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleChangeAuthType = () => {
        setErrorMessage(null);
        const switchToSms = !values.useSMS;
        if (switchToSms) {
            requestSmsCode();
        }
        setFieldValue('useSMS', !values.useSMS);
        setFieldValue('tfaType', switchToSms ? 'SMS' : 'AuthenticatorApp');
    };

    if (success)
        return (
            <div className="ReactivateAccount Success">
                <h1>Account reactivated</h1>
                <p>Your account has been reactivated. Return to the login screen to sign in </p>
                <Button
                    variety="full"
                    priority="primary"
                    onClick={() => {
                        setFieldValue('tfaCode', '');
                        dispatch(setAuthStatus('signed_out'));
                    }}
                >
                    Go to Sign In
                </Button>
            </div>
        );

    return (
        <Form className="AuthFormContainer">
            <div className="AuthForm ReactivateAccount" css={TFAFormCSS}>
                <h1>{translations?.heading2 ?? ''}</h1>
                <h2>Your account is deactivated</h2>
                <p>
                    If you would like to reactivate your account{' '}
                    {values.useSMS
                        ? translations?.sub_heading4?.toLowerCase() ?? ''
                        : translations?.sub_heading2?.toLowerCase() ?? ''}
                </p>
                <div className="FieldsContainer">
                    <TFAField
                        field={'tfaCode'}
                        label={'Your verification code'}
                        required={true}
                        toggleTFAType={TFAType === 'both' ? handleChangeAuthType : null}
                        tfaType={values.tfaType ?? 'AuthenticatorApp'}
                        handleResendCode={requestSmsCode}
                        holdFocus
                        autoFocus
                    />
                    {errorMessage && <p className="ErrorMessage">{errorMessage}</p>}
                    <Button variety="full" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? <Spinner color={'#fff'} /> : translations?.continue_button}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export const parseTfaForm = (payload: TFAFormDetails): TFASignInAttempt => ({
    username: payload.username,
    password: payload.password,
    rememberMe: payload.rememberMe,
    tfaCode: payload.tfaCode,
    tfaType: payload.tfaType ?? 'AuthenticatorApp',
});
