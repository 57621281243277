import { InfoRequestDataRow } from './models';
import styles from './InfoRequest.module.scss';
import Button from '../../components/button/Button';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from '../../reducers/language';
import { localizedFormatDate } from '../../helpers/language';
import { formatPriceWithLocale } from '../../helpers/formatPriceWithCommas';
import { ModalTypes, openModal } from '../../reducers/modal';

type Props = InfoRequestDataRow;

export const InfoRequestTile: React.FC<Props> = ({
    customerAssetAccountsTransactionsInformationRequests__Id,
    transactionInformationRequests__ExternalPartyName: toFrom,
    transactionInformationRequests__Reference: reference,
    customerAssetAccountsTransactionsInformationRequests__AddDate: addDate,
    customerAssetAccountsTransactionsInformationRequests__ExpiryDate: expiryDate,
    transactionInformationRequests__Amount: amount,
    customerAssetAccountsTransactionsInformationRequestStatuses__Status: status,
    transactionInformationRequests__CurrencySymbol: currencySymbol,

    transactionInformationRequests__bMoneyIn: transactionAmounts__bInTransaction,
}) => {
    const cultureCode = useSelector(selectCultureCode);

    const dispatch = useDispatch();
    const { colors } = useTheme();

    const handleClick = () => {
        dispatch(
            openModal({
                modalType: ModalTypes.UPLOAD_REQUEST_INFO,
                data: customerAssetAccountsTransactionsInformationRequests__Id,
            })
        );
    };

    const moneyIn = transactionAmounts__bInTransaction;

    return (
        <div className={styles.InfoRequestTile}>
            <div className={styles.Header}>
                {!!toFrom && (
                    <div className={styles.ToFrom}>
                        <div className={`${styles.Letter} ${moneyIn ? styles.MoneyIn : ''}`}>
                            {toFrom[0]}
                        </div>
                        {toFrom}
                    </div>
                )}
                <div className={styles.Date}>
                    {localizedFormatDate(
                        new Date(addDate),
                        cultureCode || undefined,
                        'dd - MM - yyyy'
                    )}
                </div>
                {moneyIn ? (
                    <IconMoneyIn width={15} height={15} />
                ) : (
                    <IconMoneyOut width={15} height={15} fill={colors.third} />
                )}
            </div>

            <div>{reference}</div>

            <div className={styles.Date}>
                Expiry:{' '}
                {localizedFormatDate(
                    new Date(expiryDate),
                    cultureCode || undefined,
                    'dd - MM - yyyy'
                )}
            </div>

            <div className={`${styles.Amounts} ${moneyIn ? styles.MoneyIn : styles.MoneyOut}`}>
                {currencySymbol}
                {formatPriceWithLocale(amount ?? 0, cultureCode ?? 'en-GB')}
            </div>

            <div className={styles.Status}>{status}</div>

            <Button onClick={handleClick} color="third">
                Upload
            </Button>
        </div>
    );
};
