import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasInfoRequest,
    selectRefreshInfoRequest,
    updateHasInfoRequest,
} from '../../reducers/infoRequest';
import Button from '../../components/button/Button';
import { ModalTypes, openModal } from '../../reducers/modal';
import { TableInfo, useTable } from '../../api';
import { InfoRequestDataRow } from './models';
import { endpoints } from '../../endpoints.config';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';
import styles from './InfoRequest.module.scss';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { FlexTable } from '../../components/flexTable';
import { localizedFormatDate } from '../../helpers/language';
import { selectCultureCode } from '../../reducers/language';
import Pagination from '../../components/pagination/Pagination';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { InfoRequestTile } from './InfoRequestTile';

const idColumn: keyof InfoRequestDataRow =
    'customerAssetAccountsTransactionsInformationRequests__Id';

const insertSymbol = (amount: number, symbol: string | null) => {
    if (!symbol) return amount;
    if (!(amount + '').includes('-')) return `${symbol}${amount}`;
    return (amount + '').replace('-', `- ${symbol}`);
};

export const InfoRequest = () => {
    const hasInfoRequest = useSelector(selectHasInfoRequest);
    const refreshInfoRequest = useSelector(selectRefreshInfoRequest);
    const cultureCode = useSelector(selectCultureCode);

    const { colors } = useTheme();

    const dispatch = useDispatch();

    const toggleHasRequest = () => {
        dispatch(updateHasInfoRequest(!hasInfoRequest));
    };
    const openRequestInfoModal = () => {
        setTimeout(() => {
            dispatch(openModal({ modalType: ModalTypes.REQUEST_INFO }));
            dispatch(updateHasInfoRequest(true));
        }, 3000);
    };

    const infoRequestTable = useTable<InfoRequestDataRow, any>({
        url: endpoints.informationRequest.list,
        bClearFilters: true,
        pageSize: 1000,
    });

    const { data, loading, error, reload } = infoRequestTable;

    useAfterMountEffect(() => {
        reload();
    }, [refreshInfoRequest]);

    const rowFormatter = (row: InfoRequestDataRow) => {
        let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };

        const moneyIn = row.transactionInformationRequests__bMoneyIn;

        if (formattedRow.transactionInformationRequests__bMoneyIn != null) {
            formattedRow = {
                ...formattedRow,
                transactionInformationRequests__bMoneyIn: moneyIn ? (
                    <IconMoneyIn width={20} height={20} />
                ) : (
                    <IconMoneyOut width={20} height={20} fill={colors.third} />
                ),
            };
        }
        if (formattedRow.transactionInformationRequests__Amount != null) {
            formattedRow = {
                ...formattedRow,
                transactionInformationRequests__Amount: (
                    <span className={moneyIn ? styles.MoneyIn : styles.MoneyOut}>
                        {insertSymbol(
                            row.transactionInformationRequests__Amount,
                            row.transactionInformationRequests__CurrencySymbol
                        )}
                    </span>
                ),
            };
        }
        if (formattedRow.transactionInformationRequests__ExternalPartyName != null) {
            const desc = row.transactionInformationRequests__ExternalPartyName;
            formattedRow = {
                ...formattedRow,
                transactionInformationRequests__ExternalPartyName: (
                    <NameWithInitial name={desc} moneyIn={moneyIn} />
                ),
            };
        }

        if (formattedRow.customerAssetAccountsTransactionsInformationRequests__AddDate) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactionsInformationRequests__AddDate: (
                    <span className={styles.Date}>
                        {localizedFormatDate(
                            new Date(
                                row.customerAssetAccountsTransactionsInformationRequests__AddDate
                            ),
                            cultureCode || undefined,
                            'dd - MM - yyyy'
                        )}
                    </span>
                ),
            };
        }
        if (formattedRow.customerAssetAccountsTransactionsInformationRequests__ExpiryDate) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactionsInformationRequests__ExpiryDate: (
                    <span className={styles.Date}>
                        {localizedFormatDate(
                            new Date(
                                row.customerAssetAccountsTransactionsInformationRequests__ExpiryDate
                            ),
                            cultureCode || undefined,
                            'dd - MM - yyyy'
                        )}
                    </span>
                ),
            };
        }
        if (formattedRow.customerAssetAccountsTransactionsInformationRequestStatuses__Status) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactionsInformationRequestStatuses__Status: (
                    <div className={styles.Status}>
                        {row.customerAssetAccountsTransactionsInformationRequestStatuses__Status}
                    </div>
                ),
            };
        }
        if (formattedRow.transactionInformationRequests__Reference) {
            formattedRow = {
                ...formattedRow,
                transactionInformationRequests__Reference: (
                    <div className={styles.Reference}>
                        {row.transactionInformationRequests__Reference}
                    </div>
                ),
            };
        }

        return formattedRow;
    };

    const rowButton = (data: InfoRequestDataRow) => <InfoRequestButton {...data} />;

    return (
        <div className={styles.InformationRequest}>
            <h1>Information request</h1>
            <p className={styles.Description}>
                Please provide some more information on the transactions listed below
            </p>

            {false && (
                <div>
                    <Button onClick={toggleHasRequest}>Toggle has request</Button>
                    <br />
                    <br />
                    <Button onClick={openRequestInfoModal}>Open modal (3s delay)</Button>
                </div>
            )}

            {loading && !data && (
                <div className={styles.Loading}>
                    <FoldingCube color={colors.first} size={80} />
                    <h1 className="Message">Loading</h1>
                </div>
            )}
            {!loading && error && (
                <div className={styles.Error}>
                    <WarningIcon width={50} />
                    <h2>Failed to load information requests</h2>
                    <p>
                        We couldn't load any information requests for you. Press the button below or
                        try refreshing the page
                    </p>
                    <Button onClick={() => reload()}>Retry</Button>
                </div>
            )}
            {data && (
                <>
                    <div className={styles.Table}>
                        <FlexTable
                            idColumn={idColumn}
                            table={infoRequestTable as TableInfo}
                            rowFormatter={rowFormatter}
                            rowCellOptions={{
                                transactionAmounts__bInTransaction: {
                                    style: { minWidth: '60px', width: '60px', flex: '0' },
                                },
                                _button: {
                                    style: {
                                        minWidth: '100px',
                                        width: '100px',
                                        flex: '0',
                                        padding: '0 10 0 0',
                                    },
                                },
                            }}
                            buttonInMiddleTable
                            rowButton={rowButton}
                        />
                        {!loading && data.details.resultsCount === 0 && (
                            <div className="EmptyTable">
                                <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                                <h3 className="Message">No requests found</h3>
                            </div>
                        )}
                        {data.details.resultsCount > 10 && <Pagination table={infoRequestTable} />}
                    </div>

                    <div className={styles.MobileTiles}>
                        {data.details.listData.map((item, i) => (
                            <InfoRequestTile key={i} {...item} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default InfoRequest;

type InfoRequestButtonProps = InfoRequestDataRow;

const InfoRequestButton: React.FC<InfoRequestButtonProps> = (data) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(
            openModal({
                modalType: ModalTypes.UPLOAD_REQUEST_INFO,
                data: data[idColumn],
            })
        );
    };

    return (
        <div className="TransactionsButtonRow">
            {data.customerAssetAccountsTransactionsInformationRequestStatuses__Status !==
                'Pending Review' && (
                <Button color="third" className={styles.MoreInfoButton} onClick={handleClick}>
                    Upload
                </Button>
            )}
        </div>
    );
};

type NameWithInitialProps = {
    name: string;
    moneyIn: boolean;
};
export const NameWithInitial: React.FC<NameWithInitialProps> = ({ name, moneyIn }) => (
    <div className={styles.ToFrom}>
        {name && name !== '-' && (
            <div className={`${styles.Letter} ${moneyIn ? styles.MoneyIn : ''}`}>{name[0]}</div>
        )}
        {name ?? '-'}
    </div>
);
