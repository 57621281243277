import instance, { ApiResponse, isAxiosErrorHandled, isErrorHandled, useFetch } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useTFAField } from 'helpers/useTFAField';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import * as Yup from 'yup';
import WarningIcon from 'assets/Icon_Warning.png';
import { OrderDetails, PayeeDetail, TradeCrypto, TransferType } from 'pages/crypto/Crypto';
import { TFAType } from 'pages/register/models';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { ReviewOrderForm } from 'pages/crypto/ReviewOrderForm';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useGetAccountDetailsForCrypto } from 'helpers/useGetAccountDetails';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { Spinner } from 'components/spinner/Spinner';
import logger from 'helpers/logger';
import { CalculatedPriceField, FiatToggle } from 'pages/quickCoin/QuickCoinFormComponents';
import { debounce } from 'lodash';
import { AxiosResponse } from 'axios';
import { formatCurrency } from 'helpers/fiatFormatter';

type FiatAccountDetails = {
    customerAssetAccountsId: number;
    assetCode: string;
    availableBalance: number;
    bVirtualBuy: boolean;
    accountName: string;
};

type ReviewUsdBuyRequest = {
    sourceCustomerAssetAccountID: number;
    destinationCustomerAssetAccountID: number;
    asset: 'dUSD';
    amount: string;
    price: string;
    quantityType: 'Amount' | 'Price';
};

type ReviewDusdBuyResponse = {
    sourceCustomerAssetAccountsId: number;
    assetExchangeType: 'Buy';
    quantityType: 'Amount' | 'Price';
    pairedAsset: string;
    asset: 'dUSD';
    amount: number;
    price: number;
    networkFee: number;
    commissionFee: number;
    totalPrice: number;
};

type BuyDusdRequest = {
    tfaCode: '';
    tfaType: TFAType;
    destinationCustomerAssetAccountID: number | null;
    sourceCustomerAssetAccountID: number | null;
    quantityType: 'Amount' | 'Price';
    asset: 'dUSD';
    amount: string;
    price: string;
};

const generateInitialValues = (
    dUsdAccountId: number,
    sourceAssetAccountId: number
): BuyDusdRequest => ({
    amount: '',
    price: '',
    tfaCode: '',
    tfaType: 'SMS',
    quantityType: 'Amount',
    asset: 'dUSD',
    destinationCustomerAssetAccountID: dUsdAccountId,
    sourceCustomerAssetAccountID: sourceAssetAccountId,
});

const validationSchemaStep1 = Yup.object({
    quantityType: Yup.string(),

    amount: Yup.number().when('quantityType', {
        is: 'Amount',
        then: (schema) =>
            schema.required('Please enter an amount').moreThan(0, 'Please enter an amount'),
    }),
    price: Yup.number().when('quantityType', {
        is: 'Price',
        then: (schema) =>
            schema.required('Please enter an amount').moreThan(0, 'Please enter an amount'),
    }),
    sourceCustomerAssetAccountID: Yup.number().required('Please Select an account'),
});

const validationSchemaStep2 = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your MFA code')
        .min(6, 'Please enter your MFA code'),
});

export const BuyDusdModal = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');

    const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

    // const { data, loading } = useFetch<ApiResponse<FiatAccountDetails[]>>(
    //     endpoints.cryptosmodule.getFiatAssetAccounts,
    //     {
    //         params: { asset: 'dUSD' },
    //     }
    // );

    const modalData = useSelector(selectModalState);

    const dusdAccountDetails = useGetAccountDetailsForCrypto('dUSD');

    const initialValues = useMemo(
        () =>
            generateInitialValues(
                dusdAccountDetails?.id ?? 0,
                modalData.modalType === ModalTypes.BUY_DUSD
                    ? modalData.data.sourceAssetAccount.id
                    : 0
            ),
        [dusdAccountDetails?.id, modalData]
    );

    const handleSubmit = (values: BuyDusdRequest, formikHelpers: FormikHelpers<BuyDusdRequest>) => {
        if (!orderDetails) {
            const {
                sourceCustomerAssetAccountID,
                destinationCustomerAssetAccountID,
                asset,
                price,
                amount,
                quantityType,
            } = values;
            //get order details
            const payload = {
                sourceCustomerAssetAccountID,
                destinationCustomerAssetAccountID,
                asset,
                amount: quantityType === 'Amount' ? amount : undefined,
                totalPrice: quantityType === 'Price' ? price : undefined,
                quantityType,
            };
            instance
                .get<ApiResponse<ReviewDusdBuyResponse>>(endpoints.cryptosmodule.reviewDusdBuy, {
                    params: payload,
                })
                .then((res) => {
                    setOrderDetails({
                        ...res.data.details,
                        customerAssetAccountsId: sourceCustomerAssetAccountID!,
                        validUntilUtcDate: new Date(Date.now() + 5 * 60 * 1000).toISOString(),
                    });
                    setErrorMessage('');
                })
                .catch((err) => {
                    if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                        setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                    } else {
                        setErrorMessage(getErrorMessage(''));
                    }
                })
                .finally(() => {
                    formikHelpers.setSubmitting(false);
                });
            return;
        }

        instance
            .post(endpoints.cryptosmodule.buyDusd, {
                ...values,
                ...orderDetails,
            })
            .then((res) => {
                Toast.openSuccessToast('Transaction Successful');
                dispatch(closeModal());
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                } else setErrorMessage(getErrorMessage('Generic'));
                formikHelpers.setSubmitting(false);
                console.error(err);
            });
    };

    const createModalButtons = (submitfn: () => void, submitting: boolean) => (
        <div className="ModalNavigation">
            <Button onClick={() => dispatch(closeModal())} priority="secondary">
                Close
            </Button>
            <Button onClick={submitfn} priority="primary" disabled={submitting}>
                Convert to <AssetCodeFormatter assetCode="dUSD" />
            </Button>
        </div>
    );
    if (modalData.modalType !== ModalTypes.BUY_DUSD) return null;
    const selectedFiatCurrency = modalData.data.sourceAssetAccount.currencyCode;

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={orderDetails ? validationSchemaStep2 : validationSchemaStep1}
            enableReinitialize
        >
            {({ submitForm, isSubmitting, values, setFieldValue }) => {
                return (
                    <Modal
                        title={
                            <>
                                Convert to <AssetCodeFormatter assetCode="dUSD" />
                            </>
                        }
                        customButtons={createModalButtons(submitForm, isSubmitting)}
                        closeOnClickOutside={false}
                    >
                        <Form>
                            <div className="BuyDusdModal">
                                {!orderDetails && (
                                    <>
                                        <FiatToggle
                                            currencyCode={selectedFiatCurrency}
                                            assetCode={'dUSD'}
                                        />
                                        <FormTextField
                                            type="number"
                                            autoComplete={false}
                                            step={values.quantityType === 'Price' ? 'any' : 0.01}
                                            field={
                                                values.quantityType === 'Price' ? 'price' : 'amount'
                                            }
                                            required={true}
                                            label={
                                                values.quantityType === 'Price' ? (
                                                    `Target value in ${selectedFiatCurrency}`
                                                ) : (
                                                    <>
                                                        Amount of{' '}
                                                        <AssetCodeFormatter assetCode="dUSD" />
                                                    </>
                                                )
                                            }
                                            key={
                                                values.quantityType === 'Price' ? 'price' : 'amount'
                                            }
                                            hint={undefined}
                                        />
                                        <BuyDusdPriceField
                                            label={
                                                values.quantityType === 'Price' ? (
                                                    <>
                                                        Amount of{' '}
                                                        <AssetCodeFormatter assetCode="dUSD" />
                                                    </>
                                                ) : (
                                                    `Value in ${selectedFiatCurrency}`
                                                )
                                            }
                                            isFiat={values.quantityType === 'Price'}
                                            hint={undefined}
                                            assetExchangeType={'Sell'}
                                            enabled={true}
                                        />
                                    </>
                                )}
                                {orderDetails && (
                                    <ReviewOrderForm
                                        back={() => setOrderDetails(null)}
                                        orderDetails={orderDetails}
                                        refreshOrder={() => {}}
                                        errorMessage=""
                                        hideSubmitButton
                                        noFormComponent
                                    />
                                )}
                            </div>
                            {errorMessage && <div className="ErrorText">{errorMessage}</div>}
                            <button type="submit" style={{ display: 'none' }} />
                        </Form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

const BuyDusdPriceField = ({
    label,
    isFiat,
    hint,
    assetExchangeType = 'Buy',
    enabled = true,
}: {
    label: React.ReactNode;
    isFiat: boolean;
    hint?: string;
    assetExchangeType?: 'Buy' | 'Sell';
    enabled?: boolean;
}) => {
    //THIS COMPONENT CURRENTLY ASSUMES ONLY USD -> dUSD EXCHANGE. Assumes a 1-1 price (we don't have an endpoint to getprice so we just assume 1-1 and add a disclaimer about fees)
    const { values, setFieldValue } = useFormikContext<BuyDusdRequest>();
    const {
        asset,
        amount,
        price,
        quantityType,
        sourceCustomerAssetAccountID,
        destinationCustomerAssetAccountID,
    } = values;

    useEffect(() => {
        if (isFiat && price && destinationCustomerAssetAccountID && sourceCustomerAssetAccountID) {
            setFieldValue('amount', price);
        }
    }, [
        isFiat,
        price,
        asset,
        sourceCustomerAssetAccountID,
        destinationCustomerAssetAccountID,
        quantityType,
        assetExchangeType,
        setFieldValue,
    ]);
    useEffect(() => {
        if (
            !isFiat &&
            amount &&
            destinationCustomerAssetAccountID &&
            sourceCustomerAssetAccountID
        ) {
            setFieldValue('price', amount);
        }
    }, [
        isFiat,
        amount,
        asset,
        sourceCustomerAssetAccountID,
        destinationCustomerAssetAccountID,
        quantityType,
        assetExchangeType,
        setFieldValue,
    ]);
    return !enabled ? (
        <div className="FormBox">
            <div className="FormLabel">
                <label>{label}</label>
            </div>
            <div className="FormField">
                <div className="EditBox PriceField ViewOnly">
                    {enabled ? 'Calculating...' : ' '}
                </div>
            </div>
        </div>
    ) : (
        <FormTextField
            required={false}
            field={isFiat ? 'amount' : 'price'}
            label={<>{label} (before fees)</>}
            readOnly
            key={isFiat ? 'amount' : 'price'}
            hint={hint}
            className="PriceField"
            autoComplete={false}
        />
    );
};
