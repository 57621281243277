export enum BusinessVerificationStage {
    RegistrationInformation = 'NotStarted',
    OperationsInformation = 'OperationsInformation',
    OwnerInformation = 'OwnerInformation',
    Terms = 'Terms',
    Documents = 'Documents',
    Complete = 'Submitted',
    Verified = 'Verified',
}

export const getPreviousStage = (currentStage: BusinessVerificationStage) => {
    const currentStageIndex = BusinessStepsOrder.findIndex((stage) => stage === currentStage);
    return BusinessStepsOrder[currentStageIndex > 0 ? currentStageIndex - 1 : 0];
};
export const getNextStage = (currentStage: BusinessVerificationStage) => {
    const currentStageIndex = BusinessStepsOrder.findIndex((stage) => stage === currentStage);
    return BusinessStepsOrder[
        currentStageIndex + 1 < BusinessStepsOrder.length
            ? currentStageIndex + 1
            : currentStageIndex
    ];
};
export const stageTitles = {
    [BusinessVerificationStage.RegistrationInformation]: 'Entity Registration Information',
    [BusinessVerificationStage.OperationsInformation]: 'Entity Operations Information',
    [BusinessVerificationStage.OwnerInformation]:
        'Beneficial Owner or Controlling Party Information',
    [BusinessVerificationStage.Terms]: 'Final Steps',
    [BusinessVerificationStage.Documents]: 'Document Upload',
    [BusinessVerificationStage.Complete]: 'Submitted',
    [BusinessVerificationStage.Verified]: 'verified',
} as const;

export const VisibleBusinessSteps = [
    BusinessVerificationStage.RegistrationInformation,
    BusinessVerificationStage.OperationsInformation,
    BusinessVerificationStage.OwnerInformation,
    BusinessVerificationStage.Terms,
    BusinessVerificationStage.Documents,
];

export const BusinessStepsOrder = [
    BusinessVerificationStage.RegistrationInformation,
    BusinessVerificationStage.OperationsInformation,
    BusinessVerificationStage.OwnerInformation,
    BusinessVerificationStage.Terms,
    BusinessVerificationStage.Documents,
    BusinessVerificationStage.Complete,
    BusinessVerificationStage.Verified,
] as const;
export type BusinessVerificationStatusResponse = {
    businessVerificationStatus: 'Accepted' | 'Rejected' | 'Pending' | null;
    businessVerificationStep: BusinessVerificationStage;
    additionalData: AdditionalData;
    rejectedDocuments: DocumentRejection;
};
export type DocumentRejection = {
    financialInstitutionForm: string | null;
    proofOfAddress: string[] | null;
};

export type AdditionalData = {
    //UPDATE WITH NEW ENDPOINT DATA
    iframeUrl?: string;
    bManualAvailable?: boolean;
} | null;
