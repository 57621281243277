import { useCSVReader } from 'react-papaparse';

import UploadIcon from 'assets/ui-update/upload-01.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from '../../../components/circleSpinner/Spinner';
import { Toast } from '../../../helpers/toast';
import { SOURCE_ACCOUNT_LABEL } from './helpers';
import { BulkTransferOptions, ParsedCsv } from './models';
import { useParseCsvResults } from './parseCsvResults';

type Props = {
    accountId?: number;
    loadingData: boolean;
    accounts: BulkTransferOptions['accounts'];
    countries: BulkTransferOptions['countries'];
    purposeCodes: BulkTransferOptions['purposeCodes'];
    setBulkTransfer: Dispatch<SetStateAction<ParsedCsv[]>>;
    setError: Dispatch<SetStateAction<string | null>>;
};

type ParsedResults = {
    data: { [key: string]: string }[];
    errors: { code: string; message: string; row: number; type: string }[][];
};

export const BulkTransferUpload: React.FC<Props> = ({
    accountId,
    loadingData,
    accounts,
    countries,
    purposeCodes,
    setBulkTransfer,
    setError,
}) => {
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);

    const [loading, setLoading] = useState(false);
    const [csvData, setCsvData] = useState<ParsedResults | null>(null);
    const handleUploadedResult = (results: ParsedResults) => {
        setZoneHover(false);
        setLoading(true);

        setError(null);
        if (!results) return;

        const { data, errors } = results;

        /* Might get back some empty rows from the file so we need to filter those out */
        const errRows: number[] = [];
        const trimmedData = data.filter((entry, i) => {
            // Sometimes an empty string key and empty string value pair show up
            delete entry[''];

            const keep = Object.values(entry).some((val) => !!val);
            !keep && errRows.push(i);
            return keep;
        });
        // Make use of the error generated from the library
        const trimmedErrors = errors.filter((err: any) =>
            err.some((e: any) => !errRows.includes(e.row))
        );

        // Check if any data was parsed
        if (trimmedData.length === 0) {
            Toast.openErrorToast('File was empty or invalid. Please try again');
            setCsvData(null);
            setLoading(false);
            return;
        }

        setCsvData({ data: trimmedData, errors: trimmedErrors });
    };

    const { parsedData, missingColumns } = useParseCsvResults(
        csvData?.data,
        accountId,
        accounts,
        countries,
        purposeCodes
    );

    useEffect(() => {
        if (!csvData || !parsedData) return;
        if (parsedData.length === 0) {
            Toast.openErrorToast('File was empty or invalid. Please try again');
            setCsvData(null);
            setLoading(false);

            return;
        }
        // Set data to be rendered
        else setBulkTransfer(parsedData);

        // Don't worry about the source account missing if we're on an account page
        if (accountId && missingColumns.includes(SOURCE_ACCOUNT_LABEL))
            missingColumns.splice(missingColumns.indexOf(SOURCE_ACCOUNT_LABEL), 1);

        if (missingColumns.length > 0)
            setError(
                `Failed to detect columns; ${missingColumns.join(
                    ', '
                )}. Consider redownloading the template to ensure your csv has the correct format. For now these fields have been left blank and the rest of the data has been parsed`
            );

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData, missingColumns, accountId]);

    return (
        <>
            {loading ? (
                <div className="Loading">
                    <Spinner />
                </div>
            ) : (
                <CSVReader
                    config={{ header: true }}
                    onUploadAccepted={handleUploadedResult}
                    onDragOver={(event: DragEvent) => {
                        event.preventDefault();
                        setZoneHover(true);
                    }}
                    onDragLeave={(event: DragEvent) => {
                        event.preventDefault();
                        setZoneHover(false);
                    }}
                >
                    {/* @ts-ignore */}
                    {({ getRootProps, acceptedFile }) => (
                        <div
                            {...getRootProps({ className: `Dropzone ${zoneHover ? 'Hover' : ''}` })}
                        >
                            {acceptedFile ? (
                                <div className="FileDisplay">
                                    <p>
                                        Uploaded: <b>{acceptedFile.name}</b>
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <img src={UploadIcon} alt="upload" />
                                    <h5>Upload csv</h5>
                                    <span>Upload your csv here</span>
                                </>
                            )}
                        </div>
                    )}
                </CSVReader>
            )}
        </>
    );
};
