import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { useCallback, useEffect, useState } from 'react';
import { CryptoExchangeItem } from './models';
import instance, { ApiResponse, useFetch } from 'api';
import { endpoints } from 'endpoints.config';
import Button from 'components/button/Button';
import FavouriteIcon from 'assets/icon-favorite-01.svg';
import FavouriteIconSelected from 'assets/icon-favorite-02.svg';
import classNames from 'classnames';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { CryptoExchangeTile } from './CryptoExchangeTile';
import { Dropdown, SelectOption } from '../../components/form/Dropdown';

type Response = {
    availableAssets: SelectOption[];
    exchanges: CryptoExchangeItem[];
};

export const CryptoExchangePage = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [exchangeItems, setExchangeItems] = useState<null | CryptoExchangeItem[]>(null);
    const [searchString, setSearchString] = useState('');
    const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);

    const [assetExchangeOptions, setAssetExchangeOptions] = useState<SelectOption[]>([]);
    const [selectedExchangeAsset, setSelectedExchangeAsset] = useState<string | null>(null);

    const { data } = useFetch<ApiResponse<Response>>(endpoints.cryptoExchange.list, {
        params: { currency: selectedExchangeAsset },
    });

    useEffect(() => {
        if (
            data &&
            assetExchangeOptions.length > 0 &&
            selectedExchangeAsset &&
            !assetExchangeOptions.find((ass) => ass.value === selectedExchangeAsset)
        ) {
            setSelectedExchangeAsset(null);
        }
    }, [data, assetExchangeOptions, selectedExchangeAsset]);

    useEffect(() => {
        if (!data) return;
        setExchangeItems(data.details?.exchanges);
        setAssetExchangeOptions(data.details.availableAssets);
        setIsPageLoading(false);
    }, [data]);

    const filteredItems = exchangeItems?.filter(
        (item) =>
            (item.asset.toLowerCase().includes(searchString.toLowerCase()) ||
                item.pairedAsset.toLowerCase().includes(searchString.toLowerCase())) &&
            (!showOnlyFavourites || item.bFavourited)
    );

    const setFavourite = useCallback(
        (pairedAssetsId: number, assetsId: number, bFavourited: boolean) => {
            const toggleItem = () =>
                setExchangeItems(
                    (items) =>
                        items?.map((item) =>
                            item.pairedAssetsId === pairedAssetsId && item.assetsId === assetsId
                                ? { ...item, bFavourited: !item.bFavourited }
                                : item
                        ) ?? null
                );
            toggleItem(); // optimistically set bFavourite
            instance
                .post(endpoints.cryptoExchange.updateFavourites, {
                    pairedAssetsId,
                    assetsId,
                    bFavourited,
                })
                .catch(() => {
                    toggleItem(); //revert the optimistic toggle
                });
        },
        []
    );

    if (isPageLoading) return <SubPageLoader message={`Loading OTC Desk`} />;
    return (
        <div className="CryptoExchangePage">
            <div className="CryptoExchangePageHeader TableHeader">
                <div className="left">
                    <Dropdown
                        id="Asset select"
                        onChange={(e: SelectOption) => setSelectedExchangeAsset(e.value)}
                        options={assetExchangeOptions}
                        value={assetExchangeOptions.find(
                            (opt) => opt.value === selectedExchangeAsset
                        )}
                    />
                </div>
                <div className="right">
                    <Button
                        onClick={() => setShowOnlyFavourites((curr) => !curr)}
                        className={classNames('SetFavouriteButton', {
                            Selected: showOnlyFavourites,
                        })}
                        priority="secondary"
                    >
                        <img
                            className="ToggleDisplayFavs"
                            src={showOnlyFavourites ? FavouriteIconSelected : FavouriteIcon}
                            alt="Toggle display favourites"
                        />
                        Favorites
                    </Button>
                    <SearchBox
                        initialSearchString=""
                        changeSearch={setSearchString}
                        placeholder="Search all currencies"
                    />
                </div>
            </div>
            <div key={selectedExchangeAsset ?? 'NULL'} className="CryptoExchangeList">
                {filteredItems?.map((item, i) => (
                    <CryptoExchangeTile
                        key={(selectedExchangeAsset ?? 'NULL') + i}
                        item={item}
                        setFavourite={setFavourite}
                        baseAsset={selectedExchangeAsset ?? item.pairedAsset}
                    />
                ))}
            </div>
        </div>
    );
};
