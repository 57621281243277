import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import GreenTick from 'assets/ibanera/Icon_GreenTick.png';
import React from 'react';
import BusinessPartner from './BusinessPartner';
import Button from 'components/button/Button';
import { CorporateApp } from '../../corporateAppModel';
import { RadioButton, RadioButtons } from 'components/radiobuttons/radiobuttons';
interface Props {
    values: any;
    disable: boolean;
    expandAll: boolean;
}
const MainBusinessPartners: React.FC<Props> = ({ values, disable, expandAll }) => {
    const [expand, setExpand] = useState<boolean>(false);
    const { errors, touched, setFieldValue } = useFormikContext<CorporateApp>();
    const [completedSection, setCompletedSection] = useState(false);
    const idRef = useRef<number>(1);

    let businessPartnerValues = {
        number: 0,
        name: '',
        companyRegistrationNumber: '',
        website: '',
    };

    useEffect(() => {
        if (values.corporate.mainBusinessPartners === null) {
            setFieldValue('corporate.mainBusinessPartners', [{ ...businessPartnerValues }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateSection = () => {
        if (!errors.corporate?.mainBusinessPartners && touched.corporate?.mainBusinessPartners) {
            setCompletedSection(true);
        } else {
            setCompletedSection(false);
        }
    };
    useEffect(() => {
        if (expandAll) {
            setExpand(true);
        }
    }, [expandAll]);

    useEffect(() => {
        validateSection();
        if (errors.corporate?.mainBusinessPartners) {
            setExpand(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.corporate?.mainBusinessPartners, touched.corporate?.mainBusinessPartners]);

    return (
        <div>
            <FieldArray
                name="corporate.mainBusinessPartners"
                render={(arrayHelpers) => (
                    <>
                        <div className="FormBox">
                            <div className="FormLabel">
                                <label>Is your company partnered with any other businesses?</label>
                            </div>
                            <div className="RadioButtons">
                                <RadioButton
                                    label="Yes"
                                    selected={values.corporate.mainBusinessPartners.length > 0}
                                    onClick={() =>
                                        values.corporate.mainBusinessPartners.length === 0 &&
                                        arrayHelpers.push(businessPartnerValues)
                                    }
                                />
                                <RadioButton
                                    label="No"
                                    selected={values.corporate.mainBusinessPartners.length === 0}
                                    onClick={() =>
                                        setFieldValue('corporate.mainBusinessPartners', [])
                                    }
                                />
                            </div>
                        </div>
                        {values.corporate.mainBusinessPartners &&
                        values.corporate.mainBusinessPartners.length > 0 ? (
                            values.corporate.mainBusinessPartners.map(
                                (mainBusinessPartners: any, index: number) => (
                                    <BusinessPartner
                                        key={index}
                                        index={index}
                                        arrayHelpers={arrayHelpers}
                                        disableRemove={
                                            values.corporate.mainBusinessPartners.length === 1
                                        }
                                        values={values}
                                        idRef={idRef}
                                        disable={disable}
                                    />
                                )
                            )
                        ) : (
                            <div></div>
                        )}
                        {values.corporate.mainBusinessPartners.length > 0 && (
                            <Button
                                type="button"
                                priority="secondary"
                                variety="full"
                                onClick={() => {
                                    arrayHelpers.push(businessPartnerValues);
                                    idRef.current++;
                                }}
                                disabled={disable}
                            >
                                Add Business Partner
                            </Button>
                        )}
                    </>
                )}
            />
        </div>
    );
};
export default MainBusinessPartners;
