import React from 'react';
import { ErrorM } from '../../pagination/ErrorM';

import RedX from 'assets/ibanera/Icon_RedCross.png';
import GreenTick from 'assets/ibanera/Icon_GreenTick.png';
import Button from '../../button/Button';
import { useField, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { ModalTypes, closeModal, openModal } from '../../../reducers/modal';
import { DocumentData, DocumentFieldValue } from './BusinessVerificationDocuments';
import { UploadDocumentFlowType } from '../../modals/UploadDocumentModal';

type Props = {
    documents: DocumentData[];
    documentId: number;
    reload: () => void;
    flowType: UploadDocumentFlowType;
};

export const AdditionalDocumentUpload: React.FC<Props> = ({
    documents,
    documentId,
    reload,
    flowType,
}) => {
    const hasNotUploadedAdditional =
        documents.findIndex(
            (doc) => doc.bIsAdditionalDocument && doc.documentStatusString !== 'Not Submitted'
        ) === -1;
    const index =
        documents.findIndex(
            (doc) => doc.bIsAdditionalDocument && doc.documentStatusString === 'Not Submitted'
        ) === -1
            ? documents.length
            : documents.findIndex(
                  (doc) => doc.bIsAdditionalDocument && doc.documentStatusString === 'Not Submitted'
              );

    const additionalDoc = documents.find((doc) => doc.bIsAdditionalDocument);
    const { name, documentNotes } = additionalDoc ?? { name: 'Other Documents' };

    const fieldName = `documentsUpload.[${index}]`;

    const [{ value }, , { setValue }] = useField<DocumentFieldValue[]>(`documentsUpload`);
    const [, , helpers] = useField(`${fieldName}.fileName`);
    const { values } = useFormikContext<any>();

    const dispatch = useDispatch();

    const onRemovedItem = (docId: number, customersBusinessDocumentsId: number) => {
        setValue(
            value.filter(
                (doc) =>
                    doc.documentTypesID !== docId &&
                    doc.customersBusinessDocumentsId !== customersBusinessDocumentsId
            )
        );
        reload();
        dispatch(closeModal());
    };

    const handleUploadDocument = (documentId: number) => {
        dispatch(
            openModal({
                modalType: ModalTypes.UPLOAD_MULTI_BUSINESS_DOCUMENT,
                data: {
                    flowType,
                    documentId,
                    reloadTable: reload,
                    onRemovedItem,
                    values,
                    helpers,
                    fieldName,
                },
            })
        );
    };

    return (
        <div className="CardContainer">
            <div className="DocumentCard">
                <div className="CardTitle">{name ?? 'Other Documents'}</div>
                <div className="CardBody">
                    <h3 className="CardStatus">
                        {hasNotUploadedAdditional ? 'Not Submitted' : 'Uploaded'}
                    </h3>
                    <img
                        className="CardImage"
                        src={hasNotUploadedAdditional ? RedX : GreenTick}
                        alt={hasNotUploadedAdditional ? 'Not uploaded icon' : 'Uplaoded icon'}
                    ></img>
                    <div className="CardCaption">
                        {documentNotes ?? 'Please upload any other supporting documents'}
                    </div>
                </div>
                <div className="CardFooter">
                    <Button
                        type="button"
                        onClick={() => {
                            helpers.setTouched(true);
                            handleUploadDocument(documentId);
                        }}
                    >
                        Upload
                    </Button>
                </div>
            </div>
            <ErrorM name={`documentsUpload.${index}.fileName`} />
        </div>
    );
};
