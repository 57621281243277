import { useTable } from 'api';
import { TableProps } from 'components/flexTable/FlexTable';
import { endpoints } from 'endpoints.config';
import { parseCryptosToPortfolioTable } from 'pages/crypto/Portfolio';
import { splitCryptoPairString } from 'pages/crypto/useCryptoContextEffects';
import React, { useContext } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';
import { ExchangePortfolioTable, PortfolioTableRow } from './ExchangePortfolioTable';

export type Data = {
    crypto__Id: number;
    crypto__Ticker: string;
    crypto__Name: string;
    crypto__Price: number;
    crypto__24hr: number;
    crypto__PercentChnge: number;
};

const CryptoHoldings: React.FC = () => {
    const { cryptos, selectedFiatCurrency, selectedCurrencyInfo } = useContext(ExchangeCryptoContext);

    const { crypto, fiat } = splitCryptoPairString(selectedCurrencyInfo!.pair);

    const assetCode: keyof PortfolioTableRow = "assets__Code";
    const pairedCurrency: keyof PortfolioTableRow = "fiatAssets__Code";

    const portfolioTable = useTable<PortfolioTableRow, any>({
        url: endpoints.exchangemodule.portfolioList,
        filters: `${assetCode} EQ (${crypto}) | ${pairedCurrency} EQ (${fiat})`,
        multiSort: false,
    });

    return (
        <div className="CryptoHoldings">
            <h2>{`My ${selectedCurrencyInfo!.name} (${selectedCurrencyInfo?.ticker})`}</h2>
            {selectedFiatCurrency && (
                <ExchangePortfolioTable
                    idColumn={'assets__Id'}
                    table={portfolioTable as TableProps['table']}
                    selectedCurrency={selectedFiatCurrency}
                    isLoading={portfolioTable.loading}
                    hideFiltering
                />
            )}
        </div>
    );
};

export { CryptoHoldings };
