import Button from "components/button/Button";
import FormTextField from "components/form/FormTextField";
import { Spinner } from "components/spinner/Spinner";
import { ThemedLink } from "components/themedLink/ThemedLink";
import { Formik, Form } from "formik";
import { EmailVerificationProps, EmailVerificationCodePayload } from "pages/register/models";
import { postEmailVerificationCode } from "pages/register/RegisterApi";
import { handleTFAError } from "pages/register/RegistrationErrorHandling";
import React from "react";
import { useState } from "react";
import { dispatch } from "rxjs/internal/observable/pairs";


type EmailNeedsVerificationProps = {
    accountDetails: any;
    formikProps: any;
}

export const EmailNeedsVerification: React.FC<EmailNeedsVerificationProps> = ({ accountDetails, formikProps }) => {

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);


    const handleEmailCode = (resendCode: boolean, values: EmailVerificationCodePayload) => () => {

        setLoading(true);
        (async () => {
            const result = await postEmailVerificationCode(resendCode ? { ...values, bResendCode: true, emailVerificationCode: '' } : values);
            if (result.response) {
                setLoading(false)
            }
            if (result.errors && result.errors.length > 0) {
                setError(handleTFAError(result.errors[0]))
                setLoading(false)
            }
        })();
    };

    React.useEffect(() => {

        handleEmailCode(true, { username: accountDetails.emailAddress, password: accountDetails.password, bResendCode: true, emailVerificationCode: '' })()
    }, []);

    const handleResetError = () => {
        setError(null);
    }

    return (
        <div className="RegistrationForm WidthContent HeightContent">
            <Form onChange={handleResetError}>
                <div className="RegisterFormWidth">
                    <h1>Email Verification</h1>
                    <p>Enter the code sent to your email address</p>
                    <FormTextField
                        field={'emailVerificationCode'}
                        label={'Your verification code'}
                        required={true}
                        maxLength={6}
                    />
                    <ThemedLink onClick={handleEmailCode(true, formikProps.values)} className="RegisterLink">Resend code</ThemedLink>
                    {error &&
                        <div className="ErrorMessage">{error}</div>
                    }
                    <Button priority="primary" variety="full" type="submit" disabled={loading}>
                        {loading ?
                            <Spinner color={'#fff'} /> :
                            'Continue'
                        }
                    </Button>
                </div>
            </Form>
        </div>
    );
};