import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { CurrencyExchangeRateWidget } from 'components/currencyExchange/CurrencyExchangeRateWidget';
import FormTextField from 'components/form/FormTextField';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Formik } from 'formik';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, openModal, selectModalState } from 'reducers/modal';

type Props = {};

export const CurrencyExchangeChartModal = (props: Props) => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    useEffect(() => {
        if (modalState.modalType !== ModalTypes.CURRENCY_EXCHANGE_CHART) return;
    }, [modalState, dispatch]);
    if (modalState.modalType !== ModalTypes.CURRENCY_EXCHANGE_CHART) return null;
    const { baseAsset, exchangeAsset, baseAssetId, exchangeAssetId } = modalState.data;
    return (
        <Modal
            title={`${baseAsset} / ${exchangeAsset}`}
            className="CurrencyExchangeChartModal"
            customButtons={
                <div className="ModalNavigation">
                    <Button
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: ModalTypes.CURRENCY_EXCHANGE,
                                    data: { ...modalState.data, exchangeType: 'Sell', amount: 0 },
                                })
                            )
                        }
                    >
                        Buy {exchangeAsset}
                    </Button>
                    <Button
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: ModalTypes.CURRENCY_EXCHANGE,
                                    data: { ...modalState.data, exchangeType: 'Buy', amount: 0 },
                                })
                            )
                        }
                    >
                        Sell {exchangeAsset}
                    </Button>
                </div>
            }
        >
            <CurrencyExchangeRateWidget baseCurrency={baseAsset} exchangeCurrency={exchangeAsset} />
        </Modal>
    );
};
