import { Link } from '@reach/router';
import { DashboardPath } from '../../../appConstants';
import { useBasePath } from '../../../helpers/useBasePath';
import { TextCurrencyIcon } from '../../../components/currencyIcon/CurrencyIcon';
import { selectComponentResources } from '../../../reducers/componentResources';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import chevron from '../../../assets/ibanera/Icon_Dropdown_01.png';
import { useFetchBulkConfig } from './helpers';

type Props = {
    isLinkActive?: (link: string) => boolean;
    mobileMenu?: boolean;
};

export const BulkTransferSideNavLink: React.FC<Props> = ({ isLinkActive, mobileMenu = false }) => {
    const { customerMenuLinks, userInfo } = useSelector(selectComponentResources);
    const canBulkTransfer = userInfo?.bCanBulkTransfer;
    const { accounts } = useFetchBulkConfig();

    const accountLinks = useMemo(
        () =>
            (customerMenuLinks ?? [])?.filter(
                (link) => link.position === 'LeftMenu' && link.elementType === 'Accounts'
            ),
        [customerMenuLinks]
    );

    const pattern = /^accounts\/\d+\/movemoney$/;
    const canTransfer = accountLinks.some((acc) =>
        acc.accounts?.some((acc) => acc.childElements.some((child) => pattern.test(child.path)))
    );

    const basePath = useBasePath();

    const bulkTransferLink = `${basePath}${DashboardPath.BULK_TRANSFER}`;
    const linkActive = isLinkActive ? isLinkActive(bulkTransferLink) : false;

    if (!canTransfer || !canBulkTransfer || accounts.length === 0) return null;
    return (
        <>
            <Link
                className={`BulkTransferNavLink ${mobileMenu ? 'MobileMenu' : 'SideMenuLink'} ${
                    linkActive ? 'Active' : ''
                }`}
                to={bulkTransferLink}
            >
                <TextCurrencyIcon currencySymbol={'B'} active={linkActive} />
                <div className="LinkDetails">Bulk Transfer</div>
                <img src={chevron} alt="chevron" className="Chevron" />
            </Link>
            <div className="DividingLine SmallPad" />
        </>
    );
};
