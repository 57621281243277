import React, { lazy } from 'react';
import { RouteComponentProps } from '@reach/router';
import { endpoints } from '../../endpoints.config';
import DeleteModal from '../../components/DeleteModal';
import Category from '../../components/Category';
import { CrudItems } from 'components/Category/CrudMenu';
import { localizedFormatDate } from '../../helpers/categoryHelpers/userLocale';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { AppPath, DashboardPath } from 'appConstants';
import { selectCultureCode } from 'reducers/language';
import { useSelector } from 'react-redux';

// Icons
import View from '../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../assets/Cat-Entity/Icon_Action_02.png';
import Delete from '../../assets/Cat-Entity/Icon_Action_03.png';

const BusinessUser = lazy(() => import('./ManageBusinessUser'));

const url = endpoints.manageBusinessUsersModule.listusers;

// Define which column on the table represents each row's ID.
const idColumn = 'customerUsers__Id';

//Define the data structure for table
export type MBUData = {
    customerUsers__Id: number;
    customerUsersCustomers__Id: number;
    customerUsers__CustomerUsersCode: string;
    siteUsers__EmailAddress: string;
    customerUsers__FirstName: string;
    customerUsers__LastName: string;
    siteUsers__bSuppressed: boolean;
    customerUsers__AddDate: string;
};

const crudItems: CrudItems<MBUData> = [
    {
        icon: View,
        title: 'Add Business User',
        imgAltText: 'Create',
        menuCode: 'CREATE',
        showInMultiSelect: false,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <BusinessUser
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="create"
                submitButtonText="Add"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
        notInMenus: true,
    },
    {
        icon: View,
        title: 'View business user',
        imgAltText: 'View',
        //permissionsKey: 'view',
        menuCode: 'VIEW',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <BusinessUser
                onDone={onDone}
                selectedData={selectedData}
                idFromRowOption={menu.selectedId}
                actionType="view"
                submitButtonText="Close"
                noSubmit={true}
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: Update,
        imgAltText: 'Update',
        //permissionsKey: 'edit',
        title: 'Update business user',
        menuCode: 'UPDATE',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <BusinessUser
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="edit"
                submitButtonText="Update"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: Delete,
        imgAltText: 'Delete',
        //permissionsKey: 'delete',
        title: (data) =>
            data.length <= 1 ? 'Delete business user' : `Delete ${data.length} business users `,
        menuCode: 'DELETE',
        showInMultiSelect: true,
        content: (onActionComplete, selectedData, menu) => (
            <DeleteModal
                url={endpoints.manageBusinessUsersModule.deleteusers}
                noun="business user"
                selectedIds={selectedData.map((d) => d.customerUsers__Id)}
                renderListItem={(data) =>
                    data.customerUsers__FirstName + ' ' + data.customerUsers__LastName
                }
                selectedData={selectedData}
                open={menu.currentMenu === 'DELETE'}
                close={onActionComplete}
                idFromRowOption={menu.selectedId}
            />
        ),
        inRightPanel: false,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name
const rowFormatter = (row: MBUData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.customerUsers__AddDate) {
        formattedRow = {
            ...formattedRow,
            customerUsers__AddDate: localizedFormatDate(new Date(row.customerUsers__AddDate)),
        };
    }
    return formattedRow;
};

export interface BusinessUsersProps {}

type Props = BusinessUsersProps & RouteComponentProps;

function ManageBusinessUsers(props: Props) {
    const cultureCode = useSelector(selectCultureCode);
    useDefaultRoute(`/${cultureCode}${AppPath.BUSINESS}${DashboardPath.MANAGE}/users`);
    return (
        <Category<MBUData>
            location={props.location}
            contentTitle="Business Users"
            noun="business users"
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={false}
            fetchConfig={{ withCredentials: false }}
            classname="listsCatTable"
            emptyTableLabel="No users found"
        />
    );
}

export default ManageBusinessUsers;
