import React from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { getCultureCodeFromLocalStorage } from '../../helpers/cache';
import { Link } from '@reach/router';
import { useTheme } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectImagePath } from '../../reducers/ui';
import RememberMeInput from '../../components/form/RememberMeInput';
import FormTextField from '../../components/form/FormTextField';
import Button from '../../components/button/Button';
import { selectCultureCode } from '../../reducers/language';
import { selectAuthMessage, selectAuthStatus, signIn, SignInAttempt } from '../../reducers/auth';
import { AppPath, MAINTENANCE_WARNING } from '../../appConstants';
import { SignInTranslation } from './signInTypes';
import { Spinner } from 'components/spinner/Spinner';
import { getErrorMessage } from 'errors';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';

type Props = {
    translations: SignInTranslation | null;
};

const SignInForm: React.FC<Props> = ({ translations }) => {
    const authMessages = useSelector(selectAuthMessage);
    const authMessageToShow =
        authMessages && authMessages.length > 0 && getErrorMessage(authMessages[0].messageCode);
    const authStatus = useSelector(selectAuthStatus);

    const { values } = useFormikContext<SignInAttempt>();

    const theme = useTheme();
    const imagesPath = useSelector(selectImagePath);

    const cultureCode = useSelector(selectCultureCode);

    const SignInFormCSS = css`
        .Btn {
            background: ${theme.colors.first};
        }

        a {
            color: ${theme.colors.second};
        }

        input[type='checkbox']:checked + .CustomInput:after {
            background-image: url('../..${imagesPath}/Icon_Tick.png');
        }
    `;

    return (
        <Form className="WidthContent AuthFormContainer">
            <div className="AuthForm" css={SignInFormCSS}>
                <h1>{translations?.heading1 ?? ''}</h1>
                <p>{translations?.sub_heading1 ?? ''}</p>
                <div className="FieldsContainer">
                    <FormTextField
                        field={'username'}
                        label={translations?.email_address ?? ''}
                        required={true}
                        maxLength={200}
                    />
                    <FormTextField
                        field={'password'}
                        label={translations?.password ?? ''}
                        type={'password'}
                        required={true}
                        maxLength={20}
                    />
                    {authMessageToShow && (
                        <span className="SignInErrorMessage">{authMessageToShow}</span>
                    )}
                    <div className="RememberMe">
                        <RememberMeInput
                            type="checkbox"
                            name="rememberMe"
                            id="RememberMe"
                            checked={values.rememberMe}
                        />
                        <label className="CustomInput" htmlFor="RememberMe">
                            {translations?.remember_me ?? ''}
                        </label>
                        <Link to={`/${cultureCode}${AppPath.FORGOTTEN_PASSWORD}`}>
                            <span className="ForgottenPassword">
                                {translations?.forgotten_password ?? ''}
                            </span>
                        </Link>
                    </div>
                    <Button variety="full" type="submit" disabled={authStatus === 'pending'}>
                        {translations?.sign_in_button ?? ''}
                    </Button>
                    {authStatus === 'pending' && <Spinner className="noBG-absCenter" />}


                    {MAINTENANCE_WARNING?.ACTIVE ? 

                    <div className='AlertNotification'>
                        
                        <WarningIcon className='WarningIcon'/>

                        <div className='NotificationText'>
                            <h3>{MAINTENANCE_WARNING.HEADER_MESSAGE}</h3>
                            <span className='SubText'>{MAINTENANCE_WARNING.SUBTEXT_MESSAGE}</span>
                        </div>
                    </div>
                    :
                    <></>
                    }
                    

                    <div className="Redirect">
                        <span>{translations?.no_account}&nbsp;</span>
                        <Link to={`/${cultureCode}${AppPath.REGISTER}`}>
                            {' '}
                            <span className="Register">{translations?.register}</span>
                        </Link>
                    </div>

                    
                </div>
            </div>
        </Form>
    );
};

export default SignInForm;
