import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { getErrorMessage } from 'errors';
import React, { useCallback, useState } from 'react';

export const usePost = <T>() => {
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<T | null>(null);

    const post = useCallback((url, data) => {
        return instance
            .post<ApiResponse<T>>(url, data)
            .then((res) => {
                setResponse(res.data.details);
            })
            .catch((err) => {
                if (isAxiosErrorHandled(err) && err.response.data.errors.length > 0) {
                    setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                }
                return Promise.reject(err);
            });
    }, []);

    return { post, response, errorMessage };
};
