import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import {
    selectVerificationDetailsV3,
    selectVerificationLoading,
    setVerificationStage,
} from 'reducers/verification';
import { Spinner } from 'components/spinner/Spinner';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { Form, Formik } from 'formik';
import { saveContinue, submitForm } from './helpers/helpers';
import api, { ApiResponse, isAxiosErrorHandled } from 'api';
import { validate, validateOwners } from './validation';
import {
    BusinessVerificationStage,
    VisibleBusinessSteps,
    stageTitles,
} from './BusinessVerificationV3Models';
import { verificationValidationSchema } from './validation';
import { FormValues, initialValue } from './schema';
import { processedErrors } from 'components/businessVerification/helpers/ExitContinueButtons';
import { RegistrationInformation } from './steps/RegistrationInformation';
import { OperationsInformation } from './steps/OperationsInformation';
import { VerificationContext, VerificationDropdownOptions } from './helpers/verificationContext';
import instance from 'api';
import { ExitContinueButtons } from './helpers/ExitContinueButtons';
import { SegmentedProgressBar2 } from 'components/SegmentedProgressBar/SegmentedProgressBar';
import { BeneficialOwners } from './steps/BeneficialOwners';
import { FinalSteps } from './steps/FinalSteps';
import BusinessVerificationDocuments from './steps/BusinessVerificationDocuments';
import classNames from 'classnames';
import { BusinessVerificationComplete } from './steps/BusinessVerificationComplete';

/*
Business onboarding v3 - Copied entire businessVerficationV2 file so v2 can be removed easily at a later date
*/

const PendingScreen = () => {
    return (
        <div className="Waiting">
            <Spinner />
            <h3>Please wait, we are checking your details</h3>
            <p>This may take a few minutes, we'll email you when it's done.</p>
        </div>
    );
};

export interface DocumentRejection {
    proofOfAddress: string[] | null;
    authorizedCapitalDocument: string | null;
    fundsSupportingDocuments: string[] | null;
    bDocumentsUpload: boolean;
}

export const BusinessVerificationV3 = () => {
    const dispatch = useDispatch();
    //rejectedDocuments
    const { stage, rejectedDocuments } = useSelector(selectVerificationDetailsV3); //additionalData to have the stored form data for current stage?
    const loading = useSelector(selectVerificationLoading);
    //persist error state
    const [submitValues, setSubmitValues] = useState<FormValues>();
    const [apiErrors, setApiErrors] = useState<processedErrors>([]);
    const [bManualValidate, setBManualValidate] = useState<boolean>(true);
    const [dropdownData, setDropdownData] = useState<VerificationDropdownOptions | null>(null);
    //fethcing form data state
    const [fetchingFormData, setFetchingFormData] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    const [initialValues, setInitialValues] = useState<any>(null);

    useEffect(() => {
        if (stage) {
            if (stage !== BusinessVerificationStage.Complete && !dataFetched) {
                (async () => {
                    try {
                        const response = await api.get(
                            endpoints.businessVerificationV3Module.getFormData
                        );
                        if (response.data.status === '1') {
                            setInitialValues({
                                ...response.data.details.formData,
                                businessVerificationStep:
                                    response.data.details.formData.businessVerificationStep,
                            });
                            setDataFetched(true);
                        } else if (response.data.status === '0') {
                            Toast.openGenericErrorToast();
                            dispatch(signOut());
                        }
                    } catch (error: any) {
                        if (isAxiosErrorHandled(error) && error.response.status === 404) {
                            setDataFetched(true);
                        } else {
                            Toast.openToastMessage(
                                'Something went wrong checking your verification status, please log in again',
                                ToastMessageReason.ERROR
                            );
                            dispatch(signOut());
                        }
                    }
                    setFetchingFormData(false);
                })();
            } else {
                setFetchingFormData(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage]);

    useEffect(() => {
        if (!rejectedDocuments) return;
        if (rejectedDocuments?.financialInstitutionForm) {
            dispatch(
                setVerificationStage({
                    currentStage: BusinessVerificationStage.OperationsInformation,
                })
            );
            setBManualValidate(true);
            if (!submitValues && initialValues) {
                setSubmitValues({ ...initialValues });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejectedDocuments, stage]);

    useEffect(() => {
        if (!initialValues) {
            setInitialValues(initialValue);
        }
        instance
            .get<ApiResponse<VerificationDropdownOptions>>(
                endpoints.businessVerificationV3Module.getDropdownData
            )
            .then((res) => {
                const details = res.data.details;
                details.uboReportingExemptions = details.uboReportingExemptions
                    ? [{ label: 'Select...', value: null }, ...details.uboReportingExemptions]
                    : undefined;
                setDropdownData(details);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return null;
    }

    const chooseStage = () => {
        switch (stage) {
            case BusinessVerificationStage.RegistrationInformation:
                return <RegistrationInformation />;
            case BusinessVerificationStage.OperationsInformation:
                return <OperationsInformation />;
            case BusinessVerificationStage.OwnerInformation:
                return <BeneficialOwners />;
            case BusinessVerificationStage.Terms:
                return <FinalSteps />;
            case BusinessVerificationStage.Documents:
                return <BusinessVerificationDocuments />;
            case BusinessVerificationStage.Complete:
                return <BusinessVerificationComplete />;
        }
    };

    return (
        <VerificationContext.Provider
            value={{
                selectOptions: dropdownData ?? undefined,
                rejectedDocuments,
            }}
        >
            {loading || fetchingFormData ? (
                <PendingScreen />
            ) : (
                stage && (
                    <Formik
                        initialValues={initialValues}
                        {...(apiErrors.length > 0 || bManualValidate
                            ? {
                                  validate: (values) =>
                                      validate(
                                          values,
                                          submitValues,
                                          apiErrors,
                                          verificationValidationSchema[
                                              stage as BusinessVerificationStage
                                          ],
                                          rejectedDocuments
                                      ),
                              }
                            : stage === BusinessVerificationStage.OwnerInformation
                            ? { validate: validateOwners }
                            : {
                                  validationSchema:
                                      verificationValidationSchema[
                                          stage as BusinessVerificationStage
                                      ],
                              })}
                        onSubmit={(values, helper) =>
                            stage === BusinessVerificationStage.Documents
                                ? submitForm(values, helper, dispatch, setApiErrors)
                                : saveContinue(
                                      values,
                                      helper,
                                      dispatch,
                                      stage as BusinessVerificationStage,
                                      setSubmitValues,
                                      setApiErrors
                                  )
                        }
                    >
                        {() => (
                            <Form
                                className={classNames(
                                    'BusinessVerificationForm BusinessVerificationFormV3',
                                    {
                                        Wider: stage === BusinessVerificationStage.Documents,
                                    }
                                )}
                            >
                                <SegmentedProgressBar2
                                    steps={VisibleBusinessSteps.map((step, i) => ({
                                        stepNumber: i,
                                        label: stageTitles[step],
                                    }))}
                                    currentStepNumber={
                                        stage === BusinessVerificationStage.Complete
                                            ? VisibleBusinessSteps.length - 1
                                            : VisibleBusinessSteps.findIndex((val) => val === stage)
                                    }
                                />
                                {chooseStage()}
                                {stage !== BusinessVerificationStage.Complete && (
                                    <ExitContinueButtons />
                                )}
                            </Form>
                        )}
                    </Formik>
                )
            )}
        </VerificationContext.Provider>
    );
};
