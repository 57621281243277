import { useSelector } from 'react-redux';
import { selectAllCryptoPrices } from '../../reducers/cryptoPrices';
import { useMemo } from 'react';
import { QuantityType } from './models';
import { FormValues } from 'components/modals/CryptoExchangeModal';

/*
Because you only ever get one asset pair eg BTC / USD and not the reverse eg USD / BTC
If we want to display the UX as BTC / USD then we we need to reverse a lot of the UI.
We can't just switch asset and paired asset because the BE doesn't have the pair the 
other way around. 
Hence to not clutter the files these helpers are used to abstract some of it away
*/

const destinationFieldMapper = (quantityType: QuantityType): keyof FormValues => {
    return quantityType === 'Destination' ? 'destinationAmount' : 'estimatedDestinationAmount';
};

const sourceFieldMapper = (quantityType: QuantityType): keyof FormValues => {
    return quantityType === 'Source' ? 'sourceAmount' : 'estimatedSourceAmount';
};

export const generateTileHeader = (asset: string, pairedAsset: string, reverse: boolean) =>
    !reverse ? `${asset} / ${pairedAsset}` : `${pairedAsset} / ${asset}`;
export const topBuySellString = (isBuyAction: boolean, reverseUX: boolean, isExactPrice: boolean) =>
    `You're ${
        !reverseUX ? (isBuyAction ? 'buying' : 'selling') : isBuyAction ? 'selling' : 'buying'
    } ${isExactPrice ? 'exactly ' : ''}`;

export const bottomBuySellString = (
    isBuyAction: boolean,
    reverseUX: boolean,
    isExactPrice: boolean
) =>
    `You'll be ${
        !reverseUX ? (!isBuyAction ? 'buying' : 'selling') : !isBuyAction ? 'selling' : 'buying'
    } ${isExactPrice ? 'exactly ' : ''}`;
export const buttonString = (
    _isBuyAction: boolean,
    reverseUX: boolean,
    quantityType: QuantityType
) => {
    const isBuyAction = reverseUX ? !_isBuyAction : _isBuyAction;
    return `${
        isBuyAction
            ? quantityType === 'Destination'
                ? 'Buy'
                : 'Spend'
            : quantityType === 'Destination'
            ? 'Receive'
            : 'Sell'
    } Exact`;
};

export const getFieldNames = (
    isBuyAction: boolean,
    isReverseUX: boolean,
    quantityType: QuantityType
) => {
    const adjustedIsBuyAction = isReverseUX ? !isBuyAction : isBuyAction;
    // if buy, then we want a source field bottom
    return adjustedIsBuyAction
        ? { top: destinationFieldMapper(quantityType), bottom: sourceFieldMapper(quantityType) }
        : { top: sourceFieldMapper(quantityType), bottom: destinationFieldMapper(quantityType) };
};

export const useGetAssetPrecision = (asset: string) => {
    const allCryptoPrices = useSelector(selectAllCryptoPrices);

    const assetArray = useMemo(
        () => Object.entries(allCryptoPrices).find(([key]) => key.split('/')[0] === asset),
        // Precision won't update so no need to recalculate
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const assetDetails = (assetArray ?? []).length > 0 ? assetArray![1] : undefined;

    return assetDetails?.maxDecimalPrecision ?? 2;
};

export const getPrecisionRounding = (
    isExactTop: boolean,
    assetPrecision: number,
    pairedAssetPrecision: number,
    reverseUX: boolean
) =>
    !reverseUX
        ? isExactTop
            ? pairedAssetPrecision
            : assetPrecision
        : isExactTop
        ? assetPrecision
        : pairedAssetPrecision;
