import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import { DashboardPath } from 'appConstants';
import { ElementType, SideMenuLink } from 'components/sideMenu/SideMenu';
import { removeLeadingSlash } from 'helpers/removeLeadingSlash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectisVerified } from 'reducers/verification';

type Props = {
    sub_page?: string;
} & RouteComponentProps;

export enum HomeSubPage {}

export const HomePage: React.FC<Props> = ({ sub_page }) => {
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const isVerified = useSelector(selectisVerified);
    const location = useLocation();
    if (!isVerified) {
        return null;
    }
    if (customerMenuLinks) {
        const redirectPath = getFirstValidPath(customerMenuLinks, [
            'Accounts',
            'CryptoAsAccounts',
            'FundingAccounts',
        ]);
        if (redirectPath) {
            return <Redirect to={`../../${redirectPath}`} noThrow />;
        }
    }
    // if we don't have any accounts to show (e.g. if business isn't verified), check if there is a business management section
    const manageBusinessPath = customerMenuLinks?.find(
        (parentLink) => parentLink.path === removeLeadingSlash(DashboardPath.MANAGE)
    )?.childElements?.[0]?.path;
    if (manageBusinessPath) {
        return <Redirect to={`../../${manageBusinessPath}`} noThrow />;
    }
    return null;
};

/**
 *Looks through the links provided, and returns the path to the first valid account whose elementType is in the elementType array.
 *Link priority is determined by the order of the elementTypes array
 **/
const getFirstValidPath = (links: SideMenuLink[], elementTypes: ElementType[]) => {
    const defaultLinks: { [key in ElementType]?: string } = {};
    links.forEach((link) => {
        if (link.elementType && elementTypes.includes(link.elementType)) {
            defaultLinks[link.elementType] =
                link.accounts && link.accounts?.[0].childElements?.[0].path;
        }
    });
    const firstValidElementType = elementTypes.find((element) => defaultLinks[element]);
    return firstValidElementType ? defaultLinks[firstValidElementType] : undefined;
};
