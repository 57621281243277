import React, { createContext, SetStateAction, useEffect, useState } from 'react';
import { Trade } from './Trade';
import { History } from './History';
import { AddFunds } from './AddFunds';
import { endpoints } from 'endpoints.config';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { useSelector } from 'react-redux';
import { parseSignalRPriceUpdate } from 'helpers/parseSignalRPriceUpdate';
import { selectAllCryptoPrices } from 'reducers/cryptoPrices';
import { CryptoExchangePage } from '../cryptoV2/CryptoExchangePage';
import { Store } from '../../reducers/rootReducer';
import { CryptoOtcDeskVersion } from '../../reducers/appSettings';
import { useGetAccountDetailsForCrypto } from 'helpers/useGetAccountDetails';
import { MonthlyStatements } from 'components/monthlyStatements/MonthlyStatements';
import { AccountContextProvider } from 'pages/account/AccountContext';

export type CurrencyOption = {
    value: string;
    label: string;
};

export type FiatCurrency = {
    code: string;
    symbol: string;
};

export const DEFAULT_SELECT_OPTION = { value: 'USD', label: 'USD' };

export type SelectedCryptoData = {
    open: boolean;
    data: CryptoLiveData | null;
};

export enum CryptoSubPage {
    BUY = 'buy',
    SELL = 'sell',
    TRADE = 'trade',
    PORTFOLIO = 'portfolio',
    HISTORY = 'history',
    MONTHLY_STATEMENTS = 'monthlystatements',
}

export type CryptoLiveData = {
    pair: string;
    buyPrice: number | null;
    sellPrice: number | null;
    name: string;
    ticker: string;
    low24hrs: number | null;
    high24hrs: number | null;
    priceVariability: number | null;
};

export type CryptoPairData = {
    pair: string;
};

export const parseLowHighString = (lowHighString: string) => {
    const splitString = lowHighString.split('-');
    return {
        low: parseFloat(splitString[0]),
        high: parseFloat(splitString[1]),
    };
};

export type CryptoContextType = {
    currency: string;
    setCurrency: React.Dispatch<SetStateAction<string>>;
    cryptos: CryptoLiveData[];
    setCryptos: React.Dispatch<SetStateAction<CryptoLiveData[]>>;
    recentMessage: any;
    setRecentMessage: React.Dispatch<SetStateAction<any>>;
    currencyFormat: any;
    setCurrencyFormat: React.Dispatch<SetStateAction<any>>;
    selectedCryptoPair: string | null;
    setSelectedCryptoPair: React.Dispatch<SetStateAction<string | null>>;
    addFunds: boolean;
    setAddFunds: React.Dispatch<SetStateAction<boolean>>;
    assetPairs: string[];
    setAssetPairs: React.Dispatch<SetStateAction<string[]>>;
    searchString: string;
    setSearchString: React.Dispatch<SetStateAction<string>>;
    fiatCurrencies: FiatCurrency[];
    setFiatCurrencies: React.Dispatch<SetStateAction<FiatCurrency[]>>;
    selectedFiatCurrency: FiatCurrency | null;
    setSelectedFiatCurrency: React.Dispatch<SetStateAction<FiatCurrency | null>>;
    endpoints: TradeEndpoints;
    isPageLoading: boolean;
    setIsPageLoading: React.Dispatch<SetStateAction<boolean>>;
};

const defaultContext: CryptoContextType = {
    currency: 'USD',
    setCurrency: () => {},
    cryptos: [],
    setCryptos: () => {},
    recentMessage: {},
    setRecentMessage: () => {},
    currencyFormat: {},
    setCurrencyFormat: () => {},
    selectedCryptoPair: null,
    setSelectedCryptoPair: () => {},
    addFunds: false,
    setAddFunds: () => {},
    assetPairs: [],
    setAssetPairs: () => {},
    searchString: '',
    setSearchString: () => {},
    fiatCurrencies: [],
    setFiatCurrencies: () => {},
    selectedFiatCurrency: null,
    setSelectedFiatCurrency: () => {},
    // Defaulting to crypto page endpoints to avoid null typing errors.
    endpoints: endpoints.cryptosmodule,
    isPageLoading: false,
    setIsPageLoading: () => {},
};

export const CryptoContext = createContext<CryptoContextType>(defaultContext);

export const generateCryptoLiveData = (
    name: string,
    ticker: string,
    pairedAsset: string,
    price?: number,
    low?: number,
    high?: number
): CryptoLiveData => {
    return {
        pair: `${ticker}/${pairedAsset}`,
        buyPrice: price ?? null,
        sellPrice: price ?? null,
        name,
        ticker: ticker,
        low24hrs: low ?? null,
        high24hrs: high ?? null,
        priceVariability: null,
    };
};

export type TradeEndpoints = typeof endpoints.cryptosmodule;

interface CryptoPageProps {
    subPage: string | CryptoSubPage;
    crypto?: string;
    endpoints: TradeEndpoints;
}

export const CryptoPage: React.FC<CryptoPageProps> = ({ subPage, crypto, endpoints }) => {
    const [addFunds, setAddFunds] = useState<boolean>(false);
    const [recentMessage, setRecentMessage] = useState<any>(null);
    const [currency, setCurrency] = useState<string>('USD');
    const [currencyFormat, setCurrencyFormat] = useState<any>(null);
    const [selectedCryptoPair, setSelectedCryptoPair] = useState<string | null>(null);
    const [assetPairs, setAssetPairs] = useState<string[]>([]);
    const [fiatCurrencies, setFiatCurrencies] = useState<FiatCurrency[]>([]);
    const [selectedFiatCurrency, setSelectedFiatCurrency] = useState<FiatCurrency | null>(null);
    const [searchString, setSearchString] = useState('');
    const [cryptos, setCryptos] = useState<CryptoLiveData[]>([]);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const prices = useSelector(selectAllCryptoPrices);

    const otcVersion = useSelector<Store>(
        (store) => store.componentResources.userInfo?.otcDeskVersion
    ) as CryptoOtcDeskVersion;

    useEffect(() => {
        if (prices) {
            setCryptos(parseSignalRPriceUpdate(prices));
        }
    }, [prices]);

    // number formatter.
    useEffect(() => {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        setCurrencyFormat(formatter);
    }, [currency]);

    //const ws = useWebSocket('wss://ws.kraken.com', true);

    // useEffect(() => {
    //     if (ws && assetPairs.length > 0) {
    //         ws.onopen = () => {
    //             ws.send(
    //                 JSON.stringify({
    //                     event: 'subscribe',
    //                     pair: assetPairs,
    //                     subscription: {
    //                         name: 'ticker',
    //                     },
    //                 })
    //             );
    //         };

    //         ws.onmessage = (event: any) => {
    //             const message = JSON.parse(event.data);
    //             //check if the message is an array
    //             if (message.length > 0) {
    //                 setRecentMessage(message);
    //             }
    //         };
    //         ws.onerror = (error: any) => {
    //             console.log({ error });
    //         };

    //         ws.onclose = () => {
    //             console.log('ws disconnected');
    //         };
    //     }
    // }, [ws, assetPairs]);

    /*
        SUBSCRIBE TO A TICKER
        Data object => 
        a[]: ask => best ask price, wholeLotVolume, lotVolume,
        b[]: bid => best bid price, wholeLotVol, lotVol,
        c[]: close => price, lot volume,
        v[]: volume => value today, value last 24hrs,
        p[]: VWAP => value today, value last 24hrs,
        t[]: no. of trades => value today, value last 24hrs,
        l[]: low price => today, 24hrs,
        h[]: high price => today, 24hrs,
    */
    useEffect(() => {
        if (recentMessage && cryptos) {
            const parsedData = cryptos.map((crypto) => {
                //if cryptopair matches the pair in the message
                if (recentMessage.includes(crypto.pair)) {
                    return {
                        ...crypto,
                        pair: recentMessage[3],
                        bestAsk: recentMessage[1].a[0],
                        close: recentMessage[1].c[0],
                        low24hrs: recentMessage[1].l[1],
                        high24hrs: recentMessage[1].h[1],
                        openPrice: recentMessage[1].o[1],
                    };
                }
                return crypto;
            });
            setCryptos(parsedData);
            setRecentMessage(null);
        }
    }, [recentMessage, cryptos]);

    useEffect(() => {
        setSelectedCryptoPair(null);
        setSearchString('');
        setIsPageLoading(true);
    }, [endpoints]);

    useEffect(() => {
        setSearchString('');
        if (!selectedCryptoPair) {
            setIsPageLoading(true);
        }
    }, [subPage]);

    const isOtcVersion1 = otcVersion === CryptoOtcDeskVersion.v1;
    const accountId = useGetAccountDetailsForCrypto(crypto ?? '')?.id;

    useEffect(() => {
        if (subPage === ('trade' || undefined) && !isOtcVersion1) setIsPageLoading(false);
        if (subPage === CryptoSubPage.MONTHLY_STATEMENTS && accountId) setIsPageLoading(false);
    }, [isPageLoading, isOtcVersion1, subPage, accountId]);

    return (
        <CryptoContext.Provider
            value={{
                cryptos,
                setCryptos,
                currency,
                setCurrency,
                recentMessage,
                setRecentMessage,
                currencyFormat,
                setCurrencyFormat,
                selectedCryptoPair,
                setSelectedCryptoPair,
                addFunds,
                setAddFunds,
                assetPairs,
                setAssetPairs,
                searchString,
                setSearchString,
                fiatCurrencies,
                setFiatCurrencies,
                selectedFiatCurrency,
                setSelectedFiatCurrency,
                endpoints,
                isPageLoading,
                setIsPageLoading,
            }}
        >
            <AccountContextProvider accountId={accountId?.toString() ?? ''}>
                {addFunds ? (
                    <AddFunds setAddFunds={setAddFunds} />
                ) : subPage === ('trade' || undefined) && !addFunds ? (
                    isOtcVersion1 ? (
                        <Trade />
                    ) : (
                        <CryptoExchangePage />
                    )
                ) : subPage === CryptoSubPage.HISTORY ? (
                    <History ticker={crypto} />
                ) : subPage === CryptoSubPage.MONTHLY_STATEMENTS && accountId ? (
                    <MonthlyStatements accountNumber={accountId.toString()} />
                ) : null}
                {isPageLoading && <SubPageLoader message={`Loading ${subPage}`} />}
            </AccountContextProvider>
        </CryptoContext.Provider>
    );
};
