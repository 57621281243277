import React from 'react';
import { Modal } from 'components/modal/Modal';
import axios from 'api';
import { Spinner } from 'components/circleSpinner/Spinner';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import warningIcon from '../../assets/Cat-Entity/Icon_Notification_Warning.png';
import { closeModal } from 'reducers/modal';
import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';

type FailedDeletion = {
    id: number;
    reason: string;
};

type DeletionResponse = {
    successfulDeletions: number[];
    failedDeletions: FailedDeletion[];
};

type ErrorDeletion = {
    hasError: boolean;
    failedDeletions: FailedDeletion[];
};

type Props<Data> = {
    open: boolean;
    close(): void;
    url: string;
    selectedData: (Data | undefined)[];
    selectedIds: React.ReactText[];
    idFromRowOption: React.ReactText | null;
    noun: string;
    pluralNoun?: string;
    renderListItem(data: Data): string;
};

function DeleteModal<Data>(props: Props<Data>) {
    const [submitting, setSubmitting] = React.useState(false);
    const [errorState, setErrorState] = React.useState<ErrorDeletion | undefined>();

    const { open, close, noun, selectedIds, selectedData, idFromRowOption, renderListItem } = props;

    const pluralNoun = props.pluralNoun ?? noun + 's';

    const idsToDelete = idFromRowOption ? [idFromRowOption] : selectedIds;

    const handleDelete = () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);
        const payload = { idsToDelete };
        axios
            .post<ApiResponse<any, DeletionResponse>>(props.url, payload, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status === '1' && res.data.details.failedDeletions.length === 0) {
                        close();
                    } else {
                        // Failed deleting some users
                        setErrorState({
                            hasError: true,
                            failedDeletions: res.data.details.failedDeletions,
                        });
                    }
                }
            })
            .catch((err) => {
                // Generic error
                setErrorState({ hasError: true, failedDeletions: [] });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const dispatch = useDispatch();

    return (
        <Modal
            title={`Delete ${noun[0] + noun.slice(1)}`}
            onCloseModal={close}
            customButtons={
                <div className="ModalNavigation">
                    <Button
                        priority="secondary"
                        type="button"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        priority="primary"
                        type="submit" //disabled={formikProps.isSubmitting}
                        onClick={handleDelete}
                        className="Btn BtnRed"
                    >
                        Delete
                    </Button>
                </div>
            }
        >
            <div className="DeleteModal">
                <h4>Permanently Delete</h4>

                <p>
                    {`Are you sure you want to permanently delete ${
                        idsToDelete.length > 1 ? `these ${pluralNoun}` : `this ${noun}`
                    }?`}
                </p>

                <p className="BoldText">
                    You'll permanently lose all information about{' '}
                    {idsToDelete.length > 1 ? `these ${pluralNoun}` : `this ${noun}`}
                </p>

                {idsToDelete.length > 1 ? <p>Selected {pluralNoun}:</p> : <p>Selected {noun}:</p>}

                <ul>
                    {selectedData.map((user, i) => (
                        <li key={i}>{user && renderListItem(user)}</li>
                    ))}
                </ul>

                <div className="DeleteModalWarning">
                    <img src={warningIcon} alt="warning" />

                    <p>This action cannot be undone</p>
                </div>
                {errorState?.hasError && (
                    <>
                        {errorState.failedDeletions.length > 0
                            ? `Error deleting the following ${pluralNoun}:`
                            : `Error deleting the following ${noun}:`}
                        <ul>
                            {errorState.failedDeletions.map((d, i) => (
                                <li key={i}>
                                    {d.id}: {d.reason}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {submitting && <Spinner positionAbsolute />}
            </div>
        </Modal>
    );
}

export default DeleteModal;
