import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { Cell, CellOptions } from './Cell';
import { makeRow, RowConfig, Checkbox, SingleCheckbox } from './FlexTable';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { TableInfo } from 'api';
import FilterIcon from 'assets/ibanera/Icon_Btn_04.png';
import FilterActiveIcon from 'assets/ibanera/Icon_Btn_05.png';

type RightTableProps<T = any> = {
    idColumn: string;
    columns: ColumnDetail<any>[];
    rawListData: any[];
    rowHovered: number | null;
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick?(id: number): void;
    checkbox?: Checkbox;
    onFilterClick?(): void;
    rowButton?(data: any): React.ReactNode;
    rowOptionComponent?(data: any): React.ReactNode;
    hiddenColumns?: string[];
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    columnCount: number;
    hideFiltering?: boolean;
    buttonInMiddleTable?: boolean;
    rowCellOptions?: { [K in keyof T]?: CellOptions };
    rowConfig?: RowConfig;
    table: TableInfo;
    //showFiltering?: boolean;
    singleCheckbox?: SingleCheckbox;
};

const RightTable: React.FC<RightTableProps> = ({
    idColumn,
    columns,
    rawListData,
    rowHovered,
    handleMouseEnterRow,
    checkbox,
    onRowClick,
    onFilterClick,
    rowButton,
    rowOptionComponent,
    hiddenColumns,
    formatPrimitives,
    rowFormatter,
    noRowHover,
    columnCount,
    hideFiltering,
    buttonInMiddleTable,
    //showFiltering,
    rowCellOptions = {},
    table,
    singleCheckbox,
}) => {
    const selectedColumns = columns
        .filter((c) => !hiddenColumns?.includes(c.columnKey.toString().toLowerCase()))
        .slice(columns.length - columnCount);

    const processedRowData = rawListData.map((data) => {
        let rowData = {} as any;
        selectedColumns.forEach((col) => {
            rowData = {
                ...rowData,
                [col.columnKey]: data[toCamelCase(col.columnKey as string)] ?? data[col.columnKey],
            };
        });
        return { id: data[idColumn].toString(), ...rowData };
    });

    const rowButtonData = rowButton ? rawListData.map((data) => rowButton(data)) : [];
    const rowOptionData = rowOptionComponent
        ? rawListData.map((data) => rowOptionComponent(data))
        : [];

    const handleFilterClick = onFilterClick ?? (() => {});
    const filters = table.data && table.data.details.filters.length > 0;

    return (
        <div className="RightTable">
            <div className="Head">
                {selectedColumns.map((c, i) => {
                    const sortByValue =
                        table.data && (table.data.details.sortBy[String(c.columnKey)] ?? 'NONE');
                    const toggleColumnSort =
                        table.data && (table.data.actions.toggleColumnSort as (s: string) => void);

                    const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                    const { bSortable } = c;

                    return (
                        <Cell
                            key={i}
                            options={rowCellOptions[toCamelCase(c.columnKey as string)]}
                            sortable={bSortable}
                            onClick={() =>
                                bSortable &&
                                toggleColumnSort &&
                                toggleColumnSort(String(c.columnKey))
                            }
                        >
                            <span className={bSortable && isSorting ? 'Sorting' : ''}>
                                {c.labelValue}
                            </span>
                            {bSortable && (
                                <i
                                    className={
                                        sortByValue === 'ASC'
                                            ? 'SortIcon ASC'
                                            : sortByValue === 'DESC'
                                            ? 'SortIcon DESC'
                                            : 'SortIcon'
                                    }
                                />
                            )}
                        </Cell>
                    );
                })}
                {rowButton && !buttonInMiddleTable && (
                    <Cell options={{ cellType: 'buttonCell', noSortIcon: true }} />
                )}
                {/* {rowOptionComponent && (
          <Cell options={{ cellType: "rowOptionCell", noSortIcon: true }} />
        )} */}
                {hideFiltering === false && (
                    <Cell
                        options={{
                            //cellType: !showFiltering ? 'filterCell' : 'filterCell2',
                            // cellType: 'filterCell',
                            cellType: 'filterCell-tableHeader',
                            noSortIcon: true,
                        }}
                    >
                        <img
                            className="FilterIcon"
                            src={filters ? FilterActiveIcon : FilterIcon}
                            alt="Filter"
                            onClick={handleFilterClick}
                        />
                    </Cell>
                )}
            </div>
            <div className="TableBody">
                {processedRowData.map((d, i) =>
                    makeRow({
                        id: d.id,
                        r: d,
                        index: i,
                        columns,
                        formatPrimitives,
                        hiddenColumns: hiddenColumns ?? [],
                        rowHovered,
                        handleMouseEnterRow,
                        onRowClick: onRowClick
                            ? () => onRowClick(rawListData.find((_, j) => j === i)[idColumn])
                            : null,
                        rowButton:
                            rowButton && !buttonInMiddleTable ? () => rowButtonData[i] : undefined,
                        formatRow: rowFormatter,
                        checkbox,
                        noRowHover,
                        rowOptionComponent: rowOptionComponent ? () => rowOptionData[i] : undefined,
                        rowCellOptions,
                        rowSelected:
                            checkbox?.selectedIds.includes(d.id) ||
                            checkbox?.selectedIds.includes(d.id.toString()) ||
                            checkbox?.selectedIds.includes(parseInt(d.id, 10)),
                        hideRowOptionComponent: checkbox ? checkbox.selectedIds.length > 0 : false,
                        includeFilterCell: !rowOptionComponent,
                        singleCheckbox,
                    })
                )}
            </div>
        </div>
    );
};

export { RightTable };
