// https://stackoverflow.com/a/2901298
export const formatPriceWithCommas = (price: number | string, precision: number = 6) => {
    const typedPrice = typeof price === 'string' ? parseFloat(price) : price;
    let newprecision =
        Math.log10(typedPrice) > precision ? Math.ceil(Math.log10(typedPrice)) + 2 : precision;
    const priceToPrecision = typedPrice.toPrecision(newprecision);
    if (!priceToPrecision.includes('.')) {
        return priceToPrecision;
    }
    const priceWithPaddingZero = priceToPrecision.concat(
        `${priceToPrecision.split('.')[1].length === 1 ? '0' : ''}`
    );
    const preceedingDecimalPointWithCommas = priceWithPaddingZero
        .split('.')[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return preceedingDecimalPointWithCommas.concat('.').concat(priceWithPaddingZero.split('.')[1]);
};

export const formatPriceWithLocale = (
    price: number | string | undefined,
    locale: string,
    decimalPlaces?: number,
    maxDecimal?: number
) => {
    const typedPrice = typeof price === 'string' ? parseFloat(price) : price;

    return (typedPrice ?? 0).toLocaleString(locale, {
        minimumFractionDigits: decimalPlaces || 2,
        maximumFractionDigits: maxDecimal ?? decimalPlaces,
    });
};
