import { CheckboxInput } from 'components/form/CheckboxInput';
import { useField } from 'formik';
import { ErrorM } from '../../form/ErrorM';
import BackButton from '../helpers/BackButton';

export const FinalSteps = () => {
    return (
        <div>
            <BackButton />
            <h2>Final Steps</h2>
            <ButtonCheckboxInput
                name={fieldName('bTaxAcknowledgement')}
                label="Tax Acknowledgement"
                text="I confirm that the entity applying for the account is up to date and compliant with all applicable tax laws and regulations for which the entity would be responsible"
            />
            <ButtonCheckboxInput
                name={fieldName('bFatcaAcknowledgement')}
                label="FATCA Acknowledgement"
                text="I do confirm that I am in full compliance with the Foreign Account Tax Compliance Act (FATCA)"
            />
            <ButtonCheckboxInput
                name={fieldName('bConfirmTrueAndCorrect')}
                label="Information Supplied is True and Correct"
                text="I confirm that the information provided is true and correct. I will notify, in writing, any changes to the information provided. I am aware that all or part of the information provided in this questionnaire may be transferred to the tax administrator or to any other institution in accordance with international treaties or agreements, as well as the United States regarding automated exchange of information on financial accounts."
            />
            <ButtonCheckboxInput
                name={fieldName('bAcceptTerms')}
                label="Accept Services of Ibanera LLC"
                text="I agree to use the services of Ibanera LLC only for legitimate purposes. I acknowledge that false or incorrect information provided could require reports to regulators or law enforcement resulting in termionation of business relations"
            />
        </div>
    );
};
const fieldName = (name: string) => `terms.${name}`;

export const ButtonCheckboxInput = ({
    name,
    label,
    text,
    showErrorMessage = false,
}: {
    name: string;
    label?: string;
    text: string;
    showErrorMessage?: boolean;
}) => {
    const [, { error, touched }] = useField(name);

    const hasError = !!(error && touched);
    return (
        <div className="FormBox">
            {label && (
                <div className="FormLabel">
                    <label>{label}</label>
                </div>
            )}
            <div className={`FormField ButtonCheckboxInput ${hasError ? 'Error' : ''}`}>
                <CheckboxInput name={name} label={text} />
            </div>
            {showErrorMessage && <ErrorM name={name} />}
        </div>
    );
};
