import { NotificationsConfig } from 'components/notifications/NotificationsConfig';
import React from 'react';

export const NotificationsPage = () => {
    return (
        <div>
            <NotificationsConfig />
        </div>
    );
};
