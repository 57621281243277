import { useField } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type ManualErrorsProps = {
    errors: { [key: string]: string };
    setManualErrors: Dispatch<
        SetStateAction<{
            [key: string]: string;
        }>
    >;
};
export const ManualErrorTracker: React.FC<ManualErrorsProps> = ({ errors, setManualErrors }) => {
    const removeError = (key: string) => {
        setManualErrors((prev) => {
            const copy = prev;
            delete copy[key];

            return copy;
        });
    };

    return (
        <>
            {Object.entries(errors).map(([key, value]) => (
                <ErrorTracker
                    key={key}
                    fieldname={key}
                    value={value}
                    removeError={() => removeError(key)}
                />
            ))}
        </>
    );
};
const ErrorTracker = ({
    fieldname,
    value,
    removeError,
}: {
    fieldname: string;
    value: string;
    removeError: () => void;
}) => {
    useHandleManualError(fieldname, value, removeError);

    return null;
};
const useHandleManualError = (fieldname: string, errorStr: string, removeError: () => void) => {
    const [{ value }, { error }, { setError }] = useField(fieldname);
    const [initialValue] = useState(value);
    const [initialFieldname] = useState(fieldname);

    useEffect(() => {
        if (!!fieldname && errorStr && !error) {
            setError(errorStr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!fieldname || initialFieldname !== fieldname || value === initialValue) return;
        removeError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
};
