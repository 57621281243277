import Button from 'components/button/Button';
import { useField } from 'formik';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { useResetableDropzone } from 'helpers/useResetableDropzone';

import UploadIcon from 'assets/ui-update/upload-01.svg';
import { DropzoneOptions, FileError } from 'react-dropzone';

type FileType = 'application/pdf' | 'image/jpeg' | 'image/png';

type Props = {
    label: string;
    fieldName: string;
    accepts?: FileType[];
    multi?: boolean;
    small?: boolean;
    disabled?: boolean;
    emptyUploadTitle?: string;
    emptyUploadMessage?: string;
    labelExtraInfo?: string;
    labelChild?: React.ReactNode;
    dropZoneOptions?: Omit<DropzoneOptions, 'disabled' | 'multiple' | 'accept'>;
};

export const FileUpload: React.FC<Props> = ({
    fieldName,
    label,
    labelChild,
    labelExtraInfo,
    multi = false,
    small = false,
    accepts = ['application/pdf', 'image/jpeg', 'image/png'],
    emptyUploadTitle = 'Upload File',
    emptyUploadMessage = 'Click or drag to upload',
    disabled = false,
    dropZoneOptions,
}) => {
    const [, { initialValue, error, touched }, { setValue, setError, setTouched }] =
        useField(fieldName);
    const { acceptedFiles, getRootProps, getInputProps, resetFiles } = useResetableDropzone(
        {
            multiple: multi,
            accept: accepts.join(','),
            disabled,
            validator: (file) => {
                // https://stackoverflow.com/questions/20690499/concrete-javascript-regular-expression-for-accented-characters-diacritics
                var validFilename =
                    /^[ a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F0-9_.@()-]+\.[^.]+$/i.test(
                        file.name
                    );
                if (!validFilename)
                    return {
                        message: `Invalid file name`,
                    } as FileError;

                if (file.name.length > 200)
                    return {
                        message: `Filename too long. Cannot be greater than 200 characters`,
                    } as FileError;

                return null;
            },
            onDropRejected: (rejections) => {
                !touched && setTouched(true);

                if (
                    rejections.some((reject) =>
                        reject.errors.some((error) => error.code === 'file-invalid-type')
                    )
                ) {
                    setError('Invalid file type uploaded');
                } else if (
                    rejections.length > 0 &&
                    rejections[0].errors.length > 0 &&
                    rejections[0].errors[0].message
                ) {
                    setError(rejections[0].errors[0].message);
                } else setError('Failed to upload file. Please try again');
            },
            ...dropZoneOptions,
        },
        fieldName
    );

    const hasError = !!(touched && error);

    const renderNames = (files: File[]) => {
        return (
            <div className="FileNames">
                {files.map((file, i) => (
                    <div key={i}>
                        {file.name} - {roundToPrecision(file.size / 1000, 2)} kB
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={`FileUpload FormBox FormSideBySide ${small ? 'Small' : ''}`}>
            <div className="FormLabel">
                {labelChild ? (
                    labelChild
                ) : (
                    <label>
                        {label}
                        {labelExtraInfo && <span className="Optional"> - {labelExtraInfo}</span>}
                    </label>
                )}
            </div>
            <div className="FormField">
                <div {...getRootProps({ className: 'Dropzone' })}>
                    <input {...getInputProps()} />
                    {Array.isArray(acceptedFiles) &&
                    (acceptedFiles.length > 0 || acceptedFiles[0]) ? ( //formikField.value->acceptedFiles
                        <>
                            {renderNames(acceptedFiles)}
                            {/* <p>{formikField.value.length} files have been uploaded</p> */}
                            <p>
                                {acceptedFiles.length}
                                {acceptedFiles.length > 1 ? ' files have ' : ' file has '}
                                been uploaded
                            </p>
                        </>
                    ) : (
                        <>
                            <img src={UploadIcon} alt="upload" />
                            <h5>{emptyUploadTitle}</h5>
                            <span>{emptyUploadMessage}</span>
                        </>
                    )}
                </div>
                <div className="DropzoneFooter">
                    <div>
                        Accepts - {accepts.map((file) => `.${file.split('/')[1]}`).join(', ')}
                    </div>
                    {acceptedFiles instanceof Array &&
                        acceptedFiles.length > 0 && ( //formikField.value->acceptedFiles
                            <Button
                                priority="secondary"
                                className="ResetBtn"
                                disabled={disabled}
                                onClick={() => {
                                    resetFiles();
                                    setValue(initialValue);
                                }}
                            >
                                Reset
                            </Button>
                        )}
                </div>
                {hasError && <p className="ErrorText NoMargin">{error}</p>}
            </div>
        </div>
    );
};
