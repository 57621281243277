import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Dropdown } from './Dropdown';
import { ErrorM } from './ErrorM';
import { deepGet } from 'helpers/deepObjUtils';
import { FormFieldTooltip } from './FormFieldTooltip';
import logger from 'helpers/logger';

export type SelectOption = { value: any; label: string };

export interface FormMultiSelectFieldProps<T> {
    fieldName: keyof T & string;
    options: SelectOption[];
    label: string;
    readOnly?: boolean;
    dropdownProps?: any;
    containerStyle?: React.CSSProperties;
    tooltip?: React.ReactNode;
}

const FormMultiSelectField = function <T = any>(props: FormMultiSelectFieldProps<T>) {
    const { fieldName, label, options, readOnly, dropdownProps, containerStyle } = props;
    const formik = useFormikContext<T>();
    const [, { touched, error }] = useField(fieldName);

    const hasError = !!(touched && error);

    const handleChange = (e: SelectOption) => {
        if (e != null) {
            if (Array.isArray(e)) {
                formik.setFieldValue(
                    fieldName,
                    e.map((e) => e.value)
                );
            } else {
                formik.setFieldValue(fieldName, [e.value]);
            }
        } else {
            formik.setFieldValue(fieldName, []);
        }
    };

    const getOptionFromValue = (value: number | string | null) => {
        return options.find(
            (o) =>
                o.value === value ||
                (value &&
                    (typeof o.value === 'number' || typeof o.value === 'string') &&
                    o.value.toString() === value.toString())
        );
    };

    const fieldValues = deepGet(formik.values, fieldName);

    const value = Array.isArray(fieldValues)
        ? fieldValues.map(getOptionFromValue).filter((v) => v != null)
        : getOptionFromValue(fieldValues);

    return (
        <div className={`DropdownContainer ${hasError ? 'ErrorField' : ''}`} style={containerStyle}>
            <div className="FormBox FormSideBySide">
                <div className="FormLabel">
                    <label htmlFor={fieldName.toString()}>{label}</label>
                </div>
                <div className="FormField">
                    <div
                        className={`SingleSelectDropdownWrapper ${
                            !!props.tooltip ? 'HasToolTip' : ''
                        }`}
                    >
                        <Dropdown
                            id={fieldName.toString()}
                            isMulti
                            onChange={handleChange}
                            options={options}
                            value={(value as any) ?? null}
                            roundedRectangle
                            readOnly={readOnly}
                            dropdownProps={dropdownProps}
                            hasError={hasError}
                            key={(value as SelectOption[])?.length}
                        />
                        {props.tooltip && <FormFieldTooltip tooltip={<>{props.tooltip}</>} />}
                    </div>
                    <ErrorM name={fieldName.toString()} />
                </div>
            </div>
        </div>
    );
};

export { FormMultiSelectField };
