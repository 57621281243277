import Button from 'components/button/Button';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Spinner } from 'components/spinner/Spinner';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthType, RegisterStep, TFAVerificationPayload, TFAVerificationProps } from './models';
import { postEnableTfa, postSmsTFASetup } from './RegisterApi';
import { handleSMSRequestError, handleTFAError } from './RegistrationErrorHandling';

export const TFAVerification: React.FC<TFAVerificationProps> = ({
    translations,
    accountDetails,
    onComplete,
    authType,
    setAuthType,
    phoneNumber,
    sharedSecret,
    setRegistrationStep,
}) => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const initialValues: TFAVerificationPayload = {
        username: accountDetails && accountDetails.emailAddress,
        password: accountDetails && accountDetails.password,
        tfaType: authType === AuthType.APP ? 'AuthenticatorApp' : 'SMS',
        tfaCode: '',
        phoneNumber: phoneNumber ? phoneNumber : '',
        totpSharedSecret: sharedSecret ? sharedSecret : '',
    };

    //Send SMS verification code on mount
    React.useEffect(() => {
        if (phoneNumber && accountDetails) {
            (async () => {
                const result = await postSmsTFASetup({
                    username: accountDetails.emailAddress,
                    password: accountDetails.password,
                    phoneNumber,
                    bNewPhoneNumber: true,
                });
                if (result.response) {
                }
                if (result.errors && result.errors.length > 0) {
                    const translatableErrors = result.errors.filter(
                        (err) => err.messageCode in ERROR_CODES
                    );
                    if (translatableErrors.length > 0) {
                        Toast.openToastMessage(
                            getErrorMessage(translatableErrors[0].messageCode),
                            ToastMessageReason.ERROR
                        );
                    } else {
                        Toast.openGenericErrorToast();
                    }
                }
            })();
        }
    }, [phoneNumber, accountDetails, dispatch]);

    //Modal functionality
    const handleResendCode = () => {
        if (phoneNumber && accountDetails) {
            (async () => {
                const result = await postSmsTFASetup({
                    username: accountDetails.emailAddress,
                    password: accountDetails.password,
                    phoneNumber,
                    bNewPhoneNumber: true,
                });
                if (result.response) {
                    Toast.openToastMessage(
                        `Verification code sent to ${phoneNumber}`,
                        ToastMessageReason.PENDING
                    );
                }
                if (result.errors && result.errors.length > 0) {
                    setError(handleSMSRequestError(result.errors[0]));
                }
            })();
        }
    };

    const handleUseSMS = () => {
        setAuthType(AuthType.SMS);
        setRegistrationStep(RegisterStep.TFA_SETUP);
    };

    const handleSubmit = (values: TFAVerificationPayload) => {
        setLoading(true);
        (async () => {
            const result = await postEnableTfa(values);
            if (result.response) {
                onComplete();
            }
            if (result.errors && result.errors.length > 0) {
                setError(handleTFAError(result.errors[0]));
            }
            setLoading(false);
        })();
    };

    const handleResetError = () => {
        setError(null);
    };

    return (
        <>
            {loading && <Spinner />}
            {translations && (
                <>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {(formikProps) => {
                            return (
                                <Form onChange={handleResetError}>
                                    <div className="RegisterFormWidth">
                                        <Button
                                            priority="tertiary"
                                            className="ChangeNumberButton InlineLink"
                                            onClick={() =>
                                                setRegistrationStep(RegisterStep.TFA_SETUP)
                                            }
                                            type="button"
                                        >
                                            {'< Back'}
                                        </Button>
                                        <h1>{translations.heading2}</h1>
                                        <p>
                                            {authType === AuthType.APP
                                                ? translations.sub_heading2
                                                : translations.sub_heading4}
                                        </p>
                                        <ValidationCodeField
                                            field={'tfaCode'}
                                            label={translations.verification_code}
                                            required={true}
                                            autoFocus
                                            holdFocus
                                            value={formikProps.values.tfaCode}
                                        >
                                            {authType === AuthType.APP ? (
                                                <ThemedLink
                                                    onClick={handleUseSMS}
                                                    className="RegisterLink"
                                                >
                                                    {translations.use_sms}
                                                </ThemedLink>
                                            ) : (
                                                <ThemedLink
                                                    onClick={handleResendCode}
                                                    className="RegisterLink"
                                                >
                                                    {translations.resend_code}
                                                </ThemedLink>
                                            )}
                                        </ValidationCodeField>

                                        {error && <div className="ErrorMessage">{error}</div>}

                                        <Button
                                            priority="primary"
                                            variety="full"
                                            type="submit"
                                            disabled={loading}
                                            color="second"
                                        >
                                            {translations.continue_button}
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};
