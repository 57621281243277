import React from 'react';
import ApprovalHistory from './ApprovalHistory';

enum ManageBusinessSubpage {
    Manage = 'manage',
}
type Props = { subPage?: string };

function ApprovalHistoryNavigator({ subPage }: Props) {
    switch (subPage) {
        case ManageBusinessSubpage.Manage:
            return <ApprovalHistory />;
        default:
            return <ApprovalHistory />;
    }
}

export default ApprovalHistoryNavigator;
