import { navigate, useLocation } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import { FooterLinks } from 'components/footer/types';
import { ElementType, SideMenuLink } from 'components/sideMenu/SideMenu';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from 'reducers/auth';
import { Icons, selectAccountTypePathItem } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { StaticIcon, MobileNavigationButton } from './MobileNavigationButton';
import { PublicMoreMenu, SignedInMoreMenu } from './MoreMenu';
import { SignedInSubMenu } from './SignedInSubMenu';
import { partition } from 'lodash';
import { selectHasInfoRequest } from '../../reducers/infoRequest';

const NUM_SIDEMENU_LINKS_IN_NAV_BAR = 3;

enum SubPage {
    ACCOUNTS = 'Accounts',
    CARDS = 'Cards',
    CRYPTO = 'Crypto',
    MORE = 'More',
}

// Links that are in the footer on desktop are moved into the 'More' menu.
type MobileNavigationProps = {
    moreMenuFooterLinks: FooterLinks | null;
    sideMenuLinks?: SideMenuLink[] | null;
    sideMenuIcons?: Icons | null;
};

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
    moreMenuFooterLinks,
    sideMenuLinks,
    sideMenuIcons,
}) => {
    const isSignedIn = useSelector(selectSignedIn);
    const cultureCode = useSelector(selectCultureCode);
    const hasInfoRequest = useSelector(selectHasInfoRequest);
    const { pathname } = useLocation();
    const accountTypePathItem = useSelector(selectAccountTypePathItem);
    const [currentSubPage, setCurrentSubPage] = useState<SubPage | null>(null);

    const navigateWithCultureCode = (path: AppPath | string) => {
        setCurrentSubPage(null);
        navigate(`/${cultureCode}${path}`);
    };

    const isPathActive = (path: AppPath | string) => {
        // Don't let another path show as active when moreMenu is open.
        const pathContainsUrl =
            pathname === `/${cultureCode}${path}` || pathname.startsWith(`/${cultureCode}${path}/`);
        return pathContainsUrl && !currentSubPage;
    };

    const isLinkActive = (link: SideMenuLink) => {
        if (isPathActive(`${accountTypePathItem}/${link.path}`)) return true;
        if (
            link.accounts?.some((account) =>
                account.childElements?.some(
                    (childElement) => isPathActive(`${accountTypePathItem}/${childElement.path}`) //we merge cryptoaccounts into the accounts section for funding - they have a different path (e.g. /cryptoaccounts/usdc/history) but we still want to mark the item active
                )
            )
        )
            return true;
        return false;
    };

    const openMoreMenu = () => {
        setCurrentSubPage(SubPage.MORE);
    };

    useEffect(() => {
        document.body.classList.toggle('OverflowHidden', !!currentSubPage);
    }, [currentSubPage]);

    const pageLinks = useMemo(() => {
        if (!sideMenuLinks || sideMenuLinks.length < 1) return [];
        const [accountLinks, otherLinks] = partition(
            sideMenuLinks.filter((link) => link.path !== 'accounts/addaccount'), // filter our the addaccount link
            (link) =>
                link.elementType === 'CryptoAsAccounts' ||
                link.elementType === 'Accounts' ||
                link.elementType === 'FundingAccounts'
        );
        if (accountLinks.length === 0) return otherLinks;
        if (accountLinks.length === 1) return [accountLinks[0], ...otherLinks];

        let account = accountLinks.reduce<SideMenuLink>(
            (prev: SideMenuLink | null, current): SideMenuLink => {
                if (!prev)
                    return {
                        ...current,
                        accounts: current.accounts?.map((account) => ({
                            ...account,
                            elementType: current.elementType,
                        })),
                    };
                return {
                    ...prev,
                    accounts: (prev.accounts ?? []).concat(
                        ...(current.accounts?.map((account) => ({
                            ...account,
                            elementType: current.elementType,
                        })) ?? [])
                    ),
                };
            },
            null as any
        );
        // let [account, cryptoAsAccount] = [...accountLinks]; //We don't want to mutate the existing sidemenuLinks, so we need to copy the accounts section
        // account = {
        //     ...account,
        //     accounts: account?.accounts?.concat(...(cryptoAsAccount.accounts ?? [])),
        // };
        return [account, ...otherLinks];
    }, [sideMenuLinks]);

    const openSubMenu = (elementType?: ElementType) => {
        if (Object.values(SubPage).includes(elementType as any)) {
            if (currentSubPage === elementType) {
                setCurrentSubPage(null);
            } else setCurrentSubPage(elementType as SubPage);
        }
    };

    const closeMenu = () => {
        setCurrentSubPage(null);
    };

    const [accountLinks, extraAccountLinks] = useMemo(
        () => [
            sideMenuLinks?.filter((link) => link.elementType === 'Accounts'),
            sideMenuLinks?.filter(
                (link) =>
                    link.elementType === 'CryptoAsAccounts' ||
                    link.elementType === 'FundingAccounts'
            ),
        ],
        [sideMenuLinks]
    );

    return (
        <>
            {/* Rendering 'More' menu ontop of page rather than having a separate route 
            allows us to keep the routing structure for the mobile and desktop apps identical. */}

            <div className="MobileNavigation">
                {/* TODO(HC): Get signed-in buttons from shared state with SideMenu */}
                {isSignedIn ? (
                    pageLinks &&
                    sideMenuIcons && (
                        <>
                            {currentSubPage === SubPage.MORE && pageLinks && sideMenuIcons ? (
                                <SignedInMoreMenu
                                    linksFromFooter={moreMenuFooterLinks}
                                    pageLinks={pageLinks.slice(
                                        NUM_SIDEMENU_LINKS_IN_NAV_BAR,
                                        pageLinks.length
                                    )}
                                    pageLinkIcons={sideMenuIcons}
                                    navigateWithCultureCode={navigateWithCultureCode}
                                    closeMenu={closeMenu}
                                />
                            ) : (
                                currentSubPage && (
                                    <SignedInSubMenu
                                        pageLink={pageLinks.find(
                                            (link) => link.elementType === currentSubPage
                                        )}
                                        closeMenu={closeMenu}
                                        accountLinks={accountLinks}
                                        extraAccountLinks={extraAccountLinks}
                                    />
                                )
                            )}
                            <MobileNavigationButton
                                onClick={() => {
                                    return navigateWithCultureCode(
                                        `${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                                    );
                                }}
                                label="Home"
                                icon={StaticIcon.HOME}
                                isActive={isPathActive(
                                    `${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                                )}
                            />
                            {pageLinks
                                .slice(0, NUM_SIDEMENU_LINKS_IN_NAV_BAR)
                                .map((link, index) => {
                                    // // See App.tsx Dashboard routing for explanation on the need for
                                    // // this placeholder.
                                    // const finalUrlSegment = `/${
                                    //     linkHasChildren
                                    //         ? `${link.childElements[0].path}`
                                    //         : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
                                    // }`;
                                    const linkHasChildren =
                                        !!link.accounts ||
                                        !!link.childElements ||
                                        !!link.crypto ||
                                        !!link.stablecoin;
                                    const path = `${accountTypePathItem}/${link.path}`;
                                    return (
                                        <React.Fragment key={link.id}>
                                            <MobileNavigationButton
                                                onClick={() => {
                                                    linkHasChildren
                                                        ? openSubMenu(link.elementType)
                                                        : navigateWithCultureCode(path);
                                                }}
                                                label={link.displayName}
                                                icon={sideMenuIcons[link.iconName]}
                                                isActive={
                                                    currentSubPage === link.elementType ||
                                                    isLinkActive(link)
                                                }
                                                iconName={link.iconName}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            <MobileNavigationButton
                                onClick={openMoreMenu}
                                label="More"
                                icon={StaticIcon.MORE}
                                isActive={currentSubPage === SubPage.MORE}
                                hasAlert={hasInfoRequest}
                            />
                        </>
                    )
                ) : (
                    <>
                        {currentSubPage === SubPage.MORE && (
                            <PublicMoreMenu
                                linksFromFooter={moreMenuFooterLinks}
                                navigateWithCultureCode={navigateWithCultureCode}
                            />
                        )}
                        <MobileNavigationButton
                            onClick={() => {
                                navigateWithCultureCode(AppPath.SIGN_IN);
                            }}
                            label="Sign in"
                            icon={StaticIcon.SIGN_IN}
                            isActive={isPathActive(AppPath.SIGN_IN)}
                        />
                        <MobileNavigationButton
                            onClick={() => {
                                navigateWithCultureCode(AppPath.REGISTER);
                            }}
                            label="Register"
                            icon={StaticIcon.REGISTER}
                            isActive={isPathActive(AppPath.REGISTER)}
                        />
                        <MobileNavigationButton
                            onClick={openMoreMenu}
                            label="More"
                            icon={StaticIcon.MORE}
                            isActive={currentSubPage === SubPage.MORE}
                        />
                    </>
                )}
            </div>
        </>
    );
};
