import instance, { ApiResponse } from 'api';
import {
    AdditionalData,
    JumioStage,
    JumioVerificationStatusResponse,
} from 'components/jumio/jumioModels';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, StateObservable, combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { signOut } from './auth';
import { Store } from './rootReducer';
import {
    BackendBusinessVerificationStage,
    BusinessVerificationStage,
    BusinessVerificationStatusResponse,
    getStageFromBackend,
} from 'components/businessVerification/businessVerificationModels';
import {
    BusinessVerificationStage as V2BusinessVerificationStage,
    BusinessVerificationStatusResponse as V2BusinessVerificationStatusResponse,
    DocumentRejection as V2DocumentRejection,
} from 'components/businessVerificationV2/BusinessVerificationV2Models';
import {
    BusinessVerificationStage as V3BusinessVerificationStage,
    BusinessVerificationStatusResponse as V3BusinessVerificationStatusResponse,
    DocumentRejection as V3DocumentRejection,
} from 'components/businessVerificationV3/BusinessVerificationV3Models';
import { DocumentRejection } from 'components/businessVerification/BusinessVerification';
import { BusinessVerificationFlow } from './appSettings';

interface V1State {
    isVerified: boolean;
    isRejected: boolean;
    currentStage: JumioStage | BusinessVerificationStage | V2BusinessVerificationStage | null;
    checking: boolean;
    additionalData?: AdditionalData;
    rejectedDocuments?: DocumentRejection | V2DocumentRejection | null;
}
interface V2State {
    isVerified: boolean;
    isRejected: boolean;
    currentStage: JumioStage | V2BusinessVerificationStage | null;
    checking: boolean;
    additionalData?: AdditionalData;
    rejectedDocuments?: V2DocumentRejection | null;
}
interface V3State {
    isVerified: boolean;
    isRejected: boolean;
    currentStage: JumioStage | V2BusinessVerificationStage | null;
    checking: boolean;
    additionalData?: AdditionalData;
    rejectedDocuments?: V2DocumentRejection | null;
}
type State = V1State | V2State | V3State;
// | DocumentRejection;

const initialState: State = {
    isVerified: false,
    isRejected: false,
    currentStage: null,
    checking: false,
    additionalData: null,
    rejectedDocuments: null,
};

enum ActionType {
    FETCH_VERIFICATION_STATUS = 'FETCH_VERIFICATION_STATUS',
    SET_VERIFICATION_STATUS = 'SET_VERIFICATION_STATUS',
    DELETE_VERIFICATION_STATUS = 'DELETE_VERIFICATION_STATUS',
    UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS',
}

type Action =
    | { type: ActionType.FETCH_VERIFICATION_STATUS }
    | { type: ActionType.SET_VERIFICATION_STATUS; payload: State }
    | { type: ActionType.UPDATE_VERIFICATION_STATUS; payload: Partial<State> }
    | { type: ActionType.DELETE_VERIFICATION_STATUS };

const reducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_VERIFICATION_STATUS:
            return action.payload as State;
        case ActionType.UPDATE_VERIFICATION_STATUS:
            return { ...state, ...action.payload };
        case ActionType.DELETE_VERIFICATION_STATUS:
            return initialState;
        case ActionType.FETCH_VERIFICATION_STATUS:
            return {
                ...state,
                checking: true,
            };
        default:
            return state;
    }
};

const fetchStatus = (isBusiness: boolean, version: BusinessVerificationFlow) => {
    return from(
        isBusiness
            ? version === BusinessVerificationFlow.v1
                ? instance.get<ApiResponse<BusinessVerificationStatusResponse>>(
                      endpoints.businessverificationmodule.verificationStatus
                  )
                : version === BusinessVerificationFlow.v2
                ? instance.get<ApiResponse<V2BusinessVerificationStatusResponse>>(
                      endpoints.businessVerificationV2Module.verificationStatus
                  )
                : instance.get<ApiResponse<V3BusinessVerificationStatusResponse>>(
                      endpoints.businessVerificationV3Module.verificationStatus
                  )
            : instance.get<ApiResponse<JumioVerificationStatusResponse>>(
                  endpoints.jumiomodule.verificationStatus
              )
    );
};

const fetchVerificationStatusEpic = (
    action$: ActionsObservable<{
        type: ActionType.FETCH_VERIFICATION_STATUS;
    }>,
    state$: StateObservable<Store>
) => {
    return action$.pipe(
        ofType(ActionType.FETCH_VERIFICATION_STATUS),
        switchMap(() => {
            let isBusiness = state$.value.componentResources.userInfo?.accountType === 'Business';
            let version = state$.value.appSettings.businessVerificationFlow;

            return fetchStatus(isBusiness, version).pipe(
                filter((response) => response.data.status === '1'),
                map(
                    (response) =>
                        response.data.details as BusinessResponses | JumioVerificationStatusResponse
                ),
                map((details) => {
                    if (isBusinessVerificationStatusResponse(details, isBusiness)) {
                        return isV2Repsonse(details, version)
                            ? setVerificationStatus({
                                  checking: false,
                                  isVerified:
                                      details.businessVerificationStep ===
                                      V2BusinessVerificationStage.Verified,
                                  currentStage: details.businessVerificationStep,
                                  additionalData: details.additionalData,
                                  rejectedDocuments: details.rejectedDocuments,
                                  isRejected: details.businessVerificationStatus === 'Rejected',
                              })
                            : isV3Repsonse(details, version)
                            ? setVerificationStatus({
                                  checking: false,
                                  isVerified:
                                      (details as V3BusinessVerificationStatusResponse)
                                          .businessVerificationStep ===
                                      V3BusinessVerificationStage.Verified,
                                  currentStage: (details as V3BusinessVerificationStatusResponse)
                                      .businessVerificationStep,
                                  additionalData: (details as V3BusinessVerificationStatusResponse)
                                      .additionalData,
                                  rejectedDocuments: (
                                      details as V3BusinessVerificationStatusResponse
                                  ).rejectedDocuments,
                                  isRejected:
                                      (details as V3BusinessVerificationStatusResponse)
                                          .businessVerificationStatus === 'Rejected',
                              })
                            : setVerificationStatus({
                                  checking: false,
                                  isVerified:
                                      details.businessVerificationStep ===
                                      BackendBusinessVerificationStage.Verified,
                                  currentStage: getStageFromBackend(
                                      details.businessVerificationStep
                                  ),
                                  additionalData: details.additionalData,
                                  rejectedDocuments: details.rejectedDocuments,
                                  isRejected: details.businessVerificationStatus === 'Rejected',
                              });
                    } else
                        return setVerificationStatus({
                            checking: false,
                            isVerified: details.status === JumioStage.Verified,
                            currentStage: details.status,
                            additionalData: details.additionalData,
                            rejectedDocuments: null,
                            // Not using this in personal
                            isRejected: false,
                        });
                }),
                catchError(() => {
                    Toast.openToastMessage(
                        'There was an error checking your verification status. Please try again later.',
                        ToastMessageReason.ERROR
                    );
                    return of(signOut());
                })
            );
        })
    );
};
export default reducer;
export const verificationEpic = combineEpics(fetchVerificationStatusEpic);
//action creators
const setVerificationStatus = (payload: State): Action => {
    return { type: ActionType.SET_VERIFICATION_STATUS, payload: payload };
};
export const deleteVerificationStatus = (): Action => ({
    type: ActionType.DELETE_VERIFICATION_STATUS,
});

export const fetchVerificationStatus = () => ({
    type: ActionType.FETCH_VERIFICATION_STATUS,
});

export const setVerificationStage = (
    payload: Omit<State, 'isVerified' | 'checking' | 'isRejected'>
): Action => {
    return {
        type: ActionType.UPDATE_VERIFICATION_STATUS,
        payload: { ...payload },
    };
};

export const updateAdditionalData = (additionalData: AdditionalData): Action => {
    return {
        type: ActionType.UPDATE_VERIFICATION_STATUS,
        payload: { additionalData },
    };
};

// Selectors
export const selectVerificationLoading = (store: Store) => store.verification.checking;
export const selectisVerified = (store: Store) => store.verification.isVerified;
export const selectVerificationDetails = (store: Store) => ({
    stage: store.verification.currentStage,
    additionalData: store.verification.additionalData,
    rejectedDocuments: store.verification.rejectedDocuments,
});
export const selectIsBusinessRejected = (store: Store) => store.verification.isRejected;
export const selectVerificationDetailsV1 = (store: Store) => {
    if (!(store.appSettings.businessVerificationFlow === BusinessVerificationFlow.v1))
        throw new Error('Accessing v1 state when not v1 flow');
    else
        return {
            stage: store.verification.currentStage as BusinessVerificationStage,
            additionalData: store.verification.additionalData,
            rejectedDocuments: store.verification.rejectedDocuments as DocumentRejection,
            isRejected: store.verification.isRejected,
        };
};
export const selectVerificationDetailsV2 = (store: Store) => {
    if (!(store.appSettings.businessVerificationFlow === BusinessVerificationFlow.v2))
        throw new Error('Accessing v2 state when not v2 flow');
    else
        return {
            stage: store.verification.currentStage as V2BusinessVerificationStage,
            additionalData: store.verification.additionalData,
            rejectedDocuments: store.verification.rejectedDocuments as V2DocumentRejection,
            isRejected: store.verification.isRejected,
        };
};
export const selectVerificationDetailsV3 = (store: Store) => {
    if (!(store.appSettings.businessVerificationFlow === BusinessVerificationFlow.v3))
        throw new Error('Accessing v3 state when not v3 flow');
    else
        return {
            stage: store.verification.currentStage as V3BusinessVerificationStage,
            additionalData: store.verification.additionalData,
            rejectedDocuments: store.verification.rejectedDocuments as V3DocumentRejection,
            isRejected: store.verification.isRejected,
        };
};

type BusinessResponses =
    | BusinessVerificationStatusResponse
    | V2BusinessVerificationStatusResponse
    | V3BusinessVerificationStatusResponse;

const isBusinessVerificationStatusResponse = (
    response: BusinessResponses | JumioVerificationStatusResponse,
    bIsBusiness: boolean
): response is BusinessResponses => {
    return bIsBusiness;
};
const isV2Repsonse = (
    response: BusinessResponses,
    version: BusinessVerificationFlow
): response is V2BusinessVerificationStatusResponse => {
    return version === BusinessVerificationFlow.v2;
};
const isV3Repsonse = (
    response: BusinessResponses,
    version: BusinessVerificationFlow
): response is V3BusinessVerificationStatusResponse => {
    return version === BusinessVerificationFlow.v3;
};
