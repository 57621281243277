import Button from 'components/button/Button';
import { DateRangePicker } from 'components/dateRangePicker/DateRangePicker';
import { FormDateField } from 'components/form/FormDateField';
import { RadioButton, RadioButtons } from 'components/radiobuttons/radiobuttons';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAccountContext } from 'pages/account/AccountContext';
import React from 'react';
import styles from './monthlyStatements.module.scss';

import FillerImage from 'assets/ibanera/IbaneraBounce.gif';
import instance from 'api';
import { endpoints } from 'endpoints.config';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { plaintext } from 'plaintext.config';

type Props = { accountNumber: string };

type DownloadStatementForm = {
    accountNumber: string;
    date: Date;
};

const contentDispositionRegex = /.*?filename="?(.*?\.pdf)"?/; // for some reason the endpoint sometimes has content type filename:"file" and sometimes filename:file

export const MonthlyStatements = (props: Props) => {
    const { accountId, monthlyStatementsEnabled, setMonthlyStatementsEnabled } =
        useAccountContext();

    const handleChangeSettings = (setting: 'Enabled' | 'Disabled') => {
        if ((setting === 'Enabled') === monthlyStatementsEnabled) return;
        instance
            .post(endpoints.accounts.monthlyStatementSettings, {
                customerAssetAccountsId: accountId,
                bEnabled: setting === 'Enabled',
            })
            .then((res) => {
                setMonthlyStatementsEnabled(setting === 'Enabled');
            })
            .catch((err) => setMonthlyStatementsEnabled(setting !== 'Enabled'));
    };

    const handleDownloadStatement = (
        values: DownloadStatementForm,
        helpers: FormikHelpers<DownloadStatementForm>
    ) => {
        instance
            .get<BlobPart>(endpoints.accounts.downloadMonthlyStatement, {
                params: {
                    customerAssetAccountsId: values.accountNumber,
                    month: values.date.getMonth() + 1,
                    year: values.date.getFullYear(),
                },
                responseType: 'blob',
            })
            .then((res) => {
                const contentDisposition: string = res.headers['content-disposition'];
                const regexResult = contentDispositionRegex.exec(contentDisposition ?? '');
                const fileName = regexResult ? regexResult[1] : 'Account_Statement.pdf';
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => helpers.setSubmitting(false));
    };

    const downloadInitialValues: DownloadStatementForm = {
        accountNumber: accountId,
        date: new Date(),
    };
    const isLoading = monthlyStatementsEnabled === null;
    if (isLoading) {
        return <SubPageLoader message={plaintext.accounts.loading} />;
    }
    return (
        <div className={styles.MonthlyStatementsPage}>
            <div className={styles.Content}>
                <Formik
                    initialValues={{
                        monthlyStatements: monthlyStatementsEnabled ? 'Enabled' : 'Disabled',
                    }}
                    onSubmit={() => {}}
                    enableReinitialize
                >
                    <RadioButtons
                        options={['Enabled', 'Disabled']}
                        fieldname={'monthlyStatements'}
                        label="Recieve monthly statements by email?"
                        customOnChange={handleChangeSettings}
                    />
                </Formik>
                <Formik initialValues={downloadInitialValues} onSubmit={handleDownloadStatement}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="FormBuilderComponent SideBySide">
                                <FormDateField
                                    field={'date'}
                                    label={'Download for period'}
                                    datePickerProps={{
                                        dateFormat: 'MM/yyyy',
                                        showMonthYearPicker: true,
                                        maxDate: new Date(),
                                        minDate: new Date('2020-01'),
                                    }}
                                />
                                <Button variety="full" disabled={isSubmitting}>
                                    Download Statement
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <img src={FillerImage} alt="" className={styles.FillerImage} />
        </div>
    );
};
