import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { navigate } from '@reach/router';
import instance, { ApiResponse } from 'api';
import { AppPath } from 'appConstants';
import { toCamelCase } from 'components/ArrayRenderer/ArrayRenderer';
import Button from 'components/button/Button';
import { FormDateField } from 'components/form/FormDateField';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { Toast } from 'helpers/toast';
import { RegistrationDetails } from 'pages/register/models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { createLinkedAccount, selectAuthMessage } from 'reducers/auth';
import { selectCultureCode } from 'reducers/language';
import { selectAllowedAccounts } from 'reducers/ui';
import * as Yup from 'yup';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';

export interface CreateBusinessProfileSubmission {
    companyName: string;
    companyRole: string;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    taxNumber: string;
    entityTypesID: number | null;
    registrationDate: string;
    registrationNumber: string;
    website: string;
    countryISOCode: string;
    companyEmailAddress: string;
}

const initialValues: CreateBusinessProfileSubmission = {
    companyName: '',
    companyRole: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    taxNumber: '',
    entityTypesID: null,
    registrationDate: '',
    registrationNumber: '',
    website: '',
    countryISOCode: '',
    companyEmailAddress: '',
};

const validationSchema = Yup.object({
    companyName: Yup.string().required('Please provide a company name'),
    companyRole: Yup.string().required('Please provide your role'),
    addressLine1: Yup.string().required('Please provide the first line of your address'),
    townCity: Yup.string().required('Please provide the town / city'),
    zipCode: Yup.string().required('Please provide the zip code'),
    state: Yup.string().required('Please provide the state'),
    countryISOCode: Yup.string().required('Please provide the country'),
    companyEmailAddress: Yup.string().required('Please provide an email address for your company'),
    phoneNumber: Yup.string().required('Please provide a phone number for your company'),
    taxNumber: Yup.string().required('Please provide a tax number for your company'),
    entityTypesID: Yup.number().required('Please select a business type').nullable(),
    registrationDate: Yup.string().required('Please provide the registration date of your company'),
    registrationNumber: Yup.string().required(
        'Please provide the registration number of your company'
    ),
});

export const CreateBusinessProfile = () => {
    const dispatch = useDispatch();
    const cultureCode = useSelector(selectCultureCode);
    const [bBusinessAccountsEnabled] = useSelector(selectAllowedAccounts);

    const [entityTypes, setEntityTypes] = useState<SelectOption[]>([]);
    const [availableCountries, setAvailableCountries] = useState<
        (SelectOption & { states: SelectOption[] | null })[]
    >([]);

    useEffect(() => {
        const getRegistrationDetails = () => {
            instance
                .get<ApiResponse<RegistrationDetails>>(
                    endpoints.registration.businessRegistrationDetails
                )
                .then((res) => {
                    setAvailableCountries(
                        res.data.details.countries.map((country) => ({
                            value: country.isoCode,
                            label: country.name,
                            states: country.states
                                ? country.states.map((state) => ({
                                      label: state.name,
                                      value: state.isoCode,
                                  }))
                                : null,
                        }))
                    );
                    setEntityTypes(
                        res.data.details.entityTypes.map((entityType) => ({
                            value: entityType.id,
                            label: entityType.name,
                        }))
                    );
                })
                .catch((err) => {
                    Toast.openGenericErrorToast();
                    setAvailableCountries([]);
                    setEntityTypes([]);
                });
        };
        getRegistrationDetails();
    }, []);

    const handleSubmit = (values: CreateBusinessProfileSubmission) => {
        dispatch(createLinkedAccount(values));
    };
    if (bBusinessAccountsEnabled === false) {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
        return null;
    }
    return (
        <div className="CreateAccount">
            <h1>Create a business account</h1>
            <p>
                Opening a Business Account with us will allow you to control your business and
                personal finances separately.
            </p>
            <div className="RegisterBusinessForm">
                <h2>Business Details</h2>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, isSubmitting }) => {
                        const currentCountry = availableCountries.find(
                            (country) => country.value === values.countryISOCode
                        );
                        const stateOptions = currentCountry ? currentCountry.states : null;

                        return (
                            <Form>
                                <StateReset />
                                <AuthMessageHandler />
                                <div className="FormSideBySide">
                                    <FormTextField
                                        field={'companyName'}
                                        label={'Legal Name'}
                                        required={true}
                                        maxLength={200}
                                        asterisk={true}
                                        sideBySide={true}
                                    />
                                    <FormTextField
                                        field={'companyRole'}
                                        label={'Role in company'}
                                        required={true}
                                        maxLength={200}
                                        asterisk={true}
                                        sideBySide={true}
                                    />
                                </div>
                                <div className="FormSideBySide">
                                    <FormTextField
                                        field={'companyEmailAddress'}
                                        label={'Email Address'}
                                        required={true}
                                        maxLength={200}
                                        asterisk={true}
                                        sideBySide={true}
                                    />
                                    <FormPhoneNumberField
                                        field={'phoneNumber'}
                                        label={'Phone number*'} //TODO replace with translation
                                        required={true}
                                        sideBySide={true}
                                    />
                                </div>
                                <div className="FormSideBySide">
                                    <div className="FormBox SideBySide">
                                        <WrappedFormSingleSelectField
                                            fieldName="countryISOCode"
                                            label={'Country*'}
                                            options={availableCountries}
                                        />
                                    </div>
                                    <ReactTooltip
                                        id="TaxNumberTooltip"
                                        effect="solid"
                                        place="right"
                                    />
                                    <FormTextField
                                        field={'taxNumber'}
                                        label={'Tax Id Number'}
                                        labelChild={taxNumberTooltip}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                        // hint="If you do not have a Tax ID Number, please provide your EIN number or an equivalent number for your country"
                                    />
                                </div>
                                <div className="FormSideBySide">
                                    <div className="FormBox SideBySide">
                                        <WrappedFormSingleSelectField
                                            fieldName="entityTypesID"
                                            label={'Entity Type*'}
                                            options={entityTypes}
                                        />
                                    </div>
                                    {stateOptions ? (
                                        <div className="FormBox SideBySide">
                                            <WrappedFormSingleSelectField
                                                fieldName="state"
                                                label={'State / Province*'}
                                                options={stateOptions}
                                            />
                                        </div>
                                    ) : (
                                        <FormTextField
                                            field={'state'}
                                            label={'State / Province'}
                                            required={true}
                                            maxLength={200}
                                            sideBySide={true}
                                            asterisk={true}
                                        />
                                    )}
                                </div>
                                <div className="FormSideBySide">
                                    <FormTextField
                                        field={'townCity'}
                                        label={'City'}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                    />

                                    <FormTextField
                                        field={'addressLine1'}
                                        label={'Street Name'}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                    />
                                </div>
                                <div className="FormSideBySide">
                                    <FormTextField
                                        field={'addressLine2'}
                                        label={'Street Address 2'}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                    />

                                    <FormTextField
                                        field={'zipCode'}
                                        label={'Postal / ZIP Code'}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                    />
                                </div>
                                <div className="FormSideBySide">
                                    <div className="FormBuilderComponent SideBySide">
                                        <FormDateField
                                            field="registrationDate"
                                            label="Registration Date*"
                                            required={true}
                                            datePickerProps={{ maxDate: new Date() }}
                                        />
                                    </div>
                                    <FormTextField
                                        field={'registrationNumber'}
                                        label={'Registration Number'}
                                        required={true}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                    />
                                </div>
                                <FormTextField
                                    field={'website'}
                                    label={'Business Website'}
                                    required={false}
                                    maxLength={200}
                                />
                                <Button
                                    priority="primary"
                                    variety="full"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Create Account
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
                <p className="Disclaimer">
                    By clicking the "Create Account" button above, you agree to Ibanera's{' '}
                    <a href="https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/">
                        Terms &amp; Conditions
                    </a>
                    ,{' '}
                    <a href="https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/">
                        Digital Asset Custody Terms &amp; Conditions
                    </a>{' '}
                    and <a href="https://www.ibanera.com/privacy-policy/">Privacy Policy</a>
                </p>
            </div>
        </div>
    );
};

const AuthMessageHandler = () => {
    const authMessage = useSelector(selectAuthMessage);
    const { setFieldError, setSubmitting } = useFormikContext();
    useEffect(() => {
        if (authMessage?.length) {
            authMessage.forEach((error) => {
                setFieldError(
                    toCamelCase(error.fieldName) as string,
                    getErrorMessage(error.messageCode)
                );
            });
        }
        setSubmitting(false);
    }, [authMessage, setFieldError, setSubmitting]);
    return null;
};

const taxNumberTooltip: React.ReactNode = (
    <label>
        Tax Id Number
        <span>*</span>
        <span
            data-tip="If you do not have a Tax ID Number, please provide your EIN number or an equivalent number for your country"
            data-for="TaxNumberTooltip"
            className="TooltipTag"
        >
            ?
        </span>
    </label>
);

const StateReset = () => {
    const [{ value }] = useField('countryISOCode');
    const [, , { setValue }] = useField('state');

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return null;
};
