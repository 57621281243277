import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { CryptoLiveData, FiatCurrency } from 'pages/crypto/CryptoPage';
import React, { createContext, SetStateAction, useEffect, useState } from 'react';
import { AddFunds } from './AddFunds';
import { History } from './History';
import { Portfolio } from './Portfolio';
import { Trade } from './Trade';

export enum ExchangeSubPage {
    TRADE = 'trade',
    PORTFOLIO = 'portfolio',
    HISTORY = 'history',
}

interface ExchangePageProps {
    subPage: ExchangeSubPage;
}

export type ExchangeCryptoContextType = {
    currency: string;
    setCurrency: React.Dispatch<SetStateAction<string>>;
    cryptos: CryptoLiveData[];
    setCryptos: React.Dispatch<SetStateAction<CryptoLiveData[]>>;
    recentMessage: any;
    setRecentMessage: React.Dispatch<SetStateAction<any>>;
    currencyFormat: any;
    setCurrencyFormat: React.Dispatch<SetStateAction<any>>;
    selectedCurrencyInfo: CryptoLiveData | null;
    setSelectedCurrencyInfo: React.Dispatch<SetStateAction<CryptoLiveData | null>>;
    addFunds: boolean;
    setAddFunds: React.Dispatch<SetStateAction<boolean>>;
    assetPairs: string[];
    setAssetPairs: React.Dispatch<SetStateAction<string[]>>;
    searchString: string;
    setSearchString: React.Dispatch<SetStateAction<string>>;
    fiatCurrencies: FiatCurrency[];
    setFiatCurrencies: React.Dispatch<SetStateAction<FiatCurrency[]>>;
    selectedFiatCurrency: FiatCurrency | null;
    setSelectedFiatCurrency: React.Dispatch<SetStateAction<FiatCurrency | null>>;
    isPageLoading: boolean;
    setIsPageLoading: React.Dispatch<SetStateAction<boolean>>;
};

const defaultContext: ExchangeCryptoContextType = {
    currency: 'USD',
    setCurrency: () => {},
    cryptos: [],
    setCryptos: () => {},
    recentMessage: {},
    setRecentMessage: () => {},
    currencyFormat: {},
    setCurrencyFormat: () => {},
    selectedCurrencyInfo: null,
    setSelectedCurrencyInfo: () => {},
    addFunds: false,
    setAddFunds: () => {},
    assetPairs: [],
    setAssetPairs: () => {},
    searchString: '',
    setSearchString: () => {},
    fiatCurrencies: [],
    setFiatCurrencies: () => {},
    selectedFiatCurrency: null,
    setSelectedFiatCurrency: () => {},
    isPageLoading: false,
    setIsPageLoading: () => {},
};

export const ExchangeCryptoContext = createContext<ExchangeCryptoContextType>(defaultContext);

export const ExchangePage: React.FC<ExchangePageProps> = ({ subPage }) => {
    const [addFunds, setAddFunds] = useState<boolean>(false);
    const [recentMessage, setRecentMessage] = useState<any>(null);
    const [currency, setCurrency] = useState<string>('USD');
    const [currencyFormat, setCurrencyFormat] = useState<any>(null);
    const [selectedCurrencyInfo, setSelectedCurrencyInfo] = useState<CryptoLiveData | null>(null);
    const [assetPairs, setAssetPairs] = useState<string[]>([]);
    const [fiatCurrencies, setFiatCurrencies] = useState<FiatCurrency[]>([]);
    const [selectedFiatCurrency, setSelectedFiatCurrency] = useState<FiatCurrency | null>(null);
    const [searchString, setSearchString] = useState('');
    const [cryptos, setCryptos] = useState<CryptoLiveData[]>([]);
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        setSearchString('');
        if (!selectedCurrencyInfo) {
            setIsPageLoading(true);
        }
    }, [subPage]);

    return (
        <ExchangeCryptoContext.Provider
            value={{
                cryptos,
                setCryptos,
                currency,
                setCurrency,
                recentMessage,
                setRecentMessage,
                currencyFormat,
                setCurrencyFormat,
                selectedCurrencyInfo,
                setSelectedCurrencyInfo,
                addFunds,
                setAddFunds,
                assetPairs,
                setAssetPairs,
                searchString,
                setSearchString,
                fiatCurrencies,
                setFiatCurrencies,
                selectedFiatCurrency,
                setSelectedFiatCurrency,
                isPageLoading,
                setIsPageLoading,
            }}
        >
            {addFunds ? (
                <AddFunds setAddFunds={setAddFunds} />
            ) : subPage === 'trade' && !addFunds ? (
                <Trade />
            ) : subPage === ExchangeSubPage.PORTFOLIO ? (
                <Portfolio />
            ) : subPage === ExchangeSubPage.HISTORY ? (
                <History />
            ) : null}
            {isPageLoading && <SubPageLoader message={`Loading ${subPage}`} />}
        </ExchangeCryptoContext.Provider>
    );
};
