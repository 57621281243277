import React, { useState } from 'react';
import { TableInfo } from 'api';
import { ColumnDetail } from '@avamae/table';
import { Formik, Form, useField, FormikProps } from 'formik';
import { Dropdown, SelectOption } from 'components/categoryComponents/dropdown/Dropdown';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { localizedFormatDate, userLocale } from 'helpers/categoryHelpers/userLocale';
export type Operator =
    | 'EQ'
    | 'NEQ'
    | 'SW'
    | 'EW'
    | 'CONTAINS'
    | 'GT'
    | 'GEQ'
    | 'LT'
    | 'LEQ'
    | 'INSTRINGARRAY'
    | 'BETWEEN';

export type OperatorOptions = { label: string; key: Operator };

export type Filter =
    | {
          columnKey: string;
          operator: Omit<Operator, 'INSTRINGARRAY'>;
          value: string;
      }
    | {
          columnKey: string;
          operator: 'INSTRINGARRAY';
          value: string[];
      };
export type Filters = {
    columnKey: string;
    filters: Filter[];
}[];

type FilterMetadata = { details: any | null; filterType: string };

type FilterBuilderProps = {
    table: TableInfo;
    onDone(): void;
    hiddenFields?: string[];
};

export const FilterBuilder: React.FC<FilterBuilderProps> = ({ table, onDone, hiddenFields }) => {
    const columns: ColumnDetail[] = table && table.data ? table.data.details.columns : [];
    const [keyVal, setKeyVal] = React.useState(0);
    function incrementKeys() {
        setKeyVal((x) => x + 1);
    }

    const hidden: string[] = hiddenFields ? hiddenFields.map((c) => c.toLowerCase()) : [];

    const [metadata, initialValues] = React.useMemo(() => {
        let meta: Record<string, FilterMetadata> = {};
        let values: Record<string, { columnKey: string; filters: Filter[] }> = {};
        table.data?.details.columns.forEach((entry) => {
            if (entry.bFilterable) meta[entry.columnKey] = entry.filterMetadata;
            if (entry.bFilterable)
                values[entry.columnKey] = table.data?.details.filters.find(
                    (f) => f.columnKey === entry.columnKey
                ) ?? { columnKey: entry.columnKey, filters: [] };
        });
        return [meta, values];
    }, [table]);

    function handleSubmit(values: Record<string, { columnKey: string; filters: Filter[] }>) {
        const filters: Filter[] = Object.values(values).flatMap((filt) => filt.filters);
        table.data?.actions.setFilters(filters);
        onDone();
    }

    function clearFilters(formik: FormikProps<any>) {
        table.data?.actions.setFilters([]);
        Object.entries(initialValues).forEach(([key, value]) => {
            initialValues[key].filters = [];
        });
        formik.resetForm(initialValues);
        incrementKeys();
    }

    if (columns) {
        return (
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {(formik) => (
                    <Form>
                        <div className="Content">
                            <div className="Form">
                                <div className="Filters">
                                    {columns
                                        .filter(
                                            (x) =>
                                                x.bFilterable &&
                                                !hidden.includes(
                                                    x.columnKey.toString().toLowerCase()
                                                )
                                        )
                                        .map((x) => {
                                            switch (x.filterMetadata?.filterType) {
                                                case 'TextContains':
                                                    return (
                                                        <div key={x.columnKey.toString()}>
                                                            <TextContainsField
                                                                name={x.columnKey.toString()}
                                                                label={x.labelValue}
                                                            />
                                                        </div>
                                                    );
                                                case 'Amount':
                                                    return (
                                                        <div key={x.columnKey.toString()}>
                                                            <AmountField
                                                                name={x.columnKey.toString()}
                                                                label={x.labelValue}
                                                            />
                                                        </div>
                                                    );
                                                case 'SingleChoice':
                                                    return (
                                                        <div
                                                            className="SingleChoiceField"
                                                            key={x.columnKey.toString()}
                                                        >
                                                            <SingleChoiceField
                                                                name={x.columnKey.toString()}
                                                                label={x.labelValue}
                                                                keyVal={keyVal}
                                                                possibleValues={
                                                                    x.filterMetadata.details
                                                                        ?.PossibleValues ?? []
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                case 'DateTime:Range':
                                                    return (
                                                        <div
                                                            className="FilterDateContainer"
                                                            key={x.columnKey?.toString()}
                                                        >
                                                            <DateTimeRangeField
                                                                name={x.columnKey.toString()}
                                                                label={x.labelValue}
                                                            />
                                                        </div>
                                                    );

                                                default:
                                                    return (
                                                        <div key={x.columnKey.toString()}>
                                                            {x.columnKey} -{' '}
                                                            {
                                                                metadata[x.columnKey.toString()]
                                                                    ?.filterType
                                                            }
                                                        </div>
                                                    );
                                            }
                                        })}
                                </div>
                            </div>

                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Btn"
                                    onClick={() => clearFilters(formik)}
                                >
                                    Clear
                                </button>
                                <button type="submit" className="Btn">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    return <div className="Filters"></div>;
};

const numberValidator = Yup.number();
const numericalOperators = [
    { value: 'CONTAINS', label: 'Contains' },
    { value: 'EQ', label: 'Equals' },
    { value: 'GT', label: 'Greater than' },
    { value: 'GEQ', label: 'Greater or equal to' },
    { value: 'LT', label: 'Less than' },
    { value: 'LEQ', label: 'Less than or equal to' },
];

const AmountField: React.FC<{ name: string; label: string }> = ({ name, label }) => {
    const [operator, setOperator] = useState(numericalOperators[0]);
    const [field, , helpers] = useField(name);
    const { filters } = field?.value ?? { filters: [] };

    const value = filters[0]?.value ?? '';

    function handleChange(e: React.ChangeEvent<any>) {
        e.preventDefault();
        const newValue = e.target.value;
        if (e.target.value !== '' && !numberValidator.isValidSync(e.target.value)) return;
        const filters: Filter[] = [];

        if (newValue.length > 0) {
            filters.push({
                columnKey: field.value.columnKey,
                operator: operator.value,
                value: newValue,
            });
        }
        helpers.setValue({
            columnKey: field.value.columnKey,
            filters,
        });
    }

    return (
        <div className="FormBox FormSideBySide">
            <div className="FormLabel">
                <label>{label}</label>
            </div>
            <div className={`FormDropdownField`}>
                <div className={'FormField'}>
                    <Dropdown
                        key={`${name}Operator`}
                        value={operator}
                        options={numericalOperators}
                        onChange={(e: SelectOption) => {
                            setOperator(e);
                        }}
                        roundedRectangle
                    />
                </div>
            </div>
            <div className="FormField">
                <input
                    key={`${name}Value`}
                    className="EditBox"
                    {...field}
                    value={value}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

const textOperators = [
    { value: 'CONTAINS', label: 'Contains' },
    { value: 'EQ', label: 'Equals' },
];

const TextContainsField: React.FC<{ name: string; label: string }> = ({ name, label }) => {
    const [operator, setOperator] = useState(textOperators[0]);
    const [field, , helpers] = useField(name);

    const { filters } = field?.value ?? { filters: [] };
    const value = filters[0]?.value ?? '';

    function handleChange(e: React.ChangeEvent<any>) {
        const newValue = e.target.value;
        const filters: Filter[] = [];

        if (newValue.length > 0) {
            filters.push({
                columnKey: field.value.columnKey,
                operator: operator.value,
                value: newValue,
            });
        }
        helpers.setValue({
            columnKey: field.value.columnKey,
            filters,
        });
    }

    return (
        <div className="FormBox FormSideBySide">
            <div className="FormLabel">
                <label>{label}</label>
            </div>
            <div className={`FormDropdownField`}>
                <div className={'FormField'}>
                    <Dropdown
                        key={`${name}Operator`}
                        value={operator}
                        options={textOperators}
                        onChange={(e: SelectOption) => {
                            setOperator(e);
                        }}
                        roundedRectangle
                    />
                </div>
            </div>
            <div className="FormField">
                <input className="EditBox" {...field} value={value} onChange={handleChange} />
            </div>
        </div>
    );
};

function SingleChoiceField(props: {
    name: string;
    label: string;
    keyVal: number;
    possibleValues: { label: string; value: React.ReactText }[];
}) {
    const [field, , helpers] = useField(props.name);

    const { filters } = field?.value ?? { filters: [] };
    const value = filters[0]?.value;

    function handleChange(selectedOption: SelectOption) {
        const filters: Filter[] = [];

        if (selectedOption.value != null)
            filters.push({
                columnKey: field.value.columnKey,
                operator: 'EQ',
                value: String(selectedOption.value),
            });
        helpers.setValue({
            columnKey: field.value.columnKey,
            filters,
        });
    }

    return (
        <div className="FormBox FormSideBySide">
            <div className="FormLabel">
                <label>{props.label}</label>
            </div>
            <div className="FormField">
                <Dropdown
                    key={`${props.name}${props.keyVal}`}
                    value={props.possibleValues.find((v) => v.value === value)}
                    options={props.possibleValues}
                    onChange={handleChange}
                    roundedRectangle
                />
            </div>
        </div>
    );
}

function DateTimeRangeField(props: { name: string; label: string; range?: boolean }) {
    const [field, , helpers] = useField(props.name);

    const { filters } = field?.value ?? { filters: [] };
    const value = filters[0]?.value ?? '';
    let values = value.split('TO');
    if (values.length !== 2) values = ['NULL', 'NULL'];

    function handleChangeFirst(date: Date | null) {
        const filters: Filter[] = [];

        const formattedDate = date ? localizedFormatDate(date) : 'NULL';
        filters.push({
            columnKey: field.value.columnKey,
            operator: 'BETWEEN',
            value: `${formattedDate}TO${values[1]}`,
        });
        helpers.setValue({
            columnKey: field.value.columnKey,
            filters,
        });
    }

    function handleChangeSecond(date: Date | null) {
        const filters: Filter[] = [];

        const formattedDate = date ? localizedFormatDate(date) : 'NULL';
        filters.push({
            columnKey: field.value.columnKey,
            operator: 'BETWEEN',
            value: `${values[0]}TO${formattedDate}`,
        });
        helpers.setValue({
            columnKey: field.value.columnKey,
            filters,
        });
    }

    const startDate = values[0] === 'NULL' ? null : new Date(values[0]);
    const endDate = values[1] === 'NULL' ? null : new Date(values[1]);

    return (
        <div className="FormBox FormSideBySide">
            <div className="DatePickerFormLabel FormLabel">
                <label>{props.label}</label>
            </div>
            <div className="DatePickerFormField FormField">
                <div className="DatePickerDateFields DateFields">
                    <DatePicker
                        locale={userLocale}
                        className="DatePickerBox DatePickerBox1 EditBox"
                        placeholderText="Starting at..."
                        selected={startDate}
                        onChange={handleChangeFirst}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <DatePicker
                        locale={userLocale}
                        className="DatePickerBox DatePickerBox2 EditBox"
                        placeholderText="Ending at..."
                        selected={endDate}
                        onChange={handleChangeSecond}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
                </div>
            </div>
        </div>
    );
}
