import React from 'react';
import OptionsIcon from '../../assets/Cat-Entity/Icon_Table_Action.png';
import { RowOptions } from 'components/categoryComponents/RowOptions';
import { applyMaybeBoolean, CrudItems, useMenuState } from 'components/Category/CrudMenu';

type RowOptionMenuProps = {
    data: any;
    menuState: ReturnType<typeof useMenuState>;
    selectedIds: any[];
    crudItems: CrudItems<any>;
    idColumn: string;
    visible: string | number | null;
    setVisible: React.Dispatch<React.SetStateAction<string | number | null>>;
};

const RowOptionMenu: React.FC<RowOptionMenuProps> = ({
    data,
    menuState,
    selectedIds,
    crudItems,
    idColumn,
    visible,
    setVisible,
}) => {
    const [menu, menuActions] = menuState;

    const close = () => setVisible(null);

    const handleClick = (e: any) => {
        e.stopPropagation();
        setVisible(idColumn);
    };

    const optionList = crudItems
        .filter(
            (i) =>
                !applyMaybeBoolean(i.notInMenus ?? false, [data]) &&
                !applyMaybeBoolean(i.isDisabled, [data])
        )
        .map((item, _key) => {
            return {
                name: typeof item.title === 'function' ? item.title(selectedIds) : item.title,
                onClick: (id: string | number) => menuActions.openMenu(item.menuCode, id, true),
            };
        });

    return (
        <>
            <img onClick={handleClick} src={OptionsIcon} alt="Options" className="Icon" />
            <RowOptions
                id={idColumn}
                visibleOptions={visible}
                close={close}
                optionList={optionList}
            />
        </>
    );
};

export default RowOptionMenu;
