import instance, { isErrorHandled } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import React from 'react';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import * as dateFns from 'date-fns';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useGetFiatAccountDetails } from '../../../helpers/useGetFiatAccountDetails';

type Props = { onCompletion?: () => void; isPersonalAccount?: boolean };
export const LaunchPlaid = (props: Props) => {
    const [linkToken, setLinkToken] = useState<string | null>(null);
    const [expiryDate, setExpiryDate] = useState<string | null>(null);
    const [customersPlaidLinkSessionsId, setCustomersPlaidLinkSessionsId] = useState<number | null>(
        null
    );
    const generateToken = async () => {
        const response = await instance.post(endpoints.accounts.createLinkToken);
        const data = await response.data;
        const { linkToken, expiryDate, customersPlaidLinkSessionsId } = data.details;
        setLinkToken(linkToken);
        setExpiryDate(expiryDate);
        setCustomersPlaidLinkSessionsId(customersPlaidLinkSessionsId);
    };
    useEffect(() => {
        generateToken();
    }, []);
    return linkToken != null && customersPlaidLinkSessionsId != null && expiryDate !== null ? (
        <Link
            linkToken={linkToken}
            expiryDate={expiryDate}
            customersPlaidLinkSessionsId={customersPlaidLinkSessionsId}
            isPersonalAccount={props.isPersonalAccount}
        />
    ) : (
        <div className="AddPersonalAccount">
            <Button priority="primary" disabled={true}>
                {'Connecting...'}
            </Button>
        </div>
    ); //could be lowered down to Link func below
};

interface LinkProps {
    linkToken: string | null;
    customersPlaidLinkSessionsId: number | null;
    expiryDate: string | null;
    onCompletion?: () => void;
    isPersonalAccount?: boolean;
}
const Link: React.FC<LinkProps> = (props: LinkProps) => {
    const accountDetails = useGetFiatAccountDetails();

    const { customersPlaidLinkSessionsId, expiryDate, onCompletion } = props;
    const [errMsg, setErrMsg] = useState<string>('');
    const [expired, setExpired] = useState<boolean>(false);
    const onSuccess = React.useCallback(async (public_token, metadata) => {
        // send public_token to server
        const response = await instance.post(endpoints.accounts.exchangePublicToken, {
            customersPlaidLinkSessionsId,
            publicToken: public_token,
            customerAssetAccountsId: accountDetails?.id,
        });

        // Handle response ...
        if (response) {
            onCompletion && onCompletion();
        }
    }, []);
    const config: Parameters<typeof usePlaidLink>[0] = {
        token: props.linkToken!,
        //receivedRedirectUri: window.location.href,
        onSuccess,
    };
    const { open, ready } = usePlaidLink(config);
    return (
        //if any of expiryDate, linkToken customersPlaidLinkSessionsId is null return Connecting... Button
        <div className="AddPersonalAccount">
            <Button
                priority="primary"
                onClick={() => {
                    //if any of expiryDate, linkToken customersPlaidLinkSessionsId is null return null
                    if (expiryDate && dateFns.isAfter(new Date(), new Date(expiryDate))) {
                        setErrMsg('Link expired. Please refresh the page.');
                        setExpired(true);
                        return;
                    }
                    open();
                }}
                disabled={!ready || expired} //disabled || if any of expiryDate, linkToken customersPlaidLinkSessionsId is null
            >
                {expired ? 'Link Expired' : 'Connect Bank Account'}
                {/* if any of expiryDate, linkToken customersPlaidLinkSessionsId is null o/p label Connecting */}
            </Button>
            {errMsg && <div className="ErrorLabel">{errMsg}</div>}
        </div>
    );
};

export const ConnectPlaid = (props: Props) => {
    const accountDetails = useGetFiatAccountDetails();
    const [linkToken, setLinkToken] = useState<string | null>(null);
    const [expiryDate, setExpiryDate] = useState<string | null>(null);
    const [expired, setExpired] = useState<boolean>(false);
    const [customersPlaidLinkSessionsId, setCustomersPlaidLinkSessionsId] = useState<number | null>(
        null
    );
    const [isConnecting, setConnecting] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>('');
    const generateToken = async () => {
        setConnecting(true);
        const response = await instance.post(endpoints.accounts.createLinkToken);
        const data = await response.data;
        const { linkToken, expiryDate, customersPlaidLinkSessionsId } = data.details;
        setLinkToken(linkToken);
        setExpiryDate(expiryDate);
        setCustomersPlaidLinkSessionsId(customersPlaidLinkSessionsId);
    };

    const handleAddAccount = () => {
        if (expiryDate && dateFns.isAfter(new Date(), new Date(expiryDate))) {
            setErrMsg('Link expired. Please refresh the page.');
            setExpired(true);
            return;
        }
        if (!linkToken || !customersPlaidLinkSessionsId || !expiryDate) {
            generateToken(); //also need to not be first render
        } else if (
            linkToken &&
            customersPlaidLinkSessionsId &&
            expiryDate &&
            dateFns.isBefore(new Date(), new Date(expiryDate)) &&
            typeof open === 'function' &&
            ready
        ) {
            open(); //also need to not be first render
        }
    };

    const onSuccess = React.useCallback(async (public_token, metadata) => {
        try {
            // send public_token to server
            const response = await instance.post(endpoints.accounts.exchangePublicToken, {
                customersPlaidLinkSessionsId,
                publicToken: public_token,
                customerAssetAccountsId: accountDetails?.id,
            });

            // Handle response ...
            if (response) {
                props.onCompletion && props.onCompletion();
            }
        } catch (err: any) {
            if (isErrorHandled(err)) {
                const errors = err.response.data.errors;
                Toast.openToastMessage(errors[0].messageCode, ToastMessageReason.ERROR);
            } else {
                Toast.openToastMessage('Something went wrong', ToastMessageReason.ERROR);
            }
        }
    }, []);
    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        //receivedRedirectUri: window.location.href,
        onSuccess,
    };
    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if (
            linkToken &&
            customersPlaidLinkSessionsId &&
            expiryDate &&
            dateFns.isBefore(new Date(), new Date(expiryDate)) &&
            typeof open === 'function' &&
            ready
        ) {
            open(); //also need to not be first render
            setConnecting(false);
        }
    }, [linkToken, customersPlaidLinkSessionsId, expiryDate, open, ready]); //open used not b4 declaration

    return (
        <div className="AddPersonalAccount">
            <Button
                priority="primary"
                onClick={() => handleAddAccount()} //disabled={!ready}
                disabled={isConnecting || expired}
            >
                {expired ? 'Link Expired' : isConnecting ? 'Connecting...' : 'Add Personal Account'}
            </Button>
            {errMsg && <div className="ErrorLabel">{errMsg}</div>}
        </div>
    );
};
