import { Scopes, AccessElement } from "./auth";

class Deque<T> {
  private stack = new Array<T>();
  popBack = (): T | undefined => this.stack.pop();
  pushBack = (item: T): number => this.stack.push(item);
  popFront = (): T | undefined => this.stack.shift();
  pushFront = (item: T): number => this.stack.unshift(item);
  peekBack = (): T | undefined => this.stack[this.stack.length - 1];
  peekFront = (): T | undefined => this.stack[0];
  isEmpty = (): boolean => this.stack.length === 0;
}

// Breadth-first search of permissions tree for the correct displayName.
export function getPageTitle(path: string | undefined, scopes: Scopes): string {
  // Remove leading slash in path.
  const pathname = path?.slice(1);
  // If it's undefined or empty, return empty string.
  if (!pathname) return "";

  // Like a stack, except we can push and pop values on either end.
  const queue = new Deque<AccessElement>();

  // Check the first level of elements, pushing to the queue
  // as we go.
  for (let i = 0; i < scopes.length; i++) {
    if (pathname === scopes[i].path) {
      return scopes[i].displayName;
    }

    queue.pushBack(scopes[i]);
  }

  // Pop a value from the queue, check it, add its childElements
  // to the back of the queue, move on to the next element.
  // Repeat until we found it or the queue is empty.
  while (!queue.isEmpty()) {
    const candidate = queue.popFront();
    if (!candidate) return "";
    if (candidate.path === pathname) return candidate.displayName;
    if (candidate.childElements) {
      for (let i = 0; i < candidate.childElements.length; i++) {
        queue.pushBack(candidate.childElements[i]);
      }
    }
  }

  // We didn't find it, return empty string.
  return "";
}

/**
 * Traverses a Scopes tree breadth-first to find if the requested permissions are available.
 */
export function getPermissions(
  permissionTypes: string[],
  page: string,
  scopes: Scopes
): Map<string, boolean> {
  const searchTerms = permissionTypes.map(perm => page + "/" + perm);
  const stillToFind = new Set(searchTerms);
  const searchTermsToVals = new Map(
    permissionTypes.map((perm, i) => [searchTerms[i], perm])
  );

  const vals = new Map(permissionTypes.map(perm => [perm, false]));

  const queue = new Deque<AccessElement>();

  for (const scope of scopes) {
    if (stillToFind.has(scope.path) && searchTermsToVals.get(scope.path)) {
      stillToFind.delete(scope.path);
      const key = searchTermsToVals.get(scope.path);
      if (key == null)
        throw new Error(
          `Could not find key in search terms dictionary: ${scope.path}`
        );
      vals.set(key, true);
    }

    if (stillToFind.size === 0) return vals;
    queue.pushBack(scope);
  }

  while (!queue.isEmpty() && stillToFind.size > 0) {
    const scope = queue.popFront();
    if (!scope) throw new Error("Queue was empty??");
    if (stillToFind.has(scope.path)) {
      stillToFind.delete(scope.path);
      const key = searchTermsToVals.get(scope.path);
      if (key == null)
        throw new Error(
          `Could not find key in search terms dictionary: ${scope.path}`
        );
      vals.set(key, true);
    }
    if (scope.childElements) {
      for (const child of scope.childElements) {
        queue.pushBack(child);
      }
    }
  }

  return vals;
}
