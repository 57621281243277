/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import instance from 'api';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Form, useFormikContext } from 'formik';
import { postSmsTFASetup } from 'pages/register/RegisterApi';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import {
    selectAuthMessage,
    selectAuthStatus,
    selectTFAType,
    signOut,
    TFASignInAttempt,
} from '../../reducers/auth';
import { SignInTranslation, TFAFormDetails } from './signInTypes';
import { TFAField } from 'components/form/TFAField';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';

type Props = {
    translations: SignInTranslation | null;
    buttonLabel?: string;
    customErrorMessage?: string;
};

const TFAForm: React.FC<Props> = ({
    translations,
    buttonLabel = translations?.continue_button ?? '',
    customErrorMessage,
}) => {
    const theme = useTheme();
    const TFAType = useSelector(selectTFAType);
    const TFAError = useSelector(selectAuthMessage);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { values, setFieldValue, setFieldTouched, setFieldError, isSubmitting, setSubmitting } =
        useFormikContext<TFAFormDetails>();
    const authStatus = useSelector(selectAuthStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authStatus === 'need_tfa' && !(values.username && values.password)) {
            dispatch(signOut(false));
        }
    }, [authStatus, values.username, values.password, dispatch]);

    const TFAFormCSS = css`
        .Btn {
            background: ${theme.colors.first};
        }

        .Btn.SwitchTFATypeButton {
            background-color: transparent;
        }

        .UseSMS {
            color: ${theme.colors.second};
        }
    `;
    const requestSmsCode = useCallback(() => {
        if (authStatus === 'signed_in') {
            instance
                .post(endpoints.profilemodule.requestSmsTfaCode)
                .catch((err) =>
                    Toast.openToastMessage(
                        'There was an error sending your SMS code',
                        ToastMessageReason.ERROR
                    )
                );
        } else if (authStatus === 'need_tfa' && values.username && values.password) {
            postSmsTFASetup({
                username: values.username,
                password: values.password,
                bNewPhoneNumber: false,
            });
        }
    }, [authStatus, values.password, values.username]);

    useEffect(() => {
        setSubmitting(false);
        const tfa = TFAType === 'both' || TFAType === 'app' ? 'AuthenticatorApp' : 'SMS';
        setFieldTouched('tfaCode', false);
        setFieldValue('tfaType', tfa);
        if (tfa === 'SMS') {
            setFieldValue('useSMS', true);
            requestSmsCode();
        } else {
            setFieldValue('useSMS', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useAfterMountEffect(() => {
        setSubmitting(false);
        if (values.tfaType) return;
        const tfa = TFAType === 'both' || TFAType === 'app' ? 'AuthenticatorApp' : 'SMS';
        setFieldTouched('tfaCode', false);
        setFieldValue('tfaType', tfa);
        if (tfa === 'SMS') {
            setFieldValue('useSMS', true);
            requestSmsCode();
        } else {
            setFieldValue('useSMS', false);
        }
    }, [TFAType, setFieldValue, setFieldTouched, requestSmsCode, setSubmitting, values.tfaType]);

    React.useEffect(() => {
        if (TFAError && TFAError.length > 0) {
            setErrorMessage('Code is invalid, please try again');
            setFieldValue('tfaCode', '');
            setSubmitting(false);
        }
    }, [TFAError, setFieldError, setFieldValue, setSubmitting]);

    const handleChangeAuthType = () => {
        setErrorMessage(null);
        const switchToSms = !values.useSMS;
        if (switchToSms) {
            requestSmsCode();
        }
        setFieldValue('useSMS', !values.useSMS);
        setFieldValue('tfaType', switchToSms ? 'SMS' : 'AuthenticatorApp');
    };

    return (
        <Form className="AuthFormContainer">
            <div className="AuthForm TFAForm" css={TFAFormCSS}>
                <h1>{translations?.heading2 ?? ''}</h1>
                <p>
                    {' '}
                    {values.useSMS
                        ? translations?.sub_heading4 ?? ''
                        : translations?.sub_heading2 ?? ''}
                </p>
                <div className="FieldsContainer">
                    <TFAField
                        field={'tfaCode'}
                        label={'Your verification code'}
                        required={true}
                        toggleTFAType={TFAType === 'both' ? handleChangeAuthType : null}
                        tfaType={values.tfaType ?? 'AuthenticatorApp'}
                        holdFocus
                        autoFocus
                    />
                    {/* <div className="TFAInputContainer">
                        <FormTextField
                            field={'tfaCode'}
                            label={'Your verification code'}
                            required={true}
                            maxLength={6}
                            autoFocus
                            autoComplete={false}
                            // touched={() => { setError(false) }}
                            onBlur={setFocus}
                            ref={inputRef}
                            className="TFAHiddenField"
                        />
                        <TFAInputMask value={values.tfaCode} />
                    </div>
                    {TFAType === 'both' && (
                        <div className="UseSMS" onClick={handleChangeAuthType}>
                            {values.useSMS
                                ? translations?.use_authy ?? ''
                                : translations?.use_sms ?? ''}
                        </div>
                    )} */}
                    {errorMessage && <p className="ErrorMessage">{errorMessage}</p>}
                    {customErrorMessage && <p className="ErrorMessage">{customErrorMessage}</p>}
                    <Button
                        variety="full"
                        type="submit"
                        disabled={authStatus === 'tfa_pending' || isSubmitting}
                    >
                        {isSubmitting ? <Spinner color={'#fff'} /> : buttonLabel}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export const parseTfaForm = (payload: TFAFormDetails): TFASignInAttempt => ({
    username: payload.username,
    password: payload.password,
    rememberMe: payload.rememberMe,
    tfaCode: payload.tfaCode,
    tfaType: payload.tfaType ?? 'AuthenticatorApp',
});

export default TFAForm;
