import { useField } from 'formik';
import { useFocus } from 'helpers/useFocus';
import React, { useCallback } from 'react';
import FormTextField, { FormTextFieldProps } from './FormTextField';
import { TFAInputMask } from './TFAField';

type ValidationCodeFieldProps = FormTextFieldProps & {
    value: string;
    backgroundColor?: 'white' | 'grey';
    holdFocus?: boolean;
};

export const ValidationCodeField: React.FC<ValidationCodeFieldProps> = ({
    value,
    backgroundColor = 'grey',
    className = '',
    holdFocus,
    children,
    ...props
}) => {
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();
    const [fieldProps, , fieldHelperProps] = useField(props.field);
    const handleBlur = (e: React.FocusEvent) => {
        fieldProps.onBlur(e);
        holdFocus && setFocus();
    };
    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const { value } = e.target;
            const regex = /^[0-9]*$/;
            if (regex.test(value)) {
                fieldHelperProps.setValue(value, false);
            } else {
                fieldHelperProps.setValue(fieldProps.value, true);
            }
        },
        [fieldProps.value, fieldHelperProps]
    );
    return (
        <div className="TFAInputContainer">
            {children}
            <FormTextField
                maxLength={6}
                autoComplete={false}
                inputMode="numeric"
                {...props}
                className={`TFAHiddenField ${className}`}
                ref={inputRef}
                onBlur={handleBlur}
                onChange={onChange}
            />
            <TFAInputMask value={value} className={`InputMask ${backgroundColor}`} />
        </div>
    );
};
