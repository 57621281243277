import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { useFormikContext } from 'formik';
import { accountAsMergedAccount, ExchangeDetails } from 'pages/cryptoV2/models';
import { FormValues } from '../CryptoExchangeModal';
import { AccountDropdown } from './AccountDropdown';
import { useEffect, useMemo } from 'react';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';

export const formatGeneralCurrency = (
    num: number,
    code: string,
    after = false,
    options?: Intl.NumberFormatOptions
) =>
    `${!after ? `${code} ` : ''}${Number(num).toLocaleString('en', {
        maximumFractionDigits: 13,
        ...options,
    })}${after ? ` ${code}` : ''}`;

export const FromTo = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const {
        values: { exchangeType, asset, pairedAsset, fromAccountId, toMergedAccountGenericId },
        setFieldValue,
    } = useFormikContext<FormValues>();

    const sourceCurrency = exchangeType === 'Buy' ? pairedAsset : asset;
    const destinationCurrency = exchangeType === 'Buy' ? asset : pairedAsset;

    const fromAccount = useMemo(
        () => exchangeDetails.fromAccounts.find((acc) => acc.accountID === fromAccountId),
        [exchangeDetails.fromAccounts, fromAccountId]
    );
    const toAccount = exchangeDetails.mergedToAccounts.find(
        (acc) => acc.genericId === toMergedAccountGenericId
    );

    const fromHasOptions = exchangeDetails.fromAccounts.length > 1;
    const toHasOptions = exchangeDetails.mergedToAccounts.length > 1;

    useEffect(() => {
        if (fromAccount && fromAccount.assetID)
            exchangeType === 'Buy'
                ? setFieldValue('pairedAssetsId', fromAccount.assetID)
                : setFieldValue('assetsId', fromAccount.assetID);
    }, [fromAccount, setFieldValue, exchangeType]);

    return (
        <div className="FromTo">
            {!fromHasOptions && fromAccount && (
                <div className="From">
                    <TextCurrencyIcon currencySymbol={fromAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>From:</p>
                        <h4>{fromAccount.displayName}</h4>
                    </div>
                    <div className="Balance">
                        {formatGeneralCurrency(fromAccount.balance, sourceCurrency)}
                    </div>
                </div>
            )}
            {fromHasOptions && (
                <AccountDropdown
                    fieldname="fromAccountId"
                    currency={sourceCurrency}
                    accounts={exchangeDetails.fromAccounts.map(accountAsMergedAccount)}
                    direction="From"
                />
            )}
            {!toHasOptions && toAccount && (
                <div className={`To ${fromHasOptions ? 'ExtraMargin' : ''}`}>
                    <TextCurrencyIcon currencySymbol={toAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>To:</p>
                        <h4>{toAccount.label}</h4>
                    </div>
                    {toAccount.balance !== null && (
                        <div className="Balance">
                            {formatGeneralCurrency(toAccount.balance, destinationCurrency)}
                        </div>
                    )}
                </div>
            )}
            {toHasOptions && (
                <AccountDropdown
                    fieldname="toMergedAccountGenericId"
                    currency={destinationCurrency}
                    accounts={exchangeDetails.mergedToAccounts}
                    direction="To"
                />
            )}
            <TransferTypeSelector exchangeDetails={exchangeDetails} />
        </div>
    );
};

const TransferTypeSelector = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const {
        values: { toMergedAccountGenericId },
    } = useFormikContext<FormValues>();

    const selectedDestination = useMemo(
        () =>
            exchangeDetails.mergedToAccounts.find(
                (account) => account.genericId === toMergedAccountGenericId
            ),
        [exchangeDetails, toMergedAccountGenericId]
    );

    return (
        <RadioButtons
            options={selectedDestination?.allowedTransferTypes ?? []}
            fieldname={'transferType'}
        />
    );
};
