import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import api, { ApiResponse } from '../api';

export const useQueryFetch = <T>(
    url: string,
    key?: QueryKey,
    params?: any,
    options?: Omit<UseQueryOptions<ApiResponse<T>>, 'queryFn' | 'queryKey'>,
    axiosOptions?: AxiosRequestConfig
) => {
    const queryClient = useQueryClient();

    const removeQuery = () => {
        queryClient.removeQueries({ queryKey: key });
    };

    const { error, data, refetch, isLoading } = useQuery<ApiResponse<T>>({
        queryKey: key,
        queryFn: () =>
            api
                .get<ApiResponse<T>>(url, {
                    params,
                    ...axiosOptions,
                })
                .then((res) => res.data)
                .catch((err) => {
                    throw err;
                }),
        retry: (failureCount, error) => {
            if ((error as any)?.response?.status) {
                const statusCode = (error as AxiosError)?.response?.status;
                // Do not retry if the status code is 404 or 400

                if (statusCode === 404 || statusCode === 400 || statusCode === 403) {
                    return false;
                }
            }
            // Retry for all other cases
            return true;
        },
        ...(options ?? {}),
    });

    return {
        loading: isLoading,
        error: error ? error : null,
        data,
        refetch,
        removeQuery,
    };
};
