import { ErrorMessage } from 'api';
import { store } from '../..';
import { ERROR_CODES } from '../../errors';

const businessFields = [
    'AddressLine1',
    'AddressLine2',
    'CompanyName',
    'CountryISOCode',
    'EntityTypesID',
    'PhoneNumber',
    'PostCode',
    'RegistrationDate',
    'RegistrationNumber',
    'State',
    'TaxNumber',
    'TownCity',
    'Website',
];
const DEFAULT_MSG = 'An error has occurred. Please try again.';
export const handleRegistrationFormErrors = (errors: ErrorMessage[], actions: any) => {
    let businessErrors = {};
    let bPersonalForm = false;
    let prefix = 'personalDetails.';
    errors.forEach((error) => {
        let fieldName = prefix + error.fieldName.charAt(0).toLowerCase() + error.fieldName.slice(1);
        const codes = store.getState().errorCodes.codes ?? ERROR_CODES;
        let message = '';
        [message] = handleRegisterFormErrors(error);
        if (message === DEFAULT_MSG && codes[error.messageCode]) message = codes[error.messageCode];

        if (businessFields.includes(error.fieldName)) {
            businessErrors = { ...businessErrors, [fieldName]: message };
        }

        actions.setFieldError(fieldName, message);
    });
};

export const handleRegisterFormErrors = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            switch (error.fieldName) {
                case 'EmailAddress':
                    return [`Email address is required`, 'Personal'];
                case 'CompanyName':
                    return ['Company name is required', 'Business'];
                case 'Postcode':
                    return ['Postcode is required', 'Business'];

                default:
                    return ['Please provide information', 'General'];
            }
        case 'Invalid':
            switch (error.fieldName) {
                case 'EmailAddress':
                    return [`Email address is invalid`, 'Personal'];
                case 'CompanyName':
                    return ['Company name is invalid', 'Business'];
                default:
                    return ['Please provide valid information', 'General'];
            }
        case 'Already_In_Use':
            switch (error.fieldName) {
                case 'EmailAddress':
                    return [`Email address is already in use`, 'Personal'];
                case 'CompanyName':
                    return ['Company name is already in use', 'Business'];
                default:
                    return ['Already in use', 'General'];
            }
        case 'Already_In_Use_For_Personal_Account':
            switch (error.fieldName) {
                case 'EmailAddress':
                    return [`Email address is already in use for a personal account`, 'Personal'];
                case 'CompanyName':
                    return ['Company name is already in use for a personal account', 'Business'];
                default:
                    return ['Already used by a personal account', 'General'];
            }
        case 'Already_In_Use_For_Business_Account':
            switch (error.fieldName) {
                case 'EmailAddress':
                    return [`Email address is already in use for a business account`, 'Personal'];
                case 'CompanyName':
                    return ['Company name is already in use for a business account', 'Business'];
                default:
                    return ['Already used by a business account', 'General'];
            }
        case 'Password_No_Match':
            return ['Passwords do not match', 'Personal'];
        case 'Password_Policy_No_Match':
            return ['Password must contain a letter and a number', 'Personal'];
        default:
            return [DEFAULT_MSG, 'General'];
        // if (error.fieldName === 'EmailAddress') {
        //     return `Email address is required`;
        // } else if (error.fieldName === 'CompanyName') {
        //     return 'Company name is required';
        // }
        // return 'A required field is missing';
        // case 'Invalid':
        //     if (error.fieldName === 'EmailAddress') {
        //         return `Email address is invalid`;
        //     } else if (error.fieldName === 'CompanyName') {
        //         return 'Company name is invalid';
        //     }
        //     return 'There is an invalid field';
        // case 'Already_In_Use':
        //     if (error.fieldName === 'EmailAddress') {
        //         return `Email address is already in use`;
        //     } else if (error.fieldName === 'CompanyName') {
        //         return 'Company name is already in use';
        //     }
        //     return 'A field is already in use';
        // case 'Already_In_Use_For_Personal_Account':
        //     if (error.fieldName === 'EmailAddress') {
        //         return `Email address is already in use for a personal account`;
        //     } else if (error.fieldName === 'CompanyName') {
        //         return 'Company name is already in use for a personal account';
        //     }
        //     return 'A field is already in use';
        // case 'Already_In_Use_For_Business_Account':
        //     if (error.fieldName === 'EmailAddress') {
        //         return `Email address is already in use for a business account`;
        //     } else if (error.fieldName === 'CompanyName') {
        //         return 'Company name is already in use for a business account';
        //     }
        //     return 'A field is already in use';
        // case 'Password_No_Match':
        //     return 'Passwords do not match';
        // case 'Password_Policy_No_Match':
        //     return 'Password must contain a letter and a number';
        // default:
        //     return 'An error has occurred. Please try again.';
    }
};
export const handleAppQRErrors = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return `An email address is required`;
        case 'Invalid':
            return `Email address is invalid`;
        case 'Expired':
            return `Oops, please try again`;
        case 'Already_In_Use':
            return `This email address is already in use`;
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        case 'EmailAddress_Already_Verified':
            return 'Email address has already been verified.';
        default:
            return 'An error has occurred. Please try again.';
    }
};
export const handleSMSRequestError = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return `An email address is required`;
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        default:
            return 'An error has occurred. Please try again.';
    }
};

export const handleTFAError = (error: ErrorMessage) => {
    switch (error.messageCode) {
        case 'Required':
            return 'Please enter your 6-digit code';
        case 'Invalid':
            return 'Please enter a valid 6-digit code';
        case 'Expired':
            return 'Your 6-digit code has expired';
        case 'Username_Or_Password_Incorrect':
            return 'Your username or password is incorrect';
        case 'Account_Locked':
            return 'Too many failed attempts. Please retry in 10 minutes';
        case 'Account_Suppressed':
            return 'Account is currently inactive. Please try again later';
        case 'EmailAddress_Already_Verified':
            return 'Email address has already been verified.';
        default:
            return 'An error has occurred. Please try again.';
    }
};
