import { Spinner } from 'components/spinner/Spinner';
import { useCMS } from 'helpers/useCMS';
import { useEffect, useRef } from 'react';

interface CMSContentProps {
    page: string;
}

export const CMSContent: React.FC<CMSContentProps> = ({ page }) => {
    const CMSResponse = useCMS(`/${page}`);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        //set all anchor tags to open in new window
        const aElements = containerRef.current?.querySelectorAll('a');
        aElements?.forEach((element) => (element.target = '_blank'));
    }, [CMSResponse]);

    return CMSResponse && CMSResponse.content ? (
        <div ref={containerRef} dangerouslySetInnerHTML={{ __html: CMSResponse.content }}></div>
    ) : (
        <Spinner className="CMSContentSpinner" />
    );
};
