import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { setVerificationStage } from 'reducers/verification';
import { JumioVerificationStatusResponse } from './jumioModels';

export const JumioCooldown = ({ bManualAvailable }: { bManualAvailable: boolean }) => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const handleManualInterventionRequest = () => {
        setSubmitting(true);
        instance
            .post<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.requestManualVerification
            )
            .then((res) =>
                dispatch(
                    setVerificationStage({
                        currentStage: res.data.details.status,
                        additionalData: res.data.details.additionalData,
                    })
                )
            )
            .catch(() => {
                setSubmitting(false);
                Toast.openGenericErrorToast();
            });
    };

    return (
        <div className="VerificationComplete">
            <h3>Verification Limit Reached</h3>
            <p>Please come back and try again later</p>
            <Button variety="full" onClick={() => dispatch(signOut())}>
                Sign out
            </Button>
            {bManualAvailable && (
                <Button
                    disabled={submitting}
                    className="ManualInterventionBtn"
                    onClick={handleManualInterventionRequest}
                >
                    Click here to have one of our team manually look through your details instead
                </Button>
            )}
        </div>
    );
};
