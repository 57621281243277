import { useTable } from 'api';
import { HistoryTable } from 'components/historyTable/HistoryTable';
import Pagination from 'components/pagination/Pagination';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';
import { endpoints } from 'endpoints.config';
import { useDebouncedState } from 'helpers/useDebouncedState';
import React, { useContext, useEffect } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';

export type Data = {
    crypto__Id: number;
    crypto__Ticker: string;
    crypto__Name: string;
    crypto__Price: number;
    crypto__24hr: number;
    crypto__PercentChnge: number;
};

const CryptoOrderHistory: React.FC = () => {
    const { selectedCurrencyInfo } = useContext(ExchangeCryptoContext);
    const table = useTable({
        url: endpoints.exchangemodule.historyList,
        filters: `Assets__Code EQ (${selectedCurrencyInfo?.ticker})`,
        multiSort: false,
    });
    const [searchString, setSearchString, localSearchString] = useDebouncedState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.currentTarget.value);
    };

    useEffect(() => {
        table.data?.actions.changeSearch(searchString);
    }, [searchString]);

    return (
        <div className="CryptoHoldings">
            <div className="MarketTableSearch OrderHistory">
                <h2>{`Order History (${selectedCurrencyInfo!.ticker})`}</h2>
                <TableSearchInput
                    value={localSearchString}
                    onChange={handleSearch}
                    placeholder={'Search all references'}
                />
            </div>
            <HistoryTable idColumn="" table={table} simpleReferenceCell />
            <Pagination table={table} />
        </div>
    );
};

export { CryptoOrderHistory };
