import { useTable } from 'api';
import Button from 'components/button/Button';
import { TableProps } from 'components/flexTable/FlexTable';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';
import { TradeTable, TradeTableLiveData, TradeTableRow } from 'components/tradeTable/TradeTable';
import React, { useContext, useState } from 'react';
import { CryptoContext, CryptoLiveData } from './CryptoPage';
import { splitCryptoPairString, useCryptoContextEffects } from './useCryptoContextEffects';

const parseCryptosToMarketTable = (
    cryptos: CryptoLiveData[],
    selectedFiatCurrency: string
): TradeTableLiveData => {
    return cryptos.reduce((acc, crypto) => {
        const { crypto: ticker, fiat } = splitCryptoPairString(crypto.pair);
        if (fiat !== selectedFiatCurrency) {
            return acc;
        }
        const tradeTableRow = {
            price: crypto.buyPrice,
            percentChange: crypto.priceVariability,
            high: crypto.high24hrs,
            low: crypto.low24hrs,
        };
        return { ...acc, [ticker]: tradeTableRow };
    }, {});
};

const SEARCH_DEBOUNCE_TIME = 500;

const Cryptos: React.FC = () => {
    const { cryptos, searchString, setSearchString, selectedFiatCurrency, endpoints } =
        useContext(CryptoContext);

    const [searchTimeout, setSearchTimeout] = useState<null | number>(null);

    const cryptosTable = useTable<TradeTableRow, any>({
        url: endpoints.marketList,
        pageSize: 100,
        bClearSearch: true,
        bClearSortBy: true,
        multiSort: false,
    });

    // // When changing between crypto and stablecoin tab.
    // useEffect(() => {
    //     if (cryptosTable.data) {
    //         cryptosTable.data.actions.changeSearch('');
    //         cryptosTable.data.details.listData = [];
    //     }
    // }, [endpoints]);

    useCryptoContextEffects(cryptosTable);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        setSearchString(search);
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        const timeout = window.setTimeout(() => {
            cryptosTable.data?.actions.changeSearch(search);
            setSearchTimeout(null);
        }, SEARCH_DEBOUNCE_TIME);
        setSearchTimeout(timeout);
    };

    return (
        <div className="Cryptos">
            <div className="Market">
                <div className="MarketSubHeader">
                    <div className="Left">
                        <Button priority="secondary" className="MarketViewBtn">
                            <a href="https://trade.kraken.com/markets" target="__blank">
                                Advanced Markets view
                            </a>
                        </Button>
                    </div>
                    <div className="MarketTableSearch">
                        <TableSearchInput
                            name="search"
                            placeholder="Search all currencies"
                            className="EditBox"
                            value={searchString}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                {<div className="TableLoaderContainer"></div>}
                {selectedFiatCurrency && (
                    <TradeTable
                        idColumn={'assets__Id'}
                        table={cryptosTable as TableProps['table']}
                        liveData={parseCryptosToMarketTable(cryptos, selectedFiatCurrency.code)}
                        selectedCurrency={selectedFiatCurrency}
                        isLoading={cryptosTable.loading}
                        hideFiltering
                    />
                )}
            </div>
        </div>
    );
};

export { Cryptos };
