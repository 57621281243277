import React from 'react';
import { SearchBox } from 'components/categoryHelpers/searchBox/SearchBox';
import { TableInfo } from 'api';
import Button from 'components/button/Button';

export type PageHeaderProps = {
    pageTitle: string;
    table: TableInfo;
    changeSearch?(searchString: string): void;
    initialSearchString: string;
    addButtonText: string;
    onAddButtonClick?(): void;
    onOpenFilters?: any;
    noAddBtn: boolean;
};

const PageHeader: React.FC<PageHeaderProps> = ({
    pageTitle,
    table,
    changeSearch,
    initialSearchString,
    addButtonText,
    onAddButtonClick,
    noAddBtn,
}) => {
    return (
        <div className="PageHeader">
            <h1 style={{ marginBottom: '0' }}>{pageTitle}</h1>
            <div className="HeaderActionsContainer">
                {(table?.data?.details as any).bHasSearchField && (
                    <SearchBox
                        key={initialSearchString}
                        changeSearch={changeSearch}
                        initialSearchString={initialSearchString}
                        goToPage={table.data?.actions.goToPage}
                    />
                )}
                {noAddBtn && noAddBtn === true ? (
                    <></>
                ) : (
                    <div className="Buttons">
                        {onAddButtonClick && (
                            <Button onClick={onAddButtonClick}>{addButtonText}</Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export { PageHeader };
