import { navigate, RouteComponentProps } from '@reach/router';
import { AppPath, PageResourceKey } from 'appConstants';
import { usePageTranslations } from 'helpers/usePageTranslations';
import { SignInTranslation } from 'pages/signIn/signInTypes';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStatus, setAuthStatus } from 'reducers/auth';
import { selectCultureCode } from 'reducers/language';
import Page from '../../components/page/Page';
import { EmailVerification } from './EmailVerification';
import { AccountDetails, AuthType, RegisterStep, RegistrationAdditionalInfo } from './models';
import { postEmailVerificationCode } from './RegisterApi';
import { RegistrationSuccessMessage } from './RegistrationSuccessMessage';
import { SelectAccountType } from './SelectAccountType';
import { SelectAuthType } from './SelectAuthType';
import { TFASetup } from './TFASetup';
import { TFAVerification } from './TFAVerification';
import { InputRegistrationDetails } from './InputRegistrationDetails';
import { RegistrationContext } from 'helpers/registration';

export type RegisterInitialState = {
    stage: 'emailVerification' | 'tfa';
    email: string;
    password: string;
};

// TODO(Hari): Move individual form steps into single shared Formik state one submission schema has
// been determined.
export const Register: React.FC<RouteComponentProps & { skipWaitlist?: boolean }> = ({
    location,
    skipWaitlist: allowPersonalRegistration = false,
}) => {
    const [registrationStep, setRegistrationStep] = useState<RegisterStep>(
        RegisterStep.SELECT_ACCOUNT_TYPE
    );
    const [authType, setAuthType] = useState<AuthType>(AuthType.APP);
    const [accountDetails, setAccountDetails] = useState<AccountDetails>({
        emailAddress: '',
        password: '',
    });
    const [sharedSecret, setSharedSecret] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [registrationAdditionalInfo, setRegistrationAdditionalInfo] =
        useState<RegistrationAdditionalInfo | null>(null);
    const accountType = registrationAdditionalInfo?.accountType ?? null;
    const translations = usePageTranslations<SignInTranslation>(PageResourceKey.SIGN_IN);

    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);

    const resendEmailVerificationCode = (email: string, password: string) => {
        postEmailVerificationCode({
            username: email,
            password: password,
            bResendCode: true,
            emailVerificationCode: '',
        });
    };

    if (authStatus === 'signed_in') {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
    }

    useEffect(() => {
        if (location?.state) {
            const initialState = location?.state as RegisterInitialState;
            if (initialState.stage === 'emailVerification') {
                setAccountDetails({
                    emailAddress: initialState.email,
                    password: initialState.password,
                });
                resendEmailVerificationCode(initialState.email, initialState.password);
                setRegistrationStep(RegisterStep.EMAIL_VERIFICATION);
            } else if (initialState.stage === 'tfa') {
                setAccountDetails({
                    emailAddress: initialState.email,
                    password: initialState.password,
                });
                setRegistrationStep(RegisterStep.SELECT_AUTH_TYPE);
            }
            // Resetting auth status back to signed-out so that user sees the
            // sign-in form once they have completed registration.
            setAuthStatus('signed_out');
        }
    }, [location?.state]);

    const handleOnComplete = (nextStep: RegisterStep) => () => {
        setRegistrationStep(nextStep);
    };

    return (
        <Page isPublic={true}>
            <RegistrationContext.Provider
                value={{ accountType, registrationAdditionalInfo, setRegistrationAdditionalInfo }}
            >
                <div className="RegistrationForm RegistrationFormWidth">
                    {registrationStep === RegisterStep.SELECT_ACCOUNT_TYPE && (
                        <SelectAccountType
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.INPUT_REGISTRATION_DETAILS)}
                            accountType={accountType}
                            disablePersonalRegistration={!allowPersonalRegistration}
                        />
                    )}
                    {registrationStep === RegisterStep.INPUT_REGISTRATION_DETAILS && (
                        <InputRegistrationDetails
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.EMAIL_VERIFICATION)}
                            accountType={accountType}
                            setAccountDetails={setAccountDetails}
                        />
                    )}
                    {/* {registrationStep === RegisterStep.INPUT_GENERAL_DETAILS && (
                    <InputPersonalDetails
                        translations={translations}
                        onComplete={handleOnComplete(RegisterStep.EMAIL_VERIFICATION)}
                        accountType={AccountType.PERSONAL}
                        setAccountDetails={setAccountDetails}
                    />
                )} */}
                    {registrationStep === RegisterStep.EMAIL_VERIFICATION && (
                        <EmailVerification
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.SELECT_AUTH_TYPE)}
                            accountDetails={accountDetails}
                        />
                    )}
                    {registrationStep === RegisterStep.SELECT_AUTH_TYPE && (
                        <SelectAuthType
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.TFA_SETUP)}
                            authType={authType}
                            setAuthType={setAuthType}
                        />
                    )}
                    {registrationStep === RegisterStep.TFA_SETUP && (
                        <TFASetup
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.TFA_VERIFICATION)}
                            authType={authType}
                            setAuthType={setAuthType}
                            accountDetails={accountDetails}
                            setSharedSecret={setSharedSecret}
                            setPhoneNumber={setPhoneNumber}
                        />
                    )}
                    {registrationStep === RegisterStep.TFA_VERIFICATION && (
                        <TFAVerification
                            translations={translations}
                            onComplete={handleOnComplete(RegisterStep.COMPLETE)}
                            authType={authType}
                            setAuthType={setAuthType}
                            accountDetails={accountDetails}
                            sharedSecret={sharedSecret}
                            phoneNumber={phoneNumber}
                            setRegistrationStep={setRegistrationStep}
                        />
                    )}
                    {registrationStep === RegisterStep.COMPLETE && <RegistrationSuccessMessage />}
                </div>
            </RegistrationContext.Provider>
        </Page>
    );
};
