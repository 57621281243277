import { FormBuilder } from '@avamae/formbuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik } from 'formik';
import React from 'react';

type CurrencyOptionsModalProps = {}

const CurrencyOptionsModal: React.FC<CurrencyOptionsModalProps> = () => {

    const handleSubmit = () => {
    }

    return (
        <div className="CurrencyOptionsModal">
            <Formik
                initialValues={{ alert: 0 }}
                onSubmit={handleSubmit}
            >
                <Form className="FormBuilderForm">
                    <p>Your Kraken account Primary currently uses GBP as its default currency. Your activity will be displayed in this currency.</p>
                    <FormBuilder
                        loadingFallback={<Spinner />}
                        data={formData}
                        additionalData={{ defaultCurrency: [{ label: 'GBP', key: 'GBP' }, { label: 'USD', key: 'USD' }] }}
                    />
                </Form>
            </Formik>
        </div>
    )
}

export { CurrencyOptionsModal };


const formData: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: "defaultCurrency",
            type: "singleselect",
            label: "New default currency",
            bRemoteDataSource: false,
        },
    ],
    status: "1",
    errors: []
}