import Button from 'components/button/Button';
import { FlexTable } from 'components/flexTable';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import {
    VariabilityCell,
    EmptyCell,
    HighLowCell,
    NameCell,
    PriceCell,
} from 'components/flexTable/CustomCells';
import { TableProps } from 'components/flexTable/FlexTable';
import {
    FiatCurrency,
    generateCryptoLiveData,
    CryptoLiveData,
    parseLowHighString,
} from 'pages/crypto/CryptoPage';
import React, { useContext } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';

export type TradeTableRow = {
    assets__Id: number;
    assets__Code: string;
    assets__Name: string;
    market__BuyPrice: number;
    market__MarketHighLow: string | null;
    market__PercentChange: number;
    fiatAssets__Code: string;
};

export type TradeTableLiveDataPoint = {
    price: number | null;
    percentChange: number | null;
    high: number | null;
    low: number | null;
};

export type TradeTableLiveData = {
    [key: string]: TradeTableLiveDataPoint;
};

const rowFormatter =
    (selectedCurrency: FiatCurrency, isLoading: boolean) =>
    (row: TradeTableRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: any = { ...row };

        if (row.assets__Name && row.assets__Code) {
            formattedRow = {
                ...formattedRow,
                assets__Name: !isLoading ? (
                    <NameCell name={row.assets__Name} ticker={row.assets__Code} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.market__BuyPrice) {
            formattedRow = {
                ...formattedRow,
                market__Price: !isLoading ? (
                    <PriceCell
                        currencySymbol={selectedCurrency.symbol}
                        currencyTicker={selectedCurrency.code}
                        price={row.market__BuyPrice}
                    />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.market__PercentChange || row.market__PercentChange === 0) {
            formattedRow = {
                ...formattedRow,
                market__PercentChange: isLoading ? (
                    <EmptyCell />
                ) : (
                    <VariabilityCell amount={row.market__PercentChange} />
                ),
            };
        }

        if (row.market__MarketHighLow) {
            const { high, low } = parseLowHighString(row.market__MarketHighLow);
            formattedRow = {
                ...formattedRow,
                market__MarketHighLow: !isLoading ? (
                    <HighLowCell
                        high={high}
                        low={low}
                        price={row.market__BuyPrice}
                        currencySymbol={selectedCurrency.symbol}
                    />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        return formattedRow;
    };

export const ExchangeTradeTable: React.FC<
    TableProps & {
        liveData: TradeTableLiveData;
        selectedCurrency: FiatCurrency;
        isLoading: boolean;
    }
> = (props) => {
    const formatter = rowFormatter(props.selectedCurrency, props.isLoading);
    const { setSelectedCurrencyInfo } = useContext(ExchangeCryptoContext);
    return (
        <div className="TradeTableContainer">
            <FlexTable
                {...props}
                rowFormatter={formatter}
                rowButton={createRowButtons(setSelectedCurrencyInfo, props.isLoading)}
            />
            {props.table?.data?.details.listData.length === 0 && !props.table?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">Empty list</h3>
                </div>
            )}
        </div>
    );
};

const createRowButtons =
    (
        setSelectedCurrencyInfo: React.Dispatch<React.SetStateAction<CryptoLiveData | null>>,
        isLoading: boolean
    ) =>
    (rowData: TradeTableRow) => {
        const handleBuySell = (crypto: string, fiat: string) => {
            const {
                assets__Code,
                assets__Name,
                market__MarketHighLow,
                fiatAssets__Code,
                market__BuyPrice: market__Price,
            } = rowData;
            const { high, low } = parseLowHighString(market__MarketHighLow as string);
            const latestData = generateCryptoLiveData(
                assets__Name,
                assets__Code,
                fiatAssets__Code,
                market__Price,
                low,
                high
            );
            setSelectedCurrencyInfo(latestData);
        };

        return !isLoading ? (
            <div className="ExchangeMarketRowButtons">
                <Button
                    className="Buy"
                    onClick={() => handleBuySell(rowData.assets__Code, rowData.fiatAssets__Code)}
                >
                    Buy / Sell
                </Button>
            </div>
        ) : (
            <EmptyCell />
        );
    };
