import instance, { isErrorHandled } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import FormTextField from 'components/form/FormTextField';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { getErrorMessage } from 'errors';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useNavigate } from '@reach/router';
import { CardSubPage } from './CardPage';

const initialValues = {
    oldPin: '',
    newPin: '',
};

const validationSchema = Yup.object({
    oldPin: Yup.string().required('Please enter your current PIN'),
    newPin: Yup.string().required('Please enter a new PIN').length(4, 'PIN must be 4 digits long'),
});

export const ChangePin = ({ cardId }: { cardId: number }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post(endpoints.cards.changeCardPin, { ...values, topUpCardsId: cardId })
            .then((res) => {
                Toast.openToastMessage('PIN Changed Successfully', ToastMessageReason.VALID);
                navigate(`../${cardId}/${CardSubPage.TRANSACTIONS}`);
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                if (isErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (errors.some((error) => ['OldPin', 'NewPin'].includes(error.fieldName))) {
                        errors.forEach((error) =>
                            formikHelpers.setFieldError(
                                toCamelCase(error.fieldName) as string,
                                getErrorMessage(error.messageCode)
                            )
                        );
                    } else setErrorMessage(getErrorMessage(errors[0].messageCode));
                } else {
                    setErrorMessage('Something went wrong');
                }
            });
    };

    return (
        <div className="ChangePinPage FormPage">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <Form>
                        <FormTextField
                            field="oldPin"
                            required
                            label="Current PIN"
                            placeholder="4 digit pin"
                            inputMode="numeric"
                            maxLength={4}
                            onChange={(e) => {
                                props.setFieldValue('oldPin', e.target.value.replaceAll(/\D/g, ''));
                            }}
                        />
                        <FormTextField
                            field="newPin"
                            required
                            label="New PIN"
                            placeholder="4 digit pin"
                            inputMode="numeric"
                            maxLength={4}
                            onChange={(e) => {
                                props.setFieldValue('newPin', e.target.value.replaceAll(/\D/g, ''));
                            }}
                        />
                        <Button variety="full" type="submit" disabled={props.isSubmitting}>
                            Change PIN
                        </Button>
                    </Form>
                )}
            </Formik>
            {errorMessage && <div className="ErrorLabel">{errorMessage}</div>}
        </div>
    );
};
