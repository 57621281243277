import { TableInfo, useTable } from 'api';
import Button from 'components/button/Button';
import { DateRangePicker } from 'components/dateRangePicker/DateRangePicker';
import { CryptoHistoryItem, HistoryTable, ID_COLUMN } from 'components/historyTable/HistoryTable';
import Pagination from 'components/pagination/Pagination';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { openModal, ModalTypes, selectModalState } from 'reducers/modal';
import { CryptoContext } from './CryptoPage';
import { selectComponentResources } from 'reducers/componentResources';
import { canShowLiquidateModal } from 'components/modals/LiquidateCryptoModal';
import { useWindowDimensions } from 'helpers/useWindowDimensions';
import { checkIsPendingCrypto } from 'pages/cryptoaccounts/CryptoAccountsHistory';
import { TableEntryInfoPanel } from 'components/TableEntryInfoPanel/TableEntryInfoPanel';
import styles from '../../components/TableEntryInfoPanel/TableEntryInfoPanel.module.scss';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';
import { selectRefreshTransactionFlag } from '../../reducers/utility';

export const History = ({ ticker }: { ticker?: string }) => {
    const { endpoints, setIsPageLoading, isPageLoading } = useContext(CryptoContext);
    const [selectedRow, setSelectedRow] = useState<number>();
    const refreshTransactionsFlag = useSelector(selectRefreshTransactionFlag);

    const table = useTable<CryptoHistoryItem, Error>({
        url: endpoints.historyList,
        bClearFilters: false,
        bClearSearch: true,
        multiSort: false,
        filters: `assets__Code EQ (${ticker?.toUpperCase()})`,
        params: { assetCode: ticker?.toUpperCase() },
    });
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const cultureCode = useSelector(selectCultureCode);
    const dispatch = useDispatch();
    const modalState = useSelector(selectModalState);
    const { width } = useWindowDimensions();

    useAfterMountEffect(() => {
        table.reload();
    }, [refreshTransactionsFlag]);

    const handleOpenWithdrawModal = () => {
        if (!ticker) return null;
        if (ticker.toLowerCase() === 'dusd') {
            dispatch(
                openModal({
                    modalType: ModalTypes.WITHDRAW_CRYPTO_AS_FIAT,
                })
            );
        } else {
            dispatch(
                openModal({
                    modalType: ModalTypes.WITHDRAW_CRYPTO,
                    data: { crypto: ticker.toUpperCase(), withdrawUrl: endpoints.withdraw },
                })
            );
        }
    };

    const openLiquidateModal = () => {
        if (!ticker) return;
        dispatch(
            openModal({
                modalType: ModalTypes.LIQUIDATE_CRYPTO,
                data: { crypto: ticker.toUpperCase(), fromAccount: 1000 },
            })
        );
    };

    const openDepositModal = () => {
        if (!ticker) return;
        dispatch(
            openModal({
                modalType: ModalTypes.DEPOSIT_CRYPTO,
                data: {
                    crypto: ticker.toUpperCase(),
                    depositEndpoint: endpoints.getDepositAddress,
                },
            })
        );
    };

    useEffect(() => {
        if (
            table.data &&
            !table.data.details.filters.some(
                (filter) =>
                    filter.columnKey === 'assets__Code' &&
                    filter.filters.some(
                        (filterObj) => filterObj.value === (ticker?.toUpperCase() ?? '')
                    )
            )
        ) {
            table.data.actions.changeFilter('assets__Code', [
                { columnKey: 'assets__Code', operator: 'EQ', value: ticker?.toUpperCase() ?? '' },
            ]);
        }
    });

    useEffect(() => {
        if (isPageLoading && !table.loading) {
            setIsPageLoading(false);
        }
    }, [setIsPageLoading, isPageLoading, table.data, table.loading]);

    const setFilter = (startDate: Date, endDate: Date) => {
        table.data?.actions.changeFilter('customerAssetAccountsTransactions__Date', [
            {
                columnKey: 'customerAssetAccountsTransactions__Date',
                operator: 'BETWEEN',
                value: `${startDate.toISOString()}TO${endDate.toISOString()}`,
            },
        ]);
    };
    const onRowClick = (id: number) => {
        if (selectedRow === id) setSelectedRow(undefined);
        else setSelectedRow(id);
    };
    useEffect(() => {
        if (width >= 1400 || !selectedRow) return;
        else
            dispatch(
                openModal({
                    modalType: ModalTypes.TRANSACTION_DETAILS,
                    data: {
                        rowData: {
                            id: selectedData!.customerAssetAccountsTransactions__Id,
                            amount: selectedData!.customerAssetAccountsTransactions__Amount,
                            moneyIn: selectedData!.transactionTypes__Type === 'Buy',
                            reference: `${selectedData!.assets__Code}: ${
                                selectedData!
                                    .customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode
                            }`,
                            currencyCode: selectedData!.assets__Code,
                            isPending: checkIsPendingCrypto(selectedData),
                            approvalStatus: selectedData!.transactionAmounts__ApprovalStatus,
                            bEligibleForReverse:
                                selectedData!.transactionAmounts__bEligibleForReverse,
                            bCancellable: selectedData!.bCancellable,
                        },
                        currencySymbol: '',
                    },
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, width]);
    useEffect(() => {
        if (modalState.modalType) return;
        else setSelectedRow(undefined);
    }, [modalState]);
    const selectedData = table.data?.details.listData.find(
        (entry) => entry[ID_COLUMN as keyof CryptoHistoryItem] === selectedRow
    );
    const emptyTable = table?.data?.details.listData.length === 0;

    return (
        <div className="Cryptos">
            <div className="Portfolio">
                <div className="TableHeader">
                    <DateRangePicker setFilter={setFilter} cultureCode={cultureCode} />
                    <SearchBox
                        key={'searchBox'}
                        placeholder="Search transactions"
                        initialSearchString={table?.data?.details.searchString ?? ''}
                        changeSearch={table?.data?.actions.changeSearch}
                        goToPage={table?.data?.actions.goToPage}
                    />
                    <div className="HeaderButtons">
                        <Button priority="primary" onClick={handleOpenWithdrawModal}>
                            Withdraw{' '}
                            {ticker?.toLowerCase() === 'dusd' ? 'USD' : ticker?.toUpperCase()}
                        </Button>
                        {ticker && ticker.toLowerCase() !== 'dusd' && (
                            <Button priority="primary" color="second" onClick={openDepositModal}>
                                Deposit {ticker?.toUpperCase()}
                            </Button>
                        )}
                        {ticker && canShowLiquidateModal(ticker, customerMenuLinks) && (
                            <Button priority="secondary" onClick={openLiquidateModal}>
                                Liquidate {ticker?.toUpperCase()}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="TableWrapper">
                    <HistoryTable
                        table={table as TableInfo}
                        selectedIds={selectedRow ? [selectedRow] : undefined}
                        onRowClick={onRowClick}
                        noRowButton
                        idColumn={''}
                    />
                    {!emptyTable && (
                        <div className={styles.TableEntryInfoPanelWrapper}>
                            <TableEntryInfoPanel
                                selectedRowData={
                                    selectedData
                                        ? {
                                              id: selectedData.customerAssetAccountsTransactions__Id,
                                              amount: selectedData.customerAssetAccountsTransactions__Amount,
                                              moneyIn:
                                                  selectedData.transactionTypes__Type === 'Buy',
                                              reference:
                                                  selectedData.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode,
                                              currencyCode: selectedData.assets__Code,
                                              isPending: checkIsPendingCrypto(selectedData),
                                              approvalStatus:
                                                  selectedData.transactionAmounts__ApprovalStatus,
                                              bEligibleForReverse:
                                                  selectedData.transactionAmounts__bEligibleForReverse,
                                              bCancellable: selectedData.bCancellable,
                                          }
                                        : undefined
                                }
                                closePanel={() => setSelectedRow(undefined)}
                            />
                        </div>
                    )}
                </div>
                <Pagination table={table} />
            </div>
        </div>
    );
};
