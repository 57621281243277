import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';
import { ModalTypes, openModal } from 'reducers/modal';

type Props = { customerAssetAccountsId: number; reloadTable: () => void };

export const LaunchVisaDirect = ({ customerAssetAccountsId, reloadTable }: Props) => {
    const dispatch = useDispatch();
    const openVisaDirectModal = () => {
        dispatch(
            openModal({
                modalType: ModalTypes.VISA_DIRECT,
                data: { customerAssetAccountsId, reloadTable },
            })
        );
    };
    return (
        <div className="AddPersonalAccount">
            <Button priority="primary" onClick={openVisaDirectModal}>
                Add Visa Direct
            </Button>
        </div>
    );
};
