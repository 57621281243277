import instance from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';

export const ReplaceCard = ({ cardId }: { cardId: number }) => {
    const handleOrder = () => {
        instance
            .post(endpoints.cards.reorderCard, { topUpCardsId: cardId })
            .then((res) => Toast.openSuccessToast('Your card has been reordered successfully')) // TODO add success message
            .catch((err) => Toast.openGenericErrorToast()); // TODO add error handling
    };

    return (
        <div className="ReplaceCardPage FormPage">
            <p>If your card is lost or damaged you can order a new one using the form below</p>

            <Button variety="full" onClick={handleOrder}>
                Replace Card
            </Button>
        </div>
    );
};
