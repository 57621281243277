import instance, { ApiResponse } from 'api';
import { Modal } from 'components/modal/Modal';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { endpoints } from 'endpoints.config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { isEqual } from 'lodash';
import { JumioIframe } from './JumioIdIframe';
import { JumioTextualData } from './JumioTextualData';
import { JumioVerificationComplete } from './JumioVerificationComplete';
import { ManualVerificationRequired } from './JumioManualVerificationRequired';
import Button from 'components/button/Button';
import {
    fetchVerificationStatus,
    selectVerificationDetails,
    selectVerificationLoading,
    setVerificationStage,
} from 'reducers/verification';
import { JumioStage, JumioVerificationStatusResponse } from './jumioModels';
import { Spinner } from 'components/spinner/Spinner';
import { JumioFailure } from './JumioFailure';
import { JumioCooldown } from './JumioCooldown';
import { JumioRejected } from './JumioRejected';
import { Toast } from 'helpers/toast';
import { JumioConsent } from './JumioConsent';
import { PendingAdminApproval } from './PendingAdminApproval';

export type VerifyIdentityOptions = {
    countryOptions: { isoCode: string; name: string }[];
    documentOptions: { typeCode: string; name: string }[];
};

export const JumioVerification = () => {
    const dispatch = useDispatch();
    const update = useSelector(selectUIUpdate);
    const [verifyIdentityOptions, setVerifyIdentityOptions] =
        useState<VerifyIdentityOptions | null>(null);
    const { stage, additionalData } = useSelector(selectVerificationDetails);
    const loading = useSelector(selectVerificationLoading);

    useEffect(() => {
        if (update?.pushType !== NotificationIdentifier.UPDATE_VERIFICATION_COMPONENTS) return;
        dispatch(
            setVerificationStage({
                currentStage: (update.data as JumioVerificationStatusResponse).status,
                additionalData: update.data.additionalData,
            })
        );
    }, [update, dispatch]);

    useEffect(() => {
        if (
            (stage === JumioStage.AddressFrameForm || stage === JumioStage.IdFrameSubmitted) &&
            !verifyIdentityOptions
        ) {
            instance
                .get<ApiResponse<VerifyIdentityOptions>>(endpoints.jumiomodule.verifyIdentity)
                .then((res) => {
                    setVerifyIdentityOptions(res.data.details);
                })
                .catch((err) => Toast.openGenericErrorToast());
        }
    });

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(fetchVerificationStatus()), 20000);
        return clearTimeout(timeout);
    });

    if (loading) {
        return null;
    }

    const chooseStage = () => {
        switch (stage) {
            case JumioStage.ConsentCollection:
                return <JumioConsent />;
            case JumioStage.IdFrame:
                return (
                    additionalData?.iframeUrl && (
                        <JumioIframe url={additionalData?.iframeUrl} stage={stage} />
                    )
                );
            case JumioStage.IdFrameSubmitted:
                return <PendingScreen />;
            case JumioStage.IdFrameFailed:
                return <JumioFailure bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.AddressFrameForm:
                return (
                    verifyIdentityOptions && <JumioTextualData options={verifyIdentityOptions} />
                );
            case JumioStage.AddressFrame:
                return (
                    additionalData?.iframeUrl && (
                        <JumioIframe url={additionalData?.iframeUrl} stage={stage} />
                    )
                );
            case JumioStage.AddressFrameSubmitted:
                return <PendingScreen />;
            case JumioStage.AddressFrameFailed:
                return <JumioFailure bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.AwaitingManualVerification:
                return <ManualVerificationRequired />;
            case JumioStage.PendingVerification:
                return <PendingAdminApproval />;
            case JumioStage.FrameCooldown:
                return <JumioCooldown bManualAvailable={!!additionalData?.bManualAvailable} />;
            case JumioStage.Success:
                return <JumioVerificationComplete />;
            case JumioStage.Rejected:
                return <JumioRejected />;
        }
    };

    return (
        <Modal
            title="Customer verification"
            customCloseButton={
                <span className="SignOutLink" onClick={() => dispatch(signOut())}>
                    Sign out
                </span>
            }
            className="JumioModal"
            backgroundClassName="JumioModalBg"
        >
            {chooseStage()}
        </Modal>
    );
};

const PendingScreen = () => {
    return (
        <div className="Waiting">
            <Spinner />
            <h3>Please wait, we are checking your details</h3>
            <p>This may take a few minutes, we'll email you when it's done.</p>
        </div>
    );
};
