import { Redirect } from '@reach/router';
import React from 'react';
import CardTransactions from './cardTransactions/CardTransactions';
import ManageCardRequests from './manageCards/ManageCardRequests';
import ManageCards from './manageCards/ManageCards';
import ManageCardUsers from './manageUsers/ManageCardUsers';

export enum CardManagementSubpage {
    Transactions = 'transactions',
    Users = 'users',
    Cards = 'cards',
    CardRequests = 'requests',
}
type Props = { subPage?: string };

function CardManagement({ subPage }: Props) {
    switch (subPage) {
        case CardManagementSubpage.Transactions:
            return <CardTransactions />;
        case CardManagementSubpage.Cards:
            return <ManageCards />;
        case CardManagementSubpage.Users:
            return <ManageCardUsers />;
        case CardManagementSubpage.CardRequests:
            return <ManageCardRequests />;
        default:
            return <ManageCardUsers />;
    }
}

export default CardManagement;
