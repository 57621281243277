import React from 'react';

export const MoveMoneySubPage: React.FC<{ back?: () => void }> = ({ children, back }) => {
    return (
        <div className="MoveMoneySubPage">
            <div className="LeftSide">
                {back && (
                    <div className="LinkText" onClick={back}>
                        {'< Back'}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
};
