import { RefObject, useEffect, useRef, useState } from 'react';

export const useDivHeight = (): [RefObject<HTMLDivElement>, number] => {
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (!ref.current) return;
        setHeight(ref.current.clientHeight);
    }, [setHeight, ref.current?.clientHeight]);

    return [ref, height];
};
