import { Link } from '@reach/router';
import { AppPath, DashboardPath } from 'appConstants';
import expandIcon from 'assets/ibanera/Icon_Dropdown_01.png';
import Button from 'components/button/Button';
import { useOnLocationChange } from 'helpers/useOnLocationChange';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutWithBackendCall } from 'reducers/auth';
import { selectAccountTypePathItem, selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

const menuLinksForWhichToShowChildren = ['view-profile', 'manage'];

export const UserDropdown = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const cultureCode = useSelector(selectCultureCode);
    const { customerMenuLinks, userInfo } = useSelector(selectComponentResources);
    const menuLinks = customerMenuLinks?.filter((item) => item.position === 'TopMenu');

    const toggleMenuOpen = () => {
        setIsOpen((oldIsOpen) => !oldIsOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleSignOut = () => {
        dispatch(signOutWithBackendCall());
    };

    useOnLocationChange(closeMenu);

    const isLinkActive = (link: string) => {
        const pathname = window.location.pathname;
        return pathname === `${link}`;
    };

    const accountTypePathItem = useSelector(selectAccountTypePathItem);
    const basePath = `/${cultureCode}${accountTypePathItem}`;

    const profileLink =
        menuLinks &&
        menuLinks.find((link) => link.resourceKey === 'key_access_elements_View_Profile');
    const otherDropdownLinks = menuLinks && menuLinks.slice(1);

    return (
        <div className="UserDropdown">
            {isOpen && <div className="ClickOffCatcher" onClick={closeMenu} />}
            <Button
                priority="tertiary"
                variety="full"
                icon={expandIcon}
                iconClassName="OpenIcon"
                onClick={toggleMenuOpen}
                className="OpenButton Desktop"
            >
                {userInfo?.firstName} {userInfo?.lastName}
            </Button>

            <Button
                priority="primary"
                variety="full"
                icon={expandIcon}
                iconClassName="OpenIcon"
                onClick={toggleMenuOpen}
                className="OpenButton Mobile"
            >
                {userInfo?.firstName[0]}
                {userInfo?.lastName[0]}
            </Button>

            {isOpen && (
                <div className="Menu">
                    <div className="Triangle" />
                    <div className="Top">
                        {profileLink && (
                            <Link to={`${basePath}/${profileLink.childElements[0].path}`}>
                                <p className="Name">
                                    {userInfo?.firstName} {userInfo?.lastName}
                                </p>
                                <p>{profileLink.displayName}</p>
                            </Link>
                        )}
                    </div>
                    {menuLinks?.map((parentLink, i) =>
                        menuLinksForWhichToShowChildren.includes(parentLink.path) ? (
                            parentLink.childElements ? (
                                <div key={i} className="Middle">
                                    {parentLink.childElements.map((link) => {
                                        const path = `${basePath}/${link.path}`;
                                        const linkClass = `${isLinkActive(path) && 'Active'}`;
                                        return (
                                            <div className="DropdownOption" key={link.id}>
                                                <Link to={path} className={linkClass}>
                                                    <p>{link.displayName}</p>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null
                        ) : (
                            <div key={i} className="Middle">
                                <div className="DropdownOption" key={parentLink.id}>
                                    <Link
                                        to={`${basePath}/${parentLink.path}`}
                                        className={`${
                                            isLinkActive(`${basePath}/${parentLink.path}`) &&
                                            'Active'
                                        }`}
                                    >
                                        <p>{parentLink.displayName}</p>
                                    </Link>
                                </div>
                            </div>
                        )
                    )}
                    {/* <div className="Middle">
                        {otherDropdownLinks &&
                            otherDropdownLinks.map((link) => {
                                const path = `${basePath}/${link.path}`;
                                const linkClass = `${isLinkActive(path) && 'Active'}`;
                                return (
                                    <div className="DropdownOption" key={link.id}>
                                        <Link to={path} className={linkClass}>
                                            <p>{link.displayName}</p>
                                        </Link>
                                    </div>
                                );
                            })}
                    </div> */}
                    <div className="DropdownOption SignOut" onClick={handleSignOut}>
                        <p>Sign out</p>
                    </div>
                </div>
            )}
        </div>
    );
};
