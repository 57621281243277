import { useTable } from 'api';
import { PortfolioTableRow } from 'components/portfolioTable/PortfolioTable';
import { TradeTableRow } from 'components/tradeTable/TradeTable';
import {
    CryptoContext,
    FiatCurrency,
    generateCryptoLiveData,
    parseLowHighString,
} from 'pages/crypto/CryptoPage';
import { useContext, useEffect } from 'react';

type CryptoTableMetadata = {
    AvailablePairingAssets: FiatCurrency[];
};

export const generateCryptoPairString = (crypto: string, fiat: string) => `${crypto}/${fiat}`;
export const splitCryptoPairString = (pair: string) => {
    const splitPair = pair.split('/');
    return { crypto: splitPair[0], fiat: splitPair[1] };
};

// https://stackoverflow.com/a/62620115
const DUMMY_CREATE_TRADE_TABLE = () => useTable<TradeTableRow, null>({ url: '' });
const DUMMY_CREATE_PORFOLIO_TABLE = () => useTable<PortfolioTableRow, null>({ url: '' });
type TradeTable = ReturnType<typeof DUMMY_CREATE_TRADE_TABLE>;
type PortfolioTable = ReturnType<typeof DUMMY_CREATE_PORFOLIO_TABLE>;

export const useCryptoContextEffects = (cryptosTable: TradeTable | PortfolioTable) => {
    // Push metadata from list endpoint up to context.
    const {
        cryptos,
        setCryptos,
        assetPairs,
        setAssetPairs,
        setFiatCurrencies,
        setSelectedFiatCurrency,
        selectedFiatCurrency,
        setIsPageLoading,
        isPageLoading,
    } = useContext(CryptoContext);
    useEffect(() => {
        if (cryptosTable.data?.details.metadata && assetPairs.length === 0) {
            const meta = cryptosTable.data?.details.metadata as CryptoTableMetadata;
            setFiatCurrencies(meta.AvailablePairingAssets);
            setAssetPairs(cryptos.map((crypto) => crypto.pair));
            setSelectedFiatCurrency(
                meta.AvailablePairingAssets.find((currency) => currency.code === 'USD') ?? null
            );
        }
    }, [
        cryptosTable.data?.details.metadata,
        setAssetPairs,
        assetPairs.length,
        setFiatCurrencies,
        setSelectedFiatCurrency,
        cryptos,
    ]);

    // Update cryptos list in context from list endpoint listData.
    useEffect(() => {
        if (cryptosTable.data?.details.listData) {
            // If we already have the pair in context, use the values we have,
            // else take values from list endpoint. Backend caches prices for 60 seconds
            // so the value from backend could be outdated therefore we prefer values already
            // saved on front-end.
            const cryptoList = (cryptosTable.data.details.listData as []).map(
                (crypto: TradeTableRow | PortfolioTableRow) => {
                    const currentlyStoredCrypto = cryptos.find(
                        (storedCrypto) =>
                            storedCrypto.pair ===
                            generateCryptoPairString(crypto.assets__Code, crypto.pairedAssets__Code)
                    );
                    if (currentlyStoredCrypto) {
                        return currentlyStoredCrypto;
                    }
                    const lowHighValues = crypto.market__MarketHighLow
                        ? parseLowHighString(crypto.market__MarketHighLow)
                        : null;

                    const price = isTradeTableRow(crypto)
                        ? crypto.market__BuyPrice
                        : crypto.market__SellPrice;
                    return generateCryptoLiveData(
                        crypto.assets__Name,
                        crypto.assets__Code,
                        crypto.pairedAssets__Code,
                        price,
                        lowHighValues?.low ? lowHighValues.low : undefined,
                        lowHighValues?.high ? lowHighValues.high : undefined
                    );
                }
            );
            setCryptos(cryptoList);
        }
        // TODO(HC): Include cryptos in dependencies without rerunning effect.
    }, [cryptosTable.data?.details.listData, setCryptos]);

    useEffect(() => {
        if (isPageLoading && !cryptosTable.loading) {
            setIsPageLoading(false);
        }
    }, [cryptosTable.loading, cryptosTable.data, setIsPageLoading, isPageLoading]);

    // Apply filter when selected currency changes.
    useEffect(() => {
        if (selectedFiatCurrency) {
            const columnKey: keyof TradeTableRow | PortfolioTableRow = 'pairedAssets__Code';
            cryptosTable.data?.actions.setFilters([
                {
                    columnKey: columnKey,
                    operator: 'EQ',
                    value: selectedFiatCurrency?.code,
                },
            ]);
        }
    }, [selectedFiatCurrency, cryptosTable.data?.actions]);
};

const isTradeTableRow = (x: TradeTableRow | PortfolioTableRow): x is TradeTableRow => {
    if ((x as TradeTableRow).market__BuyPrice !== undefined) return true;
    else return false;
};
