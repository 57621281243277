import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';

type Props = {
    message?: string;
};

export const GeneralWarning: React.FC<Props> = ({ message = 'Are you sure?' }) => {
    return (
        <div className="GeneralWarning">
            <WarningIcon width={25} />
            <p>{message}</p>
        </div>
    );
};
