import { PaymentFlowType } from 'pages/quickCoin/QuickCoinPage';
import instance, { ApiResponse, isAxiosErrorHandled } from '../api';
import { endpoints, isProduction } from '../endpoints.config';
import logger from './logger';
import { PaymentInfo } from 'pages/quickCoin/CardPaymentForm';

type ApplePaySessionConstructor = typeof ApplePaySession;
type ApplePayButton = {
    locale: string;
    type: string;
    buttonstyle: string;
} & HTMLElement;

const scriptId = 'applepayscript';

if (!document.getElementById(scriptId)) {
    const script = document.createElement('script');
    script.id = scriptId;
    script.onload = () => {
        // ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier).then(
        //   (canMakePayment) => {
        //     logger.log(
        //       `Can make payments response: ${canMakePayment ? "True" : "False"}`
        //     );
        //     canMakePayments = canMakePayment;
        //     canMakePayment && createButton();
        //     buttonReadyCallback && buttonReadyCallback(canMakePayment);
        //   }
        // );
    };

    document.head.appendChild(script);
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
}

export type ApplePayValues = {
    totalCost: string;
    currencyCode: string;
};
export const applePayHelper = (function () {
    const ApplePaySession: ApplePaySessionConstructor | undefined = (window as any).ApplePaySession;
    const merchantIdentifier = isProduction ? 'merchant.ibanera.com' : 'merchant.com.ibanera.portl';

    let canMakePayments: boolean;
    let session: ApplePaySession | null;
    let _values: ApplePayValues = {
        totalCost: '123.00',
        currencyCode: 'USD',
    };
    const supportedVersion = 3; // lowest supported version of applepay on user's device

    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
        script.id = scriptId;
        document.head.appendChild(script);
    }

    const generateRequest = (values: ApplePayValues): ApplePayJS.ApplePayPaymentRequest => {
        return {
            countryCode: 'US',
            currencyCode: values.currencyCode,
            supportedNetworks: ['visa', 'masterCard', 'amex'], // Only use the cards listed here
            merchantCapabilities: ['supports3DS'],
            total: { label: 'Total Cost', amount: values.totalCost },
        };
    };

    let button: ApplePayButton;
    let buttonReadyCallback: (canMakePayment: boolean) => void = () => {};

    const getButtonWhenReady = new Promise<ApplePayButton>((resolve, reject) => {
        buttonReadyCallback = (canShowButton: boolean) => {
            if (canShowButton) resolve(button);
            else reject("Can't use button");
        };
    });

    const createButton = () => {
        button = document.createElement('apple-pay-button') as ApplePayButton;
        button.locale = 'en-US';
        button.type = 'buy';
        button.buttonstyle = 'black';
        button.onclick = beginPayment;
    };

    const beginPayment = () => {
        if (!session && canMakePayments && ApplePaySession) {
            session = new ApplePaySession(supportedVersion, generateRequest(_values));

            session.onvalidatemerchant = (validateMerchantEvent) => {
                instance
                    .post(endpoints.quickCryptoModule.applepayValidate, {
                        validationUrl: validateMerchantEvent.validationURL,
                    })
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            session?.completeMerchantValidation(res.data);
                        }
                    });
            };
            session.onpaymentauthorized = (event) => {
                const paymentData = event.payment.token.paymentData;
                instance
                    .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.submitApplePay, {
                        applePayEphemeralPublicKey: paymentData.header.ephemeralPublicKey,
                        applePayPublicKeyHash: paymentData.header.publicKeyHash,
                        applePayTransactionId: paymentData.header.transactionId,
                        applePaySignature: paymentData.signature,
                        applePayVersion: paymentData.version,
                        applePayData: paymentData.data,
                        currencyCode: _values.currencyCode,
                        currencyAmount: _values.totalCost,
                        paymentFlow: PaymentFlowType.Form,
                        deviceSessionId: (window as any)?.sessionUUID,
                        chargeCardIdempotencyKey: idempotencyKey,
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            session?.completePayment({
                                status: ApplePaySession.STATUS_SUCCESS,
                            });
                            onComplete(res.data.details);
                        } else throw new Error('Submit response did not indicate success');
                    })
                    .catch((err) => {
                        session?.completePayment({
                            status: ApplePaySession.STATUS_FAILURE,
                        });
                        if (
                            isAxiosErrorHandled(err) &&
                            err.response.data.errors.some(
                                (error) =>
                                    error.fieldName === 'Create_Payment' &&
                                    error.messageCode === 'Failed'
                            )
                        ) {
                            onComplete({
                                paymentReference: '',
                                status: 'Failed',
                                redirectUrl: '',
                            });
                        }
                    })
                    .finally(() => {
                        // session = null;
                    });
            };
            session.oncancel = () => {
                logger.log('Session cancelled');
                session = null;
            };

            session.begin();
        }
    };

    const setValues = (values: ApplePayValues) => {
        _values = { ...values };
    };
    let idempotencyKey = '';
    const setIdempotencyKey = (value: string) => {
        idempotencyKey = value;
    };
    let onComplete = (x: PaymentInfo) => {};
    const updateOnComplete = (newOnComplete: (x: PaymentInfo) => void) => {
        onComplete = newOnComplete;
    };

    if (ApplePaySession) {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.onload = () => {};

            document.head.appendChild(script);
            script.src = 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js';
        }

        try {
            if (ApplePaySession.canMakePayments()) {
                canMakePayments = true;
                createButton();
                buttonReadyCallback && buttonReadyCallback(true);
            }
        } catch (err) {
            logger.log('[DEBUG] ApplePaySession.canMakePayments err', err);
        }
    }

    return {
        setValues,
        getButtonWhenReady,
        beginPayment,
        setIdempotencyKey,
        updateOnComplete,
    };
})();
