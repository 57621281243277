import { useField } from 'formik';
import { BeneficialBusinessOwner, OwnerTreeStructure } from '../../../schema';
import { BUSINESS_OWNERS_FORM_STRING } from '../OwnersTree';
import Button from '../../../../button/Button';
import FormTextField from '../../../../form/FormTextField';
import { useState } from 'react';
import { FormSingleSelectField } from '../../../../form/FormSingleSelectField';
import { beneficialOwnersStructureSchema } from '../../../validation';

type NewBusinessFormProps = {
    hideLeftBar: boolean;
    fieldname: string;
    parentFieldname: string;
    ownerGuid: string;
    handleDeleteOwner: () => void;
};
export const NewBusinessForm: React.FC<NewBusinessFormProps> = ({
    ownerGuid,
    fieldname,
    parentFieldname,
    hideLeftBar,
    handleDeleteOwner,
}) => {
    const [{ value: parentValue }] = useField<OwnerTreeStructure | OwnerTreeStructure[]>(
        parentFieldname
    );
    const [{ value }, , { setValue: setBusinesses }] = useField<BeneficialBusinessOwner[] | null>(
        BUSINESS_OWNERS_FORM_STRING
    );
    const [, , { setValue: setTreeIsNewValue }] = useField<boolean | undefined>(
        `${fieldname}.isNewNode`
    );
    const [{ value: ownerStartingGuid }] = useField<string>(`${fieldname}.ownersGuid`);
    const [{ value: treeValue }, , { setTouched }] = useField<OwnerTreeStructure>(fieldname);

    const handleSetNewItemToFalse = () => {
        setTreeIsNewValue(false);
        /* We always add new entires to the start of the array but this element
           Will have been marked as touched when we add something new we should mark it 
           this index as not touched when we're done adding to avoid showing errors
           on the inputs of a new element */
        setTouched(false);
    };
    const [originalGuid] = useState(ownerStartingGuid);
    const removeOriginalOwner = () => {
        // Remove originally added owner
        // Guid may have changed from dropdown comp hence storing originalGuid
        setBusinesses((value ?? []).filter((item) => item.guid !== originalGuid));
    };
    const handleAddExistingOwner = () => {
        removeOriginalOwner();
        handleSetNewItemToFalse();
    };
    const handleDeleteOwnerExisiting = () => {
        removeOriginalOwner();
        handleDeleteOwner();
    };

    const parentGuid = Array.isArray(parentValue) ? '' : parentValue.ownersGuid;
    const siblingGuids = (Array.isArray(parentValue) ? parentValue : parentValue.children ?? [])
        .filter((o) => o.bIsBusiness && o.ownersGuid !== parentGuid)
        .map((o) => o.ownersGuid)
        .concat(parentGuid);
    const options = (value ?? [])
        // Filter out existing siblings to not allwow repeats in the same branch level
        .filter((o) => !siblingGuids.includes(o.guid))
        .map((opt, i) => ({
            label: opt.entityName ?? `Business ${i}`,
            value: opt.guid,
        }));
    const hasExistingOwners = options.length > 0;
    const [ownerMethod, setOwnerMethod] = useState<'Existing' | 'New' | null>(
        !hasExistingOwners ? 'New' : null
    );

    const isOwnerStructureValid =
        beneficialOwnersStructureSchema.isValidSync(treeValue) &&
        (ownerMethod === 'Existing' ? treeValue.ownersGuid !== originalGuid : true);

    const selectedOwnerIndex = (value ?? []).findIndex((o) => o.guid === ownerGuid)!;

    return (
        <div className="NewBusinessForm">
            {hideLeftBar && <div aria-hidden="true" className="FloatingHide Full" />}
            {!ownerMethod && (
                <div className="NewIndividualFormRow">
                    <Button
                        onClick={() => setOwnerMethod('New')}
                        type="button"
                        priority="secondary"
                    >
                        Add new business
                    </Button>
                    <Button
                        onClick={() => setOwnerMethod('Existing')}
                        type="button"
                        priority="secondary"
                    >
                        Add existing business
                    </Button>
                </div>
            )}
            {ownerMethod === 'New' && (
                <>
                    <div className="NewBusinessFormRow">
                        <FormTextField
                            label="Legal Name of Entity"
                            field={`${BUSINESS_OWNERS_FORM_STRING}[${selectedOwnerIndex}].entityName`}
                        />
                        <FormTextField
                            type="number"
                            label="Percentage of Shares Owned (%)"
                            field={`${fieldname}.percentageOwned`}
                        />
                    </div>
                    <div className="NewBusinessFormRow">
                        <Button onClick={handleDeleteOwner} type="button" priority="secondary">
                            Cancel
                        </Button>
                        <Button
                            disabled={!isOwnerStructureValid}
                            onClick={handleSetNewItemToFalse}
                            type="button"
                        >
                            Add
                        </Button>
                    </div>
                </>
            )}
            {ownerMethod === 'Existing' && (
                <>
                    <FormSingleSelectField<any>
                        options={options}
                        label="Choose from existing businesses"
                        fieldName={`${fieldname}.ownersGuid`}
                    />
                    <FormTextField
                        type="number"
                        label="Percentage of Shares Owned (%)"
                        field={`${fieldname}.percentageOwned`}
                    />
                    <div className="NewIndividualFormRow AddSave">
                        <Button
                            onClick={handleDeleteOwnerExisiting}
                            type="button"
                            priority="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isOwnerStructureValid}
                            onClick={handleAddExistingOwner}
                            type="button"
                        >
                            Add
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
