import React, { lazy } from 'react';
import { RouteComponentProps } from '@reach/router';
import { endpoints } from '../../endpoints.config';
import Category from '../../components/Category';
import { CrudItems } from 'components/Category/CrudMenu';
import { localizedFormatDate } from '../../helpers/categoryHelpers/userLocale';

// Icons
import View from '../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../assets/Cat-Entity/Icon_Action_02.png';

const Approval = lazy(() => import('./ManageApproval'));

// Define the url for the table's data endpoint.
const url = endpoints.approvals.list;

// Define which column on the table represents each row's ID.
const idColumn: keyof ApprovalsListData = 'approvals__Id';

//Define the data structure for table
export type ApprovalsListData = {
    approvals__Id: number;
    approvals__Code: string;
    approvals__Type: string;
    approvals__Description: string;
    approvals__AddedBy: string;
    approvals__AddDate: string;
    approvals__ApprovalsRequired: number;
    approvals__ApprovalsReceived: number;
};

const crudItems: CrudItems<ApprovalsListData> = [
    {
        icon: View,
        title: 'View Approval',
        imgAltText: 'View',
        menuCode: 'VIEW',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <Approval
                onDone={onDone}
                selectedData={selectedData}
                idFromRowOption={menu.selectedId}
                actionType="view"
                submitButtonText="Close"
                noSubmit={true}
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: Update,
        imgAltText: 'Update',
        title: 'Approve / Reject',
        menuCode: 'UPDATE',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <Approval
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="edit"
                submitButtonText="Update"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: ApprovalsListData) => {
    let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };
    if (formattedRow.approvals__AddDate) {
        formattedRow = {
            ...formattedRow,
            approvals__AddDate: localizedFormatDate(new Date(row.approvals__AddDate)),
        };
    }
    if (row.approvals__ApprovalsReceived != null && row.approvals__ApprovalsRequired != null) {
        formattedRow.approvals__ApprovalsReceived = `${row.approvals__ApprovalsReceived}/${row.approvals__ApprovalsRequired}`;
    }
    return formattedRow;
};

type Props = RouteComponentProps;

function ManageApprovals(props: Props) {
    return (
        <Category<ApprovalsListData>
            location={props.location}
            contentTitle="Approvals"
            noun="Approvals"
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            rowCellOptions={{
                approvals__Description: {
                    width: 'Large',
                    style: { flex: 4 },
                },
            }}
            noAddBtn
            classname="listsCatTable"
            emptyTableLabel="No approvals found"
        />
    );
}

export default ManageApprovals;
