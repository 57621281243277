import { Modal } from 'components/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import { BusinessVerification } from './BusinessVerification';
import { BusinessVerificationV2 } from '../businessVerificationV2/BusinessVerificationV2';
import { selectUserInfo } from 'reducers/componentResources';
import { SwitchAccountDropdown } from 'components/switchAccountDropdown/SwitchAccountDropdown';
import { BusinessVerificationFlow, selectAppSettings } from 'reducers/appSettings';
import { BusinessVerificationV3 } from '../businessVerificationV3/BusinessVerificationV3';
import { selectIsBusinessRejected } from '../../reducers/verification';
import WarningIcon from 'assets/Icon_Warning.png';

type Props = {};

export const BusinessVerificationModal = (props: Props) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);
    const appSettings = useSelector(selectAppSettings);
    const businessIsRejected = useSelector(selectIsBusinessRejected);
    return (
        <Modal
            title="Entity Verification"
            customCloseButton={
                <div className="RightHeader">
                    {userInfo?.bAccountSwitchAvailable && <SwitchAccountDropdown />}
                    <span className="SignOutLink" onClick={() => dispatch(signOut())}>
                        Sign out
                    </span>
                </div>
            }
            className={`BusinessVerificationModal ${businessIsRejected ? 'Rejected' : ''}`}
            backgroundClassName={`BusinessVerificationModalBg ${
                businessIsRejected ? 'Rejected' : ''
            }`}
        >
            {!businessIsRejected ? (
                appSettings.businessVerificationFlow === BusinessVerificationFlow.v1 ? (
                    <BusinessVerification />
                ) : appSettings.businessVerificationFlow === BusinessVerificationFlow.v2 ? (
                    <BusinessVerificationV2 />
                ) : (
                    <BusinessVerificationV3 />
                )
            ) : (
                <BusinessRejected />
            )}
        </Modal>
    );
};

const BusinessRejected = () => {
    return (
        <div className="VerificationError">
            <img src={WarningIcon} alt="error" />
            <h3>Verification attempt rejected</h3>
            <p>We couldn't verify your account, please contact support.</p>
        </div>
    );
};
