import { ReactComponent as BusinessIcon } from 'assets/ui-update/business.svg';
import { ReactComponent as IndividualIcon } from 'assets/ui-update/individual.svg';
import { OwnerTreeStructure } from '../../../schema';
import { NewIndividualForm } from './NewIndividualForm';
import { NewBusinessForm } from './NewBusinessForm';

type Props = {
    owner: OwnerTreeStructure;
    fieldname: string;
    parentFieldname: string;
    hideLeftBar?: boolean;
    handleDeleteOwner: () => void;
};

export const SingleNewItem: React.FC<Props> = ({
    owner,
    fieldname,
    parentFieldname,
    hideLeftBar = false,
    handleDeleteOwner,
}) => {
    return (
        <div className="SingleTreeItemWrapper">
            <div className="SingleTreeItem SingleNewItem">
                {hideLeftBar && <div aria-hidden="true" className="FloatingHide" />}
                <div aria-hidden="true" className="FloatingCircle" />
                {owner.bIsBusiness ? <BusinessIcon /> : <IndividualIcon />}
                <h4>
                    {owner.bIsBusiness
                        ? 'Business Beneficial Owner'
                        : 'Individual Beneficial Owner'}
                </h4>
            </div>
            {owner.bIsBusiness ? (
                <NewBusinessForm
                    hideLeftBar={hideLeftBar}
                    ownerGuid={owner.ownersGuid}
                    fieldname={fieldname}
                    parentFieldname={parentFieldname}
                    handleDeleteOwner={handleDeleteOwner}
                />
            ) : (
                <NewIndividualForm
                    hideLeftBar={hideLeftBar}
                    ownerGuid={owner.ownersGuid}
                    fieldname={fieldname}
                    parentFieldname={parentFieldname}
                    handleDeleteOwner={handleDeleteOwner}
                />
            )}
        </div>
    );
};
