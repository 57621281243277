import Button from 'components/button/Button';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';

export const BusinessVerificationComplete = () => {
    const dispatch = useDispatch();
    return (
        <div className="Submit">
            <>
                <h1>Thank you</h1>
                <p>
                    Thank you. You have now completed your verfication. You will receive a
                    notification once the application has been reviewed.
                </p>
                <Button
                    priority="primary"
                    variety="full"
                    type="button"
                    onClick={() => dispatch(signOut())}
                >
                    Sign out
                </Button>
            </>
        </div>
    );
};
