import Button from 'components/button/Button';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { ExchangeDetails } from 'pages/cryptoV2/models';
import { useGetAccountById } from 'helpers/useGetAccountDetails';
import SuccessImg from 'assets/icon-successful.svg';
import { ReviewedDetails, ExecuteExchangeDetails } from '../CryptoExchangeModal';
import { formatGeneralCurrency } from './FromTo';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../reducers/modal';

export const OrderComplete = ({
    orderDetails,
    exchangeDetails,
    executedDetails,
    navigate,
}: {
    orderDetails: ReviewedDetails;
    exchangeDetails: ExchangeDetails;
    executedDetails: ExecuteExchangeDetails;
    navigate: (path: string) => void;
}) => {
    const sourceAccount = useGetAccountById(executedDetails.sourceAccountId);
    const destinationAccount = useGetAccountById(executedDetails.destinationAccountId);
    const sourceAccountPath = sourceAccount?.childElements[0].path;
    const destinationAccountPath = destinationAccount?.childElements?.[0]?.path;

    const isBuyExchange = orderDetails.exchangeType === 'Buy';

    const dispatch = useDispatch();
    const handleClose = () => dispatch(closeModal());

    return (
        <div>
            <div className="OrderCompleteReference">
                <img className="OrderCompleteImage" src={SuccessImg} alt="Success"></img>
                <p>
                    Reference Number: <strong>{executedDetails.reference}</strong>
                </p>
            </div>
            <div className="BuySellAmounts">
                <div>
                    <h4>You bought</h4>
                    <h3>
                        {formatGeneralCurrency(
                            executedDetails.destinationAmount,
                            exchangeDetails.destinationAsset
                        )}
                    </h3>
                </div>
                <div>
                    <h4>You sold</h4>
                    <h3>
                        {formatGeneralCurrency(
                            executedDetails.sourceAmount,
                            exchangeDetails.sourceAsset
                        )}
                    </h3>
                </div>
            </div>
            <div className="ExchangeRate ExchangeRateConfirm">
                <div>Quoted Price:</div>
                <div className="Rates">
                    {((isBuyExchange && executedDetails.buyPrice) ||
                        (!isBuyExchange && executedDetails.sellPrice)) && (
                        <p>
                            {exchangeDetails.asset} 1 <span className="c1">{'>'}</span>
                            <span className="c2">{'>'}</span>
                            <span className="c3">{'>'}</span> {exchangeDetails.pairedAsset} ~{' '}
                            {roundToPrecision(
                                isBuyExchange
                                    ? 1 / executedDetails.buyPrice
                                    : 1 / executedDetails.sellPrice,
                                8
                            )}
                        </p>
                    )}
                    {((!isBuyExchange && executedDetails.sellPrice) ||
                        (isBuyExchange && executedDetails.buyPrice)) && (
                        <p>
                            {exchangeDetails.pairedAsset} 1 <span className="c1">{'>'}</span>
                            <span className="c2">{'>'}</span>
                            <span className="c3">{'>'}</span> {exchangeDetails.asset} ~{' '}
                            {roundToPrecision(
                                !isBuyExchange
                                    ? executedDetails.sellPrice
                                    : executedDetails.buyPrice,
                                8
                            )}
                        </p>
                    )}
                </div>
            </div>
            <div className="SummaryRow">
                <div className="RowInfo">
                    <p>From:</p>
                    <h4>{executedDetails.sourceAccountName}</h4>
                </div>
                <div className="RowInfo">
                    <p>To:</p>
                    <h4>{executedDetails.destinationAccountName}</h4>
                </div>
            </div>
            <div className="OrderCompleteButtons">
                {destinationAccountPath && (
                    <Button
                        onClick={() => {
                            navigate('/' + destinationAccountPath);
                            handleClose();
                        }}
                    >
                        Go to {executedDetails.destinationAccountName}
                    </Button>
                )}
                {sourceAccountPath && (
                    <Button
                        onClick={() => {
                            navigate('/' + sourceAccountPath);
                            handleClose();
                        }}
                    >
                        Go to {executedDetails.sourceAccountName}
                    </Button>
                )}
                {!sourceAccountPath && !destinationAccountPath && (
                    <Button onClick={handleClose}>Close</Button>
                )}
            </div>
        </div>
    );
};
