import { css, useTheme } from '@emotion/react';

export const useCreateThemeStyle = () => {
    const theme = useTheme();

    const styles =
        theme &&
        css`
            h2 {
                color: ${theme.colors.second};
            }

            h4 {
                color: ${theme.colors.third};
            }
        `;

    return styles;
};
