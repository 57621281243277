import { RouteComponentProps, useNavigate } from '@reach/router';
import { AppPath } from 'appConstants';
import { JumioVerification } from 'components/jumio/JumioVerification';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectisVerified } from 'reducers/verification';
import Page from '../../components/page/Page';
import { BusinessVerificationModal } from 'components/businessVerification/BusinessVerificationModal';

const Dashboard: React.FC<RouteComponentProps> = ({ children, path }) => {
    const isVerified = useSelector(selectisVerified);
    const { userInfo } = useSelector(selectComponentResources);
    const navigate = useNavigate();
    useEffect(() => {
        if (!userInfo?.accountType) return;
        if (path?.endsWith(AppPath.PERSONAL) && userInfo.accountType !== 'Personal') {
            navigate('/');
        }
        if (path?.endsWith(AppPath.BUSINESS) && userInfo.accountType !== 'Business') {
            navigate('/');
        }
    }, [path, userInfo?.accountType, navigate]);

    return (
        <Page isPublic={false} className="Dashboard">
            {isVerified ? (
                children
            ) : userInfo?.accountType === 'Personal' ? (
                <JumioVerification />
            ) : (
                <BusinessVerificationModal />
            )}
        </Page>
    );
};

export default Dashboard;
