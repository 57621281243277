/* eslint-disable react-hooks/exhaustive-deps */
import { SelectOption, WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import instance from '../../../../api';
import { endpoints } from '../../../../endpoints.config';
import { Toast, ToastMessageReason } from '../../../../helpers/toast';
import { useAfterMountEffect } from '../../../../helpers/useAfterMountEffect';
import { selectVerificationDetailsV3 } from '../../../../reducers/verification';
import Button from '../../../button/Button';
import { FormDateField } from '../../../form/FormDateField';
import { FormPhoneNumberField } from '../../../form/FormPhoneNumberField';
import { RadioButtons } from '../../../radiobuttons/radiobuttons';
import { FormValues } from '../../schema';
import { formatValues } from '../helpers';
import UploadField from '../UploadField';

export type IndividualOwnerProps = {
    fieldName: string;
    treeFieldname: string;
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    onClose: () => void;
};

export const IndividualOwner: React.FC<IndividualOwnerProps> = ({
    fieldName,
    treeFieldname,
    availableCountries,
    onClose,
}) => {
    const { rejectedDocuments } = useSelector(selectVerificationDetailsV3); //additionalData to have the stored form data for current stage?

    const { values, setFieldValue, setFieldError } = useFormikContext<FormValues>();
    const [{ value: countryValue }] = useField(`${fieldName}.country`);
    const [{ value }, , { setValue }] = useField(fieldName);
    const [{ value: treeValue }, , { setValue: setTreeValue }] = useField(treeFieldname);

    // Store initial state in case they want to close the form rather than save
    const [initialValues] = useState({ individual: value, treeValue });

    const handleCloseWithoutSave = () => {
        setValue(initialValues.individual);
        setTreeValue(initialValues.treeValue);
        onClose();
    };

    useEffect(() => {
        if (
            rejectedDocuments &&
            rejectedDocuments.hasOwnProperty('proofOfAddress') &&
            rejectedDocuments.proofOfAddress &&
            rejectedDocuments.proofOfAddress.length > 0
        ) {
            if (rejectedDocuments.proofOfAddress?.includes(value.proofOfAddressFilename)) {
                setFieldError(
                    `${fieldName}.proofOfAddressFilename`,
                    `File named ${value.proofOfAddressFilename} has been rejected, please upload another file`
                );
            }
        }
    }, []);

    const saveForm = async () => {
        try {
            await instance.post(endpoints.businessVerificationV3Module.saveFormData, {
                ...formatValues(values),
                businessVerificationStep: values.businessVerificationStep,
            });
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };

    const currentCountry = availableCountries.find((country) => country.value === countryValue);
    const stateOptions = currentCountry ? currentCountry.states : null;

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.stateProvince`, '');
    }, [countryValue]);

    return (
        <div className="Form">
            <h1>Add Beneficial Owner Information</h1>
            <div className="Row">
                <FormTextField
                    label="First name"
                    field={`${fieldName}.firstName`}
                    maxLength={200}
                />
                <FormTextField label="Last name" field={`${fieldName}.lastName`} maxLength={200} />
            </div>
            <div className="Row">
                <FormTextField label="Title" field={`${fieldName}.title`} maxLength={200} />
                <FormDateField
                    field={`${fieldName}.dateOfBirth`}
                    label="Date of Birth"
                    error={true}
                    datePickerProps={{
                        maxDate: new Date(),
                    }}
                />
            </div>
            <div className="Row">
                <WrappedFormSingleSelectField
                    fieldName={`${fieldName}.nationality`}
                    options={availableCountries}
                    label="Nationality"
                />
                <FormTextField label="Email" field={`${fieldName}.email`} maxLength={200} />
            </div>
            <div className="Row">
                <FormPhoneNumberField
                    field={`${fieldName}.phoneNumber`}
                    label="Phone Number"
                    sideBySide={true}
                />
                <WrappedFormSingleSelectField
                    fieldName={`${fieldName}.country`}
                    options={availableCountries}
                    label="Country"
                />
            </div>
            <div className="Row">
                <FormTextField
                    field={`${fieldName}.ssn`}
                    label={'Tax ID number / SSN'}
                    maxLength={200}
                />
                {stateOptions && stateOptions.length > 0 ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName={`${fieldName}.stateProvince`}
                            label={'State / Province*'}
                            options={stateOptions}
                        />
                    </div>
                ) : (
                    <FormTextField
                        field={`${fieldName}.stateProvince`}
                        label={'State / Province'}
                        maxLength={200}
                    />
                )}
            </div>
            <div className="Row">
                <FormTextField field={`${fieldName}.city`} label={'City'} maxLength={200} />
                <FormTextField
                    field={`${fieldName}.addressLine1`}
                    label={'Street Address'}
                    maxLength={200}
                />
            </div>
            <div className="Row">
                <FormTextField
                    field={`${fieldName}.addressLine2`}
                    label={'Street Address 2'}
                    maxLength={200}
                />
                <FormTextField
                    field={`${fieldName}.postcode`}
                    label={'Postal / Zip Code'}
                    maxLength={200}
                />
            </div>
            <div className="Row">
                <FormTextField
                    field={`${treeFieldname}.percentageOwned`}
                    label={'Percentage of Shares Owned (%)'}
                    type="number"
                    maxLength={200}
                />
                <FormTextField
                    field={`${treeFieldname}.positionAtCompany`}
                    label={'Position at the Company'}
                    maxLength={200}
                />
            </div>
            <RadioButtons
                options={['Yes', 'No']}
                label={'Controlling Party*'}
                booleanField={true}
                fieldname={`${treeFieldname}.bControllingParty`}
                error={true}
            />
            <div className="FormBox">
                <div className="FormLabel">
                    <label>
                        Proof of Address
                        <span>*</span>
                    </label>
                </div>
                <UploadField
                    fieldName={`${fieldName}.proofOfAddressFilename`}
                    displayError={true}
                    jsonField={true}
                    onChange={saveForm}
                />
            </div>
            <div className="IndividualOwnerButtons">
                <Button onClick={handleCloseWithoutSave} type="button" priority="secondary">
                    Close
                </Button>
                <Button onClick={onClose} type="button">
                    Save
                </Button>
            </div>
        </div>
    );
};
