/** @jsxImportSource @emotion/react */
import { TFAType } from 'pages/register/models';
import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { useTheme } from '@emotion/react';
import css from '@emotion/css';

export const TfaToggle = ({
    value,
    setValue,
    disabled,
    setInitialValue,
}: {
    value: boolean;
    setValue: (v: boolean) => void;
    disabled?: boolean;
    setInitialValue: (v: boolean) => void;
}) => {
    const debouncedToggle = useCallback(
        debounce((value: boolean) => {
            setInitialValue(value);
        }, 5000),
        [setInitialValue]
    );

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        setValue(e.target.checked);
        debouncedToggle(e.target.checked);
    };
    const { colors } = useTheme();

    return (
        <div
            className={`Toggle ${disabled ? 'sliderDisabled' : ''}`}
            css={css(`
            &.sliderDisabled .slider {
                opacity:80%;
                cursor:default;
            }
        input:checked + .slider::before{
            background-color: ${colors.first} !important;
        }
        input:checked + .slider{
            border-color: ${colors.first} !important;
        }
        `)}
        >
            <label className="switch">
                <input
                    type="checkbox"
                    onChange={handleToggle}
                    checked={value}
                    disabled={disabled}
                />
                <span className="slider round "></span>
            </label>
        </div>
    );
};
