import { ApiResponse, TableInfo, useFetch, useTable } from 'api';
import { AppPath, DashboardPath } from 'appConstants';
import { FlexTable } from 'components/flexTable';
import Pagination from 'components/pagination/Pagination';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { plaintext } from 'plaintext.config';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import ReactTooltip from 'react-tooltip';

import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { ModalTypes, openModal } from 'reducers/modal';
import Button from 'components/button/Button';
import { EmptyCell } from 'components/flexTable/CustomCells';
import { groupBy } from 'lodash';

import RedX from 'assets/ibanera/Icon_RedCross.png';
import GreenTick from 'assets/ibanera/Icon_GreenTick.png';
import Checking from 'assets/ibanera/Icon_Checking.png';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { UploadDocumentFlowType } from 'components/modals/UploadDocumentModal';

type Props = {};

type Data = {
    documentId: number;
    documentStatus: number;
    documentStatusString: string;
    name: string;
    orderNumber: number;
    documentNotes?: string;
    uploadNotes: string;
    bAlwaysRequired: boolean;
    rejectionReason: string | null;
};
const idColumn: keyof Data = 'documentId';

const rowFormatter = (row: Data) => {
    let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };
    if (formattedRow.documentStatusString) {
        formattedRow = {
            ...formattedRow,
            documentStatusString: <b>{formattedRow.documentStatusString}</b>,
        };
    }

    return formattedRow;
};

const statusesToUploadFrom: Data['documentStatusString'][] = [
    'Not Submitted',
    'Expired',
    'Rejected',
    'Checking', //added this so that users can reupload
];

const statusImages = {
    'Not Submitted': 'NotSubmitted',
    Checking: 'Checking',
    Approved: 'Approved',
    Expired: 'Invalid',
    Rejected: 'Invalid',
    undefined: 'NotSumbited',
};

const statusesInOrder: Data['documentStatusString'][] = [
    'Not Submitted',
    'Rejected',
    'Expired',
    'Checking',
    'Approved',
];

function ManageBusinessAccount({}: Props) {
    const cultureCode = useSelector(selectCultureCode);
    useDefaultRoute(`/${cultureCode}${AppPath.BUSINESS}${DashboardPath.MANAGE}/documents`);
    const { reload, data } = useFetch<ApiResponse<Data[]>>(endpoints.businessModule.documentList);
    const uiUpdate = useSelector(selectUIUpdate);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            uiUpdate?.pushType === NotificationIdentifier.RELOAD_BUSINESS_DOCUMENT_LIST ||
            uiUpdate?.pushType === NotificationIdentifier.BUSINESS_DOCUMENT_VERIFIED
        ) {
            reload();
            dispatch(completeUIUpdate());
        }
    }, [uiUpdate, reload, dispatch]);

    useEffect(() => {
        ReactTooltip.rebuild();
    });
    return (
        <div className="ManageBusinessAccount">
            <h1>Business Profile</h1>
            <p>Upload your documents here to activate your business account</p>
            <div className="CardsSection">
                {data?.details.map((document) => (
                    <ManageBusinessDocumentCard
                        cardData={document}
                        key={document.documentId}
                        reload={reload}
                    ></ManageBusinessDocumentCard>
                ))}
            </div>
            <ReactTooltip effect="solid" className="TooltipContent" /* type="dark" */ />
        </div>
    );
}

function ManageBusinessDocumentCard({ cardData, reload }: { cardData: Data; reload: () => void }) {
    const dispatch = useDispatch();
    const handleUploadDocument = (documentId: number, documentName: string) => {
        dispatch(
            openModal({
                modalType: ModalTypes.UPLOAD_BUSINESS_DOCUMENT,
                data: {
                    documentId,
                    documentName,
                    reloadTable: reload,
                    flowType: UploadDocumentFlowType.LinkedCustomer,
                },
            })
        );
    };

    return (
        <div className="DocumentCard">
            <div className="CardTitle">{cardData.name}</div>
            <div className="CardBody">
                <h3 className="CardStatus">
                    {cardData.documentStatusString}
                    {cardData.documentStatusString === 'Rejected' && cardData.rejectionReason && (
                        <button
                            data-tip={cardData.rejectionReason ?? 'No reason given'}
                            className="TooltipTag"
                        >
                            i
                        </button>
                    )}
                </h3>
                <img
                    className="CardImage"
                    src={
                        cardData.documentStatusString === 'Approved'
                            ? GreenTick
                            : cardData.documentStatusString === 'Checking'
                            ? Checking
                            : RedX
                    }
                    alt={cardData.documentStatusString}
                ></img>
                <div className="CardCaption">{cardData.documentNotes}</div>
                {cardData.bAlwaysRequired && (
                    <>
                        <div className="CardSpacer"></div>
                        <div className="CardRequired">Required</div>
                    </>
                )}
            </div>
            <div className="CardFooter">
                <Button
                    disabled={!statusesToUploadFrom.includes(cardData.documentStatusString)}
                    onClick={() => handleUploadDocument(cardData.documentId, cardData.name)}
                >
                    {cardData.documentStatusString === 'Checking' ? 'Replace' : 'Upload'}
                </Button>
            </div>
        </div>
    );
}

export default ManageBusinessAccount;
