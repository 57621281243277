import React, { useContext } from 'react';
import { Crypto } from './Crypto';
import { CryptoContext } from './CryptoPage';
import { Cryptos } from './Cryptos';

export const Trade: React.FC = () => {
    const { selectedCryptoPair } = useContext(CryptoContext);

    return <>{selectedCryptoPair ? <Crypto /> : <Cryptos />}</>;
};
