import * as Yup from 'yup';

export const OTHER_SOURCE_FUND_ID = 999;

export const validationSchema = Yup.object({
    dateOfBirth: Yup.string().required('Required'),
    nationalityId: Yup.string().required('Required'),
    countryId: Yup.string().required('Required'),
    ssn: Yup.string().max(100, 'Maximum 100 characters').required('Required'),
    stateProvince: Yup.string().max(100, 'Maximum 100 characters').required('Required'),
    city: Yup.string().max(200, 'Maximum 200 characters').required('Required'),
    addressLine1: Yup.string().max(200, 'Maximum 200 characters').required('Required'),
    addressLine2: Yup.string(),
    postcode: Yup.string().required('Required'),

    sourceFundsIds: Yup.array(Yup.number().required('Required'))
        .required('Required')
        .min(1, 'Must select at least 1 option'),
    otherSourceOfFunds: Yup.string().when('sourceFundsIds', {
        is: (val: number[]) => val.includes(OTHER_SOURCE_FUND_ID),
        then: Yup.string().required('Required'),
    }),
    estimatedGrossAnnualIncome: Yup.number().typeError('Must be valid number').required('Required'),
    estimatedNetWorth: Yup.number().typeError('Must be valid number').required('Required'),

    otherBanks: Yup.array(Yup.string()),

    bActivelyEmployed: Yup.boolean().required('Required'),
    employedCompanyName: Yup.string().when('bActivelyEmployed', {
        is: true,
        then: Yup.string().required('Required'),
    }),
    employedJobTitle: Yup.string().when('bActivelyEmployed', {
        is: true,
        then: Yup.string().required('Required'),
    }),

    purposeOfOpeningAccountIds: Yup.array(Yup.number().required('Required'))
        .required('Required')
        .min(1, 'Must select at least 1 option'),
    otherPurposeOfOpeningAccount: Yup.string().when('purposeOfOpeningAccountIds', {
        is: (val: number[]) => val.includes(OTHER_SOURCE_FUND_ID),
        then: Yup.string().required('Required'),
    }),
    monthlyTurnover: Yup.number().typeError('Must be valid number').required('Required'),
    anticipatedtransactionactivity: Yup.number()
        .typeError('Must be valid number')
        .required('Required'),

    purchaseCryptoMonthlyVolume: Yup.number()
        .typeError('Must be valid number')
        .required('Required'),
    purchaseCryptoAverageAmount: Yup.number()
        .typeError('Must be valid number')
        .required('Required'),
    purchaseCryptoTotalAmountPerMonth: Yup.number()
        .typeError('Must be valid number')
        .required('Required'),
    sellingCryptoMonthlyVolume: Yup.number().typeError('Must be valid number').required('Required'),
    sellingCryptoAverageAmount: Yup.number().typeError('Must be valid number').required('Required'),
    sellingCryptoTotalAmountPerMonth: Yup.number()
        .typeError('Must be valid number')
        .required('Required'),

    btcTradingPercentage: Yup.number()
        .typeError('Must be a number')
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide coin BTC activity')
        .nullable(),
    ethTradingPercentage: Yup.number()
        .typeError('Must be a number')
        .required('Please provide your monthly volume')
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide coin ETH activity')
        .nullable(),
    usdcTradingPercentage: Yup.number()
        .typeError('Must be a number')
        .required('Please provide your monthly volume')
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide coin USDC activity')
        .nullable(),
    otherCoinsTradingPercentage: Yup.number()
        .typeError('Must be a number')
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide other coin activity')
        .nullable()
        .test(
            'totalTradingPercentage',
            'Total crypto trading percentage must equal 100%',
            (value, testContext) => {
                const btc = testContext.parent.btcTradingPercentage ?? 0;
                const eth = testContext.parent.ethTradingPercentage ?? 0;
                const usdc = testContext.parent.usdcTradingPercentage ?? 0;
                const other = testContext.parent.otherCoinsTradingPercentage ?? 0;
                return btc + eth + usdc + other === 100;
            }
        ),

    irsTax: Yup.boolean().required('Required').oneOf([true], 'Please confirm'),
    foreignAccountTax: Yup.boolean().required('Required').oneOf([true], 'Please confirm'),
    notCriminal: Yup.boolean().required('Required').oneOf([true], 'Please confirm'),
    accurateInfo: Yup.boolean().required('Required').oneOf([true], 'Please confirm'),
});

export type FormState = Yup.InferType<typeof validationSchema>;

export const initialValues: Omit<
    FormState,
    | 'bActivelyEmployed'
    | 'irsTax'
    | 'foreignAccountTax'
    | 'notCriminal'
    | 'accurateInfo'
    | 'estimatedGrossAnnualIncome'
    | 'estimatedNetWorth'
    | 'monthlyTurnover'
    | 'anticipatedtransactionactivity'
    | 'purchaseCryptoMonthlyVolume'
    | 'purchaseCryptoAverageAmount'
    | 'purchaseCryptoTotalAmountPerMonth'
    | 'sellingCryptoAverageAmount'
    | 'sellingCryptoMonthlyVolume'
    | 'sellingCryptoTotalAmountPerMonth'
    | 'btcTradingPercentage'
    | 'ethTradingPercentage'
    | 'usdcTradingPercentage'
    | 'otherCoinsTradingPercentage'
> & {
    bActivelyEmployed?: boolean;
    irsTax?: boolean;
    foreignAccountTax?: boolean;
    notCriminal?: boolean;
    accurateInfo?: boolean;
    estimatedGrossAnnualIncome?: number;
    estimatedNetWorth?: number;
    monthlyTurnover?: number;
    anticipatedtransactionactivity?: number;
    purchaseCryptoMonthlyVolume?: number;
    purchaseCryptoAverageAmount?: number;
    purchaseCryptoTotalAmountPerMonth?: number;
    sellingCryptoAverageAmount?: number;
    sellingCryptoMonthlyVolume?: number;
    sellingCryptoTotalAmountPerMonth?: number;
    btcTradingPercentage?: number;
    ethTradingPercentage?: number;
    usdcTradingPercentage?: number;
    otherCoinsTradingPercentage?: number;
    otherPurposeOfOpeningAccount?: string;
} = {
    dateOfBirth: '',
    nationalityId: '',
    countryId: '',
    ssn: '',
    stateProvince: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    sourceFundsIds: [],
    otherSourceOfFunds: undefined,

    estimatedNetWorth: undefined,
    estimatedGrossAnnualIncome: undefined,

    otherBanks: [],
    bActivelyEmployed: undefined,
    employedCompanyName: '',
    employedJobTitle: '',

    purposeOfOpeningAccountIds: [],
    otherPurposeOfOpeningAccount: undefined,

    monthlyTurnover: undefined,
    anticipatedtransactionactivity: undefined,

    purchaseCryptoMonthlyVolume: undefined,
    purchaseCryptoAverageAmount: undefined,
    purchaseCryptoTotalAmountPerMonth: undefined,
    sellingCryptoAverageAmount: undefined,
    sellingCryptoMonthlyVolume: undefined,
    sellingCryptoTotalAmountPerMonth: undefined,

    btcTradingPercentage: undefined,
    ethTradingPercentage: undefined,
    usdcTradingPercentage: undefined,
    otherCoinsTradingPercentage: undefined,

    irsTax: undefined,
    foreignAccountTax: undefined,
    notCriminal: undefined,
    accurateInfo: undefined,
};
