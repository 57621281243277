import { RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import instance, { ApiResponse, isErrorHandled } from 'api';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import Page from 'components/page/Page';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useEffect, useMemo, useState } from 'react';

type Props = {};

type ReviewOrderDetails = {
    customerAssetAccountsId: number;
    amount: number;
    price: number;
    previousAmount: number;
    previousTotalPrice: number;
    asset: string;
    pairedAsset: string;
    // bApprovedByAdmin: boolean;
    assetExchangeType: 'Buy' | 'Sell';
    networkFee: number;
    commissionFee: number;
    totalPrice: number;
    quantityType: 'Amount' | 'Price';
};

export const ReviewAmendedOrder = (props: Props & RouteComponentProps) => {
    const [loading, setLoading] = useState(true);
    const { search } = useLocation();
    const screeningId = useMemo(
        () => new URLSearchParams(search).get('transactionScreeningId'),
        [search]
    );
    const navigate = useNavigate();
    const [values, setValues] = useState<ReviewOrderDetails | null>(null);
    const [tfaType, toggleTfaType] = useTFAField(!!values);
    useEffect(() => {
        instance
            .get<ApiResponse<ReviewOrderDetails>>(endpoints.cryptosmodule.reviewByScreeningId, {
                params: { TransactionScreeningId: screeningId },
            })
            .then((res) => {
                setValues(res.data.details);
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    Toast.openToastMessage(
                        getErrorMessage(
                            err.response.data.errors[0].messageCode,
                            "We couldn't find your order, please check the link"
                        ),
                        ToastMessageReason.ERROR
                    );
                } else
                    Toast.openToastMessage(
                        'Error getting details for this transaction',
                        ToastMessageReason.ERROR
                    );
                navigate('/');
            })
            .finally(() => {
                setLoading(false);
            });
        // get review order details from endpoint
        /* setTimeout(() => {
            setLoading(false);
            setValues(dummyValues);
        }, 5000); */
    }, [screeningId, navigate]);

    const cancelOrder = (tfaCode: string) => {
        instance
            .post(endpoints.cryptosmodule.cancelByScreeningId, {
                transactionScreeningId: screeningId,
                tfaCode,
                tfaType,
            })
            .then(() => {
                navigate('/');
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    Toast.openToastMessage(
                        getErrorMessage(err.response.data.errors[0].messageCode),
                        ToastMessageReason.ERROR
                    );
                } else Toast.openGenericErrorToast();
            });
    };

    const approveOrder = (tfaCode: string) => {
        instance
            .post(endpoints.cryptosmodule.sellAssetByScreeningId, {
                transactionScreeningId: screeningId,
                tfaCode,
                tfaType,
                amount: values?.amount,
                totalPrice: values?.totalPrice,
            })
            .then((res) => {
                if (!res.data.details.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                }
                navigate('/');
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    Toast.openToastMessage(
                        getErrorMessage(err.response.data.errors[0].messageCode),
                        ToastMessageReason.ERROR
                    );
                } else Toast.openGenericErrorToast();
            });
    };

    return (
        <Page>
            {loading || !values ? (
                <SubPageLoader message="Fetching Transaction Details" />
            ) : (
                <div className="HeightContent WidthContent">
                    <div className="ReviewAmendedOrder">
                        <div className="OriginalValues">
                            <div className="OrderSummaryContainer">
                                <h3>Your original order:</h3>
                                <div className="SummarySection">
                                    <p>
                                        Amount of {values.asset} to sell:
                                        <span className="FloatRight">{values.previousAmount}</span>
                                    </p>
                                    <p>
                                        {`Value in ${values.pairedAsset}`}
                                        <span className="FloatRight">
                                            {values.previousTotalPrice}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="NewValues">
                            <div className="OrderSummaryContainer">
                                <h3>Your updated order:</h3>
                                <div className="SummarySection">
                                    <p>
                                        Amount of {values.asset} to sell:
                                        <span
                                            className={
                                                'FloatRight ' +
                                                biggerIsBad(values.amount, values.previousAmount)
                                            }
                                        >
                                            {values.amount}
                                        </span>
                                    </p>
                                    <p>
                                        {`Value in ${values.pairedAsset}`}
                                        <span
                                            className={
                                                'FloatRight ' +
                                                biggerIsGood(
                                                    values.totalPrice,
                                                    values.previousTotalPrice
                                                )
                                            }
                                        >
                                            {values.totalPrice}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Formik
                            initialValues={{ tfaCode: '', tfaType: tfaType }}
                            onSubmit={() => {}}
                        >
                            {({ values: FormikValues }) => (
                                <Form>
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'TFA code'}
                                        required={true}
                                        toggleTFAType={toggleTfaType}
                                        tfaType={tfaType}
                                    />
                                    <div className="ButtonRow">
                                        <Button
                                            priority="secondary"
                                            onClick={() => cancelOrder(FormikValues.tfaCode)}
                                        >
                                            Cancel Order
                                        </Button>
                                        <Button
                                            disabled={false /* !values.bApprovedByAdmin */}
                                            onClick={() => approveOrder(FormikValues.tfaCode)}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
        </Page>
    );
};

const biggerIsGood = (value1: number, value2: number) => {
    if (value1 > value2) return 'Green';
    if (value1 < value2) return 'Red';
    else return '';
};

const biggerIsBad = (value1: number, value2: number) => {
    return biggerIsGood(value2, value1);
};
