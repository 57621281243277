import { useState } from 'react';
import { SelectOption } from '../../../../../components/form/Dropdown';
import { useField, useFormikContext } from 'formik';
import api, { ApiResponse, isAxiosErrorHandled } from '../../../../../api';
import { endpoints } from '../../../../../endpoints.config';
import { SingleSelectWithSearch } from '../../../../../components/form/SingleSelectWithSearch/SingleSelectWithSearch';
import styles from './AddressLookup.module.scss';
import FormTextField from '../../../../../components/form/FormTextField';
import { PayeeAddress } from '../../EditPayee';
import { WrappedFormSingleSelectField as FormSingleSelectField } from '../../../../../components/form/FormSingleSelectField';

type LatLng = {
    lat: number;
    lon: number;
};

type AddressResponseOption = {
    type: string;
    id: string;
    score: number;
    address: {
        streetName: string;
        streetNameAndNumber: string | null;
        streetNumber: string | null;
        municipality: string;
        municipalitySubdivision: string | null;
        neighbourhood: string | null;
        countrySecondarySubdivision: string;
        countrySubdivision: string;
        countrySubdivisionName: string;
        postalCode: string;
        extendedPostalCode: string;
        countryCode: string;
        country: string;
        countryCodeISO3: string;
        freeformAddress: string;
        localName: string;
    };
    position: LatLng;
    viewport: {
        topLeftPoint: LatLng;
        btmRightPoint: LatLng;
    };
};

type AddressOptionResponse = {
    summary: {
        query: string;
        queryType: string;
        queryTime: number;
        numResults: number;
        offset: number;
        totalResults: number;
        fuzzyLevel: number;
    };
    results: AddressResponseOption[];
};

type Props = {
    disabled?: boolean;
    countryOptions: SelectOption[];
    defaultShowLookup?: boolean;
    fieldnamePrefix?: string;
};

function debounce(fn: (...args: any) => void, delay = 250) {
    let timeout: any;

    return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

export const AddressLookup: React.FC<Props> = ({
    disabled,
    countryOptions,
    defaultShowLookup = true,
    fieldnamePrefix,
}) => {
    const [manual, setManual] = useState(!defaultShowLookup);

    const buildFieldname = (name: string) =>
        fieldnamePrefix ? `${fieldnamePrefix}.${name}` : name;
    const [, { error, touched }] = useField<AddressResponseOption | null>(
        buildFieldname('addressLine1')
    );
    const { values, setValues } = useFormikContext<{ [key: string]: any } & PayeeAddress>();

    const buildAddressObject = (
        address: AddressOptionResponse['results'][0]['address']
    ): PayeeAddress => ({
        addressLine1:
            address.streetNameAndNumber ??
            `${address.streetNumber ? `${address.streetNumber} ` : ''}${address.streetName}`, // we want streetNameAndNumber
        addressLine2: address.municipalitySubdivision ?? '',
        townCity: address.municipality,
        state:
            address.countryCodeISO3 === 'GBR'
                ? address.countrySecondarySubdivision
                : address.countrySubdivision,
        postCode: address.extendedPostalCode?.split(',')[0] ?? address.postalCode,
        addressCountryCode: address.countryCodeISO3,
    });
    const buildAddressString = (address: AddressOptionResponse['results'][0]['address']) => {
        return address.freeformAddress;
    };

    const loadOptions = debounce(async (inputValue: string, callback: any) => {
        try {
            const res = await api.get<ApiResponse<AddressOptionResponse>>(
                endpoints.accounts.lookupAddress,
                {
                    params: { term: inputValue },
                }
            );

            const options = res.data.details.results;
            const newOpts = options.map((item) => ({
                label: buildAddressString(item.address),
                value: item,
            }));

            callback(newOpts);
        } catch (error) {
            callback([]);
        }
    }, 500);

    const handleAddressChosen = (opt: { value: AddressResponseOption; label: string }) => {
        const address = buildAddressObject(opt.value.address);

        const addressField = fieldnamePrefix ? { [fieldnamePrefix]: address } : address;

        setValues({
            ...values,
            ...addressField,
        });
        setManual(true);
    };
    const resetAddressLookup = () => {
        const emptyAddress = {
            addressLine1: '',
            addressLine2: '',
            townCity: '',
            state: '',
            postCode: '',
            addressCountryCode: '',
        };
        const addressField = fieldnamePrefix ? { [fieldnamePrefix]: emptyAddress } : emptyAddress;

        setValues({
            ...values,
            ...addressField,
        });
        setManual(false);
    };

    const NoOptionsComponent = (inputValue: string) => {
        return !inputValue ? (
            <p>Start typing to search for an address</p>
        ) : (
            <>
                <p>No results found</p>
                <button
                    className={`${styles.Toggle} ${styles.ToggleInline}`}
                    type="button"
                    onClick={() => setManual(true)}
                >
                    Enter your address manually
                </button>
            </>
        );
    };

    if (!manual)
        return (
            <div className={styles.Lookup}>
                <SingleSelectWithSearch
                    loadOptions={loadOptions}
                    onChange={handleAddressChosen}
                    noOptionsComponent={NoOptionsComponent}
                    label="Address"
                    defaultOptions={[]}
                    dropdownProps={{
                        instanceId: 'lookup',
                        isDisabled: disabled,
                        placeholder: '10 Example Street...',
                    }}
                    error={!!touched && !!error ? error : undefined}
                />
                <button className={styles.Toggle} type="button" onClick={() => setManual(true)}>
                    Enter your address manually
                </button>
            </div>
        );

    return (
        <div className={styles.Manual}>
            <button className={styles.Toggle} type="button" onClick={resetAddressLookup}>
                Search your address
            </button>
            <FormTextField
                disabled={disabled}
                field={buildFieldname('addressLine1')}
                label={'Address Line 1'}
            />
            <FormTextField
                disabled={disabled}
                field={buildFieldname('addressLine2')}
                label={'Address Line 2'}
                labelExtraInfo="Optional"
            />
            <div className="AddressLayout">
                <FormTextField
                    disabled={disabled}
                    field={buildFieldname('townCity')}
                    label={'Town / City'}
                />
                <FormTextField
                    disabled={disabled}
                    field={buildFieldname('state')}
                    label={'State'}
                />
            </div>
            <div className="AddressLayout">
                <FormTextField
                    disabled={disabled}
                    field={buildFieldname('postCode')}
                    label={'ZIP code'}
                />
                <FormSingleSelectField
                    fieldName={buildFieldname('addressCountryCode') as never}
                    options={countryOptions}
                    label="Country"
                    forceTouchOnChange
                    dropdownProps={{ isDisabled: disabled }}
                />
            </div>
        </div>
    );
};
