import { TableInfo, useTable } from 'api';
import SearchIcon from 'assets/ibanera/search-icon.svg';
import {
    BuySellCell,
    DateCell,
    ExchangeReferenceCell,
    NumberAndTickerCell,
    StringCell,
} from 'components/flexTable/CustomCells';
import { HistoryTable } from 'components/historyTable/HistoryTable';
import Pagination from 'components/pagination/Pagination';
import { endpoints } from 'endpoints.config';
import React, { useContext, useEffect, useState } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';

type CryptoHistoryItem = {
    customerAssetAccountsTransactions__Id: number;
    assets__Name: string;
    assets__Code: string;
    customerAssetAccountsTransactions__Reference: string;
    customerAssetAccountsTransactions__Date: string;
    transactionTypes__Type: 'Buy' | 'Sell' | 'Deposit' | 'Withdraw';
    customerAssetAccountsTransactions__Amount: number;
    assetExchanges__Price: number | null;
    feeTransactions__Amount: number;
};

const rowFormatter = (
    row: Partial<CryptoHistoryItem>
): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (row.assets__Name && row.customerAssetAccountsTransactions__Reference && row.assets__Code) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Reference: (
                <ExchangeReferenceCell
                    ticker={row.assets__Code}
                    reference={row.customerAssetAccountsTransactions__Reference}
                />
            ),
        };
    }
    if (row.customerAssetAccountsTransactions__Date) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Date: (
                <DateCell date={row.customerAssetAccountsTransactions__Date} />
            ),
        };
    }

    if (row.transactionTypes__Type) {
        formattedRow = {
            ...formattedRow,
            transactionTypes__Type: <BuySellCell type={row.transactionTypes__Type} />,
        };
    }

    if (
        row.customerAssetAccountsTransactions__Amount ||
        row.customerAssetAccountsTransactions__Amount === 0
    ) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Amount:
                row.assets__Code && row.assetExchanges__Price ? (
                    <NumberAndTickerCell
                        number={row.customerAssetAccountsTransactions__Amount}
                        ticker={row.assets__Code}
                    />
                ) : (
                    row.customerAssetAccountsTransactions__Amount
                ),
        };
    }

    if (row.assetExchanges__Price) {
        formattedRow = {
            ...formattedRow,
            assetExchanges__Price: <StringCell value={row.assetExchanges__Price} />,
        };
    }

    if (row.feeTransactions__Amount || row.feeTransactions__Amount) {
        formattedRow = {
            ...formattedRow,
            feeTransactions__Amount:
                row.assets__Code && row.feeTransactions__Amount ? (
                    <NumberAndTickerCell
                        number={row.feeTransactions__Amount}
                        ticker={row.assets__Code}
                    />
                ) : (
                    row.feeTransactions__Amount
                ),
        };
    }

    return formattedRow;
};

const ID_COLUMN = 'customerAssetAccountsTransactions__Id';

export const History = () => {
    const table = useTable<CryptoHistoryItem, Error>({
        url: endpoints.exchangemodule.historyList,
        bClearFilters: true,
        multiSort: false,
    });
    const [searchTimeout, setSearchTimeout] = useState<number | null>(null);
    const { searchString, setSearchString, isPageLoading, setIsPageLoading } = useContext(
        ExchangeCryptoContext
    );

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        setSearchString(search);
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        const timeout = window.setTimeout(() => {
            table.data?.actions.changeSearch(search);
            setSearchTimeout(null);
        }, 500);
        setSearchTimeout(timeout);
    };

    useEffect(() => {
        if (isPageLoading && !table.loading) {
            setIsPageLoading(false);
        }
    }, [setIsPageLoading, isPageLoading, table.data, table.loading]);

    return (
        <div className="Cryptos">
            <div className="Portfolio">
                <div className="MarketSubHeader">
                    <p>&nbsp;</p>
                    <div className="MarketTableSearch">
                        <div className="MarketTableInput">
                            <div className="FormBox FormSideBySide">
                                <div className="FormLabel">
                                    <label></label>
                                </div>
                                <div className="FormField">
                                    <input
                                        name="search"
                                        placeholder="Search all currencies"
                                        className="EditBox"
                                        value={searchString}
                                        onChange={handleSearch}
                                    />
                                    <img src={SearchIcon} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HistoryTable table={table as TableInfo} idColumn={''} simpleReferenceCell />
                <Pagination table={table} />
            </div>
        </div>
    );
};
