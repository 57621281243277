import React from 'react';

type SummaryItemProps = {
    title: string;
    content: string;
    value: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ title, content, value }) => {
    return (
        <div className="SummaryItem">
            <p className="ItemTitle">{title}</p>
            <p className="ItemContent">{content ? content : 'n/a'}<span>{value}</span></p>
        </div>
    )
}

export { SummaryItem };
