import { Form, Formik, FormikHelpers, useField } from 'formik';
import { plaintext } from 'plaintext.config';
import FormTextField from '../form/FormTextField';
import { FormDateField } from '../form/FormDateField';
import { WrappedFormSingleSelectField } from '../form/FormSingleSelectField';
import { MultiSelectChecklist } from '../form/MultiSelectChecklist';
import { useState } from 'react';
import Button from '../button/Button';
import { RadioButtons } from '../radiobuttons/radiobuttons';
import { AnticipatedActivityPersonal } from './AnticipatedActivityPersonal';
import api, { ApiResponse, ErrorMessage, isErrorHandled, useFetch } from '../../api';
import { endpoints } from '../../endpoints.config';
import { Spinner } from '../spinner/Spinner';
import {
    FormState,
    OTHER_SOURCE_FUND_ID,
    initialValues,
    validationSchema,
} from './manualValidationHelpers';
import { processErrors } from '../../helpers/categoryHelpers/processErrors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosResponse } from 'axios';
import { Toast } from '../../helpers/toast';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';

const setFormikErrors = <T,>(
    helpers: FormikHelpers<T>,
    errors: ErrorMessage[],
    parseErrors?: (e: ErrorMessage[]) => ErrorMessage[]
) => {
    helpers.setSubmitting(false);
    const parsedErrors = parseErrors ? parseErrors(errors) : errors;
    helpers.setErrors(processErrors(parsedErrors));
};

type InitResponse = {
    countries:
        | {
              id: number;
              countryName: string;
              isoCode: string;
              states:
                  | {
                        id: number;
                        stateProvinceName: string;
                        ansiCode: string;
                    }[]
                  | null;
          }[]
        | null;
    nationalities:
        | {
              id: number;
              countryName: string;
              nationality: string;
              isoCode: string;
          }[]
        | null;
    sourceOfFunds:
        | {
              id: number;
              name: string;
          }[]
        | null;
    purposes:
        | {
              id: number;
              name: string;
          }[]
        | null;
};

export const ManualVerificationRequired = () => {
    const [success, setSuccess] = useState(false);

    const { data, loading, error, reload } = useFetch<ApiResponse<InitResponse>>(
        endpoints.personalVerification.dropdownOptions
    );

    const reloadInitForm = () => {
        reload();
    };

    const countries = data?.details.countries
        ? data.details.countries.map((val) => ({ label: val.countryName, value: val.id }))
        : [];
    const nationalities = data?.details.nationalities
        ? data.details.nationalities.map((val) => ({ label: val.nationality, value: val.id }))
        : [];
    const sourceOfFunds = data?.details.sourceOfFunds
        ? data.details.sourceOfFunds.map((val) => ({ label: val.name, value: val.id }))
        : [];
    const purposes = data?.details.purposes
        ? data.details.purposes.map((val) => ({
              label: val.name,
              value: val.id,
          }))
        : [];

    const selectStates = (countryCode?: string) => {
        if (!countryCode) return null;

        const country = (data?.details.countries ?? []).find(
            (count) => count.isoCode === countryCode
        );
        if (!country) return null;
        return country.states
            ? country.states.map((state) => ({
                  label: state.stateProvinceName,
                  value: state.ansiCode,
              }))
            : null;
    };

    const onSubmit = async (values: FormState, helpers: FormikHelpers<FormState>) => {
        const {
            dateOfBirth,
            sourceFundsIds,
            otherSourceOfFunds,
            purposeOfOpeningAccountIds,
            otherPurposeOfOpeningAccount,
            ...rest
        } = values;

        const filteredFunds = sourceFundsIds.filter((src) => src !== OTHER_SOURCE_FUND_ID);
        const filteredPurpose = purposeOfOpeningAccountIds.filter(
            (src) => src !== OTHER_SOURCE_FUND_ID
        );
        const payload = {
            ...rest,
            dateOfBirth: new Date(dateOfBirth).toISOString(),
            sourceFundsIds: filteredFunds.length > 0 ? filteredFunds : null,
            otherSourceOfFunds: sourceFundsIds.includes(OTHER_SOURCE_FUND_ID)
                ? otherSourceOfFunds
                : undefined,
            purposeOfOpeningAccountIds: filteredPurpose.length > 0 ? filteredPurpose : null,
            otherPurposeOfOpeningAccount: purposeOfOpeningAccountIds.includes(OTHER_SOURCE_FUND_ID)
                ? otherPurposeOfOpeningAccount
                : undefined,
        };

        try {
            const res = await api.post<ApiResponse>(
                endpoints.personalVerification.personalVerification,
                payload
            );
            if (res.data.status === '1') {
                setSuccess(true);
            }
        } catch (error: AxiosResponse | any) {
            if (isErrorHandled(error)) {
                setFormikErrors(helpers, error.response?.data.errors);
                setFinalStep(false);
            } else {
                Toast.openErrorToast('Failed to submit form');
            }
        }
    };

    const [finalStep, setFinalStep] = useState(false);
    const handleContinue = async (helpers: FormikHelpers<FormState>) => {
        const valid = await helpers.validateForm();

        const secondPageFields = ['irsTax', 'foreignAccountTax', 'notCriminal', 'accurateInfo'];

        // 4 because the boolean agreements won't be done yet
        if (Object.keys(valid).filter((key) => !secondPageFields.includes(key)).length > 0) {
            // Easiest way to force touched on everything
            helpers.submitForm();
            return;
        } else {
            // Reset touched for these fields on next page in case above if was triggered first
            helpers.setTouched({
                irsTax: false,
                foreignAccountTax: false,
                notCriminal: false,
                accurateInfo: false,
            });
            setFinalStep(true);
        }
    };

    if (success)
        return (
            <p className="ManualVerificationRequiredSuccess">
                {plaintext.verification.additionalNotes}
            </p>
        );
    if (loading)
        return (
            <div className="ManualVerificationRequired Loading">
                <Spinner />;
            </div>
        );
    if (error)
        return (
            <div className="ManualVerificationRequired Error">
                <h2>Failed to load form</h2>
                <Button priority="secondary" type="button" onClick={reloadInitForm}>
                    Retry
                </Button>
            </div>
        );

    const isOtherSelected = (ids: number[] | null) => {
        if (!ids) return false;
        // const otherId = purposes.find(opt => opt.label === 'Other')
        const otherId = [...purposes, { value: OTHER_SOURCE_FUND_ID, label: 'Other' }].find(
            (opt) => opt.label === 'Other'
        );

        return ids.includes(otherId?.value ?? 0);
    };

    return (
        <div className="ManualVerificationRequired">
            <p>{plaintext.verification.needAdditionalInfo}</p>
            <Formik
                // @ts-ignore
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ values, ...helpers }) => {
                    const country = (data?.details.countries ?? []).find(
                        (c) => c.id + '' === values.countryId + ''
                    );
                    const states = selectStates(country?.isoCode);

                    return (
                        <Form>
                            {!finalStep && (
                                <>
                                    <StateReset />
                                    <h2>Individual</h2>
                                    <div className="IndividualLayout Divide">
                                        <FormDateField
                                            field={`dateOfBirth`}
                                            label="Date of Birth*"
                                            datePickerProps={{
                                                maxDate: new Date(),
                                            }}
                                        />
                                        <WrappedFormSingleSelectField
                                            fieldName={'nationalityId'}
                                            label={'Nationality*'}
                                            options={nationalities}
                                            tooltip={'Please enter Citizenship or Resident Country'}
                                        />
                                        <WrappedFormSingleSelectField
                                            fieldName={'countryId'}
                                            label={'Country*'}
                                            options={countries}
                                            tooltip={
                                                'Please choose the country in which you reside.'
                                            }
                                        />
                                        <FormTextField
                                            field="ssn"
                                            label={'Tax ID Number/SSN'}
                                            maxLength={200}
                                            asterisk
                                        />
                                        {states ? (
                                            <WrappedFormSingleSelectField
                                                fieldName="stateProvince"
                                                label="State / Province*"
                                                options={states}
                                            />
                                        ) : (
                                            <FormTextField
                                                field="stateProvince"
                                                label="State / Province"
                                                asterisk
                                            />
                                        )}
                                        <FormTextField field="city" label="City" asterisk />
                                        <FormTextField
                                            field="addressLine1"
                                            label="Street Address"
                                            asterisk
                                        />
                                        <FormTextField
                                            field="addressLine2"
                                            label="Street Address 2"
                                        />
                                        <FormTextField
                                            field="postcode"
                                            label="ZIP / Postal code"
                                            asterisk
                                        />
                                    </div>

                                    <MultiSelectChecklist
                                        label="Source of funds"
                                        fieldName="sourceFundsIds"
                                        asterisk
                                        options={[
                                            ...sourceOfFunds,
                                            { value: OTHER_SOURCE_FUND_ID, label: 'Other' },
                                        ]}
                                    />
                                    {values.sourceFundsIds.includes(OTHER_SOURCE_FUND_ID) && (
                                        <FormTextField
                                            field="otherSourceOfFunds"
                                            label="Other Source of funds"
                                            asterisk
                                        />
                                    )}
                                    <div className="Divide" />
                                    <div className="IndividualLayout Divide">
                                        <FormTextField
                                            field="estimatedGrossAnnualIncome"
                                            label="Estimated Gross Annual Income"
                                            currencySymbol="$"
                                            asterisk
                                            tooltip={<>Estimated amount of earned income.</>}
                                        />
                                        <FormTextField
                                            field="estimatedNetWorth"
                                            label="Estimated Net Worth"
                                            currencySymbol="$"
                                            asterisk
                                            tooltip={
                                                <>
                                                    Enter estimated value of all assets held in your
                                                    name.
                                                </>
                                            }
                                        />
                                    </div>

                                    <AccountsAtOtherBanks fieldName="otherBanks" />

                                    <RadioButtons
                                        options={['Yes', 'No']}
                                        label={'Are you actively employed? *'}
                                        booleanField
                                        fieldname="bActivelyEmployed"
                                    />
                                    {values.bActivelyEmployed && (
                                        <>
                                            <FormTextField
                                                field="employedCompanyName"
                                                label="Full Name of Company"
                                                asterisk
                                            />
                                            <FormTextField
                                                field="employedJobTitle"
                                                label="Job title"
                                                asterisk
                                            />
                                        </>
                                    )}
                                    <div className="Divide" />

                                    <MultiSelectChecklist
                                        label="Purpose of opening account"
                                        fieldName="purposeOfOpeningAccountIds"
                                        asterisk
                                        options={[
                                            ...purposes,
                                            { value: OTHER_SOURCE_FUND_ID, label: 'Other' },
                                        ]}
                                    />
                                    {isOtherSelected(values.purposeOfOpeningAccountIds) && (
                                        <FormTextField
                                            field="otherPurposeOfOpeningAccount"
                                            label="Other purpose of opening account"
                                            asterisk
                                        />
                                    )}
                                    <div className="Divide" />
                                    <FormTextField
                                        field="monthlyTurnover"
                                        label="Monthly Turnover"
                                        currencySymbol="$"
                                        inputMode="numeric"
                                        asterisk
                                    />
                                    <FormTextField
                                        field="anticipatedtransactionactivity"
                                        label="Anticipated transaction activity for the next 12 months"
                                        currencySymbol="$"
                                        inputMode="numeric"
                                        asterisk
                                    />
                                    <div className="Divide" />
                                    <AnticipatedActivityPersonal />
                                    <Button onClick={() => handleContinue(helpers)} type="button">
                                        Continue
                                    </Button>
                                </>
                            )}
                            {finalStep && (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => setFinalStep(false)}
                                        className="FormBackButton"
                                    >
                                        {'<'} Back
                                    </button>
                                    <RadioButtons
                                        options={['Yes', 'No']}
                                        label="I confirm that I am personally up to date and compliant with IRS tax filing requirements*"
                                        booleanField
                                        fieldname="irsTax"
                                    />
                                    <RadioButtons
                                        options={['Yes', 'No']}
                                        label="I confirm that I am in full compliance with Foreign Account Tax Compliance Act (FACTA)*"
                                        booleanField
                                        fieldname="foreignAccountTax"
                                    />
                                    <RadioButtons
                                        options={['Yes', 'No']}
                                        label="I declare I have not been engaged in or have benefited from criminal conduct in any part of the world and funds which are subject to the proposed arrangement do not wholly or in part directly represent the proceeds of criminal conduct*"
                                        booleanField
                                        fieldname="notCriminal"
                                    />
                                    <RadioButtons
                                        options={['Yes', 'No']}
                                        label="I declare that the information given hereunder and in the documents requested hereby is to the best of your knowledge true and accurate as at the date hereof,and should there be any changes in the information so provided you undertake to promptly advise our law firm of the same in writing*"
                                        booleanField
                                        fieldname="accurateInfo"
                                    />
                                    <Button type="submit">Submit</Button>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

type AccountsAtOtherBanksProps = {
    fieldName: string;
};
const AccountsAtOtherBanks: React.FC<AccountsAtOtherBanksProps> = ({ fieldName }) => {
    const [{ value }, , { setValue }] = useField<string[]>(fieldName);

    const [additionalAccounts, setAdditionalAccounts] = useState(0);

    const handleAddAccount = () => {
        setAdditionalAccounts((prev) => prev + 1);
    };
    const handleRemoveAccount = () => {
        setAdditionalAccounts((prev) => {
            setValue(value.slice(0, prev));

            return prev - 1;
        });
    };

    return (
        <fieldset className="Divide">
            <legend>Accounts at other banks</legend>
            <FormTextField
                field="otherBanks[0]"
                label="Name"
                tooltip={<>Please enter the name of other active bank accounts. Optional.</>}
            />
            {new Array(additionalAccounts).fill(null).map((_, i) => (
                <FormTextField key={i} field={`otherBanks[${i + 1}]`} label="Name" />
            ))}
            <Button onClick={handleAddAccount} type="button" priority="secondary">
                + Add Another Account
            </Button>
            {additionalAccounts > 0 && (
                <Button
                    disabled={additionalAccounts === 0}
                    onClick={handleRemoveAccount}
                    type="button"
                    priority="secondary"
                >
                    - Remove Account
                </Button>
            )}
        </fieldset>
    );
};

const StateReset = () => {
    const [{ value }] = useField('countryId');
    const [, , { setValue }] = useField('stateProvince');

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return null;
};
