import Button from '../../../button/Button';
import { ReactComponent as BusinessIcon } from 'assets/ui-update/business.svg';
import { ReactComponent as IndividualIcon } from 'assets/ui-update/individual.svg';

export type TreeActionButtonsProps = {
    handleAddOwner(fieldname: string, isBusiness: boolean): void;
    fieldname: string;
    isAddingItem: boolean;
    hideAddBusiness?: boolean;
    hideLeftBar?: boolean;
    hasError: boolean;
};

export const TreeActionButtons: React.FC<TreeActionButtonsProps> = ({
    handleAddOwner,
    hideLeftBar = false,
    isAddingItem,
    fieldname,
    hideAddBusiness = false,
    hasError,
}) => (
    <div className={`TreeActionButtons ${hasError ? 'Error' : ''}`}>
        {hideLeftBar && <div aria-hidden="true" className="FloatingHide Full" />}
        {!hideAddBusiness && (
            <Button
                disabled={isAddingItem}
                type="button"
                priority="secondary"
                onClick={() => handleAddOwner(fieldname, true)}
            >
                <BusinessIcon />
                <span>Add business beneficial owner</span>
            </Button>
        )}
        <Button
            disabled={isAddingItem}
            type="button"
            priority="secondary"
            onClick={() => handleAddOwner(fieldname, false)}
        >
            <IndividualIcon />
            <span>Add individual beneficial owner</span>
        </Button>
    </div>
);
