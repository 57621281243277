import { FormTextField } from '@avamae/formbuilder';
import Button from 'components/button/Button';
import { Form, Formik } from 'formik';
import { FormFieldWrapper } from 'helpers/formFieldWrapper';
import React, { useState } from 'react';
import Select from 'react-select';
import { CryptoLiveData } from 'pages/crypto/CryptoPage';
import logger from 'helpers/logger';

type BuyCryptoFormProps = {
    crypto: CryptoLiveData;
    currency: string;
};

type BuyCrypto = {
    account: string;
    amount: number;
    price: number;
};

const BuyCryptoForm: React.FC<BuyCryptoFormProps> = ({ crypto, currency }) => {
    const [isFiat, setIsFiat] = useState<boolean>(false);
    // STILL TO DO - tie into endpoint
    const handleSubmit = () => {
        logger.log('submit');
    };

    const initialValues: BuyCrypto = {
        account: '',
        amount: 0,
        price: 0,
    };

    const options = [
        { value: 'Current account', label: 'Current account' },
        { value: 'Savings account', label: 'Savings account' },
        { value: 'Trading account', label: 'Trading account' },
    ];

    const handleAmountField = (formik: any) => (e: any) => {
        if (!isFiat) {
            formik.setFieldValue('price', crypto.buyPrice && e.target.value * crypto.buyPrice);
            formik.setFieldValue('amount', e.target.value);
        } else {
            formik.setFieldValue(
                'price',
                crypto.buyPrice ? (isNaN(e.target.value) ? 0 : e.target.value / crypto.buyPrice) : 0
            );
            formik.setFieldValue('amount', e.target.value);
        }
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {(formik) => (
                <Form className="FormBuilderForm">
                    <FormFieldWrapper label="Select Account" fieldName="account">
                        <Select
                            isSearchable={false}
                            options={options}
                            defaultValue={{ value: 'Current account', label: 'Current account' }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                    </FormFieldWrapper>
                    <div className="Toggle">
                        <label className="switch">
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    e.target.checked ? setIsFiat(true) : setIsFiat(false);
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <FormTextField
                        inputProps={{
                            type: 'number',
                            min: 0,
                            onChange: handleAmountField(formik),
                        }}
                        fieldName="amount"
                        label={isFiat ? `Amount in ${currency}` : `Amount in ${crypto.ticker}`}
                    />
                    <FormTextField
                        fieldName="price"
                        label={isFiat ? `Amount of ${crypto.ticker}` : `Price of ${crypto.ticker}`}
                        readOnly
                    />
                    <Button type="submit" className="TradeSubmit">
                        Review order
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export { BuyCryptoForm };
