/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { CardContext } from './CardPage';
import Cards from 'react-credit-cards-2';
import Button from '../../components/button/Button';
import { Toast } from '../../helpers/toast';
import { CardDetailsModal } from './CardDetailsModal';

const EMPTY_CARD_STR = '************';

export const CardDetails = () => {
    const [revealCVC, setRevealCVC] = useState(false);

    const { cardDetails, resetCardDetails } = useContext(CardContext);

    const [revealPin, setRevealPin] = useState(false);

    const hasProtectedDetails =
        cardDetails && (cardDetails.cardNumberFull || cardDetails.cvc || cardDetails.pin);

    const cardNumberShort = cardDetails?.cardNumber ? cardDetails?.cardNumber.slice(-4) : '****';
    const cardNumberFull = cardDetails?.cardNumberFull;

    const [showModal, setShowModal] = useState<{
        resolve: () => void;
        reject: () => void;
    }>();

    const getProtectedCardDetails = async () => {
        await new Promise<undefined>((resolve, reject) => {
            setShowModal({
                resolve: () => {
                    resolve(undefined);
                },
                reject: () => {
                    reject();
                },
            });
        });
    };
    // Flip the card back if the cvc gets hidden again
    useEffect(() => {
        if (!hasProtectedDetails && revealCVC) setRevealCVC(false);
    }, [hasProtectedDetails]);

    useEffect(() => {
        return () => resetCardDetails();
    }, []);

    const handleRevealCardNumber = async () => {
        try {
            if (!hasProtectedDetails) await getProtectedCardDetails();
            setRevealCVC(false);
        } catch {}
    };
    const copyCardNumber = () => {
        if (!cardNumberFull) return;
        handleRevealCardNumber();
        window.navigator.clipboard.writeText(cardNumberFull.toString());
        Toast.openSuccessToast('Copied', { autoClose: 2000 });
    };
    const handleRevealCvc = async () => {
        try {
            if (!hasProtectedDetails) await getProtectedCardDetails();
            setRevealCVC((prev) => !prev);
        } catch {}
    };
    const onCloseModal = () => {
        showModal?.reject();
        setShowModal(undefined);
    };
    const handleRevealPin = async () => {
        try {
            if (revealPin) return;
            if (!hasProtectedDetails) await getProtectedCardDetails();
            if (!cardDetails?.pin) return;
            setRevealPin(true);
            setTimeout(() => {
                setRevealPin(false);
            }, 2900);
        } catch {}
    };

    return (
        <div className="CardDetailsPage FormPage">
            <Cards
                name={cardDetails?.nameOnCard ?? ''}
                number={cardNumberFull ? cardNumberFull : EMPTY_CARD_STR + cardNumberShort}
                expiry={
                    Date.parse(cardDetails?.expiryDate ?? '')
                        ? format(new Date(cardDetails!.expiryDate), 'MM/yy')
                        : ''
                }
                cvc={cardDetails?.cvc ?? '***'}
                preview={!hasProtectedDetails || !cardNumberFull}
                issuer={cardDetails?.cardIssuer}
                focused={revealCVC ? 'cvc' : hasProtectedDetails ? 'number' : undefined}
            />
            <div className="CardControls">
                {cardDetails?.bHasFullCardNumber && !hasProtectedDetails && (
                    <Button onClick={handleRevealCardNumber}>Show Card Number</Button>
                )}
                {hasProtectedDetails && cardNumberFull && (
                    <Button onClick={copyCardNumber}>Copy Card Number</Button>
                )}
                {cardDetails?.bHasCvc && (
                    <Button priority="secondary" onClick={handleRevealCvc}>
                        {revealCVC ? 'Hide' : 'Show'} CVC
                    </Button>
                )}
                {cardDetails?.bHasPin && (
                    <div className={`PinWrapper ${revealPin ? 'Reveal' : ''}`}>
                        <div className="TimerBar" />
                        <Button priority="secondary" onClick={handleRevealPin}>
                            {!revealPin && <span className="Text">Show Pin</span>}
                            {revealPin && <span className="Pin">{cardDetails?.pin}</span>}
                        </Button>
                    </div>
                )}
            </div>

            <CardDetailsModal
                visible={!!showModal}
                onSuccess={() => {
                    showModal?.resolve();
                    onCloseModal();
                }}
                onClose={onCloseModal}
            />
        </div>
    );
};
