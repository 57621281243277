import { FormMultiSelectField } from 'components/form/FormMultiSelectField';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import FormTextField from 'components/form/FormTextField';
import React, { useEffect, useMemo } from 'react';
import { useVerificationContext } from '../helpers/verificationContext';
import { useFormikContext } from 'formik';
import { FormValues } from '../schema';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { SelectOptionRadioButton } from 'components/radiobuttons/SelectOptionRadioButtons';
import UploadField from '../helpers/UploadField';
import Button from 'components/button/Button';
import BackButton from '../helpers/BackButton';

export const OperationsInformation = () => {
    const { selectOptions } = useVerificationContext();
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const otherFundsSelected = useMemo(
        () =>
            values.operationsInformation.sourceOfFundsIds &&
            values.operationsInformation.sourceOfFundsIds.some(
                (id) =>
                    id ==
                    (selectOptions?.fundingSources?.find((type) => type.label === 'Other')?.value ??
                        0)
            ),
        [selectOptions?.fundingSources, values.operationsInformation.sourceOfFundsIds]
    );
    useEffect(() => {
        if (!otherFundsSelected) setFieldValue(fieldName('sourceOfFundsOther'), null);
    }, [otherFundsSelected, setFieldValue]);
    return (
        <>
            <BackButton />
            <h2>Entity Operations Information</h2>
            <FormTextField
                field={fieldName('webAddress')}
                label={'Website Address'}
                labelExtraInfo="Optional"
            />
            <div className="FormSideBySide">
                <div className="FormBuilderComponent SideBySide">
                    <FormPhoneNumberField
                        field={fieldName('phoneNumber')}
                        label="Business Phone Number"
                        labelExtraInfo="Optional"
                    />
                </div>
                <FormTextField
                    field={fieldName('supportEmail')}
                    label={'Support Email'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
            </div>
            <RadioButtons
                options={['Yes', 'No', 'NA']}
                fieldname={fieldName('bPubliclyListed')}
                label="Is business a publicly listed company? If so, list exchange symbol and exchange where traded."
            />
            {values.operationsInformation.bPubliclyListed === 'Yes' && (
                <div className="FormSideBySide">
                    <FormTextField
                        field={fieldName('ticker')}
                        label={'Exchange Symbol'}
                        sideBySide
                        tooltip={
                            'Enter trading symbol of the listed company. Example: Apple is public and trades under the symbol AAPL'
                        }
                    />
                    <FormTextField
                        field={fieldName('exchanges')}
                        label={'Exchange(s)'}
                        sideBySide
                    />
                </div>
            )}

            <FormMultiSelectField
                fieldName={fieldName('operationRegionIds')}
                options={selectOptions?.regionsOfOperation ?? []}
                label={'Regions of Business Operations'}
                tooltip={
                    'Select all regions where the business conducts business or provides services or goods to the citizens'
                }
            />
            <RadioButtons
                options={['Yes', 'No', 'NA']}
                fieldname={fieldName('bLicenseRequired')}
                label="Does your business require a license?"
            />

            <FormTextField
                field={fieldName('additionalLicensingInfo')}
                label={
                    "Additional information you'd like to add regarding registration or licensing"
                }
                labelExtraInfo="Optional"
                tooltip="Use this space to provide any explantion or clarification regarding the entity's legal status, registrations, jurisdiction of operation etc."
            />
            <FormTextField
                field={fieldName('primaryRegulator')}
                label={'Primary Regulator - Example: FCA, SEC, OCC, FINMA, MAS'}
                labelExtraInfo="Optional"
                tooltip="If the entity is licensed, it will have a primary regulator. Example: A bank in the UK will have the FCA it's primary regulator, a money service business in the US will have the Department of Treasury, IRS as it's primary regulator."
            />
            <FormTextField
                field={fieldName('licenseNumber')}
                label={'License Number'}
                labelExtraInfo="Optional"
                tooltip="If the entity is licensed enter the license number."
            />

            <div className={`FormBox`}>
                <div className="FormLabel">
                    <label>
                        If you are considered a FINANCIAL INSTITUTION in the jurisdiction in which
                        you operate or are registered, please complete this form
                        <span className="Optional"> - optional</span>
                    </label>
                </div>
                <a href="/Ibanera-Financial-Institution-Form.pdf" download>
                    <Button type="button" style={{ marginBottom: 10 }} variety="full">
                        Download form
                    </Button>
                </a>
                <UploadField
                    fieldName={fieldName('financialInstitutionFormFileName')}
                    displayError={true}
                    multi={false}
                    jsonField={true}
                />
            </div>
            <FormMultiSelectField
                fieldName={fieldName('businessActivityIds')}
                options={selectOptions?.businessActivities ?? []}
                label={'Main Business Activity'}
            />
            <FormTextField
                type="textarea"
                field={fieldName('web3ChainsAndAddresses')}
                label={
                    'If the entity expects to utilize Web3 for transactions, provide blockchain and blockchain addresses that will be used when conducting business'
                }
                labelExtraInfo="Optional"
            />
            <FormMultiSelectField
                fieldName={fieldName('sourceOfFundsIds')}
                options={selectOptions?.fundingSources ?? []}
                label={'Please indicate the source of funding for the entity, check all that apply'}
            />

            {otherFundsSelected && (
                <FormTextField
                    field={fieldName('sourceOfFundsOther')}
                    label={'Other Source of Funds'}
                />
            )}
            <FormTextField
                type="textarea"
                field={fieldName('activeBanks')}
                label={'Name of bank for which the business currently holds an active account'}
            />
            <SelectOptionRadioButton
                options={selectOptions?.transactionVolumes ?? []}
                fieldname={fieldName('yearlyTransactionsId')}
                label="Anticipated yearly number of transactions"
            />
            <SelectOptionRadioButton
                options={selectOptions?.monthlyUsdEquivalents ?? []}
                fieldname={fieldName('monthlyUsdValueId')}
                label="Anticipated monthly US dollar equivalent"
            />
        </>
    );
};

const fieldName = (name: string) => `operationsInformation.${name}`;
