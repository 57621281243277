import React, { createContext, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { Transactions } from 'components/transactionsTable/Transactions';
import { endpoints } from 'endpoints.config';
import { ActivateCard } from './ActivateCard';
import { ReplaceCard } from './ReplaceCard';
import instance, { ApiResponse } from 'api';
import { useDispatch } from 'react-redux';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { DeactivateCard } from './DeactivateCard';
import { TopUpCard } from './TopUpCard';
import { ChangePin } from './ChangePin';
import { CardDetails } from './CardDetails';

type Props = {
    cardIdNumber: string;
    sub_page: string;
} & RouteComponentProps;

export enum CardSubPage {
    TRANSACTIONS = 'card-transaction-history',
    ACTIVATE = 'activate-card',
    REPLACE = 'order-replacement',
    TOP_UP = 'top-up',
    CHANGE_PIN = 'change-pin',
    CARD_DETAILS = 'details',
    DEACTIVATE = 'deactivate-card',
}

export type CardDetails = {
    nameOnCard: string;
    cardNumber: string;
    expiryDate: string;
    cardIssuer: string;
    bHasPin: boolean;
    bHasCvc: boolean;
    bHasFullCardNumber: boolean;
    cvc?: number;
    pin?: number | null;
    cardNumberFull?: number;
};

type CardContext = {
    cardDetails?: CardDetails;
    setCardDetails?: React.Dispatch<React.SetStateAction<CardDetails | null>>;
    resetCardDetails: () => void;
    cardId?: number;
};
export const CardContext = createContext<CardContext>({ resetCardDetails: () => {} });

const CardPage: React.FC<Props> = ({ cardIdNumber, sub_page, navigate }) => {
    const cardId = parseInt(cardIdNumber);
    const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);
    const dispatch = useDispatch();

    const resetCardDetails = () => {
        if (!cardDetails) return;
        setCardDetails({
            ...cardDetails,
            cardNumberFull: undefined,
            cvc: undefined,
            pin: undefined,
        });
    };
    // Reset card details after timeout
    useEffect(() => {
        if (!cardDetails || !cardDetails?.cardNumberFull) return;

        const id = setTimeout(() => {
            resetCardDetails();
        }, 1000 * 60);

        return () => clearTimeout(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardDetails?.cardNumberFull]);

    useEffect(() => {
        instance
            .get<ApiResponse<Omit<CardDetails, 'cvc' | 'pin' | 'cardNumberFull'>>>(
                endpoints.cards.cardDetails,
                {
                    params: { topUpCardsId: cardId },
                    withCredentials: false,
                }
            )
            .then((res) => {
                setCardDetails(res.data.details);
            })
            .catch((err) => {
                Toast.openToastMessage(
                    'There was an error retrieving your card details',
                    ToastMessageReason.ERROR
                );
            });
    }, [cardId, dispatch]);

    const handleCardToppedUp = () => {
        navigate && navigate('../card-transaction-history');
    };

    return (
        <CardContext.Provider
            value={{
                cardDetails: cardDetails ?? undefined,
                setCardDetails,
                resetCardDetails,
                cardId,
            }}
        >
            {
                sub_page === CardSubPage.TRANSACTIONS ? (
                    <Transactions accountId={cardIdNumber} pageType="TopUpCard" />
                ) : sub_page === CardSubPage.ACTIVATE ? (
                    <ActivateCard cardId={cardId} />
                ) : sub_page === CardSubPage.REPLACE ? (
                    <ReplaceCard cardId={cardId} />
                ) : sub_page === CardSubPage.TOP_UP ? (
                    <TopUpCard cardId={cardId} onCardToppedUp={handleCardToppedUp} />
                ) : sub_page === CardSubPage.DEACTIVATE ? (
                    <DeactivateCard cardId={cardId} />
                ) : sub_page === CardSubPage.CHANGE_PIN ? (
                    <ChangePin cardId={cardId} />
                ) : sub_page === CardSubPage.CARD_DETAILS ? (
                    <CardDetails />
                ) : (
                    <Redirect to={'/transactions'} noThrow />
                ) // default to showing transactions page if URL not correct. TODO add notfoundpage?
            }
        </CardContext.Provider>
    );
};

export default CardPage;
