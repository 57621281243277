import instance, { isAxiosErrorHandled } from 'api';
import { Modal } from 'components/modal/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import Button from 'components/button/Button';
import { AxiosRequestConfig } from 'axios';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { endpoints } from 'endpoints.config';
import UploadIcon from 'assets/ibanera/Icon_Upload.png';
import { useResetableDropzone } from 'helpers/useResetableDropzone';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { Form, Formik, FormikHelpers } from 'formik';
import FormTextField from 'components/form/FormTextField';
import { FileError } from 'react-dropzone';

export const UploadDocumentFlowType = {
    BusinessForm: 'BUSINESS_FORM',
    BusinessFormV2: 'BUSINESS_FORM_V2',
    BusinessFormV3: 'BUSINESS_FORM_V3',
    LinkedCustomer: 'LINKED_CUSTOMER',
} as const;
export type UploadDocumentFlowType =
    (typeof UploadDocumentFlowType)[keyof typeof UploadDocumentFlowType];

type FormValues = {
    note: string;
};
const initialValues: FormValues = {
    note: '',
};
export const UploadDocumentModal = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);
    const { acceptedFiles, getRootProps, getInputProps, resetFiles } = useResetableDropzone({
        multiple: false,
        accept: 'application/pdf',
        validator: (file) => {
            // https://stackoverflow.com/questions/20690499/concrete-javascript-regular-expression-for-accented-characters-diacritics
            var validFilename =
                /^[ a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F0-9_.@()-]+\.[^.]+$/i.test(
                    file.name
                );
            if (!validFilename)
                return {
                    message: `Invalid file name`,
                } as FileError;

            if (file.name.length > 200)
                return {
                    message: `Filename too long. Cannot be greater than 200 characters`,
                } as FileError;

            return null;
        },
        onDropRejected: (rejections) => {
            if (
                rejections.some((reject) =>
                    reject.errors.some((error) => error.code === 'file-invalid-type')
                )
            ) {
                setErrorMessage('File type must be .pdf');
            } else if (
                rejections.length > 0 &&
                rejections[0].errors.length > 0 &&
                rejections[0].errors[0].message
            ) {
                setErrorMessage(rejections[0].errors[0].message);
            } else setErrorMessage('File rejected - please upload a valid .pdf file');
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => setErrorMessage(''), [acceptedFiles]); //reset error message when files change

    if (modalState.modalType !== ModalTypes.UPLOAD_BUSINESS_DOCUMENT) return null;

    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!acceptedFiles || acceptedFiles.length < 1)
            return setErrorMessage('Please upload a file');
        setIsSubmitting(true);
        setErrorMessage('');
        const formData = new FormData();
        formData.append('documentId', modalState.data.documentId.toString());
        formData.append('file', acceptedFiles[0]);
        formData.append('note', values.note);

        const config: AxiosRequestConfig = {
            headers: { 'content-type': 'multipart/form-data' },
        };

        instance
            .post(endpoints.businessModule.uploadDocument, formData, config)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status === '1') {
                        modalState.data.reloadTable();
                        setIsSubmitting(false);

                        dispatch(closeModal());
                    }
                }
            })
            .catch((err) => {
                let translatedErrorMessage = getErrorMessage('Generic');
                // Generic error

                if (isAxiosErrorHandled(err) && err.response.data.errors) {
                    err.response.data.errors.forEach((error) => {
                        if (error.messageCode in ERROR_CODES)
                            translatedErrorMessage = getErrorMessage(error.messageCode);
                    });
                }
                if (isAxiosErrorHandled(err) && err.response.status === 413) {
                    translatedErrorMessage = 'File too large';
                }

                setErrorMessage(translatedErrorMessage);
                setIsSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <Modal
                    title={`Upload ${modalState.data.documentName}`}
                    customButtons={
                        <div className="ModalNavigation">
                            <Button
                                priority="secondary"
                                type="button"
                                onClick={() => {
                                    dispatch(closeModal());
                                }}
                            >
                                Cancel
                            </Button>
                            <Button priority="primary" type="submit" disabled={isSubmitting}>
                                Upload
                            </Button>
                        </div>
                    }
                >
                    <div className="UploadDocumentModal">
                        <p>Please upload your {modalState.data.documentName} below</p>
                        <div {...getRootProps({ className: 'Dropzone' })}>
                            <input {...getInputProps()} />
                            {acceptedFiles[0] ? (
                                <span>
                                    {acceptedFiles[0].name} -{' '}
                                    {roundToPrecision(acceptedFiles[0].size / 1000, 2)} kB
                                </span>
                            ) : (
                                <>
                                    <img src={UploadIcon} alt="upload" />
                                    <h5>PDF file</h5>
                                    <span>Click or drag to upload</span>
                                </>
                            )}
                        </div>
                        <div className="DropzoneFooter">
                            <span>Accepts - .pdf</span>
                            {acceptedFiles.length > 0 && (
                                <Button
                                    priority="secondary"
                                    className="ResetBtn"
                                    onClick={resetFiles}
                                >
                                    Reset
                                </Button>
                            )}
                        </div>
                        <FormTextField
                            field={'note'}
                            label={'Notes'}
                            type={'textarea'}
                            required={false}
                            inputProps={{ rows: 3, style: { resize: 'vertical' } }}
                        ></FormTextField>
                        {errorMessage && <p className="ErrorText NoMargin">{errorMessage}</p>}
                    </div>
                </Modal>
            </Form>
        </Formik>
    );
};
