import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { useEffect, useMemo } from 'react';
import { useVerificationContext } from '../helpers/verificationContext';
import { FormValues } from '../schema';
import { FormDateField } from 'components/form/FormDateField';
import { useFormikContext } from 'formik';
import { useAfterMountEffect } from '../../../helpers/useAfterMountEffect';

export const RegistrationInformation = () => {
    const { selectOptions } = useVerificationContext();
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const availableStates = useMemo(() => {
        return (
            selectOptions?.availableCountries
                ?.find(
                    (country) => country.isoCode === values.registrationInformation.addressCountry
                )
                ?.states?.map((state) => ({
                    label: state.stateProvinceName,
                    value: state.ansiCode,
                })) ?? null
        );
    }, [values.registrationInformation.addressCountry, selectOptions?.availableCountries]);
    const availableOperatingStates = useMemo(() => {
        return (
            selectOptions?.availableCountries
                ?.find(
                    (country) =>
                        country.isoCode === values.registrationInformation.operatingAddressCountry
                )
                ?.states?.map((state) => ({
                    label: state.stateProvinceName,
                    value: state.ansiCode,
                })) ?? null
        );
    }, [values.registrationInformation.operatingAddressCountry, selectOptions?.availableCountries]);
    const availableCountries = useMemo(
        () =>
            selectOptions?.availableCountries?.map((country) => ({
                label: country.countryName,
                value: country.isoCode,
            })) ?? [],
        [selectOptions?.availableCountries]
    );
    const isOtherEntityType = useMemo(
        () =>
            values.registrationInformation.entityTypesID &&
            values.registrationInformation.entityTypesID ==
                selectOptions?.entityTypes?.find((type) => type.label === 'Other')?.value,
        [values.registrationInformation.entityTypesID, selectOptions?.entityTypes]
    );
    useEffect(() => {
        if (!isOtherEntityType) setFieldValue(fieldName('entityTypesOther'), null);
    }, [isOtherEntityType, setFieldValue]);

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(fieldName('addressState'), '');
    }, [values.registrationInformation.addressCountry]);
    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(fieldName('operatingAddressState'), '');
    }, [values.registrationInformation.operatingAddressCountry]);

    return (
        <>
            <h2>Entity Registration Information</h2>
            <h3>Business Registration Information</h3>
            <FormTextField
                field={fieldName('entityName')}
                label={'Legal Name of Entity'}
                tooltip={
                    'The name in which the entity was formed, it should be listed on the formation documents.  This name needs to be accurate, please enter the name as it appears on the formation documents (or official name change documents)  to ensure banking parnters process funds.'
                }
            />
            <div className="FormSideBySide">
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={fieldName('entityTypesID')}
                        options={selectOptions?.entityTypes ?? []}
                        label={'Type of Entity'}
                    />
                </div>

                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={fieldName('registrationDate')}
                        label={'Date of Entity Registration'}
                        tooltip="Please enter the date that the entity was registered in it's jurisdiction. Example:  In the US, a new business in Delware will register with Deleware State, please enter the date of that registration. "
                    />
                </div>
            </div>
            {isOtherEntityType && (
                <FormTextField field={fieldName('entityTypesOther')} label={'Entity Type'} />
            )}
            <div className="FormSideBySide">
                <FormTextField
                    field={fieldName('registrationNumber')}
                    label={'Official Business Registration Number'}
                    sideBySide
                    tooltip="Please enter the number provided by the government agency when the entity was registered"
                />
                <FormTextField field={fieldName('taxNumber')} sideBySide label={'Tax ID Number'} />
            </div>
            <FormTextField
                type="textarea"
                field={fieldName('doingBusinessAs')}
                label={'Doing Business As - DBA (Please list all business names.)'}
                tooltip={
                    <>
                        Please enter all names the business uses to operate or advertise. Example:
                        Google operates as "Google" but is owned by ABC. We'd need both names."
                    </>
                }
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={fieldName('addressStreet')}
                    label={'Registered Address Street'}
                    sideBySide
                />
                <FormTextField
                    field={fieldName('addressNumber')}
                    label={'Registered Address Unit Number'}
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={fieldName('addressPostCode')}
                    label={'Registered Address Postal Code'}
                    sideBySide
                />
                <FormTextField
                    field={fieldName('addressCity')}
                    label={'Registered Address City'}
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                {availableStates ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName={fieldName('addressState')}
                            options={availableStates}
                            label={
                                'Registered Address State / County / Province (as applicable to the jurisdiction)'
                            }
                        />
                    </div>
                ) : (
                    <FormTextField
                        field={fieldName('addressState')}
                        label={
                            'Registered Address State / County / Province (as applicable to the jurisdiction)'
                        }
                        sideBySide
                    />
                )}
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={fieldName('addressCountry')}
                        options={availableCountries}
                        label={'Registered Country'}
                    />
                </div>
            </div>
            <h3>Business Operating Information (If different than registerd information.)</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={fieldName('operatingAddressStreet')}
                    label={'Operating Address Street'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
                <FormTextField
                    field={fieldName('operatingAddressNumber')}
                    label={'Operating Address Unit Number'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={fieldName('operatingAddressPostCode')}
                    label={'Operating Address Postal Code'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
                <FormTextField
                    field={fieldName('operatingAddressCity')}
                    label={'Operating City'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                {availableOperatingStates ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName={fieldName('operatingAddressState')}
                            options={availableOperatingStates}
                            label={
                                'Operating State / County / Province (as applicable to the jurisdiction)'
                            }
                        />
                    </div>
                ) : (
                    <FormTextField
                        field={fieldName('operatingAddressState')}
                        label={
                            'Operating State / County / Province (as applicable to the jurisdiction)'
                        }
                        labelExtraInfo="Optional"
                        sideBySide
                    />
                )}
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={fieldName('operatingAddressCountry')}
                        options={availableCountries}
                        label={'Operating Country'}
                        labelExtraInfo="Optional"
                    />
                </div>
            </div>
        </>
    );
};

const fieldName = (name: string) => `registrationInformation.${name}`;
