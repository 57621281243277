import React from 'react';
import { CreateBusinessProfile } from './CreateBusinessProfile';
import { CreatePersonalProfile } from './CreatePersonalProfile';
import { NotificationsPage } from './NotificationsPage';
import { ProfileDetailsPage } from './ProfileDetailsPage';
import { Security } from './Security';

export enum ProfileSubPage {
    CREATE_BUSINESS_ACCOUNT = 'registerbusinessaccount',
    CREATE_PERSONAL_ACCOUNT = 'create-personal-account',
    SECURITY = 'security',
    NOTIFICATIONS = 'notifications',
    PROFILE = 'profile',
}

interface ProfilePageProps {
    subPage: ProfileSubPage;
}

export const ProfilePage: React.FC<ProfilePageProps> = ({ subPage }) => {
    return (
        <div className="ProfilePage">
            {subPage === ProfileSubPage.CREATE_PERSONAL_ACCOUNT ? (
                <CreatePersonalProfile />
            ) : subPage === ProfileSubPage.CREATE_BUSINESS_ACCOUNT ? (
                <CreateBusinessProfile />
            ) : subPage === ProfileSubPage.SECURITY ? (
                <Security />
            ) : subPage === ProfileSubPage.NOTIFICATIONS ? (
                <NotificationsPage />
            ) : subPage === ProfileSubPage.PROFILE ? (
                <ProfileDetailsPage />
            ) : null}
        </div>
    );
};
