import React from 'react';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'reducers/auth';
import {
    selectVerificationDetailsV1,
    selectVerificationLoading,
    setVerificationStage,
} from 'reducers/verification';
import { BusinessVerificationStage, getStageFromBackend } from './businessVerificationModels';
import { Spinner } from 'components/spinner/Spinner';
import { Toast, ToastMessageReason } from 'helpers/toast';
import BusinessVerificationTerms from './components/BusinessVerificationTerms';
import BusinessVerificationSubmit, { submitForm } from './components/BusinessVerificationSubmit';
import BusinessVerificationDocuments from './components/BusinessVerificationDocuments';
import SignificantParties from './components/significantParties/SignificantParties';
import { Form, Formik } from 'formik';
import {
    FormValues,
    documentUploadValidationSchema,
    initialValue,
    validationSchemaConfirmation,
    validationSchemaTerms,
} from './businessVerificationSchema';
import CorporateApp from './CorporateApp/CorporateApp';
import { processedErrors, saveContinue } from './helpers/ExitContinueButtons';
import { validationSchemaSignificantParties } from './components/significantParties/significantPartiesModel';
import {
    validationSchemaAnticipatedActivity,
    validationSchemaBusinessPartners,
    validationSchemaFunding,
    validationSchemaMainActivity,
    validationSchemaOperatingAddress,
    validationSchemaOtherAccounts,
    validationSchemaRepresentative,
} from './CorporateApp/corporateAppModel';
import api, { isAxiosErrorHandled } from 'api';
import validate from './helpers/validate';

const PendingScreen = () => {
    return (
        <div className="Waiting">
            <Spinner />
            <h3>Please wait, we are checking your details</h3>
            <p>This may take a few minutes, we'll email you when it's done.</p>
        </div>
    );
};

export interface DocumentRejection {
    proofOfAddress: string[] | null;
    authorizedCapitalDocument: string | null;
    fundsSupportingDocuments: string[] | null;
    bDocumentsUpload: boolean;
}

export const BusinessVerification = () => {
    const dispatch = useDispatch();
    //rejectedDocuments
    const { stage, rejectedDocuments } = useSelector(selectVerificationDetailsV1); //additionalData to have the stored form data for current stage?
    const loading = useSelector(selectVerificationLoading);
    //scroll to state
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [submitErrorField, setSubmitErrorField] = useState<string>('');
    //persist error state
    const [submitValues, setSubmitValues] = useState<FormValues>();
    const [apiErrors, setApiErrors] = useState<processedErrors>([]);
    const [bManualValidate, setBManualValidate] = useState<boolean>(true);
    //fethcing form data state
    const [fetchingFormData, setFetchingFormData] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    const [initialValues, setInitialValues] = useState<any>(null);
    //docuemnt rejection state for testing
    // const [rejectedDocuments, setRejectDocuments] = useState<DocumentRejection | null>({
    //     proofOfAddress: ['DMV-Affidavit-of-Residence-Letter - Copy (2).pdf'],
    //     authorizedCapitalDocument: null,
    //     fundsSupportingDocuments: [
    //         'DMV-Affidavit-of-Residence-Letter - Copy (2) - Copy - Copy.pdf',
    //     ],
    //     bDocumentsUpload: true,
    // });

    useEffect(() => {
        if (stage) {
            if (
                // stage !== BusinessVerificationStage.Terms &&
                stage !== BusinessVerificationStage.Complete &&
                !dataFetched
            ) {
                (async () => {
                    try {
                        const response = await api.post(
                            endpoints.businessverificationmodule.getFormData
                        );
                        if (response.data.status === '1') {
                            setInitialValues({
                                ...response.data.details.formData,
                                businessVerificationStep: getStageFromBackend(
                                    response.data.details.formData.businessVerificationStep
                                ),
                            });
                            // dispatch( // we get this elsewhere, otherwise it still upodates when a step fails
                            //     setVerificationStage({
                            //         currentStage:
                            //             response.data.details.formData.businessVerificationStep,
                            //     })
                            // );
                            // setSubmitValues({ ...response.data.details.formData }); used to test rejected docuemnts error handling
                            setDataFetched(true);
                        } else if (response.data.status === '0') {
                            Toast.openGenericErrorToast();
                            dispatch(signOut());
                        }
                    } catch (error: any) {
                        if (isAxiosErrorHandled(error) && error.response.status === 404) {
                            setDataFetched(true);
                        } else {
                            Toast.openToastMessage(
                                'Something went wrong checking your verification status, please log in again',
                                ToastMessageReason.ERROR
                            );
                            dispatch(signOut());
                        }
                    }
                    setFetchingFormData(false);
                })();
            } else {
                setFetchingFormData(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stage]);

    useEffect(() => {
        if (!rejectedDocuments) return;
        if (rejectedDocuments?.proofOfAddress) {
            dispatch(
                setVerificationStage({
                    currentStage: BusinessVerificationStage.SignificantParties,
                })
            );
            setBManualValidate(true);
            if (!submitValues && initialValues) {
                setSubmitValues({ ...initialValues });
            }
        }
        if (
            rejectedDocuments?.authorizedCapitalDocument ||
            rejectedDocuments?.fundsSupportingDocuments
        ) {
            dispatch(
                setVerificationStage({
                    currentStage: BusinessVerificationStage.OperatingAddress,
                })
            );
            setBManualValidate(true);
            if (!submitValues && initialValues) {
                setSubmitValues({ ...initialValues });
            }
        }
        if (rejectedDocuments?.bDocumentsUpload) {
            dispatch(
                setVerificationStage({
                    currentStage: BusinessVerificationStage.Documents,
                })
            );
            setBManualValidate(true);
            if (!submitValues && initialValues) {
                setSubmitValues({ ...initialValues });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejectedDocuments, stage]);

    useEffect(() => {
        if (!initialValues) {
            setInitialValues(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return null;
    }
    type ValidationSchemas = {
        [key in BusinessVerificationStage]: any;
    };

    const verificationValidationSchema: ValidationSchemas = {
        [BusinessVerificationStage.Terms]: validationSchemaTerms,
        [BusinessVerificationStage.SignificantParties]: validationSchemaSignificantParties,
        [BusinessVerificationStage.Documents]: documentUploadValidationSchema,
        [BusinessVerificationStage.Submit]: validationSchemaConfirmation,
        [BusinessVerificationStage.Complete]: null,
        [BusinessVerificationStage.Verified]: null,
        [BusinessVerificationStage.OperatingAddress]: validationSchemaOperatingAddress,
        [BusinessVerificationStage.CompanyRep]: validationSchemaRepresentative,
        [BusinessVerificationStage.MainBusinessPartners]: validationSchemaBusinessPartners,
        [BusinessVerificationStage.MainActivity]: validationSchemaMainActivity,
        [BusinessVerificationStage.Funding]: validationSchemaFunding,
        [BusinessVerificationStage.OtherBankAccounts]: validationSchemaOtherAccounts,
        [BusinessVerificationStage.AnticipatedActivity]: validationSchemaAnticipatedActivity,
    };

    const chooseStage = (values: any) => {
        switch (stage) {
            case BusinessVerificationStage.Terms:
                return (
                    <BusinessVerificationTerms
                        isRepeatSubmission={
                            !!(initialValues as FormValues)?.significantParties?.[0]?.firstName
                        }
                    />
                );
            case BusinessVerificationStage.SignificantParties:
                return (
                    <SignificantParties
                        values={values}
                        submitError={submitError}
                        submitErrorField={submitErrorField}
                        setSubmitError={setSubmitError}
                        rejectedDocuments={rejectedDocuments}
                    />
                );
            case BusinessVerificationStage.OperatingAddress:
            case BusinessVerificationStage.CompanyRep:
            case BusinessVerificationStage.MainBusinessPartners:
            case BusinessVerificationStage.MainActivity:
            case BusinessVerificationStage.Funding:
            case BusinessVerificationStage.OtherBankAccounts:
            case BusinessVerificationStage.AnticipatedActivity:
                return (
                    <CorporateApp
                        values={values}
                        submitError={submitError}
                        submitErrorField={submitErrorField}
                        setSubmitError={setSubmitError}
                        rejectedDocuments={rejectedDocuments}
                        currentStage={stage}
                    />
                );
            case BusinessVerificationStage.Documents:
                return <BusinessVerificationDocuments />;
            case BusinessVerificationStage.Submit:
                return <BusinessVerificationSubmit />;
            case BusinessVerificationStage.Complete:
                return <BusinessVerificationSubmit completedSubmit={true} />;
        }
    };

    return (
        <React.Fragment>
            {loading || fetchingFormData ? (
                <PendingScreen />
            ) : stage && stage === BusinessVerificationStage.Terms ? (
                <BusinessVerificationTerms
                    isRepeatSubmission={
                        !!(initialValues as FormValues)?.significantParties?.[0]?.firstName
                    }
                />
            ) : (
                stage && (
                    <Formik
                        initialValues={initialValues}
                        {...(apiErrors.length > 0 || bManualValidate
                            ? {
                                  validate: (values) =>
                                      validate(
                                          values,
                                          submitValues,
                                          apiErrors,
                                          verificationValidationSchema[
                                              stage as BusinessVerificationStage
                                          ],
                                          rejectedDocuments,
                                          stage as BusinessVerificationStage
                                      ),
                              }
                            : {
                                  validationSchema:
                                      verificationValidationSchema[
                                          stage as BusinessVerificationStage
                                      ],
                              })}
                        onSubmit={(values, helper) =>
                            stage === BusinessVerificationStage.Submit
                                ? submitForm(
                                      values,
                                      helper,
                                      dispatch,
                                      setSubmitErrorField,
                                      setApiErrors
                                  )
                                : saveContinue(
                                      values,
                                      helper,
                                      dispatch,
                                      stage as BusinessVerificationStage,
                                      setSubmitError,
                                      setSubmitErrorField,
                                      setSubmitValues,
                                      setApiErrors
                                  )
                        }
                    >
                        {({ values }) => <Form>{chooseStage(values)}</Form>}
                    </Formik>
                )
            )}
        </React.Fragment>
    );
};
