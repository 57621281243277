import allFilesHaveUniqueNames from 'components/businessVerification/helpers/validateFiles';

import React, { useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions, DropEvent } from 'react-dropzone';
import logger from './logger';
import { useSelector } from 'react-redux';
import { selectAppSettings } from 'reducers/appSettings';

export const useResetableDropzone = (
    options: DropzoneOptions = {},
    fieldName?: string,
    values?: any,
    setErrorMessage?: React.SetStateAction<any>,
    meta?: any,
    helpers?: any,
    jsonField?: boolean,
    saveFile?: (file: File[]) => Promise<boolean | void>
) => {
    const { businessVerificationFlow } = useSelector(selectAppSettings);
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const onDropAccepted = useCallback(
        (files: File[], dropEvent: DropEvent) => {
            const handleSaveFile = async (files: File[], multi: boolean = false) => {
                if (saveFile) {
                    let saveSuccess = await saveFile(files); // Await the async operation
                    if (saveSuccess && !multi) {
                        helpers.setValue(files[0].name);
                        setAcceptedFiles(files);
                    } else if (saveSuccess && multi) {
                        let nextIndex = meta.value ? meta.value.length + 1 : 1;
                        const fieldFile = files.map((file) => ({
                            number: nextIndex,
                            fileName: file.name,
                        }));
                        meta.value
                            ? helpers.setValue([...meta.value, ...fieldFile])
                            : helpers.setValue([...fieldFile]);
                        setAcceptedFiles((curr) => [...curr, ...files]);
                    }
                }
            };

            if (options.multiple) {
                if (jsonField && meta && helpers && fieldName && values) {
                    if (allFilesHaveUniqueNames(files, values, fieldName, meta.value)) {
                        handleSaveFile(files, true);
                    } else {
                        setErrorMessage(
                            `${
                                files.length > 1 ? 'Files' : 'File'
                            } not accepted, file names must be unique.`
                        );
                    }
                } else if (meta && helpers) {
                    // setAcceptedFiles((curr) => [...curr, ...files]);
                    // let fieldValues = meta.value;
                    // let nextIndex = fieldValues ? fieldValues.length + 1 : 0;
                    // helpers.setValue([...acceptedFiles, ...files]);
                    // const fieldFile = files.map((file) => ({
                    //     number: nextIndex,
                    //     fileName: file.name,
                    //     //Add Id from response
                    // }));
                    // helpers.setValue([...fieldValues, ...fieldFile]);
                    logger.log("shouldn't hit");
                }
            } else {
                // setAcceptedFiles(files);
                if (jsonField && saveFile && helpers && fieldName && values) {
                    if (
                        allFilesHaveUniqueNames(files, values, fieldName, businessVerificationFlow)
                    ) {
                        handleSaveFile(files);
                    } else {
                        setErrorMessage(
                            'File name must be unique, this file has already been uploaded'
                        );
                    }
                } else {
                    if (
                        fieldName &&
                        values &&
                        allFilesHaveUniqueNames(files, values, fieldName, businessVerificationFlow)
                    ) {
                        setAcceptedFiles(files);
                        setErrorMessage('');
                        // helpers.setValue(files[0]);
                    } else {
                        setErrorMessage(
                            'File name must be unique, this file has already been uploaded'
                        );
                    }
                }
            }
            options.onDropAccepted && options.onDropAccepted(files, dropEvent);
        },
        [options]
    );
    const dropzoneValues = useDropzone({ ...options, onDropAccepted });

    const resetFiles = useCallback(() => {
        setAcceptedFiles([]);
        //fieldName && formik.setFieldValue(fieldName, options.multiple ? [] : null);
        helpers && helpers.setValue(null);
    }, []);

    return { ...dropzoneValues, acceptedFiles, resetFiles, setAcceptedFiles };
};
