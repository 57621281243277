import instance, { isErrorHandled } from 'api';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Toast } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';

const initialValues = { tfaCode: '' };
const validationSchema = Yup.object({
    tfaCode: Yup.string().required('Please enter your two-factor authentication code'),
});

export const DeactivateAccountModal = () => {
    const [tfaType, toggleTfaType, reSendTfaCode] = useTFAField();
    const dispatch = useDispatch();
    const [isConfirming, setConfirming] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        if (!isConfirming) {
            setConfirming(true);
            helpers.setSubmitting(false);
            return;
        }
        instance
            .post(endpoints.profilemodule.disableAccount, { ...values, tfaType })
            .then((res) => {
                Toast.openSuccessToast('Account disabled');
                dispatch(closeModal());
                dispatch(signOut());
            })
            .catch((err) => {
                setConfirming(false);
                if (isErrorHandled(err)) {
                    helpers.setSubmitting(false);
                    const fieldErrors = err.response.data.errors.filter(
                        (error) => toCamelCase(error.fieldName) === 'tfaCode'
                    );
                    if (fieldErrors.length > 0) {
                        helpers.setFieldError(
                            'tfaCode',
                            getErrorMessage(fieldErrors[0].messageCode)
                        );
                    } else if (
                        err.response.data.errors.length > 0 &&
                        err.response.data.errors.some(
                            (err: any) => err.errorType === 'FundsInAccount'
                        )
                    ) {
                        const error = err.response.data.errors.find(
                            (err: any) => err.errorType === 'FundsInAccount'
                        )!;

                        if (error.messageCode === 'Invalid') {
                            helpers.setFieldError(
                                'tfaCode',
                                'Unable to deactivate account. You still have funds in your account'
                            );
                        } else {
                            Toast.openGenericErrorToast();
                        }
                    } else {
                        setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                    }
                } else {
                    Toast.openGenericErrorToast();
                    dispatch(closeModal());
                }
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => (
                <Form>
                    <Modal
                        title="Deactivate Account"
                        customButtons={
                            !isConfirming ? (
                                <div className="ModalNavigation">
                                    <Button
                                        priority="secondary"
                                        type="button"
                                        onClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        priority="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting || !formikProps.isValid}
                                    >
                                        Update
                                    </Button>
                                </div>
                            ) : (
                                <div className="ModalNavigation">
                                    <Button
                                        priority="secondary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting || !formikProps.isValid}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        priority="primary"
                                        type="button"
                                        onClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            )
                        }
                    >
                        {!isConfirming ? (
                            <TFAField
                                field="tfaCode"
                                label={'TFA code'}
                                required={true}
                                toggleTFAType={toggleTfaType}
                                tfaType={tfaType}
                                handleResendCode={reSendTfaCode}
                                autoFocus
                                holdFocus
                            />
                        ) : (
                            <div>
                                <h3>Deactivate account</h3>
                                <p>Are you sure you want to deactivate your account?</p>
                                <p>You can reactivate your account at any time</p>
                            </div>
                        )}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
