import React from 'react';
import { SummaryItem } from './SummaryItem';

type CryptoContentProps = {
    subject: string;
    name: string;
    ticker: string;
    summaryItems?: any;
    description?: string;
    link?: boolean;
};

const CryptoContent: React.FC<CryptoContentProps> = ({
    subject,
    name,
    ticker,
    summaryItems,
    description,
    link,
}) => {
    return (
        <div className="CryptoContent">
            <h2>{`${subject} ${name} (${ticker})`}</h2>
            {summaryItems && (
                <div className="SummaryContainer">
                    {summaryItems.map((i: any) => (
                        <SummaryItem title={i.title} content={i.content} value={i.value} />
                    ))}
                </div>
            )}
            {description && <p>{description}</p>}
        </div>
    );
};

export { CryptoContent };
