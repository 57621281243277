import {
    BackendBusinessVerificationStage,
    BusinessStepsOrder,
    BusinessVerificationStage,
    getPreviousStage,
} from '../businessVerificationModels';
import { setVerificationStage } from 'reducers/verification';
import instance from '../../../api';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { css, useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { endpoints } from 'endpoints.config';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useState } from 'react';
/** @jsxImportSource @emotion/react */
interface Props {
    disable?: boolean;
    currentStage: BusinessVerificationStage;
}

const debounced = debounce((callback: () => void) => {
    callback();
}, 500);

const BackButton: React.FC<Props> = ({ disable, currentStage }) => {
    const [isGoingBack, setIsGoingBack] = useState(false);
    const theme = useTheme();
    const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();
    const buttonColor = css`
        color: ${theme.colors.first};
    `;
    //Do I need to call endpiont?
    const handleBack = () => {
        if (isGoingBack) return;
        setIsGoingBack(true);
        (async () => {
            try {
                // only call endpoint if we're at a stage that the BE knows about
                if (isFrontendOnlyStage(currentStage)) {
                    setFieldValue('businessVerificationStep', getPreviousStage(currentStage));
                    return setIsGoingBack(false);
                }
                const res = await instance.post(endpoints.businessverificationmodule.backForm);
                if (res.data.status === '1') {
                    setFieldValue('businessVerificationStep', getPreviousStage(currentStage));
                    dispatch(
                        setVerificationStage({ currentStage: getPreviousStage(currentStage) })
                    );
                }
            } catch (error) {
                Toast.openToastMessage(
                    'an error occured, please try again later',
                    ToastMessageReason.ERROR
                );
            }
            setIsGoingBack(false);
        })();
    };

    return (
        <button
            css={buttonColor}
            type="button"
            className="Back"
            onClick={() => handleBack()}
            disabled={disable || isGoingBack}
        >
            {'< Back'}
        </button>
    );
};

export default BackButton;

const isFrontendOnlyStage = (stage: BusinessVerificationStage) => {
    return !Object.values(BackendBusinessVerificationStage).includes(stage as any);
};
