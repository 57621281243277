import instance, { ApiResponse, isErrorHandled, retrieveErrorMessages } from 'api';
import Button from 'components/button/Button';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Toast } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';

enum UpdateAppTFAStep {
    CURRENT_TFA = 1,
    SHOW_NEW_DETAILS,
    CONFIRM_NEW,
}

type UpdateAppTFAState = {
    sharedSecret: string;
    qrCode: string;
};

const initialValues = {
    tfaCode: '',
    changeTfaType: 'AuthenticatorApp',
    totpSharedSecret: '',
};

const validationSchema = Yup.object({
    tfaCode: Yup.string().required('Please enter your two-factor authentication code'),
});

export const UpdateAppTfaModal = () => {
    const [currentStep, setCurrentStep] = useState<UpdateAppTFAStep>(UpdateAppTFAStep.CURRENT_TFA);
    const [updateAppTFA, setUpdateAppTFA] = useState<UpdateAppTFAState | null>(null);
    const [tfaType, toggleTFAType] = useTFAField(currentStep === UpdateAppTFAStep.CURRENT_TFA);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        if (currentStep === UpdateAppTFAStep.CURRENT_TFA) {
            const { tfaCode } = values;
            instance
                .post<ApiResponse<UpdateAppTFAState>>(endpoints.profilemodule.updateAppTfa, {
                    tfaType,
                    tfaCode,
                })
                .then((res) => {
                    setUpdateAppTFA(res.data.details);
                    setCurrentStep(UpdateAppTFAStep.SHOW_NEW_DETAILS);
                    setErrorMessage('');
                    helpers.resetForm();
                })
                .catch((err) => {
                    if (isErrorHandled(err)) {
                        const fieldErrors = err.response.data.errors.filter(
                            (error) => toCamelCase(error.fieldName) === 'tfaCode'
                        );
                        if (fieldErrors.length > 0) {
                            helpers.setFieldError(
                                'tfaCode',
                                getErrorMessage(fieldErrors[0].messageCode)
                            );
                        } else {
                            setErrorMessage(
                                getErrorMessage(err.response.data.errors[0].messageCode)
                            );
                        }
                    } else {
                        Toast.openGenericErrorToast();
                    }
                })
                .finally(() => helpers.setSubmitting(false));
        } else if (currentStep === UpdateAppTFAStep.CONFIRM_NEW) {
            const { tfaCode } = values;
            instance
                .post(endpoints.profilemodule.updateTfaConfirm, {
                    tfaCode,
                    tfaType: 'AuthenticatorApp',
                    totpSharedSecret: updateAppTFA?.sharedSecret,
                })
                .then((res) => {
                    Toast.openSuccessToast(
                        'You have successfully enabled App two-factor authentication'
                    );
                    dispatch(closeModal());
                })
                .catch((err) => {
                    helpers.setSubmitting(false);
                    if (isErrorHandled(err)) {
                        const fieldErrors = err.response.data.errors.filter(
                            (error) => toCamelCase(error.fieldName) === 'tfaCode'
                        );
                        if (fieldErrors.length > 0) {
                            helpers.setFieldError(
                                'tfaCode',
                                getErrorMessage(fieldErrors[0].messageCode)
                            );
                        } else {
                            setErrorMessage(
                                getErrorMessage(err.response.data.errors[0].messageCode)
                            );
                        }
                    } else {
                        Toast.openGenericErrorToast();
                    }
                });
        }
    };

    return (
        <div className="UpdateAppTFAModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
            >
                {(formikProps) => (
                    <Form>
                        <Modal
                            title="Update App TFA"
                            customButtons={
                                <div className="ModalNavigation">
                                    <Button
                                        priority="secondary"
                                        type="button"
                                        onClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    {currentStep === UpdateAppTFAStep.CURRENT_TFA ? (
                                        <Button
                                            priority="primary"
                                            type="submit"
                                            disabled={formikProps.isSubmitting}
                                            key={'submitStep1'}
                                        >
                                            Next
                                        </Button>
                                    ) : currentStep === UpdateAppTFAStep.SHOW_NEW_DETAILS ? (
                                        <Button
                                            priority="primary"
                                            type="button"
                                            onClick={() => {
                                                setCurrentStep(UpdateAppTFAStep.CONFIRM_NEW);
                                            }}
                                            disabled={formikProps.isSubmitting}
                                            key="dontSubmit" //using keys stops the form from autosubmitting when it goes to the next step
                                        >
                                            Next
                                        </Button>
                                    ) : currentStep === UpdateAppTFAStep.CONFIRM_NEW ? (
                                        <Button
                                            priority="primary"
                                            type="submit"
                                            disabled={formikProps.isSubmitting}
                                            key="submitStep2"
                                        >
                                            Update
                                        </Button>
                                    ) : null}
                                </div>
                            }
                        >
                            {currentStep === UpdateAppTFAStep.CURRENT_TFA ? (
                                <>
                                    <TFAField
                                        field={'tfaCode'}
                                        label={'TFA Code'}
                                        autoComplete={false}
                                        toggleTFAType={toggleTFAType}
                                        tfaType={tfaType}
                                        required
                                        autoFocus
                                        holdFocus
                                    />
                                </>
                            ) : currentStep === UpdateAppTFAStep.SHOW_NEW_DETAILS ? (
                                updateAppTFA ? (
                                    <div>
                                        <img
                                            className="QRCode"
                                            src={'data:image/png;base64, ' + updateAppTFA.qrCode}
                                            alt="qrCode"
                                        />
                                        <p className="SharedSecret">{updateAppTFA.sharedSecret}</p>
                                    </div>
                                ) : null
                            ) : currentStep === UpdateAppTFAStep.CONFIRM_NEW ? (
                                <>
                                    <ValidationCodeField
                                        field={'tfaCode'}
                                        label={'Enter the new code from your App to confirm'}
                                        autoComplete={false}
                                        value={formikProps.values.tfaCode}
                                        required
                                        autoFocus
                                        holdFocus
                                    />
                                </>
                            ) : null}
                            {errorMessage && (
                                <div style={{ width: '100%' }}>
                                    <p className="ErrorText NoMargin">{errorMessage}</p>
                                </div>
                            )}
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
