import { components, OptionProps } from 'react-select';
import { FormSingleSelectFieldProps, WrappedFormSingleSelectField } from './FormSingleSelectField';
import RedX from 'assets/ibanera/Icon_RedCross.png';
import { SelectOption } from './Dropdown';

type Props<T> = {
    onDeleteItem(value: SelectOption): void;
} & FormSingleSelectFieldProps<T>;

export const SingleSelectRemoveOption = <T,>({
    onDeleteItem,
    dropdownProps,
    ...props
}: Props<T>) => {
    const handleDelete = (event: React.MouseEvent<HTMLElement>, value: any) => {
        event.stopPropagation();

        onDeleteItem(value);
    };

    const Option = (props: OptionProps<any, false>) => (
        <div>
            <components.Option
                {...props}
                className={`RemoveOptionContainer ${props.className ?? ''}`}
            >
                {props.data.label}
                <div
                    onClick={(e) => handleDelete(e, props.data)}
                    className={'RemoveOptionContainerImage'}
                >
                    <img src={RedX} alt={'Delete'} className={''} />
                </div>
            </components.Option>
        </div>
    );

    return (
        <WrappedFormSingleSelectField
            dropdownProps={{
                components: {
                    Option,
                },
                ...dropdownProps,
            }}
            {...props}
        />
    );
};
