import { useSelector } from 'react-redux';
import { selectHasInfoRequest } from '../../reducers/infoRequest';
import { Link } from '@reach/router';
import { useBasePath } from '../../helpers/useBasePath';
import { DashboardPath } from '../../appConstants';
import WarningIcon from 'assets/ui-update/warning.svg';
import styles from './InfoRequest.module.scss';

type Props = {
    isLinkActive?: (link: string) => boolean;
    mobile?: boolean;
    onLinkPressed?: () => void;
};

export const InfoRequestNavLink: React.FC<Props> = ({ isLinkActive, onLinkPressed, mobile }) => {
    const basePath = useBasePath();
    const hasInfoRequest = useSelector(selectHasInfoRequest);

    const infoRequestLink = `${basePath}${DashboardPath.INFORMATION_REQUEST}`;
    const infoRequestActive = isLinkActive ? isLinkActive(infoRequestLink) : false;
    return (
        <>
            {hasInfoRequest && (
                <Link
                    onClick={onLinkPressed}
                    className={`${styles.InfoRequestNavLink} ${mobile ? styles.Mobile : ''} ${
                        infoRequestActive ? 'Active' : ''
                    }`}
                    to={infoRequestLink}
                >
                    <img alt="Attention" src={WarningIcon} />
                    <div className={styles.InfoRequestText}>Information request</div>
                </Link>
            )}
        </>
    );
};
