import { Form, Formik } from 'formik';
import { PaymentStepProps } from './MakePayment';
import FormTextField from '../../../../components/form/FormTextField';
import { FileUpload } from '../../../../components/form/FileUpload/FileUpload';
import Button from '../../../../components/button/Button';

type Props = PaymentStepProps & {
    initialValues: Partial<MoreDetailsForm>;
};

export type MoreDetailsForm = {
    memo: string;
    attachments: File[];
    notes: string;
};

export const MoreDetails: React.FC<Props> = ({ initialValues, nextStep }) => {
    const onSubmit = async (values: MoreDetailsForm) => {
        nextStep(values);
    };

    return (
        <Formik
            initialValues={{
                memo: '',
                attachments: [],
                notes: '',
                ...initialValues,
            }}
            onSubmit={onSubmit}
        >
            {({ setFieldError, setFieldTouched, touched }) => (
                <Form>
                    <p className="mb-4">More information related to this payment</p>
                    <FormTextField
                        label="Memo"
                        field="memo"
                        type="textarea"
                        placeholder="Type your text here"
                        maxLength={140}
                        inputProps={{ rows: undefined }}
                        labelExtraInfo="For further credit, for benefit or message to recipient"
                        hint="Maximum memo length is 140 characters"
                    />
                    <FileUpload
                        label="Attachments"
                        fieldName="attachments"
                        emptyUploadTitle="Drag and drop here or click to upload"
                        emptyUploadMessage="You may upload PDF, PNG or JPEG files"
                        multi
                        small
                    />
                    <FormTextField
                        label="Notes"
                        field="notes"
                        type="textarea"
                        placeholder="Type your text here"
                        maxLength={1000}
                        inputProps={{ rows: undefined }}
                        hint="Maximum text length is 1000 characters"
                    />
                    <Button type="submit" color="third">
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
