import React from 'react';
import ManageApprovals from './ManageApprovals';

enum ManageBusinessSubpage {
    Manage = 'manage',
}
type Props = { subPage?: string };

function ManageApprovalsNavigator({ subPage }: Props) {
    switch (subPage) {
        case ManageBusinessSubpage.Manage:
            return <ManageApprovals />;
        default:
            return <ManageApprovals />;
    }
}

export default ManageApprovalsNavigator;
