import React from 'react';
import { TableInfo } from 'api';
import { usePrevious } from '@avamae/hooks';
export function useSaveData<Data>(
    ids: (string | number)[],
    idColumn: string,
    table: TableInfo
): Data[] {
    const [data, setData] = React.useState<Data[]>([]);

    const previousIds = usePrevious(ids);

    React.useEffect(() => {
        const currentEntries = new Set(ids);
        const previousEntries = new Set(previousIds);

        const newEntries = difference(currentEntries, previousEntries);
        const removedEntries = new Set(difference(previousEntries, currentEntries));
        let newData: Data[] = [];

        for (const id of newEntries) {
            const datum = table.data?.details.listData.find(
                (data: any) =>
                    data[idColumn] === id || data[idColumn] === parseInt(id as string, 10)
            );
            if (datum) newData.push(datum as Data);
        }

        setData((prev) => [
            ...prev.filter((d: any) => {
                return (
                    !removedEntries.has(d[idColumn]) && !removedEntries.has(d[idColumn].toString())
                );
            }),
            ...newData,
        ]);
    }, [ids, idColumn, previousIds, table.data]);

    return data;
}

function difference<T>(set1: Set<T>, set2: Set<T>) {
    //return [...set1].filter(x => !set2.has(x));
    return Array.from(set1).filter((x) => !set2.has(x));
}
