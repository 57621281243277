import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import { parseISO } from 'date-fns';
import { endpoints } from 'endpoints.config';
import { localizedFormatDate } from 'helpers/language';
import { TFAType } from 'pages/register/models';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, openModal } from 'reducers/modal';
import { debounce } from 'lodash';
import { TfaToggle } from 'components/tfaToggle/tfatoggle';
import { curry } from 'ramda';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { selectCultureCode } from 'reducers/language';

interface UserSecurityData {
    phoneNumber: string | null;
    emailAddress: string;
    bTwoFactorAppAuthEnabled: boolean;
    bTwoFactorSMSAuthEnabled: boolean;
    bTwoFactorAppAuthSaved: boolean;
    bTwoFactorSMSAuthSaved: boolean;
    loginList: {
        browser: string;
        dateTime: string;
        ip: string;
        locationInfo: string;
    }[];
}

export const Security = () => {
    const [securityData, setSecurityData] = useState<UserSecurityData | null>(null);
    const [initialToggles, setInitialToggles] = useState<{
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
    } | null>(null);
    const dispatch = useDispatch();
    const cultureCode = useSelector(selectCultureCode);

    const fetchDetailsFromAPI = useCallback(() => {
        instance
            .get<ApiResponse<UserSecurityData>>(endpoints.profilemodule.usersecurityinfo)
            .then((response) => {
                if (response.data.status === '1') {
                    setSecurityData(response.data.details);
                    const { bTwoFactorAppAuthEnabled, bTwoFactorSMSAuthEnabled } =
                        response.data.details;
                    setInitialToggles({ bTwoFactorSMSAuthEnabled, bTwoFactorAppAuthEnabled });
                }
            });
    }, []);

    const switchAppTFA = useCallback(
        (v: boolean) => {
            if (v === initialToggles?.bTwoFactorAppAuthEnabled) return;
            setInitialToggles((current) =>
                current ? { ...current, bTwoFactorAppAuthEnabled: v } : null
            );
            instance.post(endpoints.profilemodule.flipAppTfa).catch((err) => {
                setInitialToggles((current) =>
                    current ? { ...current, bTwoFactorAppAuthEnabled: !v } : null
                );
                setSecurityData((current) =>
                    current ? { ...current, bTwoFactorAppAuthEnabled: !v } : null
                );
                Toast.openToastMessage(
                    `There was an error ${v ? 'enabling' : 'disabling'} your App TFA`,
                    ToastMessageReason.ERROR
                );
            });
        },
        [initialToggles, dispatch]
    );
    const switchSmsTFA = useCallback(
        (v: boolean) => {
            if (v === initialToggles?.bTwoFactorSMSAuthEnabled) return;
            setInitialToggles((current) =>
                current ? { ...current, bTwoFactorSMSAuthEnabled: v } : null
            );
            instance.post(endpoints.profilemodule.flipSmsTfa).catch((err) => {
                setInitialToggles((current) =>
                    current ? { ...current, bTwoFactorSMSAuthEnabled: !v } : null
                );
                setSecurityData((current) =>
                    current ? { ...current, bTwoFactorSMSAuthEnabled: !v } : null
                );
                Toast.openToastMessage(
                    `There was an error ${v ? 'enabling' : 'disabling'} your SMS TFA`,
                    ToastMessageReason.ERROR
                );
            });
        },
        [initialToggles, dispatch]
    );

    useEffect(() => {
        fetchDetailsFromAPI();
    }, [fetchDetailsFromAPI]);

    // TODO(HC): Await response from endpoint before rendering the security page.
    return securityData ? (
        <div className="SecurityPage">
            <div className="SecurityPageSection">
                <div className="Content">
                    <h2>Security</h2>
                    <div className="Item">
                        <p className="Label">Change password</p>
                        <Button
                            priority="primary"
                            variety="full"
                            className="Button"
                            onClick={() =>
                                dispatch(
                                    openModal({ modalType: ModalTypes.CHANGE_PASSWORD, data: null })
                                )
                            }
                        >
                            Change
                        </Button>
                    </div>
                    <div className="Item">
                        <p className="Label">
                            Phone number{' '}
                            {securityData.phoneNumber ? `(${securityData.phoneNumber})` : 'missing'}
                        </p>
                        <Button
                            priority="primary"
                            variety="full"
                            className="Button"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CHANGE_PHONE_NUMBER,
                                        data: null,
                                    })
                                )
                            }
                        >
                            {securityData.phoneNumber ? 'Change' : 'Add'}
                        </Button>
                    </div>
                    <div className="Item">
                        <p className="Label">Email address ({securityData.emailAddress})</p>
                        <Button
                            priority="primary"
                            variety="full"
                            className="Button"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CHANGE_EMAIL,
                                        data: null,
                                    })
                                )
                            }
                        >
                            Change
                        </Button>
                    </div>
                    <div className="Item">
                        <p className="Label">Account activation</p>
                        <Button
                            priority="secondary"
                            variety="full"
                            className="Button Deactivate"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.DEACTIVATE_ACCOUNT,
                                        data: null,
                                    })
                                )
                            }
                        >
                            Deactivate account
                        </Button>
                    </div>
                </div>
            </div>
            <div className="SecurityPageSection">
                <div className="Content">
                    <h2>Two-factor authentication</h2>
                    <div className="Item">
                        <p className="Label">Authenticator App</p>
                        <TfaToggle
                            value={securityData.bTwoFactorAppAuthEnabled}
                            setValue={(v: boolean) => {
                                setSecurityData((current) =>
                                    current ? { ...current, bTwoFactorAppAuthEnabled: v } : null
                                );
                            }}
                            setInitialValue={switchAppTFA}
                            disabled={
                                !securityData.bTwoFactorSMSAuthEnabled ||
                                !securityData.bTwoFactorAppAuthSaved
                            }
                        />
                        <Button
                            priority="primary"
                            variety="full"
                            className="Button"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.UPDATE_APP_TFA,
                                        data: null,
                                    })
                                )
                            }
                        >
                            {securityData.bTwoFactorAppAuthSaved ? 'Change' : 'Add'}
                        </Button>
                    </div>
                    <div className="Item">
                        <p className="Label">
                            SMS Phone number: {securityData.phoneNumber || 'N/A'}
                        </p>
                        <TfaToggle
                            value={securityData.bTwoFactorSMSAuthEnabled}
                            setValue={(v: boolean) => {
                                setSecurityData((current) =>
                                    current ? { ...current, bTwoFactorSMSAuthEnabled: v } : null
                                );
                            }}
                            setInitialValue={switchSmsTFA}
                            disabled={
                                !securityData.bTwoFactorAppAuthEnabled ||
                                !securityData.bTwoFactorSMSAuthSaved
                            }
                        />
                        <Button
                            priority="primary"
                            variety="full"
                            className="Button"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CHANGE_PHONE_NUMBER,
                                        data: null,
                                    })
                                )
                            }
                        >
                            Change
                        </Button>
                    </div>
                </div>
            </div>
            <div className="SecurityPageSection">
                <h2>Last Sessions</h2>
                <div className="TableContainer">
                    <table className="LoginTable">
                        <thead>
                            <tr>
                                <th>Signed in</th>
                                <th>Browser</th>
                                <th>IP address</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {securityData.loginList &&
                                securityData.loginList.map((row) => {
                                    return (
                                        <tr>
                                            <td>
                                                {row.dateTime
                                                    ? localizedFormatDate(
                                                          parseISO(row.dateTime),
                                                          cultureCode ?? undefined,
                                                          'Pp'
                                                      )
                                                    : '-'}
                                            </td>
                                            <td>{row.browser ?? '-'}</td>
                                            <td>{row.ip ?? '-'}</td>
                                            <td>{row.locationInfo ?? '-'}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : (
        <Spinner />
    );
};
