import { endpoints } from 'endpoints.config';
import { findAndRemoveFromArray } from 'helpers/findAndRemoveFromArray';
import { deepCamelCase } from 'helpers/formatFormFieldNames';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { InitialNotification, NotificationType, SignalRNotificationMessage } from './models';
import { NotificationsApi } from './NotificationsApi';
import { NotificationTile } from './NotificationTile';
import {
    NotificationData,
    NotificationIdentifier,
    setUIUpdate,
} from './notificationUIUpdateReducer';
import { useSignalRNotifications } from './useSignalRNotifications';
import { ModalTypes, openModal } from '../../reducers/modal';
import { refreshInfoRequestTable, updateHasInfoRequest } from '../../reducers/infoRequest';
import { getCustomerComponentResources } from 'reducers/componentResources';
import logger from 'helpers/logger';
import { setPendingTopUpCardReady } from 'reducers/cards';

type UINotification = {
    type: NotificationType;
    id: number;
    title: string;
    message: string;
};

const parseInitialNotificationToUINotification = (
    notification: InitialNotification,
    type?: NotificationType
): UINotification => {
    return {
        id: notification.id,
        message: notification.content,
        title: notification.title,
        type: type ?? NotificationType.ERROR,
    };
};

const parseSignalRNotificationMessageToUINotification = (
    message: SignalRNotificationMessage
): UINotification => {
    return {
        id: message.id,
        message: message.content,
        title: message.title,
        type: message.level.code,
    };
};

// Needs to match up with variable in notifications.scss.
export const NOTIFICATION_ANIMATION_TIME = 300;

type NotificationProps = {
    className?: string;
    activate?: boolean;
    // Avoid using portal functionality for now unless portal is in index.html.
    // Explanation below.
    portalID?: string;
};

export const Notifications: React.FC<NotificationProps> = ({
    className = '',
    activate = true,
    portalID,
}) => {
    const dispatch = useDispatch();
    const portalRef = useRef<HTMLDivElement | null>(null);
    const [notifications, setNotifications] = useState<UINotification[] | null>(null);
    // TODO(HC): Move this into prop
    const [latestNotification, clearLatestNotification] = useSignalRNotifications<NotificationData>(
        {
            url: endpoints.notificationsmodule.signalR,
            additionalMessageHandlers: [
                {
                    messageCode: 'ApplicationDataPush',
                    handler: (notification) => {
                        if (
                            notification.pushType ===
                                NotificationIdentifier.INFORMATION_REQUESTED ||
                            notification.pushType === NotificationIdentifier.INFORMATION_REMINDER
                        ) {
                            dispatch(
                                openModal({
                                    modalType: ModalTypes.REQUEST_INFO,
                                    data: {
                                        bIsReminder:
                                            notification.pushType ===
                                            NotificationIdentifier.INFORMATION_REMINDER,
                                    },
                                })
                            );
                            dispatch(updateHasInfoRequest(true));
                        } else if (
                            notification.pushType ===
                            NotificationIdentifier.INFORMATION_REQUEST_UPDATED
                        ) {
                            dispatch(refreshInfoRequestTable());
                        } else if (
                            notification.pushType === NotificationIdentifier.CARD_CREATE_SUCCEEDED
                        ) {
                            // Refresh the customer component resources to update the card list.
                            dispatch(getCustomerComponentResources());

                            // Set the pending top up card ready to true so the user can navigate to the card page in PendingCardModal if it's still open.
                            dispatch(setPendingTopUpCardReady(true));
                        } else {
                            dispatch(setUIUpdate(deepCamelCase(notification)));
                        }
                    },
                },
            ],
            activate: activate,
        }
    );

    const addNotification = useCallback((notificationToAdd: UINotification) => {
        setNotifications((prev) => [...(prev ? prev! : []), notificationToAdd]);
    }, []);

    useEffect(() => {
        if (latestNotification) {
            const parsedNotification =
                parseSignalRNotificationMessageToUINotification(latestNotification);
            addNotification(parsedNotification);
            clearLatestNotification();
        }
    }, [latestNotification, addNotification, clearLatestNotification]);

    useEffect(() => {
        if (activate && portalID) {
            const targetElement = document.getElementById(portalID) as HTMLDivElement;
            portalRef.current = targetElement;
        }
    }, [activate, portalID]);

    const handleClose = (id: UINotification['id']) => {
        if (notifications) {
            NotificationsApi.acknowledgeNotification(id);
            const updatedNotifications = findAndRemoveFromArray(
                notifications,
                (notification) => notification.id === id
            );
            setNotifications(updatedNotifications);
        }
    };

    const getAndSetInitialNotification = useCallback(async (retriesRemaining: number) => {
        const response = await NotificationsApi.getNotificationsStack();
        if (NotificationsApi.isSuccessData(response)) {
            const notifications = response.data.notifications.map((notification) => {
                const level = response.data.levels.find(
                    (level) => level.id === notification.levelsId
                );
                return parseInitialNotificationToUINotification(notification, level?.code);
            });
            setNotifications(notifications);
        } else {
            // TODO(HC): Add error handling / retry logic here.
            if (retriesRemaining > 0) {
                getAndSetInitialNotification(retriesRemaining - 1);
            } else {
                Toast.openToastMessage(
                    'There was a problem fetching your notifications',
                    ToastMessageReason.ERROR
                );
            }
        }
    }, []);

    useEffect(() => {
        if (activate && !notifications) {
            getAndSetInitialNotification(1);
        }
    }, [getAndSetInitialNotification, activate, notifications]);

    useEffect(() => {
        if (!activate && notifications) {
            setNotifications(null);
        }
    }, [activate, notifications]);

    const itemsToRender = (
        <div className={`Notifications ${className}`}>
            {notifications && activate
                ? notifications
                      .slice()
                      .reverse()
                      .map((notification) => (
                          <NotificationTile
                              heading={notification.title}
                              message={notification.message}
                              type={notification.type}
                              key={notification.id}
                              handleClose={() => handleClose(notification.id)}
                          />
                      ))
                : null}
        </div>
    );

    /**
     * There is a currently a bug where the element with portalID needs to be
     * rendered when this component is mounted ie. the portal element needs to
     * be higher in the DOM tree than this component. I think it's to do with
     * switching from returning null to returning createPortal(). Also seeing
     * this error whilst debugging: Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React is already rendering.
     * I believe it's related to createPortal modifying the DOM during the React
     * render cycle.
     */
    if (portalID && portalRef.current) {
        return createPortal(itemsToRender, portalRef.current);
    } else if (!portalID) {
        return itemsToRender;
    } else {
        return null;
    }
};
