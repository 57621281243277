import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserTFASettings,
    selectAccountSwitchInProgress,
    selectSignedIn,
    selectTFAType,
} from 'reducers/auth';
import {
    getCustomerComponentResources,
    getPublicComponentResources,
    selectComponentResources,
} from 'reducers/componentResources';
import {
    selectVerificationDetails,
    selectVerificationLoading,
    fetchVerificationStatus,
} from 'reducers/verification';
import { selectCultureCode } from 'reducers/language';
import api from 'api';
import { endpoints } from 'endpoints.config';

export const useGetComponentResources = () => {
    const dispatch = useDispatch();
    const cultureCode = useSelector(selectCultureCode);
    const isSignedIn = useSelector(selectSignedIn);
    const componentResources = useSelector(selectComponentResources);
    const verificationStatus = useSelector(selectVerificationDetails);
    const verificationLoading = useSelector(selectVerificationLoading);
    const accountSwitchInProgress = useSelector(selectAccountSwitchInProgress);
    const TfaOptions = useSelector(selectTFAType);

    // const [prevSignedIn, setPrevSignedIn] = useState(isSignedIn);
    const [prevCultureCode, setPrevCultureCode] = useState(cultureCode);

    // Make fetch for public component resources if not present.
    useEffect(() => {
        if (!(componentResources.footerLinks && componentResources.languageList))
            dispatch(getPublicComponentResources());
    }, [dispatch, componentResources.footerLinks, componentResources.languageList]);

    // Make fetch for customer component resources if logged-in and not present.
    useEffect(() => {
        if (
            isSignedIn &&
            !accountSwitchInProgress &&
            !componentResources.fetchingCustomerResources &&
            !(componentResources.userInfo && componentResources.customerMenuLinks)
        ) {
            dispatch(getCustomerComponentResources());
        }
    }, [
        dispatch,
        isSignedIn,
        componentResources.userInfo,
        componentResources.customerMenuLinks,
        componentResources.fetchingCustomerResources,
        accountSwitchInProgress,
    ]);

    useEffect(() => {
        if (
            isSignedIn &&
            verificationStatus.stage === null &&
            !verificationLoading &&
            componentResources.userInfo
        ) {
            dispatch(fetchVerificationStatus());
        }
    }, [
        verificationStatus,
        verificationLoading,
        isSignedIn,
        dispatch,
        componentResources.userInfo,
    ]);

    // Update component resources if culture code is changed. - Commented out because we don't currently send culture info to any of these endpoints
    // useEffect(() => {
    //     if (cultureCode !== prevCultureCode) {
    //         setPrevCultureCode(cultureCode);
    //         dispatch(getPublicComponentResources());
    //         if (isSignedIn) {
    //             dispatch(getCustomerComponentResources());
    //         }
    //     }
    // }, [dispatch, cultureCode, isSignedIn, prevCultureCode]);

    useEffect(() => {
        if (isSignedIn && !TfaOptions) {
            dispatch(getUserTFASettings());
        }
    }, [dispatch, TfaOptions, isSignedIn]);
};
