import { FormSingleSelectField } from '@avamae/formbuilder';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVerificationStage } from 'reducers/verification';
import { JumioVerificationStatusResponse } from './jumioModels';
import { VerifyIdentityOptions } from './JumioVerification';
import { getErrorMessage } from 'errors';

const initialValues = {
    countryCode: 'USA',
    typeCode: '',
};
export const JumioTextualData = ({ options }: { options: VerifyIdentityOptions }) => {
    const dispatch = useDispatch();
    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.verifyIdentity,
                values
            )
            .then((res) =>
                dispatch(
                    setVerificationStage({
                        currentStage: res.data.details.status,
                        additionalData: res.data.details.additionalData,
                    })
                )
            )
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors.some(
                        (errorMessage) => errorMessage.messageCode === 'Too_Many_Requests'
                    )
                ) {
                    return Toast.openErrorToast('Too many attempts, please try again tomorrow');
                }
                Toast.openErrorToast(
                    getErrorMessage(
                        isAxiosErrorHandled(err)
                            ? err.response.data.errors?.[0].messageCode ?? ''
                            : ''
                    )
                );
            });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <h3>We need some proof of your address, please choose from the options below:</h3>
                <FormSingleSelectField
                    label="Document type"
                    fieldName="typeCode"
                    options={options.documentOptions.map((option) => ({
                        value: option.typeCode,
                        label: option.name,
                    }))}
                    dropdownProps={{
                        menuPortalTarget: document.body,
                        styles: {
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 500,
                            }),
                        },
                    }}
                />
                <FormSingleSelectField
                    label="Origin country"
                    fieldName="countryCode"
                    options={options.countryOptions.map((option) => ({
                        label: option.name,
                        value: option.isoCode,
                    }))}
                    dropdownProps={{
                        classNamePrefix: 'test',
                        menuPortalTarget: document.body,
                        styles: {
                            menuPortal: (provided: any) => ({
                                ...provided,
                                zIndex: 500,
                            }),
                        },
                    }}
                />
                <Button variety="full">Next</Button>
            </Form>
        </Formik>
    );
};
