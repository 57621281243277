import { Store } from './rootReducer';

type CryptoPricesState = {
    pendingTopUpCardReady: boolean;
};
const initialState: CryptoPricesState = {
    pendingTopUpCardReady: false,
};

enum CardsActionType {
    SET_PENDING_TOP_UP_CARD_READY = 'app/cards/SET_PENDING_TOP_UP_CARD_READY',
}

type CardsAction = {
    type: CardsActionType.SET_PENDING_TOP_UP_CARD_READY;
    payload: boolean;
};

const Reducer = (state = initialState, action: CardsAction): CryptoPricesState => {
    switch (action.type) {
        case CardsActionType.SET_PENDING_TOP_UP_CARD_READY: {
            return { ...state, pendingTopUpCardReady: action.payload };
        }
        default:
            return state;
    }
};

export default Reducer;

// Action creators
export const setPendingTopUpCardReady = (pendingTopUpCardReady: boolean): CardsAction => ({
    type: CardsActionType.SET_PENDING_TOP_UP_CARD_READY,
    payload: pendingTopUpCardReady,
});

// Selectors

export const selectPendingTopUpCardReady = (store: Store) => store.cards.pendingTopUpCardReady;
