import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageResourceKey } from '../appConstants';
import { selectCultureCode } from '../reducers/language';
import {
    addExpiryDateAndCultureCode,
    getItemFromLocalStorage,
    storeInLocalStorage,
    shouldUseLocalStorageContent,
} from './cache';
import axiosInstance from 'api';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from './toast';

export const usePageTranslations = <T>(page: PageResourceKey): T | null => {
    const [translations, setTranslations] = useState<T | null>(null);
    const dispatch = useDispatch();

    const cultureCode = useSelector(selectCultureCode);
    const cachedContent = getItemFromLocalStorage(page);

    const shouldUseLocalStorageTranslations = shouldUseLocalStorageContent(
        cachedContent,
        cultureCode
    );

    useEffect(() => {
        if (cultureCode && shouldUseLocalStorageTranslations) {
            setTranslations(cachedContent);
            return;
        }
        if (cultureCode)
            axiosInstance
                .get(endpoints.languagemodule.languageresources, {
                    params: { page: page },
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === '1') {
                            const translations: T = res.data.details[cultureCode][page];
                            setTranslations(translations);
                            const contentToCache = addExpiryDateAndCultureCode(
                                translations,
                                cultureCode
                            );
                            storeInLocalStorage(contentToCache, page);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    Toast.openToastMessage(
                        'There was an error getting translations for your language',
                        ToastMessageReason.ERROR
                    );
                });
    }, [cultureCode]);

    return translations;
};
