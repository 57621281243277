import { Store } from './rootReducer';

export type CurrencySymbolDict = {
    [ticker: string]: string;
};

const initialState: CurrencySymbolDict = {
    USD: '$',
    GBP: '£',
};

//Actions
enum ActionTypes {
    ADD_SYMBOLS = 'currency_symbols/add_symbols',
    CLEAR_SYMBOLS = 'currency_symbols/clear_symbols',
}

type Action =
    | {
          type: ActionTypes.ADD_SYMBOLS;
          payload: CurrencySymbolDict;
      }
    | {
          type: ActionTypes.CLEAR_SYMBOLS;
      };

// Reducer
const reducer = (store = initialState, action: Action): CurrencySymbolDict => {
    switch (action.type) {
        case ActionTypes.ADD_SYMBOLS:
            return { ...store, ...action.payload };
        case ActionTypes.CLEAR_SYMBOLS:
            return initialState;
        default:
            return store;
    }
};
export default reducer;

//Epics

//Action Creators
export const addSymbols = (payload: CurrencySymbolDict): Action => {
    return {
        type: ActionTypes.ADD_SYMBOLS,
        payload,
    };
};
export const addSymbolArray = (symbolArray: { ticker: string; symbol: string }[]): Action => {
    const payload = symbolArray.reduce(
        (prev, current) => ({ ...prev, [current.ticker]: current.symbol }),
        {}
    );
    return {
        type: ActionTypes.ADD_SYMBOLS,
        payload,
    };
};
//Selectors
export const selectCurrencySymbols = (store: Store) => store.currencySymbols;
