import { navigate } from '@reach/router';
import { AppPath } from 'appConstants';
import Button from 'components/button/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLinkedAccount } from 'reducers/auth';
import { deleteCustomerComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export const CreatePersonalProfile = () => {
    const dispatch = useDispatch();
    const cultureCode = useSelector(selectCultureCode);
    const handleSubmit = () => {
        dispatch(deleteCustomerComponentResources());
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
        // arg is null as the personal account doesn't require any additional information
        // not already provided when registering a business account.
        dispatch(createLinkedAccount(null));
    };
    return (
        <div className="CreateAccount">
            <h1>Create a personal account</h1>
            <p>
                Opening a Personal Account with us will allow you to control your business and
                personal finances separately.
            </p>
            <div className="SwitchForm">
                <Button priority="primary" variety="full" onClick={handleSubmit}>
                    Create account
                </Button>
                <p className="Disclaimer">
                    By clicking the "Create Account" button above, you agree to Ibanera's{' '}
                    <a href="https://www.ibanera.com/legal-agreements/usa-general-terms-conditions/">
                        Terms &amp; Conditions
                    </a>
                    ,{' '}
                    <a href="https://www.ibanera.com/legal-agreements/digital-asset-custody-terms-conditions/">
                        Digital Asset Custody Terms &amp; Conditions
                    </a>{' '}
                    and <a href="https://www.ibanera.com/privacy-policy/">Privacy Policy</a>
                </p>
            </div>
        </div>
    );
};
