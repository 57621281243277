import React, { lazy, useState } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';

// Icons
import View from '../../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../../assets/Cat-Entity/Icon_Action_02.png';
import Delete from '../../../assets/Cat-Entity/Icon_Action_03.png';

import { CrudItems } from 'components/Category/CrudMenu';
import { format } from 'date-fns';
import { endpoints } from 'endpoints.config';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';
import Category from 'components/Category/Category';
import Entity from 'components/categoryComponents/Entity';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { selectAccountTypePathItem } from 'reducers/componentResources';
import { AppPath, DashboardPath } from 'appConstants';
import { CardManagementSubpage } from '../CardManagement';
import { number } from 'yup/lib/locale';
import { Button } from 'antd';
import instance, { TableInfo, useTable } from 'api';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { DateRangePicker } from 'components/dateRangePicker/DateRangePicker';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { plaintext } from 'plaintext.config';
import { FlexTable } from 'components/flexTable';
import Pagination from 'components/pagination/Pagination';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';

// Define the url for the table's data endpoint.
const url =
    endpoints.cardManagementModule.cardTransactions; /* REPLACE__ME - api url for this component */

// Define which column on the table represents each row's ID.
// This should be unique to each row.
const idColumn: keyof TransactionData =
    'customerAssetAccountsTransactions__Id'; /* REPLACE__ME - id column for this component */

//Define the data structure for table
/* REPLACE__ME - table data structure */
export type TransactionData = {
    customerAssetAccountsTransactions__Id: number;
    topUpCards__CardNumber: number;
    customerAssetAccountsTransactions__Amount: number;
    assets__Code: string;
    customerAssetAccountsTransactions__Description: string;
    customerAssetAccountsTransactions__Date: string;
    customerAssetAccountsTransactions__bPending: boolean;
    topUpCards__CardId: number;
};

const crudItems: CrudItems<TransactionData> = [
    {
        icon: View,
        title: 'Add Card User' /* REPLACE__ME */,
        imgAltText: 'Create',
        menuCode: 'CREATE',
        showInMultiSelect: false,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => null, //<AddUser onDone={onDone} />
        inRightPanel: true,
        isDisabled: false,
        notInMenus: true,
    },
    {
        icon: View,
        title: 'Add Card' /* REPLACE__ME */,
        imgAltText: 'Add',
        menuCode: 'ADD',
        showInMultiSelect: false,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => null, // <AddCardToUser manageesId={selectedData[0].customers__Id} onDone={onDone} />
        inRightPanel: true,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: TransactionData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.customerAssetAccountsTransactions__Date) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Date: localizedFormatDate(
                new Date(row.customerAssetAccountsTransactions__Date)
            ),
        };
    }
    return formattedRow;
};

export interface CardUsersProps {}

type Props = CardUsersProps & RouteComponentProps;

function CardTransactions(props: Props) {
    const location = useLocation();
    const cardId = (location.state as any)?.cardId ?? undefined;
    const cultureCode = useSelector(selectCultureCode);

    const transactionsTable = useTable<TransactionData, any>({
        url,
        bClearFilters: !cardId,
        filters: cardId ? `TopUpCards__Id EQ (${cardId})` : undefined,
    });
    const { loading, error, data } = transactionsTable;

    const setFilter = (startDate: Date, endDate: Date) => {
        data?.actions.changeFilter('customerAssetAccountsTransactions__Date', [
            {
                columnKey: 'customerAssetAccountsTransactions__Date',
                operator: 'BETWEEN',
                value: `${startDate.toISOString()}TO${endDate.toISOString()}`,
            },
        ]);
    };

    if (loading) {
        return <SubPageLoader message={'Loading transactions'} />;
    }
    return (
        <div>
            <div className="TableHeader">
                <DateRangePicker setFilter={setFilter} cultureCode={cultureCode} />
                <SearchBox
                    key={'searchBox'}
                    placeholder={plaintext.accounts.searchTransactions}
                    initialSearchString={data?.details.searchString ?? ''}
                    changeSearch={data?.actions.changeSearch}
                    goToPage={data?.actions.goToPage}
                />
            </div>
            <FlexTable
                idColumn={idColumn}
                table={transactionsTable as TableInfo}
                rowFormatter={rowFormatter}
                rowCellOptions={{
                    transactionAmounts__bInTransaction: {
                        style: { minWidth: '60px', width: '60px', flex: '0' },
                    },
                }}
            />
            {transactionsTable?.data?.details.listData.length === 0 && !transactionsTable?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">
                        {cardId
                            ? 'No transactions found for the selected card'
                            : plaintext.tables.emptyTransactionTable}
                    </h3>
                </div>
            )}
            <Pagination table={transactionsTable} />
        </div>
    );
}

export default CardTransactions;
