import * as Yup from 'yup';

export interface OperatingAddress {
    bUseDifferentAddress: boolean;
    streetName: string;
    zipCode: string;
    state: string;
    city: string;
    country: string;
    stateProvince: string;
}

export interface CompanyRep {
    firstName: string;
    lastName: string;
    taxID: string;
    dob: string;
    addressLine1: string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    phoneNumber: string;
    email: string;
    position: string;
    documentType: string;
    idNumber: string;
    dateOfIssue: string;
    placeOfIssue: string;
    dateOfExpiry: string;
    policiticallyExposed: string;
    politicialFirstName: string;
    politicialLastName: string;
    politicialState: string;
    politicialPosition: string;
}

export interface BusinessPartner {
    name: string;
    companyRegistrationNumber: string;
    website: string;
}

export interface BusinessPartners {
    businessPartners: BusinessPartner[];
}

export interface MainActivity {
    mainActivity: string;
    businessDescription: string;
    fundsReceived: string;
    fundSent: string;
    passiveIncome: string;
}

export interface Funds {}

export interface AnticipatedActivity {
    monthlyTurnOver: string | null;
    next12MonthsActivities: string | null; //should be here but still on the otheraccounts section currently
    sellingCryptoMonthlyVolume: string | null;
    sellingCryptoAverageAmount: string | null;
    sellingCryptoTotalAmountPerMonth: string | null;
    purchaseCryptoMonthlyVolume: string | null;
    purchaseCryptoAverageAmount: string | null;
    purchaseCryptoTotalAmountPerMonth: string | null;
    totalMonthlyVolume: string | null;
    totalAverageAmount: string | null;
    btcTradingPercentage: string | null;
    ethTradingPercentage: string | null;
    usdcTradingPercentage: string | null;
    otherCoinsTradingPercentage: string | null;
}
export type OtherAccount = {
    bankAccountName: string;
    number?: number;
};

export interface CorporateApp {
    corporate: {
        operatingAddress: OperatingAddress;
        representative: CompanyRep;
        mainActivity: MainActivity;
        otherAccounts: OtherAccount[];
        anticipatedActivity: AnticipatedActivity;
        authorizedCapital: Funds;
        mainBusinessPartners: BusinessPartners;
    };
}
const corporateWrapper = <T extends Yup.AnySchema>(shape: T) =>
    Yup.object().shape({
        corporate: shape,
    });
export const validationSchemaOperatingAddress = corporateWrapper(
    Yup.object().shape({
        operatingAddress: Yup.object().shape({
            bUseDifferentAddress: Yup.boolean().required(
                'Please provide information on the businesses operating address'
            ),
            addressLine1: Yup.string().when('bUseDifferentAddress', {
                is: true,
                then: Yup.string()
                    .max(200, 'Maximum 200 characters')
                    .required('Please provide a street name'),
            }),
            postcode: Yup.string().when('bUseDifferentAddress', {
                is: true,
                then: Yup.string()
                    .max(20, 'Maximum 20 characters')
                    .required('Please provide a zip/postal code'),
            }),
            stateProvince: Yup.string().when('bUseDifferentAddress', {
                is: true,
                then: Yup.string()
                    .max(100, 'Maximum 100 characters')
                    .required('Please provide a state/province')
                    .nullable(),
            }),
            city: Yup.string().when('bUseDifferentAddress', {
                is: true,
                then: Yup.string()
                    .max(200, 'Maximum 200 characters')
                    .required('Please provide a city'),
            }),
            country: Yup.string().when('bUseDifferentAddress', {
                is: true,
                then: Yup.string().required('Please provide a country'),
            }),
        }),
    })
);

export const validationSchemaRepresentative = corporateWrapper(
    Yup.object().shape({
        representative: Yup.object().shape({
            firstName: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a first name'),
            lastName: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a last name'),
            ssn: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a tax ID number'),
            dateOfBirth: Yup.string().required('Please provide a date of birth').nullable(),
            addressLine1: Yup.string()
                .max(200, 'Maximum 200 characters')
                .required('Please provide street address'),
            postcode: Yup.string()
                .max(20, 'Maximum 20 characters')
                .required('Please provide a zip/postal code'),
            city: Yup.string().max(100, 'Maximum 100 characters').required('please provide a city'),
            stateProvince: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a state/province'),
            country: Yup.string().required('Please provide a country'),
            phoneNumber: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide a phone number'),
            email: Yup.string()
                .max(200, 'Maximum 200 characters')
                .required('Please provide an email address'),
            position: Yup.string()
                .max(100, 'Maximum 100 characters')
                .required('Please provide the position held at the company'),
            personalIdDocumentTypesID: Yup.number()
                .required('Pease provide a type of document supplied')
                .nullable(),
            personalIdDocument: Yup.object().shape({
                idNumber: Yup.string()
                    .max(100, 'Maximum 100 characters')
                    .required('Please provide the documents Id number'),
                dateOfIssue: Yup.string()
                    .required('Please provide the documents issue date')
                    .nullable(),
                placeOfIssue: Yup.string()
                    .max(200, 'Maximum 200 characters')
                    .required('Please provide the documents place of issue'),
                expiryDate: Yup.string()
                    .required('Please provide the documents expiry date')
                    .nullable(),
            }),
            bExposed: Yup.boolean().required(),
            politicallyExposedPersonTypesID: Yup.number()
                .when('bExposed', {
                    is: true,
                    then: (schema) =>
                        schema.required('Please provide information on political exposure'),
                })

                .nullable(),
            politicallyExposedPerson: Yup.object().when('bExposed', {
                is: true,
                then: (schema) =>
                    schema.shape({
                        firstName: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide a first name'),
                        lastName: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide a last name'),
                        stateInstitution: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide a state or institution'),
                        position: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide the position held'),
                    }),
            }),
        }),
    })
);

export const validationSchemaBusinessPartners = corporateWrapper(
    Yup.object().shape({
        mainBusinessPartners: Yup.array().of(
            Yup.object().shape({
                number: Yup.number().required(),
                name: Yup.string()
                    .max(200, 'Maximum 200 characters')
                    .required('Please provide a business partner name'),
                companyRegistrationNumber: Yup.string()
                    .max(100, 'Maximum 100 characters')
                    .required('Please provide a company registration number'),
                website: Yup.string().max(200, 'Maximum 200 characters'),
                //.required('Please provide a business partner website')
            })
        ),
    })
);

export const validationSchemaFunding = corporateWrapper(
    Yup.object().shape({
        authorizedCapital: Yup.object().shape({
            authorizedCapital: Yup.number()
                .typeError('Please provide authorized capital')
                .required('Please provide authorized capital')
                .nullable()
                .min(0, 'Must not be negative'),
            sourceOfFunds: Yup.string().required('Please provide source of funds'),
            proofOfSourceOfFundsFileName: Yup.string()
                .required('Please provide proof of authorized capital')
                .max(200, 'Maximum 200 characters')
                .nullable(),
            previousYearTurnover: Yup.number()
                .typeError("Please provide previous years' turnover")
                .required("Please provide previous years' turnover")
                .nullable(),
            plannedTurnover: Yup.number()
                .typeError("Please provide coming years' projected turnover")
                .required("Please provide coming years' projected turnover")
                .nullable(),
            bankAccountSignatories: Yup.string()
                .max(500, 'Maximum 500 characters')
                .required('Please provide a full name'),
            sourceFundsInformationTypesID: Yup.string()
                .required('Please provide information on source of funds')
                .nullable(),
            externalInvestment: Yup.object().shape({
                companyName: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a company name'),
                }),
                companyAddress: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(500, 'Maximum 500 characters')
                        .required('Please provide a company address'),
                }),
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        function (value) {
                            const { companyName, companyAddress, date } = this.parent;
                            if (companyName || companyAddress || date) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
                //.required('Please provide amount invested')
                date: Yup.string()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide date of investment').nullable(),
                    })
                    .nullable(),
            }),
            internalInvestments: Yup.array().of(
                Yup.object().shape({
                    number: Yup.number().required(),
                    employeeName: Yup.string().when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide a name'),
                    }),
                    position: Yup.string().when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string()
                            .max(100, 'Maximum 100 characters')
                            .required('Please provide a position'),
                    }),
                    amount: Yup.number()
                        .transform((value) => (Number.isNaN(value) ? null : value))
                        .nullable()
                        .test(
                            'required',
                            'Required if supplying information for this section',
                            function (value) {
                                const { employeeName, position, sourceOfWealth, date } =
                                    this.parent;
                                if (employeeName || position || sourceOfWealth || date) {
                                    return value != null;
                                }
                                return true;
                            }
                        )
                        .min(0, 'Must not be negative'),
                    date: Yup.string()
                        .when('amount', {
                            is: (amount: number) => amount != null,
                            then: Yup.string()
                                .required('Please provide date of investment')
                                .nullable(),
                        })
                        .nullable(),
                    sourceOfWealth: Yup.string().when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide source of wealth'),
                    }),
                })
            ),
            companySale: Yup.object().shape({
                companyName: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a company name'),
                }),
                country: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string().required('Please provide country of incorporation'),
                }),
                companyAddress: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(500, 'Maximum 500 characters')
                        .required('Please provide an address'),
                }),
                natureOfBusiness: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a nature of business'),
                }),
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        function (value) {
                            const { natureOfBusiness, companyName, country, companyAddress, date } =
                                this.parent;
                            if (
                                companyName ||
                                natureOfBusiness ||
                                country ||
                                companyAddress ||
                                date
                            ) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
                date: Yup.string()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide sale date').nullable(),
                    })
                    .nullable(),
            }),
            inheritance: Yup.object().shape({
                nameRelationDonor: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(500, 'Maximum 500 characters')
                        .required('Please provide name and relationship'),
                }),
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        function (value) {
                            const { nameRelationDonor, date } = this.parent;
                            if (nameRelationDonor || date) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
                date: Yup.string()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide date received').nullable(),
                    })
                    .nullable(),
            }),
            propertySale: Yup.object().shape({
                address: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(500, 'Maximum 500 characters')
                        .required('Please provide address'),
                }),
                date: Yup.string()
                    .nullable()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide sale date').nullable(),
                    }),
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        (value, context) => {
                            if (context.parent.address || context.parent.date) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
            }),
            loan: Yup.object().shape({
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        (value, context) => {
                            if (
                                context.parent.lenderNameAddress &&
                                context.parent.lenderNameAddress.length > 0
                            ) {
                                return value != null;
                            }
                            if (context.parent.purpose && context.parent.purpose.length > 0) {
                                return value != null;
                            }
                            if (context.parent.date && context.parent.date.length > 0) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
                date: Yup.string()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide date received').nullable(),
                    })
                    .nullable(),
                lenderNameAddress: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide address'),
                }),
                purpose: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide purpose of loan'),
                }),
            }),
            other: Yup.object().shape({
                payee: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide payee'),
                }),
                date: Yup.string()
                    .when('amount', {
                        is: (amount: number) => amount != null,
                        then: Yup.string().required('Please provide date received').nullable(),
                    })
                    .nullable(),
                natureOfIncome: Yup.string().when('amount', {
                    is: (amount: number) => amount != null,
                    then: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide nature of income'),
                }),
                amount: Yup.number()
                    .transform((value) => (Number.isNaN(value) ? null : value))
                    .nullable()
                    .test(
                        'required',
                        'Required if supplying information for this section',
                        (value, context) => {
                            if (
                                context.parent.natureOfIncome &&
                                context.parent.natureOfIncome.length > 0
                            ) {
                                return value != null;
                            }
                            if (context.parent.payee && context.parent.payee.length > 0) {
                                return value != null;
                            }
                            if (context.parent.date && context.parent.date.length > 0) {
                                return value != null;
                            }
                            return true;
                        }
                    )
                    .min(0, 'Must not be negative'),
            }),
            supportingDocuments: Yup.array()
                .of(
                    Yup.object().shape({
                        number: Yup.number(),
                        fileName: Yup.string().max(200, 'Maximum 200 characters'),
                    })
                )
                .nullable(),
        }),
    })
);

export const validationSchemaMainActivity = corporateWrapper(
    Yup.object().shape({
        mainActivity: Yup.object().shape({
            typesID: Yup.string()
                .required("Please provide information on the business' main activity")
                .nullable(),
            description: Yup.string().when('typesID', {
                is: '1008' || 1008,
                then: Yup.string()
                    .max(2000, 'Maximum 2000 characters')
                    .required('Please provide a description if type is "Other"'),
            }),
            bFundsReceivedUSA: Yup.boolean()
                .required('Please indicate from where funds will be recieved')
                .nullable(),
            bFundsSentUSA: Yup.boolean()
                .required('Plesae indicate where funds will be sent')
                .nullable(),
            bPassiveIncomeExceedsFiftyPercent: Yup.boolean()
                .required('Please provide information on passive income')
                .nullable(),
        }),
    })
);

export const validationSchemaOtherAccounts = corporateWrapper(
    Yup.object().shape({
        otherAccounts: Yup.array().of(
            Yup.object().shape({
                accountName: Yup.string()
                    .max(100, 'Maximum 100 characters')
                    .required('Please provide a name for this account'),
            })
        ),
    })
);

export const validationSchemaAnticipatedActivity = corporateWrapper(
    Yup.object().shape({
        anticipatedActivity: Yup.object().shape({
            monthlyTurnOver: Yup.number()
                .required('Please provide monthly turnover')
                .nullable()
                .typeError('Must be a number'),

            next12MonthsActivities: Yup.number()
                .typeError('Must be a number')
                .required(
                    'Please provide the anticiplated transactional activity for next 12 months'
                )
                .nullable(),
            sellingCryptoMonthlyVolume: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your monthly volume')
                .nullable(),
            sellingCryptoAverageAmount: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your average amount')
                .nullable(),
            sellingCryptoTotalAmountPerMonth: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your total amount per month')
                .nullable(),
            purchaseCryptoMonthlyVolume: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your monthly volume')
                .nullable(),
            purchaseCryptoAverageAmount: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your average amount')
                .nullable(),
            purchaseCryptoTotalAmountPerMonth: Yup.number()
                .typeError('Must be a number')
                .required('Please provide total amount per month')
                .nullable(),
            totalMonthlyVolume: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your total amount monthly')
                .nullable(),
            totalAverageAmount: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your total monthly volume for all')
                .nullable(),
            btcTradingPercentage: Yup.number()
                .typeError('Must be a number')
                .max(100, 'Percentage must be less than 100')
                .min(0, 'Percentage must be more than 0')
                .nullable()
                .test(
                    'totalTradingPercentage',
                    'Total crypto trading percentage must not exceed 100%',
                    (value, testContext) => {
                        const btc = testContext.parent.btcTradingPercentage ?? 0;
                        const eth = testContext.parent.ethTradingPercentage ?? 0;
                        const usdc = testContext.parent.usdcTradingPercentage ?? 0;
                        const other = testContext.parent.otherCoinsTradingPercentage ?? 0;
                        return btc + eth + usdc + other <= 100;
                    }
                )
                .required('Please provide coin BTC activity'),
            ethTradingPercentage: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your monthly volume')
                .max(100, 'Percentage must be less than 100')
                .min(0, 'Percentage must be more than 0')
                .required('Please provide coin ETH activity')
                .nullable()
                .test(
                    'totalTradingPercentage',
                    'Total crypto trading percentage must not exceed 100%',
                    (value, testContext) => {
                        const btc = testContext.parent.btcTradingPercentage ?? 0;
                        const eth = testContext.parent.ethTradingPercentage ?? 0;
                        const usdc = testContext.parent.usdcTradingPercentage ?? 0;
                        const other = testContext.parent.otherCoinsTradingPercentage ?? 0;
                        return btc + eth + usdc + other <= 100;
                    }
                ),
            usdcTradingPercentage: Yup.number()
                .typeError('Must be a number')
                .required('Please provide your monthly volume')
                .max(100, 'Percentage must be less than 100')
                .min(0, 'Percentage must be more than 0')
                .required('Please provide coin USDC activity')
                .nullable()
                .test(
                    'totalTradingPercentage',
                    'Total crypto trading percentage must not exceed 100%',
                    (value, testContext) => {
                        const btc = testContext.parent.btcTradingPercentage ?? 0;
                        const eth = testContext.parent.ethTradingPercentage ?? 0;
                        const usdc = testContext.parent.usdcTradingPercentage ?? 0;
                        const other = testContext.parent.otherCoinsTradingPercentage ?? 0;
                        return btc + eth + usdc + other <= 100;
                    }
                ),
            otherCoinsTradingPercentage: Yup.number()
                .typeError('Must be a number')
                .max(100, 'Percentage must be less than 100')
                .min(0, 'Percentage must be more than 0')
                .required('Please provide other coin activity')
                .nullable()
                .test(
                    'totalTradingPercentage',
                    'Total crypto trading percentage must not exceed 100%',
                    (value, testContext) => {
                        const btc = testContext.parent.btcTradingPercentage ?? 0;
                        const eth = testContext.parent.ethTradingPercentage ?? 0;
                        const usdc = testContext.parent.usdcTradingPercentage ?? 0;
                        const other = testContext.parent.otherCoinsTradingPercentage ?? 0;
                        return btc + eth + usdc + other <= 100;
                    }
                ),
        }),
    })
);

export const validationSchemaCorporate = Yup.object().shape({
    corporate: Yup.object().shape({
        operatingAddress: validationSchemaOperatingAddress,
        representative: validationSchemaRepresentative,
        mainBusinessPartners: validationSchemaBusinessPartners,
        authorizedCapital: validationSchemaFunding,
        mainActivity: validationSchemaMainActivity,
        otherAccounts: validationSchemaOtherAccounts,
        anticipatedActivity: validationSchemaAnticipatedActivity,
    }),
});
