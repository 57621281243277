import { ErrorM } from 'components/form/ErrorM';
import React from 'react';

type FormFieldWrapperProps = { label: string; fieldName: string; children: React.ReactNode };

const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({ label, fieldName, children }) => {
    return (
        <div className="FormBox FormSideBySide">
            <div className="FormLabel">
                <label>{label}</label>
            </div>
            <div className="FormField">{children}</div>
            <ErrorM name={fieldName} />
        </div>
    );
};

export { FormFieldWrapper };
