import instance, { ApiResponse, isErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useTFAField } from 'helpers/useTFAField';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import * as Yup from 'yup';
import WarningIcon from 'assets/Icon_Warning.png';
import { Spinner } from 'components/spinner/Spinner';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { id } from 'date-fns/locale';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { SavedPayee } from '../../pages/account/MoveMoney';

type PullFundsDetails = {
    accountOfficialName: string | null;
    achAccountNumber: string | null;
    achRoutingNumber: string | null;
    balance?: number;
    currencyCode: string | null;
    customerAssetAccounts:
        | { id: number; name: string; bUseStaticReference: boolean; staticReference?: string }[]
        | null;
    ownerName: string | null;
    payeeType: 'Card' | 'BankAccount';
    cardLast4Digits?: string;
    cardCompany?: string;
};
const initialValues = {
    reference: '',
    amount: undefined,
    customerAssetAccountsId: 0,
    tfaCode: '',
    tfaType: '',
    showTfa: false,

    // accountOfficialName: '', //null,
    // achAccountNumber: '', //null,
    // achRoutingNumber: '', //null,
    // balance: '', //null,
    // currencyCode: '', //null,
    // //customerAssetAccounts: null,
    // ownerName: '', //null,
};

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    amount: Yup.number().required('Please enter an amount').moreThan(0, 'Please enter an amount'),
    customerAssetAccountsId: Yup.number().required('Please select an account'),
    reference: Yup.string()
        // .required('Please enter a reference')
        .test('maxLength', 'No longer than 15 characters', (ref) => !ref || ref.length <= 15)
        .max(15, 'Reference must be at most 15 characters long'),
    tfaCode: Yup.string().when('showTfa', {
        is: true,
        then: Yup.string().required('Please enter your TFA code'),
    }),
});

export const PullFundsModal = ({
    payeeDetails,
    onCompletion,
}: {
    payeeDetails: SavedPayee;
    onCompletion: () => void;
}) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);

    const [pullFundsDetails, setPullFundsDetails] = useState<PullFundsDetails | null>(null);
    const [pullFundsFinal, setPullFundsFinal] = useState<boolean>(false);
    const [loading, setLoading] = useState<
        'loading' | 'loading failed' | 'loading succeeded' | null
    >(null);

    const [showTfa, setShowTfa] = useState(false);
    const [TFAType, toggleTFAType] = useTFAField(showTfa);
    const [disableReferenceField, setDisableReferenceField] = useState(false);

    useEffect(() => {
        if (pullFundsDetails || modalState.modalType !== ModalTypes.PULL_FUNDS) return; // the modaltype check lets typescript infer the modalstate's data
        setLoading('loading');
        instance
            .get<ApiResponse<PullFundsDetails>>(endpoints.accounts.getPullFundsDetails, {
                params: { PayeesId: modalState.data.payeeData.payees__Id },
            })
            .then((res) => {
                setPullFundsDetails(res.data.details);
                setLoading('loading succeeded');
            })
            .catch((err) => {
                setErrorMessage(
                    "We can't get account details for this account, please try again later"
                );
                setLoading('loading failed');
            });
    }, [pullFundsDetails, modalState]);

    if (modalState.modalType !== ModalTypes.PULL_FUNDS) {
        dispatch(closeModal());
        return null;
    }

    const handlePullFunds = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        if (!values.showTfa) {
            formikHelpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            formikHelpers.setSubmitting(false);
            formikHelpers.setFieldTouched('tfaCode', false);
            return;
        }
        const payload = {
            ...values,
            tfaType: TFAType,
            payeesId: payeeDetails.payees__Id,
        };
        instance
            .post(endpoints.accounts.pullFunds, payload)
            .then((res) => {
                if (res.data.details.bApproved === false) {
                    //setErrorMessage('Approval failed');//1
                    //2
                    dispatch(closeModal());
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                } else {
                    onCompletion();
                    dispatch(closeModal());
                    // onCompletion();
                }
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                if (isErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (
                        errors.some((error) =>
                            [
                                'Amount',
                                'Reference',
                                'CustomerAssetAccountsId',
                                'TfaCode',
                                'amount',
                                'reference',
                                'customerAssetAccountsId',
                                'tfaCode',
                            ].includes(error.fieldName)
                        )
                    ) {
                        errors.forEach((error) =>
                            formikHelpers.setFieldError(
                                toCamelCase(error.fieldName) as string,
                                translateErrorCode(error.messageCode)
                            )
                        );
                    } else setErrorMessage(translateErrorCode(errors[0].messageCode));
                } else {
                    setErrorMessage('Something went wrong');
                }
            });
    };

    const createModalButtons = (submitfn: () => void, submitting: boolean) => (
        <div className="ModalNavigation">
            <Button onClick={() => dispatch(closeModal())} priority="secondary">
                Close
            </Button>
            <Button
                onClick={
                    loading === 'loading failed'
                        ? () => dispatch(closeModal())
                        : pullFundsFinal
                        ? submitfn
                        : () => setPullFundsFinal(true)
                }
                priority="primary"
                // disabled={!pullFundsFinal || submitting}
                disabled={submitting || loading === 'loading'}
                type={pullFundsFinal ? 'submit' : 'button'}
            >
                {loading !== 'loading succeeded'
                    ? 'Cancel'
                    : pullFundsFinal
                    ? 'Pull Funds'
                    : 'Next'}
            </Button>
        </div>
    );
    if (!pullFundsDetails)
        return (
            <Modal title={`${'Pull Funds'}`}>
                <div className="PullFundsModal Loading">
                    <p>Getting account details</p>
                    <Spinner className="NoPosition" />
                </div>
            </Modal>
        );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handlePullFunds}
            validationSchema={validationSchema}
        >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Modal
                    title={`${'Pull Funds'}`}
                    customButtons={createModalButtons(submitForm, isSubmitting)}
                >
                    <Form>
                        {!pullFundsDetails ? (
                            <>
                                {!errorMessage && (
                                    <div className="PullFundsModal Loading">
                                        <Spinner positionAbsolute />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="PullFundsModal">
                                {!pullFundsFinal ? (
                                    <>
                                        {pullFundsDetails.payeeType === 'BankAccount' && (
                                            <FormTextReadOnlyField
                                                label={'Account official name'}
                                                value={pullFundsDetails.accountOfficialName}
                                                sideBySide={false}
                                            />
                                        )}
                                        <FormTextReadOnlyField
                                            label={'Owner name'}
                                            value={pullFundsDetails.ownerName}
                                            sideBySide={false}
                                        />
                                        {pullFundsDetails.payeeType === 'BankAccount' && (
                                            <>
                                                <FormTextReadOnlyField
                                                    label={'Account number'}
                                                    value={pullFundsDetails.achAccountNumber}
                                                    sideBySide={false}
                                                />
                                                <FormTextReadOnlyField
                                                    label={`Routing number`}
                                                    value={pullFundsDetails.achRoutingNumber}
                                                    sideBySide={false}
                                                />
                                                {pullFundsDetails.balance != null && (
                                                    <FormTextReadOnlyField
                                                        label={`Balance ${
                                                            pullFundsDetails.currencyCode
                                                                ? `(${pullFundsDetails.currencyCode})`
                                                                : ''
                                                        }`}
                                                        value={pullFundsDetails.balance}
                                                        sideBySide={false}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {pullFundsDetails.payeeType === 'Card' && (
                                            <>
                                                <FormTextReadOnlyField
                                                    label={'Card Number'}
                                                    value={
                                                        pullFundsDetails.cardLast4Digits
                                                            ? `XXXX XXXX XXXX ${pullFundsDetails.cardLast4Digits}`
                                                            : '-'
                                                    }
                                                    sideBySide={false}
                                                />
                                                <FormTextReadOnlyField
                                                    label={`Card Provider`}
                                                    value={pullFundsDetails.cardCompany ?? ' - '}
                                                    sideBySide={false}
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            priority="tertiary"
                                            className="InlineLink"
                                            style={{ paddingLeft: '0', marginBottom: '10px' }}
                                            onClick={() => {
                                                setPullFundsFinal(false);
                                                setErrorMessage('');
                                                setFieldValue('amount', undefined);
                                                setFieldValue('reference', '');
                                                setFieldValue('showTfa', false);
                                                setFieldValue('customerAssetAccountsId', 0);
                                            }}
                                            type="button"
                                        >
                                            {'< Back'}
                                        </Button>
                                        <AccountSelectField
                                            pullFundsDetails={pullFundsDetails}
                                            setDisableReferenceField={setDisableReferenceField}
                                            setFieldValue={setFieldValue}
                                        />
                                        <FormTextField
                                            field="amount"
                                            label={`Amount`}
                                            required={true}
                                            inputMode="decimal"
                                            type="number"
                                            sideBySide={false}
                                        />

                                        <FormTextField
                                            field="reference"
                                            label={`Reference`}
                                            required={true}
                                            type="text"
                                            sideBySide={false}
                                            disabled={disableReferenceField}
                                        />
                                        {values.showTfa && (
                                            <TFAField
                                                field={'tfaCode'}
                                                label={'TFA Code'}
                                                required={true}
                                                toggleTFAType={toggleTFAType}
                                                tfaType={TFAType}
                                                autoFocus
                                                sideBySide={true}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {errorMessage && <div className="ErrorText">{errorMessage}</div>}
                        <button type="submit" style={{ display: 'none' }} />
                    </Form>
                </Modal>
            )}
        </Formik>
    );
};

const AccountSelectField = ({
    pullFundsDetails,
    setDisableReferenceField,
    setFieldValue,
}: {
    pullFundsDetails: PullFundsDetails;
    setDisableReferenceField: (b: boolean) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) => {
    const onChange = useCallback(
        (value) => {
            const matchedAccount = pullFundsDetails.customerAssetAccounts?.find(
                (account) => account.id === value
            );
            setDisableReferenceField(matchedAccount?.bUseStaticReference ?? false);
            setFieldValue(
                'reference',
                matchedAccount?.bUseStaticReference ? matchedAccount.staticReference ?? '' : ''
            );
        },
        [pullFundsDetails, setDisableReferenceField, setFieldValue]
    );
    return (
        <WrappedFormSingleSelectField
            fieldName={'customerAssetAccountsId'}
            options={
                !pullFundsDetails.customerAssetAccounts
                    ? []
                    : pullFundsDetails.customerAssetAccounts.map((a) => ({
                          value: a.id,
                          label: a.name,
                      }))
            }
            label={'Account to fund'}
            onChange={onChange}
            hideWhenSingleValue
        />
    );
};

const FormTextReadOnlyField = ({
    label,
    value,
    sideBySide,
}: {
    label: string;
    value: any;
    sideBySide: boolean;
}) => {
    return (
        <div className={`FormBox ${sideBySide ? 'FormSideBySide' : ''}`}>
            <div className="FormLabel" style={{ marginBottom: '0' }}>
                <label>{label}</label>
            </div>
            <div className="FormField">
                <input
                    className={'EditBox ViewOnly'}
                    style={{ paddingTop: '0' }}
                    readOnly={true}
                    value={value}
                />
            </div>
        </div>
    );
};

const PullFundsErrorCodes: { [k: string]: string } = {
    ...ERROR_CODES,

    Insufficient_Funds: 'Insufficient funds',
    Screening_Failed: 'Screening checks failed',
    Funding_Already_Pending: 'Funding request already submitted - pending approval',
};

const translateErrorCode = (code: string | keyof typeof PullFundsErrorCodes) => {
    if (PullFundsErrorCodes[code as keyof typeof PullFundsErrorCodes])
        return PullFundsErrorCodes[code as keyof typeof PullFundsErrorCodes];
    else return PullFundsErrorCodes.Generic;
};
