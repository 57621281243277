import { useNavigate } from '@reach/router';
import instance from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { useDispatch } from 'react-redux';
import { getCustomerComponentResources } from 'reducers/componentResources';
import { CardSubPage } from './CardPage';

export const DeactivateCard = ({ cardId }: { cardId: number }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleDeactivate = () => {
        instance
            .post(endpoints.cards.deactivateCard, { topUpCardsId: cardId })
            .then((res) => {
                Toast.openSuccessToast('Your card has been deactivated successfully');
                dispatch(getCustomerComponentResources());
                navigate(`../${cardId}/${CardSubPage.TRANSACTIONS}`);
            }) // TODO add success message
            .catch((err) => Toast.openGenericErrorToast()); // TODO add error handling
    };
    return (
        <div className="DectivateCardPage FormPage">
            <p className="WarningText">
                Deactivating this card will void any attempted transactions. You can undo this
                action
            </p>
            <Button variety="full" onClick={handleDeactivate}>
                Deactivate Card
            </Button>
        </div>
    );
};
