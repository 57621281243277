import {
    FieldRenderers,
    FormBuilder as InternalFormBuilder,
    FormBuilderProps,
} from '@avamae/formbuilder';
import React from 'react';
import FormTextField from './FormTextField';
import { useFormikContext } from 'formik';
import { useAdditionalData } from 'helpers/categoryHelpers/useAdditionalData';

type Props<T> = {} & FormBuilderProps<T>;

export const FormBuilder = <T,>(props: Props<T>) => {
    const {
        fieldRenderers,
        overrideFormFieldRenderer,
        data,
        loadingFallback,
        formValidationErrors,
        readOnly,
        additionalData,
        hiddenFields,
        readOnlyFields,
        fieldProps,
    } = props;
    const { values } = useFormikContext();
    const { additionalData: _additionalData, pending } = useAdditionalData(data, values as any);
    const _fieldRenderers: FieldRenderers<T> = {
        decimal: (key, field) => (
            <FormTextField
                key={key}
                field={field.name.toString()}
                label={field.label}
                readOnly={
                    readOnly || (readOnlyFields ? readOnlyFields[field.name as string] : false)
                }
                inputProps={
                    fieldProps
                        ? { type: 'number', ...fieldProps[field.name as string] }
                        : { type: 'number' }
                }
                required={false}
            />
        ),
        ...props.fieldRenderers,
    };
    return (
        <InternalFormBuilder
            {...props}
            fieldRenderers={_fieldRenderers}
            additionalData={additionalData || _additionalData}
        />
    );
};
