import React from 'react';

export type CellType =
    | 'default'
    | 'checkboxCell'
    | 'buttonCell'
    | 'filterCell'
    | 'rowOptionCell'
    //| 'filterCell2';
    | 'filterCell-tableHeader';

export type CellOptions = {
    boldText?: boolean;
    greyText?: boolean;
    noPaddingLeft?: boolean;
    noPaddingRight?: boolean;
    noSortIcon?: boolean;
    overflowVisible?: boolean;
    additionalClassNames?: string;
    cellType?: CellType;
    width?: 'Small' | 'Medium' | 'Large' | 'SemiLarge' | 'SemiSmall' | 'Mini';
    DSIH?: boolean;
    style?: React.CSSProperties;
};

export type CellProps = {
    text?: string;
    title?: string;
    options?: CellOptions;
    sortable?: boolean;
    href?: string | null;
    onClick?(e: React.MouseEvent): void;
};

const Cell: React.FC<CellProps> = ({
    children,
    text,
    title = '',
    options,
    href,
    sortable,
    onClick,
}) => {
    const getCellClassName = () => {
        let className = 'Cell';
        if (options) {
            const {
                boldText,
                greyText,
                noPaddingLeft,
                noPaddingRight,
                noSortIcon,
                overflowVisible,
                additionalClassNames,
                cellType,
                width,
            } = options;
            if (boldText) {
                className += ' BoldText';
            }
            if (greyText) {
                className += ' GreyText';
            }
            if (noPaddingLeft) {
                className += ' NoPaddingLeft';
            }
            if (noPaddingRight) {
                className += ' NoPaddingRight';
            }
            if (cellType === 'buttonCell') {
                className += ' ButtonCell';
            }
            if (noSortIcon) {
                className += ' NoSortIcon';
            }
            if (overflowVisible) {
                className += ' OverflowVisible';
            }
            if (cellType === 'checkboxCell') {
                className += ' CheckboxCell';
            }
            if (cellType === 'rowOptionCell') {
                className += ' RowOptionCell';
            }
            if (cellType === 'filterCell') {
                className += ' FilterCell';
            }
            if (width === 'Small') {
                className += ' Small';
            }

            if (width === 'Large') {
                className += ' Large';
            }
            if (width === 'SemiSmall') {
                className += ' SemiSmall';
            }
            if (width === 'SemiLarge') {
                className += ' SemiLarge';
            }
            if (width === 'Mini') {
                className += ' Mini';
            }
            if (additionalClassNames) {
                className += ' ' + additionalClassNames;
            }
        }

        if (sortable) {
            className += ' Sortable';
        }
        return className;
    };

    const cellTitle = text ? text : title;

    return href ? (
        options?.DSIH ? (
            <div
                className={getCellClassName()}
                title={cellTitle}
                style={{ ...options.style, cursor: 'pointer' }}
                onClick={() => window.open(href, '_blank')}
                dangerouslySetInnerHTML={{ __html: children as any }}
            />
        ) : (
            <div
                className={getCellClassName()}
                title={cellTitle}
                style={{ ...options?.style, cursor: 'pointer' }}
                onClick={() => window.open(href, '_blank')}
            >
                {text && <span>{text}</span>}
                {children}
            </div>
        )
    ) : options?.DSIH ? (
        <div
            className={getCellClassName()}
            title={cellTitle}
            style={{ ...options?.style, cursor: 'pointer' }}
            dangerouslySetInnerHTML={{ __html: children as any }}
        />
    ) : (
        <div
            className={getCellClassName()}
            title={cellTitle}
            style={options?.style}
            onClick={onClick}
        >
            {text && <span>{text}</span>}
            {children}
        </div>
    );
};

export { Cell };
