export enum JumioStage {
    IdFrame = 'IdFrame', //Show initial modal
    IdFrameSubmitted = 'IdFrameSubmitted', //show spinner
    IdFrameFailed = 'IdFrameFailed',
    AddressFrameForm = 'AddressFrameForm',
    AddressFrame = 'AddressFrame',
    AddressFrameSubmitted = 'AddressFrameSubmitted',
    AddressFrameFailed = 'AddressFrameFailed',
    FrameCooldown = 'FrameCooldown', // too many attempts
    Success = 'Success', // newly verified
    AwaitingManualVerification = 'AwaitingManualVerification',
    PendingVerification = 'PendingVerification',
    Verified = 'Verified', // pass straight through
    Rejected = 'Rejected', // rejected by admin
    ConsentCollection = 'IdCollectConsent', // get user consent
}

export type JumioVerificationStatusResponse = {
    status: JumioStage;
    additionalData: AdditionalData;
};

export type AdditionalData = {
    iframeUrl?: string;
    bManualAvailable?: boolean;
} | null;
