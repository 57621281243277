import { Navigation } from 'components/navigation/Navigation';
import { useCreateThemeStyle } from 'helpers/useCreateThemeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSignedIn } from '../../reducers/auth';
import Private from '../private/Private';
import { AppPath } from 'appConstants';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
/** @jsxImportSource @emotion/react */

type Props = {
    isPublic?: boolean;
    className?: string;
};

const footerHiddenPages = [AppPath.SIGNIFICANT_PARTY_JUMIO, AppPath.MANAGEES_JUMIO];

const Page: React.FC<Props> = ({ children, className = '', isPublic = false }) => {
    const { pathname } = useLocation();
    const isSignedIn = useSelector(selectSignedIn);

    const styles = useCreateThemeStyle();

    const hideFooterAndLoginButtons = footerHiddenPages.some((path) => pathname.includes(path));

    if (isPublic) {
        return (
            <div
                className={classNames('Page', className, {
                    FooterHidden: hideFooterAndLoginButtons,
                })}
                css={styles}
            >
                <Navigation hideFooterAndLoginButtons={hideFooterAndLoginButtons}>
                    {children}
                </Navigation>
            </div>
        );
    }
    return (
        <Private isSignedIn={isSignedIn}>
            <div
                className={classNames('Page', className, {
                    FooterHidden: hideFooterAndLoginButtons,
                })}
                css={styles}
            >
                <Navigation hideFooterAndLoginButtons={hideFooterAndLoginButtons}>
                    {children}
                </Navigation>
            </div>
        </Private>
    );
};

export default Page;
