import { useField } from 'formik';
import Button from '../../../components/button/Button';
import { Modal } from '../../../components/modal/Modal';
import { BulkTransferOptions, ParsedCsv } from './models';
import { formatGeneralCurrency } from '../../../components/modals/CryptoExchangeModal/FromTo';
import { matchPayee } from './helpers';

type Props = {
    handleCloseModal(): void;
    handleDeleteTransfer(): void;
    accounts: BulkTransferOptions['accounts'];
    fieldname: string;
};

export const BulkTransferDeleteModal: React.FC<Props> = ({
    handleCloseModal,
    handleDeleteTransfer,
    accounts,
    fieldname,
}) => {
    const [{ value }] = useField<ParsedCsv>(fieldname);

    const account = (accounts ?? []).find((acc) => acc.id.toString() === value.sourceAccountId?.toString());
    const payee = account && value.payeeId ? matchPayee(value.payeeId, account) : null;

    return (
        <Modal
            title="Delete transfer"
            onCloseModal={handleCloseModal}
            customButtons={
                <div className="ModalNavigation">
                    <Button onClick={handleCloseModal} priority="secondary" type="button">
                        Close
                    </Button>
                    <Button
                        onClick={handleDeleteTransfer}
                        priority="primary"
                        color="third"
                        type="button"
                    >
                        Delete Transfer
                    </Button>
                </div>
            }
        >
            <div>
                <p>Are you sure you want to delete this transfer?</p>
                {payee && (
                    <p className="NoMargin">
                        Transfer to: <b>{payee.name}</b>
                    </p>
                )}
                {!payee && value.name && (
                    <p className="NoMargin">
                        Transfer to: <b>{value.name}</b>
                    </p>
                )}
                {typeof value.amount === 'number' && (
                    <p>
                        Amount:{' '}
                        <b>
                            {account?.currencySymbol}
                            {formatGeneralCurrency(
                                value.amount,
                                account?.currencyCode ?? '',
                                true,
                                { minimumFractionDigits: 2 }
                            )}
                        </b>
                    </p>
                )}
            </div>
        </Modal>
    );
};
