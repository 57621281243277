/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { ErrorM } from 'components/form/ErrorM';

type RadioButtonsProps = {
    className?: string;
    options: string[];
    fieldname: string;
    label?: string;
    customOnChange?(value?: any): void;
    error?: boolean;
    booleanField?: boolean;
    disabled?: boolean;
    showWithOnlyOneOption?: boolean;
    setToNullWhenOptionDoesNotExist?: boolean;
};
export const RadioButtons: React.FC<RadioButtonsProps> = ({
    className,
    options,
    fieldname,
    label,
    customOnChange,
    error,
    booleanField,
    disabled = false,
    showWithOnlyOneOption = false,
    setToNullWhenOptionDoesNotExist: noOptionSetNull = false,
}) => {
    const [field, meta, helpers] = useField(fieldname);

    const onClickHandler = (option: string) => {
        helpers.setTouched(true, false);
        booleanField
            ? helpers.setValue(option === 'Yes' || option === 'US' ? true : false)
            : helpers.setValue(option);
    };

    const isBooleanFieldSelected = (option: string) => {
        if (
            (field.value === true && (option === 'Yes' || option === 'US')) ||
            (field.value === false && (option === 'No' || option === 'Other'))
        ) {
            return true;
        } else {
            return false;
        }
    };

    let errorDefined = meta.error && meta.touched ? true : false;

    useEffect(() => {
        customOnChange && customOnChange(field.value);
    }, [field.value]); // we can use initialValues here since they shouldn't change

    useEffect(() => {
        if (booleanField) return; // don't do this validation for boolean fields
        if (options.length === 0 && field.value != null) return helpers.setValue(null);
        if (options.length > 0 && !options.includes(field.value)) {
            if (!noOptionSetNull) helpers.setValue(options[0]);
            else if (field.value !== null) helpers.setValue(null);
        }

        if (options.length === 1 && field.value !== options[0] && !noOptionSetNull)
            helpers.setValue(options[0]);
    }, [options, helpers, field.value, booleanField, noOptionSetNull]);

    if (options.length === 1 && !showWithOnlyOneOption) return null;
    return (
        <>
            {label && (
                <div className="FormLabel">
                    <label>{label}</label>
                </div>
            )}
            <div className={`${className ?? 'RadioButtons'}${errorDefined ? ' NoMargin' : ''}`}>
                {options.map((option, index) => (
                    <React.Fragment key={index}>
                        <RadioButton
                            label={option}
                            selected={
                                booleanField
                                    ? isBooleanFieldSelected(option)
                                    : option === field.value
                            }
                            onClick={() => onClickHandler(option)}
                            error={errorDefined}
                            disabled={disabled}
                        />
                    </React.Fragment>
                ))}
            </div>
            {errorDefined && (
                <div className="RadioButtons--error">
                    <ErrorM name={fieldname} />
                </div>
            )}
        </>
    );
};

type RadioButtonProps = {
    label: string;
    subLabel?: string;
    selected: boolean;
    onClick: () => void;
    disabled?: boolean;
    bDangerousHtml?: boolean;
    classname?: string;
    error?: boolean | undefined;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
    label,
    subLabel,
    selected,
    onClick,
    disabled,
    bDangerousHtml,
    classname,
    error,
}) => {
    const theme = useTheme();
    const buttonCss = css`
        ${selected
            ? `background-color: ${theme.colors.alertNeutralBackground};
            border-color: ${theme.colors.first};
            span:first-child::before {
                background-color: ${theme.colors.first};
                border-color: ${theme.colors.first};
            }`
            : ''}
    `;
    return (
        <button
            css={buttonCss}
            className={`RadioButton ${selected ? 'Selected' : ''} ${classname} ${
                bDangerousHtml === true ? 'dangerous-html' : ''
            } ${error ? ' ErrorInput' : ''}`}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >
            {bDangerousHtml === true ? (
                <div className={`TCContentView`} dangerouslySetInnerHTML={{ __html: label }}></div>
            ) : (
                <>
                    <span>{label}</span>
                    {subLabel && <span className="SubLabel">{subLabel}</span>}
                </>
            )}
        </button>
    );
};
