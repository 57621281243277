import React from 'react';
import ManageBusinessAccount from './ManageBusinessAccount';
import ManageBusinessUsers from './ManageBusinessUsers';

enum ManageBusinessSubpage {
    Documents = 'documents',
    Users = 'users',
}
type Props = { subPage?: string };

function ManageBusiness({ subPage }: Props) {
    switch (subPage) {
        case ManageBusinessSubpage.Documents:
            return <ManageBusinessAccount />;
        case ManageBusinessSubpage.Users:
            return <ManageBusinessUsers />;
        default:
            return <ManageBusinessAccount />;
    }
}

export default ManageBusiness;
