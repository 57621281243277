import React from "react";

type ItemProps = {
  onClick(): void;
  icon: any;
  name: string;
  imgAlt: string;
  disabled?: boolean;
};

type Props = ItemProps;

const Item: React.FC<Props> = ({ onClick, icon, name, imgAlt, disabled }) => {
  return (
    <div className={disabled ? "Item Disabled" : "Item"} onClick={onClick}>
      <span>{name}</span>
    </div>
  );
};

export { Item };
