import { useTheme } from '@emotion/react';
import { FoldingCube } from 'better-react-spinkit';

const DEFAULT_LOADING_MESSAGE = 'Loading';

type SubPageLoaderProps = {
    message?: string;
};

export const SubPageLoader: React.FC<SubPageLoaderProps> = ({ message }) => {
    const { colors } = useTheme();
    return (
        <div className="SubPageLoader">
            <FoldingCube color={colors.first} size={80} />
            <h1 className="Message">{message ? message : DEFAULT_LOADING_MESSAGE}</h1>
        </div>
    );
};
