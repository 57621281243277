import { RouteComponentProps } from '@reach/router';
import { DashboardPath } from 'appConstants';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import { IFrameProduct } from 'components/iFrameProduct/IFrameProduct';
import ErrorBoundary from 'components/page/ErrorBoundary';
import { isCardLink, isFiatLink } from 'components/sideMenu/AccountList';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import { SubNavigationMenu } from 'components/subNavigationMenu/SubNavigationMenu';
import { BASE_URL, endpoints } from 'endpoints.config';
import { removeLeadingSlash } from 'helpers/removeLeadingSlash';
import { useBasePath } from 'helpers/useBasePath';
import AccountPage from 'pages/account/AccountPage';
import { AddAccount } from 'pages/account/AddAccount';
import ManageBusiness from 'pages/businessProfile/ManageBusiness';
import CardManagement from 'pages/cardManagement/CardManagement';
import { AddCard } from 'pages/cards/AddCard';
import CardPage from 'pages/cards/CardPage';
import { CryptoPage, CryptoSubPage } from 'pages/crypto/CryptoPage';
import { CryptoAccountsPage, CryptoAccountSubPage } from 'pages/cryptoaccounts/CryptoAccountsPage';
import { ExchangePage, ExchangeSubPage } from 'pages/exchange/ExchangePage';
import { CurrencyExchangePage } from 'pages/currencyExchange/CurrencyExchangePage';
import { HomePage } from 'pages/home/HomePage';
import { ProfilePage, ProfileSubPage } from 'pages/profile/ProfilePage';
import { QuickCoinPage } from 'pages/quickCoin/QuickCoinPage';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import InfoRequest from '../InfoRequest/InfoRequest';
import ManageApprovalsNavigator from '../ManageApprovals/ManageApprovalsNavigator';
import ManageApprovalTypes from '../ManageApprovalTypes/ManageApprovalTypes';
import ApprovalHistoryNavigator from 'pages/ApprovalHistory/ApprovalHistoryNavigator';
import { BulkTransfer } from '../account/BulkTransfer/BulkTransfer';

interface DashboardPageContentProps extends RouteComponentProps {
    page?: string;
    sub_page?: string;
    sub_sub_page?: string;
}

export const DashboardContentPage: React.FC<DashboardPageContentProps> = ({
    page,
    sub_page,
    sub_sub_page,
    children,
    ...routeProps
}) => {
    const basePath = useBasePath();
    const { customerMenuLinks } = useSelector(selectComponentResources);
    const navigationItem = customerMenuLinks?.find((item) => item.path === page);
    // const navigationItems = customerMenuLinks?.filter((item) => item.path === page); //accounts and fundingaccounts share the same /account path, so there can be two navigationlinks here, we need to find which this item falls under
    // const navigationItem =
    //     navigationItems?.length === 1
    //         ? navigationItems[0]
    //         : sub_page
    //         ? navigationItems?.find(
    //               (item) => item.accounts?.some((account) => account.id == (sub_page as any)) // we can do loose comparison here between the numeric id and sub_page string
    //           )
    //         : undefined;
    const pageHasSubNavigation = !!navigationItem?.childElements || !!navigationItem?.accounts;
    // If the item has no children then we render it's own content. Else we render the
    // selected sub_page.

    const getItemToRender = (navigationItem: SideMenuLink | undefined) => {
        if (!navigationItem || (!navigationItem?.childElements && !navigationItem?.accounts))
            return navigationItem;
        if (navigationItem.accounts) {
            const account = navigationItem.accounts.find(
                (account) => account.id.toString() === sub_page
            );
            return account?.childElements?.find((item) => item.path === sub_sub_page);
        }
        return navigationItem?.childElements.find((item) => item.path === sub_page);
    };
    const getSubNavigationItems = (navigationItem: SideMenuLink | undefined) => {
        if (navigationItem?.accounts) {
            return navigationItem.accounts.find((account) =>
                isFiatLink(account) || isCardLink(account)
                    ? account.id.toString() === sub_page
                    : account?.code &&
                      sub_page &&
                      account.code.toLowerCase() === sub_page?.toLowerCase()
            )?.childElements;
        } else {
            return navigationItem?.childElements;
        }
    };
    const getAccountInfo = (navigationItem: SideMenuLink | undefined) => {
        if (navigationItem?.accounts) {
            const account = navigationItem.accounts.find((account) =>
                isFiatLink(account) || isCardLink(account)
                    ? account.id.toString() === sub_page
                    : account?.code &&
                      sub_page &&
                      account.code.toLowerCase() === sub_page?.toLowerCase()
            );
            if (!account || isCardLink(account)) return null;
            return {
                balance: account.balance,
                availableBalance: account.availableBalance,
                symbol: isFiatLink(account) ? account.currencySymbol : account.symbol,
            };
        } else {
            return null;
        }
    };
    const itemToRender = getItemToRender(navigationItem);
    const subNavigationItems = pageHasSubNavigation ? getSubNavigationItems(navigationItem) : null;

    const isIFramePage = itemToRender?.productType === 'IFrame';

    const contentContainerClass = `DashboardPageContent ${isIFramePage ? 'IFrame' : 'LocalContent'}
        ${
            pageHasSubNavigation && subNavigationItems && subNavigationItems.length > 1
                ? 'WithSubNavigation'
                : ''
        }`;

    const [cryptoEndpoints, stablecoinEndpoints] = useMemo(
        () => [endpoints.cryptosmodule, endpoints.stablecoinmodule],
        []
    );

    const accountInfo = getAccountInfo(navigationItem);

    return (
        <div className="DashboardPage">
            <ErrorBoundary key={page ?? '' + sub_page + sub_sub_page} fallback={<ErrorFallback />}>
                {!!subNavigationItems && subNavigationItems.length >= 1 && (
                    <SubNavigationMenu
                        items={subNavigationItems}
                        basePath={`${basePath}/${page}`}
                        accountInfo={accountInfo}
                    />
                )}
                <div className={contentContainerClass}>
                    {page === 'home' ? (
                        <HomePage sub_page={sub_page} />
                    ) : page === 'accounts' && sub_page === 'addaccount' ? (
                        <AddAccount />
                    ) : isIFramePage ? (
                        <IFrameProduct apiPath={`${BASE_URL}${itemToRender?.apiPath}`} />
                    ) : page === 'quick-crypto' ? (
                        <QuickCoinPage />
                    ) : page === 'cards' && sub_page === 'addcard' ? (
                        <AddCard />
                    ) : page === removeLeadingSlash(DashboardPath.MANAGE) ? (
                        <ManageBusiness subPage={sub_page} />
                    ) : page === removeLeadingSlash(DashboardPath.APPROVALS) ? (
                        <ManageApprovalsNavigator subPage={sub_page} />
                    ) : page === removeLeadingSlash(DashboardPath.APPROVAL_HISTORY) ? (
                        <ApprovalHistoryNavigator subPage={sub_page} />
                    ) : page === removeLeadingSlash(DashboardPath.APPROVAL_TYPES) ? (
                        <ManageApprovalTypes />
                    ) : page === removeLeadingSlash(DashboardPath.INFORMATION_REQUEST) ? (
                        <InfoRequest />
                    ) : page === removeLeadingSlash(DashboardPath.BULK_TRANSFER) ? (
                        <BulkTransfer />
                    ) : page === removeLeadingSlash(DashboardPath.CARDMANAGEMENT) ? (
                        <CardManagement subPage={sub_page} />
                    ) : page === removeLeadingSlash(DashboardPath.CRYPTOACCOUNTS) ? (
                        <CryptoAccountsPage
                            ticker={sub_page}
                            subPage={sub_sub_page ?? CryptoAccountSubPage.History}
                        />
                    ) : page === removeLeadingSlash(DashboardPath.CURRENCYEXCHANGE) ? (
                        <CurrencyExchangePage />
                    ) : (
                        navigationItem &&
                        (navigationItem.path === removeLeadingSlash(DashboardPath.CRYPTO) ? (
                            <CryptoPage
                                subPage={sub_sub_page ?? CryptoSubPage.TRADE}
                                crypto={sub_page}
                                endpoints={cryptoEndpoints}
                            />
                        ) : navigationItem.path === removeLeadingSlash(DashboardPath.EXCHANGE) ? (
                            <ExchangePage subPage={sub_page as ExchangeSubPage} />
                        ) : navigationItem.path === removeLeadingSlash(DashboardPath.STABLECOIN) ? (
                            <CryptoPage
                                crypto={sub_page}
                                subPage={sub_sub_page ?? CryptoSubPage.TRADE}
                                endpoints={stablecoinEndpoints}
                            />
                        ) : navigationItem.path === removeLeadingSlash(DashboardPath.PROFILE) ? (
                            <ProfilePage subPage={sub_page as ProfileSubPage} />
                        ) : navigationItem.path === removeLeadingSlash(DashboardPath.ACCOUNTS) ||
                          navigationItem.path ===
                              removeLeadingSlash(DashboardPath.FUNDINGACCOUNTS) ? (
                            <AccountPage
                                accountNumber={sub_page || ''}
                                sub_page={sub_sub_page || ''}
                                accountType={
                                    navigationItem.path ===
                                    removeLeadingSlash(DashboardPath.ACCOUNTS)
                                        ? 'Account'
                                        : 'Funding'
                                }
                                {...routeProps}
                            />
                        ) : navigationItem.path === 'cards' ? (
                            <CardPage
                                cardIdNumber={sub_page ?? ''}
                                sub_page={sub_sub_page ?? ''}
                                {...routeProps}
                            />
                        ) : (
                            <h1>{itemToRender?.displayName ?? 'Home'}</h1>
                        ))
                    )}
                </div>
            </ErrorBoundary>
        </div>
    );
};
