import instance, { ApiResponse, ErrorMessage, GENERIC_ERROR_MESSAGE } from 'api';
import { endpoints } from 'endpoints.config';
import { SignInAttempt } from 'reducers/auth';
import {
    AccountDetails,
    EmailVerificationCodePayload,
    RegisterCustomerUserPayload,
    RegisterValidateStepOne,
    SMSTFASetup,
    TFAVerificationPayload,
} from './models';

export type ApiReturn<T = any> = {
    response: T | null;
    errors: ErrorMessage[] | null;
    id?: number;
};

const registerUrl = endpoints.registration.customerUser;
export const postRegisterCustomerUser = async (
    url: string,
    payload: RegisterCustomerUserPayload
): Promise<ApiReturn> => {
    try {
        const res = await instance.post<ApiResponse>(url, {
            ...payload,
        });
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

const validateUrl = endpoints.registration.validateStepOne;
export const postValidateStepOne = async (payload: RegisterValidateStepOne): Promise<ApiReturn> => {
    try {
        const res = await instance.post<ApiResponse>(validateUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error: any) {
        return {
            response: null,
            errors: error?.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

export const parseValidateStepOne = (
    values: RegisterCustomerUserPayload
): RegisterValidateStepOne => {
    return {
        companyName: values.companyName ? values.companyName : '',
        companyEmailAddress: values.companyEmailAddress ? values.companyEmailAddress : '',
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        confirmPassword: values.confirmPassword,
    };
};

const emailVerificationCodeUrl = endpoints.auth.verifyEmailAddress;
export const postEmailVerificationCode = async (
    payload: EmailVerificationCodePayload
): Promise<ApiReturn> => {
    try {
        const res = await instance.post(emailVerificationCodeUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};

const smsTfaSetupUrl = endpoints.auth.requestSmsTfaCode;
export const postSmsTFASetup = async (payload: SMSTFASetup): Promise<ApiReturn> => {
    try {
        const res = await instance.post(smsTfaSetupUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
const appTfaSetupUrl = endpoints.auth.requestTotpCode;
export const postAppTFASetup = async (payload: AccountDetails): Promise<ApiReturn> => {
    try {
        const res = await instance.post(appTfaSetupUrl, {
            username: payload.emailAddress,
            password: payload.password,
        });
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
const enableTfaUrl = endpoints.auth.enableTfa;
export const postEnableTfa = async (payload: TFAVerificationPayload): Promise<ApiReturn> => {
    try {
        const res = await instance.post(enableTfaUrl, payload);
        if (res.data.status === '1') {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res.data,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error.response?.data?.errors
                ? error.response.data.errors
                : [GENERIC_ERROR_MESSAGE],
        };
    }
};
