import React, { Dispatch, SetStateAction } from 'react';
import { Item } from './Item';
import { Transition } from 'react-transition-group';

export type RightMenuItem = {
    icon: string;
    name: string;
    imgAlt: string;
    disabled?: boolean;
    onClick(id?: React.ReactText | null): void;
    notInMenus?: boolean;
};

export type AlternateRightMenuItems = {
    menuItems: RightMenuItem[];
};

export type RightMenuItems = {
    menuItems: RightMenuItem[];
    alternateMenuItems: AlternateRightMenuItems;
};

type RightMenuProps = {
    visible: boolean;
    menuItems: RightMenuItem[];
    alternateMenuItems: AlternateRightMenuItems;
    toggleAltMenuItems: boolean;
    setTransState?: Dispatch<SetStateAction<boolean | null>>;
};

type Props = RightMenuProps;

interface MenuItemsProps {
    toggleAltMenuItems: boolean;
    alternateMenuItems: AlternateRightMenuItems;
    menuItems: RightMenuItem[];
}

const MenuItems: React.FC<MenuItemsProps> = ({
    toggleAltMenuItems,
    alternateMenuItems,
    menuItems,
}) => {
    if (toggleAltMenuItems) {
        return (
            <>
                {alternateMenuItems.menuItems
                    .filter((m) => !m.notInMenus)
                    .map((item, key) => (
                        <Item
                            key={key}
                            onClick={item.onClick}
                            icon={item.icon}
                            name={item.name}
                            imgAlt={item.imgAlt}
                            disabled={item.disabled}
                        />
                    ))}
            </>
        );
    } else {
        return (
            <>
                {menuItems
                    .filter((m) => !m.notInMenus)
                    .map((item, key) => (
                        <Item
                            key={key}
                            onClick={item.onClick}
                            icon={item.icon}
                            name={item.name}
                            imgAlt={item.imgAlt}
                            disabled={item.disabled}
                        />
                    ))}
            </>
        );
    }
};

const RightMenu: React.FC<Props> = ({ visible, setTransState, ...menuItemsProps }) => {
    return (
        <Transition in={visible} timeout={0} mountOnEnter>
            {(transitionState) => {
                if (setTransState) {
                    setTransState(
                        transitionState === 'entered'
                            ? true
                            : transitionState === 'exited'
                            ? false
                            : null
                    );
                }
                return (
                    <div className={'RightMenu ' + transitionState}>
                        <div className="ItemsContainer">
                            <MenuItems {...menuItemsProps} />
                        </div>
                    </div>
                );
            }}
        </Transition>
    );
};

export { RightMenu };
