import { SelectOption } from 'components/form/FormSingleSelectField';
import React from 'react';
import { useEffect } from 'react';
import { RadioButton } from './radiobuttons';
import { ErrorM } from 'components/pagination/ErrorM';
import { useField } from 'formik';
import logger from 'helpers/logger';

type SelectOptionRadioButtonsProps = {
    className?: string;
    options: SelectOption[];
    fieldname: string;
    label?: string;
    customOnChange?(): void;
    error?: boolean;
    booleanField?: boolean;
    disabled?: boolean;
};

export const SelectOptionRadioButton: React.FC<SelectOptionRadioButtonsProps> = ({
    className,
    options,
    fieldname,
    label,
    customOnChange,
    error,
    disabled = false,
}) => {
    const [field, meta, helpers] = useField(fieldname);

    const onClickHandler = (option: string) => {
        helpers.setValue(option);
        helpers.setTouched(true);
    };

    let errorDefined = meta.error && meta.touched ? true : false;

    useEffect(() => {
        customOnChange && customOnChange();
    }, [field.value]); // we can use initialValues here since they shouldn't change

    useEffect(() => {
        if (options.length === 0 && field.value != null) return helpers.setValue(null);
        if (
            options.length > 0 &&
            field.value != null &&
            !options.some((option) => option.value == field.value)
        )
            helpers.setValue(options[0].value);
        if (options.length === 1 && field.value !== options[0].value)
            helpers.setValue(options[0].value);
    }, [options, helpers, field.value]);

    if (options.length === 1) return null;
    return (
        <>
            {label && (
                <div className="FormLabel">
                    <label>{label}</label>
                </div>
            )}
            <div className={`${className ?? 'RadioButtons'}${errorDefined ? ' NoMargin' : ''}`}>
                {options.map((option, index) => (
                    <React.Fragment key={index}>
                        <RadioButton
                            label={option.label}
                            selected={option.value == field.value}
                            onClick={() => onClickHandler(option.value)}
                            error={errorDefined}
                            disabled={disabled}
                        />
                    </React.Fragment>
                ))}
            </div>
            {errorDefined && (
                <div className="RadioButtons--error">
                    <ErrorM name={fieldname} />
                </div>
            )}
        </>
    );
};
