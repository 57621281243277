import Button from 'components/button/Button';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Spinner } from 'components/spinner/Spinner';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { Formik, Form } from 'formik';
import React from 'react';
import { useState } from 'react';
import { EmailVerificationCodePayload, EmailVerificationProps } from './models';
import { postEmailVerificationCode } from './RegisterApi';
import { handleTFAError } from './RegistrationErrorHandling';

export const EmailVerification: React.FC<EmailVerificationProps> = ({
    accountDetails,
    onComplete,
}) => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues: EmailVerificationCodePayload = {
        username: accountDetails.emailAddress,
        password: accountDetails.password,
        emailVerificationCode: '',
        bResendCode: false,
    };

    const handleEmailCode = (resendCode: boolean, values: EmailVerificationCodePayload) => () => {
        setLoading(true);
        (async () => {
            const result = await postEmailVerificationCode(
                resendCode ? { ...values, bResendCode: true, emailVerificationCode: '' } : values
            );
            if (result.response) {
                setLoading(false);
            }
            if (result.errors && result.errors.length > 0) {
                setError(handleTFAError(result.errors[0]));
                setLoading(false);
            }
        })();
    };

    const handleSubmit = (values: EmailVerificationCodePayload) => {
        setLoading(true);
        (async () => {
            const result = await postEmailVerificationCode(values);
            if (result.response) {
                onComplete();
            }
            if (result.errors && result.errors.length > 0) {
                setError(handleTFAError(result.errors[0]));
            }
            setLoading(false);
        })();
    };

    const handleResetError = () => {
        setError(null);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(formikProps) => {
                return (
                    <Form onChange={handleResetError} className="EmailVerificationForm">
                        {loading && <Spinner />}
                        <div className="RegisterFormWidth">
                            <h1>Email Verification</h1>
                            <p>Enter the code sent to your email address</p>
                            <ValidationCodeField
                                field={'emailVerificationCode'}
                                label={'Your verification code'}
                                value={formikProps.values.emailVerificationCode}
                                required={true}
                                maxLength={6}
                                autoFocus
                                autoComplete={false}
                                holdFocus
                            >
                                <ThemedLink
                                    onClick={handleEmailCode(true, formikProps.values)}
                                    className="RegisterLink"
                                >
                                    Resend code
                                </ThemedLink>
                            </ValidationCodeField>
                            {error && <div className="ErrorMessage">{error}</div>}
                            <Button
                                priority="primary"
                                variety="full"
                                type="submit"
                                disabled={loading}
                            >
                                Continue
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
