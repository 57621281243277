import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { createContext, useContext } from 'react';
import { DocumentRejection } from '../BusinessVerificationV2Models';

type TVerificationContext = {
    selectOptions?: VerificationDropdownOptions;
    rejectedDocuments?: DocumentRejection;
};

export type VerificationDropdownOptions = {
    entityTypes?: SelectOption[];
    regionsOfOperation?: SelectOption[];
    availableCountries?: CountryOption[];
    accountPurposes?: SelectOption[];
    businessActivities?: SelectOption[];
    fundingSources?: SelectOption[];
    transactionVolumes?: SelectOption[];
    monthlyUsdEquivalents?: SelectOption[];
    uboReportingExemptions?: SelectOption[];
};

type CountryOption = {
    id: number;
    countryName: string;
    isoCode: string;
    states: { id: number; stateProvinceName: string; ansiCode: string }[] | null;
};

export const VerificationContext = createContext<TVerificationContext | null>(null);

export const useVerificationContext = () => {
    const context = useContext(VerificationContext);
    if (!context) throw new Error('useVerificationContext used outside provider');
    return context;
};
