import { FieldArray, useField, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import FormTextField from 'components/form/FormTextField';
import SingleSelectCheckList from '../SingleSelectCheckList';
import { FormDateField } from 'components/form/FormDateField';
import UploadField from '../../../helpers/UploadField';
import Button from 'components/button/Button';
import InternalInvestor from './InternalInvestor';
import { CorporateApp } from '../../corporateAppModel';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { Type } from '../../CorporateApp';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { DocumentRejection } from 'components/businessVerification/BusinessVerification';
import instance from '../../../../../api';
import { formatValues } from '../../../helpers/ExitContinueButtons';
import { endpoints } from '../../../../../endpoints.config';
import { getEquivalentBackendStage } from '../../../businessVerificationModels';
import { Toast, ToastMessageReason } from '../../../../../helpers/toast';
interface Props {
    values: any;
    disable: boolean;
    availableCountries: SelectOption[];
    sourceFundTypes: Type[];
    expandAll: boolean;
    rejectedDocuments: DocumentRejection | null | undefined;
}
const Funds: React.FC<Props> = ({
    values,
    availableCountries,
    sourceFundTypes,
    disable,
    expandAll,
    rejectedDocuments,
}) => {
    const [expand, setExpand] = useState<boolean>(false);
    const [completedSection, setCompletedSection] = useState(false);
    const { errors, touched, setFieldError } = useFormikContext<CorporateApp>();
    const idRef = useRef<number>(1);

    const validateSection = () => {
        if (!errors.corporate?.authorizedCapital && touched.corporate?.authorizedCapital) {
            setCompletedSection(true);
        } else {
            setCompletedSection(false);
        }
    };

    useEffect(() => {
        if (expandAll) {
            setExpand(true);
        }
    }, [expandAll]);

    useEffect(() => {
        validateSection();
        if (errors.corporate?.authorizedCapital) {
            setExpand(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.corporate?.authorizedCapital, touched.corporate?.authorizedCapital]);

    useEffect(() => {
        if (
            rejectedDocuments &&
            rejectedDocuments.hasOwnProperty('authorizedCapitalDocument') &&
            rejectedDocuments.authorizedCapitalDocument
        ) {
            if (
                rejectedDocuments.authorizedCapitalDocument ===
                values.corporate.authorizedCapital.proofOfSourceOfFundsFileName
            ) {
                setFieldError(
                    'corporate.authorizedCapital.proofOfSourceOfFundsFileName',
                    `File named ${values.corporate.authorizedCapital.proofOfSourceOfFundsFileName} has been rejected, please upload another file`
                );
            }
        }
        if (
            rejectedDocuments &&
            rejectedDocuments.hasOwnProperty('fundsSupportingDocuments') &&
            rejectedDocuments.fundsSupportingDocuments &&
            rejectedDocuments.fundsSupportingDocuments.length > 0
        ) {
            rejectedDocuments.fundsSupportingDocuments.forEach((rejectedFilename) => {
                let index = -1;

                for (
                    let i = 0;
                    i < values.corporate.authorizedCapital.supportingDocuments.length;
                    i++
                ) {
                    if (
                        values.corporate.authorizedCapital.supportingDocuments[i].fileName ===
                        rejectedFilename
                    ) {
                        index = i;
                        setFieldError(
                            `corporate.authorizedCapital.supportingDocuments[${index}].fileName`,
                            `File named ${rejectedFilename} has been rejected, please upload another file`
                        );
                        break;
                    }
                }
            });
        }
    }, []);

    const internalInvestor = {
        number: 0,
        employeeName: '',
        position: '',
        amount: '',
        date: '',
        source: '',
    };

    // Save form on file change to avoid form falling out of sync with BE saved files
    const [{ value }] = useField('corporate.authorizedCapital.supportingDocuments');
    useEffect(() => {
        saveFileAfterDocumentChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    const saveFileAfterDocumentChange = async () => {
        try {
            await instance.post(endpoints.businessverificationmodule.saveFormData, {
                ...formatValues(values),
                businessVerificationStep: getEquivalentBackendStage(
                    values.businessVerificationStep
                ),
            });
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };

    return (
        <div>
            <h3>Authorized Capital</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.authorizedCapital'}
                    label={'Authorized Capital'}
                    required={true}
                    type="number"
                    sideBySide={true}
                    asterisk={true}
                />
                <FormTextField
                    field={'corporate.authorizedCapital.sourceOfFunds'}
                    label={'Source of Authorized Capital'}
                    required={true}
                    maxLength={500}
                    sideBySide={true}
                    asterisk={true}
                />
            </div>
            <div className="FormBox">
                <div className="FormLabel">
                    <label>
                        Proof of Authorized Capital
                        <span>*</span>
                    </label>
                </div>
                <UploadField
                    fieldName={'corporate.authorizedCapital.proofOfSourceOfFundsFileName'}
                    displayError={true}
                    multi={false}
                    jsonField={true}
                />
            </div>
            <h3>Turnover</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.previousYearTurnover'}
                    label={'Previous Year'}
                    required={true}
                    type="number"
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <FormTextField
                    field={'corporate.authorizedCapital.plannedTurnover'}
                    label={'Planned for Coming Year'}
                    required={true}
                    type="number"
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
            </div>
            <FormTextField
                field={'corporate.authorizedCapital.bankAccountSignatories'}
                label={'Bank Account Signatories (include full names)'}
                required={true}
                maxLength={500}
                asterisk={true}
            />
            <h3>Source Funds Information</h3>
            <SingleSelectCheckList
                fieldName="corporate.authorizedCapital.sourceFundsInformationTypesID"
                options={sourceFundTypes}
            />
            <h3>External Investment</h3>
            <FormTextField
                field={'corporate.authorizedCapital.externalInvestment.companyName'}
                label={'Investment Company Name'}
                required={false}
                maxLength={200}
                // asterisk={true}
            />
            <FormTextField
                field={'corporate.authorizedCapital.externalInvestment.companyAddress'}
                label={'Investment Company Address'}
                required={false}
                maxLength={500}
                // asterisk={true}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.externalInvestment.amount'}
                    label={'Amount Invested'}
                    required={false}
                    maxLength={200}
                    type="number"
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.externalInvestment.date'}
                        label={'Date of Investment'}
                        error={true}
                    />
                </div>
            </div>
            <h3>Internal Investment</h3>
            <FieldArray
                name="corporate.authorizedCapital.internalInvestments"
                render={(arrayHelpers) => (
                    <div className="FormBox">
                        {values.corporate.authorizedCapital.internalInvestments &&
                        values.corporate.authorizedCapital.internalInvestments.length > 0 ? (
                            values.corporate.authorizedCapital.internalInvestments.map(
                                (businesspartners: any, index: number) => (
                                    <>
                                        <InternalInvestor
                                            index={index}
                                            arrayHelpers={arrayHelpers}
                                            idRef={idRef}
                                            disable={disable}
                                            disableRemove={
                                                values.corporate.authorizedCapital
                                                    .internalInvestments.length < 2
                                            }
                                        />
                                    </>
                                )
                            )
                        ) : (
                            <div></div>
                        )}
                        <Button
                            type="button"
                            priority="secondary"
                            variety="full"
                            onClick={() => {
                                arrayHelpers.push(internalInvestor);
                                idRef.current++;
                            }}
                        >
                            Add Internal Investor
                        </Button>
                    </div>
                )}
            />

            <h3>Company Sale</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.companySale.companyName'}
                    label={'Name of Company'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SidebySide">
                    <WrappedFormSingleSelectField
                        fieldName={'corporate.authorizedCapital.companySale.country'}
                        label={'Country of Incorporation*'}
                        options={availableCountries}
                    />
                </div>
            </div>
            <FormTextField
                field={'corporate.authorizedCapital.companySale.companyAddress'}
                label={'Company Address'}
                required={false}
                maxLength={500}
                sideBySide={true}
                // asterisk={true}
            />
            <FormTextField
                field={'corporate.authorizedCapital.companySale.natureOfBusiness'}
                label={'Nature of Business'}
                required={false}
                maxLength={200}
                sideBySide={true}
                // asterisk={true}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.companySale.amount'}
                    label={'Amount of Sale'}
                    required={false}
                    type="number"
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.companySale.date'}
                        label={'Date of Sale'}
                        error={true}
                    />
                </div>
            </div>
            <h3>Inheritance</h3>
            <FormTextField
                field={'corporate.authorizedCapital.inheritance.nameRelationDonor'}
                label={'Name and Relationship to Donor'}
                required={false}
                maxLength={200}
                // asterisk={true}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.inheritance.amount'}
                    label={'Amount Received'}
                    required={false}
                    type="number"
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.inheritance.date'}
                        label={'Date Received'}
                        error={true}
                    />
                </div>
            </div>
            <h3>Property Sale</h3>
            <FormTextField
                field={'corporate.authorizedCapital.propertySale.address'}
                label={'Address of Property'}
                required={false}
                maxLength={500}
                // asterisk={true}
            />
            <div className="FormSideBySide">
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.propertySale.date'}
                        label={'Date of Sale'}
                        error={true}
                    />
                </div>
                <FormTextField
                    field={'corporate.authorizedCapital.propertySale.amount'}
                    label={'Amount of Sale'}
                    required={false}
                    maxLength={200}
                    type="number"
                    sideBySide={true}
                    // asterisk={true}
                />
            </div>
            <h3>Loan</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.loan.amount'}
                    label={'Amount of Loan'}
                    required={false}
                    maxLength={200}
                    type="number"
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.loan.date'}
                        label={'Date of Loan'}
                        error={true}
                    />
                </div>
            </div>
            <FormTextField
                field={'corporate.authorizedCapital.loan.lenderNameAddress'}
                label={'Name / Address of Lender'}
                required={false}
                maxLength={400}
                // asterisk={true}
            />
            <FormTextField
                field={'corporate.authorizedCapital.loan.purpose'}
                label={'Purpose of Loan'}
                required={false}
                maxLength={400}
                // asterisk={true}
            />
            <h3>Other</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.other.payee'}
                    label={'Payee'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.authorizedCapital.other.date'}
                        label={'Date Received'}
                        error={true}
                    />
                </div>
            </div>
            <FormTextField
                field={'corporate.authorizedCapital.other.natureOfIncome'}
                label={'Nature of Income'}
                required={false}
                maxLength={200}
                // asterisk={true}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.authorizedCapital.other.amount'}
                    label={'Amount Received'}
                    required={false}
                    maxLength={200}
                    type="number"
                    sideBySide={true}
                    // asterisk={true}
                />
                <div className="FormBox SideBySide"></div>
            </div>
            <h3>Supporting Documents</h3>
            <div className="FormBox">
                <div className="FormLabel">
                    <label>
                        Supporting Documents
                        {/* <span>*</span> */}
                    </label>
                </div>
                <UploadField
                    fieldName={'corporate.authorizedCapital.supportingDocuments'}
                    displayError={true}
                    multi={true}
                    jsonField={true}
                />
            </div>
        </div>
    );
};

export default Funds;
