import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AccountFeeInfo, FeeOption, PaymentStepProps } from './MakePayment';
import FormTextField from '../../../../components/form/FormTextField';
import { WrappedFormSingleSelectField } from '../../../../components/form/FormSingleSelectField';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import Button from '../../../../components/button/Button';
import { useGetFiatAccountDetails } from '../../../../helpers/useGetFiatAccountDetails';
import { ProductType } from '../../../../components/sideMenu/SideMenu';
import { RadioIdButtons } from '../../../../components/radiobuttons/RadioIdButtons';

export const getFeeLabel = (label: string, tempHoldFee?: string, accountName?: string) => {
    switch (label) {
        case 'Full Bank Fee':
            return {
                label: 'Us (OUR)',
                subLabel: `You pay all charges.${
                    tempHoldFee
                        ? ` A temporary hold of ${tempHoldFee} will be placed on your ${
                              accountName ? accountName : 'account'
                          } until the transfer is complete.`
                        : ''
                }`,
            };
        case null:
        case 'Half Bank Fee':
            return {
                label: 'Shared (SHA)',
                subLabel: "You pay Ibanera charges, payee pays their bank's charges",
            };
        case 'No Bank Fee':
            return { label: 'They (BEN)', subLabel: 'Payee pays all charges' };
        default:
            return { label: label, subLabel: '' };
    }
};

export const createFeeOptions = (
    feeOptions: Array<Partial<FeeOption> & { bankFeeSplitType: string }>,
    accountName?: string
) => {
    return (feeOptions ?? []).map((fee) => {
        const { label, subLabel } = getFeeLabel(fee.bankFeeSplitType, fee.tempHoldFee, accountName);
        return {
            label: label,
            value: fee.bankFeeSplitType,
            subLabel: subLabel,
        };
    });
};

type Props = PaymentStepProps & {
    bInternational: boolean;
    purposes: SelectOption[];
    accountFeeInfo: AccountFeeInfo;
    initialValues: Partial<AmountPurposeForm>;
    transferType: string;
    accountType?: string;
    loading: boolean;
};

export type AmountPurposeForm = {
    amount: number;
    purpose: string;
    feeId: string | null;
};

export const AmountPurpose: React.FC<Props> = ({
    nextStep,
    accountFeeInfo,
    transferType,
    bInternational,
    purposes,
    initialValues,
    accountType,
    loading,
}) => {
    const accountDetails = useGetFiatAccountDetails();

    const onSubmit = (values: AmountPurposeForm) => {
        nextStep(values);
    };

    const feeOptions =
        accountFeeInfo.transferTypes.find(
            (fee) => fee.transferType.toLowerCase() === transferType.toLowerCase()
        )?.feeOptions ?? [];

    const validationSchema = Yup.object({
        feeId: Yup.string().nullable(true),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .required('Please enter an amount')
            .positive('Please enter a number greater than 0')
            .test(
                'maxAmountCheck',
                'Max available funds reached',
                function (amount: number | undefined) {
                    const feeId = this.parent.feeId;
                    const fee = feeOptions.find((fee) => fee.bankFeeSplitType === feeId);

                    const minFee = fee?.minFee ?? 0;
                    const percentageFee = fee ? (amount ?? 0) * (fee.feePercent / 100) : 0;
                    const finalFee = percentageFee > minFee ? percentageFee : minFee;

                    if ((amount ?? 0) + finalFee > (accountFeeInfo?.balance ?? Number.MAX_VALUE))
                        return this.createError({
                            message: `Not enough funds ${finalFee > 0 ? `(fee: ${finalFee})` : ''}`,
                        });
                    else return true;
                }
            ),
        purpose: Yup.string()
            .nullable()
            .test('requiredIfInternational', 'Required', (val) => {
                if (
                    bInternational &&
                    accountDetails?.productDisplayName === ProductType.CRB &&
                    accountType !== 'Card'
                )
                    return !!val;
                else return true;
            }),
    });

    const radioOptions = createFeeOptions(
        feeOptions ?? [],
        (accountDetails?.accountName as any) ?? undefined
    );
    const previousSelectedFee = feeOptions.find(
        (fee) => fee.bankFeeSplitType === initialValues.feeId
    );

    return (
        <Formik
            initialValues={{
                amount: 0,
                purpose: '',
                feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                ...initialValues,
                // This is in case the transfer type is changed
                ...(!previousSelectedFee && {
                    feeId: radioOptions.length > 0 ? radioOptions[0].value : null,
                }),
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ setFieldValue, values }) => (
                <Form>
                    {radioOptions.length > 1 && (
                        <RadioIdButtons
                            className="FeeOptions"
                            options={radioOptions}
                            fieldname="feeId"
                            label="Fee"
                        />
                    )}
                    <FormTextField
                        field="amount"
                        label="Amount"
                        onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                            if (regex.test(value)) {
                                setFieldValue('amount', value, false);
                            } else {
                                setFieldValue('amount', values.amount, true);
                            }
                        }}
                    />

                    <p className="mb-2">
                        Please {bInternational ? 'select' : 'provide'} an option that best describes
                        the reason for your transfer
                    </p>
                    {bInternational &&
                    accountDetails?.productDisplayName === ProductType.CRB &&
                    accountType !== 'Card' ? (
                        <WrappedFormSingleSelectField
                            fieldName="purpose"
                            options={purposes ?? []}
                            label={`${transferType} Payment Purpose `}
                            dropdownProps={{
                                placeholder: 'Please select',
                            }}
                        />
                    ) : (
                        <FormTextField
                            field="purpose"
                            label={`${transferType} Payment Purpose `}
                            placeholder="E.g. Utility Bill"
                        />
                    )}
                    <Button disabled={loading} color="third">
                        Next
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
