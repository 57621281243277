import React from 'react';
import {
    BusinessStepsOrder,
    BusinessVerificationStage,
    stageTitles,
} from '../businessVerificationModels';

interface Props {
    currentStage: BusinessVerificationStage;
}
const stagesToShow = [
    BusinessVerificationStage.SignificantParties,
    BusinessVerificationStage.OperatingAddress,
    BusinessVerificationStage.CompanyRep,
    BusinessVerificationStage.MainBusinessPartners,
    BusinessVerificationStage.MainActivity,
    BusinessVerificationStage.Funding,
    BusinessVerificationStage.OtherBankAccounts,
    BusinessVerificationStage.AnticipatedActivity,
    BusinessVerificationStage.Documents,
    BusinessVerificationStage.Submit,
] as const;

const getStageNumber = (stage: BusinessVerificationStage) =>
    stagesToShow.findIndex((step) => step === stage);

const StageIndicator: React.FC<Props> = ({ currentStage }) => {
    const stageNumber = getStageNumber(currentStage);
    if (stageNumber === -1) {
        return null;
    }
    const isFilled = (number: number) => (stageNumber >= number ? 'Filled' : '');
    const hideAtDistanceFromCurrentStage = 2;
    const isFar = (number: number) =>
        Math.abs(stageNumber - number) <= hideAtDistanceFromCurrentStage ? '' : 'Far';
    // isFarConnector has to be done slightly differently because connectors are always left of circle
    const isFarConnector = (number: number) =>
        stageNumber - number > hideAtDistanceFromCurrentStage - 1 ||
        stageNumber - number < -hideAtDistanceFromCurrentStage
            ? 'Far'
            : '';
    return (
        <div className="StageIndicatorContainer">
            <div className="Progress">
                {stagesToShow.map((stage, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            <div
                                className={`Connector ${isFilled(index)} ${isFarConnector(index)}`}
                            ></div>
                        )}
                        <div className={`Circle ${isFilled(index)} ${isFar(index)}`}>
                            <p className={`label ${stage === currentStage ? 'CurrentStage' : ''}`}>
                                {stageTitles[stage]}
                            </p>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default StageIndicator;
