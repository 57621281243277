import { FormikErrors } from 'formik';
import { Collapse } from '../../../components/Collapse/Collapse';
import { BulkTransferItemHeader } from './BulkTransferItemHeader';
import { BulkTransferOptions, ParsedCsv } from './models';
import classNames from 'classnames';
import React from 'react';
import { csvCellConfig } from './helpers';

type Props = ParsedCsv & {
    accounts: BulkTransferOptions['accounts'];
    countries: BulkTransferOptions['countries'];
    purposeCodes: BulkTransferOptions['purposeCodes'];
    handleDeleteTransfer(): void;
    handleEditTransfer(): void;
    errors: FormikErrors<ParsedCsv> | string | undefined;
};

export const BulkTransferItem: React.FC<Props> = ({
    accounts,
    countries,
    purposeCodes,
    errors,
    ...transfer
}) => {
    const account = (accounts ?? []).find(
        (acc) => acc.id.toString() === transfer.sourceAccountId?.toString()
    );

    const accountCountry = !transfer.countryCode
        ? null
        : countries.find((country) => country.countryISO3 === transfer.countryCode)?.name ?? null;
    const addressCountry = !transfer.addressCountryCode
        ? null
        : countries.find((country) => country.countryISO3 === transfer.addressCountryCode)?.name ??
          null;

    const header = () => (
        <BulkTransferItemHeader
            currencyCode={account?.currencyCode}
            currencySymbol={account?.currencySymbol}
            sourceAccountName={account?.accountName}
            {...transfer}
        />
    );

    const memoNotesLengthExceeded =
        (transfer.memo ?? '').length + (transfer.notes ?? '').length > 800;

    const isUSA = !transfer.countryCode || transfer.countryCode === 'USA';
    return (
        <>
            <Collapse
                hideArrow
                showCollapseArrow
                header={header()}
                className={classNames({
                    HasError: errors || transfer.status === 'Error',
                    Success:
                        transfer.status === 'Success' ||
                        transfer.status === 'Success - Pending Approval',
                })}
            >
                <div className={`BulkTransferItemContent ${memoNotesLengthExceeded ? 'Wrap' : ''}`}>
                    <div>
                        <LabelValuePair label="Payee Name" value={transfer.name} />
                        <LabelValuePair label="Account Name" value={transfer.accountName} />
                        <LabelValuePair
                            classname="MarginBottom"
                            label="Account Country"
                            value={accountCountry}
                        />

                        {!transfer.isDbs && isUSA && (
                            <LabelValuePair label="Routing" value={transfer.routingNumber} />
                        )}
                        {(transfer.isDbs || (!transfer.isDbs && isUSA)) && (
                            <LabelValuePair label="Account" value={transfer.accountNumber} />
                        )}
                        {(transfer.isDbs || !isUSA) && (
                            <LabelValuePair label="SWIFT" value={transfer.swiftNumber} />
                        )}
                        {transfer.isDbs && transfer.intermediaryBic && (
                            <LabelValuePair
                                label="Intermediary Swift"
                                value={transfer.intermediaryBic}
                            />
                        )}
                        {!transfer.isDbs && !isUSA && (
                            <LabelValuePair label="IBAN" value={transfer.iban} />
                        )}
                    </div>

                    <div>
                        <LabelValuePair label="Address Line 1" value={transfer.addressLine1} />
                        {transfer.addressLine2 && (
                            <LabelValuePair label="Address Line 2" value={transfer.addressLine2} />
                        )}
                        <LabelValuePair label="Town / City" value={transfer.townCity} />
                        <LabelValuePair label="State" value={transfer.state} />
                        <LabelValuePair label="ZIP code" value={transfer.postCode} />
                        <LabelValuePair label="Country" value={addressCountry} />
                    </div>

                    <div className="MemoNotes">
                        <LabelValuePair classname="Memo" label="Memo" value={transfer.memo} />
                        <LabelValuePair label="Notes" value={transfer.notes} />
                    </div>
                </div>
            </Collapse>
            {parseError(errors)}
        </>
    );
};

const parseError = (error: FormikErrors<ParsedCsv> | string | undefined): React.ReactNode => {
    if (!error) return null;
    if (typeof error == 'string') return <div className="ErrorLabel">{error}</div>;

    return (
        <div className="ErrorLabel">
            {Object.entries(error).map(([key, value], i) => {
                const fieldLabel = csvCellConfig.find((header) => header.fieldValue === key)?.label;
                return (
                    <React.Fragment key={i}>
                        <div>
                            {fieldLabel ? `${fieldLabel} - ` : ''}
                            {value}
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

const LabelValuePair = ({
    label,
    value,
    classname = '',
}: {
    label: string;
    value?: string | null;
    classname?: string;
}) => (
    <div className={`LabelValuePair ${classname}`}>
        <div className="Label">{label}:</div>
        <div className="Value">{value ?? '-'}</div>
    </div>
);
