import React from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';

export const useIsSubledgerAccount = (accountNumber: number) => {
    const { customerMenuLinks } = useSelector(selectComponentResources);
    return !!customerMenuLinks
        ?.find((link) => link.resourceKey === 'key_access_elements_FundingAccounts')
        ?.accounts?.some((account) => account.id === accountNumber);
};
