import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';
import Select, { ValueType, components } from 'react-select';
import { TextCurrencyIcon } from '../../currencyIcon/CurrencyIcon';
import { formatCurrency } from '../../../helpers/fiatFormatter';
import { ExchangeAccountDetails } from 'pages/currencyExchange/models';

type Props = {
    fieldname: string;
    accounts: ExchangeAccountDetails[];
    currency: string;
    direction: 'From' | 'To';
};

export const AccountDropdown: React.FC<Props> = ({ fieldname, accounts, currency, direction }) => {
    const [{ value }, , { setValue }] = useField(fieldname);

    const selectedAccount = accounts.find((acc) => acc.accountID === value);

    const accountOptions = accounts.map((acc) => ({
        label: `${acc.displayName}${
            acc.balance !== null
                ? ` (${formatCurrency(acc.balance ?? 0, currency, undefined, {
                      currencyDisplay: 'code',
                      maximumFractionDigits: 13,
                  })})`
                : ''
        }`,
        value: acc.accountID,
    }));

    const onChange = (
        opt: ValueType<
            {
                label: string;
                value: string | number | null;
            },
            false
        >
    ) => {
        setValue(opt?.value);
    };

    const SingleValue = ({ children, ...props }: any) => (
        <components.SingleValue {...props} className="AccountDropdown--value">
            <div className="From">
                <TextCurrencyIcon currencySymbol={selectedAccount?.currencySymbol ?? ''} />
                <div className="AccountInfo">
                    <p>{direction}:</p>
                    <div className="AccountDropdownRow">
                        <h4>{selectedAccount?.displayName}</h4>
                        {selectedAccount?.balance !== null && (
                            <div className="Balance">
                                {formatCurrency(
                                    selectedAccount?.balance ?? 0,
                                    currency,
                                    undefined,
                                    {
                                        currencyDisplay: 'code',
                                        maximumFractionDigits: 13,
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </components.SingleValue>
    );

    return (
        <Select
            className="AccountDropdown"
            classNamePrefix="AccountDropdown"
            value={accountOptions.find((opt) => opt.value === value)}
            onChange={onChange}
            options={accountOptions}
            isSearchable={false}
            components={{
                SingleValue,
                IndicatorSeparator: null,
            }}
        />
    );
};
