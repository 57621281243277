import instance, { isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { useDispatch } from 'react-redux';
import FormTextField from 'components/form/FormTextField';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { Spinner } from 'components/spinner/Spinner';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { getErrorMessage } from 'errors';
import { Toast } from 'helpers/toast';

const initialValues = {
    customerAssetAccountsId: 0,
    amount: undefined,
};

const validationSchema = Yup.object({
    customerAssetAccountsId: Yup.number()
        .required('Please choose an account')
        .moreThan(0, 'Please choose an account'),
    amount: Yup.number().required('Please choose an amount').moreThan(0, 'Please choose an amount'),
});

export const TopUpCard = ({
    cardId,
    onCardToppedUp,
}: {
    cardId: number;
    onCardToppedUp: () => void;
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [accountOptions, setAccountOptions] = useState<SelectOption[]>([]);
    const dispatch = useDispatch();
    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post(endpoints.cards.topUpCard, { ...values, topUpCardsId: cardId })
            .then((res) => {
                Toast.openSuccessToast('Top up successfull');
                onCardToppedUp();
            })
            .catch((err) => {
                if (isAxiosErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (errors.some((error) => toCamelCase(error.fieldName) in initialValues)) {
                        errors.forEach((error) =>
                            formikHelpers.setFieldError(
                                toCamelCase(error.fieldName) as string,
                                getErrorMessage(error.messageCode)
                            )
                        );
                    } else setErrorMessage(getErrorMessage(errors[0].messageCode));
                } else Toast.openGenericErrorToast();
            })
            .finally(() => formikHelpers.setSubmitting(false));
    };
    useEffect(() => {
        instance
            .get<ApiResponse<SelectOption[]>>(endpoints.cards.getTopUpAccounts, {
                params: { topUpCardsId: cardId },
            })
            .then((res) => {
                setAccountOptions(res.data.details ?? []);
            })
            .catch((err) => {
                Toast.openGenericErrorToast();
            });
    }, [cardId, dispatch]);
    return (
        <div className="TopUpCardPage FormPage">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <WrappedFormSingleSelectField
                            fieldName="customerAssetAccountsId"
                            options={accountOptions}
                            label="Select Account"
                        />
                        <FormTextField
                            field="amount"
                            required={true}
                            label="Top Up Amount"
                            type="number"
                        />
                        <Button variety="full" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <Spinner color="#fff" /> : 'Top Up'}
                        </Button>
                        {errorMessage && <div className="ErrorLabel">{errorMessage}</div>}
                    </Form>
                )}
            </Formik>
        </div>
    );
};
