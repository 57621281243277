import { CurrencyExchangeTile } from 'components/currencyExchange/CurrencyExchangeTile';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyExchangeItem } from './models';
import instance from 'api';
import { endpoints } from 'endpoints.config';
import { useDispatch } from 'react-redux';
import { AssetPairPrice, updatePrices } from 'reducers/cryptoPrices';
import Button from 'components/button/Button';

import FavouriteIcon from 'assets/icon-favorite-01.svg';
import FavouriteIconSelected from 'assets/icon-favorite-02.svg';
import { useTheme } from '@emotion/react';
import classNames from 'classnames';
import { SearchBox } from 'helpers/searchBox/SearchBox';

type Props = {};

export const CurrencyExchangePage = (props: Props) => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [exchangeItems, setExchangeItems] = useState<null | CurrencyExchangeItem[]>(null);
    const [searchString, setSearchString] = useState('');
    const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    const filteredItems = useMemo(
        () =>
            exchangeItems?.filter(
                (item) =>
                    item.buyPrice &&
                    item.sellPrice &&
                    item.exchangeAsset.toLowerCase().includes(searchString.toLowerCase()) &&
                    (!showOnlyFavourites || item.bFavourited)
            ),
        [searchString, showOnlyFavourites, exchangeItems]
    );

    useEffect(() => {
        instance
            .get(endpoints.fxmodule.listExchanges, { params: { currency: 'USD' } })
            .then((res) => {
                setExchangeItems(res.data.details?.exchanges);
                res.data.details?.exchanges &&
                    dispatch(updatePrices(mapExchangesToPrices(res.data.details.exchanges)));
                setIsPageLoading(false);
            });
    }, []);

    const setFavourite = useCallback(
        (baseAssetId: number, exchangeAssetId: number, bFavourited: boolean) => {
            const toggleItem = () =>
                setExchangeItems(
                    (items) =>
                        items?.map((item) =>
                            item.baseAssetId === baseAssetId &&
                            item.exchangeAssetId === exchangeAssetId
                                ? { ...item, bFavourited: !item.bFavourited }
                                : item
                        ) ?? null
                );
            toggleItem(); // optimistically set bFavourite
            instance
                .post(endpoints.fxmodule.updateFavourites, {
                    baseAssetId,
                    exchangeAssetId,
                    bFavourited,
                })
                .catch(() => {
                    toggleItem(); //revert the optimistic toggle
                });
        },
        []
    );

    if (isPageLoading) return <SubPageLoader message={`Loading Currency Exchange`} />;
    return (
        <div className="CurrencyExchangePage">
            <div className="CurrencyExchangePageHeader TableHeader">
                <div className="left"></div>
                <div className="right">
                    <Button
                        onClick={() => setShowOnlyFavourites((curr) => !curr)}
                        className={classNames('SetFavouriteButton', {
                            Selected: showOnlyFavourites,
                        })}
                        priority="secondary"
                    >
                        <img
                            src={showOnlyFavourites ? FavouriteIconSelected : FavouriteIcon}
                            alt=""
                            style={{ height: '60%', marginLeft: -10 }}
                        />{' '}
                        Favorites
                    </Button>
                    <SearchBox
                        initialSearchString=""
                        changeSearch={setSearchString}
                        placeholder="Search all currencies"
                    />
                </div>
            </div>
            <div className="CurrencyExchangeList">
                {filteredItems?.map((item, i) => (
                    <CurrencyExchangeTile key={i} item={item} setFavourite={setFavourite} />
                ))}
            </div>
        </div>
    );
};

const mapExchangesToPrices = (exchanges: CurrencyExchangeItem[]): AssetPairPrice[] => {
    return exchanges.map((exchange) => ({
        assetCode: exchange.baseAsset,
        pairedAssetCode: exchange.exchangeAsset,
        buyPrice: exchange.buyPrice,
        sellPrice: exchange.sellPrice,
        priceVariability: 0,
        marketHighLow: `${exchange.buyPrice}-${exchange.buyPrice}`,
        name: `${exchange.baseAsset}/${exchange.exchangeAsset}`,
        changeDirection: null,
    }));
};
