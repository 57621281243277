import { NavigateFn, useNavigate } from '@reach/router';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Button from 'components/button/Button';
import {
    VariabilityCell,
    EmptyCell,
    HighLowCell,
    NameCell,
    PrecisionNumberCell,
    ValueCell,
} from 'components/flexTable/CustomCells';
import { FlexTable, TableProps } from 'components/flexTable/FlexTable';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { endpoints } from 'endpoints.config';
import { calculatePercentageChange } from 'helpers/calculatePercentage';
import { replaceFinalPathComponent } from 'helpers/replaceFinalPathComponent';
import { CryptoContext, FiatCurrency } from 'pages/crypto/CryptoPage';
import { generateCryptoPairString } from 'pages/crypto/useCryptoContextEffects';
import { plaintext } from 'plaintext.config';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, ModalTypes } from 'reducers/modal';

export type PortfolioTableRow = {
    assets__Id: number;
    assets__Code: string;
    assets__Name: string;
    balances__Balance: number;
    balances__Value: number;
    market__SellPrice: number;
    market__PercentChange: number;
    market__MarketHighLow: string;
    pairedAssets__Code: string;
};

export type PortfolioTableLiveDataPoint = {
    price: number | null;
    open: number | null;
    high: number | null;
    low: number | null;
};

export type PortfolioTableLiveData = {
    [key: string]: PortfolioTableLiveDataPoint;
};

const rowFormatter =
    (data: PortfolioTableLiveData, selectedCurrency: FiatCurrency, isLoading: boolean) =>
    (row: PortfolioTableRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: any = { ...row };

        const currentRowData = data[row.assets__Code] ? data[row.assets__Code] : null;

        if (row.assets__Name && row.assets__Code) {
            formattedRow = {
                ...formattedRow,
                assets__Name: !isLoading ? (
                    <NameCell name={row.assets__Name} ticker={row.assets__Code} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.balances__Value) {
            formattedRow = {
                ...formattedRow,
                balances__Value:
                    currentRowData?.price && !isLoading ? (
                        <ValueCell
                            balance={row.balances__Balance}
                            currencySymbol={selectedCurrency.symbol}
                            currencyTicker={selectedCurrency.code}
                            price={currentRowData.price}
                        />
                    ) : (
                        <EmptyCell />
                    ),
            };
        }

        if (row.market__PercentChange || row.market__PercentChange === 0) {
            formattedRow = {
                ...formattedRow,
                market__PercentChange: isLoading ? (
                    <EmptyCell />
                ) : currentRowData?.open && currentRowData?.price ? (
                    <VariabilityCell
                        amount={calculatePercentageChange(
                            currentRowData.open,
                            currentRowData.price
                        )}
                    />
                ) : (
                    <VariabilityCell amount={row.market__PercentChange} />
                ),
            };
        }

        if (row.market__MarketHighLow) {
            formattedRow = {
                ...formattedRow,
                market__MarketHighLow:
                    currentRowData?.price &&
                    currentRowData.high &&
                    currentRowData.low &&
                    !isLoading ? (
                        <HighLowCell
                            high={currentRowData.high}
                            low={currentRowData.low}
                            price={currentRowData.price}
                            currencySymbol={selectedCurrency.symbol}
                        />
                    ) : (
                        <EmptyCell />
                    ),
            };
        }

        if (row.balances__Balance) {
            formattedRow = {
                ...formattedRow,
                balances__Balance: !isLoading ? (
                    <PrecisionNumberCell number={row.balances__Balance} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        return formattedRow;
    };

export const PortfolioTable: React.FC<
    TableProps & {
        liveData: PortfolioTableLiveData;
        selectedCurrency: FiatCurrency;
        isLoading: boolean;
        excludeBuySellButton?: boolean;
    }
> = (props) => {
    const formatter = rowFormatter(props.liveData, props.selectedCurrency, props.isLoading);
    const { setSelectedCryptoPair, endpoints } = useContext(CryptoContext);
    const navigate = useNavigate();
    const update = useSelector(selectUIUpdate);
    const dispatch = useDispatch();

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.RELOAD_PORTFOLIO_TABLE) {
            props.table?.reload();
            dispatch(completeUIUpdate());
        }
    }, [update, props.table, dispatch]);

    return (
        <div className="TradeTableContainer">
            <FlexTable
                {...props}
                rowFormatter={formatter}
                rowButton={createRowButtons(
                    setSelectedCryptoPair,
                    navigate,
                    props.excludeBuySellButton,
                    props.isLoading,
                    endpoints.withdraw
                )}
            />
            {props.table?.data?.details.listData.length === 0 && !props.table?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">{plaintext.tables.emptyPortFolioTable}</h3>
                </div>
            )}
        </div>
    );
};

const createRowButtons =
    (
        setPair: React.Dispatch<React.SetStateAction<string | null>>,
        navigate: NavigateFn,
        excludeBuySellButton: boolean = false,
        isLoading: boolean,
        withdrawUrl: string
    ) =>
    (rowData: PortfolioTableRow) => {
        const handleBuySell = (crypto: string, fiat: string) => {
            setPair(generateCryptoPairString(crypto, fiat));
            navigate(replaceFinalPathComponent(window.location.pathname, '/trade'));
        };
        const dispatch = useDispatch();
        const handleWithdraw = (crypto: string) => {
            dispatch(
                openModal({ modalType: ModalTypes.WITHDRAW_CRYPTO, data: { crypto, withdrawUrl } })
            );
        };

        return !isLoading ? (
            <div className={`MarketRowButtons ${excludeBuySellButton ? 'OneButton' : ''}`}>
                {!excludeBuySellButton && (
                    <Button
                        className="Buy"
                        onClick={() =>
                            handleBuySell(rowData.assets__Code, rowData.pairedAssets__Code)
                        }
                    >
                        Buy / Sell
                    </Button>
                )}
                <Button
                    className="WithdrawBtn"
                    priority="secondary"
                    onClick={() => handleWithdraw(rowData.assets__Code)}
                >
                    Withdraw
                </Button>
            </div>
        ) : (
            <EmptyCell />
        );
    };
