import { AppPath, DashboardPath } from 'appConstants';
import { FooterLinks } from 'components/footer/types';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons, clearIcon, selectAccountTypePathItem } from 'reducers/componentResources';
import chevron from '../../assets/ibanera/Icon_Dropdown_01.png';
import { MobileNavigationButton, StaticIcon } from './MobileNavigationButton';

//icons
import VisaIcon from 'assets/ibanera/Icon_Card_01.png';
import MCIcon from 'assets/ibanera/Icon_Card_02.png';
import { InfoRequestNavLink } from '../../pages/InfoRequest/InfoRequestNavLink';
import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { BulkTransferSideNavLink } from '../../pages/account/BulkTransfer/BulkTransferSideNavLink';

type SignedInMoreMenuProps = {
    linksFromFooter: FooterLinks | null;
    pageLinks: SideMenuLink[] | null;
    pageLinkIcons: Icons | null;
    navigateWithCultureCode: (path: string) => void;
    closeMenu: () => void;
};

export const SignedInMoreMenu: React.FC<SignedInMoreMenuProps> = ({
    linksFromFooter,
    pageLinks,
    pageLinkIcons,
    navigateWithCultureCode,
    closeMenu,
}) => {
    const accountTypePathItem = useSelector(selectAccountTypePathItem);
    const dispatch = useDispatch();

    return (
        <div className="MoreMenu">
            <div className="PageLinks">
                <InfoRequestNavLink mobile onLinkPressed={closeMenu} />
                <BulkTransferSideNavLink mobileMenu />

                {pageLinks &&
                    pageLinkIcons &&
                    pageLinks.map((link, index) => {
                        const linkHasChildren = !!link.childElements;
                        // See App.tsx Dashboard routing for explanation on the need for
                        // this placeholder.
                        // const finalUrlSegment = `/${
                        //     linkHasChildren
                        //         ? `${link.childElements[0].path}`
                        //         : DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER
                        // }`;
                        return (
                            <div
                                onClick={() =>
                                    navigateWithCultureCode(`${accountTypePathItem}/${link.path}`)
                                }
                                key={link.id}
                                className="MoreMenuPageLink"
                            >
                                {pageLinkIcons[link.iconName].mobileInactive ? (
                                    <img
                                        src={
                                            link.iconName
                                                ? pageLinkIcons[link.iconName].mobileInactive ?? ''
                                                : ''
                                        }
                                        alt="Icon"
                                        className="Icon"
                                        onError={() => {
                                            dispatch(clearIcon(link.iconName));
                                        }}
                                    />
                                ) : (
                                    <TextCurrencyIcon currencySymbol={link.displayName[0]} />
                                )}
                                <span className="LinkLabel">
                                    {link.displayName}
                                    {link.elementType === 'QuickCoin' && (
                                        <div className="CardIcons">
                                            {[VisaIcon, MCIcon].map((icon) => (
                                                <img src={icon} alt="" />
                                            ))}
                                        </div>
                                    )}
                                </span>
                                {<img src={chevron} alt="chevron" className="Chevron" />}
                            </div>
                        );
                    })}
            </div>
            <div className="FooterLinks">
                {linksFromFooter &&
                    linksFromFooter.map((link) => (
                        <div
                            onClick={() =>
                                navigateWithCultureCode(
                                    `${accountTypePathItem}${DashboardPath.INFO}${link.url}`
                                )
                            }
                            className="FooterLink"
                        >
                            {link.title}
                        </div>
                    ))}
            </div>
        </div>
    );
};

type PublicMoreMenuProps = {
    linksFromFooter: FooterLinks | null;
    navigateWithCultureCode: (path: string) => void;
};

export const PublicMoreMenu: React.FC<PublicMoreMenuProps> = ({
    linksFromFooter,
    navigateWithCultureCode,
}) => {
    return (
        <div className="MoreMenu">
            <div className="PageLinks">
                <MobileNavigationButton
                    onClick={() => navigateWithCultureCode(`${AppPath.SIGN_IN}`)}
                    label="Sign in"
                    icon={StaticIcon.SIGN_IN}
                    className="MoreMenuPageLink"
                    isActive={false}
                />
                <MobileNavigationButton
                    onClick={() => navigateWithCultureCode(`${AppPath.REGISTER}`)}
                    label="Register"
                    icon={StaticIcon.REGISTER}
                    className="MoreMenuPageLink"
                    isActive={false}
                />
            </div>
            <div className="FooterLinks">
                {linksFromFooter &&
                    linksFromFooter.map((link) => {
                        return (
                            <div
                                onClick={() =>
                                    navigateWithCultureCode(`${AppPath.INFO}${link.url}`)
                                }
                                className="FooterLink"
                            >
                                {link.title}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
