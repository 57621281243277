import { Link, navigate } from '@reach/router';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';
import { useBasePath } from '../../helpers/useBasePath';
import { DashboardPath } from '../../appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalTypes, selectModalState } from '../../reducers/modal';

export const InformationRequestModal = () => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    const basePath = useBasePath();
    const infoRequestLink = `${basePath}${DashboardPath.INFORMATION_REQUEST}`;

    const handleClick = () => {
        navigate(infoRequestLink);
        dispatch(closeModal());
    };

    if (modalState.modalType !== ModalTypes.REQUEST_INFO) return null;

    return (
        <Modal
            className="InformationRequestModal"
            title={`Information request${modalState.data?.bIsReminder ? ' reminder' : ''}`}
        >
            <p>We require some more information on one or more of your transactions</p>
            <Button onClick={handleClick} color="third">
                Submit information
            </Button>
        </Modal>
    );
};
