import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Button from 'components/button/Button';
import { FlexTable } from 'components/flexTable';
import {
    VariabilityCell,
    EmptyCell,
    HighLowCell,
    NameCell,
    PriceCell,
} from 'components/flexTable/CustomCells';
import { TableProps } from 'components/flexTable/FlexTable';
import { calculatePercentageChange } from 'helpers/calculatePercentage';
import { CryptoContext, FiatCurrency, TradeEndpoints } from 'pages/crypto/CryptoPage';
import { generateCryptoPairString } from 'pages/crypto/useCryptoContextEffects';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { openModal, ModalTypes } from 'reducers/modal';

export type TradeTableRow = {
    assets__Id: number;
    assets__Code: string;
    assets__Name: string;
    market__BuyPrice: number;
    market__MarketHighLow: string | null;
    market__PercentChange: number;
    pairedAssets__Code: string;
    assets__bBuySellEnabled: boolean;
};

export type TradeTableLiveDataPoint = {
    price: number | null;
    open: number | null;
    high: number | null;
    low: number | null;
};

export type TradeTableLiveData = {
    [key: string]: TradeTableLiveDataPoint;
};

const rowFormatter =
    (data: TradeTableLiveData, selectedCurrency: FiatCurrency, isLoading: boolean) =>
    (row: TradeTableRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: any = { ...row };

        const currentRowData = data[row.assets__Code] ? data[row.assets__Code] : null;

        if (row.assets__Name && row.assets__Code) {
            formattedRow = {
                ...formattedRow,
                assets__Name: !isLoading ? (
                    <NameCell name={row.assets__Name} ticker={row.assets__Code} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.market__BuyPrice) {
            formattedRow = {
                ...formattedRow,
                market__BuyPrice:
                    currentRowData?.price && !isLoading ? (
                        <PriceCell
                            currencySymbol={selectedCurrency.symbol}
                            currencyTicker={selectedCurrency.code}
                            price={currentRowData.price}
                        />
                    ) : (
                        <EmptyCell />
                    ),
            };
        }

        if (row.market__PercentChange || row.market__PercentChange === 0) {
            formattedRow = {
                ...formattedRow,
                market__PercentChange: isLoading ? (
                    <EmptyCell />
                ) : currentRowData?.open && currentRowData?.price ? (
                    <VariabilityCell
                        amount={calculatePercentageChange(
                            currentRowData.open,
                            currentRowData.price
                        )}
                    />
                ) : (
                    <VariabilityCell amount={row.market__PercentChange} />
                ),
            };
        }

        if (row.market__MarketHighLow) {
            const listHigh = +row.market__MarketHighLow.split('-')[1];
            const listLow = +row.market__MarketHighLow.split('-')[0];
            formattedRow = {
                ...formattedRow,
                market__MarketHighLow:
                    currentRowData?.price &&
                    currentRowData.high &&
                    currentRowData.low &&
                    !isLoading ? (
                        <HighLowCell
                            high={
                                listHigh > currentRowData?.price ? listHigh : currentRowData?.price
                            }
                            low={listLow < currentRowData?.price ? listLow : currentRowData?.price}
                            price={currentRowData.price}
                            currencySymbol={selectedCurrency.symbol}
                        />
                    ) : (
                        <EmptyCell />
                    ),
            };
        }

        return formattedRow;
    };

export const TradeTable: React.FC<
    TableProps & {
        liveData: TradeTableLiveData;
        selectedCurrency: FiatCurrency;
        isLoading: boolean;
    }
> = (props) => {
    const formatter = rowFormatter(props.liveData, props.selectedCurrency, props.isLoading);
    const { setSelectedCryptoPair, endpoints } = useContext(CryptoContext);
    return (
        <div className="TradeTableContainer">
            <FlexTable
                {...props}
                rowFormatter={formatter}
                rowButton={createRowButtons(setSelectedCryptoPair, props.isLoading, endpoints)}
            />
            {props.table?.data?.details.listData.length === 0 && !props.table?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">Empty list</h3>
                </div>
            )}
        </div>
    );
};

const createRowButtons =
    (
        setCryptoPair: React.Dispatch<React.SetStateAction<string | null>>,
        isLoading: boolean,
        endpoints: TradeEndpoints
    ) =>
    (rowData: TradeTableRow) => {
        const dispatch = useDispatch();
        const handleBuySell = (crypto: string, fiat: string) => {
            setCryptoPair(generateCryptoPairString(crypto, fiat));
        };
        const handleDeposit = (crypto: string) => {
            dispatch(
                openModal({
                    modalType: ModalTypes.DEPOSIT_CRYPTO,
                    data: { crypto, depositEndpoint: endpoints.getDepositAddress },
                })
            );
        };

        return !isLoading ? (
            <div className="MarketRowButtons">
                <Button
                    className="Buy"
                    color="second"
                    onClick={() => handleBuySell(rowData.assets__Code, rowData.pairedAssets__Code)}
                    disabled={!rowData.assets__bBuySellEnabled}
                >
                    Buy / Sell
                </Button>
                <Button
                    className="DepositBtn"
                    priority="secondary"
                    onClick={() => handleDeposit(rowData.assets__Code)}
                >
                    Deposit
                </Button>
            </div>
        ) : (
            <EmptyCell />
        );
    };
