import { Spinner } from 'components/spinner/Spinner';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationChannel, NotificationStream, NotificationsUserPreferences } from './models';
import { NotificationsApi } from './NotificationsApi';
import { notification } from 'antd';

export const NotificationsConfig: React.FC = () => {
    const dispatch = useDispatch();
    const [notificationPreferences, setNotificationPreferences] =
        useState<NotificationsUserPreferences | null>(null);

    useEffect(() => {
        (async () => {
            const response = await NotificationsApi.getNotificationsConfig();
            if (NotificationsApi.isSuccessData(response)) {
                setNotificationPreferences(filterNotificationPreferences(response.data));
            } else {
                Toast.openToastMessage(
                    'There was an error getting your notification settings',
                    ToastMessageReason.ERROR
                );
            }
        })();
        //eslint-disable-next-line
    }, []);

    const findPreference = useCallback(
        (channelId: NotificationChannel['id'], streamId: NotificationStream['id']) => {
            if (notificationPreferences) {
                return notificationPreferences.userPreferences.find(
                    (preference) =>
                        preference.channelsId === channelId && preference.streamsId === streamId
                );
            }
        },
        [notificationPreferences]
    );

    const findIsChecked = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        const preference = findPreference(channelId, streamId);
        return !!preference?.bEnabled;
    };

    const handleChange = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        const preference = findPreference(channelId, streamId);
        if (preference) {
            const indexToUpdate = notificationPreferences!.userPreferences.findIndex(
                (preference) =>
                    preference.channelsId === channelId && preference.streamsId === streamId
            );
            if (indexToUpdate !== -1) {
                const newValue = !preference.bEnabled;
                NotificationsApi.updateSingleConfig({
                    channelsId: channelId,
                    streamsId: streamId,
                    bEnabled: newValue,
                });
                const newPreferences = [...notificationPreferences!.userPreferences];
                newPreferences[indexToUpdate] = { ...preference, bEnabled: !preference.bEnabled };
                setNotificationPreferences((prev) => ({
                    ...prev!,
                    userPreferences: newPreferences,
                }));
            }
        }
    };

    // This is BitLine specific implementation. Can be modified / removed on other
    // projects.
    // Each stream is required to be active in at least one channel. Therefore
    // we need to grey out certain checkboxes in the UI if they can't be disabled.
    const checkCheckboxCanBeChanged = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        if (notificationPreferences) {
            const preference = findPreference(channelId, streamId);
            const row = notificationPreferences.userPreferences.filter(
                (preference) => preference.streamsId === streamId
            );
            const numCheckedInRow = row.filter((preference) => preference.bEnabled).length;
            const isPreferenceOnlyCheckedInRow = preference?.bEnabled && numCheckedInRow === 1;
            const stream = notificationPreferences.streams.find((stream) => stream.id === streamId);
            const isStreamRequired = stream!.bRequired;
            return !(isStreamRequired && isPreferenceOnlyCheckedInRow);
        }
        return true;
    };

    const generateId = (
        channelId: NotificationChannel['id'],
        streamId: NotificationStream['id']
    ) => {
        return `s${streamId}-c${channelId}`;
    };

    return (
        <div className="NotificationsConfig">
            {notificationPreferences ? (
                <>
                    <table className="PreferencesTable Desktop">
                        <tr className="HeadRow">
                            <th></th>
                            {notificationPreferences.channels.map((channel) => (
                                <th>{channel.displayName}</th>
                            ))}
                        </tr>
                        {notificationPreferences.streams.map((stream) => (
                            <>
                                <tr>
                                    <td>{stream.description}</td>
                                    {notificationPreferences.channels.map((channel) => {
                                        const preference =
                                            notificationPreferences.userPreferences.find(
                                                (preference) =>
                                                    preference.channelsId === channel.id &&
                                                    preference.streamsId === stream.id
                                            );
                                        if (!preference) return <td></td>;
                                        const canBeChanged = checkCheckboxCanBeChanged(
                                            channel.id,
                                            stream.id
                                        );
                                        return (
                                            <td>
                                                <div className="NotificationConfigCheckbox">
                                                    <input
                                                        checked={findIsChecked(
                                                            channel.id,
                                                            stream.id
                                                        )}
                                                        type="checkbox"
                                                        className="CheckboxInput"
                                                        onChange={() =>
                                                            handleChange(channel.id, stream.id)
                                                        }
                                                        name={generateId(channel.id, stream.id)}
                                                    />
                                                    <label
                                                        // TODO: shouldn't be using onClick for label. Preferable
                                                        // to be using onChange on HTML input element.
                                                        onClick={() => {
                                                            if (canBeChanged) {
                                                                handleChange(channel.id, stream.id);
                                                            }
                                                        }}
                                                        htmlFor={generateId(channel.id, stream.id)}
                                                        className={`Checkmark ${
                                                            canBeChanged ? '' : 'Disabled'
                                                        }`}
                                                    ></label>
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            </>
                        ))}
                    </table>
                    <div className="PreferencesTable Mobile">
                        {notificationPreferences.streams.map((stream) => (
                            <div className="Row">
                                <p>{stream.description}</p>
                                <div className="Checkboxes">
                                    {notificationPreferences.channels.map((channel) => {
                                        const preference =
                                            notificationPreferences.userPreferences.find(
                                                (preference) =>
                                                    preference.channelsId === channel.id &&
                                                    preference.streamsId === stream.id
                                            );
                                        const canBeChanged = checkCheckboxCanBeChanged(
                                            channel.id,
                                            stream.id
                                        );
                                        return preference ? (
                                            <label
                                                className={`CheckboxContainer ${
                                                    findIsChecked(channel.id, stream.id)
                                                        ? 'Checked'
                                                        : ''
                                                } ${canBeChanged ? '' : 'Disabled'}
                                                `}
                                            >
                                                <div className="NotificationConfigCheckbox">
                                                    <input
                                                        checked={findIsChecked(
                                                            channel.id,
                                                            stream.id
                                                        )}
                                                        type="checkbox"
                                                        className="CheckboxInput"
                                                        onChange={() =>
                                                            handleChange(channel.id, stream.id)
                                                        }
                                                        name={generateId(channel.id, stream.id)}
                                                    />
                                                    <label
                                                        // TODO: shouldn't be using onClick for label. Preferable
                                                        // to be using onChange on HTML input element.
                                                        onClick={() => {
                                                            if (canBeChanged) {
                                                                handleChange(channel.id, stream.id);
                                                            }
                                                        }}
                                                        htmlFor={generateId(channel.id, stream.id)}
                                                        className={`Checkmark ${
                                                            canBeChanged ? '' : 'Disabled'
                                                        }`}
                                                    ></label>
                                                </div>
                                                <p className="Label">{channel.displayName}</p>
                                            </label>
                                        ) : null;
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </div>
    );
};

const filterNotificationPreferences = (
    prefs: NotificationsUserPreferences
): NotificationsUserPreferences => {
    const monthlyStatementStreamId = prefs.streams.find(
        (stream) => stream.code === 'MONTHLY_STATEMENT'
    )?.id;
    const emailChannelId = prefs.channels.find((channel) => channel.code === 'EMAIL')?.id;

    if (!monthlyStatementStreamId || !emailChannelId) return prefs;

    return {
        ...prefs,
        userPreferences: prefs.userPreferences.filter(
            (pref) =>
                pref.streamsId !== monthlyStatementStreamId || pref.channelsId === emailChannelId
        ),
    };
};
