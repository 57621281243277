import instance from 'api';
import { endpoints } from 'endpoints.config';
import { useFormikContext } from 'formik';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useDispatch } from 'react-redux';
import { signOut } from 'reducers/auth';
import { FormValues } from '../schema';
import { formatValues } from './helpers';
import Button from 'components/button/Button';

type Props = {};

export const ExitContinueButtons: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const { isSubmitting, isValidating, values } = useFormikContext<FormValues>();
    let disabled = isSubmitting || isValidating;
    const saveExit = async () => {
        try {
            const response = await instance.post(
                endpoints.businessVerificationV2Module.saveFormData,
                {
                    ...formatValues(values),
                    businessVerificationStep: values.businessVerificationStep,
                }
            );
            if (response.data.status === '1') {
                dispatch(signOut());
            }
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };

    return (
        <div className="ExitContinueContainer">
            <Button
                onClick={saveExit}
                priority="secondary"
                variety="full"
                type="button"
                className="SaveBtn"
            >
                Save & Exit
            </Button>
            <Button
                type="submit"
                disabled={disabled}
                priority="primary"
                variety="full"
                className="SaveBtn"
            >
                Save & Continue
            </Button>
        </div>
    );
};
