import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import {
    addExpiryDateAndCultureCode,
    getItemFromLocalStorage,
    storeInLocalStorage,
    shouldUseLocalStorageContent,
} from './cache';
import { Toast } from './toast';

interface CMSItem {
    id: number;
    title: string;
    metaTitle: string;
    metaDescription: string;
    content: string;
}

export const useCMS = (path: string): CMSItem | null => {
    const cultureCode = useSelector(selectCultureCode);
    const dispatch = useDispatch();

    const [pageHTML, setPageHTML] = useState<null | CMSItem>(null);

    const cachedContent = getItemFromLocalStorage(path);
    const useLocalStorageTranslations = shouldUseLocalStorageContent(cachedContent, cultureCode);

    useEffect(() => {
        setPageHTML(null);
        if (cultureCode && useLocalStorageTranslations) {
            setPageHTML(cachedContent.responseContent);
            return;
        }
        if (cultureCode) {
            setPageHTML(null);
            instance
                .get<ApiResponse<CMSItem>>(endpoints.cmsmodule.pagecontent, { params: { path } })
                .then((res) => {
                    if (res.data.status === '1') {
                        const responseContent = res.data.details;
                        setPageHTML(responseContent);
                        const contentToCache = addExpiryDateAndCultureCode(
                            { responseContent },
                            cultureCode
                        );
                        storeInLocalStorage(contentToCache, path);
                    } else if (res.data.status === '0') {
                        console.error('Invalid path query param');
                    }
                })
                .catch((err) => {
                    Toast.openGenericErrorToast();
                });
        }
    }, [cultureCode, path]);

    return pageHTML;
};
