import { MoveMoneySubPages, SavedPayee } from '../pages/account/MoveMoney';
import { Store } from './rootReducer';

/* STATE */
type State = {
    payeeSubPage: MoveMoneySubPages;
    payee: SavedPayee | null;
    refreshTable: boolean;
};

const initialState: State = {
    payeeSubPage: MoveMoneySubPages.PAYEES_TABLE,
    payee: null,
    refreshTable: false,
};

/* ACTIONS */
const UPDATE_SUB_PAGE = 'app/payee/UPDATE_SUB_PAGE';
const REFRESH_TABLE = 'app/payee/REFRESH_TABLE';

type Action =
    | {
          type: typeof UPDATE_SUB_PAGE;
          payload: {
              page: MoveMoneySubPages;
              payee?: SavedPayee;
          };
      }
    | { type: typeof REFRESH_TABLE };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UPDATE_SUB_PAGE:
            return {
                ...state,
                payeeSubPage: action.payload.page,
                payee: action.payload.payee ?? null,
            };
        case REFRESH_TABLE:
            return {
                ...state,
                refreshTable: !state.refreshTable,
            };
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const updatePayeeSubpage = (payload: {
    page: MoveMoneySubPages;
    payee?: SavedPayee;
}): Action => {
    return {
        type: UPDATE_SUB_PAGE,
        payload,
    };
};
export const refreshPayeeTable = (): Action => {
    return { type: REFRESH_TABLE };
};

/* SELECTORS */
export const selectPayeeSubpage = (app: Store): State => app.payee;
