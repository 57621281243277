import { useTheme } from '@emotion/react';
import { getInitialsFromName } from 'helpers/getInitialsFromName';
import React, { useMemo } from 'react';

type InitialsIconProps = {
    name?: string;
    initials?: string;
    className?: string;
};

export const InitialsIcon: React.FC<InitialsIconProps> = ({ initials, name, className = '' }) => {
    const displayedInitials = useMemo(
        () => (initials ? initials : name ? getInitialsFromName(name) : ''),
        [initials, name]
    );
    const theme = useTheme();
    const color = theme.colors.first;

    return (
        <div className={`InitialsIcon ${className}`} style={{ backgroundColor: color }}>
            <span>{displayedInitials}</span>
        </div>
    );
};
