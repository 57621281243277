import Button from '../../../button/Button';
import { Modal } from '../../../modal/Modal';
import { OwnerTreeStructure } from '../../schema';
import { DeletedOwnersState } from './OwnersTree';

type Props = {
    visible: boolean;
    deleteOwnersInfo: DeletedOwnersState | null;

    onCloseModal: () => void;
    handleFinalDeletion: (
        businessesToUpdate: { fieldname: string; children: OwnerTreeStructure[] }[],
        owner: OwnerTreeStructure,
        parentFieldname: string
    ) => void;
};

export const DeleteModal: React.FC<Props> = ({
    visible,
    deleteOwnersInfo,
    onCloseModal,
    handleFinalDeletion,
}) => {
    if (!visible || !deleteOwnersInfo) return null;

    return (
        <Modal
            title="Delete Beneficial Owner"
            closeOnClickOutside
            className="OwnersTreeDeletionModal"
            onCloseModal={onCloseModal}
        >
            <p className="DeleteText">Are you sure you want to delete this Beneficial Owner?</p>
            <p className="DeleteText">All related Beneficial Owners will be deleted.</p>
            <div className="DeleteOwnerButtons">
                <Button priority="secondary" color="grey" type="button" onClick={onCloseModal}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    priority="primary"
                    onClick={() => {
                        onCloseModal();
                        handleFinalDeletion(
                            deleteOwnersInfo.businessesToUpdate,
                            deleteOwnersInfo.owner,
                            deleteOwnersInfo.parentFieldname
                        );
                    }}
                >
                    Delete
                </Button>
            </div>
        </Modal>
    );
};
