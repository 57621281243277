/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import instance, { ApiResponse } from 'api';
import { Spinner } from 'components/spinner/Spinner';
import { parseISO } from 'date-fns';
import { endpoints } from 'endpoints.config';
import { localizedFormatDate } from 'helpers/language';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast, ToastMessageReason } from 'helpers/toast';

//images
import FillerImage from 'assets/ibanera/IbaneraBounce.gif';
import { selectCultureCode } from 'reducers/language';

type ProfileDetails = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: string;
    countryName: string;
    countryISOCode: string;
    addressLine1: string;
    addressLine2?: string;
    townCity: string;
    stateProvinceName: string;
    postcode: string;
};

export const ProfileDetailsPage = () => {
    const [profileDetails, setProfileDetails] = useState<ProfileDetails | null>(null);
    const dispatch = useDispatch();
    const theme = useTheme();

    const cultureCode = useSelector(selectCultureCode);

    const profileCss = css`
        border: 1px solid ${theme.colors.first};
        color: ${theme.colors.first};
    `;

    useEffect(() => {
        instance
            .get<ApiResponse<ProfileDetails>>(endpoints.profilemodule.userProfileDetails)
            .then((res) => setProfileDetails(res.data.details))
            .catch(() =>
                Toast.openToastMessage(
                    'There was an error getting your details',
                    ToastMessageReason.ERROR
                )
            );
    }, []);
    if (!profileDetails) return <Spinner />;
    return (
        <div className="ProfileDetailsPage">
            <table className="ProfileDetails" css={profileCss}>
                <ProfileDetailRow
                    label={'Full Name'}
                    value={`${profileDetails.firstName} ${profileDetails.lastName}`}
                />
                <ProfileDetailRow
                    label={'Date of Birth'}
                    value={
                        profileDetails.dateOfBirth
                            ? localizedFormatDate(
                                  parseISO(profileDetails.dateOfBirth),
                                  cultureCode ?? undefined
                              )
                            : ''
                    }
                />
                <ProfileDetailRow label={'Nationality'} value={profileDetails.nationality} />
                <ProfileDetailRow
                    label={'Country of Residence'}
                    value={profileDetails.countryName}
                />
                <ProfileDetailRow label={'Address'} value={profileDetails.addressLine1} />
            </table>
            <img src={FillerImage} alt="" className="FillerImage" />
        </div>
    );
};

const ProfileDetailRow = ({ label, value }: { label: string; value: string }) => {
    return (
        <tr>
            <td className="Label">{label + ': '}</td>
            <td className="Value">{value}</td>
        </tr>
    );
};
