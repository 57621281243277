import React, { useEffect, useRef, useState } from 'react';
import WarningIcon from 'assets/ibanera/Icon_Warning.png';
import IconClose from '../../assets/ibanera/Icon_Close.png';
import { NOTIFICATION_ANIMATION_TIME } from './Notifications';
import { NotificationType } from './models';

type NotificationTileProps = {
    type: NotificationType;
    heading: string;
    message: string;
    handleClose: () => void;
};

const NotificationClassMap: { [key in NotificationType]: string } = {
    [NotificationType.SUCCESS]: 'Good',
    [NotificationType.INFO]: 'Neutral',
    [NotificationType.WARNING]: 'Bad',
    [NotificationType.ERROR]: 'Bad',
};

export const NotificationTile: React.FC<NotificationTileProps> = ({
    heading,
    message,
    type,
    handleClose,
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [notificationHeight, setNotificationHeight] = useState<number | null>(null);
    const [isOpening, setIsOpening] = useState(true);

    const handleCloseWithAnimation = () => {
        setIsClosing(true);
        setTimeout(() => {
            handleClose();
        }, NOTIFICATION_ANIMATION_TIME);
    };

    const notificationRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (notificationRef.current && !notificationHeight) {
            const height = notificationRef.current.getBoundingClientRect().height;
            setNotificationHeight(height);
        }
    }, [notificationHeight]);

    useEffect(() => {
        if (notificationRef.current && isOpening) {
            setTimeout(() => {
                // Need to set notification height again as we have a max-height
                // set to handle the notification animating in.
                const height = notificationRef.current?.getBoundingClientRect().height;
                if (height) {
                    setNotificationHeight(height);
                }
                setIsOpening(false);
            }, NOTIFICATION_ANIMATION_TIME);
        }
    }, [isOpening]);

    return (
        <div
            className={`NotificationTile ${NotificationClassMap[type]} ${
                isClosing ? 'Closing' : ''
            } ${isOpening ? 'Opening' : ''}`}
            ref={notificationRef}
            // Typescript definition for css doesn't include custom attributes,
            // hence asserting as any.
            style={{ ['--height' as any]: `${notificationHeight}px` }}
        >
            <button onClick={handleCloseWithAnimation} className="CloseButton">
                <img src={IconClose} />
            </button>
            <div className="TitleBar">
                {type === NotificationType.WARNING && (
                    <img src={WarningIcon} className="TitleIcon" />
                )}
                <h1 className="Title">{heading}</h1>
            </div>
            <div className="Body" dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );
};
