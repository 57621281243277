import { getErrorMessage } from 'errors';
import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

export enum ToastMessageReason {
    ERROR = 1,
    VALID,
    PENDING,
}

(window as any).toast = toast;

export class Toast {
    private static lastToastId: React.ReactText;
    private static isBlocking = false;
    public static openToastMessage(
        message: string,
        type: ToastMessageReason,
        options?: ToastOptions
    ) {
        if (this.isBlocking) return;
        if (type === ToastMessageReason.VALID) {
            Toast.lastToastId = toast.success(message, options);
        } else if (type === ToastMessageReason.PENDING) {
            Toast.lastToastId = toast.info(message, options);
        } else if (type === ToastMessageReason.ERROR) {
            Toast.lastToastId = toast.error(message, options);
        }
    }
    public static parseErrorIntoToast(errorCode?: string, options?: ToastOptions) {
        if (this.isBlocking) return;
        Toast.lastToastId = toast.error(getErrorMessage(errorCode ?? 'Generic'), options);
    }

    public static openGenericErrorToast(options?: ToastOptions) {
        if (this.isBlocking) return;
        Toast.lastToastId = toast.error('There was an error. Please try again later.', options);
    }
    public static openSuccessToast(message: string, options?: ToastOptions) {
        if (this.isBlocking) return;
        Toast.lastToastId = toast.success(message, options);
    }
    public static openErrorToast(message: string, options?: ToastOptions) {
        if (this.isBlocking) return;
        Toast.lastToastId = toast.error(message, options);
    }
    public static dismissAll = () => toast.dismiss();
    public static dismissLast = () => toast.dismiss(Toast.lastToastId);
    public static openBlockingToast(
        message: string,
        type: ToastMessageReason,
        options?: ToastOptions,
        blockDuration: number = 3000
    ) {
        this.openToastMessage(message, type, options);
        this.isBlocking = true;
        setTimeout(() => (this.isBlocking = false), blockDuration);
    }
}
