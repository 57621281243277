import Button from 'components/button/Button';
import { FormDateField } from 'components/form/FormDateField';
import FormTextField from 'components/form/FormTextField';
import { useFormikContext } from 'formik';
import { MutableRefObject, useEffect, useState } from 'react';

interface Props {
    index: number;
    arrayHelpers: any;
    idRef: MutableRefObject<number>;
    disable: boolean;
    disableRemove: boolean;
}
const InternalInvestor: React.FC<Props> = ({
    index,
    arrayHelpers,
    disable,
    disableRemove,
    idRef,
}) => {
    const [key, setKey] = useState<number | null>(null);
    const { setFieldValue, values } = useFormikContext<any>();
    useEffect(() => {
        setKey(idRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFieldValue(`corporate.authorizedCapital.internalInvestments.${index}.number`, index + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.corporate.authorizedCapital.internalInvestments.length]);

    return (
        <div className="FormBox" key={`Corporate-AuthorizedCapital-InternalInvestor-${key}`}>
            <label className="FormSideBySide FormLabel">Internal Investor {index + 1}</label>
            <div className="FormSideBySide">
                <FormTextField
                    field={`corporate.authorizedCapital.internalInvestments.${index}.employeeName`}
                    label={'Employee Name'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
                <FormTextField
                    field={`corporate.authorizedCapital.internalInvestments.${index}.position`}
                    label={'Position Held'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={`corporate.authorizedCapital.internalInvestments.${index}.amount`}
                    label={'Amount Invested'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    type="number"
                    // asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={`corporate.authorizedCapital.internalInvestments.${index}.date`}
                        label={'Date of Investment'}
                        error={true}
                    />
                </div>
            </div>
            <FormTextField
                field={`corporate.authorizedCapital.internalInvestments.${index}.sourceOfWealth`}
                label={'Source of Wealth'}
                required={false}
                maxLength={200}
                sideBySide={true}
                // asterisk={true}
            />
            {!disableRemove && (
                <Button
                    type="button"
                    priority="primary"
                    variety="full"
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={disable}
                >
                    Remove Internal Investor
                </Button>
            )}
        </div>
    );
};

export default InternalInvestor;
