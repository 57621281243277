/** @jsxImportSource @emotion/react */
import React from 'react';
import { TableInfo } from 'api';
import { toPairs } from 'ramda';
import { Cell, CellType, CellOptions } from './Cell';
import { MiddleTable } from './MiddleTable';
import { RightTable } from './RightTable';
import { LeftTable } from './LeftTable';
import { ColumnDetail } from '@avamae/table';
import { useTheme } from '@emotion/react';

export type Checkbox = {
    selectedIds: (string | number)[];
    setSelectedIds: React.Dispatch<React.SetStateAction<(string | number)[]>>;
    idColumn: string;
    singleRowSelect?: boolean;
};
export type SingleCheckbox = {
    selectedId: (string | number) | null;
    setSelectedId: React.Dispatch<React.SetStateAction<(string | number) | null>>;
};

export type PrimitiveFormatters = Partial<{
    boolean(b: boolean): React.ReactNode;
    string(s: string): React.ReactNode;
    number(n: number): React.ReactNode;
    date(d: Date): React.ReactNode;
}>;

export type TableProps<T = any> = {
    idColumn: string;
    table?: TableInfo;
    selectedIds?: number[];
    primitiveFormatters?: PrimitiveFormatters;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    checkbox?: Checkbox;
    rowOptionComponent?(data: any): React.ReactNode;
    onFilterClick?(): void;
    hiddenColumns?: string[];
    widthSetting?: 'Calculate' | '100%';
    rowHeight?: 'default' | 'large';
    rowButton?(data: any): React.ReactNode;
    onRowClick?(id: number): void;
    noRowHover?: boolean;
    splitFromLeft?: number;
    splitFromRight?: number;
    buttonInMiddleTable?: boolean;
    rowCellOptions?: { [K in keyof T]?: CellOptions };
    rowConfig?: RowConfig;
    showIdColumn?: boolean;
    hideFiltering?: boolean;
    showFiltering?: boolean;
    singleCheckbox?: SingleCheckbox;
};

function thousands_separators(num: number): string {
    var num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
}

// Defaults
const defaultPrimitiveFormatters: PrimitiveFormatters = {
    boolean: (b: boolean) => (b ? <span>Yes</span> : <span>No</span>),
    date: (d: Date) => {
        return new Date(d).toDateString();
    },
    string: (s: string) => <span>{s}</span>,
    number: (n: number) => <span>{thousands_separators(n)}</span>,
};

const FlexTable: React.FC<TableProps> = ({
    idColumn,
    checkbox,
    table,
    selectedIds = [],
    onRowClick,
    onFilterClick,
    primitiveFormatters,
    widthSetting = 'Calculate',
    rowHeight = 'default',
    rowButton,
    rowOptionComponent,
    hiddenColumns = [],
    rowFormatter,
    noRowHover,
    splitFromLeft = 0,
    splitFromRight = 0,
    buttonInMiddleTable = false,
    rowCellOptions,
    rowConfig,
    showIdColumn,
    hideFiltering,
    showFiltering,
    singleCheckbox,
}) => {
    const [rowHovered, setRowHovered] = React.useState<number | null>(null);
    const theme = useTheme();

    const rawColumns: ColumnDetail[] = table && table.data ? table.data.details.columns : [];
    const rawListData: { [k: string]: any }[] =
        table && table.data ? (table.data.details.listData as any) : [];

    const processedColumns = rawColumns.filter((c) => {
        if (
            (!showIdColumn && (c.columnKey as string).toLowerCase() === idColumn.toLowerCase()) ||
            !c.bVisible
        ) {
            return false;
        }

        return !hiddenColumns
            .map((x) => x.toLowerCase())
            .includes(c.columnKey.toString().toLowerCase());
    });

    function formatPrimitives(value: unknown) {
        const formatter = { ...defaultPrimitiveFormatters, ...primitiveFormatters };
        if (typeof value === 'string' && formatter.string) return formatter.string(value);
        if (typeof value === 'number' && formatter.number) return formatter.number(value);
        if (typeof value === 'boolean' && formatter.boolean) return formatter.boolean(value);

        return value;
    }

    const handleRowHover = (_e: React.MouseEvent<HTMLDivElement>, rowIndex: number) => {
        setRowHovered(rowIndex);
    };

    const getTableWrapperClassName = () => {
        let className = 'FlexTableWrapper';
        if (checkbox?.selectedIds && checkbox.selectedIds?.length > 0) {
            className += ' SmallerWidth';
        }
        if (rowHeight === 'large') {
            className += ' LargeRow';
        }
        // if (singleCheckbox?.selectedId) {
        //     className += ' singlecheckbox';
        // }
        return className;
    };

    return (
        <>
            <div className={getTableWrapperClassName()} onMouseLeave={() => setRowHovered(null)}>
                <div className="Inner">
                    <div
                        className="TableContainer"
                        css={{
                            '.Head': {
                                color: theme.colors.second,
                            },
                            '.DepositBtn, .WithdrawBtn': {
                                color: theme.colors.second,
                                borderColor: theme.colors.second,
                                '&:hover': { backgroundColor: theme.colors.secondBackground },
                            },
                        }}
                    >
                        <LeftTable
                            idColumn={idColumn}
                            columns={processedColumns ?? []}
                            toggleColumnSort={
                                table?.data?.actions &&
                                (table.data.actions.toggleColumnSort as (s: string) => void)
                            }
                            sortBy={table?.data?.details.sortBy as any}
                            checkbox={checkbox}
                            columnData={rawListData}
                            rowHovered={rowHovered}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={onRowClick}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            columnCount={splitFromLeft}
                            rowCellOptions={rowCellOptions}
                            singleCheckbox={singleCheckbox}
                        />
                        <MiddleTable
                            selectedIds={selectedIds}
                            columns={
                                processedColumns?.filter((_, i) => {
                                    let lowerBound = checkbox
                                        ? splitFromLeft <= 1
                                            ? 1
                                            : splitFromLeft
                                        : splitFromLeft;
                                    const upperBound = rawColumns.length - splitFromRight;
                                    return isBetween(i + 1, lowerBound, upperBound);
                                }) ?? []
                            }
                            columnData={rawListData}
                            toggleColumnSort={
                                table?.data?.actions &&
                                (table.data.actions.toggleColumnSort as (s: string) => void)
                            }
                            sortBy={table?.data?.details.sortBy as any}
                            checkbox={checkbox}
                            rowHovered={rowHovered}
                            rowButton={buttonInMiddleTable ? rowButton : undefined}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={onRowClick}
                            idColumn={idColumn}
                            hiddenColumns={hiddenColumns}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            rowCellOptions={rowCellOptions}
                            singleCheckbox={singleCheckbox}
                        />
                        <RightTable
                            idColumn={idColumn}
                            columns={processedColumns}
                            table={table as any}
                            checkbox={checkbox}
                            rawListData={rawListData}
                            rowHovered={rowHovered}
                            handleMouseEnterRow={handleRowHover}
                            onRowClick={onRowClick}
                            onFilterClick={onFilterClick}
                            formatPrimitives={formatPrimitives}
                            rowFormatter={rowFormatter}
                            noRowHover={noRowHover}
                            rowButton={rowButton}
                            rowOptionComponent={rowOptionComponent}
                            columnCount={splitFromRight}
                            buttonInMiddleTable={buttonInMiddleTable}
                            rowCellOptions={rowCellOptions}
                            rowConfig={rowConfig}
                            hideFiltering={hideFiltering}
                            //showFiltering={showFiltering}
                            singleCheckbox={singleCheckbox}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export { FlexTable };

type RowData = {
    node: React.ReactNode;
    options?: CellOptions;
    cellType?: CellType;
};

export type RowConfig = {
    colourFilterHighlights?: { id: string; classNames: string }[];
};

export type MakeRow<T> = {
    id: string;
    r: T;
    index: number;
    columns: ColumnDetail<T>[];
    formatPrimitives: (v: unknown) => unknown;
    hiddenColumns: string[];
    rowHovered: number | null;
    handleMouseEnterRow: (e: any, i: number) => void;
    onRowClick: (() => void) | null;
    rowButton?: () => React.ReactNode;
    formatRow?: (r: T) => { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    checkbox?: Checkbox;
    rowOptionComponent?: () => React.ReactNode;
    rowCellOptions?: { [K in keyof T]?: CellOptions };
    includeCheckbox?: boolean;
    includeFilterCell?: boolean;
    rowSelected?: boolean;
    hideRowOptionComponent?: boolean;
    singleCheckbox?: SingleCheckbox;
};

export function makeRow<T extends { [K: string]: React.ReactNode }>(
    props: MakeRow<T>
): React.ReactNode {
    const {
        id,
        r,
        index,
        columns,
        formatPrimitives,
        hiddenColumns,
        rowHovered,
        handleMouseEnterRow,
        onRowClick,
        rowButton,
        formatRow,
        noRowHover,
        checkbox,
        rowOptionComponent,
        rowCellOptions,
        includeFilterCell,
        rowSelected,
        hideRowOptionComponent,
        includeCheckbox,
        singleCheckbox,
    } = props;
    const row = formatRow ? formatRow(r) : r;
    const columnKeys = columns.map((c) => c.columnKey.toString().toLowerCase());
    const findOrderNumber = (key: keyof T) => {
        const col = columns.find(
            (x) => x.columnKey.toString().toLowerCase() === key.toString().toLowerCase()
        );
        if (!col) return 0;
        return col.orderNumber;
    };
    const pairs = toPairs(row)
        .filter(([k]) => {
            if (columnKeys.includes(k.toLowerCase()) && !hiddenColumns.includes(k.toLowerCase())) {
                return true;
            }

            return false;
        })
        .map(([k, v]) => [k, formatPrimitives(v)] as [keyof T, React.ReactNode])
        .sort((a, b) => {
            const aOrder = findOrderNumber(a[0]);
            const bOrder = findOrderNumber(b[0]);
            if (aOrder === bOrder) return 0;
            return aOrder < bOrder ? -1 : 1;
        }) as [keyof T, React.ReactNode][];

    const handleSelectionChange = () => {
        if (checkbox) {
            const { selectedIds, setSelectedIds } = checkbox;
            const checked = selectedIds.includes(id);
            if (checked) {
                setSelectedIds((ids) => ids.filter((i) => i !== id));
            } else {
                if (checkbox.singleRowSelect) setSelectedIds([id]);
                else setSelectedIds((ids) => [...ids, id]);
            }
        }
        if (singleCheckbox) {
            const { selectedId, setSelectedId } = singleCheckbox;
            const checked = selectedId === id;
            if (checked) {
                setSelectedId(null);
            } else {
                setSelectedId(id);
            }
        }
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        handleSelectionChange();
    };

    // const rowData = pairs.map(p => p[1]);
    const rowData = (): RowData[] => {
        let data: RowData[] = rowCellOptions
            ? pairs.map((p) => ({ node: p[1], options: rowCellOptions[p[0]] ?? {} }))
            : pairs.map((p) => ({ node: p[1] }));
        if (checkbox && includeCheckbox) {
            data = [
                {
                    node: (
                        <div>
                            <input
                                id={id}
                                type="checkbox"
                                checked={checkbox.selectedIds.includes(id)}
                                onChange={handleCheckboxChange}
                            />
                            <label className="CustomInput" htmlFor={id} />
                        </div>
                    ),
                    cellType: 'checkboxCell',
                },
                ...data,
            ];
        }

        if (rowButton) {
            data = [
                ...data,
                { node: rowButton(), cellType: 'buttonCell', options: rowCellOptions?.['_button'] },
            ];
        }

        if (rowOptionComponent) {
            data = [...data, { node: rowOptionComponent(), cellType: 'rowOptionCell' }];
        }

        return data;
    };

    return (
        <React.Fragment key={index}>
            {noRowHover ? (
                <div
                    className={
                        rowSelected
                            ? 'Row NoHover Selected'
                            : singleCheckbox?.selectedId === id
                            ? 'Row NoHover single-checkbox'
                            : 'Row NoHover'
                    }
                    key={'RST_Row' + index}
                >
                    {rowData().map((r, i) => {
                        return (
                            <Cell
                                key={i}
                                onClick={
                                    r.cellType !== 'checkboxCell' && r.cellType !== 'rowOptionCell'
                                        ? onRowClick ?? handleSelectionChange
                                        : () => {}
                                }
                                options={{
                                    cellType: r.cellType,
                                    ...r.options,
                                }}
                            >
                                {r.cellType === 'rowOptionCell' && hideRowOptionComponent
                                    ? null
                                    : r.node}
                            </Cell>
                        );
                    })}
                    {includeFilterCell && (
                        <Cell options={{ cellType: 'filterCell', noSortIcon: true }} />
                    )}
                </div>
            ) : (
                <div
                    className={
                        rowSelected
                            ? 'Row Selected'
                            : singleCheckbox?.selectedId === id
                            ? 'Row single-checkbox'
                            : rowHovered === index
                            ? 'Row Highlight'
                            : 'Row'
                    }
                    key={'RST_Row' + index}
                    onMouseEnter={(e) => handleMouseEnterRow(e, index)}
                >
                    {rowData().map((r, i) => (
                        <Cell
                            key={i}
                            onClick={
                                r.cellType !== 'checkboxCell' && r.cellType !== 'rowOptionCell'
                                    ? onRowClick ?? handleSelectionChange
                                    : () => {}
                            }
                            options={{
                                cellType: r.cellType,
                                ...r.options,
                            }}
                        >
                            {r.cellType === 'rowOptionCell' && hideRowOptionComponent
                                ? null
                                : r.node}
                        </Cell>
                    ))}
                    {includeFilterCell && (
                        <Cell options={{ cellType: 'filterCell', noSortIcon: true }} />
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

// helpers
const isBetween = (numOfInterest: number, lowerBound: number, higherBound: number) =>
    numOfInterest > lowerBound && numOfInterest < higherBound;
