import instance, { isErrorHandled, retrieveErrorMessages } from 'api';
import Button from 'components/button/Button';
import { ErrorM } from 'components/form/ErrorM';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { stringOnlyContainsNumbers } from 'helpers/stringOnlyContainsNumbers';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    isVerifying: Yup.boolean(),
    phoneNumber: Yup.string().required('Please enter a phone number'),
    tfaCode: Yup.string().when('showTfa', {
        is: true,
        then: Yup.string().required('Please enter your two-factor authentication code'),
    }),
    phoneNumberVerificationCode: Yup.string().when('isVerifying', {
        is: true,
        then: Yup.string().test(
            'is valid verification',
            'Please enter your verifiction code',
            (value: string | undefined) => {
                if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
                    return true;
                }
                return false;
            }
        ),
    }),
});

enum UpdatePhoneNumberStep {
    ADD_NUMBER = 1,
    VERIFY,
}

const initialValues = {
    showTfa: false,
    isVerifying: false,
    phoneNumber: '',
    tfaCode: '',
    phoneNumberVerificationCode: '',
};

export const ChangePhoneNumberModal: React.FC = ({}) => {
    const dispatch = useDispatch();
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);

    const [errors, setErrors] = useState<string[] | null>(null);
    const [currentStep, setCurrentStep] = useState<UpdatePhoneNumberStep>(
        UpdatePhoneNumberStep.ADD_NUMBER
    );
    const [storedPhoneNumber, setStoredPhoneNumber] = useState<string | null>(null);

    const handleSubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        currentStep === UpdatePhoneNumberStep.ADD_NUMBER
            ? handleAddNumberSubmit(values, helpers)
            : handleVerifySubmit(values, helpers);
    };

    const handleAddNumberSubmit = async (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        try {
            await instance.post(endpoints.profilemodule.updatePhoneNumber, {
                ...values,
                tfaType: tfaType,
            });
            setStoredPhoneNumber(values.phoneNumber);
            setCurrentStep(UpdatePhoneNumberStep.VERIFY);
            helpers.setFieldValue('isVerifying', true);
            helpers.setFieldTouched('phoneNumberVerificationCode', false);
            setErrors(null);
        } catch (error: any) {
            if (isErrorHandled(error)) {
                const responseErrors = retrieveErrorMessages(error.response.data.errors);
                setErrors(responseErrors);
            } else {
                dispatch(closeModal());
                Toast.openGenericErrorToast();
            }
        }
        helpers.setSubmitting(false);
    };

    const handleVerifySubmit = async (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        if (storedPhoneNumber) {
            try {
                await instance.post(endpoints.profilemodule.updatePhoneNumberConfirm, {
                    ...values,
                    phoneNumber: storedPhoneNumber,
                });
                dispatch(closeModal());
                Toast.openToastMessage(
                    'Phone number changed successfully',
                    ToastMessageReason.VALID
                );
            } catch (error: any) {
                if (isErrorHandled(error)) {
                    const responseErrors = retrieveErrorMessages(error.response.data.errors);
                    setErrors(responseErrors);
                    helpers.setSubmitting(false);
                } else {
                    dispatch(closeModal());
                    Toast.openGenericErrorToast();
                }
            }
        } else {
            dispatch(closeModal());
            Toast.openGenericErrorToast();
        }
        helpers.setSubmitting(false);
    };

    // TODO(HC): Refactor into a single Formik context.
    return (
        <div className="UpdatePhoneNumberModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <Form>
                        <Modal
                            title="Change Phone Number"
                            customButtons={
                                <div className="ModalNavigation">
                                    <Button
                                        priority="secondary"
                                        type="button"
                                        onClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        priority="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting || !formikProps.isValid}
                                    >
                                        Update
                                    </Button>
                                </div>
                            }
                        >
                            {!formikProps.values.isVerifying ? (
                                <>
                                    <FormPhoneNumberField
                                        field="phoneNumber"
                                        label="New phone number"
                                    />
                                    {formikProps.values.showTfa && (
                                        <>
                                            <TFAField
                                                field={'tfaCode'}
                                                label={'Two-factor authentication code'}
                                                autoComplete={false}
                                                tfaType={tfaType}
                                                toggleTFAType={toggleTFAType}
                                                required
                                                autoFocus
                                            />
                                        </>
                                    )}
                                    {errors && (
                                        <>
                                            {errors.map((error) => (
                                                <p className="ErrorText NoMargin">{error}</p>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <ValidationCodeField
                                        required
                                        field="phoneNumberVerificationCode"
                                        label="Verification Code"
                                        autoComplete={false}
                                        value={formikProps.values.phoneNumberVerificationCode}
                                        autoFocus
                                        holdFocus
                                    />
                                    {errors && (
                                        <>
                                            {errors.map((error) => (
                                                <p className="ErrorText NoMargin">{error}</p>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export { ChangePhoneNumberModal as UpdatePhoneNumberModal };
