import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, selectModalState } from '../../reducers/modal';
import { TableEntryInfoPanel } from '../TableEntryInfoPanel/TableEntryInfoPanel';
import { Modal } from '../modal/Modal';
import styles from '../TableEntryInfoPanel/TableEntryInfoPanel.module.scss';

export const TransactionDetailsModal = () => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();

    if (modalState.modalType !== ModalTypes.TRANSACTION_DETAILS) return null;

    const { rowData, currencySymbol } = modalState.data;

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    return (
        <Modal className={styles.Modal}>
            <TableEntryInfoPanel
                selectedRowData={rowData}
                closePanel={handleCloseModal}
                currencySymbol={currencySymbol}
            />
        </Modal>
    );
};
