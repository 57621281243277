import { WrappedFormSingleSelectField as FormSingleSelectField } from 'components/form/FormSingleSelectField';
import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import api, { ApiResponse, isAxiosErrorHandled, useFetch } from '../../../api';
import FormTextField from '../../../components/form/FormTextField';
import { RadioButtons } from '../../../components/radiobuttons/radiobuttons';
import { endpoints } from '../../../endpoints.config';
import { useGetFiatAccountDetails } from '../../../helpers/useGetFiatAccountDetails';
import { selectPayeeSubpage } from '../../../reducers/payee';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import { GeneralError } from '../../../components/GeneralError/GeneralError';
import { Toast } from '../../../helpers/toast';
import { processErrors } from '../../../helpers/categoryHelpers/processErrors';
import Button from '../../../components/button/Button';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { AddressLookup } from './MakePayment/AddressLookup/AddressLookup';
import { ErrorCountry } from './MakePayment/Recipient';
import { ErrorCodes, selectErrorCodes } from '../../../reducers/errorCodes';
import { useFetchPayeeCountries } from './MakePayment/MakePayment';

type EditPayeeResponse = {
    id: number;
    accountName: string;
    name: string;
    payeesReference: string;
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postcode: string;
    addressCountryCode: string;

    type: PayeeType;
    achAccountNumber: string;
    achRoutingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    iban: string;
    bankName: string;
    countryCode: string;

    accountType: string;
    addDate: string;
    addedBy: string;
    allowedTransferTypes: string;
    bInternational: boolean;
    bPullAvailable: boolean;
    cardLast4Digits: null;
    countryName: string;
    currencyCode: string;
};

export enum PayeeType {
    Personal = 'Personal',
    Company = 'Company',
}

export type PayeeFormState = {
    payeeType: PayeeType;
    bankName: string;
    countryCode: string;
    accountName: string;
    name: string;
    payeesReference: string;

    routingNumber: string;
    swiftNumber: string;
    intermediaryBic: string;
    accountNumber: string;

    iban: string;
} & PayeeAddress;

export type PayeeAddress = {
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postCode: string;
    addressCountryCode: string;
};

export const EMPTY_PAYEE_VALUES: PayeeFormState = {
    payeeType: PayeeType.Personal,
    countryCode: '',
    accountName: '',
    name: '',
    payeesReference: '',
    routingNumber: '',
    swiftNumber: '',
    intermediaryBic: '',
    accountNumber: '',
    iban: '',
    bankName: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    state: '',
    postCode: '',
    addressCountryCode: '',
};

const validationSchemaCRB = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object({
        accountName: Yup.string().required('Please enter an account name').nullable(),
        countryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        name: Yup.string().required('Please enter a name').nullable(),
        addressLine1: Yup.string().required('Please enter an address').nullable(),
        townCity: Yup.string().required('Please enter an town or city').nullable(),
        postCode: Yup.string().required('Please enter a valid post/zip code').nullable(),
        addressCountryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        routingNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: 'USA',
                then: Yup.string().required('Please enter a routing number'),
            }),
        accountNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: 'USA',
                then: Yup.string().nullable().required('Please enter an account number'),
            }),
        swiftNumber: Yup.string()
            .nullable()
            .when('countryCode', {
                is: (value: string) => value !== 'USA',
                then: Yup.string().required('Please enter a swift number'),
            }),
        iban: Yup.string()
            .nullable()
            .when('countryCode', {
                is: (value: string) => value !== 'USA',
                then: Yup.string().required('Please enter an IBAN'),
            }),
        payeesReference: Yup.string()
            .required(`Please enter payee's reference`)
            .max(16, 'Reference cannot be longer than 16 characters')
            .matches(/^[\w\d ]*$/, 'Reference must not contain special characters')
            .nullable(),
        bankName: Yup.string()
            .required('Please enter the name of the destination bank')
            .max(35, 'Maximum 35 characters')
            .nullable(),
    });
const validationSchemaDBS = (errorCountries: ErrorCountry[], errorCodes: ErrorCodes) =>
    Yup.object({
        accountName: Yup.string().required('Please enter an account name').nullable(),
        countryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        name: Yup.string().required('Please enter a name').nullable(),
        addressLine1: Yup.string().required('Please enter an address').nullable(),
        townCity: Yup.string().required('Please enter an town or city').nullable(),
        postCode: Yup.string().required('Please enter a valid post/zip code').nullable(),
        addressCountryCode: Yup.string()
            .required('Please select a country')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const selectedCountry = errorCountries.find((cou) => cou.countryISO3 === val);

                const countryErrorCode = selectedCountry?.errorCode;
                if (countryErrorCode) {
                    const msg = errorCodes[countryErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
        accountNumber: Yup.string().required('Please enter an account number').nullable(),
        swiftNumber: Yup.string().required('Please enter a swift number').nullable(),
        intermediaryBic: Yup.string().nullable(),
        payeesReference: Yup.string()
            .required(`Please enter payee's reference`)
            .max(16, 'Reference cannot be longer than 16 characters')
            .matches(/^[\w\d ]*$/, 'Reference must not contain special characters')
            .nullable(),
        bankName: Yup.string()
            .required('Please enter the name of the destination bank')
            .max(35, 'Maximum 35 characters')
            .nullable(),
    });

export const usePayeeValidationSchema = (errorCountries: ErrorCountry[]) => {
    const accountDetails = useGetFiatAccountDetails();
    const errorCodes = useSelector(selectErrorCodes);

    return accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP
        ? validationSchemaDBS(errorCountries, errorCodes)
        : validationSchemaCRB(errorCountries, errorCodes);
};

type Props = {
    onBack: () => void;
};

export const EditPayee: React.FC<Props> = ({ onBack }) => {
    const { colors } = useTheme();

    const { payee } = useSelector(selectPayeeSubpage);

    const { data, loading, error } = useFetch<ApiResponse<EditPayeeResponse>>(
        endpoints.accounts.editPayee,
        { params: { id: payee?.payees__Id } },
        undefined,
        !payee
    );
    const { availableCountries, errorCountries } = useFetchPayeeCountries();

    const createInitValues = (): PayeeFormState => {
        if (!data) return EMPTY_PAYEE_VALUES;

        const { type, achAccountNumber, achRoutingNumber, postcode, ...values } = data.details;

        return {
            ...EMPTY_PAYEE_VALUES,
            payeeType: type,
            accountNumber: achAccountNumber,
            routingNumber: achRoutingNumber,
            postCode: postcode,
            ...values,
        };
    };

    const initialValues: PayeeFormState = createInitValues();

    const onSubmit = async (values: PayeeFormState, helpers: FormikHelpers<PayeeFormState>) => {
        try {
            const { postCode, ...payload } = values;
            const res = await api.post<ApiResponse<any>>(endpoints.accounts.editPayee, {
                id: payee?.payees__Id,
                postcode: postCode,
                ...payload,
            });

            if (res.data.status === '1') {
                Toast.openSuccessToast('Payee details updated');
                onBack();
            } else {
                throw new Error();
            }
        } catch (err) {
            if (isAxiosErrorHandled(err)) {
                const { errors } = err.response.data;
                if (errors.length > 0) {
                    helpers.setSubmitting(false);
                    const errorsObj = processErrors(errors);
                    helpers.setErrors(errorsObj);

                    if (errorsObj['error_Label']) {
                        Toast.openGenericErrorToast();
                    }
                } else {
                    Toast.openErrorToast('Failed to update details');
                }
            } else {
                Toast.openErrorToast('Failed to update details');
            }
        }
    };

    const validationSchema = usePayeeValidationSchema(errorCountries);

    const accountDetails = useGetFiatAccountDetails();

    const isDBS =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Form className="EditPayeePage">
                    <h1>Edit payee details</h1>

                    {loading && (
                        <div className="Loading">
                            <FoldingCube color={colors.first} size={80} />
                            <p>Loading payee</p>
                        </div>
                    )}
                    {!loading && error && (
                        <div className="Error">
                            <GeneralError message="Unable to load payee details" />
                        </div>
                    )}

                    {data && (
                        <>
                            <PayeeForm
                                isDBS={isDBS}
                                isEdit
                                availableCountries={availableCountries}
                            />
                            <UpdateWarning />
                            <Button type="submit" disabled={isSubmitting} color="third">
                                Save
                            </Button>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

type PayeeFormProps = {
    availableCountries: SelectOption[];
    isEdit?: boolean;
    fieldnamePrefix?: string;
    defaultShowLookup?: boolean;
    isDBS: boolean;
};

export const PayeeForm: React.FC<PayeeFormProps> = ({
    isEdit = false,
    availableCountries,
    fieldnamePrefix,
    defaultShowLookup,
    isDBS,
}) => {
    const { isSubmitting } = useFormikContext<PayeeFormState & { [key: string]: any }>();

    const buildFieldname = (name: string) =>
        fieldnamePrefix ? `${fieldnamePrefix}.${name}` : name;
    const [
        { value: countryCode },
        { touched: countryCodeTouched },
        { setTouched: setCountryCodeTouched },
    ] = useField(buildFieldname('countryCode'));

    const onChangeCountry = useCallback(
        (value: string, setFieldValue?: FormikHelpers<PayeeFormState>['setFieldValue']) => {
            !!value && !countryCodeTouched && setCountryCodeTouched(true);

            if (!setFieldValue || !value || !countryCode) return;
            if (value === 'USA') {
                setFieldValue(buildFieldname('iban'), '', false);
                !isDBS && setFieldValue(buildFieldname('swiftNumber'), '', false);
                return;
            }
            setFieldValue(buildFieldname('routingNumber'), '', false);
            setFieldValue(buildFieldname('accountNumber'), '', false);
        },
        [setCountryCodeTouched, countryCodeTouched, countryCode]
    );

    return (
        <>
            <h4>Recipient details</h4>
            <RadioButtons
                options={['Personal', 'Company']}
                fieldname={buildFieldname('payeeType')}
                label="Account Type"
                disabled={isSubmitting || isEdit}
            />
            <div className="Layout">
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('name')}
                    label="Payee Name"
                    tooltip="Name of the individual or business that is the beneficiary"
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('payeesReference')}
                    label="Payee Reference"
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('bankName')}
                    label="Bank Name"
                    tooltip="Name of the receiving bank"
                />
                <FormSingleSelectField
                    fieldName={buildFieldname('countryCode')}
                    options={availableCountries}
                    label="Bank Country"
                    onChange={onChangeCountry}
                    dropdownProps={{ isDisabled: isSubmitting }}
                />
                <FormTextField
                    disabled={isSubmitting}
                    field={buildFieldname('accountName')}
                    label="Account Name"
                    tooltip="Name of the account that belongs to the payee. This field may not be seen by a receiving bank."
                />

                {isDBS ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('intermediaryBic')}
                            label={'Intermediary Swift'}
                        />
                    </>
                ) : countryCode === 'USA' || !countryCode ? (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('routingNumber')}
                            label={'Routing Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('accountNumber')}
                            label={'Account Number'}
                        />
                    </>
                ) : (
                    <>
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('swiftNumber')}
                            label={'Swift Number'}
                        />
                        <FormTextField
                            disabled={isSubmitting}
                            field={buildFieldname('iban')}
                            label={'IBAN'}
                        />
                    </>
                )}
            </div>
            <h4>Recipient address</h4>
            <AddressLookup
                fieldnamePrefix={fieldnamePrefix}
                countryOptions={availableCountries}
                defaultShowLookup={
                    typeof defaultShowLookup === 'boolean' ? defaultShowLookup : !isEdit
                }
            />
        </>
    );
};

const UpdateWarning = () => {
    const { values, initialValues } = useFormikContext<PayeeFormState>();

    const { accountNumber, routingNumber, swiftNumber, intermediaryBic, iban } = values;
    const {
        accountNumber: initialAccountNumber,
        routingNumber: initialRoutingNumber,
        swiftNumber: initialSwiftNumber,
        intermediaryBic: initialintermediaryBic,
        iban: initalIban,
    } = initialValues;

    const showWarning =
        accountNumber !== initialAccountNumber ||
        routingNumber !== initialRoutingNumber ||
        swiftNumber !== initialSwiftNumber ||
        intermediaryBic !== initialintermediaryBic ||
        iban !== initalIban;

    return showWarning ? (
        <div className="EditPayeeWarning">
            <WarningIcon width={20} />
            <p>
                Warning! By updating this payee you may cause any pending transactions to them to
                fail
            </p>
        </div>
    ) : null;
};
