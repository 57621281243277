import { Link, navigate } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { SwitchAccountDropdown } from 'components/switchAccountDropdown/SwitchAccountDropdown';
import { UserDropdown } from 'components/userDropdown/UserDropdown';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteCustomerComponentResources,
    selectComponentResources,
} from 'reducers/componentResources';
import { AppPath, DashboardPath } from '../../appConstants';
import { selectSignedIn, switchAccountType } from '../../reducers/auth';
import { selectCultureCode } from '../../reducers/language';
import { selectImagePath } from '../../reducers/ui';
import Button from '../button/Button';
import LanguageDropdown from '../languageDropdown/LanguageDropdown';

const PublicHeaderItems: React.FC<{ cultureCode: string | null }> = ({ cultureCode }) => {
    return (
        <div className="Actions">
            {/* <LanguageDropdown /> */}
            <div className="Buttons">
                <Link to={`/${cultureCode}${AppPath.SIGN_IN}`}>
                    <Button variety="full" priority="secondary" className="BtnWhite">
                        Sign in
                    </Button>
                </Link>
                <Link to={`/${cultureCode}${AppPath.REGISTER}`}>
                    <Button variety="full" priority="primary" className="BtnBlue">
                        Register
                    </Button>
                </Link>
            </div>
        </div>
    );
};

const LoggedInHeaderItems: React.FC<{ cultureCode: string | null }> = ({ cultureCode }) => {
    const { userInfo } = useSelector(selectComponentResources);
    const switchButtonCopy =
        userInfo?.bAccountSwitchAvailable &&
        (userInfo.accountType === 'Business' ? 'Switch to personal' : 'Switch to business');
    const dispatch = useDispatch();

    const handleAccountSwitch = () => {
        dispatch(deleteCustomerComponentResources());
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`);
        //dispatch(switchAccountType());
    };

    return (
        <div className="Actions">
            {userInfo?.bAccountSwitchAvailable && <SwitchAccountDropdown />}
            {/* <LanguageDropdown /> */}
            <UserDropdown />
            {/* TODO(HC): Fetch whether already business account and hide accordingly */}
        </div>
    );
};

const Header = ({ hideLoginButtons }: { hideLoginButtons?: boolean }) => {
    const isSignedIn = useSelector(selectSignedIn);
    const imagePath = useSelector(selectImagePath);
    const cultureCode = useSelector(selectCultureCode);
    const { userInfo } = useSelector(selectComponentResources);
    const accountTypePathItem =
        userInfo?.accountType === 'Personal' ? AppPath.PERSONAL : AppPath.BUSINESS;

    return (
        <div className="Header">
            <div className="InnerHeader">
                <div className="Logo">
                    <Link
                        to={`/${cultureCode}${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`}
                    >
                        <img src={imagePath + '/Logo.png'} alt="Logo" />
                    </Link>
                </div>
                {isSignedIn ? (
                    <LoggedInHeaderItems cultureCode={cultureCode} />
                ) : hideLoginButtons ? null : (
                    <PublicHeaderItems cultureCode={cultureCode} />
                )}
            </div>
        </div>
    );
};

export default Header;
