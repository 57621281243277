import { useFormikContext } from 'formik';
import { googlePayHelper } from '../helpers/googlePay';
import React, { useEffect, useRef, useState } from 'react';
import instance, { ApiResponse } from '../api';
import { endpoints } from '../endpoints.config';
import { PaymentInfo } from 'pages/quickCoin/CardPaymentForm';
import { PurchaseDetails } from 'pages/quickCoin/QuickCoinPage';
import { usePrevious, useWhyDidYouUpdate } from '@avamae/hooks';

type Props = {
    onComplete: (res: PaymentInfo) => void;
    basket: PurchaseDetails;
    deviceSessionId: string;
    idempotencyKey: string;
};

export const GooglePayButton = ({ onComplete, basket, deviceSessionId, idempotencyKey }: Props) => {
    const { setSubmitting, isSubmitting } = useFormikContext<PurchaseDetails>();
    useEffect(() => {
        googlePayHelper.init().then((ready) => {
            if (ready) {
                const button = googlePayHelper.addGooglePayButton();
                googlePayButton.current = button;
                setReady(true); // on widget v1 setting ready before setting googlepaybutton.current meant the rerender still had the old value of googlepaybutton.current for some reason
            }
        });
    }, []);
    useEffect(() => {
        googlePayHelper.updateValues({
            price: basket.price?.toString() ?? '0',
            currencyCode: basket.currency,
        });
        googlePayHelper.updateOnComplete((paymentData) => {
            const { signature, protocolVersion, signedMessage } = JSON.parse(
                paymentData.paymentMethodData.tokenizationData.token
            );
            setSubmitting(true);
            instance
                .post<ApiResponse<PaymentInfo>>(endpoints.quickCryptoModule.submitGooglePay, {
                    googlePayProtocolVersion: protocolVersion,
                    googlePaySignature: signature,
                    googlePaySignedMessage: signedMessage,
                    currencyCode: basket.currency,
                    currencyAmount: basket.price,
                    deviceSessionId: deviceSessionId,
                    chargeCardIdempotencyKey: idempotencyKey,
                })
                .then((res) => {
                    setSubmitting(false);
                    onComplete(res.data.details);
                })
                .catch(() =>
                    onComplete({
                        paymentReference: '',
                        status: 'Failed',
                        redirectUrl: '',
                    })
                );
        });
    }, [basket.price, basket.currency, onComplete, setSubmitting, deviceSessionId, idempotencyKey]);

    const googlePayButton = useRef<HTMLElement | null>(null);
    const [ready, setReady] = useState(false);
    return ready && googlePayButton.current ? (
        <RenderHtmlElement el={googlePayButton.current} />
    ) : null;
};

const RenderHtmlElement = ({ el }: { el: HTMLElement }) => {
    const rootElement = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const container = rootElement.current;
        container?.appendChild(el);
        return () => {
            container?.removeChild(el);
        };
    }, [el]);
    return <div style={{ flexGrow: 1 }} ref={rootElement}></div>;
};
