import * as locales from "date-fns/locale";
import { toPairs } from "ramda";
import { getUserLocale } from "get-user-locale";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";

const localePairs = toPairs(locales);

const getLocaleConfig = () => {
  try {
    const foundLocale = localePairs.filter(([key, value]) => {
      const code = (value as any).code;
      return code ? code === userLocale : false;
    });

    return foundLocale && foundLocale[0] && foundLocale[0][1];
  } catch (_) {
    return null;
  }
};

export const registerUserLocale = () => {
  const localeConfig = getLocaleConfig();

  if (localeConfig) {
    registerLocale(userLocale, localeConfig);
  }
};

export const localizedFormatDate = (date: Date) => {
  try {
    const localeConfig = getLocaleConfig();
    if (!localeConfig) {
      throw new Error(); // caught below to default format to UK date
    }
    const formatted = format(date, "P", {
      locale: localeConfig,
    });
    return formatted;
  } catch (_) {
    return format(date, "dd/MM/yyyy");
  }
};

export const userLocale = getUserLocale();
