import { Form, Formik } from 'formik';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { ErrorM } from 'components/form/ErrorM';
import Button from 'components/button/Button';
import instance from 'api';
import { endpoints } from 'endpoints.config';
import { setVerificationStage } from 'reducers/verification';
import { BusinessVerificationStage } from '../businessVerificationModels';
import { setResponseErrors } from '../helpers/ExitContinueButtons';
import { useDispatch } from 'react-redux';
import { validationSchemaTerms } from '../businessVerificationSchema';
import { Toast, ToastMessageReason } from 'helpers/toast';
interface Props {
    isRepeatSubmission: boolean;
}

export const termInitialValues = {
    bIRSTaxFilingAgreement: false,
    bFATCAAgreement: false,
};
export const termRepeatSubmission = {
    bIRSTaxFilingAgreement: true,
    bFATCAAgreement: true,
};

const BusinessVerificationTerms: React.FC<Props> = ({ isRepeatSubmission }: Props) => {
    const dispatch = useDispatch();
    const submitTerms = async (values: any, helpers: any) => {
        try {
            const response = await instance.post(endpoints.businessverificationmodule.submitTerms, {
                ...values,
            });
            if (response.status === 200 && response.data && response.data.status === `1`) {
                dispatch(
                    setVerificationStage({
                        currentStage: BusinessVerificationStage.SignificantParties,
                    })
                );
            } else {
                // if (response.data.errors.length > 0) {
                //     setResponseErrors(response.data.errors, helpers, 'NotStarted');
                // }
            }
        } catch (error: any) {
            Toast.openToastMessage(
                'Error starting verification, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };
    const irsLabel =
        'I do confirm that I am personally up to date and compliant with IRS Tax filing requirements';
    const fatcaLabel =
        'I do confirm that I am in full compliance with the Foreign Account Tax Compliance Act (FATCA)';
    return (
        <Formik
            initialValues={isRepeatSubmission ? termRepeatSubmission : termInitialValues}
            validationSchema={validationSchemaTerms}
            onSubmit={(values, helper) => submitTerms(values, helper)}
        >
            {({ values, isSubmitting, isValidating }) => (
                <Form>
                    <div className="Terms">
                        <h1>Entity Verification</h1>
                        <p>
                            In accordance with legislation of the United States regarding tax
                            administration and prevention of money laundering and terrorist
                            financing, it’s obligated to collect information about Customer before
                            commencing business relationship in order to implement the "Know Your
                            Client" principle.
                        </p>
                        <p>
                            We reserve the right to ask for additional documents and/or information
                            at any time. We ensure that the information provided is deemed to be
                            confidential and will be protected as required by the Law of the United
                            States on Legal Protection of Personal Data and GDPR. For more
                            information please refer to our Privacy Policy.
                        </p>

                        <div style={{ marginBottom: 20 }}>
                            <div
                                className={`NameConfirmation ${
                                    values.bIRSTaxFilingAgreement ? 'Checked' : ''
                                }`}
                            >
                                <CheckboxInput name="bIRSTaxFilingAgreement" label={irsLabel} />
                            </div>
                            <ErrorM name="bIRSTaxFilingAgreement" />
                        </div>
                        <div style={{ marginBottom: 30 }}>
                            <div
                                className={`NameConfirmation ${
                                    values.bFATCAAgreement ? 'Checked' : ''
                                }`}
                            >
                                <CheckboxInput name="bFATCAAgreement" label={fatcaLabel} />
                            </div>
                            <ErrorM name="bFATCAAgreement" />
                        </div>
                        <Button
                            priority="primary"
                            variety="full"
                            type="submit"
                            disabled={isSubmitting || isValidating}
                        >
                            Start Verification
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BusinessVerificationTerms;
