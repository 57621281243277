import React, { useEffect, useState } from 'react';
import { navigate, RouteComponentProps, useLocation, useNavigate } from '@reach/router';
import SignInForm from './SignInForm';
import Page from '../../components/page/Page';
import TFAForm, { parseTfaForm } from './TFAForm';
import { selectCultureCode } from '../../reducers/language';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearAuthMessage,
    selectAuthStatus,
    setAuthStatus,
    signIn,
    SignInAttempt,
    tfaSignIn,
} from '../../reducers/auth';
import { AppPath, PageResourceKey } from '../../appConstants';
import { usePageTranslations } from 'helpers/usePageTranslations';
import { SignInTranslation, TFAFormDetails } from './signInTypes';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { Spinner } from 'components/spinner/Spinner';
import { EmailNeedsVerification } from './EmailNeedsVerification';
import { postEmailVerificationCode } from 'pages/register/RegisterApi';
import { RegisterInitialState } from 'pages/register/Register';
import { ReactivateAccount } from './ReactivateAccount';
import api, { ApiResponse } from '../../api';
import { endpoints } from '../../endpoints.config';

type Props = RouteComponentProps;

const SignIn: React.FC<Props> = () => {
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode) ?? 'en-US';
    const dispatch = useDispatch();
    const { state: navigationState } = useLocation();

    useEffect(() => {
        // clear authMessage when navigating away from signin page
        return function cleanup() {
            dispatch(clearAuthMessage());
        };
    }, [dispatch]);

    if (authStatus === 'signed_in') {
        navigate(`/${cultureCode}${AppPath.CHECKING_DETAILS}`, { state: navigationState as any });
    }

    useDefaultRoute(`/${cultureCode}${AppPath.SIGN_IN}`);

    const translations = usePageTranslations<SignInTranslation>(PageResourceKey.SIGN_IN);

    const initialValues: TFAFormDetails = {
        username: '',
        password: '',
        rememberMe: false,
        tfaCode: '',
        tfaType: null,
        useSMS: false,
        emailVerificationCode: '',
        bResendCode: false,
    };

    const parseSignIn = (values: TFAFormDetails): SignInAttempt => ({
        username: values.username,
        rememberMe: values.rememberMe,
        password: values.password,
    });

    const [reactivateSuccess, setReactivateSuccess] = useState(false);
    const [reactivateError, setReactivateError] = useState(false);
    const reactivateAccount = async (values: TFAFormDetails) => {
        try {
            setReactivateError(false);
            const res = await api.post<ApiResponse>(endpoints.auth.reactivateAccount, {
                username: values.username,
                password: values.password,
                rememberMe: values.rememberMe,
                tfaCode: values.tfaCode,
                tfaType: values.tfaType,
            });
            if (res.data.status === '1') {
                setReactivateSuccess(true);
            } else throw new Error();
        } catch (error) {
            setReactivateSuccess(false);
            setReactivateError(true);
        }
    };

    const handleSubmit = async (values: TFAFormDetails) => {
        if (authStatus === 'signed_out') {
            dispatch(signIn(parseSignIn(values)));
        } else if (authStatus === 'need_tfa') {
            dispatch(
                tfaSignIn(
                    parseTfaForm({ ...values, tfaType: values.useSMS ? 'SMS' : 'AuthenticatorApp' })
                )
            );
        } else if (authStatus === 'need_email_verified') {
            (async () => {
                const result = await postEmailVerificationCode(values);
                if (result.response) {
                    dispatch(signIn(parseSignIn(values)));
                }
            })();
        } else if (authStatus === 'needs_to_add_account_tfa') {
            dispatch(setAuthStatus('signed_out'));
            navigate(`/${cultureCode}${AppPath.REGISTER}`, {
                state: {
                    stage: 'tfa',
                    email: values.username,
                    password: values.password,
                } as RegisterInitialState,
            });
        } else if (authStatus === 'suppressed') {
            await reactivateAccount(values);
        }
    };

    const validationSchema = Yup.object({
        username: Yup.string()
            .required('Email address is required')
            .email('Email address is invalid'),
        password: Yup.string()
            .required('Password is required')
            .max(20, 'Field character count is invalid')
            .min(8, 'Field character count is invalid'),
        rememberMe: Yup.boolean(),
        tfaCode:
            authStatus === 'need_tfa'
                ? Yup.string().required('Please enter your 6-digit code').max(6)
                : Yup.string(),
        useSMS: Yup.boolean(),
    });

    return (
        <Page isPublic={true}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <>
                        <RegistrationRedirectWrapper />
                        {(authStatus === 'signed_out' || authStatus === 'pending') && (
                            <SignInForm translations={translations} />
                        )}
                        {authStatus === 'need_tfa' || authStatus === 'tfa_pending' ? (
                            <TFAForm translations={translations} />
                        ) : null}
                        {authStatus === 'suppressed' ? (
                            <ReactivateAccount
                                success={reactivateSuccess}
                                error={reactivateError}
                                translations={translations}
                            />
                        ) : null}
                        {authStatus === 'need_email_verified' && (
                            <EmailNeedsVerification
                                formikProps={formikProps}
                                accountDetails={{
                                    emailAddress: formikProps.values.username,
                                    password: formikProps.values.password,
                                }}
                            />
                        )}
                        {authStatus === 'signed_in' && <Spinner className="noBG-absCenter" />}
                    </>
                )}
            </Formik>
        </Page>
    );
};

const RegistrationRedirectWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const authStatus = useSelector(selectAuthStatus);
    const cultureCode = useSelector(selectCultureCode);
    const { values } = useFormikContext<TFAFormDetails>();
    const navigate = useNavigate();

    if (authStatus === 'need_email_verified') {
        dispatch(setAuthStatus('signed_out'));
        navigate(`/${cultureCode}${AppPath.REGISTER}`, {
            state: {
                stage: 'emailVerification',
                email: values.username,
                password: values.password,
            } as RegisterInitialState,
        });
    } else if (authStatus === 'needs_to_add_account_tfa') {
        dispatch(setAuthStatus('signed_out'));
        navigate(`/${cultureCode}${AppPath.REGISTER}`, {
            state: {
                stage: 'tfa',
                email: values.username,
                password: values.password,
            } as RegisterInitialState,
        });
    }

    return null;
};

export default SignIn;
