import instance, { isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { usePost } from 'helpers/usePost';
import { useTFAField } from 'helpers/useTFAField';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';

const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    tfaCode: '',
    useSms: false,
};

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    oldPassword: Yup.string().required('Please enter your current password'),
    newPassword: Yup.string()
        .required('Please enter your new password')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[0-9]/, 'Password must contain a number')
        .matches(/[a-zA-Z]/, 'Password must contain a letter'),
    confirmNewPassword: Yup.string()
        .required('Please confirm your new password')
        .test('passwords-match', 'Passwords must match', (value: any, testContext?: any) => {
            return testContext.parent.newPassword === value;
        }),
    tfaCode: Yup.string().when('showTfa', {
        is: true,
        then: Yup.string().required('Please enter your two-factor authentication code'),
    }),
});

export const ChangePasswordModal = () => {
    const dispatch = useDispatch();
    const { post, errorMessage } = usePost();
    const [isFormError, setIsFormError] = useState(false);

    const [showTfa, setShowTfa] = useState(false);
    const [TFAType, toggleTFAType] = useTFAField(showTfa);

    const initialValues = {
        showTfa: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        tfaCode: '',
    };

    const createModalButtons = (submitfn: () => void, submitting: boolean) => (
        <div className="ModalNavigation">
            <Button onClick={() => dispatch(closeModal())} priority="secondary" type={'button'}>
                Close
            </Button>
            <Button onClick={submitfn} priority="primary" disabled={submitting} type={'submit'}>
                Change password
            </Button>
        </div>
    );
    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        if (!values.showTfa) {
            formikHelpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            formikHelpers.setSubmitting(false);
            formikHelpers.setFieldTouched('tfaCode', false);
            return;
        }
        post(endpoints.profilemodule.updatePassword, { ...values, tfaType: TFAType })
            .then(() => {
                Toast.openToastMessage('Your password has been changed', ToastMessageReason.VALID);
                dispatch(closeModal());
            })
            .catch((err) => {
                if (isAxiosErrorHandled(err) && err.response.data.errors) {
                    err.response.data.errors.forEach((error) => {
                        const fieldName = toCamelCase(error.fieldName) as string;
                        if (Object.keys(validationSchema.fields).includes(fieldName)) {
                            setIsFormError(true);
                            formikHelpers.setFieldError(
                                fieldName,
                                getErrorMessage(error.messageCode)
                            );
                        }
                    });
                }
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ submitForm, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <Modal
                        title="Change Password"
                        customButtons={createModalButtons(submitForm, isSubmitting)}
                    >
                        <FormTextField
                            field="oldPassword"
                            label="Old password"
                            type="password"
                            required
                            onBlur={(e) => {
                                setFieldValue('oldPassword', e.target.value.trim(), true);
                                setFieldTouched('oldPassword', true);
                            }}
                        />
                        <FormTextField
                            field="newPassword"
                            label="New password"
                            type="password"
                            hint="8+ characters, must contain numbers & letters"
                            required
                            onBlur={(e) => {
                                setFieldValue('newPassword', e.target.value.trim(), true);
                                setFieldTouched('newPassword', true);
                            }}
                        />
                        <FormTextField
                            field="confirmNewPassword"
                            label="Confirm new password"
                            type="password"
                            required
                            onBlur={(e) => {
                                setFieldValue('confirmNewPassword', e.target.value.trim(), true);
                                setFieldTouched('confirmNewPassword', true);
                            }}
                        />
                        {values.showTfa && (
                            <TFAField
                                field={'tfaCode'}
                                label={'TFA Code'}
                                required={true}
                                toggleTFAType={toggleTFAType}
                                tfaType={TFAType}
                                autoFocus
                            />
                        )}
                        {errorMessage && !isFormError && (
                            <div className="ErrorText">{errorMessage}</div>
                        )}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
