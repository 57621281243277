import { NavigateFn, useNavigate } from '@reach/router';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Button from 'components/button/Button';
import {
    VariabilityCell,
    EmptyCell,
    HighLowCell,
    NameCell,
    PrecisionNumberCell,
    ValueCell,
} from 'components/flexTable/CustomCells';
import { FlexTable, TableProps } from 'components/flexTable/FlexTable';
import { replaceFinalPathComponent } from 'helpers/replaceFinalPathComponent';
import {
    FiatCurrency,
    generateCryptoLiveData,
    parseLowHighString,
    CryptoLiveData,
} from 'pages/crypto/CryptoPage';
import { plaintext } from 'plaintext.config';
import React, { useContext } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';

export type PortfolioTableRow = {
    assets__Id: number;
    assets__Code: string;
    assets__Name: string;
    balances__Balance: number;
    balances__Value: number;
    market__SellPrice: number;
    market__PercentChange: number;
    market__MarketHighLow: string;
    fiatAssets__Code: string;
};

export type PortfolioTableLiveDataPoint = {
    price: number | null;
    open: number | null;
    high: number | null;
    low: number | null;
};

export type PortfolioTableLiveData = {
    [key: string]: PortfolioTableLiveDataPoint;
};

const rowFormatter =
    (selectedCurrency: FiatCurrency, isLoading: boolean) =>
    (row: PortfolioTableRow): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: any = { ...row };

        if (row.assets__Name && row.assets__Code) {
            formattedRow = {
                ...formattedRow,
                assets__Name: !isLoading ? (
                    <NameCell name={row.assets__Name} ticker={row.assets__Code} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.balances__Value) {
            formattedRow = {
                ...formattedRow,
                balances__Value: !isLoading ? (
                    <ValueCell
                        balance={row.balances__Balance}
                        currencySymbol={selectedCurrency.symbol}
                        currencyTicker={selectedCurrency.code}
                        price={row.market__SellPrice}
                    />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.market__PercentChange || row.market__PercentChange === 0) {
            formattedRow = {
                ...formattedRow,
                market__PercentChange: isLoading ? (
                    <EmptyCell />
                ) : (
                    <VariabilityCell amount={row.market__PercentChange} />
                ),
            };
        }

        if (row.market__MarketHighLow) {
            const { high, low } = parseLowHighString(row.market__MarketHighLow);
            formattedRow = {
                ...formattedRow,
                market__MarketHighLow: !isLoading ? (
                    <HighLowCell
                        high={high}
                        low={low}
                        price={row.market__SellPrice}
                        currencySymbol={selectedCurrency.symbol}
                    />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        if (row.balances__Balance) {
            formattedRow = {
                ...formattedRow,
                balances__Balance: !isLoading ? (
                    <PrecisionNumberCell number={row.balances__Balance} />
                ) : (
                    <EmptyCell />
                ),
            };
        }

        return formattedRow;
    };

export const ExchangePortfolioTable: React.FC<
    TableProps & {
        selectedCurrency: FiatCurrency;
        isLoading: boolean;
        excludeBuySellButton?: boolean;
    }
> = (props) => {
    const formatter = rowFormatter(props.selectedCurrency, props.isLoading);
    const { setSelectedCurrencyInfo } = useContext(ExchangeCryptoContext);
    const navigate = useNavigate();

    return (
        <div className="TradeTableContainer ExchangePortfolioTableContainer">
            <FlexTable
                {...props}
                rowFormatter={formatter}
                rowButton={createRowButtons(
                    setSelectedCurrencyInfo,
                    navigate,
                    props.isLoading,
                    !!props.excludeBuySellButton
                )}
            />
            {props.table?.data?.details.listData.length === 0 && !props.table?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">{plaintext.tables.emptyPortFolioTable}</h3>
                </div>
            )}
        </div>
    );
};

const createRowButtons =
    (
        setSelectedCurrencyInfo: React.Dispatch<React.SetStateAction<CryptoLiveData | null>>,
        navigate: NavigateFn,
        isLoading: boolean,
        excludeBuySellButton?: boolean
    ) =>
    (rowData: PortfolioTableRow) => {
        const handleBuySell = (crypto: string, fiat: string) => {
            const {
                assets__Code,
                assets__Name,
                market__MarketHighLow,
                fiatAssets__Code,
                market__SellPrice: market__Price,
            } = rowData;
            const { high, low } = parseLowHighString(market__MarketHighLow as string);
            const latestData = generateCryptoLiveData(
                assets__Name,
                assets__Code,
                fiatAssets__Code,
                market__Price,
                low,
                high
            );
            setSelectedCurrencyInfo(latestData);
            navigate(replaceFinalPathComponent(window.location.pathname, '/trade'));
        };

        return !isLoading ? (
            <div className="MarketRowButtons">
                {excludeBuySellButton && (
                    <Button
                        className="Buy"
                        onClick={() =>
                            handleBuySell(rowData.assets__Code, rowData.fiatAssets__Code)
                        }
                    >
                        Buy / Sell
                    </Button>
                )}
                <Button className="WithdrawBtn" priority="secondary">
                    Account
                </Button>
            </div>
        ) : (
            <EmptyCell />
        );
    };
