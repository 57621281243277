/* eslint-disable react-hooks/exhaustive-deps */
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { useField, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { useAfterMountEffect } from '../../../../helpers/useAfterMountEffect';
import { FormDateField } from '../../../form/FormDateField';
import { WrappedFormSingleSelectField } from '../../../form/FormSingleSelectField';
import FormTextField from '../../../form/FormTextField';
import { FormValues } from '../../schema';
import Button from '../../../button/Button';

export type BusinessOwnerProps = {
    fieldName: string;
    treeFieldname: string;
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    entityTypes: SelectOption[];

    onClose: () => void;
};

export const BusinessOwner: React.FC<BusinessOwnerProps> = ({
    fieldName,
    treeFieldname,
    availableCountries,
    entityTypes,
    onClose,
}) => {
    const { setFieldValue } = useFormikContext<FormValues>();
    const [{ value }, , { setValue }] = useField(fieldName);
    const [{ value: countryValue }] = useField<any[]>(`${fieldName}.addressCountry`);
    const [{ value: operatingCountryValue }] = useField<any[]>(
        `${fieldName}.operatingAddressCountry`
    );
    const [{ value: entityTypesIdValue }] = useField<any[]>(`${fieldName}.entityTypesID`);
    const [{ value: treeValue }, , { setValue: setTreeValue }] = useField(treeFieldname);

    // Store initial state in case they want to close the form rather than save
    const [initialValues] = useState({ business: value, treeValue });

    const handleCloseWithoutSave = () => {
        setValue(initialValues.business);
        setTreeValue(initialValues.treeValue);
        onClose();
    };

    const stateOptions = useMemo(() => {
        return availableCountries.find((country) => country.value === countryValue)?.states ?? null;
    }, [availableCountries, countryValue]);

    const operatingStateOptions = useMemo(
        () =>
            availableCountries.find((country) => country.value === operatingCountryValue)?.states ??
            null,
        [availableCountries, operatingCountryValue]
    );

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.addressState`, '');
    }, [stateOptions]);
    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.operatingAddressState`, '');
    }, [operatingStateOptions]);

    const isOtherEntityType =
        entityTypesIdValue &&
        entityTypesIdValue == entityTypes?.find((type) => type.label === 'Other')?.value;

    return (
        <div className="Form">
            <h1>Add Beneficial Owner Information</h1>
            <FormTextField field={`${fieldName}.entityName`} label={'Legal Name of Entity'} />
            <div className="FormSideBySide">
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={`${fieldName}.entityTypesID`}
                        options={entityTypes}
                        label={'Type of Entity'}
                    />
                </div>

                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={`${fieldName}.registrationDate`}
                        label={'Date of Entity Registration'}
                    />
                </div>
            </div>
            {isOtherEntityType && (
                <FormTextField field={`${fieldName}.entityTypesOther`} label={'Entity Type'} />
            )}
            <div className="FormSideBySide">
                <FormTextField
                    field={`${fieldName}.registrationNumber`}
                    label={'Official Business Registration Number'}
                    sideBySide
                />
                <FormTextField
                    field={`${fieldName}.taxNumber`}
                    sideBySide
                    label={'Tax ID Number'}
                />
            </div>
            <FormTextField
                type="textarea"
                field={`${fieldName}.doingBusinessAs`}
                label={'Doing Business As - DBA (Please list all business names.)'}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={`${fieldName}.addressStreet`}
                    label={'Registered Address Street'}
                    sideBySide
                />
                <FormTextField
                    field={`${fieldName}.addressNumber`}
                    label={'Registered Address Unit Number'}
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={`${fieldName}.addressPostCode`}
                    label={'Registered Address Postal Code'}
                    sideBySide
                />
                <FormTextField
                    field={`${fieldName}.addressCity`}
                    label={'Registered Address City'}
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                {stateOptions ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName={`${fieldName}.addressState`}
                            options={stateOptions}
                            label={
                                'Registered Address State / County / Province (as applicable to the jurisdiction)'
                            }
                        />
                    </div>
                ) : (
                    <FormTextField
                        field={`${fieldName}.addressState`}
                        label={
                            'Registered Address State / County / Province (as applicable to the jurisdiction)'
                        }
                        sideBySide
                    />
                )}
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={`${fieldName}.addressCountry`}
                        options={availableCountries}
                        label={'Registered Country'}
                    />
                </div>
            </div>
            <FormTextField
                field={`${treeFieldname}.percentageOwned`}
                label={'Percentage of Shares Owned (%)'}
                type="number"
                maxLength={200}
            />
            <h3>Business Operating Information (If different than registerd information.)</h3>
            <div className="FormSideBySide">
                <FormTextField
                    field={`${fieldName}.operatingAddressStreet`}
                    label={'Operating Address Street'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
                <FormTextField
                    field={`${fieldName}.operatingAddressNumber`}
                    label={'Operating Address Unit Number'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={`${fieldName}.operatingAddressPostCode`}
                    label={'Operating Address Postal Code'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
                <FormTextField
                    field={`${fieldName}.operatingAddressCity`}
                    label={'Operating City'}
                    labelExtraInfo="Optional"
                    sideBySide
                />
            </div>
            <div className="FormSideBySide">
                {operatingStateOptions ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName={`${fieldName}.operatingAddressState`}
                            options={operatingStateOptions}
                            label={
                                'Operating State / County / Province (as applicable to the jurisdiction)'
                            }
                        />
                    </div>
                ) : (
                    <FormTextField
                        field={`${fieldName}.operatingAddressState`}
                        label={
                            'Operating State / County / Province (as applicable to the jurisdiction)'
                        }
                        labelExtraInfo="Optional"
                        sideBySide
                    />
                )}
                <div className="FormBuilderComponent SideBySide">
                    <WrappedFormSingleSelectField
                        fieldName={`${fieldName}.operatingAddressCountry`}
                        options={availableCountries}
                        label={'Operating Country'}
                        labelExtraInfo="Optional"
                    />
                </div>
            </div>
            <div className="BusinessOwnerButtons">
                <Button onClick={handleCloseWithoutSave} type="button" priority="secondary">
                    Close
                </Button>
                <Button onClick={onClose} type="button">
                    Save
                </Button>
            </div>
        </div>
    );
};
