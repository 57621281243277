import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions, DropEvent } from 'react-dropzone';

export const useResetableDropzone = (options: DropzoneOptions = {}, fieldName?: string) => {
    const formik = useFormikContext<any>();
    const onDropAccepted = useCallback(
        (files: File[], dropEvent: DropEvent) => {
            if (options.multiple) {
                setAcceptedFiles((curr) => [...curr, ...files]);
                fieldName && formik.setFieldValue(fieldName, [...acceptedFiles, ...files]);
            } else {
                setAcceptedFiles(files);
                fieldName && formik.setFieldValue(fieldName, files[0]);
            }
            options.onDropAccepted && options.onDropAccepted(files, dropEvent);
        },
        [options]
    );
    const dropzoneValues = useDropzone({ ...options, onDropAccepted });
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>(
        formik.initialValues[fieldName as any] ?? []
    );
    const resetFiles = useCallback(() => {
        setAcceptedFiles([]);
        //fieldName && formik.setFieldValue(fieldName, options.multiple ? [] : null);
        fieldName && formik.setFieldValue(fieldName, formik.initialValues[fieldName as any]);
    }, []);

    return { ...dropzoneValues, acceptedFiles, resetFiles };
};
