import { useTable } from 'api';
import { PortfolioTableRow } from './ExchangePortfolioTable';
import { TradeTableRow } from './ExchangeTradeTable';
import { useContext, useEffect } from 'react';
import { ExchangeCryptoContext } from './ExchangePage';
import { FiatCurrency } from 'pages/crypto/CryptoPage';

type CryptoTableMetadata = {
    AvailableFiatCurrencies: FiatCurrency[];
    WebSocketAssetPairs: string[];
};

export const generateCryptoPairString = (crypto: string, fiat: string) => `${crypto}/${fiat}`;

// https://stackoverflow.com/a/62620115
const DUMMY_CREATE_TRADE_TABLE = () => useTable<TradeTableRow, any>({ url: '' });
const DUMMY_CREATE_PORFOLIO_TABLE = () => useTable<PortfolioTableRow, any>({ url: '' });
type TradeTable = ReturnType<typeof DUMMY_CREATE_TRADE_TABLE>;
type PortfolioTable = ReturnType<typeof DUMMY_CREATE_PORFOLIO_TABLE>;

export const useExchangeContextEffects = (table: TradeTable | PortfolioTable) => {
    // Push metadata from list endpoint up to context.
    const {
        setAssetPairs,
        fiatCurrencies,
        setFiatCurrencies,
        setSelectedFiatCurrency,
        selectedFiatCurrency,
        isPageLoading,
        setIsPageLoading,
    } = useContext(ExchangeCryptoContext);

    useEffect(() => {
        if (table.data?.details.metadata && fiatCurrencies.length === 0) {
            const meta = table.data?.details.metadata as CryptoTableMetadata;
            setFiatCurrencies(meta.AvailableFiatCurrencies);
            const selectedFiatCurrency = meta.AvailableFiatCurrencies.find(
                (currency) => currency.code === 'USD'
            ) as FiatCurrency;
            setSelectedFiatCurrency(selectedFiatCurrency);
        }
    }, [
        table.data?.details.metadata,
        setAssetPairs,
        fiatCurrencies.length,
        setFiatCurrencies,
        setSelectedFiatCurrency,
    ]);

    useEffect(() => {
        if (isPageLoading && !table.loading) {
            setIsPageLoading(false);
        }
    }, [table.loading, table.data, setIsPageLoading, isPageLoading]);

    // Apply filter when selected currency changes.
    useEffect(() => {
        if (selectedFiatCurrency) {
            const columnKey: keyof PortfolioTableRow | TradeTableRow = 'fiatAssets__Code';
            table.data?.actions.setFilters([
                {
                    columnKey: columnKey,
                    operator: 'EQ',
                    value: selectedFiatCurrency?.code,
                },
            ]);
        }
    }, [selectedFiatCurrency, table.data?.actions]);
};
