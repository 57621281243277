import { navigate } from '@reach/router';
import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import logger from 'helpers/logger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPendingTopUpCardReady, setPendingTopUpCardReady } from 'reducers/cards';
import {
    getCustomerComponentResources,
    selectAccountTypePathItem,
} from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { ModalTypes, PendingCard, closeModal, selectModalState } from 'reducers/modal';

type Props = {};

export const PendingCardModal = (props: Props) => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState<PendingCard | null>(null);

    const pathNameEnding = window.location.pathname.split('/').pop();
    const [cardReady, setCardReady] = useState<boolean>(false);

    const pendingTopUpCardReady = useSelector(selectPendingTopUpCardReady);

    const cultureCode = useSelector(selectCultureCode);
    const accountType = useSelector(selectAccountTypePathItem);

    useEffect(() => {
        if (pendingTopUpCardReady || modalData?.bReadyForActivation) {
            setCardReady(true);
        } else {
            setCardReady(false);
        }
    }, [pendingTopUpCardReady, modalData?.bReadyForActivation]);

    useEffect(() => {
        if (modalState.modalType !== ModalTypes.PENDING_CARD) {
            return;
        } else {
            const { id, cardUrlPath, bReadyForActivation } = modalState.data;

            setModalData({ id, cardUrlPath, bReadyForActivation });
        }
    }, [modalState, dispatch]);

    if (modalState.modalType !== ModalTypes.PENDING_CARD) return null;

    return (
        <Modal
            title={`Card ${cardReady ? 'Ready' : 'Pending'}`}
            onCloseModal={() => {
                if (pathNameEnding === 'addcard') {
                    navigate('/transactions');
                }

                if (cardReady) {
                    dispatch(setPendingTopUpCardReady(false));
                }

                dispatch(getCustomerComponentResources());
                dispatch(closeModal());
            }}
            className="PendingCardModal"
            customButtons={
                <div className="ModalNavigation">
                    <Button
                        onClick={() => {
                            if (cardReady && modalData?.cardUrlPath && cultureCode && accountType) {
                                const parsedUrl = `/${cultureCode}${accountType}${modalData.cardUrlPath}`;

                                navigate(parsedUrl);

                                dispatch(setPendingTopUpCardReady(false));
                            } else if (pathNameEnding === 'addcard') {
                                navigate('/transactions');
                            }

                            dispatch(getCustomerComponentResources());
                            dispatch(closeModal());
                        }}
                    >
                        {cardReady ? 'Go to Card' : 'OK'}
                    </Button>
                </div>
            }
        >
            <div className="Text">{`${
                cardReady
                    ? 'New top up card is ready'
                    : 'We have received your request for a new top up card. We will review it and notify you when it has been accepted.'
            }`}</div>
        </Modal>
    );
};
