import React from 'react';
import { ErrorMessage } from 'formik';

const ErrorM: React.FC<{ name: string; style?: React.CSSProperties; className?: string }> = (
    props
) => (
    <ErrorMessage name={props.name}>
        {(msg) =>
            typeof msg === 'string' ? (
                <div style={props.style} className={`ErrorLabel ${props.className ?? ''}`}>
                    {msg}
                </div>
            ) : (
                <div></div>
            )
        }
    </ErrorMessage>
);

export { ErrorM };
