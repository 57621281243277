import { selectVerificationDetailsV3, setVerificationStage } from 'reducers/verification';
import instance from '../../../api';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { css, useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { endpoints } from 'endpoints.config';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { getPreviousStage } from '../BusinessVerificationV3Models';
/** @jsxImportSource @emotion/react */

const BackButton = () => {
    const { stage: currentStage } = useSelector(selectVerificationDetailsV3);
    const { setFieldValue, isSubmitting, isValidating } = useFormikContext();

    const disableButton = isSubmitting || isValidating;

    const [isGoingBack, setIsGoingBack] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const buttonColor = css`
        color: ${theme.colors.first};
    `;
    const handleBack = () => {
        if (isGoingBack) return;
        setIsGoingBack(true);
        (async () => {
            try {
                const res = await instance.post(endpoints.businessVerificationV3Module.backForm);
                if (res.data.status === '1') {
                    setFieldValue('businessVerificationStep', getPreviousStage(currentStage));
                    dispatch(
                        setVerificationStage({ currentStage: getPreviousStage(currentStage) })
                    );
                }
            } catch (error) {
                Toast.openToastMessage(
                    'an error occured, please try again later',
                    ToastMessageReason.ERROR
                );
            }
            setIsGoingBack(false);
        })();
    };

    return (
        <button
            css={buttonColor}
            type="button"
            className="Back"
            onClick={() => handleBack()}
            disabled={disableButton || isGoingBack}
        >
            {'< Back'}
        </button>
    );
};

export default BackButton;
