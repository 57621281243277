import {
    findDifferences,
    transformDotNotationObject,
} from 'components/businessVerification/helpers/validate';
import { BusinessVerificationStage, DocumentRejection } from './BusinessVerificationV2Models';
import * as Yup from 'yup';
import { FormikErrors, FormikValues } from 'formik';
import { processedErrors } from 'components/businessVerification/helpers/ExitContinueButtons';
import { BeneficialPersonalOwner, FormValues } from './schema';

type ValidationSchemas = Partial<{
    [key in BusinessVerificationStage]: any;
}>;

const registrationInformationSchema = Yup.object({
    registrationInformation: Yup.object({
        entityName: Yup.string()
            .nullable()
            .required('Please enter a name')
            .max(200, 'Maximum 200 characters'),
        entityTypesID: Yup.number().nullable().required('Please select an option'),
        entityTypesOther: Yup.string().max(200, 'Maximum 200 characters').nullable(),
        registrationDate: Yup.string().nullable().required('Please enter a date'),
        registrationNumber: Yup.string()
            .nullable()
            .required('Please enter a number')
            .max(200, 'Maximum 200 characters'),
        taxNumber: Yup.string()
            .nullable()
            .max(100, 'Maximum 100 characters')
            .when('addressCountry', {
                is: 'USA',
                then: (schema) => schema.required('Please enter a number'),
            }),
        doingBusinessAs: Yup.string()
            .nullable()
            .required("Please list all your business's names")
            .max(500, 'Maximum 500 characters'),
        addressStreet: Yup.string()
            .nullable()
            .required("Please enter your business's address")
            .max(200, 'Maximum 200 characters'),
        addressNumber: Yup.string()
            .nullable()
            .required("Please enter your business's address")
            .max(200, 'Maximum 200 characters'),
        addressPostCode: Yup.string()
            .nullable()
            .required("Please enter your business's postal code")
            .max(20, 'Maximum 20 characters'),
        addressCity: Yup.string()
            .nullable()
            .required("Please enter your business's city")
            .max(200, 'Maximum 200 characters'),
        addressState: Yup.string()
            .nullable()
            .required("Please enter your business's state")
            .max(100, 'Maximum 100 characters'),
        addressCountry: Yup.string()
            .nullable()
            .required('Please select an option')
            .max(10, 'Maximum 10 characters'),
        operatingAddressStreet: Yup.string()
            .nullable()
            .optional()
            .max(200, 'Maximum 200 characters'),
        operatingAddressNumber: Yup.string()
            .nullable()
            .optional()
            .max(200, 'Maximum 200 characters'),
        operatingAddressPostCode: Yup.string()
            .nullable()
            .optional()
            .max(20, 'Maximum 20 characters'),
        operatingAddressCity: Yup.string().nullable().optional().max(200, 'Maximum 200 characters'),
        operatingAddressState: Yup.string()
            .nullable()
            .optional()
            .max(100, 'Maximum 100 characters'),
        operatingAddressCountry: Yup.string()
            .nullable()
            .optional()
            .max(10, 'Maximum 10 characters'),
    }),
});

const operationsInformationSchema = Yup.object({
    operationsInformation: Yup.object({
        webAddress: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        phoneNumber: Yup.string().nullable().max(100, 'Maximum 100 characters'),
        supportEmail: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        bPubliclyListed: Yup.string().nullable().required('Please select an option'),
        ticker: Yup.string()
            .when('bPubliclyListed', {
                is: (bPubliclyListed: string) => bPubliclyListed === 'Yes',
                then: Yup.string()
                    .nullable()
                    .required("Please provide your business's ticker")
                    .max(500, 'Maximum 500 characters'),
            })
            .nullable(),
        exchanges: Yup.string()
            .when('bPubliclyListed', {
                is: (bPubliclyListed: string) => bPubliclyListed === 'Yes',
                then: Yup.string()
                    .nullable()
                    .required('Please provide the exchange(s) your business is listed on')
                    .max(500, 'Maximum 500 characters'),
            })
            .nullable(),
        operationRegionIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        bLicenseRequired: Yup.string().nullable().required('Please select an option'),
        additionalLicensingInfo: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        primaryRegulator: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        licenseNumber: Yup.string().nullable().max(100, 'Maximum 100 characters'),
        businessActivityIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        web3ChainsAndAddresses: Yup.string().nullable().max(500, 'Maximum 500 characters'),
        sourceOfFundsIds: Yup.array(Yup.number())
            .nullable()
            .min(1, 'Please select one or more options')
            .required('Please select one or more options'),
        sourceOfFundsOther: Yup.string().nullable().max(200, 'Maximum 200 characters'),
        activeBanks: Yup.string()
            .nullable()
            .required('Please name your current banking provider')
            .max(500, 'Maximum 500 characters'),
        yearlyTransactionsId: Yup.number().nullable().required('Please select an option'),
        monthlyUsdValueId: Yup.number().nullable().required('Please select an option'),
    }),
});
const individualBeneficialOwnersSchema = Yup.object({
    firstName: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a first name'),
    lastName: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a last name'),
    title: Yup.string().max(100, 'Maximum 100 characters').required('Please provide a title'),
    dateOfBirth: Yup.string().required('Please provide a date of birth').nullable(),
    nationality: Yup.string().required('Please provide a nationality'),
    email: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide an email address')
        .test(
            'isRepeatedEmail',
            'Beneficial owners can not share the same email address',
            (value, context) => {
                const arrayParent = (context as any).from?.[1]?.value?.individualBeneficialOwners;
                const isEmailRepeated =
                    arrayParent.filter(
                        (beneficialOwner: BeneficialPersonalOwner) =>
                            beneficialOwner.email === value
                    ).length > 1;
                return !isEmailRepeated;
            }
        ),
    phoneNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a phone number'),
    country: Yup.string().required('Please provide a country'),
    ssn: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a tax ID number/SSN'),
    stateProvince: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a state/province'),
    city: Yup.string().max(200, 'Maximum 200 characters').required('Please provide a city'),
    addressLine1: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide a street address'),
    addressLine2: Yup.string().max(200, 'Maximum 200 characters'),
    postcode: Yup.string()
        .max(20, 'Maximum 20 characters')
        .required('Please provide a postal/zip code'),
    percentageSharesOwned: Yup.number()
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide a percentage of shares owned')
        .typeError('Please provide a percentage of shares owned')
        .nullable(),
    position: Yup.string()
        .max(100, 'Maximum 100 characters')
        .required('Please provide a position at the company'),
    bControllingParty: Yup.boolean()
        .required('Please state whether this party is a controlling party')
        .test(
            'hasControllingParty',
            'You must add at least one controlling party',
            (values, context) => {
                const arrayParent = (context as any).from?.[1]?.value?.individualBeneficialOwners;
                return (
                    arrayParent?.length === 0 ||
                    arrayParent?.some(
                        (individualOwner: BeneficialPersonalOwner) =>
                            individualOwner.bControllingParty
                    )
                );
            }
        )
        .nullable(),
    proofOfAddressFilename: Yup.string()
        .max(200, 'Maximum 200 characters')
        .required('Please provide a proof of address')
        .nullable(),
});
const businessBeneficialOwnerSchema = Yup.object({
    entityName: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required('Please enter a name'),
    entityTypesID: Yup.number().nullable().required('Please select an option'),
    entityTypesOther: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    registrationDate: Yup.string().nullable().required('Please enter a date'),
    registrationNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required('Please enter a number'),
    taxNumber: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required('Please enter a number'),
    doingBusinessAs: Yup.string()
        .max(500, 'Maximum 500 characters')
        .nullable()
        .required("Please list all your business's names"),
    addressStreet: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's address"),
    addressNumber: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's address"),
    addressPostCode: Yup.string()
        .max(20, 'Maximum 20 characters')
        .nullable()
        .required("Please enter your business's postal code"),
    addressCity: Yup.string()
        .max(200, 'Maximum 200 characters')
        .nullable()
        .required("Please enter your business's city"),
    addressState: Yup.string()
        .max(100, 'Maximum 100 characters')
        .nullable()
        .required("Please enter your business's state"),
    addressCountry: Yup.string()
        .max(10, 'Maximum 10 characters')
        .nullable()
        .required('Please select an option'),
    percentageSharesOwned: Yup.number()
        .max(100, 'Percentage must be less than 100')
        .min(0, 'Percentage must be more than 0')
        .required('Please provide a percentage of shares owned')
        .typeError('Please provide a percentage of shares owned')
        .nullable(),
    operatingAddressStreet: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressNumber: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressPostCode: Yup.string().max(20, 'Maximum 20 characters').nullable(),
    operatingAddressCity: Yup.string().max(200, 'Maximum 200 characters').nullable(),
    operatingAddressState: Yup.string().max(100, 'Maximum 100 characters').nullable(),
    operatingAddressCountry: Yup.string().max(10, 'Maximum 10 characters').nullable(),
});
const ownerInformationSchema = Yup.object({
    ownerInformation: Yup.object({
        exemptionId: Yup.number().nullable(),
        individualBeneficialOwners: Yup.array(individualBeneficialOwnersSchema).test({
            name: 'individualBeneficialOwnerArray',
            test: (value, context) =>
                (value?.length && value.length >= 1) ||
                context.parent.exemptionId ||
                context.parent.businessBeneficialOwners.length >= 1,
            message: 'You must have a beneficial owner listed if your business is not exempt',
        }),
        businessBeneficialOwners: Yup.array(businessBeneficialOwnerSchema).test({
            name: 'businessBeneficialOwnerArray',
            test: (value, context) =>
                (value?.length && value.length >= 1) ||
                context.parent.exemptionId ||
                context.parent.individualBeneficialOwners.length >= 1,
            message: 'You must have a beneficial owner listed if your business is not exempt',
        }),
        authorizedSigner: Yup.string()
            .nullable()
            .required('Please name an authorized signer')
            .max(200, 'Maximum 200 characters'),
    }),
    /* .test( // Removed - beneficial owners can add up to more than 100% in cases where businesses own other businesses
        'sharesOwnedMax',
        'Percentage of shares owned can not total more than 100',
        (values, context) => {
            const individualShares = (values.individualBeneficialOwners ?? []).reduce(
                (prev, cur) => prev + (cur?.percentageSharesOwned ?? 0),
                0
            );
            const businessShares = (values.businessBeneficialOwners ?? []).reduce(
                (prev, cur) => prev + (cur?.percentageSharesOwned ?? 0),
                0
            );
            const totalShares = individualShares + businessShares;

            return totalShares <= 100
                ? true
                : context.createError({
                      path: context.path,
                      message: `Percentage of shares owned can not total more than 100 (currently: ${totalShares})`,
                  });
        }
    ) */
});
const termsSchema = Yup.object({
    terms: Yup.object({
        bTaxAcknowledgement: Yup.boolean()
            .nullable()
            .required('Please agree to the tax acknowledgement')
            .oneOf([true], 'Please agree to the tax acknowledgement'),
        bFatcaAcknowledgement: Yup.boolean()
            .nullable()
            .required('You must be compliance with FACTA')
            .oneOf([true], 'You must be compliance with FACTA'),
        bConfirmTrueAndCorrect: Yup.boolean()
            .nullable()
            .required('Please confirm the information you have provided is true and correct')
            .oneOf([true], 'Please confirm the information you have provided is true and correct'),
        bAcceptTerms: Yup.boolean()
            .nullable()
            .required('Please accept our terms of service')
            .oneOf([true], 'Please accept our terms of service'),
    }),
});
const documentsSchema = Yup.object({});

export const verificationValidationSchema: ValidationSchemas = {
    [BusinessVerificationStage.RegistrationInformation]: registrationInformationSchema,
    [BusinessVerificationStage.OperationsInformation]: operationsInformationSchema,
    [BusinessVerificationStage.OwnerInformation]: ownerInformationSchema,
    [BusinessVerificationStage.Terms]: termsSchema,
    [BusinessVerificationStage.Documents]: documentsSchema,
};

export function validate(
    values: FormikValues,
    submitValues: FormValues | undefined,
    apiErrors: processedErrors,
    validationSchema: Yup.SchemaOf<any>,
    rejectedDocuments: DocumentRejection | null | undefined,
    stage: BusinessVerificationStage
) {
    const handleRejected = (
        rejectedDocuments: DocumentRejection | null | undefined,
        stage: BusinessVerificationStage
    ) => {
        let rejectedDocErrors: FormikErrors<any>[] = [];
        let path;
        if (rejectedDocuments) {
            //TODO Handle rejected documents
        }
        return rejectedDocErrors;
    };

    return new Promise(async function (resolve, reject) {
        const errorObject = await validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                let rejectedDocErrors = handleRejected(rejectedDocuments, stage);
                if (submitValues) {
                    let errors: FormikErrors<any> = {};
                    const diff = findDifferences(submitValues, values);
                    if (apiErrors.length > 0) {
                        apiErrors.forEach((error) => {
                            if (
                                error.fieldName.endsWith('Other') &&
                                diff.some((diffField) =>
                                    diffField.startsWith(error.fieldName.slice(0, -5))
                                )
                            )
                                return; // skip fieldNameOther fields if fieldName... has changed
                            if (diff && (!diff.includes(error.fieldName) || diff.length === 0)) {
                                errors = { ...errors, [error.fieldName]: error.message };
                            }
                        });
                    }
                    if (rejectedDocErrors.length > 0) {
                        rejectedDocErrors.forEach((error) => {
                            errors = { ...errors, ...error };
                        });
                    }
                    if (Object.keys(errors) && Object.keys(errors).length > 0) {
                        return transformDotNotationObject(errors);
                    }
                }
            })
            .catch((e) => {
                if (submitValues) {
                    let rejectedDocErrors = handleRejected(rejectedDocuments, stage);
                    if (e instanceof Yup.ValidationError) {
                        let errors: FormikErrors<any> = e.inner.reduce(
                            (acc: FormikErrors<any>, currentError) => {
                                const errorPath = currentError.path as string;
                                if (errorPath && !acc[errorPath]) {
                                    acc[errorPath] = currentError.message;
                                }
                                return acc;
                            },
                            {}
                        );
                        const diff = findDifferences(submitValues, values);
                        if (apiErrors.length > 0) {
                            apiErrors.forEach((error) => {
                                if (
                                    diff &&
                                    (!diff.includes(error.fieldName) || diff.length === 0)
                                ) {
                                    errors = { ...errors, [error.fieldName]: error.message };
                                }
                            });
                        }
                        if (rejectedDocErrors.length > 0) {
                            rejectedDocErrors.forEach((error) => {
                                errors = { ...errors, ...error };
                            });
                        }
                        if (Object.keys(errors) && Object.keys(errors).length > 0) {
                            return transformDotNotationObject(errors);
                        }
                    }
                }
                if (e instanceof Yup.ValidationError)
                    return transformDotNotationObject(
                        e.inner.reduce((acc: FormikErrors<any>, currentError) => {
                            const errorPath = currentError.path as string;
                            if (errorPath && !acc[errorPath]) {
                                acc[errorPath] = currentError.message;
                            }
                            return acc;
                        }, {})
                    );
            });
        resolve(errorObject);
    });
}
