import { useTable } from 'api';
import SearchIcon from 'assets/ibanera/search-icon.svg';
import { TableProps } from 'components/flexTable/FlexTable';
import { TradeTableLiveData, TradeTableRow, ExchangeTradeTable } from './ExchangeTradeTable';
import { endpoints } from 'endpoints.config';
import React, { useContext, useMemo, useState } from 'react';
import Select, { ValueType } from 'react-select';
import { ExchangeCryptoContext } from './ExchangePage';
import {
    CryptoLiveData,
    CurrencyOption,
    DEFAULT_SELECT_OPTION,
    FiatCurrency,
} from 'pages/crypto/CryptoPage';
import { useExchangeContextEffects } from './useExchangeContextEffects';
import { PortfolioTableRow } from './ExchangePortfolioTable';
import { CurrencySelectDropdown } from 'components/currencySelectDropdown/CurrencySelectDropdown';

const parseCryptosToMarketTable = (cryptos: CryptoLiveData[]): TradeTableLiveData => {
    return cryptos.reduce((acc, crypto) => {
        const tradeTableRow = {
            price: crypto.buyPrice,
            percentChange: crypto.priceVariability,
            high: crypto.high24hrs,
            low: crypto.low24hrs,
        };
        return { ...acc, [crypto.ticker]: tradeTableRow };
    }, {});
};

const SEARCH_DEBOUNCE_TIME = 500;

const Cryptos: React.FC = () => {
    const {
        cryptos,
        searchString,
        setSearchString,
        fiatCurrencies,
        selectedFiatCurrency,
        setSelectedFiatCurrency,
    } = useContext(ExchangeCryptoContext);

    const [searchTimeout, setSearchTimeout] = useState<null | number>(null);

    const cryptosTable = useTable<TradeTableRow, any>({
        url: endpoints.exchangemodule.marketList,
        pageSize: 100,
        bClearSearch: true,
        bClearSortBy: true,
        multiSort: false,
    });

    useExchangeContextEffects(cryptosTable);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        setSearchString(search);
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        const timeout = window.setTimeout(() => {
            cryptosTable.data?.actions.changeSearch(search);
            setSearchTimeout(null);
        }, SEARCH_DEBOUNCE_TIME);
        setSearchTimeout(timeout);
    };

    const handleCurrency = (selectedItem: ValueType<CurrencyOption, false>) => {
        if (selectedItem) {
            const fiatCurrency = fiatCurrencies.find(
                (currency) => currency.code === selectedItem.value
            ) as FiatCurrency;
            setSelectedFiatCurrency(fiatCurrency);
        }
    };

    const fiatList = useMemo(
        () =>
            fiatCurrencies.map<CurrencyOption>((currency) => ({
                value: currency.code,
                label: currency.code,
            })),
        [fiatCurrencies]
    );

    const selectedCurrencyOption = useMemo<CurrencyOption | null>(
        () =>
            selectedFiatCurrency
                ? { value: selectedFiatCurrency.code, label: selectedFiatCurrency.code }
                : null,
        [selectedFiatCurrency]
    );

    return (
        <div className="Cryptos Exchange ExchangeTrade">
            <div className="Market">
                <div className="MarketSubHeader">
                    <div className="Left">
                        <p>Select a currency to buy, sell or deposit.</p>
                        {fiatList.length > 0 && (
                            <CurrencySelectDropdown
                                isSearchable={false}
                                options={fiatList}
                                value={selectedCurrencyOption}
                                defaultValue={DEFAULT_SELECT_OPTION}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onChange={handleCurrency}
                            />
                        )}
                    </div>
                    <div className="MarketTableSearch">
                        <div className="MarketTableInput">
                            <div className="FormBox FormSideBySide">
                                <div className="FormLabel">
                                    <label></label>
                                </div>
                                <div className="FormField">
                                    <input
                                        name="search"
                                        placeholder="Search all currencies"
                                        className="EditBox"
                                        value={searchString}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <img src={SearchIcon} alt="icon" />
                            </div>
                        </div>
                    </div>
                </div>
                {selectedFiatCurrency && (
                    <ExchangeTradeTable
                        idColumn={'assets__Id'}
                        table={cryptosTable as TableProps['table']}
                        liveData={parseCryptosToMarketTable(cryptos)}
                        selectedCurrency={selectedFiatCurrency}
                        isLoading={cryptosTable.loading}
                        hideFiltering
                    />
                )}
            </div>
        </div>
    );
};

export { Cryptos };
