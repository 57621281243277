import { FormikHelpers } from 'formik';
import { Dispatch, createContext, useContext } from 'react';
import {
    BusinessStepsOrder,
    BusinessVerificationStage,
    getNextStage,
} from '../BusinessVerificationV2Models';
import { FormValues } from '../schema';
import { endpoints } from 'endpoints.config';
import instance from 'api';
import { setVerificationStage } from 'reducers/verification';
import { Toast, ToastMessageReason } from 'helpers/toast';
import {
    Errors,
    processedErrors,
} from 'components/businessVerification/helpers/ExitContinueButtons';
import { DocumentFieldValue } from 'components/businessVerification/components/BusinessVerificationDocuments';
import { format, parse, parseISO } from 'date-fns';
import logger from 'helpers/logger';

/* 
Formats the values we submit from the form.
Returns a new object without mutating the existing object
Notably we need to remove any documents in the documentsUpload section that have not been uploaded(filename==null)
*/
export const formatValues = (_values: FormValues) => {
    const { registrationInformation } = _values;
    const values = {
        ..._values,
        registrationInformation: {
            ...registrationInformation,
            registrationDate:
                registrationInformation.registrationDate &&
                typeof registrationInformation.registrationDate !== 'string'
                    ? format(
                          registrationInformation.registrationDate as unknown as Date,
                          'yyyy-MM-dd'
                      )
                    : registrationInformation.registrationDate,
        },
    };
    if (Array.isArray(values.documentsUpload) && values.documentsUpload.length > 1)
        values.documentsUpload = values.documentsUpload.filter((document) => document.fileName);
    return values;
};

export const submitForm = async (
    values: any,
    helpers: any,
    dispatch: Dispatch<any>,
    setSubmitErrorField: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>
) => {
    try {
        const response = await instance.post(endpoints.businessVerificationV2Module.submitForm, {
            ...formatValues(values as any),
            businessVerificationStep: BusinessVerificationStage.Complete,
        });
        if (response.status === 200 && response.data && response.data.status === `1`) {
            if (
                response.data.details.listInnerErrors &&
                Array.isArray(response.data.details.listInnerErrors) &&
                response.data.details.listInnerErrors.length === 0
            ) {
                dispatch(
                    setVerificationStage({ currentStage: BusinessVerificationStage.Complete })
                );
            } else if (
                response.data.details.listInnerErrors &&
                Array.isArray(response.data.details.listInnerErrors) &&
                response.data.details.listInnerErrors.length > 0
            ) {
                //if errors check which stage and set step to the earliest in the process
                let errorsOnDifferentStage = response.data.details.listInnerErrors
                    .map((error: Errors) => getStageFromFieldName(error.fieldName))
                    .filter(
                        (stage: BusinessVerificationStage) =>
                            stage !== BusinessVerificationStage.Documents
                    );
                if (Array.isArray(errorsOnDifferentStage) && errorsOnDifferentStage.length > 0) {
                    for (const stage of BusinessStepsOrder) {
                        if (errorsOnDifferentStage.includes(stage)) {
                            setResponseErrors(
                                response.data.details.listInnerErrors,
                                helpers,
                                stage,
                                setSubmitErrorField,
                                setApiErrors,
                                values
                            );
                            dispatch(
                                setVerificationStage({
                                    currentStage: stage,
                                })
                            );
                            break;
                        }
                    }
                } else {
                    setResponseErrors(
                        response.data.details.listInnerErrors,
                        helpers,
                        BusinessVerificationStage.Documents,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                }
            }
        }
    } catch (error: any) {
        Toast.openToastMessage(
            'Error submitting form, please try again later.',
            ToastMessageReason.ERROR
        );
    }
};

const getStageFromFieldName = (fieldName: string): BusinessVerificationStage | null => {
    const stageName = fieldName.split('_')[0];
    switch (stageName) {
        case 'RegistrationInformation':
            return BusinessVerificationStage.RegistrationInformation;
        case 'OperationsInformation':
            return BusinessVerificationStage.OperationsInformation;
        case 'OwnerInformation':
            return BusinessVerificationStage.OwnerInformation;
        case 'Terms':
            return BusinessVerificationStage.Terms;
        case 'DocumentsUpload':
            return BusinessVerificationStage.Documents;
        default:
            return null;
    }
};

export async function saveContinue<T>(
    values: T,
    helpers: FormikHelpers<T>,
    dispatch: Dispatch<any>,
    stage: BusinessVerificationStage | null,
    setSubmitError: React.SetStateAction<any>,
    setSubmitErrorField: React.SetStateAction<any>,
    setSubmitValues: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>
) {
    try {
        if (stage) {
            const response = await instance.post(
                endpoints.businessVerificationV2Module.saveFormData,
                {
                    ...formatValues(values as any as FormValues),
                    businessVerificationStep: getNextStage(stage),
                }
            );
            if (response.status === 200 && response.data && response.data.status === `1`) {
                if (
                    response.data.details.listInnerErrors &&
                    Array.isArray(response.data.details.listInnerErrors) &&
                    response.data.details.listInnerErrors.length === 0
                ) {
                    //if no errors proceed
                    helpers.setFieldValue('businessVerificationStep', getNextStage(stage));
                    dispatch(
                        setVerificationStage({
                            currentStage: getNextStage(stage),
                        })
                    );
                    helpers.setTouched({});
                    setSubmitError(false);
                    setApiErrors([]);
                } else if (
                    response.data.details.listInnerErrors &&
                    Array.isArray(response.data.details.listInnerErrors) &&
                    response.data.details.listInnerErrors.length > 0
                ) {
                    setSubmitError(true);
                    helpers.setFieldValue('businessVerificationStep', stage);
                    setSubmitValues({ ...values });
                    setResponseErrors(
                        response.data.details.listInnerErrors,
                        helpers,
                        stage,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                    document
                        .querySelector('.BusinessVerificationModalBg')
                        ?.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                const { errors } = response.data;
                if (errors.length > 0) {
                    setSubmitValues({ ...values });
                    setResponseErrors(
                        errors,
                        helpers,
                        stage,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                }
            }
        }
    } catch (error: any) {
        logger.log(error);
        Toast.openToastMessage('Error saving form, please try again.', ToastMessageReason.ERROR);
    }
    // }
}

export const parseFieldName = (name: string, stage: BusinessVerificationStage) => {
    const words = name.split('_').map((word) => word.charAt(0).toLowerCase() + word.slice(1));
    if (getStageFromFieldName(name) === stage) {
        if (words.length === 1) {
            return words[0];
        } else if (words.length === 2) {
            return `${words[0]}.${words[1]}`;
        } else if (words.length === 3) {
            let index = +words[2] - 1;
            return `${words[0]}.${index}.${words[1]}`;
        } else {
            let index = +words[3] - 1;
            return `${words[0]}.${words[1]}.${index}.${words[2]}`;
        }
    }
};

const parseDocumentUploadResponse = (error: Errors, values: any) => {
    const words = error.fieldName
        .split('_')
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1));
    let errorMessage = '';
    if (words.length === 2) {
        if (words[1] === 'documentTypesID') {
            errorMessage =
                error.messageCode === 'Missing'
                    ? 'Document type missing please try again'
                    : 'Only one file per document type is allowed';
        } else {
            errorMessage = 'File names must be distinct';
        }
        return [words[0], errorMessage];
    }
    if (words.length === 3) {
        let index = values.documentsUpload
            .map((upload: DocumentFieldValue) => upload.documentTypesID)
            .indexOf(+words[2]);
        if (index >= 0) {
            errorMessage = 'Cannot find file please reupload';
            return [`${words[0]}.${index}`, errorMessage];
        }
    }

    return [null, null];
};

export const setResponseErrors = (
    errors: Errors[],
    helpers: any,
    stage: BusinessVerificationStage,
    setSubmitErrorField: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>,
    values?: any
) => {
    const apiErrors: processedErrors = [];
    errors.forEach((error: any, index: number) => {
        if (stage === BusinessVerificationStage.Documents && values) {
            let [fieldName, errorMessage] = parseDocumentUploadResponse(error, values);
            if (fieldName && errorMessage) {
                if (index === 0) setSubmitErrorField(fieldName);
                apiErrors.push({ fieldName: fieldName, message: errorMessage });
                helpers.setFieldError(`${fieldName}`, errorMessage);
            }
        } else {
            let fieldName = parseFieldName(error.fieldName, stage);
            if (fieldName) {
                if (index === 0) setSubmitErrorField(fieldName); //set first error field to scroll to
                if (error.messageCode === 'Invalid') {
                    apiErrors.push({
                        fieldName: fieldName,
                        message: 'Please provide valid information',
                    });
                    helpers.setFieldError(`${fieldName}`, 'Please provide valid information');
                } else {
                    apiErrors.push({ fieldName: fieldName, message: '*Required' });
                    helpers.setFieldError(`${fieldName}`, '*Required');
                }
            }
        }
    });
    if (apiErrors.length > 0) {
        const firstElement = document.querySelectorAll(
            `input[name="${apiErrors[0].fieldName}"]`
        )?.[0] as any; //typescript doesn't recognise the scrollintoviewifneeded method
        firstElement?.scrollIntoViewIfNeeded && firstElement.scrollIntoViewIfNeeded();
        setApiErrors([...apiErrors]);
    }
};
