import { useTable } from 'api';
import { TableProps } from 'components/flexTable/FlexTable';
import React, { useContext, useMemo, useState } from 'react';
import Select, { ValueType } from 'react-select/';
import { ExchangeCryptoContext } from './ExchangePage';
import {
    CryptoLiveData,
    CurrencyOption,
    DEFAULT_SELECT_OPTION,
    FiatCurrency,
} from 'pages/crypto/CryptoPage';
import SearchIcon from 'assets/ibanera/search-icon.svg';
import { useExchangeContextEffects } from './useExchangeContextEffects';
import Pagination from 'components/pagination/Pagination';
import { endpoints } from 'endpoints.config';
import {
    ExchangePortfolioTable,
    PortfolioTableLiveData,
    PortfolioTableRow,
} from './ExchangePortfolioTable';
import { CurrencySelectDropdown } from 'components/currencySelectDropdown/CurrencySelectDropdown';

const SEARCH_DEBOUNCE_TIME = 500;

export const Portfolio = () => {
    const {
        fiatCurrencies,
        selectedFiatCurrency,
        setSelectedFiatCurrency,
        searchString,
        setSearchString,
    } = useContext(ExchangeCryptoContext);

    const [searchTimeout, setSearchTimeout] = useState<null | number>(null);

    const portfolioTable = useTable<PortfolioTableRow, any>({
        url: endpoints.exchangemodule.portfolioList,
        bClearFilters: true,
        bClearSearch: true,
        multiSort: false,
    });

    useExchangeContextEffects(portfolioTable);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.currentTarget.value;
        setSearchString(search);
        if (searchTimeout) {
            window.clearTimeout(searchTimeout);
        }
        const timeout = window.setTimeout(() => {
            portfolioTable.data?.actions.changeSearch(search);
            setSearchTimeout(null);
        }, SEARCH_DEBOUNCE_TIME);
        setSearchTimeout(timeout);
    };

    const fiatList = useMemo(
        () =>
            fiatCurrencies.map<CurrencyOption>((currency) => ({
                value: currency.code,
                label: currency.code,
            })),
        [fiatCurrencies]
    );

    const selectedCurrencyOption = useMemo<CurrencyOption | null>(
        () =>
            selectedFiatCurrency
                ? { value: selectedFiatCurrency.code, label: selectedFiatCurrency.code }
                : null,
        [selectedFiatCurrency]
    );

    const handleCurrency = (selectedItem: ValueType<CurrencyOption, false>) => {
        if (selectedItem) {
            const fiatCurrency = fiatCurrencies.find(
                (currency) => currency.code === selectedItem.value
            ) as FiatCurrency;
            setSelectedFiatCurrency(fiatCurrency);
        }
    };

    return (
        <div className="Cryptos">
            <div className="Market">
                <div className="MarketSubHeader">
                    <div className="Left">
                        <p>Your account balances.</p>
                        {fiatList.length > 0 && (
                            <CurrencySelectDropdown
                                isSearchable={false}
                                options={fiatList}
                                value={selectedCurrencyOption}
                                defaultValue={DEFAULT_SELECT_OPTION}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onChange={handleCurrency}
                            />
                        )}
                    </div>
                    <div className="MarketTableSearch">
                        <div className="MarketTableInput">
                            <div className="FormBox FormSideBySide">
                                <div className="FormLabel">
                                    <label></label>
                                </div>
                                <div className="FormField">
                                    <input
                                        name="search"
                                        placeholder="Search all currencies"
                                        className="EditBox"
                                        value={searchString}
                                        onChange={handleSearch}
                                    />
                                    <img src={SearchIcon} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedFiatCurrency && (
                    <>
                        <ExchangePortfolioTable
                            idColumn={'assets__Id'}
                            table={portfolioTable as TableProps['table']}
                            selectedCurrency={selectedFiatCurrency}
                            isLoading={portfolioTable.loading}
                            hideFiltering
                            excludeBuySellButton
                        />
                        <Pagination table={portfolioTable} />
                    </>
                )}
            </div>
        </div>
    );
};
