import React, { lazy, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

// Icons
import View from '../../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../../assets/Cat-Entity/Icon_Action_02.png';
import Delete from '../../../assets/Cat-Entity/Icon_Action_03.png';

import { CrudItems } from 'components/Category/CrudMenu';
import { format } from 'date-fns';
import { endpoints } from 'endpoints.config';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';
import Category from 'components/Category/Category';
import Entity from 'components/categoryComponents/Entity';
import { CardAndUserData } from './ManageCardUsers';

const ViewListUserCard = lazy(() => import('./ViewListUserCard'));

// Define the url for the table's data endpoint.
const url =
    endpoints.cardManagementModule.viewlistcards; /* REPLACE__ME - api url for this component */

// Define which column on the table represents each row's ID.
// This should be unique to each row.
const idColumn = 'UserCards__Id'; /* REPLACE__ME - id column for this component */

//Define the data structure for table
/* REPLACE__ME - table data structure */
export type UserCardsData = {
    UserCards__Id: number;
    UserCards__Code: string;
    UserCards__CardType: string;
    UserCards__CardNumber: string;
    UserCards__ExpiryDateUTC: string;
    UserCards__Balance: number;
    UserCards__AddDateUTC: string;
    UserCards__EditDateUTC: string;
};

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: UserCardsData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.UserCards__ExpiryDateUTC) {
        formattedRow = {
            ...formattedRow,
            UserCards__ExpiryDateUTC: localizedFormatDate(new Date(row.UserCards__ExpiryDateUTC)),
        };
    }
    if (formattedRow.UserCards__AddDateUTC) {
        formattedRow = {
            ...formattedRow,
            UserCards__AddDateUTC: localizedFormatDate(new Date(row.UserCards__AddDateUTC)),
        };
    }
    if (formattedRow.UserCards__EditDateUTC) {
        formattedRow = {
            ...formattedRow,
            UserCards__EditDateUTC: localizedFormatDate(new Date(row.UserCards__EditDateUTC)),
        };
    }
    return formattedRow;
};

export interface UserCardsProps {
    setSubCatShown?: React.Dispatch<React.SetStateAction<boolean>>;

    idFromParentRowOption?: React.ReactText | null;
    selectedParentData?: CardAndUserData[];
}

type Props = UserCardsProps & RouteComponentProps;

function ViewListCards(props: Props) {
    const {
        // setIdFromParentRowOption,
        // setSelectedParentData,
        setSubCatShown,
        idFromParentRowOption,
        selectedParentData,
    } = props;

    const [parentSelectedData, setParentSelectedData] = useState<UserCardsData[]>([]);
    const [parentIdFromRowOption, setParentIdFromRowOption] = useState<React.ReactText | null>(
        null
    );

    const crudItems: CrudItems<UserCardsData> = useMemo(
        () => [
            {
                icon: View,
                title: 'View Card' /* REPLACE__ME */,
                imgAltText: 'View',
                //permissionsKey: 'view',
                menuCode: 'VIEW',
                showInMultiSelect: false,
                content: (onDone, selectedData, menu) => (
                    <ViewListUserCard
                        onDone={onDone}
                        selectedParentData={selectedParentData}
                        idFromParentRowOption={idFromParentRowOption}
                        selectedData={selectedData}
                        idFromRowOption={menu.selectedId}
                        actionType="view"
                        submitButtonText="Close"
                        noSubmit={true}
                    />
                ),
                inRightPanel: true,
                isDisabled: false,
            },

            // {
            //     icon: Delete,
            //     imgAltText: 'Delete',
            //     //permissionsKey: 'delete',
            //     title: (data) =>
            //         data.length <= 1 ? 'Delete Card' : `Delete ${data.length} Cards ` /* REPLACE__ME */,
            //     menuCode: 'DELETE',
            //     showInMultiSelect: true,
            //     content: (onActionComplete, selectedData, menu) => (
            //         <DeleteModal
            //             url={endpoints.cardManagementModule.deleteusers /* REPLACE__ME */}
            //             noun="Cards"
            //             selectedIds={selectedData.map((d) => d.Cards__Id /** */)} /*
            //         REPLACE__ME - return id */
            //             renderListItem={(data) =>
            //                 /** return the name to be displayed */
            //                 data.Cards__Code + ' ' + data.Cards__Type
            //             }
            //             selectedData={selectedData}
            //             open={menu.currentMenu === 'DELETE'}
            //             close={onActionComplete}
            //             idFromRowOption={menu.selectedId}
            //         />
            //     ),
            //     inRightPanel: false,
            //     isDisabled: false,
            // },
        ],
        []
    );

    return (
        <Category<UserCardsData>
            //location={props.location}
            location={'/cards'}
            contentTitle="Cards"
            noun="Cards" /** REPLACE__ME */
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={true}
            fetchConfig={{
                params: {
                    ParentId: idFromParentRowOption ?? selectedParentData![0].customers__Id,
                },
                withCredentials: false,
            }}
            classname="listsCatTable"
            setSubCatShown={setSubCatShown}
            setSelectedParentData={setParentSelectedData}
            setIdFromParentRowOption={setParentIdFromRowOption}
            isParent={false}
            emptyTableLabel="No Cards Found"
        />
    );
}

export default ViewListCards;
