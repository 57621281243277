import Button from 'components/button/Button';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { CurrencyIcon, TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import FormTextField from 'components/form/FormTextField';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { formatCurrency } from 'helpers/fiatFormatter';
import {
    CurrencyExchangeItem,
    ExchangeDetails,
    GetExchangeDetailsResponse,
    QuantityType,
} from 'pages/currencyExchange/models';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';
import { selectCryptoPrice } from 'reducers/cryptoPrices';
import logger from 'helpers/logger';
import { TFAType } from 'pages/register/models';
import { useTFAField } from 'helpers/useTFAField';
import { TFAField } from 'components/form/TFAField';
import { getErrorMessage } from 'errors';
import { Toast } from 'helpers/toast';
import { AccountDropdown } from './AccountDropdown';

import { ReactComponent as ExchangeSvg } from 'assets/icon-exchange.svg';

type Props = {};

export type CurrencyExchangeModalData = CurrencyExchangeItem & {
    amount: number;
    exchangeType: 'Buy' | 'Sell';
};

type GenericValues = {
    note: string;
    exchangeType: 'Buy' | 'Sell';
    tfaType: TFAType;
    tfaCode: string;
    sourceAccountId: string | null;
    destinationAccountId: string | null;
} & CurrencyExchangeItem;

type FormValues = DestinationValues | SourceValues;

type DestinationValues = {
    quantityType: 'Destination';
    sourceAmount: null;
    destinationAmount: number;
    estimatedSourceAmount: number;
    estimatedDestinationAmount: null;
} & GenericValues;

type SourceValues = {
    quantityType: 'Source';
    sourceAmount: number;
    destinationAmount: null;
    estimatedSourceAmount: null;
    estimatedDestinationAmount: number;
} & GenericValues;

type OrderDetails = {
    sourceCurrency: string;
    destinationCurrency: string;
} & FormValues;

type ReviewedDetails = {
    commisionFees: number;
    destinationAmount: number | null;
    quotedFXRate: string;
    quotedFXRateID: number;
    quotedFxRateExpiry: string;
    sourceAmountLessFees: number;
    totalFees: number;
    note?: string;
} & (SourceQuote | DestinationQuote);

type SourceQuote = {
    quoteDestinationAmount: null;
    quoteSourceAmount: number;
    sourceAmount: null;
    destinationAmount: number;
};
type DestinationQuote = {
    quoteDestinationAmount: number;
    quoteSourceAmount: null;
    sourceAmount: number;
    destinationAmount: null;
};

const validationSchemaStep1 = Yup.object({});
const validationSchemaStep2 = Yup.object({});

export const CurrencyExchangeModal = (props: Props) => {
    const modalState = useSelector(selectModalState);

    const [exchangeDetails, setExchangeDetails] = useState<ExchangeDetails | null>(null);
    const [orderDetails, setOrderDetails] = useState<ReviewedDetails | null>(null);

    const [tfaType, toggleTfaType] = useTFAField(!!orderDetails);
    const dispatch = useDispatch();

    const [executeErrorMessage, setExecuteErrorMessage] = useState('');
    const [reviewErrorMessage, setReviewErrorMessage] = useState('');

    useEffect(() => {
        if (modalState.modalType !== ModalTypes.CURRENCY_EXCHANGE) return;
        instance
            .get<ApiResponse<GetExchangeDetailsResponse>>(endpoints.fxmodule.getExchangeDetails, {
                params: {
                    baseAssetId: modalState.data.baseAssetId,
                    exchangeAssetId: modalState.data.exchangeAssetId,
                    exchangeType: modalState.data.exchangeType,
                },
            })
            .then((res) =>
                setExchangeDetails({
                    baseCurrency: modalState.data.baseAsset,
                    exchangeCurrency: modalState.data.exchangeAsset,
                    exchangeType: modalState.data.exchangeType,
                    baseCurrencyAccounts:
                        modalState.data.exchangeType === 'Buy'
                            ? res.data.details.toAccounts
                            : res.data.details.fromAccounts,
                    exchangeCurrencyAccounts:
                        modalState.data.exchangeType === 'Buy'
                            ? res.data.details.fromAccounts
                            : res.data.details.toAccounts,
                })
            );
    }, [modalState]);

    if (modalState.modalType !== ModalTypes.CURRENCY_EXCHANGE) return null;
    const { data } = modalState;

    const reviewOrder = (
        values: FormValues,
        setFieldError: FormikHelpers<FormValues>['setFieldError']
    ) => {
        const payload = {
            ...values,
            destinationCurrency:
                values.exchangeType === 'Buy' ? values.baseAsset : values.exchangeAsset,
            sourceCurrency: values.exchangeType === 'Buy' ? values.exchangeAsset : values.baseAsset,
        };
        return instance
            .post<ApiResponse<ReviewedDetails>>(endpoints.fxmodule.reviewOrder, payload)
            .then((res) => {
                setOrderDetails({ ...res.data.details, note: values.note });
            })
            .catch((err) => {
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors?.some(
                        (err) => err.messageCode === 'Insufficient_Funds'
                    )
                ) {
                    setFieldError('sourceAmount', getErrorMessage('Insufficient_Funds'));
                    setFieldError('estimatedSourceAmount', getErrorMessage('Insufficient_Funds'));
                } else if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors?.some(
                        (err) =>
                            err.fieldName === 'TransactionFailed' &&
                            err.messageCode.match(
                                /Deal amount .* is less than transaction minimum size \d*/
                            )
                    )
                ) {
                    setFieldError('sourceAmount', getErrorMessage('Amount_Too_Low'));
                    setFieldError('estimatedSourceAmount', getErrorMessage('Amount_Too_Low'));
                } else setReviewErrorMessage(getErrorMessage('GENERIC'));
            });
    };
    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!orderDetails) {
            //call api to review order and set order details
            reviewOrder(values, helpers.setFieldError).finally(() => helpers.setSubmitting(false));
            return;
        }
        //post to create transaction
        instance
            .post<ApiResponse<any>>(endpoints.fxmodule.executeExchange, {
                tfaCode: values.tfaCode,
                tfaType: values.tfaType,
                fxRateId: orderDetails.quotedFXRateID,
                sourceAccountId: values.sourceAccountId,
                destinationAccountId: values.destinationAccountId,
            })
            .then((res) => {
                if (res.data.status === '1') {
                    Toast.openSuccessToast('Exchange created');
                    dispatch(closeModal());
                } else throw Error('Status not marked as success');
            })
            .catch((err) => {
                helpers.setSubmitting(false);
                setExecuteErrorMessage(getErrorMessage(''));
            })
            .finally();
    };
    const title = orderDetails
        ? 'Accept Price'
        : `Book ${data.exchangeAsset} / ${data.baseAsset} Contract`;

    return (
        <Formik
            initialValues={generateInitialValues(data, tfaType)}
            onSubmit={handleSubmit}
            validationSchema={orderDetails ? validationSchemaStep2 : validationSchemaStep1}
        >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Modal className="CurrencyExchangeModal" title={title} closeOnClickOutside={false}>
                    {orderDetails ? (
                        <Form>
                            <OrderSummary
                                orderDetails={orderDetails}
                                exchangeDetails={exchangeDetails!}
                                reloadOrder={reviewOrder}
                            />
                            <TFAField
                                field={'tfaCode'}
                                label={'TFA Code'}
                                required={true}
                                toggleTFAType={
                                    toggleTfaType
                                        ? () => {
                                              toggleTfaType();
                                              setFieldValue(
                                                  'tfaType',
                                                  values.tfaType === 'SMS'
                                                      ? 'AuthenticatorApp'
                                                      : 'SMS'
                                              );
                                          }
                                        : null
                                }
                                tfaType={tfaType}
                                holdFocus
                                autoFocus
                            />

                            {executeErrorMessage && (
                                <div className="ErrorText">{executeErrorMessage}</div>
                            )}
                            <div className="OrderButtons">
                                <Button
                                    type="button"
                                    priority="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setOrderDetails(null);
                                        setExecuteErrorMessage('');
                                    }}
                                >
                                    Reject
                                </Button>
                                <Button type="submit" priority="primary" disabled={isSubmitting}>
                                    Accept
                                </Button>
                            </div>
                        </Form>
                    ) : exchangeDetails ? (
                        <Form>
                            <ExchangeDetail exchangeDetails={exchangeDetails} />
                            <FromTo exchangeDetails={exchangeDetails} />
                            <FormTextField
                                type="textarea"
                                label={'My comment'}
                                labelExtraInfo="Optional"
                                field={'note'}
                                hint="Maximum length is 1000 characters"
                                required={false}
                                className="CommentField"
                            />
                            {reviewErrorMessage && (
                                <div className="ErrorText">{reviewErrorMessage}</div>
                            )}
                            <Button disabled={isSubmitting} variety="full" type="submit">
                                Book Now
                            </Button>
                        </Form>
                    ) : (
                        <Spinner />
                    )}
                </Modal>
            )}
        </Formik>
    );
};

const generateInitialValues = (data: CurrencyExchangeModalData, tfaType: TFAType): FormValues => {
    const { amount, ...rest } = data;
    if (data.exchangeType === 'Buy') {
        const values: SourceValues = {
            ...rest,
            note: '',
            quantityType: 'Source',
            sourceAmount: amount,
            estimatedDestinationAmount: amount / data.sellPrice,
            estimatedSourceAmount: null,
            destinationAmount: null,
            tfaType,
            tfaCode: '',
            sourceAccountId: null,
            destinationAccountId: null,
        };
        return values;
    }
    const values: DestinationValues = {
        ...rest,
        exchangeType: data.exchangeType,
        note: '',
        quantityType: 'Destination',
        sourceAmount: null,
        estimatedDestinationAmount: null,
        estimatedSourceAmount: amount * data.buyPrice,
        destinationAmount: amount,
        tfaType,
        tfaCode: '',
        sourceAccountId: null,
        destinationAccountId: null,
    };
    return values;
};

const ExchangeDetail = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const sourceCurrency = values.exchangeType === 'Buy' ? values.exchangeAsset : values.baseAsset;
    const destinationCurrency =
        values.exchangeType === 'Buy' ? values.baseAsset : values.exchangeAsset;
    const exactPrice =
        (values.exchangeType === 'Buy' && values.quantityType === 'Source') ||
        (values.exchangeType === 'Sell' && values.quantityType === 'Destination')
            ? 'Top'
            : 'Bottom';

    const currentPrice = useSelector(
        selectCryptoPrice(`${exchangeDetails?.baseCurrency}/${exchangeDetails?.exchangeCurrency}`)
    );

    const switchQuantityType = () => {
        if (values.quantityType === 'Destination') {
            setFieldValue('quantityType', 'Source');
            setFieldValue('sourceAmount', values.estimatedSourceAmount);
            setFieldValue('estimatedSourceAmount', null);
            setFieldValue('destinationAmount', null);
            setFieldValue('estimatedDestinationAmount', values.destinationAmount);
        } else {
            setFieldValue('quantityType', 'Destination');
            setFieldValue('sourceAmount', null);
            setFieldValue('estimatedSourceAmount', values.sourceAmount);
            setFieldValue('destinationAmount', values.estimatedDestinationAmount);
            setFieldValue('estimatedDestinationAmount', null);
        }
    };
    useEffect(() => {
        currentPrice?.buyPrice && setFieldValue('buyPrice', currentPrice.buyPrice);
        currentPrice?.sellPrice && setFieldValue('sellPrice', currentPrice.sellPrice);
    }, [currentPrice, setFieldValue]);
    useEffect(() => {
        if (values.sourceAmount === null || !currentPrice) return;
        setFieldValue(
            'estimatedDestinationAmount',
            roundToPrecision(
                values.exchangeType === 'Buy'
                    ? //buy means sourceAmount is baseAsset, destinationAmount is exchangeasset
                      values.sourceAmount / currentPrice.sellPrice
                    : //sell means sourceAmount is exchangeasset, destinationAmount is baseAsset
                      values.sourceAmount * currentPrice.buyPrice,
                2
            )
        );
    }, [values.sourceAmount, values.exchangeType, currentPrice, setFieldValue]);
    useEffect(() => {
        if (values.destinationAmount === null || !currentPrice) return;
        setFieldValue(
            'estimatedSourceAmount',
            roundToPrecision(
                values.exchangeType === 'Buy'
                    ? values.destinationAmount * currentPrice.sellPrice
                    : values.destinationAmount / currentPrice.buyPrice,
                2
            )
        );
    }, [values.destinationAmount, values.exchangeType, currentPrice, setFieldValue]);

    const buttonString = `${
        values.exchangeType === 'Sell'
            ? values.quantityType === 'Source'
                ? 'Buy'
                : 'Spend'
            : values.quantityType === 'Source'
            ? 'Receive'
            : 'Sell'
    } Exact`;

    return (
        <div className="ExchangeDetail">
            <div className={'InputSection' + (exactPrice === 'Top' ? ' Exact' : '')}>
                {`You're ${values.exchangeType === 'Buy' ? 'selling' : 'buying'} ${
                    exactPrice === 'Top' ? 'exactly ' : ''
                }`}
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel">
                        {values.exchangeType === 'Buy' ? sourceCurrency : destinationCurrency}
                    </div>
                    <FormTextField
                        field={
                            exactPrice === 'Top'
                                ? values.exchangeType === 'Buy'
                                    ? 'sourceAmount'
                                    : 'destinationAmount'
                                : values.exchangeType === 'Buy'
                                ? 'estimatedSourceAmount'
                                : 'estimatedDestinationAmount'
                        }
                        label={''}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={exactPrice !== 'Top'}
                        // key={
                        //     exactPrice === 'Top'
                        //         ? values.exchangeType === 'Buy'
                        //             ? 'destinationAmount'
                        //             : 'sourceAmount'
                        //         : values.exchangeType === 'Buy'
                        //         ? 'estimatedDestinationAmount'
                        //         : 'estimatedSourceAmount'
                        // }
                    />
                </div>
            </div>
            <div className="ExchangeRate">
                <Button
                    title="Switch selected input"
                    type="button"
                    className="Btn ExactToggle"
                    onClick={switchQuantityType}
                >
                    <ExchangeSvg /> {buttonString}
                </Button>
                <div style={{ marginLeft: 5, width: 60 }}>Indicative Sell Rate:</div>
                <div className="Rates">
                    <p>
                        {values.baseAsset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {values.exchangeAsset} ~{' '}
                        {roundToPrecision(
                            values.exchangeType === 'Buy' ? values.sellPrice : values.buyPrice,
                            6
                        )}
                    </p>
                    <p>
                        {values.exchangeAsset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {values.baseAsset} ~{' '}
                        {roundToPrecision(
                            values.exchangeType === 'Buy'
                                ? 1 / values.sellPrice
                                : 1 / values.buyPrice,
                            6
                        )}
                    </p>
                </div>
            </div>
            <div className={'InputSection' + (exactPrice === 'Bottom' ? ' Exact' : '')}>
                {`You'll be ${values.exchangeType === 'Buy' ? 'buying' : 'selling'} ${
                    exactPrice === 'Bottom' ? 'exactly ' : ''
                }`}
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel">
                        {values.exchangeType === 'Buy' ? destinationCurrency : sourceCurrency}
                    </div>
                    <FormTextField
                        field={
                            exactPrice === 'Bottom'
                                ? values.exchangeType === 'Buy'
                                    ? 'destinationAmount'
                                    : 'sourceAmount'
                                : values.exchangeType === 'Buy'
                                ? 'estimatedDestinationAmount'
                                : 'estimatedSourceAmount'
                        }
                        label={''}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        disabled={exactPrice !== 'Bottom'}
                        // key={
                        //     exactPrice === 'Bottom'
                        //         ? values.exchangeType === 'Buy'
                        //             ? 'sourceAmount'
                        //             : 'destinationAmount'
                        //         : values.exchangeType === 'Buy'
                        //         ? 'estimatedSourceAmount'
                        //         : 'estimatedDestinationAmount'
                        // }
                    />
                </div>
            </div>
        </div>
    );
};

const FromTo = ({ exchangeDetails }: { exchangeDetails: ExchangeDetails }) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const sourceCurrency = values.exchangeType === 'Buy' ? values.exchangeAsset : values.baseAsset;
    const destinationCurrency =
        values.exchangeType === 'Buy' ? values.baseAsset : values.exchangeAsset;

    const sourceAccounts =
        values.exchangeType === 'Buy'
            ? exchangeDetails.exchangeCurrencyAccounts
            : exchangeDetails.baseCurrencyAccounts;
    const destinationAccounts =
        values.exchangeType === 'Buy'
            ? exchangeDetails.baseCurrencyAccounts
            : exchangeDetails.exchangeCurrencyAccounts;

    const sourceHasOptions = sourceAccounts.length > 1;
    const destinationHasOptions = destinationAccounts.length > 1;

    const sourceAccount = sourceAccounts.find(
        (account) => account.accountID === values.sourceAccountId
    );
    const destinationAccount = destinationAccounts.find(
        (account) => account.accountID === values.destinationAccountId
    );

    useEffect(() => {
        if (!values.sourceAccountId) {
            setFieldValue(
                'sourceAccountId',
                exchangeDetails.exchangeType === 'Buy'
                    ? exchangeDetails.exchangeCurrencyAccounts?.[0]?.accountID
                    : exchangeDetails.baseCurrencyAccounts?.[0]?.accountID
            );
        }
        if (!values.destinationAccountId)
            setFieldValue(
                'destinationAccountId',
                exchangeDetails.exchangeType === 'Buy'
                    ? exchangeDetails.baseCurrencyAccounts?.[0]?.accountID
                    : exchangeDetails.exchangeCurrencyAccounts?.[0]?.accountID
            );
    }, [exchangeDetails, setFieldValue, values.destinationAccountId, values.sourceAccountId]);

    return (
        <div className="FromTo">
            {!sourceHasOptions && sourceAccount && (
                <div className="From">
                    <TextCurrencyIcon currencySymbol={sourceAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>From:</p>
                        <h4>{sourceAccount.displayName}</h4>
                    </div>
                    <div className="Balance">
                        {formatCurrency(sourceAccount.balance, sourceCurrency)}
                    </div>
                </div>
            )}
            {sourceHasOptions && (
                <AccountDropdown
                    fieldname="sourceAccountId"
                    currency={sourceCurrency}
                    accounts={sourceAccounts}
                    direction="From"
                />
            )}
            {!destinationHasOptions && destinationAccount && (
                <div className={`To ${sourceHasOptions ? 'ExtraMargin' : ''}`}>
                    <TextCurrencyIcon currencySymbol={destinationAccount.currencySymbol} />
                    <div className="AccountInfo">
                        <p>To:</p>
                        <h4>{destinationAccount.displayName}</h4>
                    </div>
                    {destinationAccount.balance !== null && (
                        <div className="Balance">
                            {formatCurrency(destinationAccount.balance, destinationCurrency)}
                        </div>
                    )}
                </div>
            )}
            {destinationHasOptions && (
                <AccountDropdown
                    fieldname="destinationAccountId"
                    currency={destinationCurrency}
                    accounts={destinationAccounts}
                    direction="To"
                />
            )}
        </div>
    );
};

const OrderSummary = ({
    orderDetails,
    exchangeDetails,
    reloadOrder,
}: {
    orderDetails: ReviewedDetails;
    exchangeDetails: ExchangeDetails;
    reloadOrder: (
        values: FormValues,
        setFieldError: FormikHelpers<FormValues>['setFieldError']
    ) => void;
}) => {
    const { values, setFieldError } = useFormikContext<FormValues>();
    const exactPrice =
        (exchangeDetails.exchangeType === 'Buy' && orderDetails.sourceAmount !== null) ||
        (exchangeDetails.exchangeType === 'Sell' && orderDetails.destinationAmount !== null)
            ? 'Left'
            : 'Right';

    const sourceAccounts =
        exchangeDetails.exchangeType === 'Buy'
            ? exchangeDetails.exchangeCurrencyAccounts
            : exchangeDetails.baseCurrencyAccounts;
    const destinationAccounts =
        exchangeDetails.exchangeType === 'Buy'
            ? exchangeDetails.baseCurrencyAccounts
            : exchangeDetails.exchangeCurrencyAccounts;
    const sourceAccount = sourceAccounts.find(
        (account) => account.accountID === values.sourceAccountId
    );
    const destinationAccount = destinationAccounts.find(
        (account) => account.accountID === values.destinationAccountId
    );

    useEffect(() => {
        if (!orderDetails) return;
        const timeRemaining = new Date(orderDetails.quotedFxRateExpiry).getTime() - Date.now();
        const timeout = setTimeout(() => {
            reloadOrder(values, setFieldError);
        }, timeRemaining);
        return () => clearTimeout(timeout);
    }, [values, orderDetails, setFieldError, reloadOrder]);

    return (
        <div>
            <div className="BuySellAmounts">
                <div>
                    <h4>{`You're ${exchangeDetails.exchangeType === 'Buy' ? 'selling' : 'buying'} ${
                        exactPrice === 'Left' ? 'exactly' : ''
                    }`}</h4>
                    <h3>
                        {formatCurrency(
                            exchangeDetails.exchangeType === 'Buy'
                                ? orderDetails.sourceAmount ?? orderDetails.quoteSourceAmount
                                : orderDetails.destinationAmount ??
                                      orderDetails.quoteDestinationAmount,
                            exchangeDetails.exchangeCurrency,
                            undefined,
                            {
                                currencyDisplay: 'code',
                            }
                        )}
                    </h3>
                    {exchangeDetails.exchangeType === 'Buy' && (
                        <span>({orderDetails.sourceAmountLessFees} after fees)</span>
                    )}
                </div>
                <div>
                    <h4>{`You're ${exchangeDetails.exchangeType === 'Buy' ? 'buying' : 'selling'} ${
                        exactPrice === 'Right' ? 'exactly' : ''
                    }`}</h4>
                    <h3>
                        {formatCurrency(
                            exchangeDetails.exchangeType === 'Buy'
                                ? orderDetails.destinationAmount ??
                                      orderDetails.quoteDestinationAmount
                                : orderDetails.sourceAmount ?? orderDetails.quoteSourceAmount,
                            exchangeDetails.baseCurrency,
                            undefined,
                            {
                                currencyDisplay: 'code',
                            }
                        )}
                    </h3>
                    {exchangeDetails.exchangeType === 'Sell' && (
                        <span>({orderDetails.sourceAmountLessFees} plus fees)</span>
                    )}
                </div>
            </div>
            <div className="ExchangeRate ExchangeRateConfirm">
                <div>Quoted Price:</div>
                <div className="Rates">
                    <p>
                        {exchangeDetails.exchangeCurrency} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {exchangeDetails.baseCurrency} ~{' '}
                        {roundToPrecision(parseFloat(orderDetails.quotedFXRate), 6)}
                    </p>
                    <p>
                        {exchangeDetails.baseCurrency} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {exchangeDetails.exchangeCurrency} ~{' '}
                        {roundToPrecision(1 / parseFloat(orderDetails.quotedFXRate), 6)}
                    </p>
                </div>
            </div>
            <div className="SummaryRow">
                <div className="RowInfo">
                    <p>From:</p>
                    <h4>{sourceAccount?.displayName ?? null}</h4>
                </div>
                <div className="RowInfo">
                    <p>To:</p>
                    <h4>{destinationAccount?.displayName ?? null}</h4>
                </div>
            </div>
            <div className="SummaryRow">
                <div className="RowInfo">
                    <p>My comment</p>
                    <h4>{orderDetails.note ? orderDetails.note : '--'}</h4>
                </div>
            </div>
        </div>
    );
};
