import { useSelector } from 'react-redux';
import { selectModalState, ModalTypes } from 'reducers/modal';
import { ChangePasswordModal } from './ChangePasswordModal';
import { ChangePhoneNumberModal } from './ChangePhoneNumberModal';
import { DeactivateAccountModal } from './DeactivateAccountModal';
import { DepositCryptoModal } from './DepositCryptoModal';
import { PullFundsModal } from './PullFundsModal';
import { UpdateAppTfaModal } from './UpdateAppTfaModal';
import { UpdateEmailAddressModal } from './UpdateEmailAddressModal';
import { UploadDocumentFlowType, UploadDocumentModal } from './UploadDocumentModal';
import { WithdrawCryptoModal } from './WithdrawCryptoModal';
import { ManageCardUsers_AddCard } from './ManageCardUsers_AddCard';
import { VisaDirectModal } from 'components/payees/VisaDirectModal';
import { WithdrawCryptoAsFiatModal } from './WithdrawCryptoAsFiatModal';
import { LiquidateCryptoModal } from './LiquidateCryptoModal';
import { UploadDocumentModalBusinessForm } from './UploadDocumentModalBusinessForm';
import { TransactionInfoModal } from './TransactionInfoModal';
import { TransactionNoteModal } from './TransactionNoteModal';
import { CurrencyExchangeModal } from './CurrencyExchangeModal/CurrencyExchangeModal';
import { CurrencyExchangeChartModal } from './CurrencyExchangeChartModal';
import { UploadMulitpleDocumentModalBusinessForm } from './UploadMultipleDocumentModalBusinessForm';
import { TransactionDetailsModal } from './TransactionDetailsModal';
import { InformationRequestModal } from './InformationRequestModal';
import { UploadInfoRequestModal } from './UploadInfoRequestModal';
import { PayeeDetailsModal } from './PayeeDetailsModal';
import { RemovePayeeModal } from './RemovePayeeModal';
import { CryptoExchangeModal } from './CryptoExchangeModal';
import { CryptoExchangeChartModal } from './CryptoExchangeChartModal';
import { BuyDusdModal } from './BuyDusdModal';
import { PendingCardModal } from './PendingCardModal';

export const GlobalModal = () => {
    const modalState = useSelector(selectModalState);
    if (modalState.modalType === null) return null;
    if (modalState.modalType === ModalTypes.DEPOSIT_CRYPTO)
        return <DepositCryptoModal asset={modalState.data.crypto} />;
    if (modalState.modalType === ModalTypes.WITHDRAW_CRYPTO)
        return <WithdrawCryptoModal asset={modalState.data.crypto} />;
    if (modalState.modalType === ModalTypes.WITHDRAW_CRYPTO_AS_FIAT)
        return <WithdrawCryptoAsFiatModal />;
    if (modalState.modalType === ModalTypes.CHANGE_PASSWORD) return <ChangePasswordModal />;
    if (modalState.modalType === ModalTypes.CHANGE_PHONE_NUMBER) return <ChangePhoneNumberModal />;
    if (modalState.modalType === ModalTypes.CHANGE_EMAIL) return <UpdateEmailAddressModal />;
    if (modalState.modalType === ModalTypes.DEACTIVATE_ACCOUNT) return <DeactivateAccountModal />;
    if (modalState.modalType === ModalTypes.TRANSACTION_INFO) return <TransactionInfoModal />;
    if (modalState.modalType === ModalTypes.TRANSACTION_NOTE) return <TransactionNoteModal />;
    if (modalState.modalType === ModalTypes.TRANSACTION_DETAILS) return <TransactionDetailsModal />;
    if (modalState.modalType === ModalTypes.PAYEE_DETAILS) return <PayeeDetailsModal />;
    if (modalState.modalType === ModalTypes.REMOVE_PAYEE) return <RemovePayeeModal />;
    if (modalState.modalType === ModalTypes.UPDATE_APP_TFA) return <UpdateAppTfaModal />;
    if (modalState.modalType === ModalTypes.UPLOAD_BUSINESS_DOCUMENT)
        return modalState.data.flowType === UploadDocumentFlowType.LinkedCustomer ? (
            <UploadDocumentModal />
        ) : (
            <UploadDocumentModalBusinessForm />
        );
    if (modalState.modalType === ModalTypes.VISA_DIRECT)
        return (
            <VisaDirectModal
                customerAssetAccountsId={modalState.data.customerAssetAccountsId}
                reloadTable={modalState.data.reloadTable}
            />
        );
    if (modalState.modalType === ModalTypes.UPLOAD_MULTI_BUSINESS_DOCUMENT)
        return <UploadMulitpleDocumentModalBusinessForm />;
    if (modalState.modalType === ModalTypes.PULL_FUNDS)
        return (
            <PullFundsModal
                payeeDetails={modalState.data.payeeData}
                onCompletion={modalState.data.reloadTable}
            />
        );
    if (modalState.modalType === ModalTypes.ADD_CARD_MANAGECARDUSERS)
        return <ManageCardUsers_AddCard />;
    if (modalState.modalType === ModalTypes.LIQUIDATE_CRYPTO) return <LiquidateCryptoModal />;
    if (modalState.modalType === ModalTypes.CURRENCY_EXCHANGE) return <CurrencyExchangeModal />;
    if (modalState.modalType === ModalTypes.CURRENCY_EXCHANGE_CHART)
        return <CurrencyExchangeChartModal />;
    if (modalState.modalType === ModalTypes.REQUEST_INFO) return <InformationRequestModal />;
    if (modalState.modalType === ModalTypes.UPLOAD_REQUEST_INFO) return <UploadInfoRequestModal />;
    if (modalState.modalType === ModalTypes.CRYPTO_EXCHANGE) return <CryptoExchangeModal />;
    if (modalState.modalType === ModalTypes.CRYPTO_EXCHANGE_CHART)
        return <CryptoExchangeChartModal />;
    if (modalState.modalType === ModalTypes.BUY_DUSD) return <BuyDusdModal />;
    if (modalState.modalType === ModalTypes.PENDING_CARD) return <PendingCardModal />;
    return null;
};
