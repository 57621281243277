import React from 'react';
import SearchIcon from '../../../assets/Cat-Entity/Icon_Form_Search_01.png';
import CrossIcon from '../../../assets/Cat-Entity/Icon_Form_Search_02.png';

type Props = JSX.IntrinsicElements['input'] & {
    changeSearch?(searchString: string): void;
    initialSearchString: string;
    goToPage?(page: number): void;
};

const SearchBox: React.FC<Props> = ({ changeSearch, initialSearchString, goToPage, ...props }) => {
    const [search, setSearch] = React.useState(initialSearchString);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (changeSearch && goToPage) {
            changeSearch(search);
            goToPage(1);
        } else {
            // possibly do something here if needed
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch('');
        if (changeSearch && goToPage) {
            changeSearch('');
            goToPage(1);
        }
    };

    const isEmpty = search === '';

    return (
        <form onSubmit={handleSubmit} className="SearchBoxContainer">
            <input
                className="SearchBox"
                placeholder="Type your search here"
                value={search}
                onChange={handleChange}
                {...props}
            />
            {isEmpty ? (
                <img src={SearchIcon} alt="Search" className="SearchIcon" onClick={clearSearch} />
            ) : (
                <img
                    src={CrossIcon}
                    alt="Clear"
                    className="ClearSearchIcon"
                    onClick={clearSearch}
                />
            )}
        </form>
    );
};

export { SearchBox };
