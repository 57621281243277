import { useEffect, useState } from 'react';
import RedX from 'assets/ibanera/Icon_RedCross.png';
import GreenTick from 'assets/ibanera/Icon_GreenTick.png';
import { useDispatch, useSelector } from 'react-redux';
import { endpoints } from 'endpoints.config';
import instance, { ApiResponse, useFetch } from 'api';
import {
    NotificationIdentifier,
    completeUIUpdate,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import Button from 'components/button/Button';
import { ModalTypes, openModal } from 'reducers/modal';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { useField, useFormikContext } from 'formik';
import { ErrorM } from 'components/form/ErrorM';
import { Spinner } from 'components/spinner/Spinner';
import { UploadDocumentFlowType } from 'components/modals/UploadDocumentModal';
import { formatValues } from '../helpers/helpers';
import { FormValues } from '../schema';
import { AdditionalDocumentUpload } from 'components/businessVerification/components/AdditionalDocumentUpload';
import BackButton from '../helpers/BackButton';
interface Props {}

export type DocumentFieldValue = {
    documentTypesID: number;
    customersBusinessDocumentsId: number;
    fileName: string | null;
    bAlwaysRequired?: boolean; //some old forms won't have this value (07/03/24)
    bIsAdditionalDocument?: boolean;
};

export type DocumentData = {
    documentId: number;
    filename: string;
    documentStatus: number;
    documentStatusString: 'Checking' | 'Rejected' | 'Expired' | 'Not Submitted' | 'Approved';
    name: string;
    orderNumber: number;
    documentNotes?: string;
    uploadNotes: string | null;
    bAlwaysRequired: boolean;
    rejectionReason: string | null;
    bIsAdditionalDocument: boolean;
    customersBusinessDocumentsId: number;
};

const statusesToUploadFrom: DocumentData['documentStatusString'][] = [
    'Not Submitted',
    'Expired',
    'Rejected',
    'Checking',
];

const BusinessVerificationDocuments: React.FC<Props> = (props) => {
    const { reload, data } = useFetch<ApiResponse<DocumentData[]>>(
        endpoints.businessVerificationV2Module.getDocumentList
    );

    const additionalDocuentId = data?.details.find((doc) => doc.bIsAdditionalDocument)?.documentId;

    const uiUpdate = useSelector(selectUIUpdate);
    const dispatch = useDispatch();
    const [, meta, helpers] = useField<DocumentFieldValue[]>('documentsUpload');
    const { values } = useFormikContext<FormValues>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (
            uiUpdate?.pushType === NotificationIdentifier.RELOAD_BUSINESS_DOCUMENT_LIST ||
            uiUpdate?.pushType === NotificationIdentifier.BUSINESS_DOCUMENT_VERIFIED //Will docs be verified on upload?
        ) {
            reload();
            dispatch(completeUIUpdate());
        }
    }, [uiUpdate, reload, dispatch]);

    const saveForm = async () => {
        try {
            await instance.post(endpoints.businessVerificationV2Module.saveFormData, {
                ...formatValues(values),
                businessVerificationStep: values.businessVerificationStep,
            });
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };

    useEffect(() => {
        if (data && data.details) {
            //if field not set intilise document array
            setLoading(true);
            if (
                Array.isArray(meta.value) &&
                meta.value.length === 1 &&
                meta.value[0].documentTypesID === null
            ) {
                let documentUploads: DocumentFieldValue[] = [];
                data.details.forEach((document) => {
                    documentUploads.push({
                        documentTypesID: document.documentId,
                        customersBusinessDocumentsId: document.customersBusinessDocumentsId,
                        fileName: null,
                        bAlwaysRequired: document.bAlwaysRequired,
                        bIsAdditionalDocument: document.bIsAdditionalDocument,
                    });
                });
                helpers.setValue([...documentUploads]);
            } else if (Array.isArray(meta.value)) {
                const newDocuments: DocumentFieldValue[] = data.details.map((newDocument) => {
                    if (
                        newDocument.documentStatusString === 'Expired' ||
                        newDocument.documentStatusString === 'Rejected'
                    ) {
                        //reset documents that have expired or been rejected
                        return {
                            documentTypesID: newDocument.documentId,
                            customersBusinessDocumentsId: newDocument.customersBusinessDocumentsId,
                            fileName: null,
                            bAlwaysRequired: newDocument.bAlwaysRequired,
                            bIsAdditionalDocument: newDocument.bIsAdditionalDocument,
                        };
                    }

                    //replace with data from new documents list (in case of document list updates)
                    const matchedDocument = meta.value.find(
                        (document) =>
                            document.documentTypesID === newDocument.documentId &&
                            (document.bIsAdditionalDocument
                                ? document.customersBusinessDocumentsId ===
                                  newDocument.customersBusinessDocumentsId
                                : true)
                    );

                    return matchedDocument
                        ? {
                              ...matchedDocument,
                              fileName: newDocument.filename,
                              bAlwaysRequired: newDocument.bAlwaysRequired,
                          }
                        : {
                              documentTypesID: newDocument.documentId,
                              customersBusinessDocumentsId:
                                  newDocument.customersBusinessDocumentsId,
                              fileName: newDocument.filename,
                              bAlwaysRequired: newDocument.bAlwaysRequired,
                              bIsAdditionalDocument: newDocument.bIsAdditionalDocument,
                          };
                });
                helpers.setValue(newDocuments);
                saveForm();
            }
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]); // ignore the meta.value dependency to avoid looping

    return (
        <>
            <div className="Docs">
                <BackButton />
                <div className="DocsHeader">
                    <h1>Document Upload</h1>
                    <ErrorM name={`documentsUpload`} />
                    <p>Please upload the following documents:</p>
                </div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div className="CardsSection">
                            {data &&
                                data.details.map((document, index: number) =>
                                    document.bIsAdditionalDocument ? null : (
                                        <ManageBusinessDocumentCard
                                            cardData={document}
                                            key={document.documentId}
                                            reload={reload}
                                            index={index}
                                            isInitialVerification
                                        ></ManageBusinessDocumentCard>
                                    )
                                )}
                            {data?.details && (
                                <AdditionalDocumentUpload
                                    documents={data.details}
                                    documentId={additionalDocuentId!}
                                    reload={reload}
                                    flowType="BUSINESS_FORM_V2"
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

function ManageBusinessDocumentCard({
    cardData,
    reload,
    index,
}: {
    cardData: DocumentData;
    reload: () => void;
    index: number;
    // Using this as it's a hot fix and don't want to mess with the other place this component is used
    isInitialVerification?: boolean;
}) {
    const dispatch = useDispatch();
    const [, , helpers] = useField(`documentsUpload.${index}.fileName`);

    const { values } = useFormikContext<any>();
    const fieldName = `documentsUpload.${index}`;
    const bRequired = cardData.bAlwaysRequired;
    const handleUploadDocument = (documentId: number, documentName: string) => {
        dispatch(
            openModal({
                modalType: ModalTypes.UPLOAD_BUSINESS_DOCUMENT,
                data: {
                    flowType: UploadDocumentFlowType.BusinessFormV2,
                    documentId,
                    documentName,
                    reloadTable: reload,
                    bRequired,
                    helpers,
                    values,
                    fieldName,
                },
            })
        );
    };

    return (
        <div className="CardContainer">
            <div className="DocumentCard">
                <div className="CardTitle">{cardData.name} </div>
                <div className="CardBody">
                    <h3 className="CardStatus">
                        {cardData.documentStatusString === 'Checking'
                            ? 'Uploaded'
                            : cardData.documentStatusString}
                    </h3>
                    <img
                        className="CardImage"
                        src={
                            cardData.documentStatusString === 'Approved' ||
                            cardData.documentStatusString === 'Checking'
                                ? GreenTick
                                : RedX
                        }
                        alt={cardData.documentStatusString}
                    ></img>
                    <div className="CardCaption">{cardData.documentNotes}</div>
                    {cardData.bAlwaysRequired && (
                        <>
                            <div className="CardSpacer"></div>
                            <div className="CardRequired">Required</div>
                        </>
                    )}
                </div>
                <div className="CardFooter">
                    <Button
                        type="button"
                        disabled={!statusesToUploadFrom.includes(cardData.documentStatusString)}
                        onClick={() => {
                            helpers.setTouched(true);
                            handleUploadDocument(cardData.documentId, cardData.name);
                        }}
                    >
                        Upload
                    </Button>
                </div>
            </div>
            <ErrorM name={`documentsUpload.${index}.fileName`} />
            <ErrorM name={`documentsUpload.${index}`} />
        </div>
    );
}

export default BusinessVerificationDocuments;
