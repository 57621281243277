import { useEffect, useState } from 'react';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';
import warningIcon from 'assets/Cat-Entity/Icon_Notification_Warning.png';
import api, { ApiResponse } from '../../api';
import { endpoints } from '../../endpoints.config';
import { Toast } from '../../helpers/toast';

type Props = {
    onClose(): void;
    onSuccess(): void;
    nicknameId: number;
    nickname: string;
};

export const DeleteNickname: React.FC<Props> = ({ nicknameId, nickname, onClose, onSuccess }) => {
    const [submitting, setSubmitting] = useState(true);

    // To avoid a double click accidently clicking confirm button
    useEffect(() => {
        const id = setTimeout(() => {
            setSubmitting(false);
        }, 300);

        return () => clearTimeout(id);
    }, []);

    const handleDelete = async () => {
        try {
            setSubmitting(true);
            const res = await api.post<ApiResponse>(
                endpoints.cryptosmodule.deleteSavedWallet,
                undefined,
                {
                    params: { id: nicknameId },
                }
            );
            if (res.data.status === '1') {
                onSuccess();
                Toast.openSuccessToast('Removed nickname');
            } else throw new Error();
        } catch (error) {
            Toast.openErrorToast('Failed to remove nickname');
        } finally {
            setSubmitting(false);
        }
    };
    const handleClose = () => {
        if (submitting) return;
        else onClose();
    };

    const CustomButtons = () => (
        <div className="ModalNavigation">
            <Button onClick={handleClose} priority="secondary" type={'button'}>
                Close
            </Button>
            <Button
                onClick={handleDelete}
                priority="primary"
                color="third"
                disabled={submitting}
                type={'button'}
            >
                Delete Nickname
            </Button>
        </div>
    );

    return (
        <Modal
            title="Delete nickname"
            onCloseModal={handleClose}
            backgroundClassName="DeleteNicknameContainer"
            className="DeleteNickname"
            customButtons={CustomButtons()}
        >
            <div>
                <p>Are you sure you want to delete: "{nickname}"?</p>

                <div className="DeleteNicknameWarning">
                    <img src={warningIcon} alt="warning" />

                    <p>This action cannot be undone</p>
                </div>
            </div>
        </Modal>
    );
};
