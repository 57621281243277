/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import api, { ApiResponse } from '../api';
import { endpoints } from '../endpoints.config';
import { ErrorCodes, updateErrorCodes } from '../reducers/errorCodes';

const RETRYS = 3;
const DELAY = 1000 * 5;

export const useFetchErrorCodes = () => {
    const dispatch = useDispatch();

    const fetchErrorCodes = async (attempt = 1) => {
        try {
            const res = await api.get<ApiResponse<{ errorCode: string; errorText: string }[]>>(
                endpoints.errorCodes.codes,
                { withCredentials: false }
            );

            if (res.data.details) {
                const errors = res.data.details.reduce((prev, cur) => {
                    prev[cur.errorCode] = cur.errorText;
                    return prev;
                }, {} as ErrorCodes);
                dispatch(updateErrorCodes(errors));
            } else {
                throw new Error();
            }
        } catch (error) {
            if (attempt < RETRYS) {
                // Retry after the specified delay
                setTimeout(() => fetchErrorCodes(attempt + 1), DELAY);
            } else {
                // Do nothing
            }
        }
    };

    useEffect(() => {
        fetchErrorCodes();
    }, []);
};
