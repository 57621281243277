import instance, { ApiResponse } from 'api';
import { Spinner } from 'components/spinner/Spinner';
import React, { useEffect, useState } from 'react';

interface IFrameDetails {
    productName: string;
    productType: 'IFrame';
    productURL: string;
}

type IFrameProps = {
    apiPath: string;
};

// The customer navigation items includes an apiPath attribute which we call to
// get the src and title of our iframe.
export const IFrameProduct: React.FC<IFrameProps> = ({ apiPath }) => {
    const [iFrameDetails, setIFrameDetails] = useState<IFrameDetails | null>(null);

    useEffect(() => {
        setIFrameDetails(null);
        instance
            // TODO(HC): Remove withCredentials option once stoplight endpoint is swapped out.
            .get<ApiResponse<IFrameDetails>>(apiPath, { withCredentials: false })
            .then((response) => {
                if (response.data.status === '1') {
                    setIFrameDetails(response.data.details);
                }
            });
    }, [apiPath]);

    return iFrameDetails && iFrameDetails.productType === 'IFrame' ? (
        <iframe src={iFrameDetails.productURL} title={iFrameDetails.productName}></iframe>
    ) : (
            <Spinner className="DashboardContentSpinner" />
        );
};
