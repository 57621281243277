import { Form, Formik } from 'formik';
import api, { ApiResponse, isAxiosErrorHandled } from '../../api';
import { endpoints } from '../../endpoints.config';
import { Toast } from '../../helpers/toast';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';
import { GeneralWarning } from '../GeneralWarning/GeneralWarning';

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSuccess: () => void;
    transactionId: number;
};

export const CancelTransferModal: React.FC<Props> = ({
    transactionId,
    showModal,
    closeModal,
    onSuccess,
}) => {
    if (!showModal) return null;

    const handleSubmit = async () => {
        try {
            const res = await api.post<ApiResponse>(endpoints.accounts.cancelTransaction, {
                customerAssetAccountsTransactionsId: transactionId,
            });
            if (res.data.status === '1') {
                Toast.openSuccessToast('Cancellation Request Submitted');
                onSuccess();
            } else throw new Error();
        } catch (error) {
            let msg = 'Failed to cancel transaction';
            if (isAxiosErrorHandled(error)) {
                /* 
                These are the possible messages currently. None the user needs to know so the general fail is enough.
                Unauthorized: The logged-in customer credentials do not have access to the transaction
                BadRequest: The transaction could not be found in the database, or is not eligible to be cancelled
                InternalServerError: Real-time transaction information could not be obtained
                 */
            }
            Toast.openErrorToast(msg);
        }
    };
    return (
        <Formik initialValues={{ bCancel: true }} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form>
                    <Modal
                        title="Cancel transfer"
                        className="CancelTransferModal"
                        onCloseModal={isSubmitting ? () => {} : closeModal}
                        customButtons={
                            <div className="ModalNavigation">
                                <Button
                                    disabled={isSubmitting}
                                    priority="secondary"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Close
                                </Button>
                                <Button priority="primary" type="submit" disabled={isSubmitting}>
                                    Cancel Transaction
                                </Button>
                            </div>
                        }
                    >
                        <GeneralWarning message="Are you sure you want to cancel this transaction?" />
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
