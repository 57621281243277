export const plaintext = {
    cardManagement: {
        loading: 'Loading',
        searchCard: 'Search for a card',
        searchUser: 'Search for a user',
    },
    accounts: {
        from: 'From',
        to: 'To',
        searchTransactions: 'Search all transactions',
        searchPayees: 'Search for a payee',
        loadingTransactions: 'Loading transactions',
        loading: 'Loading',
    },
    sidemenu: {
        addAccount: 'Add New Account',
        addCard: 'Add New Card',
    },
    tables: {
        emptyPortFolioTable: 'Nothing found in your portfolio',
        emptyHistoryTable: 'No transaction history found',
        emptyTransactionTable: 'No transactions found',
        emptyBusinessDocTable: 'No Documents found',
        emptyPayeesTable: 'No saved payees found',
        emptyCardsTable: 'No saved cards found',
        emptyUsersTable: 'No saved users found',
    },
    generic: {
        back: '< Back',
    },
    cryptoPage: {
        currentTime: 'Current time:',
        lastUpdated: 'Last updated:',
        newOrder: 'New Order',
        chooseOrderType: 'Choose order type',
        buy: 'Buy',
        sell: 'Sell',
        buyOrderSummary: 'Buy Order Summary',
        validFor: 'valid for the next',
        seconds: 'seconds',
    },
    addAccountPage: {
        addNewAccount: 'Add New Account',
        blurb: 'Opening another account with us will allow you to control your personal finances separately.',
        enterDetails: 'Enter the correct details below to get started.',
        accountDetails: 'Account Details',
        accountType: 'Account Type',
        currency: 'Currency',
        name: 'Account Name',
        exampleName: 'E.g. Crypto Trading',
        buttonText: 'Create Account',
        legalText1: 'By clicking the "Create Account" button above, you agree to our ',
        legalTextLink1: 'terms and conditions.',
        legalText2:
            ' To learn more about how Ibanera collects, uses, shares and protects your personal data please read our ',
        legalTextLink2: 'security and privacy policy.',
    },
    addCardPage: {
        addNewCard: 'Add New Card',
        blurb: 'You can order a prepayment card to make transactions online and in store for goods and services. You can top this card up from your other accounts.',
        selectCurrency: 'Select Currency',
        sendTo: 'Send To',
        buttonText: 'Order Card',
    },
    business: {
        loadingDocuments: 'Loading documents',
    },
    verification: {
        needAdditionalInfo:
            'Thank you for your application, your registration was received. Please complete the form below to complete your onboarding process.',
        additionalNotes:
            'You have successfully submitted the form! You can sign out now - we will email you once your account has been approved',
    },
};
