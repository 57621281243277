import { useEffect, useRef } from 'react';

export const useAfterMountEffect = (cb: () => void, dep?: any[]) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            cb();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dep ?? []);
};
