import React from 'react';
import FormTextField from 'components/form/FormTextField';

interface Props {}

const AnticipatedActivity: React.FC<Props> = () => {
    return (
        <div>
            <FormTextField
                field={'corporate.anticipatedActivity.monthlyTurnOver'}
                label={'Monthly Turnover'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.next12MonthsActivities'}
                label={'Anticipated Turnover - Next 12 Months'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <h3>Crypto Activity</h3>
            <FormTextField
                field={'corporate.anticipatedActivity.sellingCryptoMonthlyVolume'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Monthly Volume'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.sellingCryptoAverageAmount'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Average Amount (USD)'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.sellingCryptoTotalAmountPerMonth'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Total Amount Per Month (USD)'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.purchaseCryptoMonthlyVolume'}
                label={'Cryptocurrency OTC Desk - Purchasing Crypto - Monthly Volume'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.purchaseCryptoAverageAmount'}
                label={'Cryptocurrency OTC Desk - Crypto to Crypto - Average Amount (USD)'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.purchaseCryptoTotalAmountPerMonth'}
                label={'Cryptocurrency OTC Desk - Crypto to Crypto - Total Amount Monthly (USD)'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.anticipatedActivity.totalMonthlyVolume'}
                    label={'Total for All Monthly Volume'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                    inputMode="numeric"
                />
                <FormTextField
                    field={'corporate.anticipatedActivity.totalAverageAmount'}
                    label={'Total for All Average Amount (USD)'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                    inputMode="numeric"
                />
            </div>
            <FormTextField
                field={'corporate.anticipatedActivity.btcTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - BTC'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.ethTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - ETH'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.usdcTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - USDC'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'corporate.anticipatedActivity.otherCoinsTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - Other Coins'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
        </div>
    );
};

export default AnticipatedActivity;
