/** @jsxImportSource @emotion/react */
import { css, Global, useTheme } from '@emotion/react';
import React from 'react';

export const GlobalStyles = () => {
    const theme = useTheme();
    return (
        <Global
            styles={css`
                .Btn-Primary {
                    background: ${theme.colors.first};
                    border: 1px solid ${theme.colors.first};
                }
                .Btn-Secondary {
                    background-color: transparent;
                    border: 1px solid ${theme.colors.first};
                    color: ${theme.colors.first};
                }
                .SvgCurrencyIcon,
                .SvgIcon {
                    fill: ${theme.colors.first};
                }
                .Toggle .slider {
                    border-color: ${theme.colors.second};
                }
                .Toggle input:checked + .slider {
                    border-color: ${theme.colors.first};
                }
                .FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row .Cell .LowHigh {
                    color: ${theme.colors.first};
                }
                .ProgressBar {
                    background-color: ${theme.colors.secondBackground};
                }
                .ProgressBar .Filled {
                    background-color: ${theme.colors.first};
                }
                .ProgressBar .Filled .FilledCircle {
                    background-color: ${theme.colors.first};
                }
                h1,
                h5,
                .FormLabel,
                .CustomInput,
                .PasswordStrengthInfo,
                .FlexTableWrapper .TableContainer .MiddleTable .TableBody .Row.Selected .Cell,
                .PhoneInput .PhoneInputInput {
                    color: ${theme.colors.headingColor};
                }
                .InputMask .Point.Filled {
                    color: ${theme.colors.headingColor};
                }

                .TextIcon.Active .SvgCurrencyIcon {
                    fill: ${theme.colors.second};
                }
                .TextIcon {
                    background-color: ${theme.colors.first};
                }
                .TextIcon.Active {
                    background-color: ${theme.colors.second};
                }
                .SelectTrade .Sell {
                    background-color: ${theme.colors.second};
                }
                .SelectTrade .Buy {
                    background-color: ${theme.colors.first};
                }
                .ModalContent .ModalNumber span {
                    color: ${theme.colors.first};
                }
                .CryptoPrice {
                    color: ${theme.colors.second};
                }
                .RightMenu .ItemsContainer .Item:hover {
                    background-color: ${theme.colors.secondBackground};
                }
                .Modal.JumioModal .SignOutLink {
                    color: ${theme.colors.second};
                }
            `}
        />
    );
};
