import React from "react";
import { WindowLocation } from "@reach/router";
import { useLoadScopes } from "./auth";
import { getPageTitle } from "./getPageTitle";
export function useGetPageTitle(location: WindowLocation | undefined | string) {
  const scopes = useLoadScopes();
  let pathname: string;
  if (typeof location === "string") {
    pathname = location;
  } else {
    pathname = location?.pathname ?? "";
  }

  const pageTitle = React.useMemo(() => getPageTitle(pathname, scopes), [
    pathname,
    scopes,
  ]);

  return pageTitle;
}
