import Button from 'components/button/Button';
import { useField } from 'formik';
import { useFocus } from 'helpers/useFocus';
import React, { useCallback, useState } from 'react';
import { TFAType } from 'pages/register/models';
import FormTextField, { FormTextFieldProps } from './FormTextField';
import { ThemedLink } from '../themedLink/ThemedLink';

type TFAFieldProps = FormTextFieldProps & {
    toggleTFAType: (() => void) | null;
    tfaType: TFAType;
    backgroundColor?: 'white' | 'grey';
    holdFocus?: boolean;
    handleResendCode?: () => void;
};

export const TFAField: React.FC<TFAFieldProps> = ({
    toggleTFAType,
    tfaType,
    backgroundColor = 'grey',
    className = '',
    holdFocus,
    handleResendCode,
    ...props
}) => {
    const [inputRef, setFocus] = useFocus<HTMLInputElement>();
    const [fieldProps, fieldMetaProps, fieldHelperProps] = useField(props.field);
    const handleBlur = (e: React.FocusEvent) => {
        fieldProps.onBlur(e);
        holdFocus && setFocus();
    };

    const [disabledResend, setDisabledResend] = useState(false);
    const sendNewCode = () => {
        if (disabledResend) return;
        handleResendCode && handleResendCode();
        setDisabledResend(true);
        setTimeout(() => {
            setDisabledResend(false);
        }, 1000);
    };

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            const { value } = e.target;
            const regex = /^[0-9]*$/;
            if (regex.test(value)) {
                fieldHelperProps.setValue(value, false);
            } else {
                fieldHelperProps.setValue(fieldProps.value, true);
            }
        },
        [fieldProps.value, fieldHelperProps]
    );
    return (
        <div className="TFAInputContainer">
            {toggleTFAType && (
                <div className={`SwitchTFATypeContainer`}>
                    <Button
                        priority="tertiary"
                        className="SwitchTFATypeButton"
                        onClick={() => {
                            setFocus();
                            toggleTFAType();
                        }}
                        type="button"
                    >
                        {tfaType === 'AuthenticatorApp' ? 'Use SMS instead' : 'Use app instead'}
                    </Button>
                </div>
            )}

            {tfaType === 'SMS' && !toggleTFAType && handleResendCode && (
                <ThemedLink onClick={sendNewCode} className="RegisterLink">
                    Resend code
                </ThemedLink>
            )}
            <FormTextField
                maxLength={6}
                autoComplete={false}
                inputMode="numeric"
                {...props}
                className={`TFAHiddenField ${className}`}
                ref={inputRef}
                onBlur={handleBlur}
                onChange={onChange}
            />
            <TFAInputMask
                value={fieldProps.value}
                className={`InputMask ${backgroundColor} ${
                    fieldMetaProps.error && fieldMetaProps.touched ? 'ErrorInput' : ''
                }`}
            />
        </div>
    );
};

type TFAInputMaskProps = {
    value: string;
    className?: string;
};

const TFA_INPUT_LENGTH = 6;

export const TFAInputMask: React.FC<TFAInputMaskProps> = ({ value, className }) => {
    const convertValueToMask = () =>
        Array(TFA_INPUT_LENGTH)
            .fill('')
            .map((_, index) => {
                return (
                    <div key={index} className={`Point ${value[index] ? 'Filled' : ''}`}>
                        {value[index] ?? '•'}
                    </div>
                );
            });
    return <label className={`InputMask ${className}`}>{convertValueToMask()}</label>;
};
