import RefreshIcon from 'assets/ibanera/refresh.svg';
import TempGraph from 'assets/ibanera/temp-graph.png';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { Modal } from 'components/modal/Modal';
import { format } from 'date-fns/esm';
import { formatPriceWithCommas } from 'helpers/formatPriceWithCommas';
import { splitCryptoPairString } from 'pages/crypto/useCryptoContextEffects';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectModalState } from 'reducers/modal';
import { BuyCryptoForm } from './BuyCryptoForm';
import { CryptoContent } from './CryptoContent';
import { CryptoHoldings } from './CryptoHoldings';
import { CryptoOrderHistory } from './CryptoOrderHistory';
import { ExchangeCryptoContext } from './ExchangePage';

const Crypto: React.FC = () => {
    const [trade, setTrade] = useState<string>('buy');
    const modalState = useSelector(selectModalState);

    const { selectedCurrencyInfo, currency, setSelectedCurrencyInfo, selectedFiatCurrency } =
        useContext(ExchangeCryptoContext);

    return (
        <div className="Crypto">
            {selectedCurrencyInfo ? (
                <>
                    <h2
                        onClick={() => {
                            setSelectedCurrencyInfo(null);
                        }}
                        className="BackButton"
                    >
                        {'< Back'}
                    </h2>
                    <div className="Container">
                        <div className="TopContainer">
                            <div className="LeftContainer">
                                <div className="CryptoHeaderContainer">
                                    <div className="CryptoHeader">
                                        <div className="Left">
                                            <CurrencyIcon currency={selectedCurrencyInfo.ticker} />
                                            <h1>
                                                {selectedCurrencyInfo.name
                                                    ? selectedCurrencyInfo.name
                                                    : selectedCurrencyInfo.ticker}
                                                <span>{selectedCurrencyInfo.ticker}</span>
                                            </h1>
                                        </div>
                                    </div>
                                    <p className={`CryptoPrice`}>
                                        {selectedFiatCurrency?.symbol}
                                        {selectedCurrencyInfo.buyPrice
                                            ? formatPriceWithCommas(selectedCurrencyInfo.buyPrice)
                                            : ''}
                                    </p>
                                </div>
                                <div className="KrakenGraph">
                                    <img src={TempGraph} alt="graph" />
                                </div>
                                <CryptoContent
                                    subject={'About'}
                                    name={
                                        selectedCurrencyInfo.name
                                            ? selectedCurrencyInfo.name
                                            : selectedCurrencyInfo.ticker
                                    }
                                    ticker={selectedCurrencyInfo.ticker}
                                    summaryItems={dummyData1}
                                    description={descriptionHtmlString}
                                />
                            </div>
                            <div className="RightContainer">
                                <div className="ContentBox LeftAlign">
                                    <div className="Refresh">
                                        <div>
                                            <p>
                                                Current time:{' '}
                                                <span>
                                                    {format(new Date(), 'dd-MM-yyyy hh:mm:ss')}
                                                </span>
                                            </p>
                                            <p>
                                                Last updated: <span>20 seconds ago</span>
                                            </p>
                                        </div>
                                        <div className="RefreshBtn">
                                            <img src={RefreshIcon} alt="icon" />
                                        </div>
                                    </div>
                                    <h2>New Order</h2>
                                    <div className="SelectTradeContainer">
                                        <p>Choose order type</p>
                                        <div className="SelectTrade">
                                            <div
                                                className={`Trade Buy ${
                                                    trade === 'buy'
                                                        ? 'Active'
                                                        : 'Inactive NoBorderRight'
                                                }`}
                                                onClick={() => {
                                                    setTrade('buy');
                                                }}
                                            >
                                                Buy
                                            </div>
                                            <div
                                                className={`Trade Sell ${
                                                    trade === 'sell'
                                                        ? 'Active'
                                                        : 'Inactive NoBorderLeft'
                                                }`}
                                                onClick={() => {
                                                    setTrade('sell');
                                                }}
                                            >
                                                Sell
                                            </div>
                                        </div>
                                    </div>
                                    {trade === 'buy' ? (
                                        <BuyCryptoForm
                                            crypto={selectedCurrencyInfo}
                                            currency={currency}
                                        />
                                    ) : (
                                        <BuyCryptoForm
                                            crypto={selectedCurrencyInfo}
                                            currency={currency}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <CryptoHoldings />
                        <CryptoOrderHistory />
                    </div>
                </>
            ) : null}
        </div>
    );
};

export { Crypto };

const dummyData1 = [
    {
        title: 'Market cap',
        content: '$359.7B',
        value: 'USD',
    },
    {
        title: 'Volume (24 hrs)',
        content: '£626.1K',
        value: 'GBP',
    },
    {
        title: 'Circulating supply',
        content: '18.6MM',
        value: 'BTC',
    },
    {
        title: 'All-time high',
        content: '£14,841.39',
        value: 'GBP',
    },
];

const descriptionHtmlString = `<p>Bitcoin is the world's first cryptocurrency and blockchain.</p><p>Bitcoin is the world's first cryptocurrency and blockchain. Sunt quis ex cupidatat eu et aliqua aute nisi non anim eiusmod aliqua. Fugiat minim laborum exercitation aliqua non minim laboris pariatur ut consequat. Ad quis nisi mollit labore cillum ad nulla occaecat. Dolore ad tempor est amet. Et ut culpa magna laborum velit ullamco voluptate labore adipisicing voluptate dolore laborum ipsum. Ad occaecat ipsum cillum Lorem est sint exercitation officia excepteur ullamco esse elit amet commodo. Enim dolore fugiat tempor cupidatat consectetur irure culpa adipisicing cupidatat ullamco et.</p>`;
