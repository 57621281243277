import { FormBuilder } from '@avamae/formbuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import Button from 'components/button/Button';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { Form, Formik } from 'formik';
import React, { SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, selectModalState, ModalTypes } from 'reducers/modal';
import { CurrencyOptionsModal } from './CurrencyOptionsModal';
import logger from 'helpers/logger';

type AddFundsProps = {
    setAddFunds: React.Dispatch<SetStateAction<boolean>>;
};

const AddFunds: React.FC<AddFundsProps> = ({ setAddFunds }) => {
    const dispatch = useDispatch();
    const modalState = useSelector(selectModalState);

    const currencyOptionModal = {
        title: 'Change the default currency',
        primaryButton: 'Confirm',
        secondaryButton: 'Cancel',
        handlePrimary: () => {
            logger.log('primary clicked');
        },
        handleSecondary: () => {
            logger.log('secondary clicked');
        },
    };

    const initialValues = {
        account: '',
        cardType: '',
        amount: null,
    };

    const handleSubmit = (values: any) => {
        logger.log(values);
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            <div className="AddFunds">
                <Form className="FormBuilderForm">
                    <h2
                        onClick={() => {
                            setAddFunds(false);
                        }}
                        className="TempBack"
                    >
                        Back
                    </h2>
                    <h1>Add Funds</h1>
                    <p className="LightBlue">Link an account to start trading.</p>
                    <FormBuilder
                        loadingFallback={<Spinner />}
                        data={addFundsData}
                        additionalData={{
                            account: [
                                { label: 'Personal account', key: 'personal' },
                                { label: 'Business account', key: 'business' },
                            ],
                            cardType: [
                                { label: 'Visa', key: 'Visa' },
                                { label: 'Mastercard', key: 'Mastercard' },
                            ],
                        }}
                    />
                    <Button type="submit" className="AddFundsBtn">
                        Submit
                    </Button>
                </Form>
                <div className="Divider"></div>
                <p className="LightBlue">Default currency</p>
                <div className="CurrencyOptions">
                    <img src={''} alt="currency" />
                    <div>
                        <h3>GBP</h3>
                        <p>Your activity will be displayed in this currency</p>
                        <Button
                            priority="secondary"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: ModalTypes.CRYPTO_CURRENCY_SELECT,
                                        data: null,
                                    })
                                )
                            }
                        >
                            Currency options
                        </Button>
                    </div>
                </div>
                {modalState.modalType === 'CRYPTO_CURRENCY_SELECT' && (
                    <Modal
                        title={currencyOptionModal.title}
                        primaryButton={currencyOptionModal.primaryButton}
                        secondaryButton={currencyOptionModal.secondaryButton}
                        handlePrimary={currencyOptionModal.handlePrimary}
                        handleSecondary={currencyOptionModal.handleSecondary}
                    >
                        <CurrencyOptionsModal />
                    </Modal>
                )}
            </div>
        </Formik>
    );
};

export { AddFunds };

const addFundsData: ApiResponse<any> = {
    id: 0,
    details: {},
    metadata: [
        {
            name: 'account',
            type: 'singleselect',
            label: 'Select Account', //(TODO) - this needs to be dynamic
            bRemoteDataSource: false,
        },
        {
            name: 'cardType',
            type: 'singleselect',
            label: 'Card type',
            bRemoteDataSource: false,
        },
        {
            name: 'amount',
            type: 'number',
            label: 'Amount',
            bRemoteDataSource: false,
        },
    ],
    status: '1',
    errors: [],
};
