import React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

export type SelectOption = { value: any; label: string };

type DropdownProps = {
    options: { value: any; label: string }[];
    value: ValueType<SelectOption, false>;
    onChange(selectedOption: ValueType<SelectOption, false>): void;
    roundedRectangle?: boolean;
    small?: boolean;
    defaultMenuIsOpen?: boolean;
};

type Props = DropdownProps;

const Dropdown: React.FC<Props> = ({
    options,
    value,
    onChange,
    roundedRectangle,
    small,
    defaultMenuIsOpen,
}) => {
    return (
        <div>
            <Select
                //maxMenuHeight={96}
                //menuIsOpen={true}
                className={
                    roundedRectangle
                        ? small
                            ? 'CustomSelect RoundedRect Small'
                            : 'CustomSelect RoundedRect'
                        : small
                        ? 'CustomSelect Small'
                        : 'CustomSelect'
                }
                defaultMenuIsOpen={defaultMenuIsOpen}
                classNamePrefix="CustomSelect"
                options={options}
                value={value}
                onChange={onChange}
                menuPlacement="auto"
            />

            {/* <h1>test</h1> */}
        </div>
    );
};

export { Dropdown };
//<ErrorM name={`${fieldName}.${eleProperty.toString()}`} />
