/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import React from 'react';
import CrossIcon from 'assets/ibanera/Icon_Close.png';
import searchIcon from 'assets/Icon_TEMP_Search.png';
import { useTheme } from '@emotion/react';

type Props = JSX.IntrinsicElements['input'] & {
    changeSearch?(searchString: string): void;
    initialSearchString: string;
    goToPage?(page: number): void;
};

const SearchBox: React.FC<Props> = ({ changeSearch, initialSearchString, goToPage, ...props }) => {
    const [search, setSearch] = React.useState(initialSearchString);
    const theme = useTheme();
    const searchBoxCss = css`
        &.SearchBoxContainer {
            border: 1px solid ${theme.colors.first};
        }
        .SearchBox {
            color: ${theme.colors.first};
        }
    `;
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (changeSearch) {
            changeSearch(search);
            goToPage && goToPage(1);
        } else {
            // possibly do something here if needed
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch('');
        if (changeSearch) {
            changeSearch('');
            goToPage && goToPage(1);
        }
    };

    const isEmpty = search === '';

    return (
        <form onSubmit={handleSubmit} className="SearchBoxContainer" css={searchBoxCss}>
            <input
                className="SearchBox"
                placeholder="Search"
                value={search}
                onChange={handleChange}
                {...props}
            />
            <img src={searchIcon} alt="Search" className="SearchIcon" />
            {!isEmpty && (
                <img
                    src={CrossIcon}
                    alt="Clear"
                    className="ClearSearchIcon"
                    onClick={clearSearch}
                />
            )}
        </form>
    );
};

export { SearchBox };
