export type ProgressBarSteps = Readonly<
    {
        stepNumber: number;
        label: string;
    }[]
>;
type Props = {
    steps: ProgressBarSteps;
    currentStepNumber: number;
    className?: string;
};

export const SegmentedProgressBar = ({ steps, currentStepNumber, className }: Props) => {
    return (
        <div className={`SegmentedProgressBar ${className}`}>
            <div className="Bar">
                {steps.map((step, i) => (
                    <div
                        className={`Segment ${
                            step.stepNumber < currentStepNumber
                                ? 'Passed'
                                : step.stepNumber === currentStepNumber
                                ? 'Active'
                                : ''
                        }`}
                        key={step.stepNumber}
                    >
                        {i > 0 && <div className="ConnectingLine"></div>}
                        <div className="Ball" />
                        <div className="SegmentLabel">{step.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const SegmentedProgressBar2 = ({ steps, currentStepNumber, className }: Props) => {
    return (
        <div className={`SegmentedProgressBar2 ${className}`}>
            {steps.map((step, i) => (
                <div
                    className={`Segment ${
                        step.stepNumber < currentStepNumber
                            ? 'Passed'
                            : step.stepNumber === currentStepNumber
                            ? 'Active'
                            : ''
                    }`}
                    key={step.stepNumber}
                >
                    {i > 0 && <div className="ConnectingLine"></div>}
                    <div className="Ball" />
                    <div className="SegmentLabel">{step.label}</div>
                </div>
            ))}
        </div>
    );
};
