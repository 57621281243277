import { ErrorM } from 'components/form/ErrorM';
import { Field, useField } from 'formik';
interface Props {
    options: { name: string; id: number }[];
    sideBySide?: boolean;
    formSideBySide?: boolean;
    label?: string;
    asterisk?: boolean;
    fieldName: string;
}
const SingleSelectCheckList: React.FC<Props> = ({
    options,
    sideBySide,
    formSideBySide,
    label,
    asterisk,
    fieldName,
}) => {
    const [, meta, helpers] = useField(fieldName);
    const handleChange = (value: number) => {
        meta.value === value ? helpers.setValue(null) : helpers.setValue(value);
    };

    return (
        <div
            className={`FormBox ${sideBySide && 'SideBySide'} ${
                formSideBySide && 'FormSideBySide'
            }`}
        >
            {label && (
                <div className="FormLabel">
                    <label>
                        {label}
                        {asterisk && <span>*</span>}
                    </label>
                </div>
            )}
            <div className="FormField" role="group">
                {options?.map((option, index) => (
                    <label key={index} className="custom-checkbox">
                        <Field
                            className="CheckboxInput"
                            type="radio"
                            name={fieldName}
                            onChange={() => handleChange(option.id)}
                            checked={meta.value === option.id}
                        />
                        {option.name}
                        <span className="checkmark"></span>
                    </label>
                ))}
            </div>
            <ErrorM name={fieldName} />
        </div>
    );
};

export default SingleSelectCheckList;
