import { Link } from '@reach/router';
import { AppPath, DashboardPath } from 'appConstants';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthStatus } from 'reducers/auth';
import { selectAccountTypePathItem } from 'reducers/componentResources';
import { selectCultureCode } from '../../reducers/language';
import { FooterLikItem, FooterLinks } from './types';

type FooterProps = {
    links: FooterLinks;
};

const Footer: React.FC<FooterProps> = ({ links }) => {
    // On mobile, links in that were in the footer on desktop are moved into the
    // more tab that is present only in the mobile navigation menu.
    const cultureCode = useSelector(selectCultureCode);
    const isLoggedIn = useSelector(selectAuthStatus) === 'signed_in';
    const accountTypePathItem = useSelector(selectAccountTypePathItem);
    return (
        <>
            <div className="Footer Desktop">
                <ul>
                    {links &&
                        links.map((l: FooterLikItem, i: number) => {
                            // Using separate paths for public and logged-in footer links to avoid
                            // flashes in navigation bar.
                            const path = `/${cultureCode}${
                                isLoggedIn
                                    ? `${accountTypePathItem}${DashboardPath.INFO}`
                                    : AppPath.INFO
                            }${l.url}`;
                            return (
                                <li key={l.id}>
                                    <Link to={path}>{l.title}</Link>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </>
    );
};

export default Footer;
