import { FormField } from '@avamae/formbuilder';
import { isObject, isArray, transform } from 'lodash';

export const toCamelCase = function <T>(pascalCase: keyof T): keyof T {
    const firstLetter = (pascalCase as string).slice(0, 1);
    const remaining = (pascalCase as string).slice(1, (pascalCase as string).length);
    return (firstLetter.toLowerCase() + remaining) as keyof T;
};

export const formatFormFieldNames = function <T>(metaData: FormField<T>[]): FormField<T>[] {
    return metaData.map((m) => ({ ...m, name: toCamelCase<T>(m.name) }));
};

export function formatFormFieldNamesToObject(
    metaData: FormField<any>[]
): Record<string, FormField<any>> {
    let rec: Record<string, FormField<any>> = {};

    metaData.forEach((meta) => {
        const name = meta.name.toString().toLowerCase();
        rec[name] = { ...meta, name };
    });

    return rec;
}

export const deepCamelCase = <T = { [k: string]: any }>(data: T) => {
    return transform<any, any>(data, (result: { [k: string]: any }, value, key) => {
        result[toCamelCase(key) as string] =
            isObject(value) && !isArray(value) ? deepCamelCase(value) : value;
    });
};
