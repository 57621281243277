import { TableInfo, useTable } from 'api';
import Button from 'components/button/Button';
import { DateRangePicker } from 'components/dateRangePicker/DateRangePicker';
import { CryptoHistoryItem, HistoryTable, ID_COLUMN } from 'components/historyTable/HistoryTable';
import { canShowLiquidateModal } from 'components/modals/LiquidateCryptoModal';
import Pagination from 'components/pagination/Pagination';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { ModalTypes, openModal, selectModalState } from 'reducers/modal';
import { useWindowDimensions } from 'helpers/useWindowDimensions';
import { TableEntryInfoPanel } from '../../components/TableEntryInfoPanel/TableEntryInfoPanel';
import styles from '../../components/TableEntryInfoPanel/TableEntryInfoPanel.module.scss';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { useGetAccountDetailsForCrypto } from 'helpers/useGetAccountDetails';
import { AccountContextProvider } from 'pages/account/AccountContext';
import { CryptoAccountsHistory } from './CryptoAccountsHistory';
import { MonthlyStatements } from 'components/monthlyStatements/MonthlyStatements';

type Props = {
    ticker: string | undefined;
    subPage: string;
};

export enum CryptoAccountSubPage {
    History = 'history',
    MONTHLY_STATEMENTS = 'monthlystatements',
}

export const CryptoAccountsPage = ({ ticker, subPage }: Props) => {
    const accountId = useGetAccountDetailsForCrypto(ticker ?? '')?.id;

    return (
        <AccountContextProvider accountId={accountId?.toString() ?? ''}>
            {subPage === CryptoAccountSubPage.History ? (
                <CryptoAccountsHistory ticker={ticker} />
            ) : subPage === CryptoAccountSubPage.MONTHLY_STATEMENTS ? (
                <MonthlyStatements accountNumber={accountId?.toString() ?? ''} />
            ) : (
                <CryptoAccountsHistory ticker={ticker} />
            )}
        </AccountContextProvider>
    );
};
