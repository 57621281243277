import { useDispatch, useSelector } from 'react-redux';
import { updateHasInfoRequest } from '../reducers/infoRequest';
import api from '../api';
import { endpoints } from '../endpoints.config';
import { TableData } from './createUseTable';
import { useEffect } from 'react';
import { selectAccountSwitchInProgress, selectAuthStatus } from '../reducers/auth';

export const useCheckInfoRequest = () => {
    const accountSwitchInProgress = useSelector(selectAccountSwitchInProgress);
    const authStatus = useSelector(selectAuthStatus);

    const dispatch = useDispatch();

    const handleUpdateHasInfoRequest = (hasRequest: boolean) => {
        dispatch(updateHasInfoRequest(hasRequest));
    };

    const checkHasInfoRequest = async () => {
        try {
            const res = await api.get<TableData>(endpoints.informationRequest.list);

            if (res.data.details) {
                handleUpdateHasInfoRequest(res.data.details.resultsCount > 0);
            } else {
                throw new Error();
            }
        } catch (error) {
            handleUpdateHasInfoRequest(false);
        }
    };

    useEffect(() => {
        if (accountSwitchInProgress || authStatus !== 'signed_in') return;
        checkHasInfoRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountSwitchInProgress, authStatus]);
};
