import { CryptoLiveData, parseLowHighString } from 'pages/crypto/CryptoPage';
import { AssetPairsDetails } from 'reducers/cryptoPrices';

export const parseSignalRPriceUpdate = (pricelist: AssetPairsDetails): CryptoLiveData[] => {
    const parsedPrices = Object.keys(pricelist).map((key) => {
        const priceDetails = pricelist[key as keyof typeof pricelist];
        const { low, high } = parseLowHighString(priceDetails.marketHighLow);
        const parsedPrice = {
            pair: key,
            buyPrice: priceDetails.buyPrice,
            sellPrice: priceDetails.sellPrice,
            name: priceDetails.name,
            ticker: key.split('/')[0],
            priceVariability: priceDetails.priceVariability,
            low24hrs: low,
            high24hrs: high,
        };
        return parsedPrice;
    });
    return parsedPrices;
};
