import { useEffect, useRef, useState } from 'react';
import Chevron from 'assets/Icon_Btn_07.png';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/ui-update/basic-arrow.svg';

interface CollapseProps {
    header: React.ReactNode;
    startExpanded?: boolean;
    hideArrow?: boolean;
    showCollapseArrow?: boolean;
    children?: React.ReactNode;
    className?: string;
}
export const Collapse: React.FC<CollapseProps> = ({
    header,
    startExpanded = false,
    hideArrow = false,
    showCollapseArrow = false,
    children,
    className,
}) => {
    const [expand, setExpand] = useState(startExpanded);
    return (
        <ManualCollapse
            header={header}
            isOpen={expand}
            setOpen={setExpand}
            hideArrow={hideArrow}
            showCollapseArrow={showCollapseArrow}
            className={className}
        >
            {children}
        </ManualCollapse>
    );
};

type ManualCollapseProps = {
    header: React.ReactNode;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    hideArrow?: boolean;
    showCollapseArrow?: boolean;
    children?: React.ReactNode;
    className?: string;
};
export const ManualCollapse: React.FC<ManualCollapseProps> = ({
    header,
    isOpen,
    setOpen,
    hideArrow = false,
    showCollapseArrow = false,
    children,
    className,
}) => {
    return (
        <div className={classNames('Collapse', className, { Expanded: isOpen })}>
            <div
                className={`CollapseHeader ${isOpen ? 'Expand' : ''}`}
                onClick={() => setOpen(!isOpen)}
            >
                {typeof header === 'string' ? <h4>{header}</h4> : <>{header}</>}
                {!hideArrow && (
                    <div style={{ display: 'flex' }}>
                        <img
                            className={`Chevron ${isOpen ? 'ChevronExpand' : ''}`}
                            src={Chevron}
                            alt="Chevron"
                        />
                    </div>
                )}
            </div>
            {isOpen && <div className="CollapseContent">{children}</div>}

            {isOpen && showCollapseArrow && (
                <button
                    type="button"
                    onClick={() => setOpen(false)}
                    title="Collapse content"
                    className="ArrowWrapper"
                >
                    <ArrowIcon />
                </button>
            )}
        </div>
    );
};
