const logger = () => {
    const isProduction =
        process.env.NODE_ENV !== 'development' &&
        !window.origin.includes('fintech-phoenix.customer');
    const log = (...params: Parameters<(typeof console)['log']>) => {
        if (isProduction) return;
        // eslint-disable-next-line no-console
        console.log(...params);
    };
    return { log };
};

export default logger();
