import { NavigateFn, useNavigate, NavigateOptions } from '@reach/router';
import React, { useCallback } from 'react';
import { useBasePath } from './useBasePath';

export const useNavigateWithBasePath = () => {
    const basePath = useBasePath();
    const navigate = useNavigate();

    const navigateWithBasePath = useCallback<NavigateFn>(
        function (to: string | number, options?: NavigateOptions<{}>): Promise<void> {
            if (typeof to === 'string') {
                return navigate(`${basePath}${to}`, options);
            }
            return navigate(to); // i'm not sure when/how this gets used by reach router - doesn't have the basepath added though
        },
        [basePath, navigate]
    );
    return navigateWithBasePath;
};
