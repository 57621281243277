/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import FormTextField from 'components/form/FormTextField';
import { useField } from 'formik';

interface Props {}

export const AnticipatedActivityPersonal: React.FC<Props> = () => {
    const [{ value: purchMonthly }] = useField('purchaseCryptoMonthlyVolume');
    const [{ value: purchAverage }] = useField('purchaseCryptoAverageAmount');
    const [{ value: purchAmountMonth }] = useField('purchaseCryptoTotalAmountPerMonth');

    const [{ value: sellMonthly }] = useField('sellingCryptoMonthlyVolume');
    const [{ value: sellAverage }] = useField('sellingCryptoAverageAmount');
    const [{ value: sellAmountMonth }] = useField('sellingCryptoTotalAmountPerMonth');

    const [, , { setValue: setMonthlyTotal }] = useField('totalMonthlyVolume');
    const [, , { setValue: setAverageTotal }] = useField('totalAverageAmount');
    const [, , { setValue: setAmountTotal }] = useField('totalMonthlyAmount');

    useEffect(() => {
        const purch = parseFloat(purchMonthly) ? parseFloat(purchMonthly) : 0;
        const sell = parseFloat(sellMonthly) ? parseFloat(sellMonthly) : 0;
        setMonthlyTotal(purch + sell);
    }, [purchMonthly, sellMonthly]);
    useEffect(() => {
        const purch = parseFloat(purchAverage) ? parseFloat(purchAverage) : 0;
        const sell = parseFloat(sellAverage) ? parseFloat(sellAverage) : 0;
        setAverageTotal(purch + sell);
    }, [purchAverage, sellAverage]);
    useEffect(() => {
        const purch = parseFloat(purchAmountMonth) ? parseFloat(purchAmountMonth) : 0;
        const sell = parseFloat(sellAmountMonth) ? parseFloat(sellAmountMonth) : 0;
        setAmountTotal(purch + sell);
    }, [purchAmountMonth, sellAmountMonth]);

    const cryptoActivityToolTip = (
        <>Please enter your best estimate of expected activity. If you are not sure, enter zero</>
    );

    return (
        <div>
            <h3>Crypto Activity</h3>
            <FormTextField
                field={'purchaseCryptoMonthlyVolume'}
                label={'Cryptocurrency OTC Desk - Purchasing Crypto - Monthly Volume'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <FormTextField
                field={'purchaseCryptoAverageAmount'}
                label={'Cryptocurrency OTC Desk - Crypto to Crypto - Average Amount (USD)'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <FormTextField
                field={'purchaseCryptoTotalAmountPerMonth'}
                label={'Cryptocurrency OTC Desk - Crypto to Crypto - Total Amount Monthly (USD)'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <FormTextField
                field={'sellingCryptoMonthlyVolume'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Monthly Volume'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <FormTextField
                field={'sellingCryptoAverageAmount'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Average Amount (USD)'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <FormTextField
                field={'sellingCryptoTotalAmountPerMonth'}
                label={'Cryptocurrency OTC Desk - Selling Crypto - Total Amount Per Month (USD)'}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
                tooltip={cryptoActivityToolTip}
            />
            <div className="Row">
                <FormTextField
                    field={'totalMonthlyVolume'}
                    label={'Total for All Monthly Volume'}
                    disabled
                    sideBySide={true}
                    asterisk={true}
                    inputMode="numeric"
                />
                <FormTextField
                    field={'totalAverageAmount'}
                    label={'Total for All Average Amount (USD)'}
                    disabled
                    sideBySide={true}
                    asterisk={true}
                    inputMode="numeric"
                />
                <FormTextField
                    field={'totalMonthlyAmount'}
                    label={'Total Amount Per Month (USD)'}
                    disabled
                    sideBySide={true}
                    asterisk={true}
                    inputMode="numeric"
                />
            </div>
            <FormTextField
                field={'btcTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - BTC'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'ethTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - ETH'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'usdcTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - USDC'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
            <FormTextField
                field={'otherCoinsTradingPercentage'}
                label={'Percentage (%) of Anticipated Trading Activity by Coin - Other Coins'}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
                inputMode="numeric"
            />
        </div>
    );
};
