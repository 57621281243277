import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSignedIn, signOutWithBackendCall } from 'reducers/auth';
import { Toast, ToastMessageReason } from './toast';

export const useForceSignout = () => {
    const signedIn = useSelector(selectSignedIn);
    const dispatch = useDispatch();
    useEffect(() => {
        if (signedIn) {
            Toast.openBlockingToast(
                'You have been signed out',
                ToastMessageReason.ERROR,
                undefined,
                5000
            );
            dispatch(signOutWithBackendCall());
        }
    }, [signedIn, dispatch]);
};
