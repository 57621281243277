import { useEffect, useState } from 'react';
import BackButton from '../helpers/BackButton';
import { BusinessVerificationStage, stageTitles } from '../businessVerificationModels';
import ExitContinueButton from '../helpers/ExitContinueButtons';
import OperatingAddress from './Components/OperatingAddress';
import CompanyRep from './Components/CompanyRep';
import StageIndicator from '../helpers/StageIndicator';
import MainActivity from './Components/MainActivity';
import CustomerAccounts from './Components/CustomerAccounts';
import MainBusinessPartners from './Components/BusinessPartners/MainBusinessPartners';
import Funds from './Components/Funds/Funds';
import { useFormikContext } from 'formik';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import useValidate from '../helpers/touchAllFields';
import useScrollToError from '../helpers/useScrollToError';
import { ErrorM } from 'components/form/ErrorM';
import { DocumentRejection } from '../BusinessVerification';
import AnticipatedActivity from './Components/AnticipatedActivity';
import { State } from '../components/significantParties/SignificantParties';

export type Type = {
    id: number;
    name: string;
};

interface DropdwonData {
    countries: { isoCode: string; countryName: string; states: State[] | null }[];
    sourceFundTypes: Type[];
    mainActivityTypes: Type[];
    politicallyExposedPersonTypes: Type[];
    businessIdentityDocumentTypes: Type[];
}
interface Props {
    values: any;
    submitError: boolean;
    submitErrorField: string;
    setSubmitError: React.SetStateAction<any>;
    rejectedDocuments: DocumentRejection | null | undefined;
    currentStage: BusinessVerificationStage;
}
const CorporateApp: React.FC<Props> = ({
    values,
    submitError,
    submitErrorField,
    setSubmitError,
    rejectedDocuments,
    currentStage,
}) => {
    const { isSubmitting, isValidating, setFieldTouched } = useFormikContext();
    const disable = isSubmitting || isValidating;
    const [availableCountries, setAvailableCountries] = useState<
        (SelectOption & { states: SelectOption[] | null })[]
    >([]);
    const [mainActivityTypes, setMainActivityTypes] = useState<Type[]>([]);
    const [politicallyExposedPersonTypes, setPoliticallyExposedPersonTypes] = useState<Type[]>([]);
    const [personalIdentityDocumentTypes, setPersonalIdentityDocumentTypes] = useState<Type[]>([]);
    const [sourceFundTypes, setSourceFundTypes] = useState<Type[]>([]);
    const [isExpanded, setIsExapnded] = useState<boolean>(false);

    const getDropdownData = async () => {
        try {
            const res = await instance.get<ApiResponse<DropdwonData>>(
                endpoints.businessverificationmodule.getDropdownData
            );
            if (res.data.status === '1') {
                setAvailableCountries(
                    res.data.details.countries.map((country) => ({
                        value: country.isoCode,
                        label: country.countryName,
                        states: country.states
                            ? country.states.map((state) => ({
                                  label: state.stateProvinceName,
                                  value: state.ansiCode,
                              }))
                            : null,
                    }))
                );
                setPoliticallyExposedPersonTypes(
                    res.data.details.politicallyExposedPersonTypes?.map((type) => ({
                        id: type.id,
                        name: type.name,
                    }))
                );
                setMainActivityTypes(
                    res.data.details.mainActivityTypes?.map((type) => ({
                        id: type.id,
                        name: type.name,
                    }))
                );
                setSourceFundTypes(
                    res.data.details.sourceFundTypes?.map((type) => ({
                        id: type.id,
                        name: type.name,
                    }))
                );
                setPersonalIdentityDocumentTypes(
                    res.data.details.businessIdentityDocumentTypes?.map((type) => ({
                        id: type.id,
                        name: type.name,
                    }))
                );
            } else {
                setAvailableCountries([]);
            }
        } catch (err) {
            Toast.openGenericErrorToast();
            setAvailableCountries([]);
        }
    };

    useEffect(() => {
        getDropdownData();
    }, []);
    useValidate(setFieldTouched, { corporate: values.corporate });
    let expandAll = useScrollToError(
        submitError,
        submitErrorField,
        isExpanded,
        setIsExapnded,
        setSubmitError
    );

    return (
        <>
            <StageIndicator currentStage={currentStage} />
            <div className="CorporateApp">
                <BackButton disable={disable} currentStage={currentStage} />
                <h1>{stageTitles[currentStage]}</h1>
                <ErrorM name="corporate" />
                {currentStage === BusinessVerificationStage.OperatingAddress && (
                    <OperatingAddress
                        availableCountries={availableCountries}
                        expandAll={expandAll}
                    />
                )}
                {currentStage === BusinessVerificationStage.CompanyRep && (
                    <CompanyRep
                        availableCountries={availableCountries}
                        politicallyExposedTypes={politicallyExposedPersonTypes}
                        personalIdTypes={personalIdentityDocumentTypes}
                        values={values}
                        expandAll={expandAll}
                    />
                )}
                {currentStage === BusinessVerificationStage.MainBusinessPartners && (
                    <MainBusinessPartners values={values} disable={disable} expandAll={expandAll} />
                )}
                {currentStage === BusinessVerificationStage.MainActivity && (
                    <MainActivity mainActivityTypes={mainActivityTypes} expandAll={expandAll} />
                )}
                {currentStage === BusinessVerificationStage.Funding && (
                    <Funds
                        availableCountries={availableCountries}
                        sourceFundTypes={sourceFundTypes}
                        values={values}
                        disable={disable}
                        expandAll={expandAll}
                        rejectedDocuments={rejectedDocuments}
                    />
                )}
                {currentStage === BusinessVerificationStage.OtherBankAccounts && (
                    <CustomerAccounts expandAll={expandAll} setIsExpanded={setIsExapnded} />
                )}
                {currentStage === BusinessVerificationStage.AnticipatedActivity && (
                    <AnticipatedActivity />
                )}
                <ExitContinueButton disabled={disable} />
            </div>
        </>
    );
};
export default CorporateApp;
