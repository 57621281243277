import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import instance from 'api';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import Page from 'components/page/Page';
import { getErrorMessage } from 'errors';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin, selectAuthMessage, selectSignedIn } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export type RedirectState = { redirectUrl: string };

export const AutoLogin: React.FC<RouteComponentProps> = () => {
    const { userInfo } = useSelector(selectComponentResources);
    const cultureCode = useSelector(selectCultureCode);
    const errorMessages = useSelector(selectAuthMessage);
    const signedIn = useSelector(selectSignedIn);
    const dispatch = useDispatch();
    const { search } = useLocation();

    const queryParams = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return {
            token: searchParams.get('token') ?? '',
            redirectUrl: searchParams.get('redirectUrl') ?? '',
        };
    }, [search]);

    useEffect(() => {
        if (!queryParams.token) return;
        dispatch(autoLogin({ autoLoginToken: encodeURIComponent(queryParams.token) }));
    }, [queryParams, dispatch]);

    useEffect(() => {
        if (errorMessages && errorMessages.length > 0) {
            Toast.openToastMessage(
                getErrorMessage(errorMessages[0].messageCode),
                ToastMessageReason.ERROR
            );
            const redirectState: RedirectState = { redirectUrl: queryParams.redirectUrl };
            navigate('/', { state: redirectState });
        }
    });

    useEffect(() => {
        if (signedIn && userInfo && userInfo.accountType) {
            const accountTypePathItem =
                userInfo.accountType === 'Personal' ? AppPath.PERSONAL : AppPath.BUSINESS;
            if (queryParams?.redirectUrl) {
                navigate(`/${cultureCode}${accountTypePathItem}${queryParams.redirectUrl}`);
            } else {
                navigate(
                    `/${cultureCode}${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
                );
            }
        }
    }, [signedIn, userInfo, cultureCode, queryParams]);

    return <Page isPublic />;
};
