import React from 'react';
import api from 'api';
import { usePrevious } from '@avamae/hooks';
import { FormField } from '@avamae/formbuilder';
import isEqual from 'react-fast-compare';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import _ from 'lodash';

type State = {
    additionalData: Record<string, any>;
    depsPending: boolean;
    noDepsPending: boolean;
    error: null | Error;
};

/*
 * Key:
 *    - plain : Values which depend on local values.
 *    - nodeps : Values which depend on a remote resource.
 *    - deps : Values which depend on both.
 */

type Action =
    | { type: 'started_nodeps' }
    | { type: 'started_deps' }
    | { type: 'set_deps_error'; payload: Error }
    | { type: 'set_nodeps_error'; payload: Error }
    | { type: 'set_plain_values'; payload: Record<string, any> }
    | { type: 'set_nodeps_values'; payload: Record<string, any> }
    | { type: 'set_deps_values'; payload: Record<string, any> };

function createInitialValues<T>(metas: FormField<T>[]): State {
    return {
        additionalData: getDefaultValues(metas),
        depsPending: false,
        noDepsPending: false,
        error: null,
    };
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'started_deps':
            return { ...state, depsPending: true };
        case 'started_nodeps':
            return { ...state, noDepsPending: true };
        case 'set_plain_values':
            return {
                ...state,
                additionalData: { ...state.additionalData, ...action.payload },
            };
        case 'set_deps_values':
            return {
                ...state,
                depsPending: false,
                additionalData: { ...state.additionalData, ...action.payload },
            };
        case 'set_nodeps_values':
            return {
                ...state,
                noDepsPending: false,
                additionalData: { ...state.additionalData, ...action.payload },
            };
        case 'set_deps_error':
            throw action.payload;
        // return {
        //   ...state,
        //   depsPending: false,
        //   error: action.payload,
        // };
        case 'set_nodeps_error':
            throw action.payload;
        // return {
        //   ...state,
        //   noDepsPending: false,
        //   error: action.payload,
        // };
        default:
            throw new Error(`Action type ${(action as any).type} not supported`);
    }
}

/**
 * Takes form metadata and values, and produces an additionalData object
 * which can be passed to FormBuilder.
 */
export function useAdditionalData<T>(
    data: ApiResponse<T>,
    values: Record<string, string | undefined>
) {
    const metadata = data.metadata as FormField<T>[];
    const [store, dispatch] = React.useReducer(reducer, metadata, createInitialValues);

    const previousValues = usePrevious(values);

    // Some fields are dependent directly on values.
    React.useEffect(() => {
        let payload: Record<string, any> = {};

        metadata.forEach((meta) => {
            if (!meta.bRemoteDataSource) {
                const name = toCamelCase(meta.name as string);
                const dataSource = toCamelCase(meta.dataSource as string);
                if (dataSource) {
                    payload[name] = _.get(data.details, dataSource);
                } else {
                    payload[name] = values[name];
                }
            }
        });

        dispatch({ type: 'set_plain_values', payload });
    }, [metadata, values, data]);

    // Some fields are dependent on a remote resource.
    React.useEffect(() => {
        // Set this to false on cleanup, so we know to throw away the promise
        // if it comes back after the component unmounts or the effect has rerun.
        let current = true;
        const fetches: Promise<{ name: string; value: any }>[] = [];

        // Get every field that requires a fetch and has no deps.
        // Make the api call for each one and push them into the "fetches" array.
        metadata.forEach((meta) => {
            if (meta.bRemoteDataSource) {
                const req = api.get(meta.dataSource!).then((res) => ({
                    name: toCamelCase(meta.name as string),
                    value: res.data.details,
                }));
                fetches.push(req);
            }
        });

        if (fetches.length > 0) {
            dispatch({ type: 'started_nodeps' });

            // When all the requests have come back, set the new values.
            Promise.all(fetches)
                .then((res) => {
                    if (current) {
                        let newData: Record<string, any> = {};
                        res.forEach((r) => {
                            newData[r.name as string] = r.value;
                        });

                        dispatch({ type: 'set_nodeps_values', payload: newData });
                    }
                })
                .catch((err) => {
                    dispatch({ type: 'set_nodeps_error', payload: err });
                });
        }

        // Cleanup
        return () => {
            current = false;
        };
    }, [metadata]);

    // And some fields are dependent on both.
    React.useEffect(() => {
        // Set this to false on cleanup, so we know to throw away the promise
        // if it comes back after the component unmounts or the effect has rerun.
        let current = true;

        // Array of http requests that we'll make.
        const fetches: Promise<{ name: string; value: any }>[] = [];

        if (fetches.length > 0) {
            dispatch({ type: 'started_deps' });

            // Wait for all the promises to complete, then set the
            // new values all at once.
            Promise.all(fetches)
                .then((res) => {
                    if (current) {
                        let newData: Record<string, any> = {};
                        res.forEach((r) => {
                            newData[r.name as string] = r.value;
                        });

                        dispatch({ type: 'set_deps_values', payload: newData });
                    }
                })
                .catch((err) => {
                    dispatch({ type: 'set_deps_error', payload: err });
                });
        }

        // Cleanup
        return () => {
            current = false;
        };
    }, [values, previousValues, metadata]);

    const pending = store.depsPending || store.noDepsPending;
    const { additionalData, error } = store;

    const returnedBundle = React.useMemo(
        () => ({
            additionalData,
            pending,
            error,
        }),
        [additionalData, pending, error]
    );

    return returnedBundle;
}

/* Helpers */

/**
 * Converts PascalCase strings to camelCase.
 *
 * In JavaScriptland we do camelCase, in C#land we do PascalCase.
 */
function toCamelCase(val: string) {
    if (!val) return val;
    if (val.length === 0) return val;
    return val[0].toLowerCase() + val.slice(1);
}

/**
 * Gets the correct default value for a field, based on which type
 * it wants. This logic should probably be moved into the field components.
 */
function getDefaultValues<T>(metas: FormField<T>[]) {
    const values: Record<string, any> = {};
    for (const meta of metas) {
        if (meta.dataSource) {
            let value = undefined;
            switch (meta.type as any) {
                case 'SingleSelect':
                case 'MultiSelect':
                    value = [];
                    break;
            }
            values[toCamelCase(meta.name as string)] = value;
        }
    }

    return values;
}
