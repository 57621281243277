import { useSelector } from 'react-redux';
import { selectCultureCode } from '../../reducers/language';
import { formatPriceWithLocale } from '../../helpers/formatPriceWithCommas';

export type BalanceDisplayAccountInfo = {
    balance: number;
    availableBalance: number;
    symbol: string;
};

type Props = {
    accountInfo: BalanceDisplayAccountInfo | null;
};

export const BalanceDisplay: React.FC<Props> = ({ accountInfo }) => {
    const cultureCode = useSelector(selectCultureCode);

    if (!accountInfo) return null;
    return (
        <dl className="BalanceDisplay">
            <div className="BalanceDisplayItem">
                <dd>
                    {accountInfo.symbol ? `${accountInfo.symbol} ` : ''}
                    {formatPriceWithLocale(
                        accountInfo?.availableBalance,
                        cultureCode ?? 'en-GB',
                        undefined,
                        18
                    )}
                </dd>
                <dt>Available</dt>
            </div>
            <div className="BalanceDisplayItem">
                <dd>
                    {accountInfo.symbol ? `${accountInfo.symbol} ` : ''}
                    {formatPriceWithLocale(
                        accountInfo?.balance,
                        cultureCode ?? 'en-GB',
                        undefined,
                        18
                    )}
                </dd>
                <dt>Total</dt>
            </div>
        </dl>
    );
};
