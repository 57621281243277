type customWindow = typeof window & { env: { BASE_URL: string } };
const envUrl = (window as customWindow).env.BASE_URL;
export const BASE_URL = envUrl;
export const isProduction = BASE_URL === 'https://customer-api.ibanera.com';

export const STOPLIGHT_BASE_URL =
    'https://stoplight.io/mocks/avamae/fintech-cloud-web-phoenix-customer-v1';
// 'https://avamae.stoplight.io/mocks/avamae/fintech-cloud-web-phoenix-customer-v1';

export const endpoints = {
    auth: {
        login: `${BASE_URL}/api/v1/publicrole/authmodule/login`,
        tfaLogin: `${BASE_URL}/api/v1/publicrole/authmodule/tfalogin`,
        requestSmsTfaCode: `${BASE_URL}/api/v1/publicrole/authmodule/requestsmstfacode`,
        requestTotpCode: `${BASE_URL}/api/v1/publicrole/authmodule/requesttotpqrcode`,
        verifyEmailAddress: `${BASE_URL}/api/v1/publicrole/authmodule/verifyemailaddress`,
        enableTfa: `${BASE_URL}/api/v1/publicrole/authmodule/enabletfa`,
        refreshAccessToken: `${BASE_URL}/api/v1/publicrole/authmodule/refresh`,
        autoLogin: `${BASE_URL}/api/v1/publicrole/authmodule/autologin`,
        reactivateAccount: `${BASE_URL}/api/v1/publicrole/authmodule/reactivate`,
    },
    errorCodes: {
        codes: `${BASE_URL}/api/v1/publicrole/countryrestrictedactions/geterrormessages`,
    },
    licenseesmodule: {
        licenseesbranding: `${BASE_URL}/api/v1/publicrole/licenseesmodule/licenseesbranding`,
    },
    languagemodule: {
        languages: `${BASE_URL}/api/v1/publicrole/languagesmodule/languages`,
        languageresources: `${BASE_URL}/api/v1/publicrole/languagesmodule/languageresouces`,
        setlanguage: `${BASE_URL}/api/v1/publicrole/languagesmodule/setlanguage`,
    },
    navigationmodule: {
        footerlinks: `${BASE_URL}/api/v1/publicrole/navigationmodule/footerlinks`,
        customerNavigationLinks: `${BASE_URL}/api/v1/customerrole/navigationmodule/customernavigationlinks`,
    },
    registration: {
        customerUser: `${BASE_URL}/api/v2/publicrole/registrationmodule/registercustomeruser`,
        validateStepOne: `${BASE_URL}/api/v1/publicrole/registrationmodule/validatebusinesscustomerregistrationstep1`,
        availableCurrencies: `${BASE_URL}/api/v1/publicrole/registrationmodule/getavailablecurrencies`,
        availableCountries: `${BASE_URL}/api/v1/publicrole/registrationmodule/getavailablecountries`,
        businessRegistration: `${BASE_URL}/api/v2/publicrole/registrationmodule/registerbusinessaccount`,
        businessRegistrationDetails: `${BASE_URL}/api/v1/publicrole/registrationmodule/getbusinessregistrationdetails`,
        businessRegistrationDetailsV2: `${BASE_URL}/api/v2/publicrole/registrationmodule/getregistrationdetails`,
    },
    profilemodule: {
        userinfo: `${BASE_URL}/api/v1/customerrole/profilemodule/userinfo`,
        switchToLinkedAccount: `${BASE_URL}/api/v1/customerrole/profilemodule/switchtolinkedaccount`,
        signout: `${BASE_URL}/api/v1/customerrole/profilemodule/logout`,
        availableBusinessCountries: `${BASE_URL}/api/v1/customerrole/profilemodule/getavailablecountriesforbusinessregistration`,
        registerlinkedcustomer: `${BASE_URL}/api/v1/customerrole/profilemodule/registerbusinessaccount`,
        usersecurityinfo: `${BASE_URL}/api/v1/customerrole/profilemodule/usersecurityinfo`,
        requestSmsTfaCode: `${BASE_URL}/api/v1/customerrole/profilemodule/requestsmstfacode`,
        updatePassword: `${BASE_URL}/api/v1/customerrole/profilemodule/updatepassword`,
        updatePhoneNumber: `${BASE_URL}/api/v1/customerrole/profilemodule/updatephonenumber`,
        updatePhoneNumberConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updatephonenumberconfirm`,
        updateEmailAddress: `${BASE_URL}/api/v1/customerrole/profilemodule/updateemailaddress`,
        updateEmailAddressConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updateemailaddressconfirm`,
        disableAccount: `${BASE_URL}/api/v1/customerrole/profilemodule/disableaccount`,
        updateAppTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/updateapptfa`,
        updateTfaConfirm: `${BASE_URL}/api/v1/customerrole/profilemodule/updatetfaconfirm`,
        flipSmsTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/flipsmstfa`,
        flipAppTfa: `${BASE_URL}/api/v1/customerrole/profilemodule/flipapptfa`,
        userProfileDetails: `${BASE_URL}/api/v1/customerrole/profilemodule/userprofiledetails`, //verification details
        linkedAccountsList: `${BASE_URL}/api/v1/customerrole/profilemodule/linkedaccountslist`,
    },
    cmsmodule: {
        pagecontent: `${BASE_URL}/api/v1/publicrole/cmsmodule/pagecontent`,
    },
    forgottenpasswordmodule: {
        resetpasswordrequest: `${BASE_URL}/api/v1/publicrole/forgottenpasswordmodule/requestpasswordreset`,
        resetpassword: `${BASE_URL}/api/v1/publicrole/forgottenpasswordmodule/resetpassword`,
    },
    cryptosmodule: {
        marketList: `${BASE_URL}/api/v1/customerrole/cryptomodule/marketlist`,
        portfolioList: `${BASE_URL}/api/v1/customerrole/cryptomodule/portfoliolist`,
        historyList: `${BASE_URL}/api/v1/customerrole/cryptomodule/historylist`,
        getAssetDetails: `${BASE_URL}/api/v1/customerrole/cryptomodule/getassetdetails`,
        getFiatAssetAccounts: `${BASE_URL}/api/v1/customerrole/cryptomodule/getfiatassetaccounts`,
        reviewDusdBuy: `${BASE_URL}/api/v1/customerrole/cryptomodule/reviewdusdbuy`,
        buyDusd: `${BASE_URL}/api/v1/customerrole/cryptomodule/buydusd`,
        reviewOrder: `${BASE_URL}/api/v1/customerrole/cryptomodule/revieworder`,
        reviewOrderV2: `${BASE_URL}/api/v2/customerrole/cryptomodule/revieworder`,
        buyAsset: `${BASE_URL}/api/v1/customerrole/cryptomodule/buyasset`,
        sellAsset: `${BASE_URL}/api/v2/customerrole/cryptomodule/sellasset`,
        getAssetAccounts: `${BASE_URL}/api/v1/customerrole/cryptomodule/getassetaccounts`, // 'http://localhost:8080/getassetaccounts',
        getDepositAddress: `${BASE_URL}/api/v1/customerrole/cryptomodule/getdepositaddress`,
        withdraw: `${BASE_URL}/api/v1/customerrole/cryptomodule/withdraw`,
        withdrawNewWallet: `${BASE_URL}/api/v1/customerrole/cryptomodule/withdrawnewwallet`,
        withdrawSavedWallet: `${BASE_URL}/api/v1/customerrole/cryptomodule/withdrawsavedwallet`,
        deleteSavedWallet: `${BASE_URL}/api/v1/customerrole/cryptomodule/deleteassetwalletalias`,
        getPayees: `${BASE_URL}/api/v1/customerrole/cryptomodule/getavailablepayees`, // 'http://localhost:8080/getavailablepayees',
        reviewByScreeningId: `${BASE_URL}/api/v2/customerrole/cryptomodule/revieworderbyscreeningid`,
        cancelByScreeningId: `${BASE_URL}/api/v1/customerrole/cryptomodule/cancelorder`,
        sellAssetByScreeningId: `${BASE_URL}/api/v2/customerrole/cryptomodule/sellassetbyscreeningid`,
        feeDetails: `${BASE_URL}/api/v1/customerrole/cryptomodule/transfer-fee-details`,
    },
    stablecoinmodule: {
        portfolioList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/portfoliolist`,
        getFiatAssetAccounts: `${BASE_URL}/api/v1/customerrole/cryptomodule/getfiatassetaccounts`,
        reviewDusdBuy: `${BASE_URL}/api/v1/customerrole/cryptomodule/reviewdusdbuy`,
        buyDusd: `${BASE_URL}/api/v1/customerrole/cryptomodule/buydusd`,
        historyList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/historylist`,
        marketList: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/marketlist`,
        getAssetDetails: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/getassetdetails`,
        reviewOrder: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/revieworder`,
        reviewOrderV2: `${BASE_URL}/api/v2/customerrole/cryptomodule/revieworder`,
        buyAsset: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/buyasset`,
        sellAsset: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/sellasset`,
        getAssetAccounts: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/getassetaccounts`,
        getDepositAddress: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/getdepositaddress`,
        withdraw: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/withdraw`,
        withdrawNewWallet: ``,
        withdrawSavedWallet: ``,
        deleteSavedWallet: ``,
        getPayees: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/getavailablepayees`, //'http://localhost:8080/getavailablepayees',
        reviewByScreeningId: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/revieworderbyscreeningid`,
        cancelByScreeningId: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/cancelorder`,
        sellAssetByScreeningId: `${BASE_URL}/api/v1/customerrole/stablecoinmodule/sellassetbyscreeningid`,
        feeDetails: ``,
    },
    exchangemodule: {
        marketList: `${BASE_URL}/api/v1/customerrole/exchangemodule/marketlist`,
        portfolioList: `${BASE_URL}/api/v1/customerrole/exchangemodule/portfoliolist`,
        historyList: `${BASE_URL}/api/v1/customerrole/exchangemodule/historylist`,
    },
    accounts: {
        accountTransactionList: `${BASE_URL}/api/v1/customerrole/accountsmodule/getaccounttransactionlist`,
        transactionInfo: `${BASE_URL}/api/v2/customerrole/accountsmodule/transaction-details`,
        accountTransactionCsv: `${BASE_URL}/api/v1/customerrole/accountsmodule/getaccounttransactionlistcsv`,
        transactionDetails: `${BASE_URL}/api/v1/customerrole/accountsmodule/gettransactiondetails`,
        noteTransaction: `${BASE_URL}/api/v1/customerrole/accountsmodule/notetransaction`,
        getDepositIFrame: `${BASE_URL}/api/v1/customerrole/accountsmodule/getdepositiframe`,
        getPaymentCards: `${BASE_URL}/api/v1/customerrole/accountsmodule/getpaymentcards`,
        DeletePaymentCards: `${BASE_URL}/api/v1/customerrole/accountsmodule/deletepaymentcards`,
        depositWithSavedCard: `${BASE_URL}/api/v1/customerrole/accountsmodule/depositwithsavedcard`,
        addAccount: `${BASE_URL}/api/v1/customerrole/accountsmodule/addassetaccount`,
        getPayeeList: `${BASE_URL}/api/v1/customerrole/accountsmodule/getpayeelist`,
        payeeDetails: `${BASE_URL}/api/v1/customerrole/accountsmodule/payeedetails`,
        deletePayee: `${BASE_URL}/api/v1/customerrole/accountsmodule/deletepayee`,
        createExternalTransfer: `${BASE_URL}/api/v1/customerrole/accountsmodule/createexternaltransfer`,
        getInternalTransferAccounts: `${BASE_URL}/api/v1/customerrole/accountsmodule/getinternaltransferaccounts`,
        createInternalTransfer: `${BASE_URL}/api/v1/customerrole/accountsmodule/createinternaltransfer`,
        lookupAddress: `${BASE_URL}/api/v1/customerrole/accountsmodule/search-address`,
        createPayee: `${BASE_URL}/api/v1/customerrole/accountsmodule/createpayee`,
        editPayee: `${BASE_URL}/api/v1/customerrole/accountsmodule/editpayee`,
        createLinkToken: `${BASE_URL}/api/v1/customerrole/accountsmodule/createlinktoken`,
        exchangePublicToken: `${BASE_URL}/api/v1/customerrole/accountsmodule/exchangepublictoken`,
        getPullFundsDetails: `${BASE_URL}/api/v1/customerrole/accountsmodule/getpullfundsdetails`,
        pullFunds: `${BASE_URL}/api/v1/customerrole/accountsmodule/pullfunds`,
        availablePayeeCountries: `${BASE_URL}/api/v1/customerrole/accountsmodule/getavailablepayeecountries`,
        addCardPayee: `${BASE_URL}/api/v1/customerrole/accountsmodule/addcardpayee`,
        getPurposeCodes: `${BASE_URL}/api/v1/customerrole/accountsmodule/purpose-codes`,
        feeDetails: `${BASE_URL}/api/v1/customerrole/accountsmodule/transfer-details`,
        reverseInboundTransfer: `${BASE_URL}/api/v1/customerrole/accountsmodule/reverse-inbound-transfer`,
        bulkTransfer: `${BASE_URL}/api/v1/customerrole/accountsmodule/bulk-transfer`,
        transferTypeOptions: `${BASE_URL}/api/v1/customerrole/accountsmodule/transfer-types`,
        monthlyStatementSettings: `${BASE_URL}/api/v1/customerrole/accountsmodule/monthlystatementsettings`,
        downloadMonthlyStatement: `${BASE_URL}/api/v1/customerrole/accountsmodule/getmonthlystatementpdf`,
        cancelTransaction: `${BASE_URL}/api/v1/customerrole/transactioncancellationmodule/cancel`,
    },
    cards: {
        addCard: `${BASE_URL}/api/v1/customerrole/cardsmodule/addcard`,
        cardTransactionList: `${BASE_URL}/api/v1/customerrole/cardsmodule/getcardtransactionlist`,
        activateCard: `${BASE_URL}/api/v1/customerrole/cardsmodule/activatecard`,
        deactivateCard: `${BASE_URL}/api/v1/customerrole/cardsmodule/deactivatecard`,
        changeCardPin: `${BASE_URL}/api/v1/customerrole/cardsmodule/changecardpin`,
        reorderCard: `${BASE_URL}/api/v1/customerrole/cardsmodule/reordercard`,
        getTopUpAccounts: `${BASE_URL}/api/v1/customerrole/cardsmodule/getaccountsforcardtopup`,
        topUpCard: `${BASE_URL}/api/v1/customerrole/cardsmodule/topupcard`,
        cardDetails: `${BASE_URL}/api/v1/customerrole/cardsmodule/getcarddetails`,
        protectedCardDetails: `${BASE_URL}/api/v1/customerrole/cardsmodule/card-protected-details`,
    },
    notificationsmodule: {
        userStack: `${BASE_URL}/api/notificationsmodule/notifications/userstack`,
        acknowledgeSingle: `${BASE_URL}/api/notificationsmodule/notifications/acknowledgesingle`,
        getUserPreferences: `${BASE_URL}/api/notificationsmodule/userpreferences/getforuser`,
        updateSinglePreference: `${BASE_URL}/api/notificationsmodule/userpreferences/updatesingle`,
        signalR: `${BASE_URL}/nm-hub`,
    },
    jumiomodule: {
        verifyIdentity: `${BASE_URL}/api/v1/customerrole/jumiomodule/verifyidentity`,
        verificationStatus: `${BASE_URL}/api/v1/customerrole/jumiomodule/verificationstatus`,
        idIframeComplete: `${BASE_URL}/api/v1/customerrole/jumiomodule/idsubmit`,
        documentIframeComplete: `${BASE_URL}/api/v1/customerrole/jumiomodule/addressdocumentsubmit`,
        requestManualVerification: `${BASE_URL}/api/v1/customerrole/jumiomodule/requestmanualverification`,
        getExternalJumioLink: `${BASE_URL}/api/v1/publicrole/significantpartiesjumiomodule/verificationstatus`,
    },
    personalVerification: {
        dropdownOptions: `${BASE_URL}/api/v1/customerrole/profilemodule/getdropdowndata`,
        personalVerification: `${BASE_URL}/api/v1/customerrole/profilemodule/submitverificationform`,
    },
    businessverificationmodule: {
        getFormData: `${BASE_URL}/api/v1/customerrole/businessmodule/getverificationform`,
        saveFormData: `${BASE_URL}/api/v1/customerrole/businessmodule/saveverificationform`,
        backForm: `${BASE_URL}/api/v1/customerrole/businessmodule/backverification`,
        submitForm: `${BASE_URL}/api/v1/customerrole/businessmodule/submitverificationform`,
        submitTerms: `${BASE_URL}/api/v1/customerrole/businessmodule/accepttcs`,
        verificationStatus: `${BASE_URL}/api/v1/customerrole/businessmodule/getbusinessverificationstatus`,
        getDocumentList: `${BASE_URL}/api/v1/customerrole/businessmodule/documentlist`,
        uploadDocument: `${BASE_URL}/api/v1/customerrole/businessmodule/uploaddocument`,
        getDropdownData: `${BASE_URL}/api/v1/customerrole/businessmodule/getdropdowndata`,
        uploadFormDocument: `${BASE_URL}/api/v1/customerrole/businessmodule/uploadformdocument`,
        deleteFormDocument: `${BASE_URL}/api/v1/customerrole/businessmodule/deleteformdocument`,
    },
    businessVerificationV2Module: {
        getDropdownData: `${BASE_URL}/api/v2/customerrole/businessmodule/getdropdowndata`,
        verificationStatus: `${BASE_URL}/api/v2/customerrole/businessmodule/getbusinessverificationstatus`,
        getFormData: `${BASE_URL}/api/v2/customerrole/businessmodule/getverificationform`,
        saveFormData: `${BASE_URL}/api/v2/customerrole/businessmodule/saveverificationform`,
        backForm: `${BASE_URL}/api/v2/customerrole/businessmodule/backverification`,
        submitForm: `${BASE_URL}/api/v2/customerrole/businessmodule/submitverificationform`,
        getDocumentList: `${BASE_URL}/api/v2/customerrole/businessmodule/documentlist`,
        uploadDocument: `${BASE_URL}/api/v2/customerrole/businessmodule/uploaddocument`,
        uploadFormDocument: `${BASE_URL}/api/v2/customerrole/businessmodule/uploadformdocument`,
        deleteFormDocument: `${BASE_URL}/api/v2/customerrole/businessmodule/deleteformdocument`,
    },
    businessVerificationV3Module: {
        getDropdownData: `${BASE_URL}/api/v3/customerrole/businessmodule/getdropdowndata`,
        verificationStatus: `${BASE_URL}/api/v3/customerrole/businessmodule/getbusinessverificationstatus`,
        getFormData: `${BASE_URL}/api/v3/customerrole/businessmodule/getverificationform`,
        saveFormData: `${BASE_URL}/api/v3/customerrole/businessmodule/saveverificationform`,
        backForm: `${BASE_URL}/api/v3/customerrole/businessmodule/backverification`,
        submitForm: `${BASE_URL}/api/v3/customerrole/businessmodule/submitverificationform`,
        getDocumentList: `${BASE_URL}/api/v3/customerrole/businessmodule/documentlist`,
        uploadDocument: `${BASE_URL}/api/v3/customerrole/businessmodule/uploaddocument`,
        uploadFormDocument: `${BASE_URL}/api/v3/customerrole/businessmodule/uploadformdocument`,
        deleteFormDocument: `${BASE_URL}/api/v3/customerrole/businessmodule/deleteformdocument`,
    },
    quickCryptoModule: {
        getPaymentIframe: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getquickbuyiframe`,
        buyWithSavedCard: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/quickbuywithsavedcard`,
        getAssetDetails: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getassetdetails`,
        getPaymentCards: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getpaymentcards`,
        init: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/init`, //'https://stoplight.io/mocks/avamae/fintech-cloud-web-phoenix-customer-v1/4275741/quickcryptomodule/init',
        submitExchangeOptions: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/newcardform`,
        submitNewCardForm: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/submitnewcardform`,
        getAvailableCountries: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getavailablecountries`,
        getAmount: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getamount`,
        getPrice: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getprice`,
        getPaymentStatus: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/paymentstatus`,
        applepayValidate: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/applepay-validate`,
        submitApplePay: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/submitapplepayform`,
        submitGooglePay: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/submitgooglepayform`,

        getSavedPaymentCards: `${BASE_URL}/api/v1/customerrole/quickcryptomodule/getpaymentcards`,
        // 'https://stoplight.io/mocks/avamae/fintech-cloud-web-phoenix-customer-v1/4275741/quickcryptomodule/getpaymentcards',
    },
    dropdownModule: {
        getAvailableCountries: `${BASE_URL}/api/v1/publicrole/dropdownmenus/getavailablecountries`,
    },
    pricesModule: {
        getAssetPrices: `${BASE_URL}/api/v1/customerrole/pricesmodule/getassetprices`,
        getPrice: `${BASE_URL}/api/v1/customerrole/pricesmodule/getprice`,
        getAmount: `${BASE_URL}/api/v1/customerrole/pricesmodule/getamount`,
        getPricev2: `${BASE_URL}/api/v2/customerrole/pricesmodule/getprice`,
        getAmountv2: `${BASE_URL}/api/v2/customerrole/pricesmodule/getamount`,
    },
    businessModule: {
        documentList: `${BASE_URL}/api/v1/customerrole/businessmodule/documentlist`,
        uploadDocument: `${BASE_URL}/api/v1/customerrole/businessmodule/uploaddocument`,
    },
    manageBusinessUsersModule: {
        listusers: `${BASE_URL}/api/v1/customerrole/businessusersmodule/listusers`, //`${STOPLIGHT_BASE_URL}/4275741/businessusersmodule/listusers`,
        createuser: `${BASE_URL}/api/v1/customerrole/businessusersmodule/createuser`, //`${STOPLIGHT_BASE_URL}/4275741/businessusersmodule/createuser`,
        viewuser: `${BASE_URL}/api/v1/customerrole/businessusersmodule/viewuser`, //`${STOPLIGHT_BASE_URL}/4275741/businessusersmodule/viewuser`,
        updateuser: `${BASE_URL}/api/v1/customerrole/businessusersmodule/edituser`, //`${STOPLIGHT_BASE_URL}/4275741/businessusersmodule/updateuser`,
        deleteusers: `${BASE_URL}/api/v1/customerrole/businessusersmodule/deleteuser`, // `${STOPLIGHT_BASE_URL}/4275741/businessusersmodule/deleteusers`,
    },
    cardManagementModule: {
        listcards: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/listcards`,
        listusers: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/list`,
        listcardrequests: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/listrequested`,
        activateCard: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/activate`,
        deactivateCard: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/deactivate`,
        approveCardRequest: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/approve`,
        viewcard: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/viewcard`,
        viewuser: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardusersmodule/viewcarduser`,
        deletecards: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/deletecards`,
        deleteusers: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardusersmodule/deletecardusers`,

        viewlistcards: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/viewlistcards`,
        viewusercardinlistcards: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/viewusercardinlistcards`,

        addcard: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/create`,
        adduser: `${BASE_URL}/api/v1/customerrole/manageesmodule/create`,
        updateuser: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardusersmodule/updatecarduser`,
        topupcard: `${STOPLIGHT_BASE_URL}/4275741/cardmanagementcardsmodule/topupcard`,
        cardTransactions: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/transactions`,
        moveFunds: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/movefunds`,
        moveFundsBatch: `${BASE_URL}/api/v1/customerrole/manageecardsmodule/movefundsbatch`,
    },
    manageesJumioModule: {
        verifyAddress: `${BASE_URL}/api/v1/publicrole/manageesjumiomodule/verifyaddress`,
        verificationStatus: `${BASE_URL}/api/v1/publicrole/manageesjumiomodule/verificationstatus`,
        idSubmit: `${BASE_URL}/api/v1/publicrole/manageesjumiomodule/idsubmit`,
        //requestManualVerification: `${BASE_URL}/api/v1/publicrole/manageesjumiomodule/requestmanualverification`, // not used I think
    },
    fxmodule: {
        listExchanges: `${BASE_URL}/api/v1/customerrole/fxmodule/listexchanges`,
        updateFavourites: `${BASE_URL}/api/v1/customerrole/fxmodule/updatefavourites`,
        getExchangeDetails: `${BASE_URL}/api/v1/customerrole/fxmodule/getexchangedetails`,
        reviewOrder: `${BASE_URL}/api/v1/customerrole/fxmodule/revieworder`,
        executeExchange: `${BASE_URL}/api/v1/customerrole/fxmodule/executeexchange`,
    },
    informationRequest: {
        list: `${BASE_URL}/api/v1/customerrole/informationrequestmodule/getinformationrequestlist`,
        getUploadInfo: `${BASE_URL}/api/v1/customerrole/informationrequestmodule/getinformationresponseform`,
        uploadDocument: `${BASE_URL}/api/v1/customerrole/informationrequestmodule/uploadformdocument`,
        upload: `${BASE_URL}/api/v1/customerrole/informationrequestmodule/submitinformationresponseform`,
    },
    approvals: {
        list: `${BASE_URL}/api/v1/customerrole/approvalsmodule/list`,
        process: `${BASE_URL}/api/v1/customerrole/approvalsmodule/process`,
        view: `${BASE_URL}/api/v1/customerrole/approvalsmodule/view`,
    },
    approvalHistory: {
        list: `${BASE_URL}/api/v1/customerrole/approvalhistorymodule/list`,
        view: `${BASE_URL}/api/v1/customerrole/approvalhistorymodule/view`,
    },
    approvalTypes: {
        list: `${BASE_URL}/api/v1/customerrole/approvaltypesmodule/list`,
        edit: `${BASE_URL}/api/v1/customerrole/approvaltypesmodule/edit`,
        view: `${BASE_URL}/api/v1/customerrole/approvaltypesmodule/view`,
    },
    cryptoExchange: {
        list: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/listexchanges`,
        updateFavourites: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/updatefavourites`,
        getExchangeDetails: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/getexchangedetails`,
        reviewOrder: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/revieworder`,
        executeExchange: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/executeexchange`,
        getDepositAddress: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/getdepositaddress`,
        getDepositOptions: `${BASE_URL}/api/v1/customerrole/cryptoexchangemodule/getdepositoptions`,
    },
};
