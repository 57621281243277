import { useTheme } from '@emotion/react';
import { FoldingCube } from 'better-react-spinkit';
import { useDispatch, useSelector } from 'react-redux';
import api, { ApiResponse, isAxiosErrorHandled, useFetch } from '../../api';
import { FormState, InfoRequestModalResponse } from '../../pages/InfoRequest/models';
import { ModalTypes, closeModal, selectModalState } from '../../reducers/modal';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { endpoints } from '../../endpoints.config';
import { ERROR_CODES, getErrorMessage } from '../../errors';
import { formatPriceWithLocale } from '../../helpers/formatPriceWithCommas';
import { refreshInfoRequestTable } from '../../reducers/infoRequest';
import { selectCultureCode } from '../../reducers/language';
import { FileUpload } from '../form/FileUpload/FileUpload';
import FormTextField from '../form/FormTextField';

type DocumentUploadResponse = {
    bSuccess: boolean;
    downloadUri: string;
};

export const UploadInfoRequestModal = () => {
    const cultureCode = useSelector(selectCultureCode);
    const modalState = useSelector(selectModalState);

    const [errorMessage, setErrorMessage] = useState<string>();

    const dispatch = useDispatch();

    const { colors } = useTheme();

    const { data, loading, error } = useFetch<ApiResponse<InfoRequestModalResponse>>(
        endpoints.informationRequest.getUploadInfo,
        {
            params: {
                // @ts-ignore
                informationRequestsId: modalState.data ?? '',
            },
        },
        undefined,
        // @ts-ignore
        !modalState.data
    );

    if (modalState.modalType !== ModalTypes.UPLOAD_REQUEST_INFO) return null;

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleSuccessfulUpload = () => {
        dispatch(refreshInfoRequestTable());
        handleClose();
    };

    const uploadFile = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append(
                'CustomerAssetAccountsTransactionsInformationRequestsId',
                data?.details.customerAssetAccountsTransactionsInformationRequestsId + ''
            );
            formData.append('ResponseFormGuid', data?.details.responseFormGuid!);
            formData.append('File', file);
            const res = await api.post<ApiResponse<DocumentUploadResponse>>(
                endpoints.informationRequest.uploadDocument,
                formData,
                {
                    headers: { 'content-type': 'multipart/form-data' },
                }
            );
            if (res.data.status === '1') {
                return res.data.details;
            } else throw new Error();
        } catch (error) {
            throw error;
        }
    };

    const uploadFiles = async (files: File[]) => {
        try {
            const resArray = await Promise.all(files.map(async (file) => await uploadFile(file)));

            return resArray;
        } catch (error) {
            throw error;
        }
    };

    const onSubmit = async (values: FormState) => {
        try {
            // Rather than have the save endpoint take the uploaded files
            // we have to upload the files seperately then reference them
            // the endpoint only accepts one file at a time so we need to loop them
            // Don't want to do it as the user picks the files because they may abandon
            // the process befire saving which would leave unused uploaded files in
            // the system 🤷‍♂️
            const files = await uploadFiles(values.uploadFiles);

            const infoRequestId =
                data?.details.customerAssetAccountsTransactionsInformationRequestsId;
            const formGuid = data?.details.responseFormGuid;
            const payload = {
                responseFormGuid: formGuid,
                customerAssetAccountsTransactionsInformationRequestsId: infoRequestId,
                supportingInformation: values.transactionInfo,
                supportingDocumentUploads: values.uploadFiles.map((file) => ({
                    fileName: file.name,
                    responseFormGuid: formGuid,
                    customerAssetAccountsTransactionsInformationRequestsId: infoRequestId,
                })),
            };

            const res = await api.post<ApiResponse>(endpoints.informationRequest.upload, payload);
            if (res.data.status === '1') {
                handleSuccessfulUpload();
            } else {
                throw new Error();
            }
        } catch (err) {
            let translatedErrorMessage = getErrorMessage('Generic');

            if (isAxiosErrorHandled(err) && err.response.data.errors) {
                err.response.data.errors.forEach((error) => {
                    if (error.messageCode in ERROR_CODES)
                        translatedErrorMessage = getErrorMessage(error.messageCode);
                });
            }
            if (isAxiosErrorHandled(err) && err.response.status === 413) {
                translatedErrorMessage = 'File too large';
            }

            setErrorMessage(translatedErrorMessage);
        }
    };

    return (
        <Formik
            initialValues={{
                transactionInfo: '',
                uploadFiles: [],
            }}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Modal
                    closeOnClickOutside={!isSubmitting}
                    className="UploadInfoRequestModal"
                    title="Transaction information"
                >
                    {loading && (
                        <div className="InfoRequestLoading">
                            <FoldingCube color={colors.first} size={80} />
                        </div>
                    )}
                    {!loading && error && (
                        <div className="InfoRequestError">
                            <WarningIcon width={50} />
                            <h2>Something went wrong</h2>
                            <p>Unable to load transaction info</p>
                            <Button onClick={handleClose}>Close</Button>
                        </div>
                    )}

                    {data && (
                        <>
                            <div className="InfoRequestHeader">
                                <div className="Background" />
                                <div className="InfoRequestArrow">
                                    {data.details.bMoneyIn ? (
                                        <IconMoneyIn width={20} />
                                    ) : (
                                        <IconMoneyOut width={20} fill={colors.third} />
                                    )}
                                </div>
                                <div className="TitleWrapper">
                                    <div className="TransferType">
                                        {data.details.bMoneyIn ? 'Inbound' : 'Outbound'} transfer
                                    </div>
                                    <div className="TransferAmount">
                                        {data.details.assetCode}{' '}
                                        {formatPriceWithLocale(
                                            Math.abs(data.details.amount),
                                            cultureCode ?? 'en-GB'
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div>From:</div>
                            <div className="FromValue">{data.details.externalPartyName}</div>

                            <p className="Message">
                                {
                                    data.details
                                        .customerAssetAccountsTransactionsInformationRequestsDescription
                                }
                            </p>

                            <Form>
                                <FormTextField
                                    label="Transaction information"
                                    field="transactionInfo"
                                    type="textarea"
                                    required={false}
                                    placeholder="Type your text here"
                                    maxLength={1000}
                                    inputProps={{ rows: undefined }}
                                    hint="Maximum text length is 1000 characters"
                                    disabled={isSubmitting}
                                />
                                <FileUpload
                                    label="Supporting documents"
                                    fieldName="uploadFiles"
                                    emptyUploadTitle="Drag and drop here or click to upload"
                                    emptyUploadMessage="You may upload PDF, PNG or JPEG files"
                                    multi
                                    small
                                    disabled={isSubmitting}
                                />

                                {errorMessage && (
                                    <p className="ErrorText NoMargin">{errorMessage}</p>
                                )}

                                <Button disabled={isSubmitting} type="submit" color="third">
                                    Submit information
                                </Button>
                            </Form>
                        </>
                    )}
                </Modal>
            )}
        </Formik>
    );
};
