import signInActive from 'assets/ibanera/Icon_Sign_In_Active.png';
import signInInactive from 'assets/ibanera/Icon_Sign_In_Inactive.png';
import RegisterActive from 'assets/ibanera/Icon_Register_Active.png';
import RegisterInactive from 'assets/ibanera/Icon_Register_Inactive.png';
import MoreActive from 'assets/ibanera/Icon_More_Active.png';
import MoreInactive from 'assets/ibanera/Icon_More_Inactive.png';
import HomeActive from 'assets/ibanera/Icon_Home_Active.png';
import HomeInactive from 'assets/ibanera/Icon_Home_Inactive.png';
import WarningIcon from 'assets/ui-update/warning.svg';

import React from 'react';
import { selectTheme } from 'reducers/ui';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, clearIcon } from 'reducers/componentResources';
import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import classNames from 'classnames';

export enum StaticIcon {
    SIGN_IN,
    REGISTER,
    MORE,
    HOME,
}

const ICON_MAP: { [key in StaticIcon]: Icon } = {
    [StaticIcon.SIGN_IN]: {
        active: signInActive,
        mobileInactive: signInInactive,
    },
    [StaticIcon.REGISTER]: {
        active: RegisterActive,
        mobileInactive: RegisterInactive,
    },
    [StaticIcon.MORE]: {
        active: MoreActive,
        mobileInactive: MoreInactive,
    },
    [StaticIcon.HOME]: {
        active: HomeActive,
        mobileInactive: HomeInactive,
    },
};

type MobileNavigationButtonProps = {
    onClick: () => void;
    label: string;
    icon: StaticIcon | Icon | undefined;
    iconName?: string;
    className?: string;
    isActive?: boolean;
    isInMoreMenu?: boolean;
    hasAlert?: boolean;
};

// As StaticIcon is an enum, it would have typeof number.
const checkIfLoadedIcon = (icon: StaticIcon | Icon | undefined): icon is Icon => {
    const typedIcon = icon as Icon;
    return !!(typedIcon?.active || typedIcon?.inactive || typedIcon?.mobileInactive);
};

export const MobileNavigationButton: React.FC<MobileNavigationButtonProps> = ({
    onClick,
    label,
    icon,
    className,
    isActive,
    isInMoreMenu,
    hasAlert,
    iconName,
}) => {
    const iconSources: Icon = checkIfLoadedIcon(icon) ? icon : ICON_MAP[icon ?? StaticIcon.MORE];
    const theme = useSelector(selectTheme);
    const dispatch = useDispatch();

    return (
        <button
            className={`MobileNavigationButton ${className ? className : ''} ${
                isActive ? 'Active' : 'Inactive'
            }`}
            onClick={onClick}
        >
            {hasAlert && <img className="AlertIcon" alt="Alert" src={WarningIcon} />}
            {!iconSources?.mobileInactive && !iconSources?.active ? (
                <div
                    className={classNames(
                        'Icon FallbackTextIcon',
                        isActive ? 'Active' : 'Inactive'
                    )}
                >
                    <TextCurrencyIcon currencySymbol={label[0]} />
                </div>
            ) : (
                <>
                    <img
                        src={iconSources?.mobileInactive}
                        alt="Icon"
                        className={`Icon ${isActive ? 'Hidden' : ''}`}
                        onError={iconName ? () => dispatch(clearIcon(iconName)) : undefined}
                    />
                    {!isInMoreMenu && (
                        <img
                            src={iconSources?.active}
                            alt="Icon"
                            className={`Icon ${isActive ? '' : 'Hidden'}`}
                        />
                    )}
                </>
            )}
            <span
                className="Label"
                style={isActive && !isInMoreMenu ? { color: theme.colors.second } : {}}
            >
                {label}
            </span>
        </button>
    );
};
