import { useState } from 'react';

export const ccNumberPattern = /^\d{0,16}$/g;
const ccNumberSeparator = ' ';

export const ccExpiryPattern = /^\d{0,4}$/g;
const ccExpirySeparator = '/';

export const ccCVCPattern = /^\d{0,3}$/g;
export const mask = (value: any, limit: any, separator: any) => {
    let output = [];
    for (let i = 0; i < value.length; i++) {
        if (i !== 0 && i % limit === 0) {
            output.push(separator);
        }

        output.push(value[i]);
    }

    return output.join('');
};
export const unmask = (value: any) => value.replace(/[^\d]/g, '');
export const checkSeparator = (position: any, interval: any) =>
    Math.floor(position / (interval + 1));
export const highlightCC = (ccValue: any) => {
    //let ccCardType: keyof typeof ccCardTypePatterns = 'visa',
    let ccCardTypePatterns = {
        amex: /^3/,
        visa: /^4/,
        mastercard: /^5/,
        disc: /^6/,

        genric: /(^1|^2|^7|^8|^9|^0)/,
    };
    let ccCardType = '';
    let cardType: keyof typeof ccCardTypePatterns;
    for (cardType in ccCardTypePatterns) {
        if (ccCardTypePatterns[cardType].test(ccValue)) {
            ccCardType = cardType;
            break;
        }
    }
};

export const useInputMask = () => {
    const [ccNumberInputOldValue, setCcNumberInputOldValue] = useState<any>();
    const [ccNumberInputOldCursor, setCcNumberInputOldCursor] = useState<any>();
    const [ccExpiryInputOldValue, setCcExpiryInputOldValue] = useState<any>();
    const [ccExpiryInputOldCursor, setCcExpiryInputOldCursor] = useState<any>();
    const [ccCVCInputOldValue, setCcCVCInputOldValue] = useState<any>();
    const [ccCVCInputOldCursor, setCcCVCInputOldCursor] = useState<any>();

    const ccNumberInputKeyDownHandler = (e: any) => {
        let el = e.target;
        setCcNumberInputOldValue(el.value);
        setCcNumberInputOldCursor(el.selectionEnd);
    };
    const ccNumberInputInputHandler = (e: any) => {
        let el = e.target,
            newValue = unmask(el.value),
            newCursorPosition;

        if (newValue.match(ccNumberPattern)) {
            newValue = mask(newValue, 4, ccNumberSeparator);

            newCursorPosition =
                ccNumberInputOldCursor -
                checkSeparator(ccNumberInputOldCursor, 4) +
                checkSeparator(
                    ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length),
                    4
                ) +
                (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

            el.value = newValue !== '' ? newValue : '';
        } else {
            el.value = ccNumberInputOldValue;
            newCursorPosition = ccNumberInputOldCursor;
        }

        el.setSelectionRange(newCursorPosition, newCursorPosition);

        highlightCC(el.value);
    };
    const ccExpiryInputKeyDownHandler = (e: any) => {
        let el = e.target;
        setCcExpiryInputOldValue(el.value);
        setCcExpiryInputOldCursor(el.selectionEnd);
    };
    const ccExpiryInputInputHandler = (e: any) => {
        let el = e.target,
            newValue = el.value;

        newValue = unmask(newValue);
        if (newValue.match(ccExpiryPattern)) {
            newValue = mask(newValue, 2, ccExpirySeparator);
            el.value = newValue;
        } else {
            el.value = ccExpiryInputOldValue;
        }
    };
    const ccCVCInputKeyDownHandler = (e: any) => {
        let el = e.target;
        setCcCVCInputOldValue(el.value);
        setCcCVCInputOldCursor(el.selectionEnd);
    };
    const ccCVCInputInputHandler = (e: any) => {
        let el = e.target,
            newValue = el.value;

        newValue = unmask(newValue);
        if (newValue.match(ccCVCPattern)) {
            //newValue = mask(newValue, 3, '');
            el.value = newValue;
        } else {
            el.value = ccCVCInputOldValue;
        }
    };

    return {
        ccNumberInputKeyDownHandler,
        ccNumberInputInputHandler,
        ccCVCInputKeyDownHandler,
        ccCVCInputInputHandler,
        ccExpiryInputKeyDownHandler,
        ccExpiryInputInputHandler,
    };
};

// https://stackoverflow.com/questions/53672689/masking-the-input-credit-card-number-on-client-side
// https://github.com/amiryxe/credit-card-input/blob/main/js/credit-card-input.js
// https://codepen.io/amiryxe/pen/QWgQPEa

// https://codepen.io/murani/pen/KyVbrp
// https://codepen.io/amiryxe/pen/QWgQPEa

// https://codesandbox.io/s/6gm2z?file=/src/App.js:65-147
// https://reactjsexample.com/a-zero-dependency-react-hook-container-to-help-with-payment-card-input-fields/
// https://github.com/medipass/react-payment-inputs#with-hooks
// https://github.com/medipass/react-payment-inputs/tree/master/src
