import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import classNames from 'classnames';

interface SpinnerProps {
    size?: number;
    color?: string;
    scaleStart?: number;
    scaleEnd?: number;
    positionAbsolute?: boolean;
    className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 15, color, className, positionAbsolute }) => {
    const { colors } = useTheme();
    return (
        <div className={classNames('Spinner', className, { PositionAbsolute: positionAbsolute })}>
            <ThreeBounce size={size} color={color ?? colors.first} />
        </div>
    );
};

export { Spinner };
