import { createMessage, encrypt as pgpEncrypt, readKey } from 'openpgp';

export interface PublicKey {
    keyId: string;
    publicKey: string;
}
/**
 *
 * Encrypt dataToEncrypt
 *
 * @param {Object} dataToEncrypt
 * @param {PublicKey} Object containing keyId and publicKey properties
 *
 * @return {Object} Object containing encryptedMessage and keyId
 */
export const getTokenAndEncrypt = async (data: any, { keyId, publicKey }: PublicKey) => {
    const replaced = publicKey // backend sends the pgp token with characters escaped (/n,/r become //n,//r) - this fixes that
        .replaceAll(/\\r/gi, '\r')
        .replaceAll(/\\n/gi, '\n');
    const decodedPublicKey = await readKey({
        armoredKey: replaced,
    });
    const message = await createMessage({ text: JSON.stringify(data) });
    return pgpEncrypt({
        message,
        encryptionKeys: decodedPublicKey,
        format: 'armored',
    }).then((ciphertext) => {
        return {
            encryptedMessage: btoa(ciphertext as string),
            keyId,
        };
    });
};
