import React from 'react';

type Props = {
    assetCode: string;
};

const spanStyle: React.CSSProperties = {
    fontSize: '0.7em',
    padding: 0,
    margin: 0,
    textTransform: 'lowercase',
};

export const AssetCodesToReplace = ['dUSD'];

const reg = new RegExp('(' + AssetCodesToReplace.join(')|(') + ')');

export const AssetCodeFormatter = ({ assetCode }: Props) => {
    return assetCode === 'dUSD' ? (
        <>
            <span style={spanStyle}>d</span>USD
        </>
    ) : (
        <>{assetCode}</>
    );
};

export const ReplaceAssetCodeIfPresent = ({ str }: { str: string }) => {
    const matchedAssets = AssetCodesToReplace.filter((assetCode) => str.includes(assetCode));
    if (!matchedAssets.length) return <>{str}</>;
    return (
        <>
            {str
                .split(reg)
                .filter((val) => val)
                .map((val, i) => (
                    <AssetCodeFormatter assetCode={val} key={i} />
                ))}
        </>
    );
};
