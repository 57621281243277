/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormValues } from '../schema';
import { SelectOption, WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { FormPhoneNumberField } from '../../form/FormPhoneNumberField';
import { FormDateField } from '../../form/FormDateField';
import { RadioButtons } from '../../radiobuttons/radiobuttons';
import UploadField from '../helpers/UploadField';
import { useSelector } from 'react-redux';
import { selectVerificationDetailsV2 } from '../../../reducers/verification';
import { useAfterMountEffect } from '../../../helpers/useAfterMountEffect';
import instance from '../../../api';
import { endpoints } from '../../../endpoints.config';
import { Toast, ToastMessageReason } from '../../../helpers/toast';
import RedX from 'assets/ibanera/Icon_RedCross.png';
import { formatValues } from '../helpers/helpers';

type IndividualOwnerProps = {
    index: number;
    fieldName: string;
    parentField: string;
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    arrayHelpers: FieldArrayRenderProps;
};

export const IndividualOwner: React.FC<IndividualOwnerProps> = ({
    index,
    fieldName,
    parentField,
    availableCountries,
    arrayHelpers,
}) => {
    const { rejectedDocuments } = useSelector(selectVerificationDetailsV2); //additionalData to have the stored form data for current stage?

    const [expand, setExpand] = useState<boolean>(false);

    const { values, setFieldValue, setFieldError, isSubmitting } = useFormikContext<FormValues>();
    const [{ value: parentFieldValue }] = useField<any[]>(parentField);
    const [{ value: countryValue }] = useField(`${fieldName}.country`);
    const [{ value }, { error, touched }] = useField(fieldName);

    const hasError = !!(error && touched);
    useEffect(() => {
        if (!expand && hasError) setExpand(true);
    }, [hasError, isSubmitting]);

    useEffect(() => {
        if (!value.firstName && !expand) setExpand(true);
    }, [value.firstName, parentFieldValue.length]);

    useEffect(() => {
        //If array changed recalulate numbering
        setFieldValue(`${fieldName}.number`, index + 1);
    }, [parentFieldValue.length]);

    useEffect(() => {
        if (
            rejectedDocuments &&
            rejectedDocuments.hasOwnProperty('proofOfAddress') &&
            rejectedDocuments.proofOfAddress &&
            rejectedDocuments.proofOfAddress.length > 0
        ) {
            if (rejectedDocuments.proofOfAddress?.includes(value.proofOfAddressFilename)) {
                setFieldError(
                    `${fieldName}.proofOfAddressFilename`,
                    `File named ${value.proofOfAddressFilename} has been rejected, please upload another file`
                );
            }
        }
    }, []);

    const saveForm = async () => {
        try {
            await instance.post(endpoints.businessVerificationV2Module.saveFormData, {
                ...formatValues(values),
                businessVerificationStep: values.businessVerificationStep,
            });
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };
    const handleRemoveIndividual = () => {
        arrayHelpers.remove(index);
    };

    const currentCountry = availableCountries.find((country) => country.value === countryValue);
    const stateOptions = currentCountry ? currentCountry.states : null;

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.stateProvince`, '');
    }, [countryValue]);

    return (
        <div className="MarginBottom">
            <div
                className={`FormHeader ${hasError && !expand ? 'FormHeaderError' : ''}`}
                onClick={(e) => setExpand(!expand)}
            >
                <div className="LeftSide">
                    <h3 className="Title">{`Individual ${index + 1}`}</h3>
                    <button
                        className="DeleteOwner"
                        aria-label={`Delete Individual ${index + 1}`}
                        type="button"
                        onClick={() => handleRemoveIndividual()}
                    >
                        <img src={RedX} alt="Delete file" />
                    </button>
                </div>
                <div className="Row">
                    <div className={expand ? 'ChevronOpen' : 'ChevronClosed'}></div>
                </div>
            </div>
            {expand && (
                <div className="Form">
                    <div className="Row">
                        <FormTextField
                            label="First name"
                            field={`${fieldName}.firstName`}
                            maxLength={200}
                        />
                        <FormTextField
                            label="Last name"
                            field={`${fieldName}.lastName`}
                            maxLength={200}
                        />
                    </div>
                    <div className="Row">
                        <FormTextField label="Title" field={`${fieldName}.title`} maxLength={200} />
                        <FormDateField
                            field={`${fieldName}.dateOfBirth`}
                            label="Date of Birth"
                            error={true}
                            datePickerProps={{
                                maxDate: new Date(),
                            }}
                        />
                    </div>
                    <div className="Row">
                        <WrappedFormSingleSelectField
                            fieldName={`${fieldName}.nationality`}
                            options={availableCountries}
                            label="Nationality"
                        />
                        <FormTextField label="Email" field={`${fieldName}.email`} maxLength={200} />
                    </div>
                    <div className="Row">
                        <FormPhoneNumberField
                            field={`${fieldName}.phoneNumber`}
                            label="Phone Number"
                            sideBySide={true}
                        />
                        <WrappedFormSingleSelectField
                            fieldName={`${fieldName}.country`}
                            options={availableCountries}
                            label="Country"
                        />
                    </div>
                    <div className="Row">
                        <FormTextField
                            field={`${fieldName}.ssn`}
                            label={'Tax ID number / SSN'}
                            maxLength={200}
                        />
                        {stateOptions && stateOptions.length > 0 ? (
                            <div className="FormBuilderComponent SideBySide">
                                <WrappedFormSingleSelectField
                                    fieldName={`${fieldName}.stateProvince`}
                                    label={'State / Province*'}
                                    options={stateOptions}
                                />
                            </div>
                        ) : (
                            <FormTextField
                                field={`${fieldName}.stateProvince`}
                                label={'State / Province'}
                                maxLength={200}
                            />
                        )}
                    </div>
                    <div className="Row">
                        <FormTextField field={`${fieldName}.city`} label={'City'} maxLength={200} />
                        <FormTextField
                            field={`${fieldName}.addressLine1`}
                            label={'Street Address'}
                            maxLength={200}
                        />
                    </div>
                    <div className="Row">
                        <FormTextField
                            field={`${fieldName}.addressLine2`}
                            label={'Street Address 2'}
                            maxLength={200}
                        />
                        <FormTextField
                            field={`${fieldName}.postcode`}
                            label={'Postal / Zip Code'}
                            maxLength={200}
                        />
                    </div>
                    <div className="Row">
                        <FormTextField
                            field={`${fieldName}.percentageSharesOwned`}
                            label={'Percentage of Shares Owned (%)'}
                            type="number"
                            maxLength={200}
                        />
                        <FormTextField
                            field={`${fieldName}.position`}
                            label={'Position at the Company'}
                            maxLength={200}
                        />
                    </div>
                    <RadioButtons
                        options={['Yes', 'No']}
                        label={'Controlling Party*'}
                        booleanField={true}
                        fieldname={`${fieldName}.bControllingParty`}
                        error={true}
                    />
                    <div className="FormBox">
                        <div className="FormLabel">
                            <label>
                                Proof of Address
                                <span>*</span>
                            </label>
                        </div>
                        <UploadField
                            fieldName={`${fieldName}.proofOfAddressFilename`}
                            displayError={true}
                            jsonField={true}
                            onChange={saveForm}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
