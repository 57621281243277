import { FormikHelpers } from 'formik';
import { useEffect } from 'react';

function touchAllFields(
    values: any,
    setFieldTouched: FormikHelpers<any>['setFieldTouched'],
    prefix: string = ''
): void {
    Object.keys(values).forEach((key: string) => {
        const value = values[key];
        const fieldPath = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            touchAllFields(value, setFieldTouched, fieldPath);
        } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                    touchAllFields(item, setFieldTouched, `${fieldPath}[${index}]`);
                } else {
                    if (item !== null) {
                        if (typeof item === 'string' && item.length > 0) {
                            setFieldTouched(`${fieldPath}[${index}]`, true);
                        } else if (typeof value !== 'string') {
                            setFieldTouched(`${fieldPath}[${index}]`, true);
                        }
                    }
                    // setFieldTouched(`${fieldPath}[${index}]`, true);
                }
            });
        } else {
            if (value !== null) {
                if (typeof value === 'string' && value.length > 0) {
                    setFieldTouched(fieldPath, true);
                } else if (typeof value !== 'string') {
                    setFieldTouched(fieldPath, true);
                }
            }
            // setFieldTouched(fieldPath, true);
        }
    });
}

const useValidate = (setFieldTouched: any, field: {}) => {
    useEffect(() => {
        touchAllFields(field, setFieldTouched);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useValidate;
