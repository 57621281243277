import { FlexTable } from 'components/flexTable';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { DateCell, NumberAndTickerCell, StringCell } from 'components/flexTable/CustomCells';
import { TableProps } from 'components/flexTable/FlexTable';
import React, { useEffect } from 'react';
import { plaintext } from 'plaintext.config';
import { useDispatch, useSelector } from 'react-redux';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import Button from 'components/button/Button';
import { useNavigateWithBasePath } from 'helpers/useNavigateWithBasePath';
import { AppPath } from 'appConstants';
import { NavigateFn } from '@reach/router';
import { NameWithInitial } from '../../pages/InfoRequest/InfoRequest';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { useTheme } from '@emotion/react';
import { ThemeType } from '../../theme';
import { checkIsPendingCrypto } from '../../pages/cryptoaccounts/CryptoAccountsHistory';

export type CryptoHistoryItem = {
    customerAssetAccountsTransactions__Id: number;
    assets__Name: string;
    assets__Code: string;
    customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode: string;
    customerAssetAccountsTransactions__Date: string;
    transactionTypes__Type: 'Buy' | 'Sell' | 'Deposit' | 'Withdraw';
    customerAssetAccountsTransactions__Amount: number;
    assetExchanges__Price: number | null;
    feeTransactions__Amount: number;
    feeAssets__Code: string;
    customerAssetAccountsTransactions__bFrozen: boolean;
    customerAssetAccountsTransactions__bPending: boolean;
    customerAssetAccountsTransactions__bReserved: boolean;
    customerAssetAccountsTransactionScreenings__bCustomerReviewPending?: boolean;
    customerAssetAccountsTransactionScreenings__Id?: number;

    customerAssetAccountsExternalTransfers__Name: string;
    transactionAmounts__Balance: number;
    transactionAmounts__ApprovalStatus: 'Approval' | 'Approved' | 'Rejected' | 'Refunded';
    transactionAmounts__bEligibleForReverse: boolean;
    /* This just provides an indication if it could be cancellable. A more complex endpoint is
    called to calc this if this is true when the side panel is opened */
    bCancellable: boolean;
};

const rowFormatter =
    (theme: ThemeType['colors']) =>
    (row: Partial<CryptoHistoryItem>): { [K in keyof typeof row]: React.ReactNode } => {
        let formattedRow: any = { ...row };

        const moneyIn = row.transactionTypes__Type === 'Buy';
        const showPending = checkIsPendingCrypto(row as CryptoHistoryItem);

        if (row.transactionTypes__Type) {
            const showApprovalStatus =
                !showPending &&
                row.transactionAmounts__ApprovalStatus &&
                row.transactionAmounts__ApprovalStatus !== 'Approved';

            formattedRow = {
                ...formattedRow,
                transactionTypes__Type: moneyIn ? (
                    <div className="OrderType">
                        <IconMoneyIn width={20} />
                        {showPending && <span className="Pending Pending--in">Pending</span>}
                        {showApprovalStatus && (
                            <span className="Pending Pending--in">
                                {row.transactionAmounts__ApprovalStatus}
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="OrderType">
                        <IconMoneyOut width={20} fill={theme.third} />
                        {showPending && <span className="Pending Pending--out">Pending</span>}
                        {showApprovalStatus && (
                            <span className="Pending Pending--out">
                                {row.transactionAmounts__ApprovalStatus}
                            </span>
                        )}
                    </div>
                ),
            };
        }
        if (
            row.assets__Name &&
            row.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode &&
            row.assets__Code
        ) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode: `${row.assets__Code}: ${row.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode}`,
            };
        }
        if (formattedRow.transactionAmounts__Balance) {
            formattedRow = {
                ...formattedRow,
                transactionAmounts__Balance: row.assets__Code ? (
                    <NumberAndTickerCell
                        number={formattedRow.transactionAmounts__Balance}
                        ticker={row.assets__Code}
                        plainColor
                    />
                ) : (
                    row.customerAssetAccountsTransactions__Amount
                ),
            };
        }

        if (formattedRow.customerAssetAccountsExternalTransfers__Name != null) {
            const desc = row.customerAssetAccountsExternalTransfers__Name;
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsExternalTransfers__Name: (
                    <NameWithInitial name={desc ?? ''} moneyIn={moneyIn} />
                ),
            };
        }
        if (row.customerAssetAccountsTransactions__Date) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Date: (
                    <DateCell
                        date={row.customerAssetAccountsTransactions__Date}
                        formatString="dd - MM - yyyy"
                    />
                ),
            };
        }

        if (
            row.customerAssetAccountsTransactions__Amount ||
            row.customerAssetAccountsTransactions__Amount === 0
        ) {
            formattedRow = {
                ...formattedRow,
                customerAssetAccountsTransactions__Amount:
                    row.assets__Code /*  && row.assetExchanges__Price */ ? (
                        <NumberAndTickerCell
                            number={row.customerAssetAccountsTransactions__Amount}
                            ticker={row.assets__Code}
                        />
                    ) : (
                        row.customerAssetAccountsTransactions__Amount
                    ),
            };
        }

        if (row.assetExchanges__Price) {
            formattedRow = {
                ...formattedRow,
                assetExchanges__Price: <StringCell value={row.assetExchanges__Price} />,
            };
        }

        if (row.feeTransactions__Amount) {
            formattedRow = {
                ...formattedRow,
                feeTransactions__Amount:
                    row.feeAssets__Code && row.feeTransactions__Amount ? (
                        <NumberAndTickerCell
                            number={row.feeTransactions__Amount}
                            ticker={row.feeAssets__Code}
                        />
                    ) : (
                        row.feeTransactions__Amount
                    ),
            };
        }

        return formattedRow;
    };

export const ID_COLUMN = 'customerAssetAccountsTransactions__Id';

export const HistoryTable: React.FC<
    TableProps & { simpleReferenceCell?: boolean; noRowButton?: boolean; selectedIds?: number[] }
> = (props) => {
    const { colors } = useTheme();

    const formatter = rowFormatter(colors);
    const update = useSelector(selectUIUpdate);
    const dispatch = useDispatch();
    const navigate = useNavigateWithBasePath();

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.RELOAD_HISTORY_TABLE) {
            props.table?.reload();
            dispatch(completeUIUpdate());
        }
        if (update?.pushType === NotificationIdentifier.UPDATE_HISTORY_TRANSACTION) {
            const listData = props.table?.data?.details.listData as CryptoHistoryItem[];
            if (listData) {
                const selectedRow = listData.find(
                    (row) =>
                        row.customerAssetAccountsTransactions__Id ===
                        update.data.customerAssetAccountsTransactionsId
                );
                if (!selectedRow) {
                    dispatch(completeUIUpdate());
                    return;
                }
                const newRow: CryptoHistoryItem = {
                    ...selectedRow,
                    customerAssetAccountsTransactions__bFrozen: update.data.bFrozen,
                    customerAssetAccountsTransactions__bReserved: update.data.bReserved,
                    customerAssetAccountsTransactions__bPending: update.data.bPending,
                };
                props.table?.data?.actions.updateLocalTable(ID_COLUMN, [newRow], listData);
            }
            dispatch(completeUIUpdate());
        }
    }, [update, props.table, dispatch]);
    return (
        <div className="HistoryTableWarpper">
            <FlexTable
                {...props}
                idColumn={ID_COLUMN}
                selectedIds={props.selectedIds}
                hideFiltering
                rowFormatter={formatter}
                rowButton={props.noRowButton ? undefined : RowButtonCreator(navigate)}
                buttonInMiddleTable={props.noRowButton ? undefined : true}
                rowCellOptions={{
                    transactionTypes__Type: {
                        style: {
                            minWidth: '120px',
                            width: '120px',
                            flex: '0',
                        },
                    },
                }}
            />
            {props.table?.data?.details.listData.length === 0 && !props.table?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">{plaintext.tables.emptyHistoryTable}</h3>
                </div>
            )}
        </div>
    );
};

const RowButtonCreator = (navigate: NavigateFn) => (rowData: CryptoHistoryItem) => {
    if (
        rowData.customerAssetAccountsTransactionScreenings__bCustomerReviewPending &&
        rowData.customerAssetAccountsTransactionScreenings__Id &&
        (rowData.customerAssetAccountsTransactions__bPending ||
            rowData.customerAssetAccountsTransactions__bReserved)
    ) {
        return (
            <Button
                color="second"
                onClick={() =>
                    navigate(
                        `${AppPath.REVIEW_ORDER}?transactionScreeningId=${rowData.customerAssetAccountsTransactionScreenings__Id}`
                    )
                }
            >
                Review Order
            </Button>
        );
    }
    return null;
};
