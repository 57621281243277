import { formatGeneralCurrency } from '../../../components/modals/CryptoExchangeModal/FromTo';
import { dashIfPresent } from './helpers';

import { ReactComponent as DeleteIcon } from 'assets/ui-update/delete.svg';
import { ReactComponent as ExpandIcon } from 'assets/ui-update/search.svg';
import { ReactComponent as CollapsedIcon } from 'assets/ui-update/dash.svg';
import { ReactComponent as EditIcon } from 'assets/ui-update/edit-pen.svg';
import { ParsedCsv } from './models';

type HeaderProps = ParsedCsv & {
    currencySymbol?: string;
    currencyCode?: string;
    sourceAccountName?: string;
    handleDeleteTransfer(): void;
    handleEditTransfer(): void;
};
export const BulkTransferItemHeader: React.FC<HeaderProps> = ({
    name,
    purpose,
    paymentReference,
    isDbs,
    accountNumber,
    swiftNumber,
    countryCode,
    routingNumber,
    iban,
    transferType,
    currencySymbol,
    currencyCode,
    sourceAccountName,
    amount,
    handleDeleteTransfer,
    handleEditTransfer,
    status,
}) => {
    return (
        <div className="BulkTransferItemHeader">
            <div className="HeaderInfo">
                <div className="LeftSection">
                    <div className="PayeeName">{name ?? '-'}</div>
                    <div className="HeaderInfo">{dashIfPresent(purpose, paymentReference)}</div>
                    <div className="HeaderInfo">
                        {isDbs && dashIfPresent(accountNumber, swiftNumber)}
                        {!isDbs &&
                            countryCode === 'USA' &&
                            dashIfPresent(routingNumber, accountNumber)}
                        {!isDbs && countryCode !== 'USA' && dashIfPresent(swiftNumber, iban)}
                    </div>
                </div>

                {status && <div className={'StatusPill ' + status}>{status}</div>}

                <div
                    className={`TransferType TransferType--${
                        transferType ? transferType.toUpperCase() : 'NONE'
                    }`}
                >
                    {transferType ?? '-'}
                </div>
                <div className="AmountWrapper">
                    <div className="Amount">
                        {currencySymbol}
                        {typeof amount === 'number' &&
                            formatGeneralCurrency(amount, currencyCode ?? '', true, {
                                minimumFractionDigits: 2,
                            })}
                        {typeof amount !== 'number' && '0.00'}
                    </div>
                    <div className="HeaderInfo SourceAccount">{sourceAccountName}</div>
                </div>
            </div>

            {(!status || status === 'Error') && (
                <div className="Icons">
                    <ExpandIcon className="ExpandIcon" />
                    <CollapsedIcon className="CollapsedIcon" />
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditTransfer();
                        }}
                        className="EditIconButton"
                        title="Edit transfer"
                    >
                        <EditIcon className="EditIcon" />
                    </button>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTransfer();
                        }}
                        className="DeleteIconButton"
                        title="Delete transfer"
                    >
                        <DeleteIcon className="DeleteIcon" />
                    </button>
                </div>
            )}

            <div className="MobileInfo">
                <div className="AmountWrapper">
                    <div className="Amount">
                        {currencySymbol}
                        {typeof amount === 'number' &&
                            formatGeneralCurrency(amount, currencyCode ?? '', true)}
                        {typeof amount !== 'number' && '0.00'}
                    </div>
                    <div className="HeaderInfo SourceAccount">{sourceAccountName}</div>
                </div>

                <div className={`TransferType TransferType--${transferType?.toUpperCase()}`}>
                    {transferType ?? '-'}
                </div>
            </div>
        </div>
    );
};
