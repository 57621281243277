import instance from 'api';
import classNames from 'classnames';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { formatCurrency } from 'helpers/fiatFormatter';
import { CurrencyExchangeItem, ExchangeType } from 'pages/currencyExchange/models';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalTypes, openModal } from 'reducers/modal';

const initialValues: CurrencyExchangeOptions = {
    amount: '' as unknown as number,
    exchangeType: 'Buy',
};
export type CurrencyExchangeOptions = {
    amount: number;
    exchangeType: 'Buy' | 'Sell';
};

const numberformatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 });

type Props = {
    item: CurrencyExchangeItem;
    setFavourite: (baseAssetId: number, exchangeAssetId: number, bFavourited: boolean) => void;
};
export const CurrencyExchangeTile = ({ item, setFavourite }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (values: typeof initialValues) => {
        dispatch(
            openModal({
                modalType: ModalTypes.CURRENCY_EXCHANGE,
                data: {
                    ...item,
                    amount: values.amount,
                    exchangeType: swapExchangeType(values.exchangeType),
                },
            })
        );
    };
    const handleOpenChart = () => {
        dispatch(
            openModal({
                modalType: ModalTypes.CURRENCY_EXCHANGE_CHART,
                data: item,
            })
        );
    };
    const handleFavourite = () => {
        setFavourite(item.baseAssetId, item.exchangeAssetId, !item.bFavourited);
    };
    return (
        <div className={classNames('CurrencyExchangeTile', { Expanded: expanded })}>
            <div className={classNames('TileInner', { Expanded: expanded })}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form>
                        <div className="TileHeader">
                            <h3>
                                {item.baseAsset} / {item.exchangeAsset}
                            </h3>
                            <div className="Actions">
                                <button
                                    type="button"
                                    className={classNames(
                                        'FavouriteButton',
                                        item.bFavourited ? 'Favourited' : ''
                                    )}
                                    onClick={handleFavourite}
                                ></button>
                                <button
                                    type="button"
                                    className={'ChartButton'}
                                    onClick={handleOpenChart}
                                ></button>
                            </div>
                        </div>
                        <div className="ExchangeValues">
                            <div className="CurrencyItem">
                                You buy {item.exchangeAsset}{' '}
                                <div className="CurrencyAmount Green">
                                    {numberformatter.format(item.buyPrice)}
                                </div>
                            </div>
                            <div className="CurrencyItem">
                                You sell {item.exchangeAsset}{' '}
                                <div className="CurrencyAmount Red">
                                    {numberformatter.format(1 / item.sellPrice)}
                                </div>
                            </div>
                        </div>
                        {expanded && (
                            <div>
                                <RadioButtons
                                    options={['Buy', 'Sell']}
                                    fieldname={'exchangeType'}
                                />
                                <div className="CurrencyInputWrapper">
                                    <div className="CurrencyLabel">{item.exchangeAsset}</div>
                                    <FormTextField
                                        field={'amount'}
                                        label={''}
                                        required={true}
                                        className="CurrencyAmountInput"
                                        type="number"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                    />
                                </div>
                                <Button variety="full">Start contract booking</Button>
                            </div>
                        )}
                        <button
                            className="ExpandBtn"
                            type="button"
                            onClick={() => setExpanded((curr) => !curr)}
                        >
                            <div className={expanded ? 'ChevronOpen' : 'ChevronClosed'} />
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

const swapExchangeType = (exchangeType: ExchangeType): ExchangeType => {
    return exchangeType === 'Buy' ? 'Sell' : 'Buy';
};
