import Button from '../../../button/Button';
import { ReactComponent as BusinessIcon } from 'assets/ui-update/business.svg';
import { ReactComponent as IndividualIcon } from 'assets/ui-update/individual.svg';
import { ReactComponent as DeleteIcon } from 'assets/ui-update/delete.svg';
import { useDivHeight } from '../../../../helpers/useDivHeight';
import { TreeActionButtons, TreeActionButtonsProps } from './TreeActionButtons';
import {
    BeneficialBusinessOwner,
    BeneficialPersonalOwner,
    FormValues,
    OwnerTreeStructure,
} from '../../schema';
import { SingleNewItem } from './AddNewOwner/SingleNewItem';
import { useField, useFormikContext } from 'formik';
import { ErrorM } from '../../../form/ErrorM';
import { beneficialOwnersStructureSchema } from '../../validation';
import { useIsMainInstance } from './helpers';
import { useEffect } from 'react';

const FAKE_ITEM_FOR_VALIDATION = {
    ownersGuid: 'FAKE_GUID',
    bIsBusiness: false,
    percentageOwned: 20,
    bControllingParty: true,
    positionAtCompany: 'positionAtCompany',
    children: null,
};

const hasErrorField = (errors?: Partial<OwnerTreeStructure>) => {
    return (
        !!errors?.bControllingParty ||
        !!errors?.ownersGuid ||
        !!errors?.percentageOwned ||
        !!errors?.positionAtCompany
    );
};

const MAX_DEPTH = 5;
export const isBusinessOwner = (
    _owner: BeneficialPersonalOwner | BeneficialBusinessOwner,
    bIsBusiness: boolean
): _owner is BeneficialBusinessOwner => bIsBusiness;

type SingleItemProps = {
    item: OwnerTreeStructure;
    hideLeftBar: boolean; // hides the end of the branch after the circle
    hideBranchLink: boolean; // hodes the full stretch of the branch
    depth: number; // used as we set an arbitrary max depth
    fieldname: string;
    handleDeleteOwner: (fieldname: string, parentFieldName: string) => void;
    handleUpdateOwner: (fieldname: string) => void;
    lookupOwner: (
        guid: string,
        bIsBusiness: boolean
    ) => {
        owner: BeneficialPersonalOwner | BeneficialBusinessOwner | undefined;
        fieldname: string;
    };
} & Omit<TreeActionButtonsProps, 'parentFieldname' | 'hasError' | 'hasChildren'>;

export const SingleTreeItem: React.FC<SingleItemProps> = ({
    item,
    depth,
    fieldname,
    handleDeleteOwner,
    handleUpdateOwner,
    lookupOwner,
    hideLeftBar,
    hideBranchLink,
    ...actionProps
}) => {
    const [ref, height] = useDivHeight();
    const { submitCount } = useFormikContext<FormValues>();
    const [{ value }, { error: fieldError, touched }, { setValue }] =
        useField<OwnerTreeStructure>(fieldname);
    const { owner, fieldname: ownerInfoFieldname } = lookupOwner(item.ownersGuid, item.bIsBusiness);
    const [, { error: ownerInfoError }] = useField<
        BeneficialPersonalOwner | BeneficialBusinessOwner
    >(ownerInfoFieldname);

    // Main instance is always true for individuals and only true for business if it's the instance with children
    const isMainInstance = useIsMainInstance(fieldname);

    // hacky clean up from copying during deletion
    if (!isMainInstance && item.children) setValue({ ...value, children: null });

    /* crude check that it's a real validation error and not and error from the parent
    eg. parent error "We have an error" this fieldname is parent[0] so this error is "W" */
    const error = typeof fieldError === 'string' && fieldError.length > 1;
    const hasErrorInItem = !error ? hasErrorField(fieldError as any) : false;

    const hasSubmitted = submitCount > 0;
    const hasError = error && touched && typeof fieldError === 'string' && hasSubmitted;

    const getParentFieldname = (name: string) => {
        // Top level items eg. ownerInformation.beneficialOwnersStructure[2]
        if (name.split('.').length === 2) return name.split('[')[0];

        // ownerInformation.beneficialOwnersStructure[1].children[1].children[0]
        const index = name.lastIndexOf('.');
        return name.substring(0, index);
    };
    const parentFieldname = getParentFieldname(fieldname);

    const isOwnerStructureValid = beneficialOwnersStructureSchema.isValidSync({
        ...item,
        // Only interested in if the tree along is valid, not its children
        children: [FAKE_ITEM_FOR_VALIDATION],
    });

    // If a new owner has been added
    if (item.isNewNode)
        return (
            <SingleNewItem
                owner={item}
                fieldname={fieldname}
                parentFieldname={parentFieldname}
                handleDeleteOwner={() => handleDeleteOwner(fieldname, parentFieldname)}
                hideLeftBar={hideLeftBar}
            />
        );
    // Shouldn't happen but a safeguard
    if (!owner) return null;

    const { bIsBusiness } = item;

    const hasSiblingPastCurrent = (currentIndex: number) => {
        const children = item.children ?? [];
        return children.slice(currentIndex + 1).length > 0;
    };

    const hideBranchStyle = { top: height / 2, height: `calc(100% - ${height / 2}px)` };

    return (
        <div className="SingleTreeItemWrapper">
            <div
                ref={ref}
                className={`SingleTreeItem ${hasError ? 'Error' : ''} ${
                    bIsBusiness ? 'Business' : ''
                }`}
            >
                {hideLeftBar && <div aria-hidden="true" className="FloatingHide" />}
                <div aria-hidden="true" className="FloatingCircle" />
                {bIsBusiness ? <BusinessIcon /> : <IndividualIcon />}
                {isBusinessOwner(owner, bIsBusiness) ? (
                    <h4>
                        {owner.entityName} - {item.percentageOwned}%
                    </h4>
                ) : (
                    <h4>
                        {`${owner.firstName} ${owner.lastName}`} - {item.percentageOwned}%
                    </h4>
                )}
                <Button
                    className={
                        !ownerInfoError && isOwnerStructureValid && !hasErrorInItem
                            ? 'ValidButton'
                            : ''
                    }
                    onClick={() => handleUpdateOwner(fieldname)}
                    type="button"
                    color="third"
                >
                    Add owners info
                </Button>
                <button
                    type="button"
                    onClick={() => handleDeleteOwner(fieldname, parentFieldname)}
                    className="DeleteIconButton"
                    title="Delte owner"
                >
                    <DeleteIcon className="DeleteIcon" />
                </button>
            </div>
            <div className="Relative">
                {isMainInstance && bIsBusiness && depth < MAX_DEPTH && (
                    <TreeActionButtons
                        {...actionProps}
                        fieldname={fieldname}
                        hideLeftBar={hideLeftBar}
                        hideAddBusiness={depth === MAX_DEPTH - 1}
                        hasError={hasError}
                    />
                )}
                {hasError && (
                    <ErrorM
                        key={
                            !!item.children && item.children.length > 0 ? 'ErrorWithChild' : 'Error'
                        }
                        className={`TreeItemError ${
                            !!item.children && item.children.length > 0 ? 'HasChildren' : ''
                        }`}
                        name={fieldname}
                    />
                )}
            </div>
            {item.children && item.children.length > 0 && (
                <div className="ChildrenWrapper">
                    <div className="ConnectingLine" />
                    <div className={`BranchWrapper ${item.ownersGuid}`}>
                        {!hideBranchLink && (
                            <div
                                aria-hidden="true"
                                style={hideBranchStyle}
                                className="FloatingHide Full Branch"
                            />
                        )}
                        {item.children.map((child, i) => (
                            <SingleTreeItem
                                key={i}
                                depth={depth + 1}
                                item={child}
                                handleDeleteOwner={handleDeleteOwner}
                                fieldname={`${fieldname}.children[${i}]`}
                                lookupOwner={lookupOwner}
                                hideLeftBar={i === (item.children ?? []).length - 1}
                                hideBranchLink={hasSiblingPastCurrent(i)}
                                handleUpdateOwner={handleUpdateOwner}
                                {...actionProps}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
