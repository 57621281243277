import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { Cell, CellOptions } from './Cell';
import { makeRow, RowConfig, Checkbox, SingleCheckbox } from './FlexTable';
import { useDetectOverflow } from 'helpers/useDetectOverflow';
import { toCamelCase } from 'helpers/formatFormFieldNames';

type MiddleTableProps<T = any> = {
    idColumn: string;
    columns: ColumnDetail<any>[];
    columnData: any[];
    rowHovered: number | null;
    selectedIds?: number[];
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick?(id: number): void;
    checkbox?: Checkbox;
    rowButton?(data: any): React.ReactNode;
    hiddenColumns?: string[];
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    rowCellOptions?: { [K in keyof T]?: CellOptions };
    toggleColumnSort?: (k: string) => void;
    sortBy: { [k: string]: 'ASC' | 'DESC' };
    singleCheckbox?: SingleCheckbox;
};

const MiddleTable: React.FC<MiddleTableProps> = ({
    idColumn,
    columns,
    columnData,
    rowHovered,
    selectedIds = [],
    handleMouseEnterRow,
    checkbox,
    onRowClick,
    rowButton,
    hiddenColumns,
    formatPrimitives,
    rowFormatter,
    noRowHover,
    rowCellOptions = {},
    toggleColumnSort,
    sortBy,
    singleCheckbox,
}) => {
    const { ref, hasOverflow } = useDetectOverflow([checkbox?.selectedIds]);

    return (
        <div
            id="MiddleTable_OverflowEnabled"
            ref={ref}
            className={hasOverflow ? 'MiddleTable BoxShadow' : 'MiddleTable'}
        >
            <div className="Head">
                {columns
                    .filter((c) => !hiddenColumns?.includes(c.columnKey.toString().toLowerCase()))
                    .map((c, i) => {
                        const sortByValue = sortBy[String(c.columnKey)] ?? 'NONE';
                        const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                        const { bSortable } = c;

                        return (
                            <Cell
                                key={i}
                                options={rowCellOptions[toCamelCase(c.columnKey as string)]}
                                sortable={bSortable}
                                onClick={() =>
                                    bSortable &&
                                    toggleColumnSort &&
                                    toggleColumnSort(String(c.columnKey))
                                }
                            >
                                <span className={bSortable && isSorting ? 'Sorting' : ''}>
                                    {c.labelValue}
                                </span>
                                {bSortable && (
                                    <i
                                        className={
                                            sortByValue === 'ASC'
                                                ? 'SortIcon ASC'
                                                : sortByValue === 'DESC'
                                                ? 'SortIcon DESC'
                                                : 'SortIcon'
                                        }
                                    />
                                )}
                            </Cell>
                        );
                    })}
                {rowButton && (
                    <Cell
                        options={{
                            cellType: 'buttonCell',
                            noSortIcon: true,
                            ...rowCellOptions?.['_button'],
                        }}
                    />
                )}
            </div>
            <div className="TableBody">
                {columnData.map((d, i) =>
                    makeRow({
                        id: d[idColumn].toString(),
                        r: d,
                        index: i,
                        columns,
                        formatPrimitives,
                        hiddenColumns: hiddenColumns ?? [],
                        rowHovered,
                        handleMouseEnterRow,
                        onRowClick: onRowClick ? () => onRowClick(d[idColumn]) : null,
                        rowButton: rowButton ? () => rowButton(d) : undefined,
                        formatRow: rowFormatter,
                        checkbox,
                        noRowHover,
                        rowSelected:
                            checkbox?.selectedIds.includes(d[idColumn]) ||
                            checkbox?.selectedIds.includes(d[idColumn].toString()) ||
                            checkbox?.selectedIds.includes(parseInt(d[idColumn], 10)) ||
                            selectedIds.includes(d[idColumn]) ||
                            selectedIds.includes(d[idColumn].toString()) ||
                            selectedIds.includes(parseInt(d[idColumn], 10)),
                        hideRowOptionComponent: checkbox ? checkbox.selectedIds.length > 0 : false,
                        rowCellOptions,
                        singleCheckbox,
                    })
                )}
            </div>
        </div>
    );
};

export { MiddleTable };
