import { useNavigate } from '@reach/router';
import instance from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { useDispatch } from 'react-redux';
import { getCustomerComponentResources } from 'reducers/componentResources';
import { CardSubPage } from './CardPage';

export const ActivateCard = ({ cardId }: { cardId: number }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activate = () => {
        instance
            .post(endpoints.cards.activateCard, { topUpCardsId: cardId })
            .then((res) => {
                Toast.openSuccessToast('Your card has been activated successfully');
                dispatch(getCustomerComponentResources());
                navigate(`../${cardId}/${CardSubPage.TRANSACTIONS}`);
            }) // TODO add success message
            .catch((err) => Toast.openGenericErrorToast()); // TODO add error handling
    };
    return (
        <div className="ActivateCardPage FormPage">
            <p>Click the button below to activate your card once it has arrived</p>
            <Button variety="full" onClick={activate}>
                Activate Card
            </Button>
        </div>
    );
};
