import { DEFAULT_COLOR } from 'appConstants';
import { FoldingCube } from 'better-react-spinkit';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccountSwitchInProgress, selectAppLoading, selectSignedIn } from 'reducers/auth';
import { selectVerificationLoading } from 'reducers/verification';
import { selectCultureCode } from 'reducers/language';
import {
    selectIsCustomerResourcesFetched,
    selectIsPublicResourcesFetched,
} from 'reducers/rootReducer';

interface LoadingScreenProps {
    color?: string;
}

// We hardcode the loading screen translations so we don't need to fetch them,
// which would also contribute to loading time.
enum CultureCode {
    EN_GB = 'en-GB',
    ES_ES = 'es-ES',
    FR_FR = 'fr-FR',
    DE_DE = 'de-DE',
    ZH_CN = 'zh-CN',
}

type Translations = {
    [key in CultureCode]: {
        public: string;
        customer: string;
    };
};

const TRANSLATIONS: Translations = {
    [CultureCode.EN_GB]: { public: 'Loading', customer: 'Checking your details' },
    [CultureCode.ES_ES]: { public: 'Cargando', customer: 'Comprobando sus datos' },
    [CultureCode.FR_FR]: { public: 'Chargement', customer: 'vérifier vos coordonnées' },
    [CultureCode.DE_DE]: { public: 'Wird geladen', customer: 'Überprüfen Sie Ihre Daten' },
    [CultureCode.ZH_CN]: { public: '装货', customer: '检查您的详细信息' },
};

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ color = DEFAULT_COLOR }) => {
    const cultureCode = useSelector(selectCultureCode);
    const isAppLoading = useSelector(selectAppLoading);
    const isSignedIn = useSelector(selectSignedIn);
    const publicResourcesLoaded = useSelector(selectIsPublicResourcesFetched);
    const customerResourcesLoaded = useSelector(selectIsCustomerResourcesFetched);
    const verificationStatusLoaded = !useSelector(selectVerificationLoading);
    const accountSwitchInProgress = useSelector(selectAccountSwitchInProgress);
    const copy =
        cultureCode &&
        TRANSLATIONS[cultureCode as CultureCode] &&
        (isSignedIn
            ? TRANSLATIONS[cultureCode as CultureCode].customer
            : TRANSLATIONS[cultureCode as CultureCode].public);

    const showLoadingScreen =
        isAppLoading ||
        accountSwitchInProgress ||
        (isSignedIn
            ? !(customerResourcesLoaded && publicResourcesLoaded && verificationStatusLoaded)
            : !publicResourcesLoaded);

    return showLoadingScreen ? (
        <div className={`LoadingScreen`}>
            <>
                <FoldingCube size={100} color={color} />
                <h1 className={`Text ${copy ? 'Showing' : ''}`}>{copy}</h1>
            </>
        </div>
    ) : null;
};
