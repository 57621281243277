import { format, parse } from 'date-fns';
import { toPairs } from 'ramda';
import instance from '../api';
import { endpoints } from '../endpoints.config';
import * as locales from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { AxiosError } from 'axios';

const setLanguageUrl = endpoints.languagemodule.setlanguage;

export const fallbackLanguage = 'en-GB';

export const getValidCultureCode = async (cultureCode: string) => {
    const getCultureCode = await instance
        .post(setLanguageUrl + '?cultureInfo=' + cultureCode)
        .then((res) => {
            if (res.data.status === '0') {
                cultureCode = res.data.details.cultureInfo;
            }
        })
        .catch((err: AxiosError | any) => {
            if (err.response?.data?.details?.cultureInfo) {
                cultureCode = err.response.data.details.cultureInfo;
            } else {
                cultureCode = fallbackLanguage;
            }
        });

    return cultureCode;
};

export const getCultureCodeFromUrl = async () => {
    const pathname = window.location.pathname;
    if (pathname === '/') return 'redirect';
    let cultureCode: any = pathname.split('/')[1];

    await getValidCultureCode(cultureCode).then((res) => (cultureCode = res));
    return cultureCode;
};

export const getPathname = (cultureCode: string) => {
    let pathname = window.location.pathname;
    let pathnameArray = pathname.split('/');
    pathnameArray[1] = cultureCode;
    pathname = pathnameArray.join('/');
    return pathname;
};

export const setBackEndLanguage = async (cultureCode: string) => {
    await instance.post(setLanguageUrl + '?cultureInfo=' + cultureCode);
};

const localePairs = toPairs(locales);

export const getLocaleConfig = (cultureCode: string) => {
    try {
        const foundLocale = localePairs.filter(([key, value]) => {
            const code = (value as any).code;
            return code ? code === cultureCode : false;
        });

        return foundLocale && foundLocale[0] && foundLocale[0][1];
    } catch (_) {
        return null;
    }
};

export const registerUserLocale = (userLocale: string) => {
    const localeConfig = getLocaleConfig(userLocale);

    if (localeConfig) {
        registerLocale(userLocale, localeConfig);
    }
};

export const localizedFormatDate = (
    date: Date,
    cultureCode = 'en-US',
    formatString = 'P',
    separator?: string
) => {
    try {
        const localeConfig = getLocaleConfig(cultureCode);
        if (!localeConfig) {
            throw new Error(); // caught below to default format to US date
        }
        const formatted = format(date, formatString, {
            locale: localeConfig,
        });
        if (separator != undefined) {
            return formatted.replace(/\//g, separator); //regex for /
        }
        return formatted;
    } catch (error) {
        return format(date, 'MM/dd/yyyy');
    }
};

export const localizedParseDate = (
    dateString: string,
    cultureCode = 'en-US',
    formatString = 'P'
) => {
    try {
        const localeConfig = getLocaleConfig(cultureCode);
        if (!localeConfig) {
            throw new Error(); // caught below to default format to US date
        }
        return parse(dateString, formatString, 0, { locale: localeConfig });
    } catch (_) {
        return parse(dateString, 'MM/dd/yyyy', new Date());
    }
};
