import { useField } from 'formik';
import { BeneficialBusinessOwner, BeneficialPersonalOwner, OwnerTreeStructure } from '../../schema';
import {
    BUSINESS_OWNERS_FORM_STRING,
    INDIVIDUAL_OWNERS_FORM_STRING,
    OWNERS_STRUCTURE_FORM_STRING,
} from './OwnersTree';
import { useEffect } from 'react';

export const flatten = (owners: OwnerTreeStructure[] | undefined, withChildren = false) => {
    const flat: OwnerTreeStructure[] = [];
    (owners ?? []).forEach((item) => {
        if (Array.isArray(item.children)) {
            flat.push(
                { ...item, children: withChildren ? item.children : null },
                ...flatten(item.children)
            );
        } else {
            flat.push(item);
        }
    });
    return flat;
};
export type OwnerTreeItemFieldnames = {
    fieldname: string;
    childrenCount: number;
    children?: OwnerTreeStructure[] | null;
    ownersGuid: string;
    bIsBusiness: boolean;
};
export const flattenAddFieldname = (
    treeStructure: OwnerTreeStructure[] | undefined,
    fieldname: string,
    withChildren = false
): OwnerTreeItemFieldnames[] => {
    const flat: {
        fieldname: string;
        childrenCount: number;
        children?: OwnerTreeStructure[] | null;
        ownersGuid: string;
        bIsBusiness: boolean;
    }[] = [];
    (treeStructure ?? []).forEach((item, index) => {
        if (Array.isArray(item.children)) {
            flat.push(
                {
                    ownersGuid: item.ownersGuid,
                    fieldname: `${fieldname}[${index}]`,
                    childrenCount: (item.children ?? []).length,
                    ...(withChildren && { children: item.children }),
                    bIsBusiness: item.bIsBusiness,
                },
                ...flattenAddFieldname(item.children, `${fieldname}[${index}].children`)
            );
        } else {
            flat.push({
                ownersGuid: item.ownersGuid,
                fieldname: `${fieldname}[${index}]`,
                bIsBusiness: item.bIsBusiness,
                childrenCount: (item.children ?? []).length,
                ...(withChildren && { children: item.children }),
            });
        }
    });
    return flat;
};
export const instanceAlreadyExistsWithChildren = (
    treeStructure: OwnerTreeStructure[],
    treeFieldname: string,
    item: OwnerTreeStructure
) => {
    if (!item.bIsBusiness) return true;
    const flattened = flattenAddFieldname(treeStructure, OWNERS_STRUCTURE_FORM_STRING).filter(
        (o) => o.bIsBusiness && o.ownersGuid === item.ownersGuid
    );

    const businessWithChildren = flattened.find((o) => o.childrenCount > 0);
    const isMainInstance = businessWithChildren
        ? businessWithChildren.fieldname === treeFieldname
        : true;

    return isMainInstance;
};
export const useIsMainInstance = (fieldname: string) => {
    const [{ value: treeStructure }] = useField<OwnerTreeStructure[]>(OWNERS_STRUCTURE_FORM_STRING);
    const [{ value }] = useField<OwnerTreeStructure>(fieldname);
    const [, , { setValue: setTreeIsMainNode }] = useField<boolean | undefined>(
        `${fieldname}.isMainNode`
    );

    const isMainInstance = instanceAlreadyExistsWithChildren(treeStructure, fieldname, value);

    useEffect(() => {
        if (value.isMainNode !== isMainInstance) setTreeIsMainNode(isMainInstance);
    }, [isMainInstance]);

    return isMainInstance;
};
export const useUpdateOwnersOnTreeChange = () => {
    const [{ value: treeStructure }] = useField<OwnerTreeStructure[]>(OWNERS_STRUCTURE_FORM_STRING);
    const [{ value: individualOwners }, , { setValue: setIndividualOwners }] = useField<
        BeneficialPersonalOwner[]
    >(INDIVIDUAL_OWNERS_FORM_STRING);
    const [{ value: businessOwners }, , { setValue: setBusinessOwners }] = useField<
        BeneficialBusinessOwner[]
    >(BUSINESS_OWNERS_FORM_STRING);

    // Update owners tree when tree structure changes
    useEffect(() => {
        const flattenedTree = flatten(treeStructure).map((o) => o.ownersGuid);
        setIndividualOwners(individualOwners.filter((o) => flattenedTree.includes(o.guid)));
        setBusinessOwners(businessOwners.filter((o) => flattenedTree.includes(o.guid)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeStructure]);
};
