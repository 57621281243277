import GreenTick from 'assets/ibanera/Icon_GreenTick.png';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import FormTextField from 'components/form/FormTextField';
import SingleSelectCheckList from './SingleSelectCheckList';
import { CorporateApp } from '../corporateAppModel';
import { Type } from '../CorporateApp';
interface Props {
    mainActivityTypes: Type[];
    expandAll: boolean;
}
const MainActivity: React.FC<Props> = ({ mainActivityTypes, expandAll }) => {
    const [expand, setExpand] = useState<boolean>(false);
    const [completedSection, setCompletedSection] = useState(false);
    const { errors, touched } = useFormikContext<CorporateApp>();

    const validateSection = () => {
        if (!errors.corporate?.mainActivity && touched.corporate?.mainActivity) {
            setCompletedSection(true);
        } else {
            setCompletedSection(false);
        }
    };
    useEffect(() => {
        if (expandAll) {
            setExpand(true);
        }
    }, [expandAll]);
    useEffect(() => {
        validateSection();
        if (errors.corporate?.mainActivity) {
            setExpand(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.corporate?.mainActivity, touched.corporate?.mainActivity]);

    return (
        <div>
            <SingleSelectCheckList
                fieldName="corporate.mainActivity.typesID"
                options={mainActivityTypes}
            />
            <FormTextField
                field={'corporate.mainActivity.description'}
                label={'Description of Business / Industry'}
                type="textarea"
                required={true}
                maxLength={2000}
                sideBySide={true}
                // asterisk={true}
                className="NoResize"
            />
            <div className="FormBox">
                <RadioButtons
                    options={['US', 'Other']}
                    label={'Countries from which funds will be received*'}
                    fieldname={'corporate.mainActivity.bFundsReceivedUSA'}
                    error={true}
                    booleanField={true}
                />
            </div>
            <div className="FormBox">
                <RadioButtons
                    options={['US', 'Other']}
                    label={'Countries from which funds will be sent*'}
                    fieldname={'corporate.mainActivity.bFundsSentUSA'}
                    error={true}
                    booleanField={true}
                />
            </div>
            <div className="FormBox">
                <RadioButtons
                    options={['Yes', 'No']}
                    label={
                        'Does the passive income of the entity (dividends, interest, etc. except for the cases when such income is earned from main activities) or assets generating or kept to generate the above mentioned income exceed 50% of the entity’s annual gross income per calendar year or its total assets?*'
                    }
                    fieldname={'corporate.mainActivity.bPassiveIncomeExceedsFiftyPercent'}
                    error={true}
                    booleanField={true}
                />
            </div>
        </div>
    );
};

export default MainActivity;
