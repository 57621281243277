import { SchemaOf, ValidationError } from 'yup';
import { FormValues } from '../businessVerificationSchema';
import { processedErrors } from './ExitContinueButtons';
import { FormikErrors, FormikValues } from 'formik';
import _ from 'lodash';
import { DocumentRejection } from '../BusinessVerification';
import {
    BackendBusinessVerificationStage,
    BusinessVerificationStage,
} from '../businessVerificationModels';

interface DeepObject {
    [key: string]: any;
}

export function findPath(obj: Record<string, any>, targetValue: any): string | undefined {
    function search(
        currentObject: Record<string, any>,
        target: any,
        currentPath: string
    ): string | undefined {
        if (_.isObject(currentObject)) {
            for (const key in currentObject) {
                if (Object.prototype.hasOwnProperty.call(currentObject, key)) {
                    const value = currentObject[key as keyof typeof currentObject];
                    const newPath = currentPath ? `${currentPath}.${key}` : key;
                    if (value === target) {
                        return newPath;
                    }
                    if (_.isObject(value) || _.isArray(value)) {
                        const result = search(value as Record<string, any>, target, newPath);
                        if (result) {
                            return result;
                        }
                    }
                }
            }
        }
        return;
    }
    return search(obj, targetValue, '');
}

export const findDifferences = (
    submitValues: DeepObject,
    formValues: DeepObject,
    path: string = ''
): string[] => {
    let differences: string[] = [];

    Object.keys(submitValues).forEach((key) => {
        const fieldPath = path ? `${path}.${key}` : key;

        if (!(key in formValues)) {
            console.error('ERROR - fields differ');
            return;
        }

        if (
            typeof submitValues[key] === 'object' &&
            submitValues[key] !== null &&
            typeof formValues[key] === 'object' &&
            formValues[key] !== null
        ) {
            if (Array.isArray(submitValues[key]) && Array.isArray(formValues[key])) {
                if (submitValues[key].length !== formValues[key].length) {
                    differences.push(fieldPath);
                } else {
                    submitValues[key].forEach((item: any, index: number) => {
                        const innerDifferences = findDifferences(
                            item,
                            formValues[key][index],
                            `${fieldPath}.${index}`
                        );
                        differences = [...differences, ...innerDifferences];
                    });
                }
            } else {
                const innerDifferences = findDifferences(
                    submitValues[key],
                    formValues[key],
                    fieldPath
                );
                differences = [...differences, ...innerDifferences];
            }
        } else if (submitValues[key] !== formValues[key]) {
            differences.push(fieldPath);
        }
    });

    return differences;
};

export function transformDotNotationObject(obj: FormikErrors<any>) {
    return _.transform(
        obj,
        (result, value, key) => {
            _.set(result as object, key, value);
        },
        {}
    );
}

function validate(
    values: FormikValues,
    submitValues: FormValues | undefined,
    apiErrors: processedErrors,
    validationSchema: SchemaOf<any>,
    rejectedDocuments: DocumentRejection | null | undefined,
    stage: BusinessVerificationStage
) {
    const handleRejected = (
        rejectedDocuments: DocumentRejection | null | undefined,
        stage: BusinessVerificationStage | BackendBusinessVerificationStage
    ) => {
        let rejectedDocErrors: FormikErrors<any>[] = [];
        let path;
        if (rejectedDocuments) {
            for (let key in rejectedDocuments) {
                if (
                    rejectedDocuments[key as keyof typeof rejectedDocuments] &&
                    key === 'proofOfAddress' &&
                    stage === BusinessVerificationStage.SignificantParties
                ) {
                    rejectedDocuments.proofOfAddress?.forEach((fileName: string) => {
                        let path = findPath(values, fileName);
                        if (path) {
                            rejectedDocErrors.push({
                                [`${path}`]: `${fileName} has been rejected please upload another`,
                            });
                        }
                    });
                }
                if (
                    rejectedDocuments[key as keyof typeof rejectedDocuments] &&
                    key === 'authorizedCapitalDocument' /* &&
                    stage === BackendBusinessVerificationStage.CorporateApplication */
                ) {
                    path = findPath(values, rejectedDocuments.authorizedCapitalDocument);
                    if (path) {
                        rejectedDocErrors.push({
                            [`${path}`]: `${rejectedDocuments.authorizedCapitalDocument} document has been rejected please upload another`,
                        });
                    }
                }
                if (
                    rejectedDocuments[key as keyof typeof rejectedDocuments] &&
                    key === 'fundsSupportingDocuments' /* &&
                    stage === BackendBusinessVerificationStage.CorporateApplication */
                ) {
                    rejectedDocuments.fundsSupportingDocuments?.forEach((fileName: string) => {
                        let path = findPath(values, fileName);
                        if (path) {
                            rejectedDocErrors.push({
                                [`${path}`]: `${fileName} has been rejected please upload another`,
                            });
                        }
                    });
                }
            }
        }
        return rejectedDocErrors;
    };

    return new Promise(async function (resolve, reject) {
        const errorObject = await validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                let rejectedDocErrors = handleRejected(rejectedDocuments, stage);
                if (submitValues) {
                    let errors: FormikErrors<any> = {};
                    const diff = findDifferences(submitValues, values);
                    if (apiErrors.length > 0) {
                        apiErrors.forEach((error) => {
                            if (diff && (!diff.includes(error.fieldName) || diff.length === 0)) {
                                errors = { ...errors, [error.fieldName]: error.message };
                            }
                        });
                    }
                    if (rejectedDocErrors.length > 0) {
                        rejectedDocErrors.forEach((error) => {
                            errors = { ...errors, ...error };
                        });
                    }
                    if (Object.keys(errors) && Object.keys(errors).length > 0) {
                        return transformDotNotationObject(errors);
                    }
                }
            })
            .catch((e) => {
                if (submitValues) {
                    let rejectedDocErrors = handleRejected(rejectedDocuments, stage);
                    if (e instanceof ValidationError) {
                        let errors: FormikErrors<any> = e.inner.reduce(
                            (acc: FormikErrors<any>, currentError) => {
                                const errorPath = currentError.path as string;
                                if (errorPath && !acc[errorPath]) {
                                    acc[errorPath] = currentError.message;
                                }
                                return acc;
                            },
                            {}
                        );
                        const diff = findDifferences(submitValues, values);
                        if (apiErrors.length > 0) {
                            apiErrors.forEach((error) => {
                                if (
                                    diff &&
                                    (!diff.includes(error.fieldName) || diff.length === 0)
                                ) {
                                    errors = { ...errors, [error.fieldName]: error.message };
                                }
                            });
                        }
                        if (rejectedDocErrors.length > 0) {
                            rejectedDocErrors.forEach((error) => {
                                errors = { ...errors, ...error };
                            });
                        }
                        if (Object.keys(errors) && Object.keys(errors).length > 0) {
                            return transformDotNotationObject(errors);
                        }
                    }
                }
                if (e instanceof ValidationError)
                    return transformDotNotationObject(
                        e.inner.reduce((acc: FormikErrors<any>, currentError) => {
                            const errorPath = currentError.path as string;
                            if (errorPath && !acc[errorPath]) {
                                acc[errorPath] = currentError.message;
                            }
                            return acc;
                        }, {})
                    );
            });
        resolve(errorObject);
    });
}

export default validate;
