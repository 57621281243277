import React, { useState } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '@avamae/hooks';

type FormFieldTooltipProps = {
    tooltip: React.ReactNode;
    tooltipIconData?: TooltipIconData | null;
};

export type TooltipIconData = { mainIcon?: string; closeIcon?: string }; // NOTE: pass in require("<image source>") rther than the "<image source>" string

const FormFieldTooltip: React.FC<FormFieldTooltipProps> = ({ tooltip, tooltipIconData }) => {
    const [showTooltipText, setShowTooltipText] = useState<boolean>(false);
    const { ref } = useOnClickOutside(() => {
        setShowTooltipText(false);
    });

    return (
        <div className="FormTooltipContainer">
            <div
                className="TooltipIconContainer"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowTooltipText((curr) => !curr);
                    e.currentTarget.parentElement?.parentElement?.querySelector('input')?.focus();
                }}
            >
                {tooltipIconData?.mainIcon ? (
                    <img
                        src={
                            showTooltipText
                                ? tooltipIconData?.closeIcon ?? tooltipIconData.mainIcon
                                : tooltipIconData?.mainIcon
                        }
                        alt="Tooltip clickable icon"
                        className="TooltipMainIcon"
                    />
                ) : (
                    <div className={classNames('TextTooltipIcon', { Opened: showTooltipText })}>
                        i
                    </div>
                )}
            </div>

            {showTooltipText && (
                <>
                    <div className="TooltipArrow"></div>
                    <div className="TooltipTextContainer" ref={ref}>
                        <div className="Text">{tooltip}</div>
                    </div>
                </>
            )}
        </div>
    );
};

export { FormFieldTooltip };
