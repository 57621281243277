import { useField } from 'formik';
import React from 'react';
import { RadioButton } from './radiobuttons';
import { ErrorM } from '../pagination/ErrorM';

type RadioOption = {
    label: string;
    subLabel?: string;
    value: number | string;
};

type Props = {
    className?: string;
    options: RadioOption[];
    fieldname: string;
    label?: string;
    customOnChange?(): void;
    disabled?: boolean;
    loading?: boolean;
};

export const RadioIdButtons: React.FC<Props> = ({
    className,
    options,
    fieldname,
    label,
    customOnChange,
    disabled = false,
    loading = false,
}) => {
    const [field, meta, { setTouched, setValue }] = useField<string | number>(fieldname);

    const onClickHandler = (option: RadioOption) => {
        setValue(option.value);
        customOnChange && customOnChange();

        setTouched(true);
    };

    let errorDefined = meta.error && meta.touched ? true : false;

    if (options.length === 0) return null;
    return (
        <>
            {label && (
                <div className="FormLabel">
                    <label>{label}</label>
                </div>
            )}
            <div
                className={`RadioIdButtons RadioButtons ${className ?? ''}${
                    errorDefined ? ' NoMargin' : ''
                }`}
            >
                {!loading &&
                    options.map((option, index) => (
                        <RadioButton
                            key={index}
                            label={option.label}
                            subLabel={option.subLabel}
                            selected={option.value === field.value}
                            onClick={() => onClickHandler(option)}
                            error={errorDefined}
                            disabled={disabled}
                        />
                    ))}
                {loading && <div className="LoadingOptions" />}
            </div>
            {errorDefined && (
                <div className="RadioButtons--error">
                    <ErrorM name={fieldname} />
                </div>
            )}
        </>
    );
};
