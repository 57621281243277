import { differenceInSeconds, format } from 'date-fns';
import { plaintext } from 'plaintext.config';
import RefreshIcon from 'assets/ibanera/refresh.svg';
import { OrderDetails, TradeCrypto } from './Crypto';
import { useEffect, useMemo, useState } from 'react';
import TFAForm from 'pages/signIn/TFAForm';
import { usePageTranslations } from 'helpers/usePageTranslations';
import { SignInTranslation } from 'pages/signIn/signInTypes';
import { PageResourceKey } from 'appConstants';
import { TFAField } from 'components/form/TFAField';
import { useTFAField } from 'helpers/useTFAField';
import Button from 'components/button/Button';
import { Form, useFormikContext } from 'formik';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { useSelector } from 'react-redux';
import { selectCurrencySymbols } from 'reducers/currencySymbols';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';

type ReviewOrderFormProps = {
    back: () => void;
    orderDetails: OrderDetails;
    refreshOrder: () => void;
    errorMessage: string;
    hideSubmitButton?: boolean;
    noFormComponent?: boolean;
};
export const ReviewOrderForm: React.FC<ReviewOrderFormProps> = ({
    back,
    orderDetails,
    refreshOrder,
    errorMessage,
    hideSubmitButton,
    noFormComponent,
}) => {
    const [tfaType, toggleTfaType] = useTFAField();
    const { isSubmitting, submitForm, values, setFieldValue } = useFormikContext<TradeCrypto>();
    const currencySymbols = useSelector(selectCurrencySymbols);
    const pairedAssetSymbol = currencySymbols[orderDetails.pairedAsset];
    useEffect(() => {
        setFieldValue('tfaType', tfaType);
    }, [tfaType, setFieldValue]);

    const formContents = (
        <>
            <div className="LinkText" onClick={back}>
                {'< Back'}
            </div>
            {/* <OrderTimer
    expiryDateString={orderDetails.validUntilUtcDate}
    refreshOrder={refreshOrder}
/> */}
            <h2>{`${orderDetails.assetExchangeType} Order Summary`}</h2>
            <div className="OrderSummaryContainer">
                <div className="SummarySection">
                    <p>
                        Account{' '}
                        <span className="FloatRight">
                            <AssetCodeFormatter assetCode={orderDetails.asset} /> account
                        </span>
                    </p>
                </div>
                <div className="SummarySection">
                    <p>
                        Amount of <AssetCodeFormatter assetCode={orderDetails.asset} />{' '}
                        <span className="FloatRight">{orderDetails.amount}</span>
                    </p>
                    <p>
                        {`${orderDetails.assetExchangeType === 'Buy' ? 'Price' : 'Value'} in ${
                            orderDetails.pairedAsset
                        }`}
                        <span className="FloatRight">{orderDetails.price}</span>
                    </p>
                </div>
                <div className="SummarySection">
                    <p>
                        Network fee <span className="FloatRight">{orderDetails.networkFee}</span>
                    </p>
                </div>
                <div className="SummarySection">
                    <p>
                        Commission <span className="FloatRight">{orderDetails.commissionFee}</span>
                    </p>
                </div>
                <p>
                    <b>
                        {' '}
                        {orderDetails.assetExchangeType === 'Sell' ? 'Total payout' : 'Total due'}
                    </b>{' '}
                    <span className="FloatRight">
                        {`${pairedAssetSymbol ?? ''}${orderDetails.totalPrice} `}
                        <AssetCodeFormatter assetCode={orderDetails.pairedAsset} />
                    </span>
                </p>
                {orderDetails.assetExchangeType === 'Sell' && (
                    <p>
                        <b>Transfer type</b>{' '}
                        <span className="FloatRight">{values.transferType}</span>
                    </p>
                )}
            </div>
            <TFAField
                field={'tfaCode'}
                label={'TFA Code'}
                required={true}
                toggleTFAType={
                    toggleTfaType
                        ? () => {
                              toggleTfaType();
                              setFieldValue(
                                  'tfaType',
                                  values.tfaType === 'SMS' ? 'AuthenticatorApp' : 'SMS'
                              );
                          }
                        : null
                }
                tfaType={tfaType}
                holdFocus
                autoFocus
            />
            {!hideSubmitButton && (
                <Button type="submit" variety="full" disabled={isSubmitting}>
                    Submit
                </Button>
            )}
            {errorMessage && <div className="ErrorLabel">{errorMessage}</div>}
        </>
    );
    return noFormComponent ? (
        <div className="ContentBox LeftAlign">{formContents}</div>
    ) : (
        <Form className="ContentBox LeftAlign">{formContents}</Form>
    );
};
type OrderTimerProps = {
    expiryDateString: string;
    refreshOrder: () => void;
};
const OrderTimer: React.FC<OrderTimerProps> = ({ expiryDateString, refreshOrder }) => {
    const expiryDate = useMemo(() => new Date(expiryDateString), [expiryDateString]);

    const calculateTimeLeft = (expiryDate: Date) => differenceInSeconds(expiryDate, Date.now());
    const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft(expiryDate));
    useEffect(() => {
        if (timeLeft > 0) {
            setTimeout(() => setTimeLeft(calculateTimeLeft(expiryDate)), 1000);
        } else {
            const t = calculateTimeLeft(expiryDate);
            if (t > 0) setTimeLeft(t);
        }
    }, [timeLeft, expiryDate]);

    return (
        <div className="Refresh">
            <div>
                <p>
                    {plaintext.cryptoPage.currentTime}{' '}
                    <span>{format(new Date(), 'dd-MM-yyyy hh:mm:ss')}</span>
                </p>
                {timeLeft && timeLeft > 0 ? (
                    <p>
                        {plaintext.cryptoPage.validFor}
                        <span>{timeLeft} seconds</span>
                    </p>
                ) : (
                    <p className="ErrorLabel">offer expired</p>
                )}
            </div>
            <div className="RefreshBtn">
                <img src={RefreshIcon} alt="icon" onClick={refreshOrder} />
            </div>
        </div>
    );
};
