import { Store } from './rootReducer';
import { Theme } from '@emotion/react';
import { initialTheme } from '../theme';

/* STATE */
type State = {
    theme: Theme;
    companyName: string;
    imagesPath: string;
    bPersonalAccountsEnabled: boolean | null;
    bBusinessAccountsEnabled: boolean | null;
};

const initialState: State = {
    theme: initialTheme,
    companyName: '',
    imagesPath: '',
    bBusinessAccountsEnabled: null,
    bPersonalAccountsEnabled: null,
};

/* ACTIONS */
const SET_BRANDING = 'app/ui/SET_BRANDING';

type Action = { type: typeof SET_BRANDING; payload: Partial<State> };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case SET_BRANDING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const setBranding = (payload: Partial<State>): Action => {
    return {
        type: SET_BRANDING,
        payload,
    };
};

/* SELECTORS */
export const selectTheme = (app: Store): Theme => app.ui.theme;
export const selectAllowedAccounts = (app: Store) => [
    app.ui.bBusinessAccountsEnabled,
    app.ui.bPersonalAccountsEnabled,
];
export const selectImagePath = (app: Store): string => app.ui.imagesPath;
