// Passed under the streamCode key in signalR messages
export enum NotificationType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

// The shape of notification data is slightly different between the notifications
// in the initial call to get the list of notifications and those pushed by the
// SignalR websocket. Could look to align the two in the future to use a single type.

// Returned when calling the REST endpoint to fetch the initial set of notifications.
export type InitialNotification = {
    id: number;
    title: string;
    content: string;
    addDateUtc: string;
    addDateLocal: string;
    streamsId: number;
    levelsId: number;
};

type NotificationLevel = {
    id: number;
    code: NotificationType;
};

export type NotificationsStackResponse = {
    notifications: InitialNotification[];
    levels: NotificationLevel[];
};

export type SignalRNotificationLevel = {
    id: number;
    code: NotificationType;
    themeJson: Record<string, string>;
};

// Pushed by the SignalR websocket.
export type SignalRNotificationMessage = {
    id: 1049;
    title: string;
    content: string;
    level: SignalRNotificationLevel;
    addDateUtc: string;
    streamCode: string;
};

export type NotificationUserPreference = {
    streamsId: number;
    channelsId: number;
    bEnabled: boolean;
};

export type NotificationChannel = {
    id: number;
    code: string;
    displayName: string;
    languagesResourceKey_DisplayName: string | null;
};

export type NotificationStream = {
    id: number;
    code: string;
    description: string;
    languageResourcesKey_Description: string | null;
    bRequired: boolean;
};

export type NotificationsUserPreferences = {
    userPreferences: NotificationUserPreference[];
    channels: NotificationChannel[];
    streams: NotificationStream[];
};
