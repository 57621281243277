import instance from 'api';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTFAType } from 'reducers/auth';
import { TFAType } from 'pages/register/models';
import { Toast, ToastMessageReason } from './toast';

export const useTFAField = (enabled = true) => {
    const activeTFAType = useSelector(selectTFAType);
    const dispatch = useDispatch();

    const [canSwitch, _] = useState(() => activeTFAType === 'both');
    const [currentTFAType, setCurrentTFAType] = useState<TFAType | null>(null);
    useEffect(
        () =>
            setCurrentTFAType(
                activeTFAType === 'both'
                    ? 'AuthenticatorApp'
                    : activeTFAType === 'app'
                    ? 'AuthenticatorApp'
                    : activeTFAType === 'sms'
                    ? 'SMS'
                    : null
            ),
        [activeTFAType]
    );
    // Can toggle if toggleTFAType function is non-null.
    const toggleTFAType = canSwitch
        ? () => {
              setCurrentTFAType((prevValue) =>
                  prevValue === 'AuthenticatorApp' ? 'SMS' : 'AuthenticatorApp'
              );
          }
        : null;

    const sendTfaCode = () => {
        instance
            .post(endpoints.profilemodule.requestSmsTfaCode)
            .then((res) => Toast.openToastMessage('SMS TFA code sent', ToastMessageReason.PENDING))
            .catch((err) =>
                Toast.openToastMessage(
                    'There was an error sending your SMS code',
                    ToastMessageReason.ERROR
                )
            );
    };

    useEffect(() => {
        if (currentTFAType === 'SMS' && enabled) {
            sendTfaCode();
        }
    }, [currentTFAType, enabled, dispatch]);

    return [currentTFAType ?? 'AuthenticatorApp', toggleTFAType, sendTfaCode] as const;
};
