import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { endpoints } from 'endpoints.config';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectVerificationDetails,
    setVerificationStage,
    updateAdditionalData,
} from 'reducers/verification';
import { JumioStage, JumioVerificationStatusResponse } from './jumioModels';

//icons
import WarningIcon from 'assets/Icon_Warning.png';
import { Toast } from 'helpers/toast';

export const JumioFailure = ({ bManualAvailable }: { bManualAvailable: boolean }) => {
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { stage } = useSelector(selectVerificationDetails);

    const handleManualInterventionRequest = () => {
        setSubmitting(true);
        instance
            .post<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.requestManualVerification
            )
            // This endpoint just updates the additional data, it won't change page
            .then((res) => {
                dispatch(updateAdditionalData(res.data.details.additionalData));

                Toast.openSuccessToast(
                    'Our support team has been alerted, one of them will contact you by E-mail soon'
                );
            })
            .catch(() => {
                Toast.openGenericErrorToast();
            })
            .finally(() => setSubmitting(false));
    };

    const handleRetry = async () => {
        setSubmitting(true);
        try {
            const res = await instance.get<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.verificationStatus,
                {
                    params: { retry: true },
                }
            );
            dispatch(
                setVerificationStage({
                    currentStage: res.data.details.status,
                    additionalData: res.data.details.additionalData,
                })
            );
        } catch (err) {
            Toast.openGenericErrorToast();
            setSubmitting(false);
        }
    };
    const zoho = (window as any).$zoho.salesiq;
    const handleZoho = () => {
        const question =
            stage === JumioStage.AddressFrameFailed
                ? 'I need some help verifying my address'
                : 'I need some help verifying my ID';
        zoho.visitor.question(question);
        zoho.chat.start();
    };
    return (
        <div className="VerificationError">
            <img src={WarningIcon} alt="error" />
            <h3>An Error has occured</h3>
            <p>We could not verify your details, please either try again or contact us</p>
            {zoho && (
                <Button priority="secondary" onClick={handleZoho}>
                    Live chat
                </Button>
            )}
            <Button disabled={submitting} onClick={handleRetry}>
                Retry
            </Button>

            {bManualAvailable && (
                <Button
                    disabled={submitting}
                    className="ManualInterventionBtn"
                    onClick={handleManualInterventionRequest}
                    priority="secondary"
                >
                    Request help
                </Button>
            )}
        </div>
    );
};
