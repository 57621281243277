import React from 'react'
import { SummaryItem } from './SummaryItem';

type CryptoContentProps = {
    subject: string;
    name: string;
    ticker: string;
    summaryItems: any;
    description?: string;
    link?: boolean;
}

const CryptoContent: React.FC<CryptoContentProps> = ({ subject, name, ticker, summaryItems, description, link }) => {
    return (
        <div className="CryptoContent">
            <h2>{`${subject} ${name} (${ticker})`}</h2>
            <div className="SummaryContainer">
                {summaryItems.map((i: any) => (
                    <SummaryItem title={i.title} content={i.content} value={i.value} />
                ))}
            </div>
            {description &&
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
            }
        </div>
    )
}

export { CryptoContent };
