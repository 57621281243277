import instance, { ApiResponse } from 'api';
import { ActionsObservable, combineEpics, Epic, ofType } from 'redux-observable';
import { empty, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from './rootReducer';
import { UserInformation } from 'components/navigation/Navigation';

export enum BusinessVerificationFlow {
    v1 = 'v1',
    v2 = 'v2',
    v3 = 'v3',
}
export enum CryptoOtcDeskVersion {
    v1 = 'v1',
    v2 = 'v2',
}

/* STATE */
type State = {
    businessVerificationFlow: BusinessVerificationFlow;
};

const initialState: State = {
    businessVerificationFlow: BusinessVerificationFlow.v1,
};

/* ACTIONS */
const SET_APP_SETTINGS = 'app/appSettings/SET_APP_SETTINGS';
const DELETE_APP_SETTINGS = 'app/appSettings/DELETE_APP_SETTINGS';
const SET_COMPONENT_RESOURCES = 'app/resources/SET_COMPONENT_RESOURCES';

type Action =
    | {
          type: typeof SET_APP_SETTINGS;
          payload: Partial<State>;
      }
    | {
          type: typeof SET_COMPONENT_RESOURCES;
          payload: { userInfo?: UserInformation };
      }
    | { type: typeof DELETE_APP_SETTINGS };

enum EpicActionType {
    GET_APP_SETTINGS = 'app/appSettings/GET_APP_SETTINGS',
}
type EpicAction = { type: EpicActionType };

/* REDUCER */
export default function reducer(
    state = initialState,
    action: Action | { type: EpicActionType }
): State {
    switch (action.type) {
        case SET_APP_SETTINGS:
            return { ...state, ...action.payload };
        case SET_COMPONENT_RESOURCES:
            if (action.payload?.userInfo?.onboardingVersion) {
                return {
                    ...state,
                    businessVerificationFlow: action.payload.userInfo.onboardingVersion,
                };
            }
            return state;

        case DELETE_APP_SETTINGS:
            return initialState;
        default:
            return state;
    }
}

// Epics
const fetchAppSettings = () => {
    return instance.get<ApiResponse>('/SOME_ENDPOINT_HERE');
};

export const getAppSettingsEpic: Epic = (action$: ActionsObservable<EpicAction>) =>
    action$.pipe(
        ofType(EpicActionType.GET_APP_SETTINGS),
        switchMap(() =>
            from(fetchAppSettings()).pipe(
                map(() => empty())
                //TODO
            )
        )
    );

export const appSettingsEpic = combineEpics(getAppSettingsEpic);

/* ACTION CREATORS */
export const setAppSettings = (payload: State): Action => {
    return { type: SET_APP_SETTINGS, payload };
};
export const deleteAppSettings = (): Action => {
    return {
        type: DELETE_APP_SETTINGS,
    };
};

/* SELECTORS */
export const selectAppSettings = (store: Store) => store.appSettings;
