import { useTable } from 'api';
import { HistoryTable } from 'components/historyTable/HistoryTable';
import Pagination from 'components/pagination/Pagination';
import { TableSearchInput } from 'components/tableSearchInput/TableSearchInput';
import { useDebouncedState } from 'helpers/useDebouncedState';
import { CryptoLiveData } from 'pages/crypto/CryptoPage';
import React, { useContext, useEffect } from 'react';
import { CryptoContext } from './CryptoPage';
import { splitCryptoPairString } from './useCryptoContextEffects';

export type Data = {
    crypto__Id: number;
    crypto__Ticker: string;
    crypto__Name: string;
    crypto__Price: number;
    crypto__24hr: number;
    crypto__PercentChnge: number;
};

const CryptoOrderHistory: React.FC = () => {
    const { endpoints, selectedCryptoPair, cryptos } = useContext(CryptoContext);
    const { crypto: cryptoTicker } = splitCryptoPairString(selectedCryptoPair as string);
    const table = useTable({
        url: endpoints.historyList,
        filters: `Assets__Code EQ (${cryptoTicker})`,
        multiSort: false,
    });
    const [searchString, setSearchString, localSearchString] = useDebouncedState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.currentTarget.value);
    };

    useEffect(() => {
        table.data?.actions.changeSearch(searchString);
    }, [searchString]);

    const selectedCrypto = cryptos.find(
        (crypto) => crypto.pair === selectedCryptoPair
    ) as CryptoLiveData;

    if (!table.data || table.data?.details.listData.length === 0) {
        return null;
    }
    return (
        <div className="CryptoHoldings">
            <div className="MarketTableSearch OrderHistory">
                <h2>{`Order History (${selectedCrypto.ticker})`}</h2>
                <TableSearchInput
                    value={localSearchString}
                    onChange={handleSearch}
                    placeholder={'Search all references'}
                />
            </div>
            <HistoryTable idColumn="" table={table} simpleReferenceCell />
            <Pagination table={table} />
        </div>
    );
};

export { CryptoOrderHistory };
