/** @jsxImportSource @emotion/react */
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { Link, navigate } from '@reach/router';
import instance, { isErrorHandled, useFetch } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { FormDropdownField } from 'components/pagination/Dropdown';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { CurrencyDetails } from 'pages/register/models';
import { plaintext } from 'plaintext.config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCustomerComponentResources,
    selectAccountTypePathItem,
} from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

import FillerImage from 'assets/ibanera/IbaneraBounce.gif';
import { css, useTheme } from '@emotion/react';
import { Toast, ToastMessageReason } from 'helpers/toast';
import * as Yup from 'yup';
import { getErrorMessage } from 'errors';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Spinner } from 'components/spinner/Spinner';
import { FormBuilder } from 'components/form/FormBuilder';
import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import logger from 'helpers/logger';
import { ModalTypes, openModal, PendingCard } from 'reducers/modal';

type FormData = {
    businessActivityType?: string;
    complianceFieldOptions: any;
    currency?: string;
    customerSubtype?: string;
    occupationType?: string;
    sourceOfFundsType?: string;
    taxNumber?: string;
};

const validationSchema = Yup.object({
    currency: Yup.string()
        .typeError('Please select a currency')
        .required('Please select a currency'),
    customerSubtype: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    businessActivityType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    occupationType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    sourceOfFundsType: Yup.number()
        .typeError('Please select an option')
        .required('Please select an option'),
    taxNumber: Yup.string().required('Please enter your tax number'),
});

export const AddCard = () => {
    const theme = useTheme();

    const addAccountCss = css({ h3: { color: theme.colors.second } });
    const dispatch = useDispatch();

    const translations = plaintext.addCardPage;

    const { loading, error, data } = useFetch<ApiResponse<FormData>>(endpoints.cards.addCard);

    const handleSubmit = (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
        instance
            .post<ApiResponse<{ cardUrlPath: string }>>(
                endpoints.cards.addCard /* 'localhost:1234' */,
                values
            )
            .then((res) => {
                if (res?.data?.details?.bReadyForActivation) {
                    dispatch(getCustomerComponentResources());
                    return navigate(`../${res.data.details.cardUrlPath}`);
                } else {
                    const newPendingCard: PendingCard = {
                        id: res?.data?.details?.topUpCardsId,
                        cardUrlPath: res?.data?.details?.cardUrlPath,
                        bReadyForActivation: res?.data?.details?.bReadyForActivation,
                    };

                    dispatch(
                        openModal({ modalType: ModalTypes.PENDING_CARD, data: newPendingCard })
                    );
                }
            })
            .catch((err) => {
                if (isErrorHandled(err)) {
                    formikHelpers.setSubmitting(false);
                    let errorToast = false;
                    //iterate through the errors returned by the api and set error messages on the appropriate fields
                    err.response.data.errors.forEach((error) => {
                        const fieldName = toCamelCase(error.fieldName) as string;
                        if (Object.keys(values).includes(fieldName)) {
                            formikHelpers.setFieldError(
                                fieldName,
                                getErrorMessage(error.messageCode)
                            );
                        } else if (!errorToast) {
                            errorToast = true;
                            Toast.openToastMessage(
                                getErrorMessage(error.messageCode),
                                ToastMessageReason.ERROR
                            );
                        }
                    });
                } else {
                    formikHelpers.setSubmitting(false);
                    Toast.openGenericErrorToast();
                }
            });
    };
    return (
        <div className="AddAccountPage" css={addAccountCss}>
            <div className="LeftSide">
                <h3>{translations.addNewCard}</h3>
                <p>{translations.blurb}</p>
                {loading ? (
                    <Spinner />
                ) : (
                    data && (
                        <Formik initialValues={data?.details} onSubmit={handleSubmit}>
                            {({ isSubmitting }: FormikProps<any>) => (
                                <Form className="FormBuilderForm">
                                    <FormBuilder loadingFallback={<Spinner />} data={data} />

                                    <Button variety="full" type="submit" disabled={isSubmitting}>
                                        {translations.buttonText}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    )
                )}
            </div>
            <img src={FillerImage} alt="" className="FillerImage" />
        </div>
    );
};
