import Button from 'components/button/Button';
import { FieldArray, useFormikContext } from 'formik';
import SignificantParty from './SignificantParty';
import ExitContinueButton from '../../helpers/ExitContinueButtons';
import { useEffect, useRef, useState } from 'react';
import StageIndicator from '../../helpers/StageIndicator';
import { BusinessVerificationStage } from '../../businessVerificationModels';
import instance, { ApiResponse } from 'api';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import useValidate from '../../helpers/touchAllFields';
import { ErrorM } from 'components/form/ErrorM';
import useScrollToError from 'components/businessVerification/helpers/useScrollToError';
import { FormValues } from 'components/businessVerification/businessVerificationSchema';
import { DocumentRejection } from 'components/businessVerification/BusinessVerification';
interface Props {
    values: any;
    submitError: boolean;
    submitErrorField: string;
    setSubmitError: React.SetStateAction<any>;
    rejectedDocuments: DocumentRejection | null | undefined;
}

export type State = { stateProvinceName: string; ansiCode: string };
interface DropdwonData {
    countries: {
        isoCode: string;
        countryName: string;
        states: State[] | null;
    }[];
}
export interface SignificantPartyValues {
    _id: number;
    number: number;
    firstName: string;
    lastName: string;
    title: string;
    dateOfBirth: string;
    nationality: string;
    email: string;
    phoneNumber: string;
    country: string;
    ssn: string;
    stateProvince: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    postcode: string;
    percentageSharesOwned: number | null;
    position: string;
    bControllingParty: boolean | null;
    proofOfAddressFilename: string;
}
export interface SignificantPartiesValues {
    significantParties: SignificantPartyValues[];
    lastId: number;
}
const significantPartyValues = {
    _id: 0,
    number: 0,
    firstName: '',
    lastName: '',
    title: '',
    dateOfBirth: '',
    nationality: '',
    email: '',
    phoneNumber: '',
    country: '',
    ssn: '',
    stateProvince: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    percentageSharesOwned: null,
    position: '',
    bControllingParty: null,
    proofOfAddressFilename: '',
};

const SignificantParties: React.FC<Props> = ({
    values,
    submitError,
    submitErrorField,
    setSubmitError,
    rejectedDocuments,
}) => {
    const nextIdRef = useRef<number>(1);
    const { isSubmitting, isValidating, setFieldTouched } = useFormikContext<FormValues>();
    const [availableCountries, setAvailableCountries] = useState<
        (SelectOption & { states: SelectOption[] | null })[]
    >([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const getDropdownData = async () => {
        try {
            const res = await instance.get<ApiResponse<DropdwonData>>(
                endpoints.businessverificationmodule.getDropdownData
            );

            if (res.data.status === '1') {
                setAvailableCountries(
                    res.data.details.countries.map((country) => ({
                        value: country.isoCode,
                        label: country.countryName,
                        states: country.states
                            ? country.states.map((state) => ({
                                  label: state.stateProvinceName,
                                  value: state.ansiCode,
                              }))
                            : null,
                    }))
                );
            } else {
                setAvailableCountries([]);
            }
        } catch (err) {
            Toast.openGenericErrorToast();
            setAvailableCountries([]);
        }
    };

    useEffect(() => {
        getDropdownData();
    }, []);

    useValidate(setFieldTouched, { significantParties: values.significantParties });

    let expandAll = useScrollToError(
        submitError,
        submitErrorField,
        isExpanded,
        setIsExpanded,
        setSubmitError
    );

    return (
        <>
            <StageIndicator currentStage={BusinessVerificationStage.SignificantParties} />
            <div className="SignificantParties">
                <h1>Information about significant parties</h1>
                <p>
                    Please add details for all significant parties of the entity (either beneficial
                    owners and/or control persons).
                </p>
                <ErrorM name="significantParties" />
                <FieldArray
                    name="significantParties"
                    render={(arrayHelpers) => (
                        <div>
                            {values.significantParties && values.significantParties?.length > 0 ? (
                                values.significantParties.map(
                                    (significantParty: any, index: number) => (
                                        <SignificantParty
                                            key={index}
                                            index={index}
                                            availableCountries={availableCountries}
                                            arrayHelpers={arrayHelpers}
                                            disableRemove={values.significantParties.length === 1}
                                            expandAll={expandAll}
                                            setIsExpanded={setIsExpanded}
                                            rejectedDocuments={rejectedDocuments}
                                            startExpanded={index === 0}
                                        />
                                    )
                                )
                            ) : (
                                <div></div>
                            )}
                            <Button
                                type="button"
                                priority="secondary"
                                variety="full"
                                onClick={() => {
                                    arrayHelpers.push({
                                        ...significantPartyValues,
                                        _id: nextIdRef,
                                    });
                                    nextIdRef.current++;
                                }}
                            >
                                Add Significant Party
                            </Button>
                        </div>
                    )}
                />
                <ErrorM name="significantParties" />
                <ExitContinueButton disabled={isSubmitting || isValidating} />
            </div>
        </>
    );
};

export default SignificantParties;
