import { useField, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    FormValues,
    generateBeneficialBusinessOwner,
    generateBeneficialPersonalOwner,
    OwnerTreeStructure,
} from '../../schema';
import { DeleteModal } from './DeleteModal';
import { SingleTreeItem } from './SingleTreeItem';
import { TreeActionButtons } from './TreeActionButtons';
import { SelectOption } from '../../../form/Dropdown';
import { UpdateIndividualModal } from './UpdateIndividualModal';
import { UpdateBusinessModal } from './UpdateBusinessModal';
import { ErrorM } from '../../../form/ErrorM';
import { useMapNumbering } from './useMapNumbering';
import {
    flatten,
    flattenAddFieldname,
    OwnerTreeItemFieldnames,
    useUpdateOwnersOnTreeChange,
} from './helpers';

export const INDIVIDUAL_OWNERS_FORM_STRING = 'ownerInformation.individualBeneficialOwners';
export const BUSINESS_OWNERS_FORM_STRING = 'ownerInformation.businessBeneficialOwners';
export const OWNERS_STRUCTURE_FORM_STRING = 'ownerInformation.beneficialOwnersStructure';

export type DeletedOwnersState = {
    businessesToUpdate: { fieldname: string; children: OwnerTreeStructure[] }[];
    owner: OwnerTreeStructure;
    parentFieldname: string;
};

type Props = {
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    entityTypes: SelectOption[];
};
// TODO: Deletion logic needs streamlining. Copying children to another node became fiddly with heavily nested children
export const OwnersTree: React.FC<Props> = ({ availableCountries, entityTypes }) => {
    const { values, setFieldValue, getFieldMeta, submitCount } = useFormikContext<FormValues>();

    const [, { touched, error }] = useField<OwnerTreeStructure[]>(OWNERS_STRUCTURE_FORM_STRING);
    const hasSubmitted = submitCount > 0;
    const hasError = !!error && touched && typeof error === 'string' && hasSubmitted;

    const ownerInformation = values.ownerInformation;

    const isAddingItem = useMemo(
        () => flatten(ownerInformation.beneficialOwnersStructure).some((item) => item.isNewNode),
        [ownerInformation.beneficialOwnersStructure]
    );

    // The BE requires this number field. We don't use it
    useMapNumbering();

    // Update owners when tree structure changes
    useUpdateOwnersOnTreeChange();

    const lookupOwner = (guid: string, bIsBusiness: boolean) => {
        if (bIsBusiness) {
            const ownerIndex = ownerInformation.businessBeneficialOwners.findIndex(
                (owner) => owner.guid === guid
            );
            return {
                owner: ownerInformation.businessBeneficialOwners[ownerIndex],
                fieldname: `${BUSINESS_OWNERS_FORM_STRING}[${ownerIndex}]`,
            };
        } else {
            const ownerIndex = ownerInformation.individualBeneficialOwners.findIndex(
                (owner) => owner.guid === guid
            );
            return {
                owner: ownerInformation.individualBeneficialOwners[ownerIndex],
                fieldname: `${INDIVIDUAL_OWNERS_FORM_STRING}[${ownerIndex}]`,
            };
        }
    };

    const handleAddNewOwner = (fieldname: string, isBusiness: boolean) => {
        // Add an empty item. The SingleTreeItem renderer itself will handle the details
        const { value: owner } = getFieldMeta<OwnerTreeStructure | OwnerTreeStructure[] | null>(
            fieldname
        );
        const newOwner: OwnerTreeStructure = {
            bIsBusiness: isBusiness,
            ownersGuid: uuidv4(),
            percentageOwned: null,
            bControllingParty: null,
            positionAtCompany: null,
            children: null,
            isNewNode: true,
        };
        const topOfTree = Array.isArray(owner) || owner === null;
        if (topOfTree) {
            setFieldValue(fieldname, [newOwner, ...((owner as OwnerTreeStructure[]) ?? [])]);
        } else {
            setFieldValue(fieldname, {
                ...owner,
                children: [newOwner, ...((owner as OwnerTreeStructure).children ?? [])],
            });
        }
        if (isBusiness) {
            const newOwnerBusiness = generateBeneficialBusinessOwner(
                newOwner.ownersGuid,
                values.ownerInformation.businessBeneficialOwners.length
            );
            setFieldValue(BUSINESS_OWNERS_FORM_STRING, [
                ...values.ownerInformation.businessBeneficialOwners,
                newOwnerBusiness,
            ]);
        } else {
            const newOwnerIndividual = generateBeneficialPersonalOwner(
                newOwner.ownersGuid,
                values.ownerInformation.individualBeneficialOwners.length
            );
            setFieldValue(INDIVIDUAL_OWNERS_FORM_STRING, [
                ...values.ownerInformation.individualBeneficialOwners,
                newOwnerIndividual,
            ]);
        }
    };

    const [updateOwner, setUpdateOwner] = useState<null | {
        fieldname: string;
        treeFieldName: string;
        bIsBusiness: boolean;
    }>(null);
    const handleUpdateOwner = (treeFieldName: string) => {
        const { value: owner } = getFieldMeta<OwnerTreeStructure>(treeFieldName);

        let fieldname = '';
        if (owner.bIsBusiness) {
            const index = values.ownerInformation.businessBeneficialOwners.findIndex(
                (o) => o.guid === owner.ownersGuid
            );
            fieldname = `${BUSINESS_OWNERS_FORM_STRING}[${index}]`;
        } else {
            const index = values.ownerInformation.individualBeneficialOwners.findIndex(
                (o) => o.guid === owner.ownersGuid
            );
            fieldname = `${INDIVIDUAL_OWNERS_FORM_STRING}[${index}]`;
        }

        setUpdateOwner({ fieldname: fieldname, treeFieldName, bIsBusiness: owner.bIsBusiness });
    };

    const [deleteOwnersInfo, setDeleteOwners] = useState<null | DeletedOwnersState>(null);
    const handleDeleteOwner = (fieldname: string, parentFieldname: string) => {
        const { value: owner } = getFieldMeta<OwnerTreeStructure>(fieldname);

        // Check if we're removed the only instance of an owner
        // If so remove owner from individual/business arrays
        // If we delete a node with children we may be removing lots of owners hence checking all deleted owners
        const flattenedTree = flattenAddFieldname(
            ownerInformation.beneficialOwnersStructure,
            OWNERS_STRUCTURE_FORM_STRING
        );

        const deletedOwners = flattenAddFieldname(
            owner.children ?? [],
            `${fieldname}.children`,
            true
        ).concat({
            ownersGuid: owner.ownersGuid,
            fieldname: fieldname,
            childrenCount: (owner.children ?? []).length,
            children: owner.children,
            bIsBusiness: owner.bIsBusiness,
        });
        const deletedOwnersToBeRemovedFromTree = deletedOwners
            .map((deletedOwner) => {
                // One owner may have come up multiple times in the deleted node
                const ownerDeletionCount = deletedOwners.filter(
                    (del) => del.ownersGuid === deletedOwner.ownersGuid
                ).length;

                const ownerGuidNotPresentInTree =
                    flattenedTree.filter((o) => o.ownersGuid === deletedOwner.ownersGuid).length ===
                    ownerDeletionCount;

                // If we need to remove from businessBeneficialOwners/individualBeneficialOwners
                if (ownerGuidNotPresentInTree) return deletedOwner;
                else return null;
            })
            // filter out null results
            .filter((owner) => !!owner) as OwnerTreeItemFieldnames[];

        const deletedBusinessOwnersOutsideDeletedBranch = deletedOwners.filter(
            (o) =>
                !deletedOwnersToBeRemovedFromTree.map((a) => a.ownersGuid).includes(o.ownersGuid) &&
                o.bIsBusiness &&
                (o.children ?? []).length > 0
        );
        // Need to copy across business childen to node outside of deleted node
        // get deleted fieldnames for tree items being removed not owners!
        const fieldnamesBeingRemoved = deletedOwners.map((o) => o.fieldname);
        // filter out deleted field names
        const keptNodes = flattenedTree.filter(
            (o) => !fieldnamesBeingRemoved.includes(o.fieldname)
        );
        // match owner by guid from remaining
        const businessesToUpdate = deletedBusinessOwnersOutsideDeletedBranch
            .map((o) => {
                const node = keptNodes.find((keptOwner) => keptOwner.ownersGuid === o.ownersGuid);

                if (!node) return null;
                return { fieldname: node.fieldname, children: o.children };
            })
            .filter((a) => !!a) as { fieldname: string; children: OwnerTreeStructure[] }[];

        const deletedDuplicatesRemoved = deletedOwnersToBeRemovedFromTree.filter(
            (val, i, arr) => arr.findIndex((a) => a.ownersGuid === val.ownersGuid) === i
        );
        // Filter out selected owner from list to check if any children remain
        if (deletedDuplicatesRemoved.filter((o) => o.ownersGuid !== owner.ownersGuid).length > 0) {
            // Show modal warning for children will be deleted
            setDeleteOwners({
                businessesToUpdate,
                owner,
                parentFieldname,
            });
        } else {
            handleFinalDeletionOne(businessesToUpdate, owner, parentFieldname);
        }
    };
    const [finalTreeUpdate, setTriggerFinalTreeUpdate] = useState<{
        parentFieldname: string;
        ownersGuid: string;
    } | null>(null);
    useEffect(() => {
        if (!finalTreeUpdate) return;
        handleFinalDeleteTwo(finalTreeUpdate.parentFieldname, finalTreeUpdate.ownersGuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalTreeUpdate]);
    const handleFinalDeletionOne = async (
        businessesToUpdate: { fieldname: string; children: OwnerTreeStructure[] }[],
        owner: OwnerTreeStructure,
        parentFieldname: string
    ) => {
        (businessesToUpdate ?? []).forEach((item) =>
            setFieldValue(`${item.fieldname}.children`, item.children)
        );
        /* Have to do it like this in order for values to be updated correctly otherwise we're working
        with a stale tree without the above updates */
        setTriggerFinalTreeUpdate({ parentFieldname, ownersGuid: owner.ownersGuid });
    };
    const handleFinalDeleteTwo = (parentFieldname: string, ownersGuid: string) => {
        const { value: parent } = getFieldMeta<OwnerTreeStructure | OwnerTreeStructure[]>(
            parentFieldname
        );
        // Update the tree structure
        const parentFilteredChildren = Array.isArray(parent)
            ? parent.filter((o) => o.ownersGuid !== ownersGuid)
            : (parent.children ?? []).filter((o) => o.ownersGuid !== ownersGuid);
        const topOfTree = Array.isArray(parent);
        if (topOfTree) {
            setFieldValue(
                parentFieldname,
                parentFilteredChildren.length > 0 ? parentFilteredChildren : null
            );
        } else {
            setFieldValue(parentFieldname, {
                ...parent,
                children: parentFilteredChildren.length > 0 ? parentFilteredChildren : null,
            });
        }
    };

    const hasSiblingPastCurrent = (currentIndex: number) => {
        const children = ownerInformation.beneficialOwnersStructure ?? [];
        return children.slice(currentIndex + 1).length > 0;
    };

    const hasChildren = (ownerInformation.beneficialOwnersStructure ?? []).length > 0;

    return (
        <div className="OwnersTree">
            <div className={`OwnerBox ${hasError ? 'Error' : ''}`}>Your Business</div>
            <div className="Relative">
                <TreeActionButtons
                    handleAddOwner={handleAddNewOwner}
                    fieldname="ownerInformation.beneficialOwnersStructure"
                    isAddingItem={isAddingItem}
                    hasError={hasError}
                />
                {hasError && (
                    <ErrorM
                        key={hasChildren ? 'ErrorWithChild' : 'Error'}
                        className={`TreeItemError ${hasChildren ? 'HasChildren' : ''}`}
                        name="ownerInformation.beneficialOwnersStructure"
                    />
                )}
            </div>
            <div className="ChildrenWrapper">
                <div className="ConnectingLine" />
                <div className="BranchWrapper">
                    {(ownerInformation.beneficialOwnersStructure ?? []).map((item, i) => (
                        <SingleTreeItem
                            key={i}
                            item={item}
                            depth={1}
                            fieldname={`ownerInformation.beneficialOwnersStructure[${i}]`}
                            handleDeleteOwner={handleDeleteOwner}
                            handleUpdateOwner={handleUpdateOwner}
                            hideBranchLink={hasSiblingPastCurrent(i)}
                            lookupOwner={lookupOwner}
                            handleAddOwner={handleAddNewOwner}
                            hideLeftBar={
                                i === ownerInformation.beneficialOwnersStructure.length - 1
                            }
                            isAddingItem={isAddingItem}
                        />
                    ))}
                </div>
            </div>
            <UpdateIndividualModal
                visible={!!updateOwner && !updateOwner?.bIsBusiness}
                onCloseModal={() => setUpdateOwner(null)}
                fieldName={updateOwner?.fieldname ?? ''}
                treeFieldname={updateOwner?.treeFieldName ?? ''}
                availableCountries={availableCountries}
            />
            <UpdateBusinessModal
                visible={!!updateOwner && updateOwner?.bIsBusiness}
                onCloseModal={() => setUpdateOwner(null)}
                fieldName={updateOwner?.fieldname ?? ''}
                treeFieldname={updateOwner?.treeFieldName ?? ''}
                availableCountries={availableCountries}
                entityTypes={entityTypes}
            />
            <DeleteModal
                visible={!!deleteOwnersInfo}
                deleteOwnersInfo={deleteOwnersInfo}
                onCloseModal={() => setDeleteOwners(null)}
                handleFinalDeletion={handleFinalDeletionOne}
            />
        </div>
    );
};
