import React, { useEffect } from 'react';
import Button from 'components/button/Button';
import { FormikErrors, useFormikContext } from 'formik';
import { object } from 'yup';
import { over } from 'ramda';

export interface PanelButtonsProps {
    submitButtonText?: string;
    noSubmit?: boolean;
    close(): void;
    overallErrMsgPostSubmit?: string;
    customHandleClick?: () => void;
}

// Panel Buttons - Declare as its own component - useFormikContext must be declared in a FC that is rendered inside <Formik>
const PanelButtons: React.FC<PanelButtonsProps> = ({
    submitButtonText = 'Submit',
    noSubmit,
    close,
    overallErrMsgPostSubmit,
    customHandleClick,
}) => {
    const formik = useFormikContext<any>();

    // Custom error to warn user that this must be rendered inside Formik
    if (!formik) {
        throw new Error(`This component must be rendered inside Formik.`);
    }

    return (
        <div className="PanelButtonsContainer">
            {noSubmit ? (
                <div className="Buttons">
                    <div className="Btn BtnCancel" onClick={close}>
                        Cancel
                    </div>

                    <Button type="button" onClick={close}>
                        Close
                    </Button>
                </div>
            ) : (
                <div
                    className={`Buttons ${overallErrMsgPostSubmit ? 'BottomErrorSummary' : ''}`}
                    // className={`Buttons ${formik.errors && Object.keys(formik.errors).length > 0? 'BottomErrorSummary': ''}`}
                >
                    {/* {formik.errors && Object.keys(formik.errors).length > 0 && (
                        <p className="ErrorText NoMargin" style={{ margin: '0' }}>
                            {
                                //     Object.keys(formik.errors)[0]
                                // .split('_')
                                // .join('')
                                // .replace(/([A-Z])/g, ' $1')
                                // .replace(/^./, function (str) {
                                //     return str.toUpperCase();
                                // }) + //emailAddress or Email_Address to Email Address
                                // ' - ' +
                                formik.errors[`${Object.keys(formik.errors)[0]}` as any]
                            }
                        </p>
                    )} */}
                    {overallErrMsgPostSubmit && (
                        <p className="ErrorText NoMargin" style={{ margin: '0' }}>
                            {overallErrMsgPostSubmit}
                        </p>
                    )}
                    <div>
                        <div className="Btn BtnCancel" onClick={close}>
                            Cancel
                        </div>

                        <Button
                            type="submit"
                            disabled={formik.isSubmitting}
                            onClick={customHandleClick}
                        >
                            {submitButtonText}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export { PanelButtons };
