import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { PaymentStepProps } from './MakePayment';
import { RadioButtons } from '../../../../components/radiobuttons/radiobuttons';
import FormTextField from '../../../../components/form/FormTextField';
import Button from '../../../../components/button/Button';

type Props = PaymentStepProps & {
    paymentMethods: string;
    initialValues: Partial<PaymentDetailsForm>;
};

export type PaymentDetailsForm = {
    transferType: string;
    paymentReference: string;
};

const validationSchema = Yup.object({
    paymentReference: Yup.string().required('Please provide a payment reference'),
});

export const PaymentDetails: React.FC<Props> = ({ nextStep, paymentMethods, initialValues }) => {
    const onSubmit = (values: PaymentDetailsForm) => {
        nextStep(values);
    };

    const allowedTransferTypes = paymentMethods.split(',').map((item) => item.trim()) ?? []; // allowedTransferTypes is a comma separated string e.g. "ACH, RTP, Wire"

    return (
        <Formik
            initialValues={{
                transferType: allowedTransferTypes[0],
                paymentReference: '',
                ...initialValues,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <RadioButtons
                    options={allowedTransferTypes}
                    fieldname="transferType"
                    label="Payment Method"
                />
                <FormTextField
                    field="paymentReference"
                    label="Payment Reference"
                    maxLength={16}
                    tooltip="Message to be recieved by the beneficiary bank"
                />
                <Button type="submit" color="third">
                    Next
                </Button>
            </Form>
        </Formik>
    );
};
