import * as Yup from 'yup';

export interface SignificantParty {
    firstName: string;
    lastName: string;
    title: string;
    dob: string;
    nationality: string;
    email: string;
    phone: string;
    country: string;
    taxIdNumber: string;
    state: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    sharesOwned: string;
    position: string;
    controllingParty: string;
    proofOfAddressFilename: string;
}

export interface SignificantParties {
    significantParties: SignificantParty[];
}

export const significantPartyValues = {
    firstName: '',
    lastName: '',
    title: '',
    dob: '',
    nationality: '',
    email: '',
    phone: '',
    country: '',
    taxIdNumber: '',
    state: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    sharesOwned: '',
    position: '',
    controllingParty: '',
    proofOfAddress: '',
};

export const validationSchemaSignificantParties = Yup.object().shape({
    significantParties: Yup.array()
        .of(
            Yup.object()
                .shape({
                    firstName: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a first name'),
                    lastName: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a last name'),
                    title: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a title'),
                    dateOfBirth: Yup.string().required('Please provide a date of birth').nullable(),
                    nationality: Yup.string().required('Please provide a nationality'),
                    email: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide an email address'),
                    phoneNumber: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a phone number'),
                    country: Yup.string().required('Please provide a country'),
                    ssn: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a tax ID number/SSN'),
                    stateProvince: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a state/province'),
                    city: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a city'),
                    addressLine1: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a street address'),
                    addressLine2: Yup.string().max(200, 'Maximum 200 characters'),
                    postcode: Yup.string()
                        .max(20, 'Maximum 20 characters')
                        .required('Please provide a postal/zip code'),
                    percentageSharesOwned: Yup.number()
                        .max(100, 'Percentage must be less than 100')
                        .min(0, 'Percentage must be more than 0')
                        .required('Please provide a percentage of shares owned')
                        .typeError('Please provide a percentage of shares owned')
                        .nullable(),
                    position: Yup.string()
                        .max(100, 'Maximum 100 characters')
                        .required('Please provide a position at the company'),
                    bControllingParty: Yup.boolean()
                        .required('Please state whether this party is a controlling party')
                        .nullable(),
                    proofOfAddressFilename: Yup.string()
                        .max(200, 'Maximum 200 characters')
                        .required('Please provide a proof of address')
                        .nullable(),
                })
                .required('Please provide information on at least one party')
        )
        .test(
            'totalSharesPercentage',
            'Total percentage shares owned must not exceed 100%',
            (values) => {
                const total = values?.reduce((acc, current) => {
                    // if the current value is not a number, coalesce it to 0
                    return acc + (current.percentageSharesOwned || 0);
                }, 0);
                return total <= 100;
            }
        )
        .test('hasControllingParty', 'You must add at least one controlling party', (values) => {
            return !!values?.some((val) => val.bControllingParty);
        }),
});
