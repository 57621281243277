import { useEffect, useState } from 'react';

const useScrollToError = (
    submitError: boolean,
    submitErrorField: string,
    isExpanded: boolean,
    setIsExpanded: React.SetStateAction<any>,
    setSubmitError: React.SetStateAction<any>
) => {
    const [expandAll, setExpandAll] = useState(false);

    useEffect(() => {
        if (submitError && submitErrorField.length > 0) {
            setExpandAll(true);
        }
    }, [submitError, submitErrorField]);

    useEffect(() => {
        if (expandAll && isExpanded) {
            const inputElement = document.querySelector(`input[name="${submitErrorField}"]`);
            if (inputElement) {
                inputElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                (inputElement as HTMLElement).focus();
            }
            setExpandAll(false);
            setIsExpanded(false);
            setSubmitError(false);
        }
    }, [expandAll, submitErrorField, isExpanded]);

    return expandAll;
};

export default useScrollToError;
