import * as Yup from 'yup';
import { BulkTransferOptions, ParsedCsv } from './models';
import {
    getFeeFromAccountLevel,
    getFeeFromTransferType,
    matchAccount,
    matchCountry,
    matchPayee,
    matchToPurpose,
} from './helpers';
import { ProductType } from '../../../components/sideMenu/SideMenu';
import { QueryClient } from '@tanstack/react-query';
import { CachedTransferOptions, TRANSFER_OPTIONS_KEY } from './parseCsvResults';

export const buildValidationSchema = (
    accounts: BulkTransferOptions['accounts'],
    countries: BulkTransferOptions['countries'],
    purposes: BulkTransferOptions['purposeCodes'],
    queryClient: QueryClient
) => {
    return Yup.object().shape({
        bulkTransfer: Yup.array().of(
            Yup.object().shape({
                // account
                sourceAccountId: Yup.number()
                    .required('Please select an account to transfer from')
                    .nullable(),
                isDbs: Yup.boolean(),
                // Payee fields
                payeeId: Yup.mixed()
                    .nullable(true)
                    .test(
                        'payeeMatched',
                        'Payee Not Matched',
                        (value) => value == null || typeof value === 'number'
                    ),
                accountName: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an account name'),
                    }),
                countryCode: Yup.mixed()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema
                                .required('Please select a country')
                                .test(
                                    'countryMatched',
                                    'Country Not Matched - Please use a three-letter code e.g. USA',
                                    (value) => value == null || typeof value !== 'symbol'
                                ),
                    }),
                name: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter a name'),
                    }),
                addressLine1: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an address'),
                    }),
                townCity: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter an town or city'),
                    }),
                postCode: Yup.string()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required('Please enter a valid post/zip code'),
                    }),
                addressCountryCode: Yup.mixed()
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema
                                .required('Please select a country')
                                .test(
                                    'countryMatched',
                                    'Country Not Matched - Please use a three-letter code e.g. USA',
                                    (value) => value == null || typeof value !== 'symbol'
                                ),
                    }),
                routingNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'isDbs', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            isDbs: boolean,
                            countryCode: string | undefined
                        ) => !payeeId && !isDbs && countryCode === 'USA',
                        then: Yup.string().required('Please enter a routing number'),
                    }),
                accountNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'isDbs', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            isDbs: boolean,
                            countryCode: string | undefined
                        ) => !payeeId && (isDbs || countryCode === 'USA'),
                        then: Yup.string().nullable().required('Please enter an account number'),
                    }),
                swiftNumber: Yup.string()
                    .nullable()
                    .when(['payeeId', 'isDbs', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            isDbs: boolean,
                            countryCode: string | undefined
                        ) => !payeeId && (isDbs || countryCode !== 'USA'),
                        then: Yup.string().required('Please enter a swift number'),
                    }),
                iban: Yup.string()
                    .nullable()
                    .when(['payeeId', 'isDbs', 'countryCode'], {
                        is: (
                            payeeId: number | null,
                            isDbs: boolean,
                            countryCode: string | undefined
                        ) => !payeeId && !isDbs && countryCode !== 'USA',
                        then: Yup.string().required('Please enter an IBAN'),
                    }),
                intermediaryBic: Yup.string().nullable(),
                payeesReference: Yup.string()
                    .max(16, 'Reference cannot be longer than 16 characters')
                    .matches(/^[\w\d ]*$/, 'Reference must not contain special characters')
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) => schema.required(`Please enter payee's reference`),
                    }),
                bankName: Yup.string()
                    .max(35, 'Maximum 35 characters')
                    .nullable()
                    .when('payeeId', {
                        is: (val: number | null) => !val,
                        then: (schema) =>
                            schema.required(`Please enter the name of the destination bank`),
                    }),

                // payment details
                transferType: Yup.string().required('Please pick a payment method').nullable(),
                paymentReference: Yup.string().required('Please provide a payment reference'),

                // Amount & purpose
                feeId: Yup.string()
                    .nullable()
                    .test(
                        'validFee',
                        'Please choose a fee option',
                        function (val: string | undefined | null) {
                            return val === null || !!val;
                        }
                    ),
                amount: Yup.number()
                    .typeError('Amount must be a number')
                    .required('Please enter an amount')
                    .positive('Please enter a number greater than 0')
                    .test(
                        'maxAmountCheck',
                        'Max available funds reached',
                        function (amountField: number | undefined) {
                            const amount = amountField ?? 0;

                            // @ts-ignore
                            const { bulkTransfer } = this.options.from[1].value as {
                                bulkTransfer: ParsedCsv[];
                            };

                            const sourceAccountId = this.parent.sourceAccountId;
                            const account = matchAccount(sourceAccountId, accounts);
                            if (!account) return true;

                            const regex = /bulkTransfer\[(\d+)\]\.amount/;
                            const match = this.path.match(regex);
                            const index = match ? parseInt(match[1], 10) : 0;

                            const sumAmountWithoutFess = bulkTransfer
                                .slice(0, index + 1)
                                .reduce(
                                    (prev, cur) =>
                                        cur.sourceAccountId === sourceAccountId
                                            ? prev + (cur?.amount ?? 0)
                                            : prev,
                                    0
                                );

                            const payeeId = this.parent.payeeId;
                            const transferType = this.parent.transferType;
                            const feeId = this.parent.feeId;
                            const countryCode = this.parent.countryCode;

                            const transferTypes = payeeId
                                ? null
                                : queryClient.getQueryData<CachedTransferOptions>([
                                      TRANSFER_OPTIONS_KEY,
                                      account.id + '', // the cached query has id as a string
                                      countryCode,
                                  ]);

                            const fee = payeeId
                                ? getFeeFromAccountLevel(payeeId, transferType, feeId, account)
                                : getFeeFromTransferType(
                                      transferType,
                                      feeId,
                                      transferTypes?.transferOptions
                                  );

                            if (!fee) {
                                if (amount > account.balance)
                                    return this.createError({ message: 'Not enough funds' });
                                else if (sumAmountWithoutFess > account.balance)
                                    return this.createError({
                                        message:
                                            'Not enough funds. Sum of transfers exceeds balance',
                                    });
                                else return true;
                            }

                            const minFee = fee?.minFee ?? 0;
                            const percentageFee = fee ? amount * (fee.feePercent / 100) : 0;
                            const finalFee = percentageFee > minFee ? percentageFee : minFee;

                            if (amount + finalFee > (account.balance ?? Number.MAX_VALUE))
                                return this.createError({
                                    message: `Not enough funds ${
                                        finalFee > 0 ? `(fee: ${finalFee})` : ''
                                    }`,
                                });
                            else if (
                                sumAmountWithoutFess + finalFee >
                                (account.balance ?? Number.MAX_VALUE)
                            )
                                return this.createError({
                                    message: `Not enough funds. Sum of transfers exceeds available balance ${
                                        finalFee > 0 ? `(fee: ${finalFee})` : ''
                                    }`,
                                });
                            else return true;
                        }
                    ),
                purpose: Yup.string()
                    .nullable()
                    .test('requiredIfInternational', 'Required', function (val) {
                        const account = matchAccount(this.parent.sourceAccountId, accounts);
                        if (!account) return true;

                        // Existing payee
                        const payee = matchPayee(this.parent.payeeId, account);
                        // New payee
                        const countryNewPayee = this.parent.countryCode;

                        const country = payee
                            ? matchCountry(payee.countryName, countries)?.countryISO3
                            : countryNewPayee;
                        if (!country) return true;

                        const bInternational = country !== 'USA';
                        if (bInternational && account.productDisplayName === ProductType.CRB) {
                            const purpose = matchToPurpose(val ?? '', purposes);

                            return !!purpose;
                        } else return true;
                    }),

                // More details
                memo: Yup.string().max(140, 'Maximum 140 characters').nullable(),
                notes: Yup.string().max(1000, 'Maximum 1000 characters').nullable(),
            })
        ),
    });
};
