import { ApiResponse } from '@avamae/formbuilder/dist/FormBuilder';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import instance, { isErrorHandled } from 'api';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { endpoints } from 'endpoints.config';
import { Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { plaintext } from 'plaintext.config';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyCryptoForm } from './BuyCryptoForm';
import { CryptoContent } from './CryptoContent';
import { CryptoHoldings } from './CryptoHoldings';
import { CryptoOrderHistory } from './CryptoOrderHistory';
import { CryptoContext, CryptoLiveData } from './CryptoPage';
import { ReviewOrderForm } from './ReviewOrderForm';
import * as Yup from 'yup';
import { getErrorMessage } from 'errors';
import { TFAType } from 'pages/register/models';
import { selectTFAType } from 'reducers/auth';
import { CoinGeckoWidget } from 'components/widgets/CoinGeckoWidget';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { selectAllCryptoPrices } from 'reducers/cryptoPrices';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';
import { getCustomerComponentResources } from '../../reducers/componentResources';
import { completeUIUpdate } from '../../components/notifications/notificationUIUpdateReducer';

export type AssetDetails = {
    description: string;
    networkFee: number | null;
    feePercentage: number | null;
    baseAsset: string | null;
};
export type OrderDetails = {
    customerAssetAccountsId: number;
    assetExchangeType: 'Buy' | 'Sell';
    asset: string;
    pairedAsset: string;
    amount: number;
    price: number;
    networkFee: number;
    commissionFee: number;
    totalPrice: number;
    validUntilUtcDate: string;
    quantityType: TradeCrypto['quantityType'];
};
type AccountDetail = {
    assetCode: string;
    customerAssetAccountsId: number;
    availableBalance: string;
    bVirtualBuy: boolean;
};

export enum TransferType {
    Wire = 'Wire',
    ACH = 'ACH',
    RTP = 'RTP',
    P2C = 'P2C',
    INTERNAL = 'INTERNAL',
}

export type PayeeDetail = {
    assetCode: string;
    payeesId: number;
    payeeLabel: string;
    allowedTransferTypes: Array<TransferType>;
};

const Crypto: React.FC = () => {
    const [sellButtonEnabled, _setSellButtonEnabled] = useState<boolean | null>(null);
    const [detailsLoading, setDetailsLoading] = useState<boolean | null>(null);
    const [cryptoDetails, setCryptoDetails] = useState<AssetDetails | null>(null);
    const [accountsLoading, setAccountsLoading] = useState<boolean | null>(null);
    const [accountDetails, setAccountDetails] = useState<AccountDetail[] | null>(null);
    const [payeeDetails, setPayeeDetails] = useState<PayeeDetail[] | null>(null);
    const {
        selectedCryptoPair,
        setSelectedCryptoPair,
        currencyFormat,
        currency,
        cryptos,
        setIsPageLoading,
        isPageLoading,
        endpoints,
    } = useContext(CryptoContext);

    const selectedCrypto = useMemo(
        () => cryptos.find((crypto) => crypto.pair === selectedCryptoPair),
        [selectedCryptoPair, cryptos]
    );

    const fetchCryptoDetails = useCallback(() => {
        if (detailsLoading || !selectedCrypto?.ticker) return;
        setDetailsLoading(true);
        instance
            .get(endpoints.getAssetDetails, {
                params: { asset: selectedCrypto.ticker },
            })
            .then((res) => {
                setCryptoDetails({ ...res.data.details });
            })
            .finally(() => {
                setDetailsLoading(false);
            });
    }, [detailsLoading, selectedCrypto, endpoints.getAssetDetails]);

    useEffect(() => {
        instance.get<ApiResponse<PayeeDetail[]>>(endpoints.getPayees).then((res) => {
            setPayeeDetails(res.data.details);
        });
    }, [endpoints]);

    useEffect(() => {
        if (cryptoDetails) return;
        fetchCryptoDetails();
    }, [fetchCryptoDetails, cryptoDetails]);

    const fetchAccounts = useCallback(() => {
        if (!selectedCrypto || accountsLoading || accountDetails) return;
        setAccountsLoading(true);
        instance
            .get<ApiResponse<any, AccountDetail[]>>(endpoints.getAssetAccounts, {
                params: { asset: selectedCrypto.ticker },
            })
            .then((res) => {
                if (res.data.details == null) return setAccountDetails([]);
                setAccountDetails(res.data.details);
            })
            .finally(() => setAccountsLoading(false));
    }, [selectedCrypto, accountsLoading, accountDetails, endpoints]);

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    useEffect(() => {
        if (sellButtonEnabled !== null && detailsLoading === false && accountsLoading === false) {
            setIsPageLoading(false);
        } else {
            setIsPageLoading(true);
        }
    }, [sellButtonEnabled, detailsLoading, accountsLoading, setIsPageLoading]);

    const setSellButtonEnabled = (b: boolean) => {
        _setSellButtonEnabled(b);
    };

    const onDone = () => {
        fetchCryptoDetails();
    };
    if (selectedCrypto === undefined) {
        setSelectedCryptoPair(null); //this should get us back to the cryptos page
        return null;
    }

    //STILL TO DO -- MAKE ALL OF THIS DATA/INFO DYNAMIC
    return (
        <div className="Crypto">
            <h2
                onClick={() => {
                    setSelectedCryptoPair(null);
                }}
                className="BackButton"
            >
                {plaintext.generic.back}
            </h2>
            <div className="Container">
                <div className="TopContainer">
                    <div className="LeftContainer">
                        <div className="CryptoHeaderContainer">
                            <div className="CryptoHeader">
                                <div className="Left">
                                    <CurrencyIcon currency={selectedCrypto.ticker} />
                                    <h1>
                                        {selectedCrypto.name
                                            ? selectedCrypto.name
                                            : selectedCrypto.ticker}
                                        <span>{selectedCrypto.ticker}</span>
                                    </h1>
                                </div>
                            </div>
                            <p className={`CryptoPrice`}>
                                {currencyFormat.format(selectedCrypto.buyPrice)}
                            </p>
                        </div>
                        <div className="KrakenGraph">
                            <CoinGeckoWidget
                                pageLoaded={!isPageLoading}
                                selectedCrypto={selectedCrypto.name?.toLowerCase() ?? ''}
                                currency={currency}
                            />
                        </div>
                        <CryptoContent
                            subject={'About'}
                            name={selectedCrypto.name ? selectedCrypto.name : selectedCrypto.ticker}
                            ticker={selectedCrypto.ticker}
                            description={cryptoDetails?.description ?? ''}
                        />
                    </div>
                    <div className="RightContainer">
                        <TradeCryptoForm
                            sellButtonEnabled={sellButtonEnabled ?? true}
                            selectedCrypto={selectedCrypto}
                            accountDetails={accountDetails ?? []}
                            payeeDetails={payeeDetails ?? []}
                            onDone={onDone}
                            endpoints={endpoints}
                            assetCode={selectedCrypto.ticker}
                        />
                    </div>
                </div>
                <CryptoHoldings
                    currency={currency}
                    currencyFormat={currencyFormat}
                    setSellButtonEnabled={setSellButtonEnabled}
                />
                <CryptoOrderHistory />
            </div>
        </div>
    );
};

export { Crypto };
type TradeCryptoFormProps = {
    sellButtonEnabled: boolean;
    accountDetails: AccountDetail[];
    payeeDetails: PayeeDetail[];
    selectedCrypto: CryptoLiveData;
    onDone: () => void;
    endpoints: typeof endpoints.cryptosmodule;
    assetCode: string;
};

export type TradeCrypto = {
    account: number;
    amount: number | '';
    price: number | '';
    tfaCode: string;
    tfaType: TFAType;
    quantityType: 'Amount' | 'Price';
    transferType: TransferType;
    asset: string;
    currency: string;
    bVirtualBuy: boolean;
    minFee?: number;
    feeId?: number | string;
};

export const TradeCryptoForm: React.FC<TradeCryptoFormProps> = ({
    sellButtonEnabled,
    assetCode,
    accountDetails,
    payeeDetails,
    onDone,
    endpoints,
}) => {
    const [trade, setTrade] = useState<'buy' | 'sell'>('buy');
    const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const tfa = useSelector(selectTFAType);
    const prices = useSelector(selectAllCryptoPrices);
    const [_currency, _setCurrency] = useState<string | null>(null);
    const cryptoDetails = useMemo(
        () => prices?.[`${assetCode}/${_currency}`] ?? null,
        [prices, _currency, assetCode]
    );
    const dispatch = useDispatch();

    const accountOptions = useMemo(
        () =>
            accountDetails.map((account) => ({
                label: (
                    <>
                        <AssetCodeFormatter assetCode={account.assetCode} /> account -{' '}
                        {account.availableBalance}{' '}
                        <AssetCodeFormatter assetCode={account.assetCode} />
                    </>
                ),
                value: account.customerAssetAccountsId,
            })),
        [accountDetails]
    );

    const switchAccount = useCallback(
        (formikProps: FormikProps<TradeCrypto>, accountId: number) => {
            // const newCurrency = accountCurrencies[accountId];
            // formikProps.setFieldValue('currency', newCurrency);
            // _setCurrency(newCurrency);
            const newAccount = accountDetails.find(
                (account) => account.customerAssetAccountsId === accountId
            );
            formikProps.setFieldValue('currency', newAccount?.assetCode);
            formikProps.setFieldValue('bVirtualBuy', newAccount?.bVirtualBuy);
            _setCurrency(newAccount?.assetCode ?? null);
        },
        [accountDetails]
    );
    const payeeOptions = useMemo(
        () =>
            payeeDetails.map((payee) => ({
                label: (
                    <>
                        {payee.payeeLabel} - <AssetCodeFormatter assetCode={payee.assetCode} />
                    </>
                ),
                value: payee.payeesId,
                allowedTransferTypes: payee.allowedTransferTypes,
            })),
        [payeeDetails]
    );
    const payeeCurrencies = useMemo(
        () =>
            payeeDetails.reduce<{ [key: number]: string }>((prev, curr) => {
                return { ...prev, [curr.payeesId]: curr.assetCode };
            }, {}),
        [payeeDetails]
    );
    const switchPayee = useCallback(
        (formikProps: FormikProps<TradeCrypto>, payeeId: number) => {
            const newCurrency = payeeCurrencies[payeeId];
            _setCurrency(newCurrency);
            formikProps.setFieldValue('currency', newCurrency);
            formikProps.setFieldValue('bVirtualBuy', undefined);
        },
        [payeeCurrencies]
    );
    const initialValues: TradeCrypto = {
        account: accountOptions?.[0]?.value ?? 0,
        amount: '',
        price: '',
        tfaCode: '',
        tfaType: tfa === 'sms' ? 'SMS' : 'AuthenticatorApp',
        quantityType: 'Amount',
        transferType: TransferType.ACH,
        asset: assetCode,
        currency: '',
        bVirtualBuy: accountDetails?.[0]?.bVirtualBuy ?? false,
        minFee: undefined,
    };

    const validationSchema = Yup.object({
        amount: Yup.number()
            .required('Please select an amount')
            .min(
                cryptoDetails?.minAmount ?? 0,
                (
                    <>
                        You must buy at least {cryptoDetails?.minAmount ?? 0}{' '}
                        <AssetCodeFormatter assetCode={assetCode} />
                    </>
                ) as unknown as string
            )
            .max(
                cryptoDetails?.maxAmount ?? Number.MAX_SAFE_INTEGER,
                (
                    <>
                        You cannot buy more than{' '}
                        {cryptoDetails?.maxAmount ?? Number.MAX_SAFE_INTEGER}{' '}
                        <AssetCodeFormatter assetCode={assetCode} />
                    </>
                ) as unknown as string
            ),
        price: Yup.number()
            .required('Please set a price')
            .min(
                cryptoDetails?.minPrice ?? 0,
                `You must spend at least ${cryptoDetails?.minPrice ?? 0} ${_currency}`
            )
            .max(
                cryptoDetails?.maxPrice ?? Number.MAX_SAFE_INTEGER,
                `You cannot spend more than ${
                    cryptoDetails?.maxPrice ?? Number.MAX_SAFE_INTEGER
                } ${_currency}`
            ),
        account: Yup.number()
            .required('Please select an account')
            .min(1000, 'Please select an account'),
    });
    const handleFirstSubmit = (
        values: TradeCrypto,
        formikHelpers: FormikHelpers<TradeCrypto> | FormikProps<TradeCrypto>
    ) => {
        const payload = {
            customerAssetAccountId:
                trade === 'buy' && !values.bVirtualBuy ? values.account : undefined,
            payeesId: trade === 'sell' ? values.account : undefined,
            assetExchangeType: trade === 'buy' ? 'Buy' : 'Sell',
            asset: assetCode,
            amount: values.quantityType === 'Amount' ? values.amount : undefined,
            totalPrice: values.quantityType === 'Price' ? values.price : undefined,
            transferType: trade === 'sell' ? values.transferType : undefined,
            quantityType: values.quantityType,
            bVirtualBuy: trade === 'buy' ? values.bVirtualBuy : undefined,
            pairedAsset: !!values.bVirtualBuy ? values.currency : undefined,
            bankFeeSplitType: values.feeId,
        };
        instance
            .get<ApiResponse<any, OrderDetails>>(endpoints.reviewOrderV2, {
                params: payload,
            })
            .then((res) => {
                setOrderDetails(res.data.details);
                setErrorMessage('');
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                } else {
                    setErrorMessage(getErrorMessage(''));
                }
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };
    //const [tfaType, toggleTfaType] = useTFAField();
    const handleFinalSubmit = (values: TradeCrypto, formikHelpers: FormikHelpers<TradeCrypto>) => {
        if (!orderDetails) {
            Toast.openGenericErrorToast();
            return formikHelpers.setSubmitting(false);
        }
        const { tfaCode, tfaType } = values;

        const payload = {
            tfaCode,
            tfaType,
            payeesId: orderDetails.assetExchangeType === 'Sell' ? values.account : undefined, // may be able to remove if/when this is included in the orderdetails
            transferType: values.transferType,
            customerAssetAccountId:
                orderDetails.assetExchangeType === 'Sell' ||
                (orderDetails.assetExchangeType === 'Buy' && !values.bVirtualBuy)
                    ? orderDetails.customerAssetAccountsId
                    : undefined,
            ...orderDetails,
            pairedAsset:
                orderDetails.assetExchangeType === 'Buy' && !!values.bVirtualBuy
                    ? orderDetails.pairedAsset ?? values.currency
                    : undefined,
            bVirtualBuy: orderDetails.assetExchangeType === 'Buy' ? values.bVirtualBuy : undefined,
            bankFeeSplitType: values.feeId,
            // commissionFee: orderDetails.commissionFee?.toPrecision(8),
            // price: orderDetails.price?.toPrecision(8),
            // networkFee: orderDetails.networkFee?.toPrecision(8),
            // totalPrice: orderDetails.totalPrice?.toPrecision(8),
        };
        const url =
            orderDetails.assetExchangeType === 'Buy' ? endpoints.buyAsset : endpoints.sellAsset;
        instance
            .post(url, payload)
            .then((res) => {
                if (orderDetails.assetExchangeType === 'Sell' && !res?.data?.details?.bApproved) {
                    Toast.openToastMessage(
                        "Your transaction has been flagged for review. The funds have been reserved and we'll let you know when it has been approved",
                        ToastMessageReason.ERROR,
                        { autoClose: false }
                    );
                }
                setOrderDetails(null);
                setErrorMessage('');
                formikHelpers.resetForm();
                dispatch(getCustomerComponentResources());
                dispatch(completeUIUpdate());
                onDone();
            })
            .catch((err) => {
                if (isErrorHandled(err) && err.response.data?.errors?.length > 0) {
                    setErrorMessage(getErrorMessage(err.response.data.errors[0].messageCode));
                } else setErrorMessage(getErrorMessage('Generic'));
            })
            .finally(() => formikHelpers.setSubmitting(false));
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={orderDetails ? handleFinalSubmit : handleFirstSubmit}
            validationSchema={validationSchema}
        >
            {(formikProps) => (
                <>
                    <ResetErrorMessage setErrorMessage={setErrorMessage} />
                    {orderDetails ? (
                        <ReviewOrderForm
                            back={() => setOrderDetails(null)}
                            orderDetails={orderDetails}
                            refreshOrder={() => handleFirstSubmit(formikProps.values, formikProps)}
                            errorMessage={errorMessage}
                        />
                    ) : (
                        <div className="ContentBox LeftAlign">
                            <h2>{plaintext.cryptoPage.newOrder}</h2>
                            <div className="SelectTradeContainer">
                                <p>{plaintext.cryptoPage.chooseOrderType}</p>
                                <div className="SelectTrade">
                                    <div
                                        className={`Trade Buy ${
                                            trade === 'buy' ? 'Active' : 'Inactive NoBorderRight'
                                        }`}
                                        onClick={() => {
                                            setTrade('buy');
                                            _setCurrency(null);
                                        }}
                                    >
                                        {plaintext.cryptoPage.buy}
                                    </div>
                                    {sellButtonEnabled && (
                                        <div
                                            className={`Trade Sell ${
                                                trade === 'sell'
                                                    ? 'Active'
                                                    : 'Inactive NoBorderLeft'
                                            }`}
                                            onClick={() => {
                                                setTrade('sell');
                                                _setCurrency(null);
                                            }}
                                        >
                                            {plaintext.cryptoPage.sell}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {accountOptions && (
                                <BuyCryptoForm
                                    assetCode={assetCode}
                                    currency={_currency}
                                    accounts={accountOptions as unknown as SelectOption[]}
                                    onSwitchAccount={switchAccount}
                                    payees={payeeOptions as any}
                                    onSwitchPayee={switchPayee}
                                    action={trade}
                                    setOrderDetails={setOrderDetails}
                                    formikProps={formikProps}
                                    rules={cryptoDetails}
                                    errorMessage={errorMessage}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </Formik>
    );
};

type ResetErrorMessageProps = {
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};
const ResetErrorMessage: React.FC<ResetErrorMessageProps> = ({ setErrorMessage }) => {
    const { values } = useFormikContext<TradeCrypto>();

    useEffect(() => {
        setErrorMessage('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.amount, values.price]);

    return null;
};
