import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/Main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootEpic, rootReducer } from './reducers/rootReducer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const epicMiddleware = createEpicMiddleware();

// Create a client
const queryClient = new QueryClient();

const configureStore = () => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));
    epicMiddleware.run(rootEpic);
    return store;
};

export const store = configureStore();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <App />
        </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
