import React from "react";
import Icon_Error from "../../assets/Icon_Error.svg";

const ErrorFallback: React.FC = () => {
  return (
    <div className="ErrorPage">
      <div className="ErrorCell">
        <div className="Img">
          <img src={Icon_Error} alt="Error" />
        </div>
        <div className="Txt">
          <h1>Something went wrong</h1>
          <p>
            Please try again. If the error persists, please contact support.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
