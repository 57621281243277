import { PayeeType } from '../Payees/EditPayee';
import {
    matchAccount,
    matchCountry,
    matchFeeOption,
    matchPayee,
    matchTransferType,
} from './helpers';
import { BulkTransferOptions, CsvCellConfig } from './models';
import { COUNTRY_MISMATCH_SYMBOL, PAYEE_MISMATCH_SYMBOL } from './parseCsvResults';

/* These are parsers that can be done independant of other fields existing.
Some fields depend on others, for example an existing payee depends on the account  */

export const parseSourceAccount: CsvCellConfig['parser'] = (field, accounts) => {
    const account = matchAccount(field, accounts);
    return account?.id ?? null;
};
export const parseCountry: CsvCellConfig['parser'] = (field, _, countries) => {
    if (!field) return null;
    const country = matchCountry(field, countries);
    return country?.countryISO3 ?? COUNTRY_MISMATCH_SYMBOL;
};
export const parseAccountType: CsvCellConfig['parser'] = (field) => {
    if (!field) return null;

    const personalWords = ['personal', 'person', 'individual'];
    const isPersonal = personalWords.includes(field.toLowerCase());

    const companyWords = ['business', 'company'];
    const isCompany = companyWords.includes(field.toLowerCase());

    return isPersonal ? PayeeType.Personal : isCompany ? PayeeType.Company : null;
};
export const parseAmount: CsvCellConfig['parser'] = (field) => {
    if (!field) return 0;

    const value = Number.parseFloat(field);

    return !!value && value > 0 ? value : 0;
};
const CURRENT_TRANSFER_TYPES = ['Wire', 'ACH', 'RTP', 'SWIFT'];
export const parseTransferType: CsvCellConfig['parser'] = (field) => {
    if (!field) return null;

    const match = CURRENT_TRANSFER_TYPES.find(
        (opt) => opt.toLowerCase() === field.toString().trim().toLowerCase()
    );

    return match ? match : field;
};

/* These are parsers that depend on other fields existing and having been parsed */
export const parsePayee = (
    field: string | undefined,
    accountId: number | undefined,
    accounts: BulkTransferOptions['accounts']
): number | null | Symbol => {
    if (!field || !accountId) return null;

    const account = matchAccount(accountId, accounts);
    if (!account) return null;

    const payee = matchPayee(field, account);

    return payee?.id ?? PAYEE_MISMATCH_SYMBOL;
};
export const parseTransferTypeFromPayee = (
    field: string | undefined,
    accountId: number | undefined,
    payeeId: number | undefined | symbol,
    accounts: BulkTransferOptions['accounts']
): string | null => {
    if (!field || !accountId || !payeeId) return null;

    const account = matchAccount(accountId, accounts);
    if (!account) return null;

    if (typeof payeeId === 'symbol') return null;
    const payee = matchPayee(payeeId, account);
    if (!payee) return null;

    const transferType = matchTransferType(field, payee.transferTypes);

    return transferType?.transferType ?? null;
};
export const parseFeeOptionsFromTypeAndPayee = (
    feeField: string | undefined,
    accountId: number | undefined,
    payeeId: number | undefined | symbol,
    transferField: string | undefined,
    accounts: BulkTransferOptions['accounts']
): string | null | undefined => {
    // Can't return null here as null is used a split type id in the BE (annoyingly)
    if (!feeField || !accountId || !payeeId) return undefined;

    const account = matchAccount(accountId, accounts);
    if (!account) return undefined;

    if (typeof payeeId === 'symbol') return undefined;
    const payee = matchPayee(payeeId, account);
    if (!payee) return undefined;

    const transferType = matchTransferType(transferField, payee.transferTypes);
    if (!transferType) return undefined;

    const feeOption = matchFeeOption(feeField, transferType.feeOptions);

    return feeOption || feeOption === null ? feeOption : undefined;
};
