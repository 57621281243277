import { useTheme } from '@emotion/react';
import { FoldingCube } from 'better-react-spinkit';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api, { ApiResponse } from '../../api';
import { endpoints } from '../../endpoints.config';
import { Toast, ToastMessageReason } from '../../helpers/toast';
import { ModalTypes, closeModal, selectModalState } from '../../reducers/modal';
import { refreshPayeeTable } from '../../reducers/payee';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';

export const RemovePayeeModal = () => {
    const modalState = useSelector(selectModalState);

    const [loading, setLoading] = useState(false);

    const { colors } = useTheme();
    const dispatch = useDispatch();

    const handleClose = () => dispatch(closeModal());

    if (modalState.modalType !== ModalTypes.REMOVE_PAYEE) return null;

    const payee = modalState.data;

    const handleRemovePayee = async () => {
        try {
            setLoading(true);
            const payeeId = payee.payees__Id;
            const res = await api.post<ApiResponse>(endpoints.accounts.deletePayee, {
                payeesId: payeeId,
            });

            if (res.data.status === '1') {
                dispatch(refreshPayeeTable());
                handleClose();
                Toast.openToastMessage('Payee removed', ToastMessageReason.VALID);
            } else {
                throw new Error();
            }
        } catch (error) {
            Toast.openToastMessage("Couldn't delete payee", ToastMessageReason.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal className="RemovePayeeModal" title="Are you sure">
            {loading && (
                <div className="Loading">
                    <FoldingCube color={colors.first} size={80} />
                    <p>
                        Removing <span>{payee.payees__Name}</span>
                    </p>
                </div>
            )}

            {!loading && (
                <>
                    <p>
                        Confirm you want to remove <span>{payee.payees__Name}</span> from your
                        payees?
                    </p>
                    <div className="Buttons">
                        <Button onClick={handleClose} priority="secondary" color="grey">
                            Close
                        </Button>
                        <Button onClick={handleRemovePayee} color="third">
                            Remove payee
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};
