import '@emotion/react';

export const initialTheme = {
    colors: {
        first: '#1943DC',
        firstHover: '#0d31a2',
        second: '#636fec',
        secondHover: '#3544b8',
        third: '#DA0A59',
        thirdHover: '#B20A4A',
        alertGood: '#84C779',
        alertGoodHover: '#6A9F61',
        alertGoodBackground: '#F4FEF2',
        alertBad: '#C77979',
        alertBadHover: '#9F6161',
        alertBadBackground: '#FFF7F7',
        alertNeutral: '#b0b6fd',
        alertNeutralHover: '#283389',
        alertNeutralBackground: '#edeffe',

        headingColor: '#060415',
        secondBackground: '#edeffe',
    },
};

export type ThemeType = typeof initialTheme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}
