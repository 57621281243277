import { RouteComponentProps, useLocation } from '@reach/router';
import instance, { ApiResponse, useFetch } from 'api';
import { allowableOrigins } from 'components/jumio/JumioIdIframe';
import { JumioVerificationStatusResponse } from 'components/jumio/jumioModels';
import Page from 'components/page/Page';
import { Spinner } from 'components/spinner/Spinner';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

//icons
import WarningIcon from 'assets/Icon_Warning.png';
import Img_KYC from 'assets/Img_KYC.png';

type Props = {} & RouteComponentProps;

export const SignificantPartyJumio: React.FC<Props> = (props) => {
    const { search } = useLocation();
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState('');
    const [completed, setCompleted] = useState(false);
    const token = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return searchParams.get('token');
    }, [search]);
    useEffect(() => {
        instance
            .post<ApiResponse<JumioVerificationStatusResponse>>(
                endpoints.jumiomodule.getExternalJumioLink,
                {
                    jumioToken: encodeURIComponent(token ?? ''),
                    jumioConsent: true,
                }
            )
            .then((res) => {
                if (res?.data?.details?.additionalData?.iframeUrl) {
                    setUrl(res.data.details.additionalData.iframeUrl);
                    // window.location.href = res.data.details.additionalData.iframeUrl;
                }
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setLoading(false));
    }, [token]);

    const handleMessage = useCallback((e: MessageEvent<any>) => {
        if (isJumioComplete(e)) {
            setCompleted(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);
    return (
        <Page isPublic>
            {loading ? (
                <SubPageLoader />
            ) : completed ? (
                <SuccessScreen />
            ) : url ? (
                <iframe
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="id Verification"
                    src={url}
                ></iframe>
            ) : (
                <ErrorScreen />
            )}
        </Page>
    );
};

const isJumioComplete = (e: MessageEvent<any>) => {
    try {
        if (allowableOrigins.includes(e.origin.toLowerCase())) {
            if (typeof e.data === 'string') {
                const data = JSON.parse(e.data);
                return data.payload.value === 'success';
            } else return e.data.payload.value === 'success';
        }
    } catch (e) {
        return false;
    }
};

const SuccessScreen = () => {
    return (
        <div className="VerificationComplete">
            <h3>Verification complete!</h3>
            <img className="SuccessImage" src={Img_KYC} alt="" />
            <p>We'll be in touch once we've checked your documents</p>
        </div>
    );
};

const ErrorScreen = () => {
    return (
        <div className="VerificationError">
            <img src={WarningIcon} alt="error" />
            <h3>Verification error</h3>
            <p>We couldn't verify you, please try again later</p>
        </div>
    );
};
