import React from 'react';
import { Circle } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';

interface SpinnerProps {
    size?: number;
    color?: string;
    scaleStart?: number;
    scaleEnd?: number;
    positionAbsolute?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
    size = 50,
    scaleStart = 0.4,
    scaleEnd = 0.8,
    positionAbsolute,
    color,
}) => {
    const { colors } = useTheme();
    return (
        <div className={positionAbsolute ? 'Spinner PositionAbsolute' : 'Spinner'}>
            <Circle
                size={size}
                color={color ?? colors.first}
                scaleStart={scaleStart}
                scaleEnd={scaleEnd}
            />
        </div>
    );
};

export { Spinner };
