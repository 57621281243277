/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, openModal } from 'reducers/modal';
import { CryptoExchangeItem, ExchangeType } from './models';
import { Form, Formik } from 'formik';
import { selectCryptoPricePair } from 'reducers/cryptoPrices';
import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { generateTileHeader } from './helpers';
import { useNavigateWithBasePath } from '../../helpers/useNavigateWithBasePath';

const initialValues: CurrencyExchangeOptions = {
    amount: '' as unknown as number,
    exchangeType: 'Buy',
};
export type CurrencyExchangeOptions = {
    amount: number;
    exchangeType: 'Buy' | 'Sell';
};

type Props = {
    item: CryptoExchangeItem;
    baseAsset: string;
    setFavourite: (pairedAssetsId: number, assetsId: number, bFavourited: boolean) => void;
};
export const CryptoExchangeTile = ({ item, baseAsset, setFavourite }: Props) => {
    const [expanded, setExpanded] = useState(false);
    const storePrice = useSelector(selectCryptoPricePair(item.pairedAsset, item.asset));
    const dispatch = useDispatch();
    const navigate = useNavigateWithBasePath();

    const numberformatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: storePrice?.maxDecimalPrecision ?? 4,
    });

    const isCorrectFormat = baseAsset === item.pairedAsset;

    const buyPrice = storePrice?.buyPrice ?? item.buyPrice;
    const sellPrice = storePrice?.sellPrice ?? item.sellPrice;
    const handleSubmit = (values: typeof initialValues) => {
        dispatch(
            openModal({
                modalType: ModalTypes.CRYPTO_EXCHANGE,
                data: {
                    ...item,
                    amount: values.amount,
                    buyPrice: 1 / buyPrice, // BE is sending them reversed
                    sellPrice: 1 / sellPrice,
                    exchangeType: isCorrectFormat
                        ? values.exchangeType
                        : swapExchangeType(values.exchangeType),
                    swapAssetPair: !isCorrectFormat,
                    navigate,
                },
            })
        );
    };
    const handleOpenChart = () => {
        dispatch(
            openModal({
                modalType: ModalTypes.CRYPTO_EXCHANGE_CHART,
                data: { ...item, reverseAsset: !isCorrectFormat, navigate },
            })
        );
    };
    const handleFavourite = () => {
        setFavourite(item.pairedAssetsId, item.assetsId, !item.bFavourited);
    };
    const { min, max } = getMinMaxStrings(item.minTradeSize, item.maxTradeSize, item.pairedAsset);

    const buySellOptions = [];
    if (item.bBuyAvailable) buySellOptions.push(isCorrectFormat ? 'Buy' : 'Sell');
    if (item.bSellAvailable) buySellOptions.push(isCorrectFormat ? 'Sell' : 'Buy');
    // Always have Buy option first
    buySellOptions.sort();

    const title = useMemo(
        () => generateTileHeader(item.asset, item.pairedAsset, !isCorrectFormat),
        [item.asset, item.pairedAsset]
    );

    return (
        <div className={classNames('CryptoExchangeTile', { Expanded: expanded })}>
            <div className={classNames('TileInner', { Expanded: expanded })}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form>
                        <div className="TileHeader">
                            <h3>{title}</h3>
                            <div className="Actions">
                                <button
                                    title="Favourite"
                                    type="button"
                                    className={classNames(
                                        'FavouriteButton',
                                        item.bFavourited ? 'Favourited' : ''
                                    )}
                                    onClick={handleFavourite}
                                />
                                <button
                                    title="Open Chart"
                                    type="button"
                                    className={'ChartButton'}
                                    onClick={handleOpenChart}
                                />
                            </div>
                        </div>
                        <div className="ExchangeValues">
                            {isCorrectFormat ? (
                                <div className="CurrencyItem">
                                    You buy {item.asset}{' '}
                                    <div
                                        className={classNames('CurrencyAmount', {
                                            Green: storePrice?.changeDirection === 'Down',
                                            Red: storePrice?.changeDirection === 'Up',
                                        })}
                                    >
                                        {numberformatter.format(buyPrice)}
                                        {storePrice?.changeDirection && (
                                            <ArrowIcon
                                                isPoisitve={storePrice?.changeDirection === 'Down'}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="CurrencyItem">
                                    You buy {item.pairedAsset}{' '}
                                    <div
                                        className={classNames('CurrencyAmount', {
                                            Red: storePrice?.changeDirection === 'Down',
                                            Green: storePrice?.changeDirection === 'Up',
                                        })}
                                    >
                                        {numberformatter.format(1 / sellPrice)}
                                        {storePrice?.changeDirection && (
                                            <ArrowIcon
                                                isPoisitve={storePrice?.changeDirection === 'Up'}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            {isCorrectFormat ? (
                                <div className="CurrencyItem">
                                    You sell {item.asset}{' '}
                                    <div
                                        className={classNames('CurrencyAmount', {
                                            Red: storePrice?.changeDirection === 'Down',
                                            Green: storePrice?.changeDirection === 'Up',
                                        })}
                                    >
                                        {numberformatter.format(sellPrice)}
                                        {storePrice?.changeDirection && (
                                            <ArrowIcon
                                                isPoisitve={storePrice?.changeDirection === 'Up'}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="CurrencyItem">
                                    You sell {item.pairedAsset}{' '}
                                    <div
                                        className={classNames('CurrencyAmount', {
                                            Green: storePrice?.changeDirection === 'Down',
                                            Red: storePrice?.changeDirection === 'Up',
                                        })}
                                    >
                                        {numberformatter.format(1 / buyPrice)}
                                        {storePrice?.changeDirection && (
                                            <ArrowIcon
                                                isPoisitve={storePrice?.changeDirection === 'Down'}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="MinMaxValues">
                            <div className="MinMaxItem">{min}</div>
                            <div className="MinMaxItem">{max}</div>
                        </div>
                        {expanded && (
                            <div>
                                <RadioButtons
                                    options={buySellOptions}
                                    showWithOnlyOneOption
                                    fieldname={'exchangeType'}
                                />
                                <div className="CurrencyInputWrapper">
                                    <div className="CurrencyLabel">
                                        {isCorrectFormat ? item.asset : item.pairedAsset}
                                    </div>
                                    <FormTextField
                                        field={'amount'}
                                        label={''}
                                        required={true}
                                        className="CurrencyAmountInput"
                                        type="number"
                                        inputProps={{ pattern: '[0-9]*', step: 'any' }}
                                        inputMode="numeric"
                                    />
                                </div>
                                <Button variety="full">Start contract booking</Button>
                            </div>
                        )}
                        <button
                            title="Expand info"
                            className="ExpandBtn"
                            type="button"
                            onClick={() => setExpanded((curr) => !curr)}
                        >
                            <div className={expanded ? 'ChevronOpen' : 'ChevronClosed'} />
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

const swapExchangeType = (exchangeType: ExchangeType): ExchangeType => {
    return exchangeType === 'Buy' ? 'Sell' : 'Buy';
};

const ArrowIcon = ({ isPoisitve }: { isPoisitve: boolean }) => {
    return (
        <div className={`ArrowIcon ${isPoisitve ? 'Up' : ''}`}>
            <IconMoneyIn width={10} />
        </div>
    );
};

const getMinMaxStrings = (min: number | undefined, max: number | undefined, baseAsset: string) => {
    return {
        min: min ? `Min: ${numberFormatter.format(min)} ${baseAsset}` : '',
        max: max ? `Max: ${numberFormatter.format(max)} ${baseAsset}` : '',
    };
};
const numberFormatter = new Intl.NumberFormat('en-US');
