/** @jsxImportSource @emotion/react */
import { FormSingleSelectField } from '@avamae/formbuilder';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { Link, navigate } from '@reach/router';
import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { FormDropdownField } from 'components/pagination/Dropdown';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { CurrencyDetails } from 'pages/register/models';
import { plaintext } from 'plaintext.config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCustomerComponentResources,
    selectAccountTypePathItem,
} from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

import FillerImage from 'assets/ibanera/Img_AccountType_01.png';
import { css, useTheme } from '@emotion/react';
import * as Yup from 'yup';
import { Toast } from 'helpers/toast';

const initialValues = {
    currencyCode: 'USD',
    accountName: '',
};

const validationSchema = Yup.object({
    accountName: Yup.string().required('Please choose an account name'),
});

export const AddAccount = () => {
    const [availableCurrencyCodes, setAvailableCurrencyCodes] = useState<SelectOption[]>([]);
    const theme = useTheme();

    const addAccountCss = css({ h3: { color: theme.colors.second } });

    const cultureCode = useSelector(selectCultureCode);
    const accountTypePathItem = useSelector(selectAccountTypePathItem);
    const dispatch = useDispatch();

    const translations = plaintext.addAccountPage;

    useEffect(() => {
        getAvailableCurrencies();
    }, []);

    const getAvailableCurrencies = () => {
        instance
            .get<ApiResponse<CurrencyDetails[]>>(endpoints.registration.availableCurrencies)
            .then((res) => {
                setAvailableCurrencyCodes(
                    res.data.details.map((curr) => ({
                        value: curr.code,
                        label: `${curr.name} - ${curr.symbol}`,
                    }))
                );
            })
            .catch((err) => {
                Toast.openGenericErrorToast();
                setAvailableCurrencyCodes([]); //TODO handle error
            });
    };

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post<ApiResponse<{ accountUrlPath: string }>>(endpoints.accounts.addAccount, values)
            .then((res) => {
                dispatch(getCustomerComponentResources());
                return navigate(`../${res.data.details.accountUrlPath}`);
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                Toast.openGenericErrorToast();
            });
    };
    return (
        <div className="AddAccountPage" css={addAccountCss}>
            <div className="LeftSide">
                <h3>{translations.addNewAccount}</h3>
                <p>{translations.blurb}</p>
                <p>{translations.enterDetails}</p>
                <h3>{translations.accountDetails}</h3>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {/* <FormSingleSelectField
                                fieldName="currencyCode"
                                label={translations.currency}
                                options={availableCurrencyCodes}
                            /> */}
                            <FormTextField
                                field="accountName"
                                label={translations.name}
                                required
                                placeholder={translations.exampleName}
                            />
                            <Button variety="full" type="submit" disabled={isSubmitting}>
                                {translations.buttonText}
                            </Button>
                        </Form>
                    )}
                </Formik>
                <div className="LegalText">
                    {translations.legalText1}
                    <Link to={`/${cultureCode}/${accountTypePathItem}/info/terms-conditions`}>
                        {translations.legalTextLink1}
                    </Link>
                    {translations.legalText2}
                    <Link to={`/${cultureCode}/${accountTypePathItem}/info/security-privacy`}>
                        {translations.legalTextLink2}
                    </Link>
                </div>
            </div>
            <img src={FillerImage} alt="" className="FillerImage" />
        </div>
    );
};
