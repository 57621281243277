/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik';
import { FormValues } from '../../schema';
import { useEffect } from 'react';
import { BUSINESS_OWNERS_FORM_STRING, INDIVIDUAL_OWNERS_FORM_STRING } from './OwnersTree';

export const useMapNumbering = () => {
    const { values, setFieldValue } = useFormikContext<FormValues>();

    //If array changed recalulate numbering
    useEffect(() => {
        setFieldValue(
            BUSINESS_OWNERS_FORM_STRING,
            values.ownerInformation.businessBeneficialOwners.map((o, i) => ({
                ...o,
                number: i + 1,
            }))
        );
    }, [values.ownerInformation.businessBeneficialOwners.length]);
    useEffect(() => {
        setFieldValue(
            INDIVIDUAL_OWNERS_FORM_STRING,
            values.ownerInformation.individualBeneficialOwners.map((o, i) => ({
                ...o,
                number: i + 1,
            }))
        );
    }, [values.ownerInformation.businessBeneficialOwners.length]);
};
