import React, { lazy } from 'react';
import { RouteComponentProps } from '@reach/router';
import { endpoints } from '../../endpoints.config';
import Category from '../../components/Category';
import { CrudItems } from 'components/Category/CrudMenu';

// Icons
import View from '../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../assets/Cat-Entity/Icon_Action_02.png';

const ApprovalType = lazy(() => import('./ManageApprovalType'));

// Define the url for the table's data endpoint.
const url = endpoints.approvalTypes.list;

// Define which column on the table represents each row's ID.
const idColumn: keyof ApprovalsListData = 'approvalTypes__Id';

//Define the data structure for table
export type ApprovalsListData = {
    approvalTypes__Id: string;
    approvalTypes__Name: string;
    approvalTypes__NumberOfApprovers: number;
    approvalTypes__bSuppressed: boolean;
    approvalTypes__AddedBy: string;
};

const crudItems: CrudItems<ApprovalsListData> = [
    // { //Hidden for now - Doesn't add any details of value
    //     icon: View,
    //     title: 'View Approval Type',
    //     imgAltText: 'View',
    //     menuCode: 'VIEW',
    //     showInMultiSelect: false,
    //     content: (onDone, selectedData, menu) => (
    //         <ApprovalType
    //             onDone={onDone}
    //             selectedData={selectedData}
    //             idFromRowOption={menu.selectedId}
    //             actionType="view"
    //             submitButtonText="Close"
    //             noSubmit={true}
    //         />
    //     ),
    //     inRightPanel: true,
    //     isDisabled: false,
    // },
    {
        icon: Update,
        imgAltText: 'Update',
        title: 'Assign Users',
        menuCode: 'UPDATE',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <ApprovalType
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="edit"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: ApprovalsListData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: ApprovalsListData = { ...row };

    return formattedRow;
};

export interface BusinessUsersProps {}

type Props = BusinessUsersProps & RouteComponentProps;

function ManageApprovalTypes(props: Props) {
    return (
        <Category<ApprovalsListData>
            location={props.location}
            contentTitle="Delegate Approvers"
            noun="Approval Types"
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn
            classname="listsCatTable"
            emptyTableLabel="No approvals found"
        />
    );
}

export default ManageApprovalTypes;
