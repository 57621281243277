import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from '@reach/router';
import { SideMenuLink } from 'components/sideMenu/SideMenu';
import React, { useMemo } from 'react';
import { BalanceDisplay, BalanceDisplayAccountInfo } from './BalanceDisplay';
/** @jsxImportSource @emotion/react */

type SubNavigationMenuProps = {
    items: SideMenuLink[];
    basePath: string;
    accountInfo: BalanceDisplayAccountInfo | null;
};

const INVISIBLE_NAV_OPTIONS = ['accounts/transaction-details', 'accounts/transfer-details'];

export const SubNavigationMenu: React.FC<SubNavigationMenuProps> = ({
    items,
    basePath,
    accountInfo,
}) => {
    const theme = useTheme();
    const { pathname } = useLocation();

    const isLinkActive = (path: string) => pathname.endsWith(path);

    const activeLinkStyle = css`
        border-bottom: 3px solid ${theme.colors.first};
        // To prevent text from moving when active
        padding-top: 2.5px;
    `;

    const filteredItems = useMemo(
        () => items.filter((item) => !INVISIBLE_NAV_OPTIONS.includes(item.path)),
        [items]
    );

    const hideLinkOptions = filteredItems.length <= 1;
    return (
        <>
            <div className={`SubNavigationMenu ${hideLinkOptions ? 'HideLinkOptions' : ''}`}>
                <BalanceDisplay accountInfo={accountInfo} />
                {!hideLinkOptions && (
                    <div className="SubNavigationMenuWrapper">
                        {filteredItems.map((item) => {
                            const path = item.path.split('/').slice(-1)[0];
                            const isActive = isLinkActive(path);
                            const style = isActive ? activeLinkStyle : null;
                            return (
                                <Link
                                    to={`../${path}`}
                                    className={`Link`}
                                    css={style}
                                    key={item.id}
                                >
                                    <span className="LinkText">{item.displayName}</span>
                                </Link>
                            );
                        })}
                    </div>
                )}
                <div className="Hidden" />
            </div>
        </>
    );
};
