import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';

type Zoho = {
    salesiq?: {
        visitor?: {
            name(...args: any): void;
            email(...args: any): void;
            info(...args: any): void;
        };
    };
};

export const useZoho = () => {
    const zoho: Zoho | undefined = (window as any).$zoho;
    const { userInfo } = useSelector(selectComponentResources);

    useEffect(() => {
        if (!userInfo || !zoho) return;
        zoho.salesiq?.visitor?.name(`${userInfo.firstName} ${userInfo.lastName}`);
        zoho.salesiq?.visitor?.email(userInfo.emailAddress ?? '');
        zoho.salesiq?.visitor?.info({ Site: 'Ibanera', 'Account Type': 'Personal' });
    }, [userInfo, zoho]);
};
