/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import React from 'react';
import TickImage from '../../assets/ibanera/Icon_Tick_White.png';

type EitherOrSelectionProps = {
    title: string;
    selected: boolean;
    onClick: () => void;
    image: string;
    value: string;
    recommended?: boolean;
    subText?: string;

};

export const EitherOrSelection: React.FC<EitherOrSelectionProps> = ({
    title,
    selected,
    onClick,
    image,
    value,
    recommended,
    subText
}) => {
    const theme = useTheme();
    return (
        <label
            className={`RegistrationTile ${selected ? 'Selected' : ''}`}
            onClick={onClick}
            css={{
                '.Recommended': {
                    backgroundColor: theme.colors.third,
                },
                '&.Selected': {
                    backgroundColor: theme.colors.alertNeutralBackground,
                    borderColor: theme.colors.first,
                },
                '&.Selected .RegistrationTileBottom': { backgroundColor: theme.colors.first },
                '&.Selected .RegistrationTileBottom .CheckmarkContainer': {
                    backgroundColor: theme.colors.first,
                    borderColor: theme.colors.first,
                },
                '.RegistrationTileLabel': { color: theme.colors.headingColor },
            }}
        >
            <div className="RegistrationTileTop">
                <span className="RegistrationTileLabel">{title}</span>
                {subText ? <span className='RegistrationTileSubText'>{subText}</span> : <></>}
                <img src={image} className="RegistrationTileImage" alt="Personal" />
                <input
                    type="radio"
                    name="accountType"
                    checked={selected}
                    onChange={() => {
                        selected = !selected;
                    }}
                    value={value}
                />
            </div>
            {recommended && <div className="Recommended">Recommended</div>}
            <div className="RegistrationTileBottom">
                <div className="CheckmarkContainer">
                    <img src={TickImage} className="Checkmark" alt="Tick" />
                </div>
            </div>
        </label>
    );
};
