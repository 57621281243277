import { ThemeProvider } from '@emotion/react';
import { Router } from '@reach/router';
import { CheckingDetails } from 'components/checkingDetails/CheckingDetails';
import { GlobalStyles } from 'components/GlobalStyles/GlobalStyles';
import { LoadingScreen } from 'components/loadingScreen/LoadingScreen';
import { GlobalModal } from 'components/modals/GlobalModal';
import { Notifications } from 'components/notifications/Notifications';
import { useCheckSignedIn } from 'helpers/useCheckSignedIn';
import { useNotificationUIUpdates } from 'helpers/useNotificationUIUpdates';
import { useZoho } from 'helpers/useZoho';
import { DashboardContentPage } from 'pages/dashboard/DashboardContentPage';
import { DashboardInformationPage } from 'pages/dashboard/DashboardInformationPage';
import { ForgottenPassword } from 'pages/forgottenPassword/ForgottenPassword';
import { PublicInformationPage } from 'pages/publicInformationPage/PublicInformationPage';
import { ResetPassword } from 'pages/resetPassword/ResetPassword';
import { AutoLogin } from 'pages/signIn/AutoLogin';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectAppVisible, selectAuthStatus } from 'reducers/auth';
import { getInitialCryptoPrices } from 'reducers/cryptoPrices';
import { selectisVerified } from 'reducers/verification';
import { initialTheme } from 'theme';
import instance, { ApiResponse } from './api';
import { AppPath, DashboardPath } from './appConstants';
import { endpoints } from './endpoints.config';
import {
    addExpiryDate,
    getItemFromLocalStorage,
    storeInLocalStorage,
    hasContentExpired,
} from './helpers/cache';
import Dashboard from './pages/dashboard/Dashboard';
import { Register } from './pages/register/Register';
import SignIn from './pages/signIn/SignIn';
import { selectTheme, setBranding } from './reducers/ui';
import { ReviewAmendedOrder } from './pages/crypto/ReviewAmendedOrder';
import { SignificantPartyJumio } from 'pages/SignificantPartyJumio';
import { ManageesJumio } from 'pages/ManageesJumio';
import { useCheckInfoRequest } from './helpers/useCheckInfoRequest';
import { DirectLogin } from 'pages/signIn/DirectLogin';
import { useFetchErrorCodes } from './helpers/useFetchErrorCodes';

const themeUrl = endpoints.licenseesmodule.licenseesbranding;

interface Branding {
    companyName: string;
    initialTheme: typeof initialTheme;
    images: { assetsPath: string };
    expiryDate: string;
    bBusinessAccountsEnabled: boolean;
    bPersonalAccountsEnabled: boolean;
}

export const DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER = 'home';
export const NOTIFICATIONS_PORTAL_ID = 'notifications-portal';

function App() {
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);

    const cachedBranding = getItemFromLocalStorage<Branding>('branding');

    useNotificationUIUpdates();
    useZoho();

    useCheckInfoRequest();
    useFetchErrorCodes();

    // fetch app theme
    useEffect(() => {
        if (cachedBranding && !hasContentExpired(cachedBranding)) {
            dispatch(
                setBranding({
                    theme: cachedBranding.initialTheme,
                    companyName: cachedBranding.companyName,
                    imagesPath: cachedBranding.images.assetsPath,
                    bBusinessAccountsEnabled: cachedBranding.bBusinessAccountsEnabled,
                    bPersonalAccountsEnabled: cachedBranding.bPersonalAccountsEnabled,
                })
            );
            return;
        }

        instance.get<ApiResponse<Branding>>(themeUrl).then((res) => {
            if (res.status === 200) {
                if (res.data.status === '1') {
                    const branding = res.data.details;
                    const contentToCache = addExpiryDate(branding);
                    storeInLocalStorage(contentToCache, 'branding');
                    dispatch(
                        setBranding({
                            theme: {
                                colors: {
                                    ...branding.initialTheme.colors,
                                },
                            },
                            companyName: branding.companyName,
                            imagesPath: branding.images.assetsPath,
                            bBusinessAccountsEnabled: branding.bBusinessAccountsEnabled,
                            bPersonalAccountsEnabled: branding.bPersonalAccountsEnabled,
                        })
                    );
                }
            }
        });
    }, []);

    useCheckSignedIn();
    const isAppVisible = useSelector(selectAppVisible);
    const authStatus = useSelector(selectAuthStatus);
    const isVerified = useSelector(selectisVerified);

    useEffect(() => {
        if (authStatus === 'signed_in' && isVerified) {
            dispatch(getInitialCryptoPrices());
        }
    }, [authStatus, isVerified, dispatch]);

    useEffect(() => {
        if (!theme || !document) return;

        const root = document.documentElement;
        root.style.setProperty('--firstColor', theme.colors.first);
        root.style.setProperty('--firstColorHover', theme.colors.firstHover);
        root.style.setProperty('--secondColor', theme.colors.second);
        root.style.setProperty('--secondColorHover', theme.colors.secondHover);
        root.style.setProperty('--thirdColor', theme.colors.third);
        root.style.setProperty('--thirdColorHover', theme.colors.thirdHover);

        root.style.setProperty('--alertGood', theme.colors.alertGood);
        root.style.setProperty('--alertGoodHover', theme.colors.alertGoodHover);
        root.style.setProperty('--alertGoodBackground', theme.colors.alertGoodBackground);
        root.style.setProperty('--alertBad', theme.colors.alertBad);
        root.style.setProperty('--alertBadHover', theme.colors.alertBadHover);
        root.style.setProperty('--alertBadBackground', theme.colors.alertBadBackground);
        root.style.setProperty('--alertNeutral', theme.colors.alertNeutral);
        root.style.setProperty('--alertNeutralHover', theme.colors.alertNeutralHover);
        root.style.setProperty('--alertNeutralBackground', theme.colors.alertNeutralBackground);

        root.style.setProperty('--fifthColor', theme.colors.headingColor);
        root.style.setProperty('--sixthColor', theme.colors.secondBackground);
    }, [theme]);

    if (!isAppVisible) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div className="Main">
                <GlobalModal />
                <LoadingScreen color={theme.colors.first} />
                <Notifications
                    activate={authStatus === 'signed_in'}
                    className="NotificationsContainer"
                    portalID={NOTIFICATIONS_PORTAL_ID}
                />
                <ToastContainer
                    position={toast.POSITION.BOTTOM_CENTER}
                    className="ToastMessage"
                    autoClose={5000}
                    transition={Slide}
                    icon={false}
                    theme={'dark'}
                    hideProgressBar
                    pauseOnHover
                />
                <Router>
                    <SignificantPartyJumio path={AppPath.SIGNIFICANT_PARTY_JUMIO} />
                    <ManageesJumio path={AppPath.MANAGEES_JUMIO} />
                    <DirectLogin path={`${AppPath.DIRECT_LOGIN}`} />
                    <AutoLogin path={`${AppPath.AUTO_LOGIN}`} />
                    <SignIn path={`:cultureCode${AppPath.SIGN_IN}`} default />
                    <Register path={`:cultureCode${AppPath.REGISTER}`} />
                    <Register
                        path={`:cultureCode${AppPath.WAITLIST_BYPASSED_REGISTRATION}`}
                        skipWaitlist
                    />
                    <ForgottenPassword path={`:cultureCode${AppPath.FORGOTTEN_PASSWORD}`} />
                    <ResetPassword path={`:cultureCode${AppPath.RESET_PASSWORD}`} />
                    {/* We take the user to this route whilst fetching the user information
                    which will include the account type, which will dictate whether we direct
                    to AppPath.PERSONAL or AppPath.BUSINESS dashboard route. */}
                    <CheckingDetails path={`:cultureCode${AppPath.CHECKING_DETAILS}`} />
                    <ReviewAmendedOrder
                        path={`:cultureCode${AppPath.PERSONAL}${AppPath.REVIEW_ORDER}`}
                    />
                    <ReviewAmendedOrder
                        path={`:cultureCode${AppPath.BUSINESS}${AppPath.REVIEW_ORDER}`}
                    />
                    <Dashboard path={`:cultureCode${AppPath.PERSONAL}`}>
                        {/* Info pages are used for CMS content pages such as those linked to in the footer. */}
                        <DashboardInformationPage path={`${DashboardPath.INFO}/:page`} />
                        {/* Some dashboard pages don't have any subpages, so we 
                        include a placeholder for sub-page for those paths so that we can match the 
                        component to this single route. Going forward we could opt to 
                        nest the path instead. */}
                        <DashboardContentPage path={`:page/`} />
                        <DashboardContentPage path={`:page/:sub_page`} />
                        <DashboardContentPage path={`:page/:sub_page/:sub_sub_page`} />
                    </Dashboard>
                    <Dashboard path={`:cultureCode${AppPath.BUSINESS}`}>
                        <DashboardInformationPage path={`${DashboardPath.INFO}/:page`} />
                        <DashboardContentPage path={`:page/`} />
                        <DashboardContentPage path={`:page/:sub_page`} />
                        <DashboardContentPage path={`:page/:sub_page/:sub_sub_page`} />
                    </Dashboard>
                    <PublicInformationPage path={`:cultureCode${AppPath.INFO}/:page`} />
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
