import React from 'react';
import { FieldAttributes, useField } from 'formik';
import { FormFieldTooltip, TooltipIconData } from './FormFieldTooltip';
import classNames from 'classnames';

const Input = React.forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
        FieldAttributes<any> & {
            currencySymbol?: string;
            tooltip?: React.ReactNode;
            tooltipIconData?: TooltipIconData | null;
        }
>(({ className = '', onChange, currencySymbol, tooltip, tooltipIconData, ...props }, ref) => {
    const [field, meta] = useField(props);
    const showError = meta.touched && meta.error;

    const onBlur = (e: Event) => {
        props.onBlur && props.onBlur(e);
        field.onBlur(e);
    };

    if (props.type === 'textarea') {
        return (
            <div className={`InputWrapper FormTextArea${currencySymbol ? 'HasSymbol' : ''}`}>
                {tooltip && (
                    <FormFieldTooltip tooltip={tooltip} tooltipIconData={tooltipIconData} />
                )}
                <textarea
                    rows={8}
                    maxLength={1000}
                    {...(field as any)}
                    className={classNames('EditBox', className, {
                        ErrorInput: showError,
                        WithTooltip: tooltip,
                    })}
                    {...props}
                    onBlur={onBlur}
                    ref={ref}
                ></textarea>
            </div>
        );
    }
    return (
        <div className={`InputWrapper ${currencySymbol ? 'HasSymbol' : ''}`}>
            {tooltip && <FormFieldTooltip tooltip={tooltip} tooltipIconData={tooltipIconData} />}
            <input
                {...(field as any)}
                className={classNames('EditBox', className, {
                    ErrorInput: showError,
                    WithTooltip: tooltip,
                })}
                disabled={props.disabled === true}
                {...props}
                onBlur={onBlur}
                ref={ref}
                onChange={(e) => {
                    field.onChange(e);
                    onChange && onChange(e);
                }}
            />
            {currencySymbol && <span className="CurrencySymbol">{currencySymbol}</span>}
        </div>
    );
});

export { Input };
