import React, { useEffect } from 'react';
import { WindowLocation } from '@reach/router';
import { useGetPageTitle } from 'helpers/categoryHelpers/useGetPageTitle';
import { useTable, TableInfo } from 'api';
import { Spinner } from 'components/circleSpinner/Spinner';
import { PageHeader } from 'components/categoryHelpers/pageHeader/PageHeader';
import { useMenuState, CrudItems } from './CrudMenu';
import { useSaveData } from 'helpers/categoryHelpers/useSaveData';
import { useGetPermissions } from 'helpers/categoryHelpers/useGetPermissions';
import { FlexTable } from 'components/flexTable';
import { FilterBuilder } from 'components/categoryHelpers/filterBuilder/FilterBuilder';
import CrudMenu from './CrudMenu';
import RowOptionsMenu from './RowOptionMenu';
import Pagination from 'components/pagination/Pagination';
import { AxiosRequestConfig } from 'axios';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectUIUpdate } from 'components/notifications/notificationUIUpdateReducer';
import { TableProps } from '../flexTable/FlexTable';

export function CheckBox(props: {
    isSelected: boolean;
    onChange(): void;
    name: React.ReactText;
}): JSX.Element {
    const { isSelected, onChange, name } = props;
    return (
        <div>
            <div>
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={onChange}
                    id={String(name)}
                    name={String(name)}
                />
                <label className="CustomInput" htmlFor={name.toString()} />
            </div>
        </div>
    );
}

export type Props<Data> = Omit<Omit<TableProps<Data>, 'table'>, 'rowOptionComponent'> & {
    location: WindowLocation | undefined | string;
    noun: string;
    idColumn: string;
    listEndpointUrl: string;
    crudComponents: CrudItems<Data>;
    hiddenColumns: string[];
    rowOptionComponent?: (menu: any, selectedIds: any[]) => TableProps['rowOptionComponent'];
    noAddBtn: boolean;
    fetchConfig?: AxiosRequestConfig;
    contentTitle?: string;
    classname?: string;

    setSubCatShown?: React.Dispatch<React.SetStateAction<boolean>>;
    headerTitleElement?: JSX.Element | React.ReactElement;

    //// setSelectedData?: React.Dispatch<React.SetStateAction<Data[]>>;
    //// setIdFromRowOption?: React.Dispatch<React.SetStateAction<(React.ReactText | null)>>;
    // setSelectedParentData?: React.Dispatch<React.SetStateAction<[any[], any[]]>>; //React.Dispatch<React.SetStateAction<[Data[], any[]]>>;//React.Dispatch<React.SetStateAction<any[][]>>;
    // setIdFromParentRowOption?: React.Dispatch<
    //     React.SetStateAction<[React.ReactText | null, React.ReactText | null]>
    // >; // React.Dispatch<React.SetStateAction<(React.ReactText | null)[]>>;
    // selectedParentData?: [any[], any[]]; //[Data[], any[]]; //any[][];
    // idFromParentRowOption?: [React.ReactText | null, React.ReactText | null];
    setSelectedParentData?: React.Dispatch<React.SetStateAction<Data[]>>;
    setIdFromParentRowOption?: React.Dispatch<React.SetStateAction<React.ReactText | null>>;
    idFromParentRowOption?: React.ReactText | null;
    selectedParentData?: Data[];
    isParent?: boolean;
    rowButtonBuilder?: (t: TableInfo) => (d: Data) => JSX.Element;
    emptyTableLabel: string;

    notification?: {
        notificationType?: string;
        notificationFn: ({
            pushType,
            customFn,
        }: {
            pushType?: string;
            customFn?: (val?: any) => void;
        }) => void;
    };
};

const permissionsRequested = ['create', 'edit', 'view', 'delete'];

function Category<Data>(props: Props<Data>) {
    const {
        location,
        idColumn,
        listEndpointUrl,
        crudComponents,
        hiddenColumns,
        rowOptionComponent,
        noAddBtn,
        fetchConfig,
        contentTitle,
        classname,
        setSubCatShown,
        setSelectedParentData,
        setIdFromParentRowOption,
        selectedParentData,
        idFromParentRowOption,
        isParent,
        rowButtonBuilder,
        emptyTableLabel,

        notification,
        ...tableProps
    } = props;

    const pageTitle = useGetPageTitle(location);
    const permissions = useGetPermissions(permissionsRequested, location);
    const table = useTable<Data, Error>({
        url: listEndpointUrl,
        ...fetchConfig,
    });

    const { loading, data, error } = table;
    const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
    const changeSearch = data?.actions.changeSearch;

    const [visibleOptions, setVisibleOptions] = React.useState<string | number | null>(null);

    const [transState, setTransState] = React.useState<boolean | null>(null);

    const selectedData = useSaveData<Data>(selectedIds, idColumn, table as TableInfo);

    const [menu, menuActions] = useMenuState<Data>({
        menuItems: crudComponents,
        selectedData,
        permissions,
        table: table as TableInfo,
        idColumn,
    });
    const hasMultiRowAction = crudComponents.some((action) => action.showInMultiSelect);
    useEffect(() => {
        if (setSelectedParentData && setIdFromParentRowOption) {
            if (
                selectedParentData &&
                selectedParentData.length === 0 &&
                idFromParentRowOption === null
            ) {
                setIdFromParentRowOption(menu.selectedId);
                setSelectedParentData(selectedData.length > 0 ? selectedData : menu.selectedData);
            } else if (
                selectedData.length === 0 &&
                menu.selectedData.length === 0 &&
                menu.selectedId === null
            ) {
                setIdFromParentRowOption(null);
                setSelectedParentData([]);
            }
        }
    }, [
        menu.selectedData,
        menu.selectedId,
        selectedData,
        setIdFromParentRowOption,
        setSelectedParentData,
    ]);

    const tableOptionComponent = (data: any) => (
        <RowOptionsMenu
            data={data}
            menuState={[menu, menuActions]}
            selectedIds={selectedIds}
            crudItems={crudComponents}
            idColumn={data[idColumn]}
            visible={visibleOptions}
            setVisible={setVisibleOptions}
        />
    );

    const onActionComplete = () => {
        menuActions.closeMenus();
        setSelectedIds([]);
        table.reload();
    };

    const update = useSelector(selectUIUpdate);
    const dispatch = useDispatch();
    useEffect(() => {
        if (notification && notification.notificationFn && notification.notificationType) {
            // notification &&
            //     notification.notificationFn &&
            notification.notificationFn({
                pushType: notification?.notificationType,
                customFn: () => table?.reload(),
            });
        }
    }, [update, table, dispatch, notification]);

    if (loading && !table.data) return <Spinner />;

    if (error) throw error;

    if (data) {
        const rowButton = rowButtonBuilder
            ? rowButtonBuilder(table as TableInfo)
            : tableProps.rowButton;
        const initialSearchString = data.details.searchString;
        return (
            <div
                className={`CategoryContainer ${
                    transState === true ? 'entered' : transState === false ? 'exited' : ''
                } ${classname ? classname : ''}`}
                style={{ display: 'flex' }}
            >
                <div
                    className={`CategoryPage ${
                        transState === true ? 'entered' : transState === false ? 'exited' : ''
                    }`}
                >
                    <PageHeader
                        noAddBtn={noAddBtn}
                        onOpenFilters={() => menuActions.openMenu('FILTERS', null)}
                        addButtonText={`Add ${props.noun}`}
                        onAddButtonClick={
                            permissions.has('create')
                                ? () => menuActions.openMenu('CREATE', null)
                                : undefined
                        }
                        pageTitle={contentTitle ? contentTitle : pageTitle}
                        table={table as TableInfo}
                        initialSearchString={initialSearchString ?? ''}
                        changeSearch={changeSearch}
                    />
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection:
                                table?.data?.details.listData.length === 0 ? 'column' : undefined, //for some reason flex-column breaks the scrolling
                        }}
                    >
                        <FlexTable
                            idColumn={idColumn}
                            table={table as TableInfo}
                            hiddenColumns={hiddenColumns}
                            onFilterClick={
                                data.details.filters.length === 0
                                    ? () => menuActions.openMenu('FILTERS', null)
                                    : () => menuActions.openMenu('FILTERS', null) // table.data?.actions.setFilters([])
                            }
                            checkbox={{
                                selectedIds,
                                setSelectedIds,
                                idColumn,
                                singleRowSelect: !hasMultiRowAction,
                            }}
                            rowOptionComponent={tableOptionComponent}
                            hideFiltering={false}
                            //showFiltering={true}
                            rowButton={rowButton}
                            {...tableProps}
                        />
                        {table?.data?.details.listData.length === 0 && !table?.loading && (
                            <div className="EmptyTable">
                                <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                                <h3 className="Message">{emptyTableLabel}</h3>
                            </div>
                        )}
                    </div>
                    <Pagination table={table} />
                </div>
                <CrudMenu
                    menu={menu}
                    selectedData={selectedData.length > 0 ? selectedData : menu.selectedData}
                    menuItems={menu.menuItems}
                    crudItems={crudComponents}
                    closePanel={menuActions.closeMenus}
                    onDone={onActionComplete}
                    filters={
                        <FilterBuilder
                            onDone={onActionComplete}
                            table={table as TableInfo}
                            hiddenFields={hiddenColumns}
                        />
                    }
                    setTransState={setTransState}
                    setSubCatShown={setSubCatShown}
                    // selectedParentData={selectedParentData}
                    // setSelectedParentData={setSelectedParentData}
                    // idFromParentRowOption={idFromParentRowOption}
                    // setIdFromParentRowOption={setIdFromParentRowOption}
                />
            </div>
        );
    }

    return <Spinner />;
}

export default Category;
