/* eslint-disable react-hooks/exhaustive-deps */
import FormTextField from 'components/form/FormTextField';
import { useFormikContext } from 'formik';
import { roundToPrecision } from 'helpers/calculateToPrecision';
import { ExchangeDetails } from 'pages/cryptoV2/models';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCryptoPricePair } from 'reducers/cryptoPrices';
import { FormValues, QuantityType } from '../CryptoExchangeModal';
import {
    bottomBuySellString,
    buttonString,
    getFieldNames,
    getPrecisionRounding,
    topBuySellString,
    useGetAssetPrecision,
} from '../../../pages/cryptoV2/helpers';
import Button from 'components/button/Button';

import { ReactComponent as ExchangeSvg } from 'assets/icon-exchange.svg';

export const ExchangeDetail = ({
    exchangeDetails,
    reverseUX,
}: {
    exchangeDetails: ExchangeDetails;
    reverseUX: boolean;
}) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const sourceCurrency = exchangeDetails.sourceAsset;
    const destinationCurrency = exchangeDetails.destinationAsset;

    const isBuyExchange = values.exchangeType === 'Buy';

    const { top: topFieldName, bottom: bottomFieldName } = getFieldNames(
        isBuyExchange,
        reverseUX,
        values.quantityType
    );

    const exactPrice =
        topFieldName === 'destinationAmount' || topFieldName === 'sourceAmount' ? 'Top' : 'Bottom';

    const storePrice = useSelector(
        selectCryptoPricePair(exchangeDetails.pairedAsset, exchangeDetails.asset)
    );

    const pairedAssetPrecision = useGetAssetPrecision(exchangeDetails.pairedAsset);
    const assetPrecision = storePrice?.maxDecimalPrecision ?? 2;

    const precisionRounding = getPrecisionRounding(
        exactPrice === 'Top',
        assetPrecision,
        pairedAssetPrecision,
        reverseUX
    );

    const switchQuantityType = () => {
        if (values.quantityType === QuantityType.Destination) {
            setFieldValue('quantityType', QuantityType.Source);
            setFieldValue('sourceAmount', values.estimatedSourceAmount, true);
            setFieldValue('estimatedSourceAmount', null);
            setFieldValue('destinationAmount', null);
            setFieldValue('estimatedDestinationAmount', values.destinationAmount);
        } else {
            setFieldValue('quantityType', QuantityType.Destination);
            setFieldValue('sourceAmount', null);
            setFieldValue('estimatedSourceAmount', values.sourceAmount);
            setFieldValue('destinationAmount', values.estimatedDestinationAmount);
            setFieldValue('estimatedDestinationAmount', null);
        }
    };
    useEffect(() => {
        storePrice?.buyPrice && setFieldValue('buyPrice', 1 / storePrice.buyPrice);
        storePrice?.sellPrice && setFieldValue('sellPrice', 1 / storePrice.sellPrice);
    }, [storePrice, setFieldValue]);
    useEffect(() => {
        if (values.sourceAmount === null) return;
        setFieldValue(
            'estimatedDestinationAmount',
            roundToPrecision(
                isBuyExchange
                    ? values.sourceAmount * values.buyPrice
                    : values.sourceAmount / values.sellPrice,
                precisionRounding
            )
        );
    }, [
        values.sourceAmount,
        isBuyExchange,
        values.sellPrice,
        values.buyPrice,
        setFieldValue,
        exactPrice,
        storePrice?.maxDecimalPrecision,
    ]);
    useEffect(() => {
        if (values.destinationAmount === null) return;
        setFieldValue(
            'estimatedSourceAmount',
            roundToPrecision(
                isBuyExchange
                    ? values.destinationAmount / values.buyPrice
                    : values.destinationAmount * values.sellPrice,
                precisionRounding
            )
        );
    }, [
        values.destinationAmount,
        isBuyExchange,
        values.sellPrice,
        values.buyPrice,
        setFieldValue,
        exactPrice,
        storePrice?.maxDecimalPrecision,
    ]);

    const topBuySellStr = topBuySellString(isBuyExchange, reverseUX, exactPrice === 'Top');
    const bottomBuySellStr = bottomBuySellString(isBuyExchange, reverseUX, exactPrice === 'Bottom');

    const buttonStr = buttonString(isBuyExchange, reverseUX, values.quantityType);

    return (
        <div className="ExchangeDetail">
            <div className={'InputSection' + (exactPrice === 'Top' ? ' Exact' : '')}>
                {/* eg. your're buying... */}
                {topBuySellStr}
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel">
                        {!reverseUX
                            ? isBuyExchange
                                ? destinationCurrency
                                : sourceCurrency
                            : isBuyExchange
                            ? sourceCurrency
                            : destinationCurrency}
                    </div>
                    <FormTextField
                        field={topFieldName}
                        label={''}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        inputProps={{ pattern: '[0-9]*' }}
                        inputMode="numeric"
                        disabled={exactPrice !== 'Top'}
                    />
                </div>
            </div>
            <div className="ExchangeRate">
                <Button
                    title="Switch selected input"
                    type="button"
                    className="Btn ExactToggle"
                    onClick={switchQuantityType}
                >
                    <ExchangeSvg /> {buttonStr}
                </Button>
                <div style={{ marginLeft: 5, width: 60 }}>Indicative Sell Rate:</div>
                <div className="Rates">
                    <p>
                        {values.pairedAsset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {values.asset} ~{' '}
                        {roundToPrecision(
                            isBuyExchange ? values.buyPrice : values.sellPrice,
                            storePrice?.maxDecimalPrecision ?? 6
                        )}
                    </p>
                    <p>
                        {values.asset} 1 <span className="c1">{'>'}</span>
                        <span className="c2">{'>'}</span>
                        <span className="c3">{'>'}</span> {values.pairedAsset} ~{' '}
                        {roundToPrecision(
                            !isBuyExchange ? 1 / values.sellPrice : 1 / values.buyPrice,
                            storePrice?.maxDecimalPrecision ?? 6
                        )}
                    </p>
                </div>
            </div>
            <div className={'InputSection' + (exactPrice === 'Bottom' ? ' Exact' : '')}>
                {bottomBuySellStr}
                <div className="CurrencyInputWrapper">
                    <div className="CurrencyLabel">
                        {!reverseUX
                            ? isBuyExchange
                                ? sourceCurrency
                                : destinationCurrency
                            : isBuyExchange
                            ? destinationCurrency
                            : sourceCurrency}
                    </div>
                    <FormTextField
                        field={bottomFieldName}
                        label={''}
                        required={true}
                        className="CurrencyAmountInput"
                        type="number"
                        inputProps={{ pattern: '[0-9]*' }}
                        inputMode="numeric"
                        disabled={exactPrice !== 'Bottom'}
                    />
                </div>
            </div>
        </div>
    );
};
