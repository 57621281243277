import { SignInTranslation } from 'pages/signIn/signInTypes';
import { SetStateAction } from 'react';

export enum RegisterStep {
    SELECT_ACCOUNT_TYPE,
    INPUT_REGISTRATION_DETAILS,
    INPUT_PERSONAL_DETAILS,
    BUSINESS_DETAILS,
    EMAIL_VERIFICATION,
    SELECT_AUTH_TYPE,
    TFA_SETUP,
    TFA_VERIFICATION,
    COMPLETE,
}

export enum AccountType {
    PERSONAL = 'Personal',
    BUSINESS = 'Business',
}

export enum AuthType {
    APP = 'App',
    SMS = 'SMS',
}
export type TFAType = 'AuthenticatorApp' | 'SMS';

export interface RegistrationStepProps {
    translations: SignInTranslation | null;
    onComplete: () => void;
}
export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type RegistrationAdditionalInfo = Nullable<{
    accountType: AccountType;
    bConductsThirdPartyPayments: boolean;
    requestedProducts: number[];
    registeredCountryCode: string;
}>;

export interface SelectAccountTypeProps extends RegistrationStepProps {
    accountType: AccountType | null;
    disablePersonalRegistration?: boolean | null;
}
export interface SelectAuthTypeProps extends RegistrationStepProps {
    authType: AuthType;
    setAuthType: React.Dispatch<SetStateAction<AuthType>>;
}

export interface InputDetailsProps extends RegistrationStepProps {
    accountType: AccountType | null;
    setAccountDetails: React.Dispatch<SetStateAction<AccountDetails>>;
}

export interface EmailVerificationProps extends RegistrationStepProps {
    accountDetails: AccountDetails;
    formikProps?: any;
}

export type EmailVerificationCodePayload = {
    username: string;
    password: string;
    emailVerificationCode: string;
    bResendCode: boolean;
};
export type TFAVerificationPayload = {
    username: string;
    password: string;
    tfaType: string;
    tfaCode: string;
    phoneNumber?: string;
    totpSharedSecret?: string;
};

export type AccountDetails = {
    emailAddress: string;
    password: string;
    phoneNumber?: string;
};

export type CurrencyDetails = {
    name: string;
    code: string;
    symbol: string;
};

type State = { id: number; isoCode: string; name: string };

export type CountryDetails = {
    name: string;
    isoCode: string;
    states: State[] | null;
};
export interface RegistrationDetails {
    countries: CountryDetails[];
    entityTypes: { id: number; name: string }[];
}

export interface PersonalDetails {
    accountType: string;
    firstName: string;
    middleNames: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    currencyCode: string;
    nameConfirmed: boolean;
    taxNumber: string;
}

export interface BusinessDetails {
    personalDetails: {
        firstName: string;
        middleNames: string;
        lastName: string;
        emailAddress: string;
        phoneNumber: string;
        password: string;
        confirmPassword: string;
        currencyCode: string;
        nameConfirmed: boolean;
        taxNumber: string;
    };
}

export type RegisterCustomerUserPayload = {
    accountType: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    companyEmailAddress?: string;
    password: string;
    confirmPassword: string;
    companyName?: string;
    companyRole?: string;
    addressLine1?: string;
    addressLine2?: string;
    townCity?: string;
    county?: string;
    postCode?: string;
    countryISOCode?: string;
};

export type RegisterValidateStepOne = {
    companyName: string;
    companyEmailAddress: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
};

export interface TFASetupProps extends RegistrationStepProps {
    authType: string;
    setAuthType: React.Dispatch<SetStateAction<AuthType>>;
    setSharedSecret: React.Dispatch<SetStateAction<string>>;
    setPhoneNumber: React.Dispatch<SetStateAction<string>>;
    accountDetails: AccountDetails;
}

export type TFASetupState = {
    tfaStep: string;
};
export type AppSetupDetails = {
    qrCode: any;
    sharedSecret: string;
};

export interface SMSTFASetup {
    username: string;
    password: string;
    phoneNumber?: string;
    bNewPhoneNumber: boolean;
}
export interface TFAVerificationProps extends RegistrationStepProps {
    authType: string;
    accountDetails: AccountDetails;
    sharedSecret?: string;
    phoneNumber?: string;
    setAuthType: React.Dispatch<React.SetStateAction<AuthType>>;
    setRegistrationStep: React.Dispatch<React.SetStateAction<RegisterStep>>;
}

export interface BusinessErrors {
    fieldName: string;
    message: string;
}
