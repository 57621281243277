import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import FormTextField from 'components/form/FormTextField';
import { CorporateApp } from '../corporateAppModel';
import Button from 'components/button/Button';
import { RadioButton } from 'components/radiobuttons/radiobuttons';

interface Props {
    expandAll: boolean;
    setIsExpanded: React.SetStateAction<any>;
}

const CustomerAccounts: React.FC<Props> = ({ expandAll, setIsExpanded }) => {
    const { values, setFieldValue, isSubmitting } = useFormikContext<CorporateApp>();

    return (
        <div>
            <FieldArray
                name="corporate.otherAccounts"
                render={(arrayHelpers) => (
                    <>
                        <div className="FormBox">
                            <div className="FormLabel">
                                <label>Does your company have any accounts with other banks?</label>
                            </div>
                            <div className="RadioButtons">
                                <RadioButton
                                    label="Yes"
                                    selected={values.corporate.otherAccounts.length > 0}
                                    onClick={() =>
                                        values.corporate.otherAccounts.length === 0 &&
                                        arrayHelpers.push({ accountName: '', number: 1 })
                                    }
                                />
                                <RadioButton
                                    label="No"
                                    selected={values.corporate.otherAccounts.length === 0}
                                    onClick={() => setFieldValue('corporate.otherAccounts', [])}
                                />
                            </div>
                        </div>
                        {values.corporate.otherAccounts &&
                        values.corporate.otherAccounts.length > 0 ? (
                            values.corporate.otherAccounts.map((account: any, index: number) => (
                                <div className="FormBox">
                                    <FormTextField
                                        field={`corporate.otherAccounts.${index}.accountName`}
                                        label={'Bank Account Name'}
                                        required={false}
                                        maxLength={200}
                                        sideBySide={true}
                                        asterisk={true}
                                    />
                                    {values.corporate.otherAccounts.length > 1 && (
                                        <Button
                                            type="button"
                                            priority="primary"
                                            variety="full"
                                            onClick={() => arrayHelpers.remove(index)}
                                            disabled={isSubmitting}
                                            style={{ marginBottom: 10 }}
                                        >
                                            Remove Account
                                        </Button>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div></div>
                        )}
                        {values.corporate.otherAccounts.length > 0 && (
                            <Button
                                type="button"
                                priority="secondary"
                                variety="full"
                                onClick={() => {
                                    arrayHelpers.push({
                                        accountName: '',
                                        number:
                                            Math.max(
                                                ...values.corporate.otherAccounts.map(
                                                    (account) =>
                                                        account?.number ??
                                                        values.corporate.otherAccounts.length
                                                )
                                            ) + 1,
                                    });
                                }}
                            >
                                Add Bank Account
                            </Button>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default CustomerAccounts;
