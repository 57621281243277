import React from "react";
import { Transition } from "react-transition-group";

type TableOptionItem = {
  name: string;
  onClick(id: string | number): void;
};

type TableOptionsProps = {
  id: string | number;
  visibleOptions: string | number | null;
  close(): void;
  optionList: TableOptionItem[];
};

type Props = TableOptionsProps;

const RowOptions: React.FC<Props> = ({
  id,
  visibleOptions,
  close,
  optionList,
}) => {
  return (
    <Transition
      in={visibleOptions === id}
      timeout={50}
      mountOnEnter
      unmountOnExit
    >
      {transitionState => (
        <ClickOutsideDetector onClickOutside={() => close()}>
          <div className={"OptionsList " + transitionState}>
            {optionList.map(item => (
              <span
                className="OptionItem"
                onClick={() => {
                  item.onClick(id);
                  close();
                }}
                key={item.name}
              >
                {item.name}
              </span>
            ))}
          </div>
        </ClickOutsideDetector>
      )}
    </Transition>
  );
};

export { RowOptions };

type ClickOutsideDetectorProps = {
  onClickOutside(): void;
};

const ClickOutsideDetector: React.FC<ClickOutsideDetectorProps> = ({
  onClickOutside,
  children,
}) => {
  return (
    <div>
      <div className="ClickOutsideDetector" onClick={onClickOutside} />
      {children}
    </div>
  );
};
