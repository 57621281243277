import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { Modal } from 'components/modal/Modal';
import {
    NotificationIdentifier,
    setUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { endpoints } from 'endpoints.config';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, selectModalState } from 'reducers/modal';
import * as Yup from 'yup';

type Props = {};

export type NoteData = {
    transactionId: number;
    note: string;
};

const validationSchema = Yup.object({
    note: Yup.string().max(1000, 'Must be less than 1000 characters').nullable(),
});

export const TransactionNoteModal = (props: Props) => {
    const dispatch = useDispatch();
    const modalState = useSelector(selectModalState);
    if (modalState.modalType !== ModalTypes.TRANSACTION_NOTE) return null;
    const initialValues: NoteData = {
        transactionId: modalState.data.customerAssetAccountsTransactions__Id,
        note: '',
    };
    const handleSubmit = (values: NoteData, helpers: FormikHelpers<NoteData>) => {
        instance
            .post(endpoints.accounts.noteTransaction, values)
            .then((res) => {
                dispatch(
                    setUIUpdate({
                        pushType: NotificationIdentifier.ADD_TRANSACTION_NOTE,
                        data: values,
                    })
                );
                dispatch(closeModal());
            })
            .catch((err) => {
                Toast.openGenericErrorToast();
                dispatch(closeModal());
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ submitForm, isSubmitting }) => (
                <Modal
                    title={`Transaction Notes`}
                    className="TransactionNoteModal"
                    customButtons={createModalButtons(submitForm, dispatch, isSubmitting)}
                >
                    <FormTextField
                        type="textarea"
                        field={'note'}
                        label={'Note'}
                        required={false}
                        className="TransactionNoteTextArea"
                        placeholder="Type your text here"
                        hint="Maximum Length is 1000 characters"
                    />
                </Modal>
            )}
        </Formik>
    );
};

const createModalButtons = (submitfn: () => void, dispatch: any, submitting: boolean) => (
    <div className="ModalNavigation">
        <Button onClick={() => dispatch(closeModal())} priority="secondary">
            Close
        </Button>
        <Button
            onClick={submitfn}
            priority="primary"
            // disabled={!pullFundsFinal || submitting}
            disabled={submitting}
            type={'submit'}
        >
            Save
        </Button>
    </div>
);
