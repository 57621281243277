import instance, { ApiResponse } from 'api';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { update } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setVerificationStage } from 'reducers/verification';
import { JumioStage, JumioVerificationStatusResponse } from './jumioModels';

const iframeOrigin = 'https://netverify.com';
export const allowableOrigins = [
    iframeOrigin,
    'https://ibanera.netverify.com',
    'https://ibanera.web.amer-1.jumio.ai',
];

export const JumioIframe = ({ url, stage }: { url: string; stage: JumioStage }) => {
    const dispatch = useDispatch();

    const handleComplete = useCallback(() => {
        const url =
            stage === JumioStage.IdFrame
                ? endpoints.jumiomodule.idIframeComplete
                : endpoints.jumiomodule.documentIframeComplete;
        instance
            .post<ApiResponse<JumioVerificationStatusResponse>>(url)
            .then((res) => {
                dispatch(
                    setVerificationStage({
                        currentStage: res.data.details.status,
                        additionalData: res.data.details.additionalData,
                    })
                );
            })
            .catch((err) => Toast.openGenericErrorToast()); //TODO figure out how to handle this call failing
    }, [stage, dispatch]);

    const handleMessage = useCallback(
        (e: MessageEvent<any>) => {
            if (allowableOrigins.includes(e.origin.toLowerCase())) {
                if (typeof e.data === 'string') {
                    const data = JSON.parse(e.data);
                    if (data.payload.value === 'success') handleComplete();
                } else if (e.data.payload.value === 'success') handleComplete();
            }
        },
        [handleComplete]
    );

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);

    return (
        <iframe
            title="Jumio ID Iframe"
            src={url}
            className="JumioIframe"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
        ></iframe>
    );
};
