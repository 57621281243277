import { DocumentRejection } from './BusinessVerification';

export enum BusinessVerificationStage {
    Terms = 'NotStarted',
    SignificantParties = 'Step1',
    OperatingAddress = 'Step2',
    CompanyRep = 'CompanyRep',
    MainBusinessPartners = 'MainBusinessPartners',
    MainActivity = 'MainActivity',
    Funding = 'Funding',
    OtherBankAccounts = 'OtherBankAccounts',
    AnticipatedActivity = 'AnticipatedActivity',
    Documents = 'Step3',
    Submit = 'Step4',
    Complete = 'Submitted',
    Verified = 'Verified',
}

export enum BackendBusinessVerificationStage {
    Terms = BusinessVerificationStage.Terms,
    SignificantParties = BusinessVerificationStage.SignificantParties,
    OperatingAddress = BusinessVerificationStage.OperatingAddress,
    CompanyRep = BusinessVerificationStage.CompanyRep,
    MainBusinessPartners = BusinessVerificationStage.MainBusinessPartners,
    MainActivity = BusinessVerificationStage.MainActivity,
    Funding = BusinessVerificationStage.Funding,
    OtherBankAccounts = BusinessVerificationStage.OtherBankAccounts,
    AnticipatedActivity = BusinessVerificationStage.AnticipatedActivity,
    Documents = BusinessVerificationStage.Documents,
    Submit = BusinessVerificationStage.Submit,
    Complete = BusinessVerificationStage.Complete,
    Verified = BusinessVerificationStage.Verified,
}
export const getStageFromBackend = (
    stage?: BackendBusinessVerificationStage
): BusinessVerificationStage => {
    if (!stage) return BusinessVerificationStage.Terms;
    // if (stage === BackendBusinessVerificationStage.CorporateApplication)
    //     return BusinessVerificationStage.OperatingAddress;
    return stage as unknown as BusinessVerificationStage;
};
export const getEquivalentBackendStage = (
    stage: BusinessVerificationStage
): BackendBusinessVerificationStage => {
    return stage as unknown as BackendBusinessVerificationStage;
};
export const getPreviousStage = (currentStage: BusinessVerificationStage) => {
    const currentStageIndex = BusinessStepsOrder.findIndex((stage) => stage === currentStage);
    return BusinessStepsOrder[currentStageIndex > 0 ? currentStageIndex - 1 : 0];
};
export const getNextStage = (currentStage: BusinessVerificationStage) => {
    const currentStageIndex = BusinessStepsOrder.findIndex((stage) => stage === currentStage);
    return BusinessStepsOrder[
        currentStageIndex + 1 < BusinessStepsOrder.length
            ? currentStageIndex + 1
            : currentStageIndex
    ];
};
export const stageTitles = {
    [BusinessVerificationStage.Terms]: 'Terms',
    [BusinessVerificationStage.SignificantParties]: 'Significant Parties',
    [BusinessVerificationStage.OperatingAddress]: 'Operating Address',
    [BusinessVerificationStage.CompanyRep]: 'Company Representative',
    [BusinessVerificationStage.MainBusinessPartners]: 'Main Business Partners',
    [BusinessVerificationStage.MainActivity]: 'Main Activity',
    [BusinessVerificationStage.Funding]: 'Funding',
    [BusinessVerificationStage.OtherBankAccounts]: 'Other Bank Accounts',
    [BusinessVerificationStage.AnticipatedActivity]: 'Anticipated Activity',
    [BusinessVerificationStage.Documents]: 'Documents',
    [BusinessVerificationStage.Submit]: 'Submit',
    [BusinessVerificationStage.Complete]: 'Submitted',
    [BusinessVerificationStage.Verified]: 'verified',
} as const;

export const BusinessStepsOrder = [
    BusinessVerificationStage.Terms,
    BusinessVerificationStage.SignificantParties,
    BusinessVerificationStage.OperatingAddress,
    BusinessVerificationStage.CompanyRep,
    BusinessVerificationStage.MainBusinessPartners,
    BusinessVerificationStage.MainActivity,
    BusinessVerificationStage.Funding,
    BusinessVerificationStage.OtherBankAccounts,
    BusinessVerificationStage.AnticipatedActivity,
    BusinessVerificationStage.Documents,
    BusinessVerificationStage.Submit,
    BusinessVerificationStage.Complete,
    BusinessVerificationStage.Verified,
] as const;
export type BusinessVerificationStatusResponse = {
    businessVerificationStatus: 'Accepted' | 'Rejected' | 'Pending' | null;
    businessVerificationStep: BackendBusinessVerificationStage;
    additionalData: AdditionalData;
    rejectedDocuments: DocumentRejection;
};

export type AdditionalData = {
    //UPDATE WITH NEW ENDPOINT DATA
    iframeUrl?: string;
    bManualAvailable?: boolean;
} | null;
