import React, { Suspense, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { Spinner } from 'components/circleSpinner/Spinner';
import Cross from '../../../assets/Cat-Entity/Icon_Modal_Cross.png';
import ErrorBoundary from 'components/page/ErrorBoundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';

export interface RightPanelProps {
    rightPanelContent: boolean | null;
    closePanel(): void;
    title?: string;
}

const RightPanel: React.FC<RightPanelProps> = ({
    children,
    rightPanelContent,
    closePanel,
    title = 'Right panel',
}) => {
    // useEffect(() => {
    //   let el = document.querySelector(".SubTitle")?.nextElementSibling;

    //   while (el && !el.classList.contains("SubTitle")) {
    //     // opt 1 - add a class that can be used to add style
    //     el.classList.add("custom-class");

    //     // go to the next element in the DOM
    //     el = el.nextElementSibling;
    //   }
    // }, []);
    return (
        <Transition in={!!rightPanelContent} timeout={100} mountOnEnter unmountOnExit>
            {(transitionState) => (
                <>
                    <div
                        className={'RightPanelUnderlay ' + transitionState}
                        onMouseDown={closePanel}
                    />
                    <div className={'RightPanel ' + transitionState}>
                        <div className="Inner">
                            <div className="Top">
                                <h2 className="Title">{title}</h2>
                                <div className="Cross">
                                    <img src={Cross} alt="Cross" onClick={closePanel} />
                                </div>
                            </div>
                            <Suspense fallback={<Spinner positionAbsolute />}>
                                <ErrorBoundary fallback={<ErrorFallback />}>
                                    {children ?? null}
                                </ErrorBoundary>
                            </Suspense>
                        </div>
                    </div>
                </>
            )}
        </Transition>
    );
};

export { RightPanel };
