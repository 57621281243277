/* eslint-disable react-hooks/exhaustive-deps */
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import RedX from 'assets/ibanera/Icon_RedCross.png';
import FormTextField from '../../form/FormTextField';
import { WrappedFormSingleSelectField } from '../../form/FormSingleSelectField';
import { FormDateField } from '../../form/FormDateField';
import { FormValues } from '../schema';
import { useAfterMountEffect } from '../../../helpers/useAfterMountEffect';

type Props = {
    index: number;
    fieldName: string;
    parentField: string;
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    entityTypes: SelectOption[];
    arrayHelpers: FieldArrayRenderProps;
};

export const BusinessOwner: React.FC<Props> = ({
    index,
    fieldName,
    parentField,
    availableCountries,
    entityTypes,
    arrayHelpers,
}) => {
    const [expand, setExpand] = useState<boolean>(false);

    const { isSubmitting, setFieldValue } = useFormikContext<FormValues>();
    const [{ value }, { error, touched }] = useField(fieldName);
    const [{ value: parentFieldValue }] = useField<any[]>(parentField);
    const [{ value: countryValue }] = useField<any[]>(`${fieldName}.addressCountry`);
    const [{ value: operatingCountryValue }] = useField<any[]>(
        `${fieldName}.operatingAddressCountry`
    );
    const [{ value: entityTypesIdValue }] = useField<any[]>(`${fieldName}.entityTypesID`);

    const hasError = !!(error && touched);

    useEffect(() => {
        if (!expand && hasError) setExpand(true);
    }, [hasError, isSubmitting]);

    useEffect(() => {
        if (!value.firstName && !expand) setExpand(true);
    }, [value.firstName, parentFieldValue.length]);

    useEffect(() => {
        //If array changed recalulate numbering
        setFieldValue(`${fieldName}.number`, index + 1);
    }, [parentFieldValue.length]);

    const handleRemoveBusiness = () => {
        arrayHelpers.remove(index);
    };

    const stateOptions = useMemo(() => {
        return availableCountries.find((country) => country.value === countryValue)?.states ?? null;
    }, [availableCountries, countryValue]);

    const operatingStateOptions = useMemo(
        () =>
            availableCountries.find((country) => country.value === operatingCountryValue)?.states ??
            null,
        [availableCountries, operatingCountryValue]
    );

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.addressState`, '');
    }, [stateOptions]);
    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`${fieldName}.operatingAddressState`, '');
    }, [operatingStateOptions]);

    const isOtherEntityType =
        entityTypesIdValue &&
        entityTypesIdValue == entityTypes?.find((type) => type.label === 'Other')?.value;

    return (
        <div className="MarginBottom">
            <div
                className={`FormHeader ${hasError && !expand ? 'FormHeaderError' : ''}`}
                onClick={(e) => setExpand(!expand)}
            >
                <div className="LeftSide">
                    <h3 className="Title">{`Business ${index + 1}`}</h3>
                    <button
                        className="DeleteOwner"
                        aria-label={`Delete Individual ${index + 1}`}
                        type="button"
                        onClick={() => handleRemoveBusiness()}
                    >
                        <img src={RedX} alt="Delete file" />
                    </button>
                </div>
                <div className="Row">
                    <div className={expand ? 'ChevronOpen' : 'ChevronClosed'}></div>
                </div>
            </div>
            {expand && (
                <div className="Form">
                    <FormTextField
                        field={`${fieldName}.entityName`}
                        label={'Legal Name of Entity'}
                    />
                    <div className="FormSideBySide">
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={`${fieldName}.entityTypesID`}
                                options={entityTypes}
                                label={'Type of Entity'}
                            />
                        </div>

                        <div className="FormBuilderComponent SideBySide">
                            <FormDateField
                                field={`${fieldName}.registrationDate`}
                                label={'Date of Entity Registration'}
                            />
                        </div>
                    </div>
                    {isOtherEntityType && (
                        <FormTextField
                            field={`${fieldName}.entityTypesOther`}
                            label={'Entity Type'}
                        />
                    )}
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`${fieldName}.registrationNumber`}
                            label={'Official Business Registration Number'}
                            sideBySide
                        />
                        <FormTextField
                            field={`${fieldName}.taxNumber`}
                            sideBySide
                            label={'Tax ID Number'}
                        />
                    </div>
                    <FormTextField
                        type="textarea"
                        field={`${fieldName}.doingBusinessAs`}
                        label={'Doing Business As - DBA (Please list all business names.)'}
                    />
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`${fieldName}.addressStreet`}
                            label={'Registered Address Street'}
                            sideBySide
                        />
                        <FormTextField
                            field={`${fieldName}.addressNumber`}
                            label={'Registered Address Unit Number'}
                            sideBySide
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`${fieldName}.addressPostCode`}
                            label={'Registered Address Postal Code'}
                            sideBySide
                        />
                        <FormTextField
                            field={`${fieldName}.addressCity`}
                            label={'Registered Address City'}
                            sideBySide
                        />
                    </div>
                    <div className="FormSideBySide">
                        {stateOptions ? (
                            <div className="FormBuilderComponent SideBySide">
                                <WrappedFormSingleSelectField
                                    fieldName={`${fieldName}.addressState`}
                                    options={stateOptions}
                                    label={
                                        'Registered Address State / County / Province (as applicable to the jurisdiction)'
                                    }
                                />
                            </div>
                        ) : (
                            <FormTextField
                                field={`${fieldName}.addressState`}
                                label={
                                    'Registered Address State / County / Province (as applicable to the jurisdiction)'
                                }
                                sideBySide
                            />
                        )}
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={`${fieldName}.addressCountry`}
                                options={availableCountries}
                                label={'Registered Country'}
                            />
                        </div>
                    </div>
                    <FormTextField
                        field={`${fieldName}.percentageSharesOwned`}
                        label={'Percentage of Shares Owned (%)'}
                        type="number"
                        maxLength={200}
                    />
                    <h3>
                        Business Operating Information (If different than registerd information.)
                    </h3>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`${fieldName}.operatingAddressStreet`}
                            label={'Operating Address Street'}
                            labelExtraInfo="Optional"
                            sideBySide
                        />
                        <FormTextField
                            field={`${fieldName}.operatingAddressNumber`}
                            label={'Operating Address Unit Number'}
                            labelExtraInfo="Optional"
                            sideBySide
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`${fieldName}.operatingAddressPostCode`}
                            label={'Operating Address Postal Code'}
                            labelExtraInfo="Optional"
                            sideBySide
                        />
                        <FormTextField
                            field={`${fieldName}.operatingAddressCity`}
                            label={'Operating City'}
                            labelExtraInfo="Optional"
                            sideBySide
                        />
                    </div>
                    <div className="FormSideBySide">
                        {operatingStateOptions ? (
                            <div className="FormBuilderComponent SideBySide">
                                <WrappedFormSingleSelectField
                                    fieldName={`${fieldName}.operatingAddressState`}
                                    options={operatingStateOptions}
                                    label={
                                        'Operating State / County / Province (as applicable to the jurisdiction)'
                                    }
                                />
                            </div>
                        ) : (
                            <FormTextField
                                field={`${fieldName}.operatingAddressState`}
                                label={
                                    'Operating State / County / Province (as applicable to the jurisdiction)'
                                }
                                labelExtraInfo="Optional"
                                sideBySide
                            />
                        )}
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={`${fieldName}.operatingAddressCountry`}
                                options={availableCountries}
                                label={'Operating Country'}
                                labelExtraInfo="Optional"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
