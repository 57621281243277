import { ReactComponent as WarningIcon } from 'assets/ui-update/warning.svg';

type Props = {
    title?: string;
    message?: string;
};

export const GeneralError: React.FC<Props> = ({
    title = 'Something went wrong',
    message = 'Unable to get info',
}) => {
    return (
        <div className="GeneralError">
            <WarningIcon width={50} />
            <h2 className="ErrorTitle">{title}</h2>
            <p>{message}</p>
        </div>
    );
};
