import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { ErrorM } from '../../form/ErrorM';
import { OwnersTree } from '../helpers/OwnersTree/OwnersTree';
import { useVerificationContext } from '../helpers/verificationContext';
import { FormValues } from '../schema';
import { ButtonCheckboxInput } from './FinalSteps';
import BackButton from '../helpers/BackButton';

export const BeneficialOwners = () => {
    const { selectOptions } = useVerificationContext();
    const { values, setFieldValue, submitCount, setFieldTouched } = useFormikContext<FormValues>();

    const availableCountries = useMemo(
        () =>
            (selectOptions?.availableCountries ?? []).map((country) => ({
                value: country.isoCode,
                label: country.countryName,
                states: country.states
                    ? country.states.map((state) => ({
                          label: state.stateProvinceName,
                          value: state.ansiCode,
                      }))
                    : null,
            })),
        [selectOptions?.availableCountries]
    );

    useEffect(() => {
        if (submitCount < 1) return;
        setFieldTouched('bConfirmAboveTen', true);
    }, [setFieldTouched, submitCount]);

    useEffect(() => {
        if (values.ownerInformation.exemptionId) {
            setFieldValue(fieldName('individualBeneficialOwners'), []);
            setFieldValue(fieldName('businessBeneficialOwners'), []);
            setFieldValue(fieldName('beneficialOwnersStructure'), []);
            setFieldValue(fieldName('bConfirmAboveTen'), null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.ownerInformation.exemptionId]);

    return (
        <div className="BeneficialOwners">
            <BackButton />
            <h2>Beneficial Owner or Controlling Party Information</h2>
            <p>
                We are required to gather information on owners who own 10% or more of the business.
                Please add owners or signers on the account below. If you are an exempt entity,
                choose the type of exemption below.
            </p>
            <WrappedFormSingleSelectField
                fieldName={fieldName('exemptionId')}
                options={selectOptions?.uboReportingExemptions ?? []}
                label={'Choose from the Exempt List'}
                labelExtraInfo="Optional"
                dropdownProps={{ isClearable: values.ownerInformation.exemptionId }}
                tooltip={
                    'If you feel like you are an exempt entity choose from the list below, compliance will validate and get back with you.'
                }
            />
            {!values.ownerInformation.exemptionId && (
                <>
                    <OwnersTree
                        availableCountries={availableCountries}
                        entityTypes={selectOptions?.entityTypes ?? []}
                    />
                    <ButtonCheckboxInput
                        name={fieldName('bConfirmAboveTen')}
                        text="I confirm that I added all beneficial owners with 10% or more ownership"
                        showErrorMessage
                    />
                </>
            )}

            <FormTextField
                field={fieldName('authorizedSigner')}
                label={'Add an individual that is an authorized signer for the organization'}
                tooltip="Provide the name/s of all authorized signatories on the account. These are individuals authorized to move funds on behalf of the organization"
            />
            <ErrorM name="ownerInformation" />
        </div>
    );
};

const fieldName = (name: string) => `ownerInformation.${name}`;
