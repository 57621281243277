import { useTheme } from '@emotion/react';
import React from 'react';

type Props = {};

export const CardIcon = (props: Props) => {
    const { colors } = useTheme();
    const [st0, st1, st2, st3, st4]: React.CSSProperties[] = [
        { display: 'none' },
        { display: 'inline', fill: colors.first },
        { display: 'inline' },
        {
            display: 'inline',
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 18,
            strokeMiterlimit: 10,
        },
        { fill: colors.first },
    ];
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 625.5 406.5"
            className="CardImg"
            /* style={{enableBackground:"new 0 0 625.5 406.5"}} */ xmlSpace="preserve"
        >
            <style type="text/css"></style>
            <g id="Layer_1" style={st0}>
                <path
                    style={st1}
                    d="M605.3,406.5H20.1C9,406.5,0,397.5,0,386.4V20.1C0,9,9,0,20.1,0h0h585.3c11.1,0,20.1,9,20.1,20.1v366.4
		C625.4,397.6,616.4,406.5,605.3,406.5z"
                />
                <path
                    style={st2}
                    d="M480.9,235.5h79.6c12.2,0,22,9.8,22,22v41c0,12.2-9.8,22-22,22h-79.6c-12.2,0-22-9.8-22-22v-41
		C458.9,245.3,468.7,235.5,480.9,235.5z"
                />
                <line style={st3} x1="40.9" y1="249.5" x2="334.9" y2="249.5" />
                <line style={st3} x1="40.9" y1="299.5" x2="208.9" y2="299.5" />
            </g>
            <g id="Layer_2" style={st0}>
                <rect style={st3} width="619.1" height="401" />
            </g>
            <g id="Layer_3" style={st0}></g>
            <g id="Layer_4">
                <path
                    style={st4}
                    d="M550.3,0H76.7c-28,0-50.8,22.7-50.8,50.8v304.5c0,28,22.7,50.8,50.8,50.8h473.7c28,0,50.8-22.7,50.8-50.8V50.8
		C601.1,22.7,578.4,0,550.3,0z M210.9,318c0,15.5-12.6,28-28,28H114c-15.5,0-28-12.6-28-28V274c0-15.5,12.6-28,28-28h68.9
		c15.5,0,28,12.6,28,28V318z M541,132c0,15.5-12.6,28-28,28H114c-15.5,0-28-12.6-28-28V88c0-15.5,12.6-28,28-28h399
		c15.5,0,28,12.6,28,28V132z"
                />
            </g>
        </svg>
    );
};
