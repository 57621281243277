import React from 'react';
import Button from 'components/button/Button';

//icons
import WarningIcon from 'assets/Icon_Warning.png';

export const JumioRejected = () => {
    const zoho = (window as any).$zoho.salesiq;
    const handleZoho = () => {
        const question = 'My verification attempt was rejected';
        zoho.visitor.question(question);
        zoho.chat.start();
    };
    return (
        <div className="VerificationError">
            <img src={WarningIcon} alt="error" />
            <h3>Verification attempt rejected</h3>
            <p>We couldn't verify your account, please contact support.</p>
            {zoho && (
                <Button priority="primary" onClick={handleZoho}>
                    Live chat
                </Button>
            )}
        </div>
    );
};
