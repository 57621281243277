import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import { localizedFormatDate, localizedParseDate } from 'helpers/language';
import { plaintext } from 'plaintext.config';

export const DateRangePicker = (props: {
    setFilter: (startDate: Date, endDate: Date) => void;
    cultureCode?: string | null;
    currentDates?: { fromDate: string; toDate: string };
}) => {
    const { setFilter, cultureCode, currentDates } = props;

    const [startDate, setStartDate] = useState(new Date(currentDates?.fromDate || '2020-01-01'));
    const [endDate, setEndDate] = useState(
        currentDates?.toDate ? new Date(currentDates.toDate) : new Date()
    );

    const [startFocused, setStartFocused] = useState(false);
    const [endFocused, setEndFocused] = useState(false);

    const _setStartDate = (date: Date) => {
        setStartDate(date);
        setFilter(date, endDate);
    };
    const _setEndDate = (date: Date) => {
        setEndDate(date);
        setFilter(startDate, date);
    };

    const onCloseCalendar = () => {
        setFilter(startDate, endDate);
    };

    const onStartClose = () => {
        setStartFocused(false);
        onCloseCalendar();
    };
    const onEndClose = () => {
        setEndFocused(false);
        onCloseCalendar();
    };

    return (
        <div className="DateRange">
            <div>
                <label>Filter by date</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        _setStartDate(date ?? startDate);
                    }}
                    locale={cultureCode ?? 'en-GB'}
                    wrapperClassName={startFocused ? 'Focused' : ''}
                    dateFormat="P"
                    onCalendarOpen={() => setStartFocused(true)}
                    onCalendarClose={onStartClose}
                    onBlur={onStartClose}
                    customInput={
                        <DashedDateInput
                            onClick={() => {}}
                            value={localizedFormatDate(startDate, cultureCode ?? 'en-GB', 'P')}
                            cultureCode={cultureCode ?? 'en-gb'}
                            onSubmit={(date) => _setStartDate(date)}
                        />
                    }
                />
            </div>
            <div>
                <label>{plaintext.accounts.to}</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                        _setEndDate(date ?? endDate);
                    }}
                    locale={cultureCode ?? 'en-GB'}
                    dateFormat="P"
                    wrapperClassName={endFocused ? 'Focused' : ''}
                    onCalendarOpen={() => setEndFocused(true)}
                    onCalendarClose={onEndClose}
                    onBlur={onEndClose}
                    customInput={
                        <DashedDateInput
                            onClick={() => {}}
                            value={localizedFormatDate(endDate, cultureCode ?? 'en-GB', 'P')}
                            cultureCode={cultureCode ?? 'en-gb'}
                            onSubmit={(date) => _setEndDate(date)}
                        />
                    }
                />
            </div>
        </div>
    );
};

const DashedDateInput = forwardRef<
    HTMLInputElement,
    { value: string; onClick: () => void; onSubmit: (date: Date) => void; cultureCode: string }
>(({ value, onSubmit, cultureCode, onClick }, ref) => {
    const [dateString, setDateString] = useState(value.replace(/\//g, ' - '));
    const debouncedSubmit = useCallback(
        debounce(
            (dateString) =>
                onSubmit(localizedParseDate(dateString.replace(/[^\d]+/g, '/'), cultureCode)),
            2500
        ),
        [cultureCode]
    );
    useEffect(() => {
        setDateString(value);
    }, [value]);
    const _setDateString = (value: string) => {
        setDateString(value);
        debouncedSubmit(value);
    };
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter')
            onSubmit(localizedParseDate(dateString.replace(/[^\d]+/g, '/'), cultureCode));
    };
    return (
        <input
            className=""
            onClick={onClick}
            ref={ref}
            value={dateString}
            onChange={(e) => _setDateString(e.target.value)}
            onKeyDown={handleKeyDown}
        />
    );
});
