import { BusinessVerificationStage } from './BusinessVerificationV2Models';

type RegistrationInformation = {
    entityName: string | null;
    entityTypesID: number | null;
    entityTypesOther: string | null;
    registrationDate: string | null;
    registrationNumber: string | null;
    taxNumber: string | null;
    doingBusinessAs: string | null;
    addressStreet: string | null;
    addressNumber: string | null;
    addressPostCode: string | null;
    addressCity: string | null;
    addressState: string | null;
    addressCountry: string | null;
    operatingAddressStreet: string | null;
    operatingAddressNumber: string | null;
    operatingAddressPostCode: string | null;
    operatingAddressCity: string | null;
    operatingAddressState: string | null;
    operatingAddressCountry: string | null;
};
type OperationsInformation = {
    webAddress: string | null;
    phoneNumber: string | null;
    supportEmail: string | null;
    bPubliclyListed: string | null; //"Yes","No","NA"
    ticker: string | null;
    exchanges: string | null; //plain text list of all exchanges?
    operationRegionIds: Array<number>;
    bLicenseRequired: string | null; //"Yes","No","NA"
    additionalLicensingInfo: string | null;
    primaryRegulator: string | null;
    licenseNumber: string | null;
    financialInstitutionFormFileName: string | null;
    businessActivityIds: Array<number>;
    web3ChainsAndAddresses: string | null;
    sourceOfFundsIds: Array<number>;
    sourceOfFundsOther: string | null;
    activeBanks: string | null;
    yearlyTransactionsId: any; // value provided from BE options
    monthlyUsdValueId: any; // value provided from BE options
};

export type BeneficialPersonalOwner = {
    number: null | number;
    firstName: string;
    lastName: string;
    title: string;
    dateOfBirth: string;
    nationality: string;
    email: string;
    phoneNumber: string;
    country: string;
    ssn: string;
    stateProvince: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    postcode: string;
    percentageSharesOwned: null | number;
    position: string;
    bControllingParty: null | boolean;
    proofOfAddressFilename: string | null;
};
type BeneficialBusinessOwner = {
    number: number;
    percentageSharesOwned: null | number;
} & RegistrationInformation;
type OwnerInformation = {
    exemptionId: number | null;
    individualBeneficialOwners: Array<BeneficialPersonalOwner>;
    businessBeneficialOwners: Array<BeneficialBusinessOwner>;
    authorizedSigner: string | null;
};
type Terms = {
    bTaxAcknowledgement: boolean;
    bFatcaAcknowledgement: boolean;
    bConfirmTrueAndCorrect: boolean;
    bAcceptTerms: boolean;
};

export interface FormValues {
    businessVerificationStep: BusinessVerificationStage;
    registrationInformation: RegistrationInformation;
    operationsInformation: OperationsInformation;
    ownerInformation: OwnerInformation;
    terms: Terms;
    documentsUpload: {
        documentTypesID: number | null;
        fileName: string;
        bAlwaysRequired?: boolean;
    }[];
}

export const initialValue: FormValues = {
    businessVerificationStep: BusinessVerificationStage.RegistrationInformation,
    registrationInformation: {
        entityName: null,
        entityTypesID: null,
        entityTypesOther: null,
        registrationDate: null,
        registrationNumber: null,
        taxNumber: null,
        doingBusinessAs: null,
        addressStreet: null,
        addressNumber: null,
        addressPostCode: null,
        addressCity: null,
        addressState: null,
        addressCountry: null,
        operatingAddressStreet: null,
        operatingAddressNumber: null,
        operatingAddressPostCode: null,
        operatingAddressCity: null,
        operatingAddressState: null,
        operatingAddressCountry: null,
    },
    operationsInformation: {
        webAddress: null,
        phoneNumber: null,
        supportEmail: null,
        bPubliclyListed: null,
        ticker: null,
        exchanges: null,
        operationRegionIds: [],
        bLicenseRequired: null,
        additionalLicensingInfo: null,
        primaryRegulator: null,
        licenseNumber: null,
        financialInstitutionFormFileName: null,
        businessActivityIds: [],
        web3ChainsAndAddresses: null,
        sourceOfFundsIds: [],
        sourceOfFundsOther: null,
        activeBanks: null,
        yearlyTransactionsId: null,
        monthlyUsdValueId: null,
    },
    ownerInformation: {
        exemptionId: null,
        individualBeneficialOwners: [],
        businessBeneficialOwners: [],
        authorizedSigner: null,
    },
    terms: {
        bTaxAcknowledgement: false,
        bFatcaAcknowledgement: false,
        bConfirmTrueAndCorrect: false,
        bAcceptTerms: false,
    },
    documentsUpload: [
        {
            documentTypesID: null,
            fileName: '',
        },
    ],
};

export const generateBeneficialPersonalOwner = (number: number): BeneficialPersonalOwner => {
    return {
        number,
        firstName: '',
        lastName: '',
        title: '',
        dateOfBirth: '',
        nationality: '',
        email: '',
        phoneNumber: '',
        country: '',
        ssn: '',
        stateProvince: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        percentageSharesOwned: null,
        position: '',
        bControllingParty: null,
        proofOfAddressFilename: null,
    };
};

export const generateBeneficialBusinessOwner = (number: number): BeneficialBusinessOwner => {
    return {
        number,
        percentageSharesOwned: null,
        entityName: null,
        entityTypesID: null,
        entityTypesOther: null,
        registrationDate: null,
        registrationNumber: null,
        taxNumber: null,
        doingBusinessAs: null,
        addressStreet: null,
        addressNumber: null,
        addressPostCode: null,
        addressCity: null,
        addressState: null,
        addressCountry: null,
        operatingAddressStreet: null,
        operatingAddressNumber: null,
        operatingAddressPostCode: null,
        operatingAddressCity: null,
        operatingAddressState: null,
        operatingAddressCountry: null,
    };
};
