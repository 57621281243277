import { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { endpoints } from '../../../endpoints.config';
import instance from '../../../api';
import { CheckboxInput } from 'components/form/CheckboxInput';
import { ErrorM } from 'components/form/ErrorM';
import Button from 'components/button/Button';
import { BusinessStepsOrder, BusinessVerificationStage } from '../businessVerificationModels';
import { setVerificationStage } from 'reducers/verification';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { signOut } from 'reducers/auth';
import BackButton from '../helpers/BackButton';
import {
    Errors,
    formatValues,
    getStageFromFieldName,
    setResponseErrors,
    stagePrefix,
} from '../helpers/ExitContinueButtons';
import { useFormikContext } from 'formik';

interface Props {
    completedSubmit?: boolean;
}

export const submitForm = async (
    values: any,
    helpers: any,
    dispatch: Dispatch<any>,
    setSubmitErrorField: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>
) => {
    try {
        const response = await instance.post(
            endpoints.businessverificationmodule.submitForm,
            formatValues(values as any)
        );
        if (response.status === 200 && response.data && response.data.status === `1`) {
            if (
                response.data.details.listInnerErrors &&
                Array.isArray(response.data.details.listInnerErrors) &&
                response.data.details.listInnerErrors.length === 0
            ) {
                dispatch(
                    setVerificationStage({ currentStage: BusinessVerificationStage.Complete })
                );
            } else if (
                response.data.details.listInnerErrors &&
                Array.isArray(response.data.details.listInnerErrors) &&
                response.data.details.listInnerErrors.length > 0
            ) {
                //if errors check which stage and set step to the earliest in the process
                let errorsOnDifferentStage = response.data.details.listInnerErrors
                    .map((error: Errors) => getStageFromFieldName(error.fieldName))
                    .filter(
                        (stage: BusinessVerificationStage) =>
                            stage !== BusinessVerificationStage.Submit
                    );
                if (Array.isArray(errorsOnDifferentStage) && errorsOnDifferentStage.length > 0) {
                    for (const stage of BusinessStepsOrder) {
                        if (errorsOnDifferentStage.includes(stage)) {
                            setResponseErrors(
                                response.data.details.listInnerErrors,
                                helpers,
                                stage,
                                setSubmitErrorField,
                                setApiErrors,
                                values
                            );
                            dispatch(
                                setVerificationStage({
                                    currentStage: stage,
                                })
                            );
                            break;
                        }
                    }
                } else {
                    setResponseErrors(
                        response.data.details.listInnerErrors,
                        helpers,
                        BusinessVerificationStage.Submit,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                }
            }
        }
    } catch (error: any) {
        Toast.openToastMessage(
            'Error submitting form, please try again later.',
            ToastMessageReason.ERROR
        );
    }
};

const BusinessVerificationSubmit: React.FC<Props> = ({ completedSubmit }) => {
    const { isSubmitting, isValidating, values } = useFormikContext<any>();
    const [completed, setCompleted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (completedSubmit) {
            setCompleted(completedSubmit);
        }
    }, [completedSubmit]);

    let submitting = isSubmitting || isValidating;

    const infoLabel =
        'I do confirm that the information provided in the present Questionnaire is true and correct and I do undertake to forthwith notify in writing on any changes in the information provided. I am aware that all or part of the information provided in this Questionnaire may be transferred to the tax administrator or to any other institution authorized by the United States in accordance with international treaties or agreements, as well as the United States regarding automated exchange of information on financial accounts. ';
    const purposesLabel =
        'I do undertake to use the services of Ibanera LLC only for legitimate purposes and am aware that if it transpires that information provided in the present Questionnaire is incorrect or erroneous, law enforcement institutions of the United States may be informed about it and it may lead to unilateral termination of business relations.';
    return (
        <div className="Terms Submit">
            {completed ? (
                <>
                    <h1>Thank you</h1>
                    <p>
                        Thank you. You have now completed your verfication. You will receive a
                        notification once the application has been reviewed.
                    </p>
                    <Button
                        priority="primary"
                        variety="full"
                        type="button"
                        disabled={submitting}
                        onClick={() => dispatch(signOut())}
                    >
                        Sign out
                    </Button>
                </>
            ) : (
                <>
                    <BackButton
                        currentStage={BusinessVerificationStage.Submit}
                        disable={submitting}
                    />
                    <h1>You have completed your entity verification</h1>

                    <div style={{ marginBottom: 20 }}>
                        <div
                            className={`CheckBox ${values.confirmation.bConfirm ? 'Checked' : ''}`}
                        >
                            <CheckboxInput name="confirmation.bConfirm" label={infoLabel} />
                        </div>
                        <ErrorM name="confirmation.bConfirm" />
                    </div>
                    <div style={{ marginBottom: 30 }}>
                        <div
                            className={`CheckBox ${
                                values.confirmation.bAcceptTerms ? 'Checked' : ''
                            }`}
                        >
                            <CheckboxInput name="confirmation.bAcceptTerms" label={purposesLabel} />
                        </div>
                        <ErrorM name="confirmation.bAcceptTerms" />
                    </div>
                    <Button priority="primary" variety="full" type="submit" disabled={submitting}>
                        Submit application
                    </Button>
                </>
            )}
        </div>
    );
};

export default BusinessVerificationSubmit;
