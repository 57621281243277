import { Modal } from '../../../modal/Modal';
import { IndividualOwner, IndividualOwnerProps } from './IndividualOwner';

type Props = {
    visible: boolean;
    onCloseModal: () => void;
} & Omit<IndividualOwnerProps, 'onClose'>;

export const UpdateIndividualModal: React.FC<Props> = ({ visible, onCloseModal, ...rest }) => {
    if (!visible) return null;

    return (
        <Modal
            title="Beneficial Owner Information"
            className="UpdateIndividualModal"
            closeOnClickOutside={false}
            onCloseModal={onCloseModal}
        >
            <IndividualOwner {...rest} onClose={onCloseModal} />
        </Modal>
    );
};
