import { useEffect } from 'react';
import { PaymentFlowType } from './QuickCoinPage';

export type TCardPayment_New_WPG = {
    cardToken: string;
    bSaveCard?: boolean;
    fraudSightId: string | undefined;
};
export const initialValues_New_WPG = {
    cardToken: '',
    bSaveCard: false,
};
export const WpgIframe = ({ iframeUrl }: { iframeUrl: string }) => {
    return (
        <>
            {iframeUrl ? (
                <div className="IframeContainer QuickCoin">
                    <iframe title="Payment form" src={iframeUrl} id="iframe" height={840}></iframe>
                </div>
            ) : (
                <div className="IframePlaceholder">preparing payment form...</div>
            )}
        </>
    );
};

export const WpgSdkIframe = ({
    iframeUrl,
    onSuccess,
    onFailure,
}: {
    iframeUrl: string;
    onSuccess(): void;
    onFailure(): void;
}) => {
    useEffect(() => {
        if (!iframeUrl) return;
        const options = {
            iframeHelperURL: window.location.origin + '/wpghelper.html',
            url: iframeUrl,
            type: 'iframe',
            inject: 'immediate',
            target: 'wpgTargetDiv',
            accessibility: true,
            language: 'en',
            disableScrolling: true,
            flowCallback: (result: any) => {},
            resultCallback: (res: any) => {
                if (res.order.status === 'success' || res.order.status === 'pending') onSuccess();
                else onFailure();
            },
        };
        var libraryObject: any = new (window as any).WPCL.Library();
        libraryObject?.setup(options);
        return () => libraryObject.destroy();
    }, [iframeUrl, onSuccess, onFailure]);
    return <div id="wpgTargetDiv"></div>;
};

export const useWpgSdk = (paymentFlow: PaymentFlowType) => {
    useEffect(() => {
        if (paymentFlow !== PaymentFlowType.IFrame) return;
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('id', 'WpgSdk');
        script.setAttribute(
            'src',
            'https://payments.worldpay.com/resources/hpp/integrations/embedded/js/hpp-embedded-integration-library.js'
        );
        const stylesheet = document.createElement('link');
        stylesheet.setAttribute('rel', 'stylesheet');
        stylesheet.setAttribute(
            'href',
            'https://payments.worldpay.com/resources/hpp/integrations/embedded/css/hpp-embedded-integration-library.css'
        );

        document.body.appendChild(script); //head
        return () => {
            document.getElementById('WpgSdk')?.remove();
        };
    }, [paymentFlow]);
};
