import { Form, Formik, FormikHelpers } from 'formik';
import Button from '../button/Button';
import { Modal } from '../modal/Modal';
import * as Yup from 'yup';
import { WrappedFormSingleSelectField } from '../form/FormSingleSelectField';
import api, { ApiResponse, isAxiosErrorHandled } from '../../api';
import { endpoints } from '../../endpoints.config';
import { SelectOption } from '../form/Dropdown';
import { GeneralError } from '../GeneralError/GeneralError';
import styles from './TableEntryInfoPanel.module.scss';
import { FoldingCube } from 'better-react-spinkit';
import { useTheme } from '@emotion/react';
import FormTextField from '../form/FormTextField';
import { Toast } from '../../helpers/toast';
import { processErrors } from '../../helpers/categoryHelpers/processErrors';
import { useQueryFetch } from '../../helpers/useQueryFetch';
import { useState } from 'react';

type Props = {
    showModal: boolean;
    closeModal: () => void;
    onSuccess: () => void;
    transactionId: number;
};
type FormState = { purposeId: string; purposeOther: string | null };

export const ReverseTransferModal: React.FC<Props> = ({
    transactionId,
    showModal,
    closeModal,
    onSuccess,
}) => {
    const { data, error, loading, refetch } = useQueryFetch<{ purposes: SelectOption[] }>(
        endpoints.accounts.reverseInboundTransfer,
        [endpoints.accounts.reverseInboundTransfer],
        undefined,
        { staleTime: 1000 * 60 }
    );
    const { colors } = useTheme();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    if (!showModal) return null;

    const otherId = (data?.details.purposes ?? []).find(
        (item) => item.label.toLowerCase() === 'other'
    )?.value;

    const validationSchema = Yup.object().shape({
        purposeId: Yup.string().required('Required').nullable(),
        purposeOther: Yup.string()
            .when('purposeId', {
                is: otherId,
                then: (schema) => schema.required('Required'),
            })
            .nullable(),
    });

    const onChange = (value: string, setFieldValue: (name: string, value: any) => void) => {
        setFieldValue('purposeId', value);
        if (value !== otherId) setFieldValue('purposeOther', null);
    };
    const handleSubmit = async (values: FormState, helpers: FormikHelpers<FormState>) => {
        try {
            const res = await api.post<ApiResponse>(endpoints.accounts.reverseInboundTransfer, {
                transactionId,
                ...values,
            });
            if (res.data.status === '1') {
                Toast.openSuccessToast('Transaction reversed');
                onSuccess();
            } else throw new Error();
        } catch (error) {
            const defaultMsg = 'Failed to reverse transaction';
            if (isAxiosErrorHandled(error)) {
                if (error.response.data.errors.find((err) => err.fieldName === 'TransactionId')) {
                    Toast.openErrorToast(defaultMsg);
                } else {
                    const errorsObj = processErrors(error.response.data.errors);
                    helpers.setErrors(errorsObj);
                }
            } else {
                Toast.openErrorToast(defaultMsg);
            }
        }
    };
    return (
        <Formik
            initialValues={{ purposeId: '', purposeOther: null } as FormState}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <Modal
                        title="Reverse transfer"
                        className={`ReverseTransferModal ReverseTransferModal--${
                            menuIsOpen ? 'Large' : 'Normal'
                        }`}
                        onCloseModal={isSubmitting ? () => {} : closeModal}
                        customButtons={
                            <div className="ModalNavigation">
                                <Button
                                    disabled={isSubmitting}
                                    priority="secondary"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    priority="primary"
                                    type="submit"
                                    disabled={isSubmitting || (!data && !!error)}
                                >
                                    Reverse
                                </Button>
                            </div>
                        }
                    >
                        {!data && loading && (
                            <div className={styles.Loading}>
                                <FoldingCube color={colors.first} size={80} />
                            </div>
                        )}
                        {data && (
                            <>
                                <WrappedFormSingleSelectField
                                    fieldName="purposeId"
                                    label="Reason"
                                    onChange={onChange}
                                    options={data.details.purposes ?? []}
                                    dropdownProps={{
                                        maxMenuHeight: 150,
                                        onMenuOpen: () => setMenuIsOpen(true),
                                        onMenuClose: () => setMenuIsOpen(false),
                                    }}
                                />
                                {values.purposeId && values.purposeId === otherId && (
                                    <FormTextField label="Other reason" field="purposeOther" />
                                )}
                            </>
                        )}
                        {!data && error && !loading && (
                            <div className="ErrorCase">
                                <GeneralError message="Failed to load options" />
                                <Button
                                    className="mt-4"
                                    type="button"
                                    priority="secondary"
                                    onClick={() => refetch()}
                                >
                                    Retry
                                </Button>
                            </div>
                        )}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
