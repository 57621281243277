import React from "react";
import { useLoadScopes } from "./auth";
import { getPermissions } from "./getPageTitle";
import { WindowLocation } from "@reach/router";

export function useGetPermissions(
  requestedPermissions: string[],
  location?: WindowLocation | string
) {
  const scopes = useLoadScopes();
  let pathname: string;
  if (typeof location === "string") {
    pathname = location;
  } else {
    pathname = location?.pathname ?? "";
  }

  const permissions = React.useMemo(() => {
    return getPermissions(requestedPermissions, pathname.slice(1), scopes);
  }, [requestedPermissions, pathname, scopes]);

  return permissions;
}
