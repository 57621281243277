/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { Theme, useTheme } from '@emotion/react';
import React from 'react';
import { OptionTypeBase, StylesConfig } from 'react-select';
import Select from 'react-select';

type SelectProps = React.ComponentProps<typeof Select>;
type IsMulti = false;

export const CurrencySelectDropdown = (props: SelectProps | { onChange: (e: any) => void }) => {
    const theme = useTheme();
    const themedStyles = getThemedStyles(theme);

    return <Select styles={themedStyles} {...props} />;
};

const getThemedStyles = (theme: Theme): StylesConfig<OptionTypeBase, false> => ({
    container: (provided, state) => ({
        ...provided,
        marginRight: 60,
        minWidth: 50,
    }),
    control: (provided, state) => ({
        ...provided,
        border: 0,
        borderRadius: 0,
        borderBottom: `2px solid ${theme.colors.second}`,
        boxShadow: 'none',
        width: 'fit-content',
        minHeight: 30,
        cursor: 'pointer',
        '&:hover,&:focus': {
            border: 0,
            borderRadius: 0,
            borderBottom: `2px solid ${theme.colors.second}`,
            boxShadow: 'none',
        },
    }),
    indicatorSeparator: (provided, state) => ({ width: 0 }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        height: 15,
        width: 15,
        color: theme.colors.second,
        padding: 0,
        '&:hover,&:focus': { color: theme.colors.second },
        svg: { height: 15, width: 15 },
    }),
    valueContainer: (provided, state) => ({
        paddingLeft: 0,
        paddingRight: 0,
        width: 35,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: theme.colors.second,
        fontWeight: 700,
        fontSize: '12px',
        display: 'inline-block',
        textOverflow: 'clip',
    }),
    menu: (provided, state) => ({
        ...provided,
        width: 'fit-content',
        right: 0,
        textAlign: 'center',
        borderRadius: 0,
        border: `1px solid ${theme.colors.first}`,
        boxShadow: 'none',
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : theme.colors.second,
        fontWeight: 700,
        '&:hover': {
            backgroundColor: state.isSelected ? theme.colors.first : theme.colors.secondBackground,
        },
        backgroundColor: state.isSelected ? theme.colors.first : '#fff',
    }),
});
