import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import { AppPath, DashboardPath } from 'appConstants';
import Page from 'components/page/Page';
import { RedirectState } from 'pages/signIn/AutoLogin';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccountSwitchInProgress } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';

export const CheckingDetails: React.FC<RouteComponentProps> = () => {
    const { userInfo } = useSelector(selectComponentResources);
    const cultureCode = useSelector(selectCultureCode);
    const accountSwitchInProgress = useSelector(selectAccountSwitchInProgress);
    const { state } = useLocation();

    if (userInfo && userInfo.accountType && !accountSwitchInProgress) {
        const accountTypePathItem =
            userInfo.accountType === 'Personal' ? AppPath.PERSONAL : AppPath.BUSINESS;
        if (isNavigationRedirectState(state)) {
            navigate(`/${cultureCode}${accountTypePathItem}${state.redirectUrl}`);
        } else {
            navigate(
                `/${cultureCode}${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`
            );
        }
    }

    // We return an empty page component here so that the <Navigation /> component is
    // rendered, which uses the useGetComponentResources hook, hence if the user manually
    // refreshes on this page, the component resources will be refetched.
    return <Page />;
};

const isNavigationRedirectState = (state: unknown): state is RedirectState => {
    return !!(state as any)?.redirectUrl;
};
