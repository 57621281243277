import { ErrorM } from 'components/form/ErrorM';
import { Field, useField } from 'formik';

interface Props {
    fieldName: string;
    label?: string;
    options: { label: string; value: number }[];
    asterisk?: boolean;
}
export const MultiSelectChecklist: React.FC<Props> = ({ fieldName, label, options, asterisk }) => {
    const [{ value: selected }, , { setValue }] = useField<number[] | null>(fieldName);
    const handleChange = (value: number) => {
        const currentValues = selected ?? [];
        const valueIsChecked = currentValues.includes(value);

        if (valueIsChecked) {
            setValue(currentValues.filter((val) => val !== value));
        } else {
            setValue([...currentValues, value]);
        }
    };

    return (
        <div className="FormBox">
            {label && (
                <div className="FormLabel">
                    <label>
                        {label}
                        {asterisk && <span>*</span>}
                    </label>
                </div>
            )}
            <div className="FormField" role="group">
                {options?.map((option, index) => (
                    <label key={index} className="custom-checkbox">
                        <Field
                            className="CheckboxInput"
                            type="checkbox"
                            name={fieldName}
                            onChange={() => handleChange(option.value)}
                            checked={selected?.includes(option.value)}
                        />
                        {option.label}
                        <span className="checkmark"></span>
                    </label>
                ))}
            </div>
            <ErrorM name={fieldName} />
        </div>
    );
};
