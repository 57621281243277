import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { empty, from } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { addSymbolArray, addSymbols } from './currencySymbols';
import { Store } from './rootReducer';

export type AssetPairPrice = {
    assetCode: string;
    pairedAssetCode: string;
    buyPrice: number;
    sellPrice: number;
    priceVariability: number;
    marketHighLow: string;
    name: string;
    changeDirection: 'Up' | 'Down' | null;
};
export enum AvailableOnOptions {
    Crypto = 'Crypto',
    QuickCoin = 'QuickCoin',
}
export type AssetPairInitialInfo = {
    assetCode: string;
    pairedAssetCode: string;
    buyPrice: number;
    sellPrice: number;
    availableOn: AvailableOnOptions[];
    assetName: string;
    assetSymbol: string;
    pairedAssetSymbol: string;
    minAmount: number | null;
    maxAmount: number | null;
    minPrice: number | null;
    maxPrice: number | null;
    maxDecimalPrecision: number | null;
    baseAsset: string | null;
};
export type AssetPairAllInfo = AssetPairInitialInfo & AssetPairPrice;
export type PriceInfo = Omit<AssetPairAllInfo, 'assetCode' | 'pairedAssetCode'>;
export type AssetPairsDetails = {
    [key: string]: PriceInfo;
};

type CryptoPricesState = AssetPairsDetails;

const initialState: CryptoPricesState = {};

enum CryptoPriceActionType {
    GET_INITIAL_PRICES = 'app/cryptoPrices/GET_INITIAL_PRICES',
    SET_PRICES = 'app/cryptoPrices/SET_PRICES',
    UPDATE_PRICES = 'app/cryptoPrices/UPDATE_PRICES',
}

type GetInitialPricesAction = {
    type: CryptoPriceActionType.GET_INITIAL_PRICES;
};

type SetPriceAction = {
    type: CryptoPriceActionType.SET_PRICES;
    payload: AssetPairAllInfo[];
};
type UpdatePricesAction = {
    type: CryptoPriceActionType.UPDATE_PRICES;
    payload: AssetPairPrice[];
};

type CryptoPriceAction = GetInitialPricesAction | SetPriceAction | UpdatePricesAction;

const Reducer = (state = initialState, action: CryptoPriceAction): CryptoPricesState => {
    switch (action.type) {
        case CryptoPriceActionType.SET_PRICES: {
            const stateCopy = { ...state };
            action.payload.forEach((assetPair) => {
                const { assetCode, pairedAssetCode, ...others } = assetPair;
                stateCopy[`${assetPair.assetCode}/${assetPair.pairedAssetCode}`] = others;
            });
            return stateCopy;
        }
        case CryptoPriceActionType.UPDATE_PRICES: {
            const stateCopy = { ...state };
            action.payload.forEach((assetPair) => {
                const { assetCode, pairedAssetCode, ...others } = assetPair;
                const key = `${assetPair.assetCode}/${assetPair.pairedAssetCode}`;
                stateCopy[key] = {
                    ...stateCopy[key],
                    ...others,
                    changeDirection:
                        stateCopy[key]?.buyPrice && others?.buyPrice
                            ? calculateChangeDirection(stateCopy[key].buyPrice, others.buyPrice)
                            : null,
                };
            });
            return stateCopy;
        }
        default:
            return state;
    }
};

export default Reducer;

//Epics
const getInitialPricesEpic = (
    action$: ActionsObservable<GetInitialPricesAction>,
    state$: StateObservable<Store>
) =>
    action$.pipe(
        ofType(CryptoPriceActionType.GET_INITIAL_PRICES),
        switchMap(() => {
            return from(
                instance.get<ApiResponse<AssetPairInitialInfo[]>>(
                    endpoints.pricesModule.getAssetPrices
                )
            ).pipe(
                mergeMap((response) => [
                    setPrices(
                        response.data.details.map((price) => {
                            const { assetName: name, ...rest } = price;
                            return {
                                ...price,
                                priceVariability: 0,
                                marketHighLow: `${price.buyPrice}-${price.buyPrice}`,
                                name,
                                changeDirection: null,
                            };
                        })
                    ),
                    addSymbols(
                        response.data.details.reduce(
                            (prev, price) => ({
                                ...prev,
                                [price.assetCode]: price.assetSymbol,
                                [price.pairedAssetCode]: price.pairedAssetSymbol,
                            }),
                            {}
                        )
                    ),
                ]),
                catchError((err) => {
                    if (err.isAxiosError && err.response?.status === 403) return empty(); // don't show when we get a 403 since that means the API thinks we don't need prices
                    Toast.openToastMessage(
                        'Error fetching crypto prices',
                        ToastMessageReason.ERROR
                    );
                    return empty();
                })
            );
        })
    );

export const cryptoPricesEpic = combineEpics(getInitialPricesEpic);

// Action creators
export const setPrices = (price: AssetPairAllInfo[]): SetPriceAction => ({
    type: CryptoPriceActionType.SET_PRICES,
    payload: price,
});

export const updatePrices = (price: AssetPairPrice[]): UpdatePricesAction => ({
    type: CryptoPriceActionType.UPDATE_PRICES,
    payload: price,
});

export const getInitialCryptoPrices = (): GetInitialPricesAction => ({
    type: CryptoPriceActionType.GET_INITIAL_PRICES,
});

// Selectors

export const selectAllCryptoPrices = (store: Store) => store.cryptoPrices;
export const selectCryptoPrice = (assetPair: string | null) => (store: Store) => {
    if (assetPair) {
        return store.cryptoPrices[`${assetPair}`] ?? null;
    }

    return null;
};
export const selectCryptoPricePair =
    (baseAsset: string | null, exchangeAsset: string | null) => (store: Store) => {
        if (!baseAsset || !exchangeAsset) return null;
        const price =
            store.cryptoPrices[`${exchangeAsset}/${baseAsset}`] ??
            invertPrice(store.cryptoPrices[`${baseAsset}/${exchangeAsset}`]) ??
            null;
        return price;
    };
const invertPrice = (price?: PriceInfo): PriceInfo | null => {
    if (!price) return null;
    return {
        assetName: '',
        name: '',
        baseAsset: null,
        buyPrice: price.sellPrice / 1,
        sellPrice: price.buyPrice / 1,
        availableOn: price.availableOn,
        assetSymbol: price.pairedAssetSymbol,
        pairedAssetSymbol: price.assetSymbol,
        minAmount: price.minPrice,
        maxAmount: price.maxPrice,
        minPrice: price.minAmount,
        maxPrice: price.maxAmount,
        maxDecimalPrecision: null,
        priceVariability: price.priceVariability,
        marketHighLow: '',
        changeDirection: invertDirection(price.changeDirection),
    };
};

const invertDirection = (direction: PriceInfo['changeDirection']): PriceInfo['changeDirection'] =>
    direction === 'Down' ? 'Up' : direction === 'Up' ? 'Down' : null;

const calculateChangeDirection = (
    initialValue: number,
    newValue: number
): PriceInfo['changeDirection'] =>
    initialValue > newValue ? 'Down' : initialValue < newValue ? 'Up' : null;
