import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { useFormikContext } from 'formik';
import React from 'react';
import { MutableRefObject, useEffect, useState } from 'react';

interface Props {
    index: number;
    arrayHelpers: any;
    disableRemove: boolean;
    values: any;
    idRef: MutableRefObject<number>;
    disable: boolean;
}
const BusinessPartner: React.FC<Props> = ({
    index,
    arrayHelpers,
    disableRemove,
    values,
    idRef,
    disable,
}) => {
    const { setFieldValue } = useFormikContext<any>();
    const [key, setKey] = useState<number | null>(null);

    useEffect(() => {
        setKey(idRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFieldValue(`corporate.mainBusinessPartners.${index}.number`, index + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.corporate.mainBusinessPartners.length]);

    return (
        <React.Fragment key={`Corporate-BusinessPartner-${key}`}>
            <div>
                <h3 className="Title">Business Partner {index + 1} </h3>
            </div>
            <div className="FormBox">
                <div className="FormSideBySide">
                    <FormTextField
                        field={`corporate.mainBusinessPartners.${index}.name`}
                        label={'Business Partner Name'}
                        required={true}
                        maxLength={200}
                        sideBySide={true}
                        asterisk={true}
                    />
                    <FormTextField
                        field={`corporate.mainBusinessPartners.${index}.companyRegistrationNumber`}
                        label={'Company Registration Number'}
                        required={true}
                        maxLength={200}
                        sideBySide={true}
                        asterisk={true}
                    />
                </div>
                <FormTextField
                    field={`corporate.mainBusinessPartners.${index}.website`}
                    label={'Business Partner Website'}
                    required={false}
                    maxLength={200}
                    sideBySide={true}
                    // asterisk={true}
                />

                {!disableRemove && (
                    <Button
                        type="button"
                        priority="primary"
                        variety="full"
                        onClick={() => arrayHelpers.remove(index)}
                        disabled={disable}
                    >
                        Remove Business Partner
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

export default BusinessPartner;
