import React, { useContext } from 'react';
import { Crypto } from './Crypto';
import { ExchangeCryptoContext } from './ExchangePage';
import { Cryptos } from './Cryptos';

export const Trade: React.FC = () => {
    const { selectedCurrencyInfo } = useContext(ExchangeCryptoContext);

    return <>{selectedCurrencyInfo ? <Crypto /> : <Cryptos />}</>;
};
