import * as Yup from 'yup';
import { BusinessVerificationStage } from './businessVerificationModels';
import { AnticipatedActivity, OtherAccount } from './CorporateApp/corporateAppModel';

export interface FormValues {
    businessVerificationStep: BusinessVerificationStage;
    significantParties: {
        number: null | number;
        firstName: string;
        lastName: string;
        title: string;
        dateOfBirth: string;
        nationality: string;
        email: string;
        phoneNumber: string;
        country: string;
        ssn: string;
        stateProvince: string;
        city: string;
        addressLine1: string;
        addressLine2: string;
        postcode: string;
        percentageSharesOwned: null | number;
        position: string;
        bControllingParty: null | boolean;
        proofOfAddressFilename: string;
    }[];
    corporate: {
        operatingAddress: {
            bUseDifferentAddress: boolean;
            addressLine1: string;
            postcode: string;
            stateProvince: string;
            city: string;
            country: string;
        };
        representative: {
            bExposed: boolean;
            firstName: string;
            lastName: string;
            ssn: string;
            dateOfBirth: string;
            addressLine1: string;
            postcode: string;
            city: string;
            stateProvince: string;
            country: string;
            phoneNumber: string;
            email: string;
            position: string;
            personalIdDocumentTypesID: null | number;
            personalIdDocument: {
                idNumber: string;
                dateOfIssue: string;
                placeOfIssue: string;
                expiryDate: string;
            };
            politicallyExposedPersonTypesID: null | number;
            politicallyExposedPerson: {
                firstName: string;
                lastName: string;
                stateInstitution: string;
                position: string;
            };
        };
        mainBusinessPartners: {
            number: number;
            name: string;
            companyRegistrationNumber: string;
            website: string;
        }[];
        mainActivity: {
            typesID: number | null;
            description: string;
            bFundsReceivedUSA: null;
            bFundsSentUSA: null;
            bPassiveIncomeExceedsFiftyPercent: null;
        };
        anticipatedActivity: AnticipatedActivity;
        otherAccounts: OtherAccount[];
        authorizedCapital: {
            authorizedCapital: null | number;
            sourceOfFunds: string;
            proofOfSourceOfFundsFileName: string;
            previousYearTurnover: null | number;
            plannedTurnover: null | number;
            bankAccountSignatories: string;
            sourceFundsInformationTypesID: null | number;
            externalInvestment: {
                companyName: string;
                companyAddress: string;
                amount: null | number;
                date: string;
            };
            internalInvestments: {
                number: number;
                employeeName: string;
                position: string;
                amount: null | string;
                date: string;
                sourceOfWealth: string;
            }[];
            companySale: {
                companyName: string;
                country: string;
                companyAddress: string;
                natureOfBusiness: string;
                amount: null | number;
                date: string;
            };
            inheritance: {
                nameRelationDonor: string;
                amount: null | number;
                date: string;
            };
            propertySale: {
                address: string;
                date: string;
                amount: null | number;
            };
            loan: {
                amount: null | number;
                date: string;
                lenderNameAddress: string;
                purpose: string;
            };
            other: {
                payee: string;
                date: string;
                natureOfIncome: string;
                amount: null | number;
            };
            supportingDocuments: null | { number: number; fileName: string; id: number }[];
        };
    };
    documentsUpload: {
        documentTypesID: number | null;
        fileName: string;
        bAlwaysRequired?: boolean;
    }[];
    confirmation: {
        bConfirm: boolean;
        bAcceptTerms: boolean;
    };
}

export const initialValue: FormValues = {
    businessVerificationStep: BusinessVerificationStage.SignificantParties,
    significantParties: [
        {
            number: null,
            firstName: '',
            lastName: '',
            title: '',
            dateOfBirth: '',
            nationality: '',
            email: '',
            phoneNumber: '',
            country: '',
            ssn: '',
            stateProvince: '',
            city: '',
            addressLine1: '',
            addressLine2: '',
            postcode: '',
            percentageSharesOwned: null,
            position: '',
            bControllingParty: null,
            proofOfAddressFilename: '',
        },
    ],
    corporate: {
        operatingAddress: {
            bUseDifferentAddress: false,
            addressLine1: '',
            postcode: '',
            stateProvince: '',
            city: '',
            country: '',
        },
        representative: {
            firstName: '',
            lastName: '',
            ssn: '',
            dateOfBirth: '',
            addressLine1: '',
            postcode: '',
            city: '',
            stateProvince: '',
            country: '',
            phoneNumber: '',
            email: '',
            position: '',
            personalIdDocumentTypesID: null,
            personalIdDocument: {
                idNumber: '',
                dateOfIssue: '',
                placeOfIssue: '',
                expiryDate: '',
            },
            bExposed: false,
            politicallyExposedPersonTypesID: null,
            politicallyExposedPerson: {
                firstName: '',
                lastName: '',
                stateInstitution: '',
                position: '',
            },
        },
        mainBusinessPartners: [
            {
                number: 1,
                name: '',
                companyRegistrationNumber: '',
                website: '',
            },
        ],
        mainActivity: {
            typesID: null,
            description: '',
            bFundsReceivedUSA: null,
            bFundsSentUSA: null,
            bPassiveIncomeExceedsFiftyPercent: null,
        },
        anticipatedActivity: {
            monthlyTurnOver: null,
            next12MonthsActivities: null,
            sellingCryptoMonthlyVolume: null,
            sellingCryptoAverageAmount: null,
            sellingCryptoTotalAmountPerMonth: null,
            purchaseCryptoMonthlyVolume: null,
            purchaseCryptoAverageAmount: null,
            purchaseCryptoTotalAmountPerMonth: null,
            totalMonthlyVolume: null,
            totalAverageAmount: null,
            btcTradingPercentage: null,
            ethTradingPercentage: null,
            usdcTradingPercentage: null,
            otherCoinsTradingPercentage: null,
        },
        otherAccounts: [],
        authorizedCapital: {
            authorizedCapital: null,
            sourceOfFunds: '',
            proofOfSourceOfFundsFileName: '',
            previousYearTurnover: null,
            plannedTurnover: null,
            bankAccountSignatories: '',
            sourceFundsInformationTypesID: null,
            externalInvestment: {
                companyName: '',
                companyAddress: '',
                amount: null,
                date: '',
            },

            internalInvestments: [
                {
                    number: 1,
                    employeeName: '',
                    position: '',
                    amount: null,
                    date: '',
                    sourceOfWealth: '',
                },
            ],
            companySale: {
                companyName: '',
                country: '',
                companyAddress: '',
                natureOfBusiness: '',
                amount: null,
                date: '',
            },
            inheritance: {
                nameRelationDonor: '',
                amount: null,
                date: '',
            },
            propertySale: {
                address: '',
                date: '',
                amount: null,
            },
            loan: {
                amount: null,
                date: '',
                lenderNameAddress: '',
                purpose: '',
            },
            other: {
                payee: '',
                date: '',
                natureOfIncome: '',
                amount: null,
            },
            supportingDocuments: null,
        },
    },
    documentsUpload: [
        {
            documentTypesID: null,
            fileName: '',
        },
    ],
    confirmation: {
        bConfirm: false,
        bAcceptTerms: false,
    },
};

export const validationSchemaConfirmation = Yup.object().shape({
    confirmation: Yup.object().shape({
        bConfirm: Yup.bool().oneOf([true], 'Please confirm'),
        bAcceptTerms: Yup.bool().oneOf([true], 'Please accept'),
    }),
});

export const validationSchemaTerms = Yup.object().shape({
    bIRSTaxFilingAgreement: Yup.bool().oneOf([true], 'Please confirm IRS compliance'),
    bFATCAAgreement: Yup.bool().oneOf([true], 'Please confirm compliance with FATCA'),
});

export const documentUploadValidationSchema = Yup.object().shape({
    documentsUpload: Yup.array().of(
        Yup.object().shape({
            documentTypesID: Yup.number().nullable().required(),
            fileName: Yup.string()
                .when('bAlwaysRequired', {
                    is: (bAlwaysRequired: boolean) => bAlwaysRequired,
                    then: Yup.string().nullable().required('Please provide the requested document'),
                })
                .nullable(),
            bAlwaysRequired: Yup.boolean().nullable(),
        })
    ),
});
