import Button from 'components/button/Button';
import React, { Dispatch } from 'react';
import { setVerificationStage } from 'reducers/verification';
import {
    BusinessVerificationStage,
    getEquivalentBackendStage,
    getNextStage,
    getStageFromBackend,
} from '../businessVerificationModels';
import { FormikHelpers, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { endpoints } from 'endpoints.config';
import instance from 'api';
import { signOut } from 'reducers/auth';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { DocumentFieldValue } from '../components/BusinessVerificationDocuments';
import { FormValues } from '../businessVerificationSchema';

interface Props {
    disabled: boolean;
}
export interface Errors {
    errorType: string;
    fieldName: string;
    messageCode: string;
}
export type processedErrors = { fieldName: string; message: string }[];

export const parseFieldName = (name: string, stagePrefix: StagePrefix) => {
    const words = name.split('_').map((word) => word.charAt(0).toLowerCase() + word.slice(1));
    if (words[0].toLowerCase() === stagePrefix?.toLowerCase()) {
        if (words.length === 1) {
            return words[0];
        } else if (words.length === 2) {
            return `${words[0]}.${words[1]}`;
        } else {
            let index = +words[2] - 1;
            return `${words[0]}.${index}.${words[1]}`;
        }
    } else if (stagePrefix.toLowerCase() === 'corporate') {
        if (words.length === 1) {
            return `corporate.${words[0]}`;
        } else if (words.length === 2) {
            return `corporate.${words[0]}.${words[1]}`;
        } else if (words.length === 3) {
            if (words[0] === 'mainBusinessPartners') {
                let index = +words[2] - 1;
                return `corporate.${words[0]}.${index}.${words[1]}`;
            } else {
                return `corporate.${words[0]}.${words[1]}.${words[2]}`;
            }
        } else if (words.length === 4) {
            let index = +words[3] - 1;
            return `corporate.${words[0]}.${words[1]}.${index}.${words[2]}`;
        }
    }
};

const parseDocumentUploadResponse = (error: Errors, values: any) => {
    const words = error.fieldName
        .split('_')
        .map((word) => word.charAt(0).toLowerCase() + word.slice(1));
    let errorMessage = '';
    if (words.length === 2) {
        if (words[1] === 'documentTypesID') {
            errorMessage =
                error.messageCode === 'Missing'
                    ? 'Document type missing please try again'
                    : 'Only one file per document type is allowed';
        } else {
            errorMessage = 'File names must be distinct';
        }
        return [words[0], errorMessage];
    }
    if (words.length === 3) {
        let index = values.documentsUpload
            .map((upload: DocumentFieldValue) => upload.documentTypesID)
            .indexOf(+words[2]);
        if (index >= 0) {
            errorMessage = 'Cannot find file please reupload';
            return [`${words[0]}.${index}`, errorMessage];
        }
    }

    return [null, null];
};

export const errorsOnCurrentStage = (errors: Errors[], stage: BusinessVerificationStage) =>
    errors.map((error) => error.fieldName.split('_')[0]).includes(stage);

export const setResponseErrors = (
    errors: Errors[],
    helpers: any,
    stage: BusinessVerificationStage,
    setSubmitErrorField: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>,
    values?: any
) => {
    const apiErrors: processedErrors = [];
    errors.forEach((error: any, index: number) => {
        if (stage === BusinessVerificationStage.Documents && values) {
            let [fieldName, errorMessage] = parseDocumentUploadResponse(error, values);
            if (fieldName && errorMessage) {
                if (index === 0) setSubmitErrorField(fieldName);
                apiErrors.push({ fieldName: fieldName, message: errorMessage });
                helpers.setFieldError(`${fieldName}`, errorMessage);
            }
        } else {
            let fieldName = parseFieldName(error.fieldName, stagePrefix[stage]!);
            if (fieldName) {
                if (index === 0) setSubmitErrorField(fieldName); //set first error field to scroll to
                if (error.messageCode === 'Invalid') {
                    apiErrors.push({
                        fieldName: fieldName,
                        message: 'Please provide valid information',
                    });
                    helpers.setFieldError(`${fieldName}`, 'Please provide valid information');
                } else {
                    apiErrors.push({ fieldName: fieldName, message: '*Required' });
                    helpers.setFieldError(`${fieldName}`, '*Required');
                }
            }
        }
    });
    if (apiErrors.length > 0) {
        setApiErrors([...apiErrors]);
    }
};
type StagePrefix = 'SignificantParties' | 'Corporate' | 'DocumentsUpload' | 'Submit';
export const stagePrefix: { [k in BusinessVerificationStage]: StagePrefix | null } = {
    [BusinessVerificationStage.SignificantParties]: 'SignificantParties',
    [BusinessVerificationStage.OperatingAddress]: 'Corporate',
    [BusinessVerificationStage.CompanyRep]: 'Corporate',
    [BusinessVerificationStage.MainBusinessPartners]: 'Corporate',
    [BusinessVerificationStage.MainActivity]: 'Corporate',
    [BusinessVerificationStage.Funding]: 'Corporate',
    [BusinessVerificationStage.OtherBankAccounts]: 'Corporate',
    [BusinessVerificationStage.AnticipatedActivity]: 'Corporate',
    [BusinessVerificationStage.Documents]: 'DocumentsUpload',
    [BusinessVerificationStage.Submit]: 'Submit',
    [BusinessVerificationStage.Verified]: null, //not used, just here to make TS happy
    [BusinessVerificationStage.Terms]: null,
    [BusinessVerificationStage.Complete]: null,
} as const;

export const getStageFromFieldName = (fieldName: string): BusinessVerificationStage => {
    switch (fieldName.split('_')[0]) {
        case 'DocumentsUpload':
            return BusinessVerificationStage.Documents;
        case 'Submit':
            return BusinessVerificationStage.Submit;
        case 'OperatingAddress':
            return BusinessVerificationStage.OperatingAddress;
        case 'Representative':
            return BusinessVerificationStage.CompanyRep;
        case 'MainBusinessPartners':
            return BusinessVerificationStage.MainBusinessPartners;
        case 'MainActivity':
            return BusinessVerificationStage.MainActivity;
        case 'AuthorizedCapital':
            return BusinessVerificationStage.Funding;
        case 'OtherAccounts':
            return BusinessVerificationStage.OtherBankAccounts;
        case 'AnticipatedActivity':
            return BusinessVerificationStage.AnticipatedActivity;
        case 'SignificantParties':
        default:
            return BusinessVerificationStage.SignificantParties;
    }
};

export async function saveContinue<T>(
    values: T,
    helpers: FormikHelpers<T>,
    dispatch: Dispatch<any>,
    stage: BusinessVerificationStage | null,
    setSubmitError: React.SetStateAction<any>,
    setSubmitErrorField: React.SetStateAction<any>,
    setSubmitValues: React.SetStateAction<any>,
    setApiErrors: React.SetStateAction<any>
) {
    try {
        if (stage) {
            const response = await instance.post(
                endpoints.businessverificationmodule.saveFormData,
                {
                    ...formatValues(values as any as FormValues),
                    businessVerificationStep: getEquivalentBackendStage(getNextStage(stage)),
                }
            );
            if (response.status === 200 && response.data && response.data.status === `1`) {
                if (
                    response.data.details.listInnerErrors &&
                    Array.isArray(response.data.details.listInnerErrors) &&
                    response.data.details.listInnerErrors.length === 0
                ) {
                    //if no errors proceed
                    helpers.setFieldValue('businessVerificationStep', getNextStage(stage));
                    dispatch(
                        setVerificationStage({
                            currentStage: getNextStage(stage),
                        })
                    );
                    helpers.setTouched({});
                    setSubmitError(false);
                    setApiErrors([]);
                } else if (
                    response.data.details.listInnerErrors &&
                    Array.isArray(response.data.details.listInnerErrors) &&
                    response.data.details.listInnerErrors.length > 0
                ) {
                    setSubmitError(true);
                    helpers.setFieldValue('businessVerificationStep', stage);
                    setSubmitValues({ ...values });
                    setResponseErrors(
                        response.data.details.listInnerErrors,
                        helpers,
                        stage,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                    document
                        .querySelector('.BusinessVerificationModalBg')
                        ?.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                const { errors } = response.data;
                if (errors.length > 0) {
                    setSubmitValues({ ...values });
                    setResponseErrors(
                        errors,
                        helpers,
                        stage,
                        setSubmitErrorField,
                        setApiErrors,
                        values
                    );
                }
            }
        }
    } catch (error: any) {
        Toast.openToastMessage('Error saving form, please try again.', ToastMessageReason.ERROR);
    }
    // }
}
/* 
Formats the values we submit from the form.
Returns a new object without mutating the existing object
Notably we need to remove any documents in the documentsUpload section that have not been uploaded(filename==null)
*/
export const formatValues = (_values: FormValues) => {
    const values = { ..._values };
    if (Array.isArray(values.documentsUpload) && values.documentsUpload.length > 1)
        values.documentsUpload = values.documentsUpload.filter((document) => document.fileName);
    return values;
};

const ExitContinueButton: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const { isSubmitting, isValidating, setFieldTouched, errors, values } =
        useFormikContext<FormValues>();
    let errorCount = Object.keys(errors);
    let disabled = isSubmitting || isValidating;
    const saveExit = async () => {
        try {
            const response = await instance.post(
                endpoints.businessverificationmodule.saveFormData,
                {
                    ...formatValues(values),
                    businessVerificationStep: getEquivalentBackendStage(
                        values.businessVerificationStep
                    ),
                }
            );
            if (response.data.status === '1') {
                dispatch(signOut());
            }
        } catch (error: any) {
            Toast.openToastMessage(
                'Error saving form, please try again.',
                ToastMessageReason.ERROR
            );
        }
    };

    return (
        <div className="ExitContinueContainer">
            <Button
                onClick={saveExit}
                priority="secondary"
                variety="full"
                type="button"
                className="SaveBtn"
            >
                Save & Exit
            </Button>
            <Button
                type="submit"
                disabled={disabled}
                priority="primary"
                variety="full"
                className="SaveBtn"
            >
                Save & Continue
            </Button>
        </div>
    );
};

export default ExitContinueButton;
