/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { OptionTypeBase, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styles from './SingleSelectWithSearch.module.scss';
import { SelectOption } from '../Dropdown';
import SearchIcon from 'assets/Cat-Entity/Icon_Form_Search_01.png';
import { NoticeProps } from 'react-select/src/components/Menu';

export interface FormSingleSelectProps {
    label?: string;
    value?: SelectOption;
    defaultOptions: SelectOption[];
    dropdownProps?: any;
    containerClass?: string;
    error?: string;
    onChange?: (opt: any) => void;
    onInputChange?: (str: string, meta: { action: string; prevInputValue: string }) => void;
    loadOptions: (inputValue: string, callback: any) => void;
    isOptionDisabled?: (opt: any) => boolean;
    useHtmlLabel?: boolean;
    optional?: boolean;
    noOptionsComponent?: (e: any) => JSX.Element;
}

export const SingleSelectWithSearch: React.FC<FormSingleSelectProps> = ({
    defaultOptions,
    value,
    dropdownProps,
    onChange = undefined,
    loadOptions,
    useHtmlLabel = false,
    label,
    containerClass = '',
    optional = false,
    isOptionDisabled,
    onInputChange = () => {},
    error,
    noOptionsComponent,
}) => {
    const [option, setOption] = useState<SelectOption>();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const ref: any = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!menuIsOpen) return;

        setMenuIsOpen(false);
    }, []);

    const hasError = !!error;

    const handleChange = (e: SelectOption) => {
        onChange ? onChange(e) : setOption(e);
    };

    const renderOption = (a: any) => {
        return (
            <div
                className={`CustomSelect__option`}
                dangerouslySetInnerHTML={{ __html: a.data.label }}
                {...a.innerProps}
            />
        );
    };
    const EmptyOptionList = (props: PropsWithChildren<NoticeProps<OptionTypeBase, false>>) => {
        return (
            <components.NoOptionsMessage {...props}>
                {noOptionsComponent && noOptionsComponent(props.selectProps.inputValue)}
            </components.NoOptionsMessage>
        );
    };

    const DropdownIndicator = () => (
        <img className={styles.SearchIcon} src={SearchIcon} alt="SearchIcon" />
    );
    return (
        <div
            ref={ref}
            className={`${styles.SingleSelectWithSearch} ${containerClass}  ${
                hasError ? styles.Error : ''
            }`}
        >
            {label && (
                <div className={`${styles.Label} FormLabel`}>
                    <label>
                        {label}
                        {optional ? <span className={styles.OptionalLabel}>- optional</span> : null}
                    </label>
                </div>
            )}
            <AsyncSelect
                className={`CustomSelect RoundedRect`}
                onMenuClose={() => setMenuIsOpen(false)}
                onMenuOpen={() => setMenuIsOpen(true)}
                menuIsOpen={menuIsOpen}
                components={
                    useHtmlLabel
                        ? {
                              SingleValue: renderOption,
                              Option: renderOption,
                              DropdownIndicator,
                          }
                        : {
                              DropdownIndicator,
                              ...(noOptionsComponent && { NoOptionsMessage: EmptyOptionList }),
                          }
                }
                classNamePrefix={'CustomSelect'}
                loadingMessage={() => 'Loading...'}
                defaultOptions={defaultOptions}
                value={value ?? option ?? false}
                onChange={handleChange}
                onInputChange={onInputChange}
                menuPlacement="auto"
                loadOptions={loadOptions}
                placeholder="Type your search here"
                isOptionDisabled={isOptionDisabled}
                {...dropdownProps}
            />
            {hasError ? <p className="ErrorLabel">{error}</p> : null}
        </div>
    );
};
