import Button from 'components/button/Button';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { ErrorM } from '../../form/ErrorM';
import { useVerificationContext } from '../helpers/verificationContext';
import {
    FormValues,
    generateBeneficialBusinessOwner,
    generateBeneficialPersonalOwner,
} from '../schema';
import { BusinessOwner } from './BusinessOwner';
import { IndividualOwner } from './IndividualOwner';
import BackButton from '../helpers/BackButton';

export const BeneficialOwners = () => {
    const { selectOptions } = useVerificationContext();
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const availableCountries = useMemo(
        () =>
            (selectOptions?.availableCountries ?? []).map((country) => ({
                value: country.isoCode,
                label: country.countryName,
                states: country.states
                    ? country.states.map((state) => ({
                          label: state.stateProvinceName,
                          value: state.ansiCode,
                      }))
                    : null,
            })),
        [selectOptions?.availableCountries]
    );

    const otherFundsSelected = useMemo(
        () =>
            values.operationsInformation.sourceOfFundsIds &&
            values.operationsInformation.sourceOfFundsIds.includes(
                selectOptions?.fundingSources?.find((type) => type.label === 'Other')?.value
            ),
        [values.operationsInformation.sourceOfFundsIds, selectOptions?.fundingSources]
    );
    useEffect(() => {
        if (!otherFundsSelected) setFieldValue(fieldName('sourceOfFundsOther'), null);
    }, [otherFundsSelected, setFieldValue]);

    useEffect(() => {
        if (values.ownerInformation.exemptionId) {
            setFieldValue(fieldName('individualBeneficialOwners'), []);
            setFieldValue(fieldName('businessBeneficialOwners'), []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.ownerInformation.exemptionId]);

    return (
        <div className="BeneficialOwners">
            <BackButton />
            <h2>Beneficial Owner or Controlling Party Information</h2>
            <p>
                We are required to gather information on owners who own 10% or more of the business.
                Please add owners or signers on the account below. If you are an exempt entity,
                choose the type of exemption below.
            </p>
            <WrappedFormSingleSelectField
                fieldName={fieldName('exemptionId')}
                options={selectOptions?.uboReportingExemptions ?? []}
                label={'Choose from the Exempt List'}
                labelExtraInfo="Optional"
                tooltip={
                    'If you feel like you are an exempt entity choose from the list below, compliance will validate and get back with you.'
                }
            />
            {!values.ownerInformation.exemptionId && (
                <>
                    <FieldArray
                        name={fieldName('individualBeneficialOwners')}
                        render={(arrayHelpers) => (
                            <>
                                <h3>Beneficial owner that is an individual</h3>
                                {values.ownerInformation.individualBeneficialOwners &&
                                values.ownerInformation.individualBeneficialOwners.length > 0
                                    ? values.ownerInformation.individualBeneficialOwners.map(
                                          (_, i) => (
                                              <IndividualOwner
                                                  parentField={arrayHelpers.name}
                                                  fieldName={`${arrayHelpers.name}[${i}]`}
                                                  index={i}
                                                  availableCountries={availableCountries}
                                                  arrayHelpers={arrayHelpers}
                                              />
                                          )
                                      )
                                    : null}
                                <Button
                                    type="button"
                                    variety="full"
                                    priority="secondary"
                                    onClick={() =>
                                        arrayHelpers.push(
                                            generateBeneficialPersonalOwner(
                                                values.ownerInformation.individualBeneficialOwners
                                                    .length
                                            )
                                        )
                                    }
                                    style={{ marginBottom: 20 }}
                                >
                                    Add individual beneficial owner
                                </Button>
                                <ErrorM
                                    style={{ marginTop: -20, marginBottom: 20 }}
                                    name={fieldName('individualBeneficialOwners')}
                                />
                            </>
                        )}
                    />
                    <FieldArray
                        name={fieldName('businessBeneficialOwners')}
                        render={(arrayHelpers) => (
                            <>
                                <h3>Beneficial owner that is a business</h3>
                                {values.ownerInformation.businessBeneficialOwners.map((_, i) => (
                                    <BusinessOwner
                                        parentField={arrayHelpers.name}
                                        fieldName={`${arrayHelpers.name}[${i}]`}
                                        index={i}
                                        availableCountries={availableCountries}
                                        entityTypes={selectOptions?.entityTypes ?? []}
                                        arrayHelpers={arrayHelpers}
                                    />
                                ))}
                                <Button
                                    type="button"
                                    variety="full"
                                    priority="secondary"
                                    onClick={() =>
                                        arrayHelpers.push(
                                            generateBeneficialBusinessOwner(
                                                values.ownerInformation.businessBeneficialOwners
                                                    .length
                                            )
                                        )
                                    }
                                    style={{ marginBottom: 20 }}
                                >
                                    Add business beneficial owner
                                </Button>
                                <ErrorM
                                    style={{ marginTop: -20, marginBottom: 20 }}
                                    name={fieldName('businessBeneficialOwners')}
                                />
                            </>
                        )}
                    />
                </>
            )}
            <FormTextField
                field={fieldName('authorizedSigner')}
                label={'Add an individual that is an authorized signer for the organization'}
                tooltip="Provide the name/s of all authorized signatories on the account. These are individuals authorized to move funds on behalf of the organization"
            />
            <ErrorM name="ownerInformation" />
        </div>
    );
};

const fieldName = (name: string) => `ownerInformation.${name}`;
