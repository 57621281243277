import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { MoveMoney } from './MoveMoney';
import { Transactions } from 'components/transactionsTable/Transactions';
import { PayIn } from './PayIn';
import { Payees } from './Payees/Payees';
import { MonthlyStatements } from 'components/monthlyStatements/MonthlyStatements';
import { AccountContextProvider } from './AccountContext';

type Props = {
    accountNumber: string;
    accountType: AccountType;
    sub_page: string;
} & RouteComponentProps;

export enum AccountSubPage {
    TRANSACTIONS = 'transactions',
    MOVE_MONEY = 'movemoney',
    PAY_IN = 'payin',
    PAYEES = 'payees',
    MONTHLY_STATEMENTS = 'monthlystatements',
}

export type AccountType = 'Funding' | 'Account';

const AccountPage: React.FC<Props> = ({ accountNumber, sub_page, navigate, accountType }) => {
    return (
        <AccountContextProvider accountId={accountNumber}>
            {
                sub_page === AccountSubPage.TRANSACTIONS ? (
                    <Transactions accountId={accountNumber} pageType="AccountTransactions" />
                ) : sub_page === AccountSubPage.MOVE_MONEY ? (
                    <MoveMoney accountNumber={accountNumber} />
                ) : sub_page === AccountSubPage.PAY_IN ? (
                    <PayIn
                        accountNumber={accountNumber}
                        navigate={navigate}
                        accountType={accountType}
                    />
                ) : sub_page === AccountSubPage.PAYEES ? (
                    <Payees accountNumber={accountNumber} />
                ) : sub_page === AccountSubPage.MONTHLY_STATEMENTS ? (
                    <MonthlyStatements accountNumber={accountNumber} />
                ) : (
                    <Redirect to={'/transactions'} noThrow />
                ) // default to showing transactions page if URL not correct. TODO add notfoundpage?
            }
        </AccountContextProvider>
    );
};

export default AccountPage;
