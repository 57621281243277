import { createUseFetch } from './helpers/createUseFetch';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ERROR_CODES } from 'errors';
import { createUseTable } from 'helpers/createUseTable';
import { BASE_URL, endpoints } from './endpoints.config';
import { loadTokenResource, saveTokenResource } from './helpers/auth';
import { store } from './index';
import { signOut, setAccessTokenRefreshing } from './reducers/auth';

const { refreshAccessToken: refreshEndpoint } = endpoints.auth;

/**
 * Custom axios instance with base url set, and some interceptors to
 * handle auth headers.
 *
 */
const instance = axios.create({
    baseURL: BASE_URL,
    // Chrome will throw an error when making a request to a stoplight endpoint
    // with this option set. Manually disable it in the request's config when making
    //  a request to Stoplight.
    withCredentials: true,
});

/**
 * A function that, given an access token, will modify an axios request
 * config and re-run the request.
 */
type RequestCallback = (token: string) => void;

/**
 * Some mutable state for a queue of requests. If we're already in the middle of
 * requesting a new token, we want to hold other requests in a queue, then make
 * them with the new token once we get it.
 */
class RequestHandler {
    isFetchingToken: boolean;
    private pendingRequests: RequestCallback[];

    constructor() {
        this.isFetchingToken = false;
        this.pendingRequests = [];
    }
    setFetchingToken = (b: boolean) => {
        this.isFetchingToken = b;
        store.dispatch(setAccessTokenRefreshing(b));
    };
    addToQueue = (callback: RequestCallback) => {
        this.pendingRequests.push(callback);
    };

    onTokenFetched = (token: string) => {
        this.pendingRequests.forEach((cb) => cb(token));
        this.clearQueue();
    };

    clearQueue = () => {
        this.pendingRequests = [];
        this.setFetchingToken(false);
    };
}

export const reqHandler = new RequestHandler();

// Before each request, attach the stored token if it exists.
instance.interceptors.request.use((request) => {
    try {
        const resource = loadTokenResource();
        if (resource === null) throw new Error();
        const { accessToken } = resource;
        const authHeader = { Authorization: `Bearer ${accessToken}` };
        return { ...request, headers: { ...request.headers, ...authHeader } };
    } catch (err) {
        return request;
    }
});

// After each request, if it comes back with a 401 error, refresh token and then retry.
instance.interceptors.response.use(
    (fulfilled) => {
        return fulfilled;
    },
    (rejected: AxiosError) => {
        const status = rejected.response?.status;

        if (status === 401 /*&& has the expired token header*/) {
            // Refresh access token.
            return refreshAccessToken(rejected);
        }

        // It's not an authorisation issue, just pass on the rejection.
        // store.dispatch(signOut());
        return Promise.reject(rejected);
    }
);

/**
 * Intercept the error, and replace it with a promise (awaitingNewToken), which
 * on construction will add a callback to our RequestHandler's queue.
 *
 * The first time the request handler receives one of these additions to its
 * queue, it will attempt to fetch a new access token. It stores up pending
 * requests until the new token arrives. To the initial callers it looks like
 * their request is waiting for a response from the server.
 *
 * Once the request handler has fetched a new access token, it will run every
 * callback in its queue. The callback takes an original request's config,
 * changes it to use the new access token, and remakes the request. It then
 * resolves the promise made in awaitingNewToken. The response to this new
 * request is thereby delivered to the original caller. The queue is then cleared.
 *
 * To that initial caller, it just looks like its original request took a while
 * to resolve.
 */
const refreshAccessToken = async (rejected: AxiosError) => {
    type RefreshResponse = {
        details: {
            accessToken: string;
            bEmailVerified: true;
            bTwoFactorAppAuthEnabled: false;
            bTwoFactorSMSAuthEnabled: false;
            expiresIn: string;
            refreshToken: string;
            refreshTokenExpiresIn: number;
        };
        status: string;
        errors: any;
        id: number;
    };

    try {
        const { response: errorResponse } = rejected;

        // Extract the refresh token from LS and make sure it's there.
        // Otherwise, just return the error.

        const resource = loadTokenResource();
        if (resource == null) {
            store.dispatch(signOut(false));
            return Promise.reject(rejected);
        }

        // Build a promise that will be handed to the caller instead of the error.
        const awaitingNewToken = new Promise((resolve) => {
            reqHandler.addToQueue((token) => {
                if (errorResponse) {
                    errorResponse.config.headers.Authorization = `Bearer ${token}`;
                    resolve(axios(errorResponse.config));
                }
            });
        });

        // Check if we're already trying to fetch a replacement access token.
        if (!reqHandler.isFetchingToken) {
            reqHandler.setFetchingToken(true);
            const data = {
                grantType: 'refresh_token',
                accessToken: resource.accessToken,
                refreshToken: resource.refreshToken,
            };

            const response = await axios.post<
                typeof data,
                AxiosResponse<{ errors: any } & RefreshResponse>
            >(refreshEndpoint, data);

            if (!response.data || (response.data.errors && response.data.errors.length > 0)) {
                // The refresh request failed, reset the queue, sign the
                // user out and return the error.
                reqHandler.clearQueue();
                store.dispatch(signOut(false));
                return Promise.reject(rejected);
            }

            // The refresh request succeeded, save the token details for future
            // requests and make the queued requests again.
            const { accessToken, refreshToken, refreshTokenExpiresIn } = response.data.details;

            saveTokenResource({
                accessToken,
                refreshToken,
                refreshTokenExpiresIn,
            });
            reqHandler.onTokenFetched(accessToken);
        }

        // Return the promise, which will fulfill when we have a new token,
        // instead of the error.
        return awaitingNewToken;
    } catch (error) {
        // Something went generically wrong, return this error and signout.
        store.dispatch(signOut(false));
        return Promise.reject(error);
    }
};

export default instance;

export const useFetch = createUseFetch(instance);
export const useTable = createUseTable(instance);
export type TableInfo = ReturnType<typeof useTable>;

// export const useFetch = createUseFetch(instance);

export type ErrorMessage = {
    type: any;
    fieldName: string;
    messageCode: string;
};

export const GENERIC_ERROR_MESSAGE: ErrorMessage = {
    type: '',
    fieldName: '',
    messageCode: 'There was a problem. Please try again later.',
};

export interface ApiResponse<T = any> {
    id: number;
    details: T;
    metadata: any;
    status: '0' | '1';
    errors: ErrorMessage[];
}

export class BaseApi {
    public static isResponseSuccessful = (response: AxiosResponse<ApiResponse>) =>
        response.data.status === '1';

    public static generateSuccessData = function <T>(data: T): SuccessData<T> {
        return {
            // Sometimes response will be success but won't contain any data.
            // In this case we set data to null so our type guard still recognises
            // it as SuccessData.
            data,
        };
    };

    // These type guards are defined outside of the class below as can't define
    // guard methods.
    public static isSuccessData = isSuccessData;
    public static isErrorData = isErrorData;

    // Declaring an overloaded signature that doesn't include a success parser
    // so that we can explicitly declare the response type as T (not P) when
    // successParser is not defined.
    public static makeRequest(
        requestConfig: AxiosRequestConfig
    ): Promise<SuccessData<{}> | ErrorData>;
    public static makeRequest<T>(
        requestConfig: AxiosRequestConfig
    ): Promise<SuccessData<T> | ErrorData>;
    public static makeRequest<T, P>(
        requestConfig: AxiosRequestConfig,
        successParser: (data: T) => P
    ): Promise<SuccessData<P> | ErrorData>;
    // P only needs to be set when successParser is defined.
    public static async makeRequest<T = {}, P = {}>(
        requestConfig: AxiosRequestConfig,
        successParser?: (data: T | {}) => P
    ): Promise<SuccessData<P | {}> | SuccessData<T | {}> | ErrorData> {
        try {
            const response = await instance.request<ApiResponse<T>>(requestConfig);
            if (this.isResponseSuccessful(response)) {
                if (successParser) {
                    const parsedSuccessResponse = successParser(response.data.details ?? {});
                    return this.generateSuccessData(parsedSuccessResponse);
                } else {
                    return this.generateSuccessData(response.data.details ?? {});
                }
            } else {
                // This else block shouldn't be entered unless the our backend
                // returns a 200 with status === '0', which ideally shouldn't
                // be happening.
                return this.generateErrorData(null);
            }
        } catch (error) {
            if (isAxiosErrorHandled(error)) {
                return this.generateErrorData(error.response.data.errors);
            } else {
                return this.generateErrorData(null);
            }
        }
    }

    // When axios recieves a 4xx or 5xx response, it throws an error which is
    // automatically typed as any. If this error contains error data from our
    // backend, we parse the code into messages and return these. If not we return
    // {errors: null} and leave the implementation to determine how to handle this.
    // (likely pop up a generic error toast).
    public static generateErrorData = (errors: ErrorMessage[] | null): ErrorData => {
        if (errors) {
            const errorMessages = retrieveErrorMessages(errors);
            return { errors: errorMessages };
        }
        return { errors: null };
    };
}

export const isApiResponseSuccess = (response: ApiResponse) => {
    return response.status === '1';
};

export type ErrorApiResponse = {
    id: number;
    status: '0';
    errors: ErrorMessage[];
};

export function isAxiosErrorHandled(error: any): error is { response: AxiosResponse<ApiResponse> } {
    return error.response && !!error.response.data;
}

export interface SuccessData<T = {}> {
    data: T;
}

function isSuccessData<T>(data: SuccessData<T> | ErrorData): data is SuccessData<T> {
    return typeof (data as SuccessData<T>).data !== 'undefined';
}

export interface ErrorData {
    errors: string[] | null;
}

function isErrorData<T>(data: SuccessData<T> | ErrorData): data is ErrorData {
    return typeof (data as ErrorData).errors !== 'undefined' && (data as ErrorData).errors !== null;
}

export const isErrorHandled = (error: {
    response: AxiosResponse;
}): error is { response: AxiosResponse<ErrorApiResponse> } => {
    return (
        error.response &&
        !(error.response.status === 500) &&
        error.response.data?.errors &&
        error.response.data.errors.length > 0
    );
};

export const retrieveErrorMessages = (errors: ErrorMessage[]) => {
    return errors.map(
        (error) => ERROR_CODES[error.messageCode as keyof typeof ERROR_CODES] ?? error.messageCode
    );
};
