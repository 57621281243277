import { navigate } from '@reach/router';
import { AppPath } from 'appConstants';
import Button from 'components/button/Button';
import React from 'react';

export const RegistrationSuccessMessage = () => {
    return (
        <div className="SuccessMessage">
            <h1>Thank you</h1>
            <p>Your registration was sucessful. Return to the login screen to sign in.</p>
            <Button variety="full" priority="primary" onClick={() => navigate(AppPath.SIGN_IN)}>
                Go to Sign In
            </Button>
        </div>
    );
};
