import { useFormikContext } from 'formik';
import FormTextField from 'components/form/FormTextField';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import { SelectOption } from 'components/categoryComponents/dropdown/Dropdown';
import { FormDateField } from 'components/form/FormDateField';
import SingleSelectCheckList from './SingleSelectCheckList';
import { Type } from '../CorporateApp';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { ErrorM } from 'components/form/ErrorM';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { useAfterMountEffect } from '../../../../helpers/useAfterMountEffect';
import { FormValues } from '../../businessVerificationSchema';

interface Props {
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    politicallyExposedTypes: Type[];
    personalIdTypes: Type[];
    values: any;
    expandAll: boolean;
}
const CompanyRep: React.FC<Props> = ({
    availableCountries,
    values,
    personalIdTypes,
    politicallyExposedTypes,
    expandAll,
}) => {
    const { values: formikValues, setFieldValue } = useFormikContext<FormValues>();

    const currentCountry = availableCountries.find(
        (country) => country.value === formikValues.corporate.representative.country
    );
    const stateOptions = currentCountry ? currentCountry.states : null;

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`corporate.representative.stateProvince`, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikValues.corporate.representative.country]);

    return (
        <div>
            <ErrorM name="corporate.representative" />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.representative.firstName'}
                    label={'First Name'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <FormTextField
                    field={'corporate.representative.lastName'}
                    label={'Last Name'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.representative.ssn'}
                    label={'Tax ID'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={`corporate.representative.dateOfBirth`}
                        label="Date of Birth*"
                        error={true}
                        datePickerProps={{
                            maxDate: new Date(),
                        }}
                    />
                </div>
            </div>
            <FormTextField
                field={'corporate.representative.addressLine1'}
                label={'Street Address'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.representative.postcode'}
                    label={'ZIP / Postal code'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <FormTextField
                    field={'corporate.representative.city'}
                    label={'City'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
            </div>
            <div className="FormSideBySide">
                {stateOptions ? (
                    <div className="FormBuilderComponent SideBySide">
                        <WrappedFormSingleSelectField
                            fieldName="corporate.representative.stateProvince"
                            label={'State / Province*'}
                            options={stateOptions}
                        />
                    </div>
                ) : (
                    <FormTextField
                        field="corporate.representative.stateProvince"
                        label={'State / Province'}
                        required={true}
                        maxLength={200}
                        sideBySide={true}
                        asterisk={true}
                    />
                )}
                <div className="FormBuilderComponent SidebySide">
                    <WrappedFormSingleSelectField
                        fieldName={'corporate.representative.country'}
                        options={availableCountries}
                        label="Country*"
                        // onChange={onChangeCountry}
                    />
                </div>
            </div>
            <div className="FormSideBySide">
                <FormPhoneNumberField
                    field={'corporate.representative.phoneNumber'}
                    label={'Phone Number*'}
                    sideBySide={true}
                />
                <FormTextField
                    field={'corporate.representative.email'}
                    label={'Email'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                    type="email"
                />
            </div>
            <FormTextField
                field={'corporate.representative.position'}
                label={'Position at Company'}
                required={true}
                maxLength={200}
                sideBySide={true}
                asterisk={true}
            />
            <h3>Personal identification document</h3>
            <SingleSelectCheckList
                fieldName="corporate.representative.personalIdDocumentTypesID"
                options={personalIdTypes}
            />
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.representative.personalIdDocument.idNumber'}
                    label={'Passport / ID Number'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.representative.personalIdDocument.dateOfIssue'}
                        label={'Date of Issue*'}
                        error={true}
                        datePickerProps={{
                            maxDate: new Date(),
                        }}
                    />
                </div>
            </div>
            <div className="FormSideBySide">
                <FormTextField
                    field={'corporate.representative.personalIdDocument.placeOfIssue'}
                    label={'Place of Issue'}
                    required={true}
                    maxLength={200}
                    sideBySide={true}
                    asterisk={true}
                />
                <div className="FormBuilderComponent SideBySide">
                    <FormDateField
                        field={'corporate.representative.personalIdDocument.expiryDate'}
                        label={'Date of Expiry*'}
                        error={true}
                        datePickerProps={{
                            minDate: new Date(),
                        }}
                    />
                </div>
            </div>

            <h3>Representative is politically exposed person</h3>
            <RadioButtons
                fieldname="corporate.representative.bExposed"
                options={['Yes', 'No']}
                booleanField={true}
            />
            {values.corporate.representative.bExposed === true && (
                <>
                    <SingleSelectCheckList
                        fieldName="corporate.representative.politicallyExposedPersonTypesID"
                        options={politicallyExposedTypes}
                    />

                    <h3>Specify the politically exposed person</h3>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={'corporate.representative.politicallyExposedPerson.firstName'}
                            label={'First Name'}
                            required={false}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <FormTextField
                            field={'corporate.representative.politicallyExposedPerson.lastName'}
                            label={'Last Name'}
                            required={false}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={
                                'corporate.representative.politicallyExposedPerson.stateInstitution'
                            }
                            label={'State / Institution'}
                            required={false}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <FormTextField
                            field={'corporate.representative.politicallyExposedPerson.position'}
                            label={'Position'}
                            required={false}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default CompanyRep;
