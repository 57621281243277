import instance, { isErrorHandled, retrieveErrorMessages } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { ValidationCodeField } from 'components/form/ValidationCodeField';
import { Modal } from 'components/modal/Modal';
import { ThemedLink } from 'components/themedLink/ThemedLink';
import { endpoints } from 'endpoints.config';
import { ERROR_CODES, getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { stringOnlyContainsNumbers } from 'helpers/stringOnlyContainsNumbers';
import { Toast } from 'helpers/toast';
import { useTFAField } from 'helpers/useTFAField';
import React, { SetStateAction, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'reducers/modal';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    showTfa: Yup.boolean(),
    isVerifying: Yup.boolean(),
    emailAddress: Yup.string().required('Please enter your new email address'),
    tfaCode: Yup.string().when('showTfa', {
        is: true,
        then: Yup.string().required('Please enter your two-factor authentication code'),
    }),
    emailVerificationCode: Yup.string().when('isVerifying', {
        is: true,
        then: Yup.string().test(
            'is valid verification',
            'Please enter your verifiction code',
            (value: string | undefined) => {
                if (value && value.length === 6 && stringOnlyContainsNumbers(value)) {
                    return true;
                }
                return false;
            }
        ),
    }),
});

export const UpdateEmailAddressModal = () => {
    const [isVerifyStep, setIsVerifyStep] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[] | null>(null);
    const dispatch = useDispatch();
    const [showTfa, setShowTfa] = useState(false);
    const [tfaType, toggleTFAType] = useTFAField(showTfa);
    const initialValues = {
        showTfa: false,
        isVerifying: false,
        emailAddress: '',
        emailVerificationCode: '',
        bResendCode: false,
        tfaCode: '',
        tfaType: tfaType,
    };

    const handleEmailSubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        if (!values.showTfa) {
            helpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            helpers.setSubmitting(false);
            helpers.setFieldTouched('tfaCode', false);
            return;
        }
        const { emailAddress, tfaCode } = values;
        instance
            .post(endpoints.profilemodule.updateEmailAddress, { emailAddress, tfaCode, tfaType })
            .then((res) => {
                setErrors(null);
                setIsVerifyStep(true);
                helpers.setSubmitting(false);
            })
            .catch((err) => {
                if (isErrorHandled(err)) {
                    const responseErrors = retrieveErrorMessages(err.response.data.errors);
                    setErrors(responseErrors);
                    helpers.setSubmitting(false);
                } else {
                    dispatch(closeModal());
                    Toast.openGenericErrorToast();
                }
            });
    };

    const handleVerifySubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        const { emailAddress, emailVerificationCode } = values;
        instance
            .post(endpoints.profilemodule.updateEmailAddressConfirm, {
                emailAddress,
                emailVerificationCode,
            })
            .then((res) => {
                dispatch(closeModal());
                Toast.openSuccessToast('Email updated successfully');
            })
            .catch((err) => {
                if (isErrorHandled(err)) {
                    const responseErrors = retrieveErrorMessages(err.response.data.errors);
                    setErrors(responseErrors);
                    helpers.setSubmitting(false);
                } else {
                    Toast.openGenericErrorToast();
                }
            });
    };

    const handleSubmit = (
        values: typeof initialValues,
        helpers: FormikHelpers<typeof initialValues>
    ) => {
        values.isVerifying
            ? handleVerifySubmit(values, helpers)
            : handleEmailSubmit(values, helpers);
    };

    return (
        <div className="UpdateEmailAddressModal">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => (
                    <Form>
                        <Modal
                            title="Change Email Address"
                            customButtons={
                                <div className="ModalNavigation">
                                    <Button
                                        priority="secondary"
                                        type="button"
                                        onClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        priority="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting || !formikProps.isValid}
                                    >
                                        Update
                                    </Button>
                                </div>
                            }
                        >
                            {formikProps.values.isVerifying ? (
                                <>
                                    <ValidationCodeField
                                        field="emailVerificationCode"
                                        label="Email verification code"
                                        value={formikProps.values.emailVerificationCode}
                                        autoComplete={false}
                                        required
                                        autoFocus
                                        holdFocus
                                    >
                                        {/* <ThemedLink
                                            onClick={() => {
                                                handleResendCode(formikProps.values, formikProps);
                                            }}
                                            className="ResendCodeLink"
                                        >
                                            Resend code
                                        </ThemedLink> */}
                                    </ValidationCodeField>
                                    {/* <div className="ResendCodeButtonContainer">
                                        
                                    </div> */}
                                </>
                            ) : (
                                <>
                                    <FormTextField
                                        field="emailAddress"
                                        label="Email address"
                                        required
                                        inputMode="email"
                                    />
                                    {formikProps.values.showTfa && (
                                        <TFAField
                                            field={'tfaCode'}
                                            label={'Two-factor authentication code'}
                                            autoComplete={false}
                                            required
                                            tfaType={tfaType}
                                            toggleTFAType={toggleTFAType}
                                            autoFocus
                                        />
                                    )}
                                </>
                            )}
                            {errors && (
                                <>
                                    {errors.map((error) => (
                                        <p className="ErrorText NoMargin">{error}</p>
                                    ))}
                                </>
                            )}
                        </Modal>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
