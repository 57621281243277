import React, { lazy, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

// Icons
import View from '../../../assets/Cat-Entity/Icon_Action_01.png';
import Update from '../../../assets/Cat-Entity/Icon_Action_02.png';
import Delete from '../../../assets/Cat-Entity/Icon_Action_03.png';

import { CrudItems } from 'components/Category/CrudMenu';
import { format } from 'date-fns';
import { endpoints } from 'endpoints.config';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';
import Category from 'components/Category/Category';
import Entity from 'components/categoryComponents/Entity';
import ViewListUserCards from './ViewListUserCards';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { useDispatch, useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { selectAccountTypePathItem } from 'reducers/componentResources';
import { AppPath, DashboardPath } from 'appConstants';
import { CardManagementSubpage } from '../CardManagement';
import { number } from 'yup/lib/locale';
import { AddCardToUser } from './AddCardToUser';
import { AddUser } from './AddUser';
import instance, { TableInfo } from 'api';
import { MoveFunds } from './MoveFunds';
import Button from 'components/button/Button';
import { Toast } from 'helpers/toast';
import { every } from 'rxjs/operators';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { notification } from 'antd';
import { ModalTypes, openModal } from 'reducers/modal';

const ManageCardUser = lazy(() => import('./ManageCardUser'));
const UserCards = lazy(() => import('./ManageUserCards'));

// Define the url for the table's data endpoint.
const url = endpoints.cardManagementModule.listusers; /* REPLACE__ME - api url for this component */

// Define which column on the table represents each row's ID.
// This should be unique to each row.
const idColumn: keyof CardAndUserData =
    'customers__Id'; /* REPLACE__ME - id column for this component */

//Define the data structure for table
/* REPLACE__ME - table data structure */
export type CardAndUserData = {
    customers__Id: number;
    customerManagementRelations__ExternalID: string;
    topUpCards__Id: 0;
    assets__Code: string;
    topUpCards__CardNumber: string;
    topUpCards__ExpiryDate: string;
    balances__TotalBalance: number;
    balances__AvailableBalance: number;
    balances__PendingBalance: number;
    topUpCards__bSuppressed: boolean;

    bVerificationPass: boolean;
    customerUsers__VerificationStatus: string;
};

const crudItems: CrudItems<CardAndUserData> = [
    {
        icon: View,
        title: 'Add Card User' /* REPLACE__ME */,
        imgAltText: 'Create',
        menuCode: 'CREATE',
        showInMultiSelect: false,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <ManageCardUser
                actionType={'create'}
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={null}
            />
        ),
        inRightPanel: true,
        isDisabled: false,
        notInMenus: true,
    },
    // {
    //     icon: View,
    //     title: 'Move Money' /* REPLACE__ME */,
    //     imgAltText: 'Add',
    //     menuCode: 'TRANSFER',
    //     showInMultiSelect: true,
    //     //permissionsKey: 'create',
    //     content: (onDone, selectedData, menu) => (
    //         <MoveFunds
    //             manageeCardsId={selectedData[0].topUpCards__Id}
    //             manageeCardsIds={[selectedData[0].topUpCards__Id]}
    //             onDone={onDone}
    //             isBatchTransfer={false}
    //         />
    //     ),
    //     inRightPanel: true,
    //     isDisabled: (data) => {
    //         return data[0] && (!data[0].topUpCards__Id || data[0].topUpCards__bSuppressed);
    //     },
    // },
    {
        icon: View,
        //title: 'Move Money In Batch' /* REPLACE__ME */,
        title: (data) => (data.length > 1 ? 'Move Monery In Batch' : 'Move Money'),
        imgAltText: 'Add',
        menuCode: 'TRANSFER', //'TRANSFER_BATCH',
        showInMultiSelect: true,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <MoveFunds
                manageeCardsId={selectedData[0].topUpCards__Id}
                manageeCardsIds={selectedData.map((d) => d.topUpCards__Id)}
                onDone={onDone}
                // isBatchTransfer={true}
                isBatchTransfer={selectedData.length > 1}
            />
        ),
        inRightPanel: true,
        isDisabled: (data) => {
            return (
                (data.find((d) => d.bVerificationPass !== true) ? true : false) ||
                //!!data.find((d) => d.bVerificationPass !== true) ||
                (data?.find((d) => d && (!d.topUpCards__Id || d.topUpCards__bSuppressed)) //not every
                    ? true
                    : false)
            );
        },
    },

    {
        icon: View,
        title: 'View Transactions' /* REPLACE__ME */,
        imgAltText: 'Add',
        menuCode: 'VIEW',
        showInMultiSelect: false,
        //permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <Redirect
                to={`../${CardManagementSubpage.Transactions}`}
                state={{ cardId: selectedData[0].topUpCards__Id }}
                noThrow
            />
        ),
        inRightPanel: true,
        isDisabled: (data) => (data.find((d) => d.bVerificationPass !== true) ? true : false),
        // !!data.find((d) => d.bVerificationPass !== true) ?? true, //false,
        /* (data) => {
            return data[0] && (!data[0].topUpCards__Id || data[0].topUpCards__bSuppressed);
        } */
    },
];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: CardAndUserData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.topUpCards__ExpiryDate) {
        formattedRow = {
            ...formattedRow,
            topUpCards__ExpiryDate: localizedFormatDate(new Date(row.topUpCards__ExpiryDate)),
        };
    }
    return formattedRow;
};

export interface CardUsersProps {}

type Props = CardUsersProps & RouteComponentProps;

function ManageCardUsers(props: Props) {
    const [subCatShown, setSubCatShown] = useState<boolean>(false);

    const [selectedParentData, setSelectedParentData] = useState<CardAndUserData[]>([]);

    const [idFromParentRowOption, setIdFromParentRowOption] = useState<React.ReactText | null>(
        null
    );
    const cultureCode = useSelector(selectCultureCode);
    const accountTypePath = useSelector(selectAccountTypePathItem);
    useDefaultRoute(
        `/${cultureCode}${accountTypePath}${DashboardPath.CARDMANAGEMENT}/${CardManagementSubpage.Users}`
    );

    const update = useSelector(selectUIUpdate);
    const dispatch = useDispatch();

    return subCatShown ? (
        <ViewListUserCards
            setSubCatShown={setSubCatShown}
            selectedParentData={selectedParentData}
            idFromParentRowOption={idFromParentRowOption}
        />
    ) : (
        <Category<CardAndUserData>
            location={props.location}
            //location={'/Cardusers'}
            contentTitle="Card Users"
            noun="Card users" /** REPLACE__ME */
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={false}
            fetchConfig={{ withCredentials: false }}
            classname="listsCatTable"
            setSubCatShown={setSubCatShown}
            setSelectedParentData={setSelectedParentData}
            setIdFromParentRowOption={setIdFromParentRowOption}
            selectedParentData={selectedParentData}
            idFromParentRowOption={idFromParentRowOption}
            isParent={true}
            rowButtonBuilder={RowButtonBuilder}
            emptyTableLabel="No Card Users Found"
            notification={{
                notificationType: NotificationIdentifier.RELOAD_MANAGEE_VERIFICATIONS,
                notificationFn: ({
                    pushType,
                    customFn,
                }: {
                    pushType?: string;
                    customFn?: (val?: any) => void;
                }) => {
                    if (pushType && update?.pushType === pushType) {
                        //    if (update?.pushType === NotificationIdentifier.RELOAD_MANAGEE_VERIFICATIONS) {
                        customFn && customFn();
                        dispatch(completeUIUpdate());
                    }
                },
            }}
        />
    );
}

const RowButtonBuilder = (table: TableInfo) => (rowData: CardAndUserData) =>
    <RowButton rowData={rowData} table={table} />;

const RowButton = ({ rowData, table }: { rowData: CardAndUserData; table: TableInfo }) => {
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    return (
        <>
            {rowData.topUpCards__Id &&
                (rowData.topUpCards__bSuppressed && rowData.bVerificationPass ? (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            setDisabled(true);
                            instance
                                .post(endpoints.cardManagementModule.activateCard, {
                                    manageeTopUpCardsId: rowData.topUpCards__Id,
                                })
                                .then((res) => table.reload())
                                .catch(() => Toast.openGenericErrorToast())
                                .finally(() => setDisabled(false));
                        }}
                        disabled={disabled}
                    >
                        Activate Card
                    </Button>
                ) : (
                    rowData.bVerificationPass && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setDisabled(true);
                                instance
                                    .post(endpoints.cardManagementModule.deactivateCard, {
                                        manageeTopUpCardsId: rowData.topUpCards__Id,
                                    })
                                    .then((res) => table.reload())
                                    .catch(() => Toast.openGenericErrorToast())
                                    .finally(() => setDisabled(false));
                            }}
                            disabled={disabled}
                        >
                            Deactivate Card
                        </Button>
                    )
                ))}
            {!rowData.topUpCards__Id && rowData.bVerificationPass && (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        setDisabled(true);
                        dispatch(
                            openModal({
                                modalType: ModalTypes.ADD_CARD_MANAGECARDUSERS,
                                data: {
                                    manageesId: rowData.customers__Id,
                                    currency: 'USD',
                                    onSuccess: (res?: any) => table.reload(),
                                    onError: (err?: any) => Toast.openGenericErrorToast(),
                                    onCompletion: (res?: any) => setDisabled(false),
                                },
                            })
                        );
                    }}
                    disabled={disabled}
                >
                    Add Card
                </Button>
            )}
        </>
    );
};

export default ManageCardUsers;
