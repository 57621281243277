import { BusinessVerificationFlow } from 'reducers/appSettings';
import { multiFile } from './UploadField';
import { FormValues } from '../businessVerificationSchema';
import { FormValues as FormValuesV2 } from '../../businessVerificationV2/schema';

function allFilesHaveUniqueNames(
    files: File[],
    values: any,
    field: string,
    verificationFlow: BusinessVerificationFlow,
    acceptedFiles?: multiFile[]
): boolean {
    const fileNames = new Set(files.map((file) => file.name));
    if (!(fileNames.size === files.length)) {
        return false;
    }
    if (acceptedFiles && acceptedFiles.length > 0) {
        for (const file of files) {
            if (acceptedFiles.some((acceptedFile) => acceptedFile.fileName === file.name)) {
                return false;
            }
        }
    }
    let currentlyUploaded = allUploadedFiles(values, field, verificationFlow);
    if (currentlyUploaded.length > 0) {
        for (const file of files) {
            if (currentlyUploaded.some((fileName: string) => fileName === file.name)) {
                return false;
            }
        }
    }

    return true;
}

export default allFilesHaveUniqueNames;
function allUploadedFiles(
    _values: FormValues,
    field: string,
    businessVerificationFlow: BusinessVerificationFlow
): string[];
function allUploadedFiles(
    _values: FormValuesV2,
    field: string,
    businessVerificationFlow: BusinessVerificationFlow
): string[];
function allUploadedFiles(
    _values: FormValuesV2 | FormValues,
    field: string,
    businessVerificationFlow: BusinessVerificationFlow
): string[] {
    const uploadedFileNames: string[] = [];
    if (businessVerificationFlow === BusinessVerificationFlow.v1) {
        const values = _values as FormValues;
        values.significantParties.forEach(
            (party: FormValues['significantParties'][0], index: number) => {
                if (
                    party.proofOfAddressFilename &&
                    party.proofOfAddressFilename.length > 0 &&
                    field !== `significantParties.${index}.proofOfAddressFilename`
                ) {
                    uploadedFileNames.push(party.proofOfAddressFilename);
                }
            }
        );

        if (
            values.corporate.authorizedCapital.proofOfSourceOfFundsFileName &&
            values.corporate.authorizedCapital.proofOfSourceOfFundsFileName.length > 0 &&
            field !== 'corporate.authorizedCapital.proofOfSourceOfFundsFileName'
        ) {
            uploadedFileNames.push(values.corporate.authorizedCapital.proofOfSourceOfFundsFileName);
        }

        if (
            values.corporate.authorizedCapital.supportingDocuments &&
            values.corporate.authorizedCapital.supportingDocuments.length > 0 &&
            field !== 'corporate.authorizedCapital.supportingDocuments'
        ) {
            values.corporate.authorizedCapital.supportingDocuments.forEach((doc: multiFile) => {
                if (doc.fileName && doc.fileName.length > 0) {
                    uploadedFileNames.push(doc.fileName);
                }
            });
        }
    } else {
        const values = _values as FormValuesV2;
        if (values.operationsInformation.financialInstitutionFormFileName) {
            uploadedFileNames.push(values.operationsInformation.financialInstitutionFormFileName);
        }
        values.ownerInformation.individualBeneficialOwners.forEach(
            (owner) =>
                owner.proofOfAddressFilename && uploadedFileNames.push(owner.proofOfAddressFilename)
        );
    }

    if (
        Array.isArray(_values.documentsUpload) &&
        _values.documentsUpload.length > 0 &&
        _values.documentsUpload[0].documentTypesID
    ) {
        _values.documentsUpload.forEach((doc: { fileName: string }, index: number) => {
            if (doc.fileName && doc.fileName.length > 0 && field !== `documentUpload.${index}`) {
                uploadedFileNames.push(doc.fileName);
            }
        });
    }

    return uploadedFileNames;
}
