import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import FormTextField from 'components/form/FormTextField';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const initialValues = {
    toCustomerAssetAccountsId: NaN,
    amount: '',
    reference: '',
    //date: new Date().toISOString(),
    //frequency:0,
};

type DestinationAccount = {
    accountName: string;
    accountNumber: string;
    customerAssetAccountsId: number;
    symbol: string;
};

const validationSchema = Yup.object({
    toCustomerAssetAccountsId: Yup.number()
        .typeError('Please select a destination account')
        .required('Please select a destination account'),
    amount: Yup.number()
        .typeError('Amount must be a number')
        .required('Please enter an amount')
        .positive('Please enter a number greater than 0'),
    reference: Yup.string().required('Please enter a payment reference'),
});
export const InternalTransfer = ({
    fromCustomerAssetAccountsId,
    onSuccess,
}: {
    fromCustomerAssetAccountsId: number;
    onSuccess(): void;
}) => {
    const [availableAccounts, setAvailableAccounts] = useState<SelectOption[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        instance
            .get<ApiResponse<DestinationAccount[]>>(
                endpoints.accounts.getInternalTransferAccounts,
                {
                    params: { fromCustomerAssetAccountsId },
                }
            )
            .then((res) =>
                setAvailableAccounts(
                    res.data.details.map((account) => ({
                        label: account.accountName,
                        value: account.customerAssetAccountsId,
                        symbol: account.symbol,
                    }))
                )
            );
    }, [fromCustomerAssetAccountsId]);

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post(endpoints.accounts.createInternalTransfer, {
                ...values,
                fromCustomerAssetAccountsId,
            })
            .then((res) => {
                Toast.openSuccessToast('Transfer successful');
                onSuccess();
            })
            .catch((err) => {
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors &&
                    err.response.data.errors.length > 0 &&
                    err.response.data.errors.some((err) =>
                        ['amount', 'reference', 'tocustomerassetaccountsid'].includes(
                            err.fieldName.toLowerCase()
                        )
                    )
                ) {
                    err.response.data.errors.forEach((err) => {
                        if (
                            ['amount', 'reference', 'tocustomerassetaccountsid'].includes(
                                err.fieldName.toLowerCase()
                            )
                        ) {
                            formikHelpers.setFieldError(
                                toCamelCase(err.fieldName) as string,
                                getErrorMessage(err.messageCode)
                            );
                        }
                    });
                } else Toast.openGenericErrorToast();
                formikHelpers.setSubmitting(false);
            });
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                    <WrappedFormSingleSelectField
                        fieldName="toCustomerAssetAccountsId"
                        options={availableAccounts}
                        label={'Destination account'}
                        dropdownProps={{
                            formatOptionLabel: (data: {
                                value: number;
                                label: string;
                                symbol: string;
                            }) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '14px',
                                    }}
                                >
                                    <TextCurrencyIcon currencySymbol={data.symbol} />
                                    {data.label}
                                </div>
                            ),
                        }}
                    />
                    <FormTextField
                        field="amount"
                        label="Amount"
                        required={true}
                        onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^[0-9]*\.?[0-9]{0,2}$/;
                            if (regex.test(value)) {
                                setFieldValue('amount', value, false);
                            } else {
                                setFieldValue('amount', values.amount, true);
                            }
                        }}
                        inputMode="numeric"
                    />

                    <FormTextField field="reference" label="Reference" required={true} />
                    <Button variety="full" type="submit" disabled={isSubmitting}>
                        Transfer
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
