import React, { ErrorInfo } from 'react';
import { isRedirect } from '@reach/router';

type State = { hasError: boolean };
type Props = { fallback: React.ReactNode; children: React.ReactNode };

export default class ErrorBoundary extends React.Component<Props, State> {
    static defaultProps = {
        fallback: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (isRedirect(error)) {
            throw error;
        }
    }

    render() {
        if (this.state.hasError) return this.props.fallback;

        return this.props.children;
    }
}
