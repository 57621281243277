import {
    FormBooleanField,
    FormDateTimeField,
    FormField,
    FormSingleSelectField,
    FormTextField,
} from '@avamae/formbuilder';
import { useField } from 'formik';
import { camelCase } from 'lodash';

import React from 'react';
import { Collapse } from 'antd';
//import 'antd/dist/antd.css';

const { Panel } = Collapse;

type TMetadataType = 'SingleSelect' | 'Text' | 'Number' | 'Integer' | 'String' | 'DateTime';
export type TSimplifiedCustomMetadata = {
    name: string;
    type: 'SingleSelect' | 'Text' | 'Number' | 'Integer' | 'String' | 'DateTime' | 'Boolean'; //TMetadataType;
    label: string;
    dataSource: { value: number; label: string }[] | null;
}[];

export const toCamelCase = function <T>(pascalCase: keyof T): keyof T {
    const firstLetter = (pascalCase as string).slice(0, 1);
    const remaining = (pascalCase as string).slice(1, (pascalCase as string).length);
    return (firstLetter.toLowerCase() + remaining) as keyof T;
};
export const toPascalCase = function <T>(camelCase: keyof T): keyof T {
    const firstLetter = (camelCase as string).slice(0, 1);
    const remaining = (camelCase as string).slice(1, (camelCase as string).length);
    return (firstLetter.toUpperCase() + remaining) as keyof T;
};

export const arrayRenderer = <G,>(
    readOnly: boolean,
    simplifiedCustomMetadata: TSimplifiedCustomMetadata,
    upperCase?: boolean,

    title?: string,
    subFieldName?: string & keyof G
) => {
    return function ArrayRenderer<T>(_key: React.ReactText, field: FormField<T>) {
        const [formikField] = useField<G[]>(
            upperCase ? (toPascalCase(field.name as string) as string) : (field.name as string)
        );
        return (
            <Collapse //defaultActiveKey={['0']}
                onChange={() => {}}
            >
                {formikField.value.map((_v, _index) => {
                    return (
                        <Panel
                            header={
                                title
                                    ? `${title}_${_index}${
                                          subFieldName !== undefined &&
                                          _v[subFieldName] !== undefined
                                              ? `: ${_v[subFieldName]}`
                                              : ''
                                      }`
                                    : `No.${_index}${
                                          subFieldName !== undefined &&
                                          _v[subFieldName] !== undefined
                                              ? `: ${_v[subFieldName]}`
                                              : ''
                                      }`
                            }
                            key={_index}
                        >
                            {/* <div className="Btn">
                                    {title ? `${title}_${_index}` : `No.${_index}`}
                                </div> */}
                            {Object.entries(_v)
                                .reduce((acc: [string, any][], element) => {
                                    //any[]
                                    if (
                                        element[0] === 'addDate' ||
                                        element[0] === 'editDate' ||
                                        element[0] === 'addedBy' ||
                                        element[0] === 'lastEditedBy'
                                    ) {
                                        return [...acc, element];
                                    }
                                    return [element, ...acc];
                                }, [])
                                .map((_o: [string, any], _i: number) => {
                                    const mockMetadata = simplifiedCustomMetadata.find(
                                        (_d) => _o[0] === toCamelCase(_d.name)
                                    );
                                    if (!mockMetadata) return <></>;

                                    return (
                                        <>
                                            {_i === 0 ? (
                                                <h4 className="SubTitle" style={{ margin: '0' }}>
                                                    Details
                                                </h4>
                                            ) : null}
                                            {/* {_i === Object.entries(_v).length - 4 ? (
                                                    <h4
                                                        className="SubTitle"
                                                        style={{ marginBottom: '0' }}
                                                    >
                                                        System Details
                                                    </h4>
                                                ) : null} */}
                                            {(() => {
                                                switch (mockMetadata?.type) {
                                                    case 'SingleSelect':
                                                        return (
                                                            <FormSingleSelectField
                                                                //fieldName={camelCase(mockMetadata.name)}
                                                                //fieldName={_o[0] as string}
                                                                fieldName={`${
                                                                    formikField.name
                                                                }[${_index}][${_o[0] as string}]`}
                                                                options={
                                                                    mockMetadata.dataSource ?? []
                                                                }
                                                                label={mockMetadata.label}
                                                                readOnly={readOnly}
                                                            />
                                                        );
                                                    case 'DateTime':
                                                        return (
                                                            <FormDateTimeField
                                                                //fieldName={camelCase(mockMetadata.name)}
                                                                //fieldName={_o[0] as string}
                                                                fieldName={`${
                                                                    formikField.name
                                                                }[${_index}][${_o[0] as string}]`}
                                                                label={mockMetadata.label}
                                                                readOnly={readOnly}
                                                            />
                                                        );
                                                    case 'String':
                                                    case 'Text':
                                                        return (
                                                            <FormTextField
                                                                //fieldName={camelCase(mockMetadata.name)}
                                                                //fieldName={_o[0] as string}
                                                                fieldName={`${
                                                                    formikField.name
                                                                }[${_index}][${_o[0] as string}]`}
                                                                label={mockMetadata.label}
                                                                readOnly={readOnly}
                                                            />
                                                        );
                                                    case 'Integer':
                                                    case 'Number':
                                                        return (
                                                            <FormTextField
                                                                //fieldName={camelCase(mockMetadata.name)}
                                                                //fieldName={_o[0] as string}
                                                                fieldName={`${
                                                                    formikField.name
                                                                }[${_index}][${_o[0] as string}]`}
                                                                label={mockMetadata.label}
                                                                inputProps={{
                                                                    type: 'number',
                                                                }}
                                                                readOnly={readOnly}
                                                            />
                                                        );
                                                    case 'Boolean':
                                                        return (
                                                            <FormBooleanField
                                                                //fieldName={camelCase(mockMetadata.name)}
                                                                //fieldName={_o[0] as string}
                                                                fieldName={`${
                                                                    formikField.name
                                                                }[${_index}][${_o[0] as string}]`}
                                                                label={mockMetadata.label}
                                                                readOnly={readOnly}
                                                            />
                                                        );
                                                    default:
                                                        return <></>;
                                                }
                                            })()}
                                        </>
                                    );
                                })}
                        </Panel>
                    );
                })}
            </Collapse>
        );
    };
};
