import { RouteComponentProps, useLocation } from '@reach/router';
import instance, { ApiResponse, useFetch } from 'api';
import { allowableOrigins } from 'components/jumio/JumioIdIframe';
import { JumioVerificationStatusResponse } from 'components/jumio/jumioModels';
import { ManageesJumioTextualData } from 'components/jumio/managees/ManageesJumioTextualData';
import Page from 'components/page/Page';
import { Spinner } from 'components/spinner/Spinner';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { Toast, ToastMessageReason } from 'helpers/toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

//icons
import WarningIcon from 'assets/Icon_Warning.png';
import Img_KYC from 'assets/Img_KYC.png';

type Props = {} & RouteComponentProps;

const ManageeJumioSteps = {
    IdIframe: 'IdFrame',
    IdIframeSubmitted: 'IdFrameSubmitted',
    AddressForm: 'AwaitingAddressVerification',
    AddressIframe: 'AddressFrame',
    AddressIframeSubmitted: 'AddressFrameSubmitted',
    Success: 'Success',
} as const;

type ManageeJumioStep = (typeof ManageeJumioSteps)[keyof typeof ManageeJumioSteps];

const getNextStep = (step: ManageeJumioStep | null): ManageeJumioStep => {
    if (!step) return ManageeJumioSteps.IdIframe;
    switch (step) {
        case ManageeJumioSteps.IdIframe:
            return ManageeJumioSteps.IdIframeSubmitted;
        case ManageeJumioSteps.IdIframeSubmitted:
            return ManageeJumioSteps.AddressForm;
        case ManageeJumioSteps.AddressForm:
            return ManageeJumioSteps.AddressIframe;
        case ManageeJumioSteps.AddressIframe:
            return ManageeJumioSteps.AddressIframeSubmitted;
        case ManageeJumioSteps.AddressIframeSubmitted:
        case ManageeJumioSteps.Success:
            return ManageeJumioSteps.Success;
    }
};

export type ManageeStatusResponse = {
    status: ManageeJumioStep;
    additionalData?: {
        iframeUrl?: string;
        bManualAvailable: boolean;
        token: string;
    };
};

export const ManageesJumio: React.FC<Props> = (props) => {
    const [step, setStep] = useState<ManageeJumioStep | null>(null);
    const { search } = useLocation();
    const [loading, setLoading] = useState(true);
    const [addressToken, setAddressToken] = useState('');
    const [url, setUrl] = useState('');
    const jumioToken = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return searchParams.get('token');
    }, [search]);
    useEffect(() => {
        instance
            .post<ApiResponse<ManageeStatusResponse>>(
                endpoints.manageesJumioModule.verificationStatus,
                {
                    jumioToken: encodeURIComponent(jumioToken ?? ''),
                    jumioConsent: true,
                }
            )
            .then((res) => {
                res?.data?.details.status && setStep(res.data.details.status);
                res.data.details?.additionalData?.token &&
                    setAddressToken(res.data.details.additionalData.token);
                if (res?.data?.details?.additionalData?.iframeUrl) {
                    setUrl(res.data.details.additionalData.iframeUrl);
                    // window.location.href = res.data.details.additionalData.iframeUrl;
                }
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setLoading(false));
    }, [jumioToken]);

    const handleMessage = useCallback(
        (e: MessageEvent<any>) => {
            if (isIframeComplete(e)) {
                if (step === ManageeJumioSteps.IdIframe) {
                    instance.post(endpoints.manageesJumioModule.idSubmit, {
                        jumioToken: encodeURIComponent(jumioToken ?? ''),
                    });
                }
                setUrl('');
                setStep((curr) => getNextStep(curr));
            }
        },
        [step, jumioToken]
    );

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);
    return (
        <Page isPublic className="ManageesJumio">
            {loading ? (
                <SubPageLoader />
            ) : url ? (
                <iframe
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="id Verification"
                    src={url}
                ></iframe>
            ) : step === ManageeJumioSteps.AddressForm ? (
                <ManageesJumioTextualData token={addressToken} setIframeUrl={setUrl} />
            ) : step === ManageeJumioSteps.AddressIframeSubmitted ||
              step === ManageeJumioSteps.IdIframeSubmitted ? (
                <PendingScreen />
            ) : step === ManageeJumioSteps.Success ? (
                <SuccessScreen />
            ) : (
                <ErrorScreen />
            )}
        </Page>
    );
};

const isIframeComplete = (e: MessageEvent<any>) => {
    try {
        if (allowableOrigins.includes(e.origin.toLowerCase())) {
            if (typeof e.data === 'string') {
                const data = JSON.parse(e.data);
                return data.payload.value === 'success';
            } else return e.data.payload.value === 'success';
        }
    } catch (e) {
        return false;
    }
};

const PendingScreen = () => {
    return (
        <div className="Waiting">
            <Spinner />
            <h3>We are checking your details</h3>
            <p>
                Thank you for completing the application. We'll notify you once it's been reviewed.
            </p>
        </div>
    );
};

const SuccessScreen = () => {
    return (
        <div className="VerificationComplete">
            <h3>Verification complete!</h3>
            <img className="SuccessImage" src={Img_KYC} alt="" />
            <p>We'll be in touch once we've checked your documents</p>
        </div>
    );
};

const ErrorScreen = () => {
    return (
        <div className="VerificationError">
            <img src={WarningIcon} alt="error" />
            <h3>Verification error</h3>
            <p>We couldn't verify you, please try again later</p>
        </div>
    );
};
