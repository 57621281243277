import { ERROR_CODES } from '../errors';
import { Store } from './rootReducer';

export type ErrorCodes = {
    [key: string]: string;
};

/* STATE */
type State = {
    codes: ErrorCodes;
};

const initialState: State = {
    codes: ERROR_CODES,
};

/* ACTIONS */
const UPDATE_ERROR_CODES = 'app/error-codes/UPDATE_ERROR_CODES';

type Action = { type: typeof UPDATE_ERROR_CODES; payload: ErrorCodes };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case UPDATE_ERROR_CODES:
            return {
                codes: {
                    ...state.codes,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}

/* ACTION CREATORS */
export const updateErrorCodes = (payload: ErrorCodes): Action => {
    return {
        type: UPDATE_ERROR_CODES,
        payload,
    };
};

/* SELECTORS */
export const selectErrorCodes = (app: Store): ErrorCodes => app.errorCodes.codes;
