import React from 'react';
import zxcvbn from 'zxcvbn';
import { useField } from 'formik';

type PasswordHelperProps = {
    fieldName: string;
    overrideResult?: zxcvbn.ZXCVBNResult;
    userInputs?: string[];
};

const PasswordHelper: React.FC<PasswordHelperProps> = ({
    overrideResult,
    fieldName,
    userInputs = [],
}) => {
    const [field] = useField(fieldName);
    const result = overrideResult ?? zxcvbn(field.value, userInputs);
    const { feedback } = result;

    const strongPassword = !feedback.warning && feedback.suggestions.length === 0;

    return (
        <div className="PasswordHelperMoreInfo">
            <h3>Password helper</h3>
            {strongPassword && <h4>Your password is pretty strong!</h4>}
            {feedback.warning && <p>Warning: {feedback.warning}</p>}
            {feedback.suggestions.length > 0 && (
                <ul>
                    {feedback.suggestions.map((s, i) => (
                        <li key={i}>{s.replace(', digits,', '')}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export { PasswordHelper };
