import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { Modal } from 'components/modal/Modal';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Formik } from 'formik';
import { Toast } from 'helpers/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, selectModalState } from 'reducers/modal';

type Props = {};

export type TransactionInfo = {
    transactionId: number;
    fullName: string;
    accountNumber: string;
    achRoutingNumber: string;
    wireRoutingNumber: string;
    reference: string;
    imad: string;
    transactionType: string;
    transferType?: string;
};

export const TransactionInfoModal = (props: Props) => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();
    const [transactionInfo, setTransactionInfo] = useState<TransactionInfo | null>(null);
    useEffect(() => {
        if (modalState.modalType !== ModalTypes.TRANSACTION_INFO) return;
        instance
            .get<ApiResponse<TransactionInfo>>(endpoints.accounts.transactionDetails, {
                params: { transactionId: modalState.data.customerAssetAccountsTransactions__Id },
            })
            .then((res) => {
                if (!res.data.details) throw new Error('No details returned');
                setTransactionInfo(res.data.details);
            })
            .catch((err) => {
                Toast.openErrorToast('Error loading transaction info');
                dispatch(closeModal());
            });
    }, [modalState, dispatch]);
    if (modalState.modalType !== ModalTypes.TRANSACTION_INFO) return null;
    return (
        <Modal
            title={`Transaction Info`}
            customButtons={
                <div className="ModalNavigation">
                    <Button onClick={() => dispatch(closeModal())}>Close</Button>
                </div>
            }
        >
            {transactionInfo ? (
                <table className="TransactionInfoTable">
                    <tbody>
                        <tr>
                            <td className="Label">Transaction Type:</td>
                            <td className="Value">
                                {`${
                                    transactionInfo.transactionType === 'Transfer' &&
                                    transactionInfo.transferType
                                        ? transactionInfo.transferType + ' '
                                        : ''
                                } ${transactionInfo.transactionType ?? '-'}`}
                            </td>
                        </tr>
                        <tr>
                            <td className="Label">Full Name:</td>
                            <td className="Value">{transactionInfo.fullName ?? '-'}</td>
                        </tr>
                        <tr>
                            <td className="Label">Routing Number:</td>
                            <td className="Value">
                                {displayCorrectRoutingNumber(
                                    transactionInfo.achRoutingNumber,
                                    transactionInfo.wireRoutingNumber,
                                    transactionInfo.transferType ?? null
                                ) ?? '-'}
                            </td>
                        </tr>
                        <tr>
                            <td className="Label">Account Number:</td>
                            <td className="Value">{transactionInfo.accountNumber ?? '-'}</td>
                        </tr>
                        {transactionInfo.imad && (
                            <tr>
                                <td className="Label">Wire IMAD:</td>
                                <td className="Value">{transactionInfo.imad ?? '-'}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="Label">Payment Reference:</td>
                            <td className="Value">{transactionInfo.reference ?? '-'}</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <Spinner className="noBG-absCenter" />
            )}
        </Modal>
    );
};

const displayCorrectRoutingNumber = (
    achRoutingNumber: string | null,
    wireRoutingNumber: string | null,
    transferType: string | null
) => {
    if (!achRoutingNumber) return wireRoutingNumber;
    if (!wireRoutingNumber) return achRoutingNumber;
    if (transferType === 'Wire') return wireRoutingNumber;
    return achRoutingNumber;
};
