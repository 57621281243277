import { useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { CardContext } from './CardPage';
import api, { ApiResponse, isErrorHandled } from '../../api';
import { endpoints } from '../../endpoints.config';
import { Toast } from '../../helpers/toast';
import Button from '../../components/button/Button';
import { Form, Formik, FormikHelpers } from 'formik';
import { TFAField } from '../../components/form/TFAField';
import { useTFAField } from '../../helpers/useTFAField';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { toCamelCase } from '../../helpers/formatFormFieldNames';
import { getErrorMessage } from '../../errors';

type Props = {
    visible: boolean;
    onClose: () => void;
    onSuccess: () => void;
};
type FormState = {
    tfaCode: string;
};
type ProtectedCardDetails = {
    cardNumber: number;
    cvc: number;
    pin: number;
};

const validationSchema = Yup.object({
    tfaCode: Yup.string().required('Please enter your two-factor authentication code'),
});

export const CardDetailsModal: React.FC<Props> = ({ visible, onClose, onSuccess }) => {
    const { cardDetails, setCardDetails, cardId } = useContext(CardContext);
    const [tfaType, toggleTfaType, reSendTfaCode] = useTFAField();

    if (!visible) return null;

    const handleUnexpectedEmptyValue = (field: string) => {
        Toast.openErrorToast(`Failed to get ${field}`);
    };

    const getProtectedCardDetails = async (
        { tfaCode }: FormState,
        helpers: FormikHelpers<FormState>
    ) => {
        try {
            const res = await api.get<ApiResponse<ProtectedCardDetails>>(
                endpoints.cards.protectedCardDetails,
                {
                    params: {
                        topUpCardsId: cardId,
                        tfaCode: tfaCode,
                        tfaType,
                    },
                    withCredentials: false,
                }
            );
            if (res.data.status === '1') {
                const { cardNumber, cvc, pin } = res.data.details;
                const cardNumberFailed =
                    cardDetails?.bHasFullCardNumber &&
                    (!cardNumber || cardNumber.toString().includes('X'));
                const cvcFailed = cardDetails?.bHasCvc && !cvc;
                const pinFailed = cardDetails?.bHasPin && !pin;

                if (cardNumberFailed) handleUnexpectedEmptyValue('Card number');
                if (cvcFailed) handleUnexpectedEmptyValue('cvc');
                if (pinFailed) handleUnexpectedEmptyValue('pin');

                setCardDetails &&
                    setCardDetails((val) =>
                        val
                            ? {
                                  ...val,
                                  cardNumberFull:
                                      res.data.details.cardNumber &&
                                      res.data.details.cardNumber.toString().includes('X')
                                          ? undefined
                                          : res.data.details.cardNumber,
                                  cvc: res.data.details.cvc,
                                  pin: res.data.details.pin,
                              }
                            : null
                    );
                onSuccess();
            } else throw new Error();
        } catch (err: AxiosError | any) {
            if (isErrorHandled(err)) {
                const fieldErrors = err.response.data.errors.filter(
                    (error) => toCamelCase(error.fieldName) === 'tfaCode'
                );
                if (fieldErrors.length > 0) {
                    helpers.setFieldError('tfaCode', getErrorMessage(fieldErrors[0].messageCode));
                } else Toast.openErrorToast('Failed to get card details');
            } else {
                Toast.openErrorToast('Failed to get card details');
            }
        }
    };

    return (
        <Formik
            initialValues={{ tfaCode: '' }}
            validationSchema={validationSchema}
            onSubmit={getProtectedCardDetails}
        >
            {({ isValid, isSubmitting }) => (
                <Form>
                    <Modal
                        title="Reveal Card details"
                        onCloseModal={onClose}
                        customButtons={
                            <div className="ModalNavigation">
                                <Button priority="secondary" type="button" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    priority="primary"
                                    type="submit"
                                    disabled={isSubmitting || !isValid}
                                >
                                    Reveal
                                </Button>
                            </div>
                        }
                    >
                        <>
                            <TFAField
                                field="tfaCode"
                                label={'TFA code'}
                                required={true}
                                toggleTFAType={toggleTfaType}
                                tfaType={tfaType}
                                handleResendCode={reSendTfaCode}
                                autoFocus
                                holdFocus
                            />
                        </>
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
