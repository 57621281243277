import { RouteComponentProps } from '@reach/router';
import instance, { TableInfo } from 'api';
import { DashboardPath } from 'appConstants';
import Button from 'components/button/Button';
import Category from 'components/Category/Category';
import { CrudItems } from 'components/Category/CrudMenu';
import { endpoints } from 'endpoints.config';
import { localizedFormatDate } from 'helpers/categoryHelpers/userLocale';
import { Toast } from 'helpers/toast';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountTypePathItem } from 'reducers/componentResources';
import { selectCultureCode } from 'reducers/language';
import { CardManagementSubpage } from '../CardManagement';

// Define the url for the table's data endpoint.
const url =
    endpoints.cardManagementModule.listcardrequests; /* REPLACE__ME - api url for this component */

// Define which column on the table represents each row's ID.
// This should be unique to each row.
const idColumn: keyof CardRequestsData =
    'customers__Id'; /* REPLACE__ME - id column for this component */

//Define the data structure for table
/* REPLACE__ME - table data structure */
export type CardRequestsData = {
    customers__Id: number;
    customerUsers__FirstName: string;
    customerUsers__LastName: string;
    customerManagementRelations__ExternalID: string;
    requestedTopUpCards__Id: number;
    requestedTopUpCards__Currency: string;
};

const crudItems: CrudItems<CardRequestsData> = [];

// Optional - function for formatting a row by column name. Takes a row and should
// spit out an object with the same keys.
// Should do this for date columns and format them as required
const rowFormatter = (row: CardRequestsData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.topUpCards__ExpiryDate) {
        formattedRow = {
            ...formattedRow,
            //topUpCards__ExpiryDate: localizedFormatDate(new Date(row.topUpCards__ExpiryDate)),
        };
    }
    return formattedRow;
};

export interface CardRequestsProps {}

type Props = CardRequestsProps & RouteComponentProps;

function ManageCardRequests(props: Props) {
    const [subCatShown, setSubCatShown] = useState<boolean>(false);

    const [selectedParentData, setSelectedParentData] = useState<CardRequestsData[]>([]);

    const [idFromParentRowOption, setIdFromParentRowOption] = useState<React.ReactText | null>(
        null
    );
    const cultureCode = useSelector(selectCultureCode);
    const accountTypePath = useSelector(selectAccountTypePathItem);
    useDefaultRoute(
        `/${cultureCode}${accountTypePath}${DashboardPath.CARDMANAGEMENT}/${CardManagementSubpage.CardRequests}`
    );

    return (
        <Category<CardRequestsData>
            location={props.location}
            //location={'/Cardusers'}
            contentTitle="Card Requests"
            noun="Card requests" /** REPLACE__ME */
            idColumn={idColumn}
            listEndpointUrl={url}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={true}
            fetchConfig={{ withCredentials: false }}
            classname="listsCatTable"
            setSubCatShown={setSubCatShown}
            setSelectedParentData={setSelectedParentData}
            setIdFromParentRowOption={setIdFromParentRowOption}
            selectedParentData={selectedParentData}
            idFromParentRowOption={idFromParentRowOption}
            isParent={false}
            rowButtonBuilder={RowButtonBuilder}
            emptyTableLabel="No Card Requests Found"
        />
    );
}

const RowButtonBuilder = (table: TableInfo) => (rowData: CardRequestsData) =>
    <RowButton rowData={rowData} table={table} />;

const RowButton = ({ rowData, table }: { rowData: CardRequestsData; table: TableInfo }) => {
    const [disabled, setDisabled] = useState(false);
    return (
        <>
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    setDisabled(true);
                    instance
                        .post(endpoints.cardManagementModule.approveCardRequest, {
                            requestedTopUpCardsId: rowData.requestedTopUpCards__Id,
                        })
                        .then((res) => table.reload())
                        .catch(() => Toast.openGenericErrorToast())
                        .finally(() => setDisabled(false));
                }}
                disabled={disabled}
            >
                Approve Card
            </Button>
        </>
    );
};

export default ManageCardRequests;
