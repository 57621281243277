import { FormDateField } from 'components/form/FormDateField';
import FormTextField from 'components/form/FormTextField';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { FormPhoneNumberField } from 'components/form/FormPhoneNumberField';
import UploadField from '../../helpers/UploadField';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import Button from 'components/button/Button';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { DocumentRejection } from 'components/businessVerification/BusinessVerification';
import instance from 'api';
import { endpoints } from 'endpoints.config';
import { useAfterMountEffect } from '../../../../helpers/useAfterMountEffect';

interface Props {
    index: number;
    availableCountries: (SelectOption & { states: SelectOption[] | null })[];
    arrayHelpers: any;
    disableRemove: boolean;
    expandAll: boolean;
    setIsExpanded: React.SetStateAction<any>;
    rejectedDocuments: DocumentRejection | null | undefined;
    startExpanded?: boolean;
}

interface FormValues {
    significantParties: {
        _id: number;
        country: string;
        lastName: string;
        firstName: string;
        stateProvince: string;
        proofOfAddress: string;
        proofOfAddressFilename: string;
    }[];
}
const SignificantParty: React.FC<Props> = ({
    index,
    availableCountries,
    arrayHelpers,
    disableRemove,
    expandAll,
    setIsExpanded,
    rejectedDocuments,
    startExpanded,
}) => {
    const [title, setTitle] = useState<string>('');
    const [expand, setExpand] = useState<boolean>(!!startExpanded);
    const { errors, touched, values, setFieldValue, setFieldError } =
        useFormikContext<FormValues>();

    const handleTitle = () => {
        if (
            values.significantParties[index].firstName &&
            values.significantParties[index].lastName
        ) {
            setTitle(
                `${values.significantParties[index].firstName} ${values.significantParties[index].lastName}`
            );
        } else {
            setTitle('');
        }
    };

    useEffect(() => {
        if (errors.significantParties && errors.significantParties[index]) {
            setExpand(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.significantParties, touched.significantParties]);

    //eslintreact-hooks/exhaustive-deps
    useEffect(() => {
        //If array changed recalulate numbering
        setFieldValue(`significantParties.${index}.number`, index + 1);
        handleTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.significantParties.length]);

    useEffect(() => {
        if (expandAll) {
            setExpand(true);
        }
    }, [expandAll]);

    useEffect(() => {
        if (expandAll && expand && index === values.significantParties.length - 1) {
            setIsExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expand]);

    useEffect(() => {
        if (
            rejectedDocuments &&
            rejectedDocuments.hasOwnProperty('proofOfAddress') &&
            rejectedDocuments.proofOfAddress &&
            rejectedDocuments.proofOfAddress.length > 0
        ) {
            if (
                rejectedDocuments.proofOfAddress?.includes(
                    values.significantParties[index].proofOfAddressFilename
                )
            ) {
                setFieldError(
                    `significantParties.${index}.proofOfAddressFilename`,
                    `File named ${values.significantParties[index].proofOfAddressFilename} has been rejected, please upload another file`
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveForm = () => {
        instance.post(endpoints.businessverificationmodule.saveFormData, {
            ...values,
        });
    };

    const currentCountry = availableCountries.find(
        (country) => country.value === values.significantParties[index].country
    );
    const stateOptions = currentCountry ? currentCountry.states : null;

    // Reset field when the country changes
    useAfterMountEffect(() => {
        setFieldValue(`significantParties.${index}.stateProvince`, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.significantParties[index].country]);

    return (
        <div
            className="SignificantParty"
            key={`SignificantParty-${values.significantParties[index]._id}`}
        >
            <div className="FormHeader" onClick={(e) => setExpand(!expand)}>
                <h3 className="Title">{`Significant Party ${index + 1} ${
                    title ? `- ${title}` : ''
                }`}</h3>
                <div className="Row">
                    <div className={expand ? 'ChevronOpen' : 'ChevronClosed'}></div>
                </div>
            </div>
            {expand && (
                <div className="Form">
                    <div className="FormSideBySide" onBlur={handleTitle}>
                        <FormTextField
                            field={`significantParties.${index}.firstName`}
                            label={'First Name'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <FormTextField
                            field={`significantParties.${index}.lastName`}
                            label={'Last Name'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`significantParties.${index}.title`}
                            label={'Title'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <div className="FormBuilderComponent SideBySide">
                            <FormDateField
                                field={`significantParties.${index}.dateOfBirth`}
                                label="Date of Birth*"
                                error={true}
                                datePickerProps={{
                                    maxDate: new Date(),
                                }}
                            />
                        </div>
                    </div>
                    <div className="FormSideBySide">
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={`significantParties.${index}.nationality` as never}
                                label={'Nationality*'}
                                options={availableCountries}
                            />
                        </div>
                        <FormTextField
                            field={`significantParties.${index}.email`}
                            label={'Email'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormPhoneNumberField
                            field={`significantParties.${index}.phoneNumber`}
                            label={'Phone*'}
                            sideBySide={true}
                        />
                        <div className="FormBuilderComponent SideBySide">
                            <WrappedFormSingleSelectField
                                fieldName={`significantParties.${index}.country` as never}
                                label={'Country*'}
                                options={availableCountries}
                            />
                        </div>
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`significantParties.${index}.ssn`}
                            label={'Tax ID number / SSN'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        {stateOptions ? (
                            <div className="FormBuilderComponent SideBySide">
                                <WrappedFormSingleSelectField
                                    fieldName={`significantParties.${index}.stateProvince` as never}
                                    label={'State / Province*'}
                                    options={stateOptions}
                                />
                            </div>
                        ) : (
                            <FormTextField
                                field={`significantParties.${index}.stateProvince`}
                                label={'State / Province'}
                                required={true}
                                maxLength={200}
                                sideBySide={true}
                                asterisk={true}
                            />
                        )}
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`significantParties.${index}.city`}
                            label={'City'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <FormTextField
                            field={`significantParties.${index}.addressLine1`}
                            label={'Street Address'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`significantParties.${index}.addressLine2`}
                            label={'Street Address 2'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                        />
                        <FormTextField
                            field={`significantParties.${index}.postcode`}
                            label={'Postal / Zip Code'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormSideBySide">
                        <FormTextField
                            field={`significantParties.${index}.percentageSharesOwned`}
                            label={'Percentage of Shares Owned (%)'}
                            required={true}
                            type="number"
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                        <FormTextField
                            field={`significantParties.${index}.position`}
                            label={'Position at the Company'}
                            required={true}
                            maxLength={200}
                            sideBySide={true}
                            asterisk={true}
                        />
                    </div>
                    <div className="FormBox">
                        <RadioButtons
                            options={['Yes', 'No']}
                            label={'Controlling Party*'}
                            booleanField={true}
                            fieldname={`significantParties.${index}.bControllingParty`}
                            error={true}
                        />
                    </div>
                    <div className="FormBox">
                        <div className="FormLabel">
                            <label>
                                Proof of Address
                                <span>*</span>
                            </label>
                        </div>
                        <UploadField
                            fieldName={`significantParties.${index}.proofOfAddressFilename`}
                            displayError={true}
                            jsonField={true}
                            onChange={saveForm}
                        />
                    </div>
                    {!disableRemove && (
                        <Button
                            type="button"
                            priority="primary"
                            variety="full"
                            onClick={() => {
                                // delete significant party file if present
                                values.significantParties?.[index]?.proofOfAddressFilename &&
                                    instance.post(
                                        endpoints.businessverificationmodule.deleteFormDocument,
                                        {
                                            fileName:
                                                values.significantParties[index]
                                                    .proofOfAddressFilename,
                                        }
                                    );
                                arrayHelpers.remove(index);
                                saveForm();
                            }}
                        >
                            Remove Significant Party
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};
export default SignificantParty;
