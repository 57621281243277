import { FormSingleSelectField } from '@avamae/formbuilder';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import Button from 'components/button/Button';
import { Spinner } from 'components/spinner/Spinner';
import { endpoints } from 'endpoints.config';
import { Form, Formik, FormikHelpers } from 'formik';
import { Toast } from 'helpers/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { VerifyIdentityOptions } from '../JumioVerification';
import { ManageeStatusResponse } from 'pages/ManageesJumio';
import * as Yup from 'yup';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { getErrorMessage } from 'errors';
const initialValues = {
    countryCode: '',
    typeCode: '',
};

const validationSchema = Yup.object({
    countryCode: Yup.string().required('Please select a country').nullable(),
    typeCode: Yup.string().required('Please select a document type').nullable(),
});
export const ManageesJumioTextualData = ({
    token,
    setIframeUrl,
}: {
    token: string;
    setIframeUrl(url: string): void;
}) => {
    const [options, setOptions] = useState<VerifyIdentityOptions | null>(null);

    const handleSubmit = (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        instance
            .post<ApiResponse<ManageeStatusResponse>>(
                endpoints.manageesJumioModule.verifyAddress,
                values
            )
            .then((res) => {
                if (res.data.details.additionalData?.iframeUrl)
                    setIframeUrl(res.data.details.additionalData?.iframeUrl);
                else throw new Error('No iframe url returned');
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                if (
                    isAxiosErrorHandled(err) &&
                    err.response.data.errors.some(
                        (errorMessage) => errorMessage.messageCode === 'Too_Many_Requests'
                    )
                ) {
                    return Toast.openErrorToast('Too many attempts, please try again tomorrow');
                }
                Toast.openErrorToast(
                    getErrorMessage(
                        isAxiosErrorHandled(err)
                            ? err.response.data.errors?.[0].messageCode ?? ''
                            : ''
                    )
                );
            });
    };

    useEffect(() => {
        instance
            .get<ApiResponse<VerifyIdentityOptions>>(endpoints.manageesJumioModule.verifyAddress, {
                params: { token },
            })
            .then((res) => setOptions(res.data.details));
    }, [token]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <Form>
                <h3>We need some proof of your address, please choose from the options below:</h3>
                <WrappedFormSingleSelectField
                    label="Document type"
                    fieldName="typeCode"
                    options={
                        options?.documentOptions.map((option) => ({
                            value: option.typeCode,
                            label: option.name,
                        })) ?? []
                    }
                    // dropdownProps={{
                    //     menuPortalTarget: document.body,
                    //     styles: {
                    //         menuPortal: (provided: any) => ({
                    //             ...provided,
                    //             zIndex: 500,
                    //         }),
                    //     },
                    // }}
                />
                <WrappedFormSingleSelectField
                    label="Origin country"
                    fieldName="countryCode"
                    options={
                        options?.countryOptions.map((option) => ({
                            label: option.name,
                            value: option.isoCode,
                        })) ?? []
                    }
                    // dropdownProps={{
                    //     classNamePrefix: 'test',
                    //     menuPortalTarget: document.body,
                    //     styles: {
                    //         menuPortal: (provided: any) => ({
                    //             ...provided,
                    //             zIndex: 500,
                    //         }),
                    //     },
                    // }}
                />
                <Button variety="full">Next</Button>
            </Form>
        </Formik>
    );
};
